interface ConditionalWrapperProps {
  condition: boolean;
  children: React.ReactNode;
  Wrapper: React.FC<Record<string, unknown> & { children: React.ReactNode }>;
}

export const ConditionalWrapper = ({
  condition,
  children,
  Wrapper,
}: ConditionalWrapperProps) => {
  if (condition) {
    return <Wrapper>{children}</Wrapper>;
  }
  return <>{children}</>;
};
