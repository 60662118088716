import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ROUTES } from '@celito.clients/enums';
import { generateName } from '@celito.clients/services';
import { ConfirmDialog } from '@celito.clients/shared';
import { RolesType, RoleType, UserTypes } from '@celito.clients/types';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import {
  useCallbackPrompt,
  usePermission,
  useUser,
} from 'libs/core/src/hooks/';
import { InHouseInputSelectProps } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { customRoleType, FormValues, Item } from './add-group.model';
import AddGroupView from './add-group.view';

const schema = yup.object().shape({
  label: yup.string().required(LocalStrings.requiredLabel),
  roleNames: yup
    .array()
    .min(1, LocalStrings.requiredLabel)
    .required(LocalStrings.requiredLabel),
  isActive: yup.string().required(LocalStrings.requiredLabel),
}) as yup.ObjectSchema<FormValues>;

const AddGroupController: React.FC = () => {
  const [selectedKeys, setSelectedKeys] = React.useState<string[]>([]);
  const [selectedList, setSelectedList] = React.useState<(string | number)[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPermissionGrpCreated, setIsPermissionGrpCreated] =
    useState<boolean>(false);

  const [list, setList] = React.useState<Item[] | []>([]);
  const [rightItemList, setRightItemList] = useState<[] | Item[]>([]);
  const [roles, setRoles] = useState<customRoleType[]>([]);
  const navigate = useNavigate();
  const { fetchAllUsers } = useUser();
  const { addPermissionGroup, fetchAllRoles } = usePermission();

  const navigateBackRoute = `../${ROUTES.LIST}/permission_group_view__s`;

  const { handleSubmit, control, setValue, formState } = useForm<FormValues>({
    defaultValues: {
      label: '',
      description: '',
      roleNames: selectedKeys,
      isActive: 'yes',
      userNames: [],
    },
    resolver: yupResolver(schema),
    mode: 'all',
    shouldUnregister: false,
    reValidateMode: 'onChange',
  });

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    formState.isDirty
  );

  const navigateToList = () => {
    navigate(`../${ROUTES.LIST}/permission_group_view__s`);
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const createPermissionGroup = (payloads: FormValues) => {
    const updatedPayloads = {
      ...payloads,
      isActive: payloads.isActive === 'yes',
    };
    setIsPermissionGrpCreated(true);
    addPermissionGroup(updatedPayloads)
      .then(() => {
        successToast({
          message: 'Permission Group added successfully',
        });
        confirmNavigation();

        navigateToList();
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsPermissionGrpCreated(false);
      });
  };

  const onOptionSelect: InHouseInputSelectProps['onOptionSelect'] = (
    ev,
    { selectedOptions }
  ) => {
    let selectedValues: string[] = [];

    if (!Array.isArray(selectedOptions))
      selectedValues = [selectedOptions?.value];

    setValue('roleNames', selectedValues);

    setSelectedKeys(selectedValues);
  };

  const onSubmit = async () => {
    await handleSubmit((data) => {
      const payloads = {
        label: data.label,
        name: '',
        description: data?.description,
        roleNames: selectedKeys,
        isActive: data.isActive,
        userNames: selectedList,
      };
      generateName('permission_group__s', data.label)
        .then((resp: string) => {
          payloads.name = resp;
          createPermissionGroup(payloads);
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        });
    })();
  };

  const getAllUsers = () => {
    setIsLoading(true);
    fetchAllUsers()
      .then((users: UserTypes[] | []) => {
        const userList = users.map((user) => ({
          id: user.name,
          name: user.label,
        }));
        setList(
          userList.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          }) as Item[]
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAllRoles = () => {
    fetchAllRoles()
      .then((resp: RolesType) => {
        const roles = resp.data;
        setRoles(
          roles.map((role: RoleType) => {
            return { text: role.label, value: role.name };
          })
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      });
  };

  useEffect(() => {
    getAllUsers();
    getAllRoles();
  }, []);

  return (
    <>
      <AddGroupView
        {...{
          onSubmit,
          control,
          onOptionSelect,
          handleSubmit,
          setList,
          setRightItemList,
          rightItemList,
          selectedKeys,
          navigateBack,
          options: roles,
          isLoading,
          isPermissionGrpCreated,
          list,
          selectedList,
          setSelectedList,
          navigateBackRoute,
        }}
      />

      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </>
  );
};

export default AddGroupController;
