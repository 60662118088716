import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import {
  AttributeTypeEnum,
  ButtonTypes,
  OperatorsEnum,
} from '@celito.clients/enums';
import {
  ConfirmDialog,
  CustomButton,
  Field,
  Icon,
  IconButton,
  InHouseInputSelect,
  Typography,
} from '@celito.clients/shared';
import {
  Button,
  Divider,
  mergeClasses,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  Tooltip,
} from '@fluentui/react-components';
import cn from 'classnames';
import { returnSelectedOptionFromOptions } from 'libs/core/src/utils/getSelectedOptionFromOptions';
import React from 'react';
import { Controller } from 'react-hook-form';

import { InputPerDataType } from './components/input-per-data-type';
import {
  ICondition,
  IConditionType,
  IGroup,
  IOption,
  IRule,
  IRuleOption,
  RulesComponentProps,
} from './rules-component.model';
import { rulesStyles } from './rules-component.styles';
import { RuleComponentEnum } from './types/rules-component.types';

interface RulesComponentViewProps extends RulesComponentProps {
  handleSetOperatorOptions: (
    index: number,
    groupIndex: number,
    conditionIndex: number,
    dataType?: AttributeTypeEnum
  ) => void;
  operatorOptions: IRuleOption[];
  addConditionToGroup: (
    ruleIndex: number,
    groupIndex: number,
    group: IGroup
  ) => void;
  addGroupToRule: (index: number, groups: IGroup[]) => void;
  removeGroupFromRole: (
    index: number,
    groupIndex: number,
    groups: IGroup[],
    rule: IRule
  ) => void;
  removeConditionFromGroup: (
    ruleIndex: number,
    groupIndex: number,
    conditionIndex: number,
    condition: ICondition[]
  ) => void;
  handleSetConditionType: (groupIndex: number, type: IConditionType) => void;
  handleSetGroupConditionType: (
    ruleIndex: number,
    groupIndex: number,
    type: IConditionType
  ) => void;
  getInputTypeFromColumnAndCondition: (
    dataType: string,
    operator: OperatorsEnum
  ) => string;
  onTabSelect: (event: SelectTabEvent, data: SelectTabData) => void;
  selectedValue: any;
  handleDeleteModal: () => void;
  onDeleteClick: (index: number) => void;
  itemToDelete?: number;
  isDeleteDialogOpen: boolean;
  allObjectOptions: IOption[];
  isLoadingObject: boolean;
  fetchAllObjects: () => Promise<void>;
  handleManualSelectTab: (index: number) => void;
}

export const RulesComponentView = ({
  methods,
  fields,
  operatorOptions,
  addConditionToGroup,
  onRemoveRule,
  handleSetOperatorOptions,
  handleSetConditionType,
  getInputTypeFromColumnAndCondition,
  addGroupToRule,
  removeGroupFromRole,
  handleSetGroupConditionType,
  removeConditionFromGroup,
  ruleLayout,
  allowDeleteAllGroups = false,
  onAddRule,
  onTabSelect,
  selectedValue,
  handleDeleteModal,
  isDeleteDialogOpen,
  itemToDelete,
  onDeleteClick,
  isCompact,
  submitButtonTitle,
  submitButtonHandler,
  hideActionButtons = false,
  disableFields = false,
  multiRulesPlaceholder,
  allObjectOptions,
  fetchAllObjects,
  isLoadingObject,
  handleManualSelectTab,
  isMultiRuleLabelDisabled,
  interfaceName = 'rules',
  filterableColumns,
  attributeDefinitionOfColumns,
  rules,
}: RulesComponentViewProps) => {
  const styles = rulesStyles();

  const sortedFields = [...fields].sort((a, b) => a.text.localeCompare(b.text));

  const {
    control,
    getValues,
    watch,
    setValue,
    formState: { errors },
  } = methods;

  const containerRef = React.useRef<HTMLDivElement>(null);

  const ruleObserver: IRule[] = watch(`${interfaceName}`, []);

  return (
    <>
      {ruleLayout === RuleComponentEnum.MultipleRules && (
        <div className={styles.outerMultipleRules}>
          <div className={styles.availableMultipleRules} ref={containerRef}>
            <TabList
              defaultSelectedValue={`${interfaceName}.${0}`}
              selectedValue={selectedValue}
              className={mergeClasses(
                styles.tabList,
                Array.isArray(errors.rules) &&
                  errors.rules.some((rule: any) => rule) &&
                  styles.tabError
              )}
              onTabSelect={onTabSelect}
            >
              {ruleObserver.map((rule, index) => {
                return (
                  <Controller
                    name={`${interfaceName}.${index}.label`}
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                      <Tab
                        value={`${interfaceName}.${index}`}
                        className={styles.tab}
                      >
                        {isMultiRuleLabelDisabled ? (
                          <span style={{ marginRight: '8px' }}>
                            {getValues(`${interfaceName}.${index}.label`)}
                          </span>
                        ) : (
                          <Tooltip
                            content={
                              getValues(`${interfaceName}.${index}.label`) ||
                              'Fill input name'
                            }
                            relationship="label"
                          >
                            <input
                              type="text"
                              placeholder={
                                multiRulesPlaceholder
                                  ? `${multiRulesPlaceholder} ${index + 1}`
                                  : `Insert Label ${index + 1}`
                              }
                              className={mergeClasses(
                                styles.tabInput,
                                Array.isArray(errors.rules) &&
                                  errors.rules.some((rule: any) => rule) &&
                                  styles.tabInputError
                              )}
                              onChange={(_ev) =>
                                field.onChange(_ev.target.value)
                              }
                              value={getValues(
                                `${interfaceName}.${index}.label`
                              )}
                            />
                          </Tooltip>
                        )}
                        {!disableFields && (
                          <Icon
                            iconName="Delete16Regular"
                            style={{ width: '14px' }}
                            onClick={() => onDeleteClick(index)}
                            className={styles.deleteBtn}
                          />
                        )}
                      </Tab>
                    )}
                  />
                );
              })}

              {ruleObserver && ruleObserver.length === 0 ? (
                <CustomButton
                  buttonTitle="Add a View"
                  rightIcon="Add"
                  buttonType={ButtonTypes.Text}
                  onClick={onAddRule}
                />
              ) : (
                <Icon
                  iconName="Add24Regular"
                  className={styles.addIcon}
                  onClick={onAddRule}
                />
              )}
            </TabList>
          </div>

          <div className={styles.availableColumnsScrollButtons}>
            <Divider vertical className={styles.scrollDivider} />

            <Button
              onClick={() => {
                if (containerRef.current)
                  containerRef.current.scrollLeft -= 120;
              }}
              icon={<Icon iconName="ChevronLeft24Regular" />}
              data-testid="button-left-arrow"
            />
            <Button
              onClick={() => {
                if (containerRef.current)
                  containerRef.current.scrollLeft += 120;
              }}
              icon={<Icon iconName="ChevronRight24Regular" />}
              data-testid="button-right-arrow"
            />
          </div>
        </div>
      )}

      <div className={styles.rulesContainer}>
        {ruleObserver?.map((rule, index) => {
          return (
            `${interfaceName}.${index}` === selectedValue && (
              <>
                <div className={styles.ruleContainer}>
                  {rule.groups?.length > 1 && (
                    <div
                      className={cn(styles.ruleConditionType, {
                        [styles.disabled]: disableFields,
                      })}
                    >
                      <div
                        onClick={() => handleSetConditionType(index, 'all')}
                        className={mergeClasses(
                          styles.inactiveConditionType,
                          styles.inactiveRuleConditionType,
                          rule.conditionType === 'all' &&
                            styles.activeConditionType
                        )}
                        data-testid="button-set-rule-all"
                      >
                        {LocalizationString.ALL}
                      </div>
                      <div
                        className={mergeClasses(
                          styles.inactiveConditionType,
                          styles.inactiveRuleConditionType,
                          rule.conditionType === 'any' &&
                            styles.activeConditionType
                        )}
                        data-testid="button-set-rule-any"
                        onClick={() => handleSetConditionType(index, 'any')}
                      >
                        {LocalizationString.ANY}
                      </div>
                    </div>
                  )}

                  {rule.groups?.length === 0 && !disableFields && (
                    <Field
                      validationState={
                        Array.isArray(errors.rules) &&
                        errors.rules.some((rule: any) => rule?.groups)
                          ? 'error'
                          : 'none'
                      }
                      validationMessage={
                        Array.isArray(errors.rules) &&
                        errors.rules.find(
                          (rule: any, mappedRuleIndex) =>
                            mappedRuleIndex === index
                        )?.groups?.message
                      }
                    >
                      <div className={styles.addGroupBtnNoItems}>
                        <CustomButton
                          leftIcon="Flow"
                          buttonTitle="Add Group"
                          buttonType={ButtonTypes.Ghost}
                          onClick={() => addGroupToRule(index, rule.groups)}
                        />
                      </div>
                    </Field>
                  )}

                  {rule.groups?.length > 0 && (
                    <div className={styles.conditionGroup}>
                      {!isCompact && <div>If</div>}
                      <div className={styles.groupContainer}>
                        {rule.groups.map((group, groupIndex) => (
                          <>
                            <div
                              key={`inner-condition-${index}-${groupIndex}`}
                              className={styles.innerConditionGroup}
                            >
                              <div className={styles.innerConditionHeaderGroup}>
                                <div
                                  className={cn(styles.conditionType, {
                                    [styles.disabled]: disableFields,
                                  })}
                                >
                                  <div
                                    onClick={() =>
                                      handleSetGroupConditionType(
                                        index,
                                        groupIndex,
                                        'all'
                                      )
                                    }
                                    className={mergeClasses(
                                      styles.inactiveConditionType,
                                      group.conditionType === 'all' &&
                                        styles.activeConditionType
                                    )}
                                    data-testid="button-rule-section-all"
                                  >
                                    All
                                  </div>
                                  <div
                                    className={mergeClasses(
                                      styles.inactiveConditionType,
                                      group.conditionType === 'any' &&
                                        styles.activeConditionType
                                    )}
                                    onClick={() =>
                                      handleSetGroupConditionType(
                                        index,
                                        groupIndex,
                                        'any'
                                      )
                                    }
                                    data-testid="button-rule-section-any"
                                  >
                                    Any
                                  </div>

                                  <Controller
                                    name={`${interfaceName}.${index}.groups.${groupIndex}.conditionType`}
                                    control={control}
                                    render={({ field }) => (
                                      <input
                                        {...field}
                                        type="radio"
                                        checked={!!field.value}
                                        value={field.value}
                                        style={{ display: 'none' }}
                                        disabled={disableFields}
                                      />
                                    )}
                                  />
                                </div>
                                {!disableFields && (
                                  <Icon
                                    data-testid="button-remove-rule"
                                    iconName="Delete24Regular"
                                    className={mergeClasses(
                                      styles.deleteBtn,

                                      rule.groups.length === 1 &&
                                        !allowDeleteAllGroups &&
                                        styles.hidden
                                    )}
                                    onClick={() =>
                                      removeGroupFromRole(
                                        index,
                                        groupIndex,
                                        rule.groups,
                                        rule
                                      )
                                    }
                                  />
                                )}
                              </div>

                              {group.conditions &&
                                group.conditions.map(
                                  (condition, conditionIndex) => (
                                    <>
                                      <div
                                        className={styles.conditionContainer}
                                      >
                                        <div
                                          className={styles.conditionSettings}
                                        >
                                          <Typography className={styles.text}>
                                            ==
                                          </Typography>

                                          <Typography className={styles.text}>
                                            {conditionIndex + 1}
                                          </Typography>

                                          <div className={styles.row}>
                                            <Controller
                                              control={control}
                                              name={`${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`}
                                              render={({
                                                field,
                                                fieldState: { error },
                                              }) => {
                                                return (
                                                  <InHouseInputSelect
                                                    options={sortedFields.map(
                                                      (field) => {
                                                        const usedConditions: ICondition[] =
                                                          getValues(
                                                            `${interfaceName}.${index}.groups.${groupIndex}.conditions`
                                                          );
                                                        const isDisabled =
                                                          usedConditions.some(
                                                            (condition) =>
                                                              condition.fact ===
                                                              field.value
                                                          );

                                                        return {
                                                          ...field,
                                                          disabled: isDisabled,
                                                        };
                                                      }
                                                    )}
                                                    label={''}
                                                    placeholder={
                                                      LocalizationString.FIELD
                                                    }
                                                    dataTestId="dropdown-filter-name"
                                                    selectedOptions={returnSelectedOptionFromOptions(
                                                      sortedFields,
                                                      field.value
                                                    )}
                                                    onOptionSelect={(
                                                      _ev,
                                                      data
                                                    ) => {
                                                      field.onChange(
                                                        data.optionValue
                                                      );
                                                      handleSetOperatorOptions(
                                                        index,
                                                        groupIndex,
                                                        conditionIndex,
                                                        fields.find(
                                                          ({ value }) =>
                                                            value ===
                                                            data.optionValue
                                                        )?.dataType
                                                      );
                                                      setValue(
                                                        `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.operator`,
                                                        undefined
                                                      );
                                                      setValue(
                                                        `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.value`,
                                                        ''
                                                      );
                                                    }}
                                                    errorMessage={
                                                      error?.message
                                                    }
                                                    disabled={disableFields}
                                                  />
                                                );
                                              }}
                                            />

                                            <Controller
                                              control={control}
                                              name={`${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.operator`}
                                              render={({
                                                field,
                                                fieldState: { error },
                                              }) => (
                                                <InHouseInputSelect
                                                  options={
                                                    operatorOptions.find(
                                                      (option) =>
                                                        option.ruleIndex ===
                                                          index &&
                                                        option.groupIndex ===
                                                          groupIndex &&
                                                        option.conditionIndex ===
                                                          conditionIndex
                                                    )?.options ?? []
                                                  }
                                                  disabled={
                                                    !getValues(
                                                      `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                                                    ) || disableFields
                                                  }
                                                  selectedOptions={returnSelectedOptionFromOptions(
                                                    operatorOptions.find(
                                                      (option) =>
                                                        option.ruleIndex ===
                                                          index &&
                                                        option.groupIndex ===
                                                          groupIndex &&
                                                        option.conditionIndex ===
                                                          conditionIndex
                                                    )?.options ?? [],
                                                    getValues(
                                                      `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.operator`
                                                    )
                                                  )}
                                                  label={''}
                                                  placeholder={
                                                    LocalizationString.CONDITION
                                                  }
                                                  dataTestId="dropdown-condition"
                                                  onOptionSelect={(
                                                    _ev,
                                                    data
                                                  ) => {
                                                    field.onChange(
                                                      data.optionValue
                                                    );

                                                    setValue(
                                                      `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.value`,
                                                      undefined
                                                    );

                                                    methods.clearErrors(
                                                      `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                                                    );
                                                  }}
                                                  errorMessage={error?.message}
                                                />
                                              )}
                                            />

                                            {watch(
                                              `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.operator`
                                            ) && (
                                              <InputPerDataType
                                                filterableColumns={
                                                  filterableColumns
                                                }
                                                interfaceName={interfaceName}
                                                getInputTypeFromColumnAndCondition={
                                                  getInputTypeFromColumnAndCondition
                                                }
                                                allObjectOptions={
                                                  allObjectOptions
                                                }
                                                fetchAllObjects={
                                                  fetchAllObjects
                                                }
                                                isLoadingObject={
                                                  isLoadingObject
                                                }
                                                dataType={
                                                  fields.find(
                                                    (opt) =>
                                                      opt.value ===
                                                      getValues(
                                                        `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                                                      )
                                                  )?.dataType ?? ''
                                                }
                                                relationship={
                                                  fields.find(
                                                    (opt) =>
                                                      opt.value ===
                                                      getValues(
                                                        `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                                                      )
                                                  )?.relationship
                                                }
                                                picklistName={
                                                  fields.find(
                                                    (opt) =>
                                                      opt.value ===
                                                      getValues(
                                                        `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                                                      )
                                                  )?.picklistName
                                                }
                                                methods={methods}
                                                groupIndex={groupIndex}
                                                conditionIndex={conditionIndex}
                                                ruleIndex={index}
                                                disableFields={disableFields}
                                                attributeDefinitionOfColumns={
                                                  attributeDefinitionOfColumns
                                                }
                                              />
                                            )}
                                          </div>

                                          {!disableFields && (
                                            <IconButton
                                              data-testid="button-add-condition"
                                              icon={
                                                <Icon
                                                  iconName="AddCircle24Regular"
                                                  className={mergeClasses(
                                                    styles.addConditionIcon,
                                                    styles.hidden,
                                                    conditionIndex + 1 ===
                                                      group.conditions.length &&
                                                      conditionIndex + 1 <
                                                        sortedFields.length &&
                                                      styles.isVisibile
                                                  )}
                                                  onClick={() =>
                                                    addConditionToGroup(
                                                      index,
                                                      groupIndex,
                                                      group
                                                    )
                                                  }
                                                />
                                              }
                                            />
                                          )}

                                          {!disableFields && (
                                            <IconButton
                                              className={mergeClasses(
                                                group.conditions.length === 1 &&
                                                  styles.hidden
                                              )}
                                              icon={
                                                <Icon
                                                  iconName="Delete24Regular"
                                                  className={mergeClasses(
                                                    styles.deleteBtn
                                                  )}
                                                  onClick={() => {
                                                    removeConditionFromGroup(
                                                      index,
                                                      groupIndex,
                                                      conditionIndex,
                                                      group.conditions
                                                    );

                                                    handleSetOperatorOptions(
                                                      index,
                                                      groupIndex,
                                                      conditionIndex,
                                                      fields.find(
                                                        ({ value }) =>
                                                          value ===
                                                          getValues(
                                                            `${interfaceName}.${index}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                                                          )
                                                      )?.dataType
                                                    );
                                                  }}
                                                />
                                              }
                                            />
                                          )}
                                        </div>
                                      </div>
                                      {group.conditions.length > 1 &&
                                        group.conditions.length !==
                                          conditionIndex + 1 && (
                                          <Divider alignContent="start">
                                            {group.conditionType === 'all'
                                              ? 'AND'
                                              : 'OR'}
                                          </Divider>
                                        )}
                                    </>
                                  )
                                )}
                            </div>
                            {rule.groups &&
                              rule.groups.length > 1 &&
                              rule.groups.length !== groupIndex + 1 && (
                                <Divider alignContent="start">
                                  {rule.conditionType === 'all' ? 'AND' : 'OR'}
                                </Divider>
                              )}
                          </>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                {rule.groups?.length > 0 && !hideActionButtons && (
                  <div
                    className={mergeClasses(
                      styles.addGroupBtn,
                      isCompact && styles.isBtnCompact
                    )}
                  >
                    <CustomButton
                      leftIcon="Flow"
                      buttonTitle="Add Group"
                      buttonType={ButtonTypes.Ghost}
                      onClick={() => addGroupToRule(index, rule.groups)}
                    />

                    {submitButtonTitle && (
                      <CustomButton
                        buttonTitle={submitButtonTitle}
                        type={submitButtonHandler ? undefined : 'submit'}
                        buttonType={ButtonTypes.Primary}
                        onClick={submitButtonHandler ?? undefined}
                      />
                    )}
                  </div>
                )}
              </>
            )
          );
        })}
      </div>

      <ConfirmDialog
        open={isDeleteDialogOpen}
        title={LocalizationString.DELETE}
        description={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE}
        iconSrc={DeleteIcon}
        onCancelClicked={handleDeleteModal}
        onConfirmClicked={() => {
          onRemoveRule(itemToDelete!);
          handleDeleteModal();
          handleManualSelectTab(itemToDelete!);
        }}
        primaryButtonText="Yes"
        secondaryButtonText="Cancel"
      />
    </>
  );
};
