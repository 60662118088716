import {
  ApiMethodType,
  ObjectEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { FilterCriteria } from '@celito.clients/types';
import { AxiosResponse } from 'axios';
import Endpoints from 'libs/core/src/config/endpoint';

import { TFieldValues } from '../document-type/screens/edit/controller';
import { TDataTypesResponse } from './types';

const objectName = ObjectEnum.DOCUMENT_SUB_TYPE;

interface GetObjectDataOptions {
  filters?: FilterCriteria[];
}

export class DocumentSubTypeService {
  data: TFieldValues;
  constructor(data: TFieldValues) {
    this.data = data;
  }

  static readonly getDocumentSubType = async (search: string) => {
    const response = (await performRequest(
      `${Endpoints.OBJECT_DATA_URL}/${objectName}/`,
      ApiMethodType.POST,
      {
        filters: [
          {
            attribute: 'name__s',
            operator: OperatorsEnum.CONTAINS,
            value: search,
          },
        ],
      } satisfies GetObjectDataOptions,
      {
        limit: 10,
        page: 1,
        getLatestVersionOnly: true,
      }
    )) as AxiosResponse<any, never>;
    return response;
  };
  static readonly getDocumentSubTypes = async (
    documentTypeName: string,
    searchString?: string
  ) => {
    const response = (await performRequest(
      `${Endpoints.OBJECT_DATA_URL}/${objectName}/`,
      ApiMethodType.POST,
      {
        filters: [
          {
            attribute: 'document_type__a',
            operator: OperatorsEnum.EQUALS,
            value: documentTypeName,
          },
          {
            attribute: 'label__s',
            operator: OperatorsEnum.CONTAINS,
            value: searchString ?? '',
          },
        ],
      } satisfies GetObjectDataOptions,
      {
        limit: 10,
        page: 1,
        getLatestVersionOnly: true,
      }
    )) as AxiosResponse<TDataTypesResponse, never>;
    return response;
  };
}
