import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum } from '@celito.clients/enums';
import {
  getMaxWidthForColumn,
  getMinWidthForColumn,
} from '@celito.clients/list-view-engine';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';

import { EditButton } from './components/edit-button';

const columns: ColumnData[] = [
  {
    fieldName: 'documentType',
    key: 'column1',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
    name: 'Document Type',
    showTooltip: true,
  },
  {
    fieldName: 'code',
    key: 'column2',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
    name: 'Document Code',
    showTooltip: true,
  },
  {
    name: 'Document Sub Type',
    key: 'column3',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
    fieldName: 'documentSubType',
    showTooltip: true,
  },
  {
    name: 'Document Sub Type Code',
    key: 'column4',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
    fieldName: 'documentSubTypeCode',
    showTooltip: true,
  },
  {
    name: 'Is RAW File Download Allowed?',
    key: 'column5',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.YesNo),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.YesNo),
    fieldName: 'isRawFileDownloadAllowed',
    showTooltip: true,
  },
  {
    name: 'Follow Up is Required?',
    key: 'column6',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.YesNo),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.YesNo),
    fieldName: 'isFollowUpRequired',
    showTooltip: true,
  },
  {
    name: 'Is Biz Admin Review Required?',
    key: 'column7',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.YesNo),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.YesNo),
    fieldName: 'isAdminReviewRequired',
    showTooltip: true,
  },
  {
    name: 'Trainable',
    key: 'column8',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.YesNo),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.YesNo),
    fieldName: 'isTrainable',
    showTooltip: true,
  },
  {
    fieldName: 'documentTemplate',
    key: 'column9',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
    name: 'Template Name',
    showTooltip: true,
  },
  {
    fieldName: 'effectivenessPeriodInDays',
    key: 'column11',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.Number),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.Number),
    name: 'Effectiveness Period',
    showTooltip: true,
  },
  {
    fieldName: 'followUpTriggerPeriodInDays',
    key: 'column12',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.Number),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.Number),
    name: 'Follow Up Trigger Date (days)',
    showTooltip: true,
  },
  {
    fieldName: 'followUpTimePeriodInMonths',
    key: 'column13',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.Number),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.Number),
    name: 'Follow Up Frequency (in months)',
    showTooltip: true,
  },
  {
    fieldName: 'isActive',
    key: 'column10',
    minWidth: getMinWidthForColumn(AttributeTypeEnum.YesNo),
    maxWidth: getMaxWidthForColumn(AttributeTypeEnum.YesNo),
    name: 'Active',
  },
  {
    name: LocalizationString.ACTIONS,
    key: 'actions',
    maxWidth: 250,
    minWidth: 150,
    fieldName: 'action',
    onRender: (item, _, column) => {
      return (
        <div>
          {!!item?.documentType && (
            <EditButton
              item={item}
              onActivationToggle={() => {
                column?.data.refetchData?.();
              }}
            />
          )}
        </div>
      );
    },
  },
];

export const generateColumns: (
  onColumnClick: ColumnData['onColumnClick'],
  refetchData: () => void
) => ColumnData[] = (onColumnClick, refetchData) => {
  const columnsWithOnClick = columns.map((column) => {
    const isActionColumn = column.fieldName === 'action';
    return {
      ...column,
      onColumnClick,
      showSortIconWhenUnsorted: !isActionColumn,
      isSorted: false,
      isResizable: true,
      data: {
        ...column.data,
        isSortableColumn: !isActionColumn,
        refetchData,
      },
    };
  });
  return columnsWithOnClick;
};
