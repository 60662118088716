import { Loader, PDFViewer } from '@celito.clients/shared';
import { mergeClasses } from '@fluentui/react-components';

import { getStyles } from '../../styles';
import { DocumentFile } from '../../types';
import NoPreview from '../no-preview';
import { useController } from './controller';

export type TLeftPaneProps = {
  isLoading: boolean;
  noPreviewText?: boolean;
  documentURL: string;
  downloadRawDocument: () => Promise<void>;
  documentData?: DocumentFile;
};

/**
 * Left pane of the Controlled Document View Screen
 *
 * Renders the document preview if available
 */
export function LeftPane() {
  const props = useController();
  const styles = getStyles();
  const main = !props.noPreviewText ? (
    <PDFViewer url={props.documentURL} />
  ) : (
    <NoPreview downloadRawDocument={props.downloadRawDocument} />
  );
  return (
    <div className={mergeClasses(styles.ht300)}>
      <div className={styles.iFrameEditor}>
        {props.isLoading ? <Loader className={styles.loader} /> : main}
      </div>
    </div>
  );
}
