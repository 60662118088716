import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const PreUserAddStyles = makeStyles({
  container: {
    maxWidth: '100%',
    minWidth: '46rem',
    width: '30%',
    height: '35%',
    minHeight: '29.5rem',
    ...shorthands.padding(0),
  },

  modalHeader: {
    display: 'flex',
    ...shorthands.padding('0.75rem'),
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-between',
    ...shorthands.gridArea('modalHeader'),
  },

  modalBody: {
    paddingTop: '1rem',
    ...shorthands.gridArea('modalBody'),
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'stretch',
    width: '100%',
    ...shorthands.gap('1rem'),
    height: '100%',
  },

  modalFooter: {
    ...shorthands.gridArea('modalFooter'),
    gap: '1rem',
    ...shorthands.padding('0.75rem'),
    width: '100%',
    display: 'flex',
    backgroundColor: Color.drawer_header_color,
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
    justifyContent: 'space-between',
    ...shorthands.borderRadius(0, 0, '8px', '8px'),
  },

  rowForm: {
    display: 'flex',

    width: '100%',
    alignItems: 'center',

    ...shorthands.gap('1rem'),
    ...shorthands.padding(0, '1rem'),

    '> *': {
      ...shorthands.flex(1),
    },
  },

  loadingContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  formContainer: {
    height: '100%',
    display: 'grid',
    boxSizing: 'border-box',
    gridAutoRows: '1fr',
    gridTemplateColumns: '1fr',
    gridTemplateRows: '4rem auto 4rem',
    gridTemplateAreas: `
      'modalHeader'
      'modalBody'
      'modalFooter'`,
  },
});
