import { ApiMethodType } from '@celito.clients/enums';
import {
  SortConfig,
  TemporaryUnifyDefaultAndUserFilters,
} from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';
import {
  AssignmentData,
  PaginatedData,
} from 'libs/shared/src/lib/assignments-list/src/types';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { defaultSortConfig, userViewDefaultFilter } from '../config';

export const getUserViewData = async (
  paginationData: {
    pageLimit: number;
    page: number;
  },
  configData: {
    filters: RulesComponentListViewFilter;
    sortConfig?: SortConfig;
  },
  signal?: AbortSignal
) => {
  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    userViewDefaultFilter,
    configData.filters
  );

  const response = await performRequest<PaginatedData<AssignmentData>>(
    `${Endpoints.ASSIGNMENTS_URL}/company`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: configData.sortConfig ?? defaultSortConfig,
    },
    {
      limit: paginationData.pageLimit,
      page: paginationData.page,
    },
    undefined,
    signal
  );
  return response.data;
};
