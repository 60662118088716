import { LocalizationString } from '@celito.clients/assets';
import {
  DateFormat,
  DocumentCompletionStatusEnum,
  DocumentDownloadActionEnum,
  FileMimeType,
} from '@celito.clients/enums';
import { getDocumentData } from '@celito.clients/services';
import { FileBuffer } from '@celito.clients/types';
import { errorToast, formatDate, raiseErrorToast } from '@celito.clients/utils';
import { useEffect, useMemo, useState } from 'react';

import { Loader } from '../../shared';
import { mimeTypesForDocumentViewer } from './constants';
import { DocumentViewerProps } from './document-viewer.model';
import classes from './document-viewer.module.css';
import { DocumentViewerView } from './document-viewer.view';
import { getDocumentCompletionStatus } from './services';
import { formatSize, generatePdfUrl } from './utils';

const getUrlFrombuffer = async (fileBuffer: FileBuffer, fileType: string) => {
  try {
    const uint8Array = new Uint8Array(fileBuffer?.data);
    const blob = new Blob([uint8Array], { type: fileType });
    let url = URL.createObjectURL(blob);

    if (fileType === FileMimeType.PDF) {
      url = url + '#view=fitH&pagemode=none&toolbar=0';
    }
    return url;
  } catch (e) {
    errorToast({
      message: LocalizationString.ERROR_IN_CONVERTING_BUFFER_TO_URL_MESSAGE,
    });
    return null;
  }
};

const DOCUMENT_COMPLETION_CHECK_IN_MILLISECONDS = 10 * 1000; // 10 Seconds
// const INFO_TOAST_AUTO_HIDE_IN_MILLISECONDS = 2 * 1000; // 2 seconds

export const DocumentViewerController = ({
  hideNavigation,
  hideToolbar,
  ...props
}: DocumentViewerProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const [documentName, setDocumentName] = useState<string | null>(null);
  const [isLocalPDF, setIsLocalPDF] = useState(false);
  const [documentSizeInBytes, setDocumentSizeInBytes] = useState<string | null>(
    null
  );
  const [documentUploadDate, setDocumentUploadDate] = useState<string | null>(
    null
  );
  const [isPreviewAvailable, setIsPreviewAvailable] = useState(true);
  const [documentBuffer, setDocumentBuffer] = useState<FileBuffer | null>(null);
  const [documentMimeType, setDocumentMimeType] = useState<string | null>(null);
  const [docuemntCompletionCheckInterval, setDocumentCompletionCheckInterval] =
    useState<number | null>(null);
  const [errorInDocumentDownload, setErrrorInDocumentDownload] =
    useState(false);

  const handleDownload = () => {
    if (!documentBuffer || !documentMimeType || !documentName) {
      errorToast({ message: 'Error downloading Document' });
      return;
    }
    try {
      const uint8Array = new Uint8Array(documentBuffer?.data);
      const blob = new Blob([uint8Array], {
        type: documentMimeType,
      });
      const downloadLink = document?.createElement('a');
      downloadLink.href = URL.createObjectURL(blob);
      downloadLink.download = documentName;
      downloadLink.click();
      props.handleCompleteDocument();
    } catch (error) {
      errorToast({ message: `${error}` });
    }
  };

  const fetchDocumentData = async () => {
    setIsLoading(true);

    try {
      const resp = await getDocumentData(
        props.documentId,
        props.objectName,
        props.recordName ?? undefined,
        props.version ?? undefined,
        DocumentDownloadActionEnum.TakeTraining
      );

      setDocumentSizeInBytes(resp.sizeInBytes);
      setDocumentUploadDate(resp.uploadedAtUtc);
      setDocumentMimeType(resp.mimeType);

      if (resp?.fileUrl) {
        const { fileUrl } = resp;

        if (fileUrl.includes('scorm')) {
          // For SCORM Files
          props?.setScormFileState({
            hasScormFile: true,
          });
          setDocumentUrl(fileUrl);
          const documentCompletionInterval: number = window.setInterval(() => {
            getDocumentCompletionStatus(props.documentId)
              .then((data) => {
                if (
                  data.completionStatus ===
                  DocumentCompletionStatusEnum.COMPLETED
                ) {
                  props?.setScormFileState({
                    isScormFileComplete: true,
                  });
                  clearInterval(documentCompletionInterval);
                }
              })
              .catch((err) => {
                errorToast({
                  message: 'Error in getting document completion status',
                });
                clearInterval(documentCompletionInterval);
              });
          }, DOCUMENT_COMPLETION_CHECK_IN_MILLISECONDS);
          setDocumentCompletionCheckInterval(documentCompletionInterval);
        } else {
          // For Sharepoint and SCORM files that have file URLs
          const parts = fileUrl.split('&action=');
          const url = parts[0].concat('&action=embedview');
          setDocumentUrl(url);
          props?.setScormFileState({
            hasScormFile: false,
            isScormFileComplete: false,
          });
        }
        setIsPreviewAvailable(true);
      } else {
        const mimeType = resp.label.endsWith('.pdf')
          ? FileMimeType.PDF
          : resp.mimeType;
        // For Blob files that have file only Buffer
        if (
          mimeTypesForDocumentViewer.includes(resp.mimeType) ||
          resp.label.endsWith('.pdf')
        ) {
          const url = await getUrlFrombuffer(resp.fileBuffer, mimeType);
          setDocumentUrl(url);
          setIsLocalPDF(true);
          setIsPreviewAvailable(true);
        } else {
          setDocumentBuffer(resp.fileBuffer);
          setDocumentMimeType(resp.mimeType);
          setIsPreviewAvailable(false);
        }
      }
      setDocumentName(resp?.label);
      setErrrorInDocumentDownload(false);
    } catch (_error) {
      setDocumentUrl(null);
      setDocumentBuffer(null);
      setErrrorInDocumentDownload(true);
      setIsPreviewAvailable(false);
      raiseErrorToast(_error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.documentId) {
      fetchDocumentData();
    }
  }, [props.documentId]);

  useEffect(() => {
    if (documentUrl) props.handleCompleteDocument();
  }, [props.handleCompleteDocument, documentUrl]);

  useEffect(() => {
    return () => {
      if (docuemntCompletionCheckInterval) {
        clearInterval(docuemntCompletionCheckInterval);
      }
    };
  }, [docuemntCompletionCheckInterval]);

  const generatedDocumentUrl = useMemo(
    () =>
      documentUrl
        ? generatePdfUrl(documentUrl, {
            hideNavigation,
            hideToolbar,
          })
        : '',
    [documentUrl, hideNavigation, hideToolbar]
  );

  return isLoading || !documentUrl ? (
    <Loader className={classes.center} />
  ) : (
    <DocumentViewerView
      documentUrl={generatedDocumentUrl}
      documentName={documentName as string}
      formatedSize={formatSize(Number(documentSizeInBytes))}
      previewAvailable={isPreviewAvailable}
      handleDownload={handleDownload}
      errorInDocumentDownload={errorInDocumentDownload}
      mimeType={documentMimeType as string}
      isLocalPDF={isLocalPDF}
      uploadedDate={formatDate(documentUploadDate as string, DateFormat.Date)}
    />
  );
};
