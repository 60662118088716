import { LocalizationString, NoDataDisplay } from '@celito.clients/assets';
import { useTheme } from '@celito.clients/theme';
import { Header } from '@tanstack/react-table';
import cn from 'classnames';
import { Typography } from 'libs/shared/src/lib/shared';
import { MouseEvent } from 'react';

import { ColumnMeta } from '../../grid-view.model';
import { RowItem } from '../../types';
import TableDataRow from '../table-data-row/table-data-row';
import TableGroupedRows from '../table-grouped-rows/table-grouped-rows';
import TableHeader from '../table-header/table-header';
import { GridViewTableStyles } from './grid-view.table.styles';
import { GridViewTableUiProps } from './grid-view-table.model';
import classes from './grid-view-table.styles.module.css';

const GridViewTableUI = ({
  dataTestId,
  className,
  table,
  groupNames,
  disableDefaultSorting,
  expandedGroups,
  isLoading = false,
  expandGroupHandler,
  expandAllGroups,
  updateEmptyCells = false,
  canReorderRows,
  onRowReorder,
}: GridViewTableUiProps): JSX.Element => {
  const { cssVariables } = useTheme();

  const styles = GridViewTableStyles();

  const hasGroups = groupNames !== undefined;
  const totalVisibleColumns = table.getVisibleFlatColumns().length;
  const isEmpty = table.getRowModel().rows.length === 0;

  const tableHeaderClickHandler = (
    e: MouseEvent<HTMLElement>,
    header: Header<RowItem, unknown>
  ) => {
    if (header.id === 'expand') expandAllGroups();

    const column = header.column.columnDef.meta as ColumnMeta | undefined;
    column?.onClick?.(e);
  };

  return (
    <div
      data-testid={`grid-${isLoading ? 'not-loaded' : 'fully-loaded'}`}
      className={cn(classes.container, className, {
        isLoading,
        isEmpty,
      })}
      style={{ ...cssVariables }}
    >
      <div className={classes.tableContainer}>
        <table
          className={classes.table}
          data-testid="grid-view-with-header-row"
        >
          <thead>
            {table.getHeaderGroups().map((headerGroup, i) => (
              <tr key={headerGroup.id} data-testid="grid-header-row">
                {canReorderRows && <th className={classes.dummyHeaderCell} />}
                {headerGroup.headers.map((header, j) => (
                  <TableHeader
                    key={i + j}
                    dataTestId="grid-header-cell"
                    header={header}
                    isClientSortable={!disableDefaultSorting}
                    onClickHandler={(e) => tableHeaderClickHandler(e, header)}
                  />
                ))}
              </tr>
            ))}
          </thead>
          <tbody data-testid="grid-body-content">
            {hasGroups ? (
              <TableGroupedRows
                dataTestId="grid-body-grouped-rows"
                table={table}
                totalVisibleColumns={totalVisibleColumns}
                groups={groupNames}
                expandGroupHandler={expandGroupHandler}
                expanded={expandedGroups}
                showPlaceholderForBlankCells={updateEmptyCells}
              />
            ) : (
              <TableDataRow
                dataTestId={dataTestId}
                rows={table.getRowModel().rows}
                totalVisibleColumns={totalVisibleColumns}
                showPlaceholderForBlankCells={updateEmptyCells}
                onRowReorder={onRowReorder}
                canReorderRows={canReorderRows}
              />
            )}

            {isEmpty && (
              <tr className={classes.emptyState}>
                <td colSpan={totalVisibleColumns}>
                  <div className={styles.noDataDisplay}>
                    <img src={NoDataDisplay} alt="no-data-display" />
                    <Typography className={styles.noDataText}>
                      {LocalizationString.NO_ITEMS_TO_DISPLAY}
                    </Typography>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default GridViewTableUI;
