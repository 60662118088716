import {
  ColumnDef,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { has, head } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import { RowItem } from '../../types';
import getColumnDefs from '../../utils/column-defs/getColumnDefs';
import { GridViewTableProps } from './grid-view-table.model';
import GridViewTableUI from './grid-view-table.ui';

const GridViewTableController = ({
  dataTestId,
  className,
  items,
  columns,
  disableSelection,
  onRowSelection,
  groupNames,
  disableDefaultSorting,
  updateEmptyCells,
  canReorderRows,
  onRowReorder,
  rowSelection = {},
  enableMultiRowSelection,
  columnSizes,
  onColumnSizingChange,
}: GridViewTableProps) => {
  const [sorting, setSorting] = useState<SortingState>([]);
  const [expandedGroups, setExpandedGroups] = useState<string[]>([]);
  const [expandedSubRow, setExpandedSubRow] = useState<ExpandedState>({});

  const hasGroups = groupNames !== undefined;

  const areAllGroupsExpanded = hasGroups
    ? expandedGroups.length === groupNames.length
    : false;

  const tableCols: ColumnDef<RowItem>[] = useMemo(
    () =>
      getColumnDefs(
        columns,
        disableSelection,
        areAllGroupsExpanded,
        hasGroups,
        enableMultiRowSelection
      ),
    [
      areAllGroupsExpanded,
      columns,
      disableSelection,
      hasGroups,
      enableMultiRowSelection,
    ]
  );

  const table = useReactTable({
    data: items,
    columns: tableCols,
    columnResizeMode: 'onChange',
    state: {
      sorting: disableDefaultSorting ? undefined : sorting,
      rowSelection: disableSelection ? undefined : rowSelection,
      expanded: expandedSubRow,
      columnSizing: columnSizes,
    },
    enableRowSelection: !disableSelection,
    enableMultiRowSelection: enableMultiRowSelection ?? true,
    enableSortingRemoval: false,
    onSortingChange: disableDefaultSorting ? undefined : setSorting,
    onRowSelectionChange: disableSelection ? undefined : onRowSelection,
    onColumnSizingChange: onColumnSizingChange,
    getSubRows: (row) => row.subRow,
    onExpandedChange: setExpandedSubRow,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowId: has(head(items), 'name')
      ? (row) =>
          row.name && row.version ? `${row.name}-${row.version}` : row.name
      : undefined,
  });

  const expandGroupHandler = useCallback(
    (group: string) => {
      if (!hasGroups) return;

      setExpandedGroups((curr) => {
        if (curr.includes(group)) {
          return curr.filter((val) => val !== group);
        }
        return [...curr, group];
      });
    },
    [hasGroups]
  );

  const expandAllGroups = useCallback(() => {
    if (!hasGroups) return;

    setExpandedGroups(areAllGroupsExpanded ? [] : groupNames);
  }, [areAllGroupsExpanded, groupNames, hasGroups]);

  return (
    <GridViewTableUI
      dataTestId={dataTestId}
      className={className}
      table={table}
      disableDefaultSorting={disableDefaultSorting}
      groupNames={groupNames}
      expandGroupHandler={expandGroupHandler}
      expandedGroups={expandedGroups}
      expandAllGroups={expandAllGroups}
      updateEmptyCells={updateEmptyCells}
      canReorderRows={canReorderRows}
      onRowReorder={onRowReorder}
    />
  );
};

export default GridViewTableController;
