import { Label } from '@fluentui/react-components';

import { LabelProps } from './label.model';

interface LabelViewProps extends LabelProps {}

const LabelView = ({
  weight,
  ...rest
}: { weight?: string } & LabelViewProps): JSX.Element => {
  return <Label {...rest} weight={weight} />;
};

export default LabelView;
