import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { CourseData, TrainingAssignmentData } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

import { courseObjectName, trainingAssignmentObjectName } from '../config';

export const getTrainingAssignmentData = async (
  trainingAssignmentName: string
) => {
  const response = await performRequest<TrainingAssignmentData>(
    `${Endpoints.OBJECT_DATA_URL}/${trainingAssignmentObjectName}/${trainingAssignmentName}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const markTrainingMaterialAsCompleted = async (
  trainingAssingmentName: string
) => {
  await performRequest(
    `${Endpoints.OBJECT_DATA_URL}/${trainingAssignmentObjectName}/${trainingAssingmentName}`,
    ApiMethodType.PATCH,
    {
      is_document_complete__a: true,
    }
  );
};

export const getCourseDetails = async (
  assignmentData: TrainingAssignmentData
) => {
  const response = await performRequest<CourseData>(
    `${Endpoints.OBJECT_DATA_URL}/${courseObjectName}/${assignmentData.course.name}`,
    ApiMethodType.GET,
    undefined,
    {
      version: assignmentData.course.version,
    }
  );

  return response.data;
};

export const resetQuizAttempts = async (assignmentName: string) => {
  const response = await performRequest(
    `${Endpoints.ASSIGNMENTS_URL}/${assignmentName}/reset-quiz-attempts`,
    ApiMethodType.POST,
    {}
  );
  return response.data;
};
