import { AttributeTypeEnum } from '@celito.clients/enums';
import { ObjectMetadata } from '@celito.clients/list-view-engine';
import { OptionProps } from '@fluentui/react-components';
import { cloneDeep } from 'lodash';

import { ViewTypes } from '../enums/enums';
import { IColumns } from '../training-matrix.model';

export const getCustomAttributeDefinations = (
  view: OptionProps,
  columns: IColumns[]
) => {
  const newColumns = cloneDeep(columns);
  if (view.value !== ViewTypes.COURSE_VIEW) {
    newColumns.push({
      fieldName: 'createdAtUtc',
      dataType: AttributeTypeEnum.Date,
      key: 'createdAtUtc',
      name: 'Created Date',
      isFilterable: true,
      minWidth: 0,
    });
  }

  return {
    objectAttributeDefinitions: newColumns
      .filter((itm) => itm.isFilterable)
      .map((item) => {
        const itemDefinition = {
          key: item.key,
          name:
            item.dataType === AttributeTypeEnum.Reference
              ? item.data.name
              : item.fieldName,
          label: item.name,
          dataType: item.dataType,
          ownerType: 'System',
          columnName: item.fieldName,
          isEditable: true,
          isInternal: false,
          isAuditable: true,
          isMandatory: false,
          displayInList: true,
          picklistName: item.picklistName,
          relationship: item.relationship,
          attributeNameToFilter: item.data?.columnNameToBePicked,
        };
        return itemDefinition;
      }),
  } as unknown as ObjectMetadata;
};
