import { getConvertedDate, getZonedBasedTime } from '@celito.clients/utils';
import {
  formatDateToTimeString,
  TimePicker,
} from '@fluentui/react-timepicker-compat-preview';

import { Field } from '../../shared';
import { TimePickerComponentProps } from './time-picker.model';

interface TimePickerViewProps extends TimePickerComponentProps {}

const TimePickerView = ({
  dataTestId,
  label,
  error,
  required,
  size = 'large',
  value,
  onChange,
  dateAnchorValue,
  ...props
}: TimePickerViewProps): JSX.Element => {
  return (
    <Field
      label={label}
      required={required}
      validationMessage={error?.message}
      validationState={error?.message ? 'error' : 'none'}
      size={size}
    >
      <TimePicker
        data-testid={dataTestId}
        {...props}
        placeholder="Select a time..."
        dateAnchor={
          dateAnchorValue ? getZonedBasedTime(dateAnchorValue) : undefined
        }
        selectedTime={value ? getZonedBasedTime(value) : undefined}
        onTimeChange={(_ev, data) => {
          const selectedDate = data.selectedTime!;
          const date = getConvertedDate(selectedDate, undefined, [
            selectedDate?.getHours(),
            selectedDate?.getMinutes(),
            selectedDate?.getSeconds(),
            selectedDate?.getMilliseconds(),
          ]);
          onChange?.(date);
        }}
        value={
          value ? formatDateToTimeString(getZonedBasedTime(value)) : undefined
        }
      />
    </Field>
  );
};

export default TimePickerView;
