import { FieldValues } from 'react-hook-form';

import ControlledDatetimePickerController from './controlled-datetime-picker.controller';
import { ControlledDatetimePickerProps } from './controlled-datetime-picker.model';

const ControlledDatetimePicker = <T extends FieldValues>(
  props: ControlledDatetimePickerProps<T>
) => {
  return <ControlledDatetimePickerController {...props} />;
};

export default ControlledDatetimePicker;
