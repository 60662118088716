export enum QuestionTypes {
  //only once choice can be selected
  MULTIPLE_CHOICE = 'MultipleChoice',
  //more than one choice can be selected
  MULTIPLE_RESPONSE = 'MultipleResponse',
  BOOLEAN = 'TrueOrFalse',
  SEQUENCE = 'Sequence',
}

export interface IQuizFormData {
  questions: IQuestion[];
}

export interface IQuestion {
  questionType: string;
  isMandatory: boolean;
  isRandom: boolean;
  questionText: string;
  order: number;
  choices: IChoice[];
  feedback: string;
}

export interface IChoice {
  choiceText: string;
  isCorrect: boolean;
  order?: number;
}
