import cn from 'classnames';

import { IStackItem } from './stack-item.model';
import styles from './stack-item.module.css';

const StackItemView = ({ children, className }: IStackItem): JSX.Element => {
  return <div className={cn(styles.stackItem, className)}>{children}</div>;
};

export default StackItemView;
