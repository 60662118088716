import { ColumnDef, SortDirection } from '@tanstack/react-table';
import { MouseEvent as ReactMouseEvent } from 'react';

import { FixedColumnKeys } from '../../../../config/constants';
import { GridViewProps } from '../../../grid-view.model';
import { ColumnData, RowItem } from '../../../types';
import columnHelper from '../column-helper';

const customColumn = (
  col: ColumnData,
  colIndex: number,
  allCols: GridViewProps['columns']
): ColumnDef<RowItem, unknown> => {
  const isLastCol = colIndex + 1 === allCols.length;
  const isFixed = isLastCol && FixedColumnKeys.includes(col.key);
  let serverSortDirection: SortDirection | false = false;
  const isServerSorted = col.isSorted === true;
  if (col.isSorted) {
    serverSortDirection = col.isSortedAscending ? 'asc' : 'desc';
  }
  return columnHelper.display({
    id: col.key,
    header: col.name,
    minSize: col.minWidth,
    maxSize: col.maxWidth,
    enableResizing: col.isResizable,
    cell: ({ row, cell }) =>
      col.onRender
        ? col.onRender(cell.row.original, row.index, col)
        : undefined,
    meta: {
      columnType: 'custom',
      showTooltip: !!col.showTooltip,
      isServerSorted,
      serverSortDirection,
      showSortIconWhenUnsorted: col.showSortIconWhenUnsorted,
      onClick: (e: ReactMouseEvent<HTMLElement, MouseEvent>) =>
        col.onColumnClick ? col.onColumnClick(e, col) : undefined,
      isFixed,
    },
    ...(!col.disableAlphanumericSorting && {
      sortingFn: 'alphanumeric',
    }),
  });
};

export default customColumn;
