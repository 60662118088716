import {
  LayoutRulesConfigurationSchema,
  ObjectActionDefinition,
  ObjectAttributeType,
  WorkflowControlsDefinition,
} from '@celito.clients/types';
import { UseFormReturn } from 'react-hook-form';
import yup from 'yup';

export type ActionsHandlerComponentProps = {
  datatestId?: string;
  closeActionPopup: () => void;
  openHandler: boolean;
  attributeConfig: ObjectAttributeType;
  recordData: Record<string, any>;
  selectedAction: ObjectActionDefinition;
  controls: WorkflowControlsDefinition[];
  prePopulatedData: Record<string, unknown>;
  openActionPopup: () => void;
  setActionsToLoad: () => void;
  taskName?: string;
  users?: string[];
  currentTaskDueDate?: string;
  currentTaskTag?: string;
};
export interface ActionsHandlerViewProps extends ActionsHandlerComponentProps {
  open: boolean;
  closePopup: () => void;
  onSave: () => Promise<void>;
  methods: UseFormReturn;
  setValidationSchema: React.Dispatch<
    React.SetStateAction<
      yup.ObjectSchema<yup.AnyObject, yup.AnyObject, any, ''> | undefined
    >
  >;
  isLoading: boolean;
  allControls: Controls[];
  instruction: string;
  openConfirmationDialog: boolean;
  goBack: () => void;
  onConfirmationClick: () => void;
  updatedAttributeConfig: ObjectAttributeType;
  getButtonTitle: () => string;
  getActionTitlesAndDesc: () => { title: string; confirmationText: string };
  isDisabledButtton: boolean;
}

export type Controls = {
  columnName: string;
  row: number;
  width: number;
  layoutConfiguration: LayoutRulesConfigurationSchema;
};

export enum WorkflowControlTypeEnum {
  VARIABLE = 'VARIABLE',
  FIELD_PROMPT = 'FIELD-PROMPT',
  INSTRUCTION = 'INSTRUCTION',
}

export type RequestPayload = {
  [key: string]: unknown;
};
