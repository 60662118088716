import { LocalizationString } from '@celito.clients/assets';
import { useCloseOnScroll } from '@celito.clients/hooks';
import {
  makeStyles,
  Menu,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  shorthands,
} from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { useState } from 'react';

import { ExportItem } from '../../types';
import { getExportBtnMenuConfig } from '../../utils';
import { actionColumnListViewStyles } from '../action-column';

export const useExportBtnStyles = makeStyles({
  root: {
    backgroundColor: Color.background_primary_blue,
    color: Color.white,
    display: 'flex',
    height: '40px',
    minWidth: '104px',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '0px',
    marginLeft: '10px',
    fontSize: '1rem',
    ...shorthands.border('none'),

    ':hover': {
      backgroundColor: Color.blue_800,
      color: Color.white,
    },

    ':hover:active': {
      backgroundColor: Color.blue_900,
      color: Color.white,
    },
  },
});

interface ExportButtonProps {
  onClick: (item: ExportItem) => void;
  cssObject: object;
  customStyles?: string;
}

export const ExportButton: React.FC<ExportButtonProps> = ({
  onClick,
  cssObject,
  customStyles,
}) => {
  const classes = useExportBtnStyles();
  const actionColumnStyles = actionColumnListViewStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  useCloseOnScroll(setMenuOpen);

  return (
    <Menu
      closeOnScroll
      open={menuOpen}
      onOpenChange={(_, data) => setMenuOpen(data.open)}
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          className={mergeClasses(classes.root, customStyles)}
          data-testid={`button-${LocalizationString.EXPORT.toLowerCase()}`}
        >
          {LocalizationString.EXPORT}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover className={actionColumnStyles.menuPopover}>
        <MenuList className={actionColumnStyles.menuList}>
          {getExportBtnMenuConfig(cssObject, onClick)}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
