import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { ControlledDocumentRecordUserActionsResponse } from './apiService.model';

export const getRecordUserActions = async (
  objectName: string,
  recordName: string,
  version: string
) => {
  const response =
    await performRequest<ControlledDocumentRecordUserActionsResponse>(
      `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
      ApiMethodType.GET,
      null,
      {
        version,
        view_type: 'list',
      }
    );
  return response.data;
};
