import { WizardContainerComponentProps } from './wizard-container.model';
import WizardContainerView from './wizard-container.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const WizardContainerController = (
  props: WizardContainerComponentProps
) => {
  return <WizardContainerView {...props} />;
};
