import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useActiveModule, useLayout } from '@celito.clients/hooks';
import { getRecordDetailApi } from '@celito.clients/services';
import { raiseErrorToast } from '@celito.clients/utils';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { SingleLayoutContainerProps } from '../single-layout-container.model';
import ViewLayoutView from './view.view';

export const ViewLayoutController = (props: SingleLayoutContainerProps) => {
  const {
    sections = [],
    objectName,
    recordName,
    attributeConfig,
    goToEditScreen,
  } = props;

  const { configureLayout } = useLayout();
  const navigate = useNavigate();
  const activeModule = useActiveModule();

  const [recordDetails, setRecordDetails] = useState<Record<string, unknown>>(
    {}
  );
  const [isLoadingRecord, setIsLoadingRecord] = useState(true);

  const onRouteBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate(activeModule?.link || '/');
    }
  };

  const callRecordDetailApi = async (
    objectName: string,
    recordName: string
  ) => {
    try {
      setIsLoadingRecord(true);
      const response = await getRecordDetailApi(objectName, recordName, {
        view_type: FormEngineModeTypeEnum.View,
      });
      if (response?.title)
        configureLayout({
          pageTitle: '',
          enablePadding: false,
          headerTitle: (response?.title as string) || '',
        });

      setRecordDetails(response);
      props?.setRecordData?.(response);
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsLoadingRecord(false);
    }
  };

  useEffect(() => {
    if (objectName && recordName) callRecordDetailApi(objectName, recordName);
    configureLayout({
      pageTitle: '',
      enablePadding: false,
      headerTitle: '',
      showHeadingLoader: true,
    });
  }, []);

  return (
    <ViewLayoutView
      {...props}
      {...{
        onCancel: onRouteBack,
        goToEditScreen,
        sections,
        mode: props.mode,
        recordDetails,
        attributeConfig,
        isLoadingRecord,
        callRecordDetailApi,
      }}
    />
  );
};
