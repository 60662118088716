import { makeStyles, shorthands } from '@fluentui/react-components';

export const userModuleStyles = makeStyles({
  dropdownStyles: {
    width: '100%',
    ...shorthands.borderRadius('6px'),
  },

  loader: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
});
