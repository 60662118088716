import {
  BellIcon,
  CheckIcon,
  ControlledDocumentIcon,
  CourseIcon,
  CurriculumIcon,
  DelegationIcon,
  DeleteIconStrong,
  MaterialIcon,
  OkIcon,
  QuizIcon,
  UserIcon,
  WarningIcon,
} from '@celito.clients/assets';

const pngIconNameMap: Record<string, string> = {
  bell_icon: BellIcon,
  check_icon: CheckIcon,
  delegation_icon: DelegationIcon,
  okay_icon: OkIcon,
  warning_icon: WarningIcon,
  training_material_icon: MaterialIcon,
  training_plan_icon: UserIcon,
  quiz_icon_name: QuizIcon,
  controlled_document_icon: ControlledDocumentIcon,
  DeleteStrong: DeleteIconStrong,
  course_tab_icon: CourseIcon,
  curriculum_tab_icon: CurriculumIcon,
};

export default pngIconNameMap;
