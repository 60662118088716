import { RoleType } from '../types/permission-types';

class RolesContextManager {
  private rolesContext: RoleType[] | null;
  private static instance: RolesContextManager;

  constructor() {
    this.rolesContext = null;
  }

  static getInstance() {
    if (!RolesContextManager.instance) {
      RolesContextManager.instance = new RolesContextManager();
    }

    return RolesContextManager.instance;
  }

  setRoles(roles: RoleType[]) {
    this.rolesContext = roles;
  }

  getRoles() {
    return this.rolesContext;
  }

  getRoleByName(roleName: string) {
    return this.rolesContext?.find((role) => role.name === roleName);
  }

  getRolesByNames(roleNames: string[]) {
    return roleNames.reduce((acc, roleName) => {
      const role = this.getRoleByName(roleName);
      if (role) {
        acc[roleName] = role;
      }
      return acc;
    }, {} as Record<string, RoleType>);
  }
}

export default RolesContextManager;
