import { HeaderViewProps } from '../header.view';

type PageTitleProps = Pick<
  HeaderViewProps,
  | 'pageHeading'
  | 'showHomeTitle'
  | 'activeNavItem'
  | 'activeSubNavItem'
  | 'initTitleAsLoading'
>;

const getPageTitle = (
  {
    pageHeading,
    showHomeTitle,
    activeNavItem,
    activeSubNavItem,
    initTitleAsLoading = false,
  }: PageTitleProps,
  isInitialLoad: boolean
): string => {
  if (initTitleAsLoading && isInitialLoad) return '';

  if (pageHeading) return pageHeading;
  if (showHomeTitle) return 'Home';

  return activeSubNavItem?.pageName ?? activeNavItem?.pageName ?? '';
};

export default getPageTitle;
