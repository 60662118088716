import { forwardRef, useEffect, useState } from 'react';

import { PreviewPanelProps } from './preview-panel.model';
import { PreviewPanelView } from './preview-panel.view';

interface PreviewPanelControllerProps extends PreviewPanelProps {}

export const PreviewPanelController = forwardRef<
  HTMLDivElement,
  PreviewPanelControllerProps
>((props, ref) => {
  const [isPanelOpen, setIsPanelOpen] = useState(
    props.isPanelInitiallyOpen ?? true
  );

  useEffect(() => {
    setIsPanelOpen(props.isPanelInitiallyOpen ?? isPanelOpen);
  }, [props.isPanelInitiallyOpen]);

  const closePanel = () => {
    setIsPanelOpen(false);
  };

  const openPanel = () => {
    if (props.handleCollapseAll) {
      props.handleCollapseAll();
    }
    setIsPanelOpen(true);
  };

  return (
    <PreviewPanelView
      isPanelOpen={isPanelOpen}
      closePanel={closePanel}
      openPanel={openPanel}
      {...props}
      ref={ref}
    />
  );
});
