import { DocData } from '@celito.clients/types';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';

import { DownloadFileBtn } from '../components/download-file-btn';

export const getUploadedFilesViewColumns = (
  docData: DocData | undefined,
  handleDownload: () => void
): ColumnData[] => {
  return [
    {
      name: 'Name',
      isResizable: true,
      key: 'column1',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'name',
      onRender: (item, index, column) =>
        DownloadFileBtn({
          item,
          index,
          column,
          docData,
          handleDownload,
        }),
    },
    {
      name: 'Uploaded Date',
      isResizable: true,
      key: 'column2',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'uploadDate',
      disableAlphanumericSorting: true,
    },
    {
      name: 'Uploaded By',
      isResizable: true,
      key: 'column3',
      maxWidth: 250,
      minWidth: 150,
      fieldName: 'uploadBy',
    },
    {
      name: 'Training Completion Date',
      isResizable: true,
      key: 'column5',
      maxWidth: 350,
      minWidth: 150,
      fieldName: 'completionDate',
      disableAlphanumericSorting: true,
    },
  ];
};
