import { FormEngineModeTypeEnum, ROUTES } from '@celito.clients/enums';
import { Navigate, useParams } from 'react-router';

import { UserLearningController } from './user-learning.controller';
import { UserLearningProps } from './user-learning.model';

const UserLearningScreen: React.FC<UserLearningProps> = () => {
  const { viewName, mode, recordName } = useParams();

  if (!viewName) {
    return <Navigate to={`../${ROUTES.PAGE_NOT_FOUND}`} />;
  }
  return (
    <UserLearningController
      viewName={viewName}
      mode={mode as FormEngineModeTypeEnum}
      recordName={recordName}
    />
  );
};

export default UserLearningScreen;
