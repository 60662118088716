import { Combobox, Icon } from '@celito.clients/shared';
import { ZIndex } from '@celito.clients/theme';
import { Option, useId } from '@fluentui/react-components';
import { useEffect, useState } from 'react';

import ResultList from './components/ResultList';
import SearchBanner from './components/SearchBanner';
import { createSuggestionMenuStyle } from './suggestion-menu.modal.style';
import { SuggestionMenuViewProps } from './suggestion-menu.model';

const SuggestionMenuView: React.FC<SuggestionMenuViewProps> = (props) => {
  const {
    placeholder,
    searchInput,
    suggestions,
    topResults,
    onInputChangeHandler,
    onKeydownHandler,
    isIcon,
    selectedOption,
    onOptionSelectHandler,
    onTopResultClickHandler,
  } = props;

  const [open, setOpen] = useState(false);

  const styles = createSuggestionMenuStyle();
  const comboId = useId('combo-default');

  useEffect(() => {
    if (!open) return;
    const listbox = document.querySelector('div[role="listbox"].fui-Listbox');
    if (!(listbox instanceof HTMLElement)) return;
    listbox.style.zIndex = String(ZIndex.Dropdown);
  }, [open]);

  return (
    <Combobox
      freeform
      open={open}
      onOpenChange={(_, { open }) => setOpen(open)}
      aria-labelledby={comboId}
      expandIcon={
        isIcon ? (
          <Icon
            iconName="Search16Regular"
            onClick={() => onKeydownHandler(searchInput)}
          />
        ) : null
      }
      autoFocus
      placeholder={placeholder}
      className={styles.searchBar}
      value={searchInput}
      onChange={(event) => onInputChangeHandler(event.target.value)}
      onOptionSelect={(event, data) => onOptionSelectHandler(data)}
      selectedOptions={selectedOption}
    >
      {searchInput.length < 3 && (
        <div className={styles.searchBanner}>
          <b>Please enter atleast 3 characters</b>
        </div>
      )}
      {searchInput.length > 2 && (
        <Option
          text={searchInput}
          checkIcon={null}
          className={styles.searchBannerOption}
        >
          <SearchBanner value={searchInput} />
        </Option>
      )}
      {suggestions.length > 0 && (
        <ResultList type="Suggestions" result={suggestions} />
      )}
      {topResults.length > 0 && (
        <ResultList
          type="Top Results"
          result={topResults}
          onTopResultClickHandler={onTopResultClickHandler}
          onViewAllClickHandler={onKeydownHandler}
        />
      )}
    </Combobox>
  );
};

export default SuggestionMenuView;
