import { Color } from './colors';

export type ThemeType = 'dark' | 'light';

export const ThemeColors = {
  light: {
    primary: Color.blue_700,
    btn_secondary: Color.blue_1300,
    btn_hover: Color.blue_1100,
    btn_shover: Color.blue_1500,
    btn_pressed: Color.blue_1200,
    btn_spressed: Color.blue_1600,
    btn_text: Color.white,
    btn_text2: Color.blue_700,
    btn_disabled: Color.gray_100,
    secondary: Color.black,
    background: Color.white,
    footer_label: Color.white,
    text: Color.blue_700,
    border: Color.gray_300,
    ...Color,
  },
  dark: {
    primary: Color.white,
    btn_secondary: Color.gray_1000,
    btn_hover: Color.white,
    btn_shover: Color.gray_1100,
    btn_pressed: Color.gray_900,
    btn_spressed: Color.gray_1200,
    btn_text: Color.blue_700,
    btn_text2: Color.white,
    btn_disabled: Color.gray_100,
    secondary: Color.white,
    background: Color.blue_700,
    footer_label: Color.blue_700,
    text: Color.white,
    border: Color.gray_300,
    ...Color,
  },
} as const;
