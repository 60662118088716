import { AttributeTypeEnum, ObjectEnum } from '@celito.clients/enums';
import { OwnerType } from '@celito.clients/types';

export const fileUploadConfig = {
  name: 'file__a',
  label: 'Training Material',
  dataType: AttributeTypeEnum.Document,
  isEditable: true,
  isMandatory: true,
  rules: [
    {
      type: 'required',
      errorMessage: 'File is required',
    },
  ],
  ownerType: OwnerType.System,
  key: 'trainingMaterial',
  columnName: 'trainingMaterial',
  displayInList: true,
  isInternal: false,
  isAuditable: true,
  isCoAuthoringEnabled: false,
  isVersioningEnabled: false,
  maxFileSizeInBytes: 1073741824,
  allowedExtensions: [
    '.pdf',
    '.docx',
    '.doc',
    '.xlsx',
    '.pptx',
    '.jpg',
    '.jpeg',
    '.png',
    '.xls',
    '.odp',
    '.ods',
    '.odt',
    '.zip',
    '.mp4',
    '.mp3',
  ],
};

export const trainingAssignmentObjectName = 'training_assignment__a';
export const trainingMaterialObjectName = 'training_material__a';
export const trainingPlanObjectName = 'training_plan__a';
export const courseObjectName = ObjectEnum.COURSE;
