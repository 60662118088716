import { useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import { ObjectEnum } from '../../enums/objects';
import { editFormSubmitApi } from '../../services/wizard-service';
import { ObjectAttributeType } from '../../types/object-attribute-types';
import { raiseErrorToast } from '../../utils/toast-util';
import { useObjectDefinition } from '../useObject/useObjectDefinition';
import { getInitiationTypeByObject, transformToPayload } from './utils';

const useUpdateInitiationConfirmationModal = ({
  objectName,
}: {
  objectName: ObjectEnum;
}) => {
  const [
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
  ] = useState(false);
  const [selectedRecordDetails, setSelectedRecordDetails] =
    useState<Record<string, any>>();
  const [loading, setLoading] = useState(false);
  const editViewNameRef = useRef<string>();

  const navigate = useNavigate();
  const { data: objectDefinition } = useObjectDefinition({
    objectName,
  });

  const handleOnUpdateInitiate = (
    selectedRecord: Record<string, unknown>,
    editViewName: string
  ) => {
    setSelectedRecordDetails(selectedRecord);
    editViewNameRef.current = editViewName;
    setShowUpdateInititationConfirmationModal(true);
  };

  const handleOnUpdateInitiationConfirmation = async () => {
    setLoading(true);

    const initiationType = getInitiationTypeByObject(objectName);

    const payload = transformToPayload(
      {
        ...selectedRecordDetails,
        initiationType, // initiationType should always be Update type
      },
      objectDefinition as ObjectAttributeType
    );

    try {
      const response = await editFormSubmitApi(
        selectedRecordDetails?.objectDefinitionName as string,
        selectedRecordDetails?.name as string,
        payload,
        {
          skipVersionUpgrade: true,
          action: 'update',
          version: selectedRecordDetails?.version as string,
        }
      );

      navigate(
        `../edit/${editViewNameRef.current}/${response?.record?.name}?requestType=${initiationType}&version=${response?.record?.version}`
      );
    } catch (_error) {
      raiseErrorToast(_error);
      setLoading(false);
      setShowUpdateInititationConfirmationModal(false);
    } finally {
      editViewNameRef.current = undefined;
    }
  };

  return {
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
    selectedRecordDetails,
    handleOnUpdateInitiate,
    handleOnUpdateInitiationConfirmation,
    loading,
  };
};

export default useUpdateInitiationConfirmationModal;
