import { useConfigureLayout } from '@celito.clients/hooks';

import { ContainerComponentProps } from './container.model';
import ContainerView from './container.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ContainerController = (props: ContainerComponentProps) => {
  useConfigureLayout({
    pageTitle: props.name ?? props.title ?? '',
    showBackButton: props.showBackBtn,
    navigateBackRoute: props.navigateBackRoute,
  });

  return <ContainerView {...props} />;
};
