import { FieldValues } from 'react-hook-form';

import { ControlledPicklistProps } from './controlled-picklist.model';
import { ControlledPicklistView } from './controlled-picklist.view';

interface ControlledPicklistControllerProps<T extends FieldValues>
  extends ControlledPicklistProps<T> {}

export const ControlledPicklistController = <T extends FieldValues>(
  props: ControlledPicklistControllerProps<T>
): JSX.Element => {
  return <ControlledPicklistView {...props} />;
};
