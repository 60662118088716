import { Option } from '@fluentui/react-components';

import { createSuggestionMenuStyle } from '../suggestion-menu.modal.style';
import { ResultListProps } from '../suggestion-menu.model';
import { getTopResultCardHeading } from '../utils/suggestion-menu.utils';
import TopResultCard from './TopResultCard';

const ResultList: React.FC<ResultListProps> = (props) => {
  const { type, onTopResultClickHandler, result, onViewAllClickHandler } =
    props;

  const styles = createSuggestionMenuStyle();

  return (
    <div className={styles.container}>
      <div className={styles.labelSection}>
        <div className={styles.label}>{type}</div>
        {onViewAllClickHandler && (
          <div
            className={styles.viewAllOption}
            role="button"
            onClick={() => onViewAllClickHandler()}
          >
            {'View All'}
          </div>
        )}
      </div>
      <div className={styles.list}>
        {type === 'Suggestions' &&
          result.map((suggestion: any) => (
            <Option checkIcon={null}>{suggestion}</Option>
          ))}
        {onTopResultClickHandler &&
          result.map((topResult: any) => (
            <Option
              text={`${getTopResultCardHeading(topResult)}`}
              checkIcon={null}
              className={styles.card}
              value={`topResult|${topResult.detailViewName}|${topResult.name}`}
            >
              <TopResultCard
                result={topResult}
                onTopResultClickHandler={onTopResultClickHandler}
              />
            </Option>
          ))}
      </div>
    </div>
  );
};

export default ResultList;
