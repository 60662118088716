import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import {
  Task,
  WorkflowControlsDefinition,
  WorkflowDefinition,
} from '@celito.clients/types';

import { TaskActions } from './workflow-actions.model';

export const getActionsData = async (taskName: string) => {
  const response = await performRequest<{ taskActions: TaskActions[] }>(
    `/task/${taskName}/task-actions`,
    ApiMethodType.GET
  );
  return response.data.taskActions;
};

export const getWorkflowHistory = async (
  objectName: string,
  recordName: string,
  recordVersion: string
) => {
  const response = await performRequest<{ tasks: Task[] }>(
    recordVersion
      ? `/task/object/${objectName}/record/${recordName}/workflow-history?version=${recordVersion}`
      : `/task/object/${objectName}/record/${recordName}/workflow-history`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getWorkflowControls = async (
  actionName: string,
  recordName: string,
  recordVersion: string
) => {
  const response = await performRequest<WorkflowControlsDefinition[]>(
    `/workflows/${actionName}/controls?recordName=${recordName}&recordVersion=${recordVersion}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getTaskControls = async (
  recordName: string,
  recordVersion: string
) => {
  const response = await performRequest<WorkflowDefinition[]>(
    `/workflow-definitions/record-active-definitions/${recordName}/${recordVersion}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const getUpdateWorkflowControls = async (
  recordName: string,
  recordVersion: string
) => {
  const response = await performRequest<WorkflowControlsDefinition[]>(
    `/workflows/workflow-update/controls?recordName=${recordName}&recordVersion=${recordVersion}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const executeWorkflow = async (
  actionName: string,
  recordName: string,
  recordVersion: string,
  payload: object
) => {
  const response = await performRequest<{
    actions: WorkflowControlsDefinition[];
  }>(
    `/workflows/${actionName}/execute?recordName=${recordName}&recordVersion=${recordVersion}`,
    ApiMethodType.POST,
    payload
  );
  return response.data;
};

export const executeUpdateWorkflow = async (
  recordName: string,
  recordVersion: string,
  payload: object
) => {
  const response = await performRequest(
    `/workflows/execution/controls?recordName=${recordName}&recordVersion=${recordVersion}`,
    ApiMethodType.PUT,
    payload
  );
  return response.data;
};

export const getPrePopulatedValue = async (
  recordName: string,
  recordVersion: string
) => {
  const response = await performRequest<Record<string, unknown>>(
    `/workflows/execution/controls?recordName=${recordName}&recordVersion=${recordVersion}`,
    ApiMethodType.GET
  );
  return response.data;
};

export const updateAddParticipantField = async (
  taskName: string,
  payload: object
) => {
  const response = await performRequest<Record<string, any>>(
    `/task/${taskName}/participants`,
    ApiMethodType.POST,
    payload
  );
  return response.data.tasks;
};

export const updateTaskActionFields = async (
  taskName: string,
  payload: object
) => {
  const response = await performRequest<Record<string, any>>(
    `/task/${taskName}`,
    ApiMethodType.PATCH,
    payload
  );
  return response.data.task;
};
