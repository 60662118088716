import { IconProps } from './icon.component';
import IconView from './icon.view';

interface IconControllerProps extends IconProps {}

const IconController = (props: IconControllerProps): JSX.Element => {
  return <IconView {...props} key={props.iconName} />;
};

export default IconController;
