import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { RulesComponentListViewFilter } from '../../rules-component/types/rules-component.types';

export interface ICommonData {
  name: string;
  label: string;
  [key: string]: any;
}

interface IData {
  limit: number;
  page: number;
  total: number;
  data: ICommonData[];
}

export const getSelectedOptions = async (
  objectName: string,
  filters: RulesComponentListViewFilter,
  fetchSelectedOptions?: boolean
) => {
  if (!fetchSelectedOptions) return {} as IData;

  const response = await performRequest<IData>(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}`,
    ApiMethodType.POST,
    {
      filters: [filters],
    }
  );

  return response.data;
};
