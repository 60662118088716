import { LocalizationString } from '@celito.clients/assets';
import { useConfigureLayout } from '@celito.clients/hooks';
import {
  errorToast,
  getULHomePageUrl,
  raiseErrorToast,
} from '@celito.clients/utils';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { ReferenceSelectorItem } from 'libs/shared/src/lib/reference-selector/src/reference-selector.model';
import { SelectorItem } from 'libs/shared/src/lib/selector/src/selector.model';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import {
  AddOneTimeAssignmentProps,
  AssignmentPayload,
  ErrorConfig,
  User,
} from './add-one-time-assignment.model';
import { AddOneTimeAssignmentView } from './add-one-time-assignment.view';
import { TrainingPlanType } from './enums';
import { submitAssignment } from './services';

interface AddOneTimeAssignmentControllerProps
  extends AddOneTimeAssignmentProps {}

export const AddOneTimeAssignmentController = (
  props: AddOneTimeAssignmentControllerProps
): JSX.Element => {
  const navigate = useNavigate();

  useConfigureLayout({
    pageTitle: '',
    enablePadding: false,
  });

  const [trainingPlanType, setTrainingPlanType] = useState('');
  const [errors, setErrors] = useState<ErrorConfig>({});

  const [selectedCurriculum, setSelectedCurriculum] = useState<
    ReferenceSelectorItem | undefined
  >();
  const [selectedCourse, setSelectedCourse] = useState<
    ReferenceSelectorItem | undefined
  >();

  const [selectedItems, setSelectedItems] = useState<SelectorItem[]>([]);
  const [gridSelectedData, setGridSelectedData] = useState<SelectorItem[]>([]);
  const [showAll, setShowAll] = useState(true);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isSavingAssignment, setIsSavingAssignment] = useState(false);
  const [newAssignedUsers, setNewAssignedUsers] = useState<string[] | null>(
    null
  );
  const [notAssignedUsers, setNotAssignedUsers] = useState<string[] | null>(
    null
  );
  const [selectedUser, setSelectedUser] = useState<User[]>([]);
  const onTrainingPlanTypeChange = (value: string) => {
    setTrainingPlanType(value);
    setSelectedCurriculum(undefined);
    setSelectedCourse(undefined);
  };

  const onRemoveItem = (item: RowItem) => {
    setGridSelectedData((prev) =>
      prev.filter((selectedItem) => selectedItem.label !== item.label)
    );
  };
  const removeUser = (item: RowItem) => {
    setSelectedUser((prev) =>
      prev.filter((selectedItem) => selectedItem.name !== item.name)
    );
  };
  const onConfirmClick = () => {
    setIsConfirmDialogOpen(false);
    navigate(getULHomePageUrl());
  };

  const onSubmit = async () => {
    const tempErrors: ErrorConfig = {};

    if (!trainingPlanType) {
      tempErrors.trainingPlanType = LocalizationString.REQUIRED;
    } else if (
      trainingPlanType === TrainingPlanType.Curriculum &&
      !selectedCurriculum
    ) {
      tempErrors.selectedCurriculum = LocalizationString.REQUIRED;
    } else if (
      trainingPlanType === TrainingPlanType.Course &&
      !selectedCourse
    ) {
      tempErrors.selectedCourse = LocalizationString.REQUIRED;
    }

    if (gridSelectedData.length === 0 && selectedUser.length === 0) {
      tempErrors.gridSelectedData =
        LocalizationString.PLEASE_SELECT_A_USER_GROUP;
      errorToast({
        message: LocalizationString.PLEASE_SELECT_A_USER_GROUP,
      });
    }

    setErrors(tempErrors);

    if (Object.keys(tempErrors).length === 0) {
      const smartGroupNames = gridSelectedData.map((gridData) => gridData.name);
      const userNames = selectedUser.map((userData) => userData.name);
      let payload: AssignmentPayload | null = null;
      if (trainingPlanType === TrainingPlanType.Course) {
        const courseName = selectedCourse?.name ?? null;
        const courseVersion = selectedCourse?.version ?? null;
        payload = {
          smartGroupNames,
          userNames,
          courseName,
          courseVersion,
          isRetrainingRequired: true,
        };
      } else {
        const curriculumName = selectedCurriculum?.name ?? null;
        const curriculumVersion = selectedCurriculum?.version ?? null;
        payload = {
          smartGroupNames,
          userNames,
          curriculumName,
          curriculumVersion,
          isRetrainingRequired: true,
        };
      }

      try {
        setIsSavingAssignment(true);
        const { assignedUsersLabel, notAssignedUsersLabel } =
          await submitAssignment(payload);
        setNewAssignedUsers(assignedUsersLabel);
        setNotAssignedUsers(notAssignedUsersLabel);
        setIsConfirmDialogOpen(true);
      } catch (_error) {
        raiseErrorToast(_error);
      } finally {
        setIsSavingAssignment(false);
      }
    }
  };

  useEffect(() => {
    setErrors({});
  }, [selectedCurriculum, selectedCourse, gridSelectedData]);

  const handleChangeData = (smartGroup: SelectorItem[]) => {
    setGridSelectedData(smartGroup);
  };

  const handleUserChange = (item: User[]) => {
    setSelectedUser(item);
  };

  return (
    <AddOneTimeAssignmentView
      {...{
        ...props,
        trainingPlanType,
        onTrainingPlanTypeChange,
        selectedItems,
        setSelectedItems,
        onRemoveItem,
        showAll,
        setShowAll,
        gridSelectedData,
        setGridSelectedData,
        selectedCourse,
        selectedCurriculum,
        setSelectedCourse,
        setSelectedCurriculum,
        errors,
        onSubmit,
        onConfirmClick,
        isConfirmDialogOpen,
        isSavingAssignment,
        newAssignedUsers,
        notAssignedUsers,
        handleChangeData,
        selectedUser,
        handleUserChange,
        removeUser,
      }}
    />
  );
};
