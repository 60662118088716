import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { Dialog, DialogSurface, Label } from '@fluentui/react-components';
import { CustomButton, Loader, Stack } from 'libs/shared/src/lib/shared';

import classes from './claim-modal.module.css';

interface ClaimModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const ClaimModal = ({
  onClose,
  isOpen,
}: ClaimModalProps): JSX.Element => {
  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogSurface
        style={{ padding: 10, paddingTop: 20, width: 300 }}
        className={classes.container}
      >
        <div className={classes.modalBody}>
          <Loader withLabel={false} />
          <Stack className={classes.gapContainer}>
            <Label>{LocalizationString.PLEASE_WAIT}</Label>
            <p>{LocalizationString.CLAIM_RECORD_MSG}</p>
          </Stack>
        </div>

        <div className={classes.modalFooter}>
          <CustomButton
            buttonType={ButtonTypes.Primary}
            buttonTitle={LocalizationString.OK}
            customStyles={classes.button}
            onClick={onClose}
          />
        </div>
      </DialogSurface>
    </Dialog>
  );
};
