export enum CourseStatusEnum {
  IN_DRAFT = 'In Draft',
  SUBMITTED = 'Submitted',
  IN_APPROVAL = 'In Approval',
  APPROVED = 'Approved',
  SUPERSEDED = 'Superseded',
  RETIRE = 'Retire',
  PENDING_RETIREMENT = 'Pending Retirement',
  OBSOLETE = 'Obsolete',
}
