import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const rulesStyles = makeStyles({
  row: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    ...shorthands.gap('1rem'),

    '> *': {
      minWidth: '15.2rem',
      ...shorthands.flex(1),
    },
  },

  rulesContainer: {
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  conditionContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  conditionGroup: {
    display: 'flex',
    ...shorthands.gap('76px'),
    alignItems: 'center',
    width: '100%',
  },

  innerConditionGroup: {
    ...shorthands.padding('1rem'),
    backgroundColor: 'rgba(29, 57, 196, 0.05)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    ...shorthands.gap('16px'),
    ...shorthands.flex(1),
    width: '100%',
  },

  innerConditionHeaderGroup: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },

  ruleConditionType: {
    marginLeft: '78px',
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    ...shorthands.padding('4px'),

    '> div': {
      ...shorthands.padding('8px', '1rem'),
      ...shorthands.borderRadius('4px'),
    },
  },

  conditionType: {
    display: 'flex',
    backgroundColor: Color.white,
    ...shorthands.padding('4px'),

    '> div': {
      ...shorthands.padding('8px', '1rem'),
      ...shorthands.borderRadius('4px'),
    },
  },

  activeConditionType: {
    backgroundColor: Color.background_dark_blue_quiz,
    color: Color.white,
  },

  conditionSettings: {
    display: 'flex',
    ...shorthands.gap('1rem'),
    alignItems: 'center',
    width: '100%',
  },

  flex: { ...shorthands.flex(1) },

  text: { fontFamily: ThemeFontFamily.sans, fontSize: '1rem' },

  addGroupBtn: {
    ...shorthands.padding('12px', 0, '12px', '84px'),
    display: 'flex',
    ...shorthands.gap('8px'),
  },

  isBtnCompact: {
    ...shorthands.padding('12px', 0, '12px', '0'),
  },

  addGroupBtnNoItems: {},

  deleteBtn: {
    color: Color.DELETE_ICON_COLOR,
    cursor: 'pointer',
  },

  isVisibile: { visibility: 'visible', cursor: 'pointer' },

  hidden: { visibility: 'hidden' },

  groupContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
  },

  ruleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
  },

  inactiveConditionType: { cursor: 'pointer' },

  inactiveRuleConditionType: { backgroundColor: Color.gray_100 },

  referencePicker: { minWidth: '250px' },

  rangeContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexWrap: 'wrap',
    width: '100%',
    rowGap: '14px',
    columnGap: '2px',

    '> div': {
      ...shorthands.flex(1),
    },
  },

  tabList: {
    ...shorthands.borderBottom('1px', 'solid', Color.gray_tab_border),

    display: 'flex',
    alignItems: 'center',

    '> button > span': {
      fontSize: '1rem',
    },
    minWidth: '100%',
  },

  tabInput: {
    ...shorthands.border(0),
    fontSize: '1rem',
    textAlign: 'center',
    ...shorthands.overflow('hidden'),
    textOverflow: 'ellipsis ',
    height: '32px',
    backgroundColor: 'transparent',
  },

  addIcon: {
    marginLeft: '8px',
    color: Color.blue_700,
    cursor: 'pointer',
  },

  tab: {
    ...shorthands.padding('8px'),
  },

  innerAvailableColumn: {
    ...shorthands.padding('12px'),
    backgroundColor: Color.background_body_bg,
    ...shorthands.borderRadius('7.578px'),
    fontFamily: ThemeFontFamily.sans,
    ...shorthands.gap('8px'),
    display: 'flex',
    cursor: 'pointer',
    whiteSpace: 'nowrap',
  },

  outerMultipleRules: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '8px',
  },

  availableColumnsScrollButtons: {
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('1rem'),
    ...shorthands.padding(0, '1rem'),

    height: '44px',
    backgroundColor: Color.white,

    '> button': {
      ...shorthands.borderRadius('50%'),
      width: '36px',
      height: '36px',
    },
  },

  availableMultipleRules: {
    display: 'flex',
    ...shorthands.gap('8px'),
    ...shorthands.overflow('hidden'),
    width: '100%',
  },

  scrollDivider: { height: '100%' },

  tabError: {
    ...shorthands.borderBottom('1px', 'solid', Color.error_border),
  },

  addConditionIcon: { color: Color.DARK_BLUE_BORDER },

  tabInputError: { '::placeholder': { color: Color.error_color } },

  disabled: {
    pointerEvents: 'none',
    cursor: 'not-drop !important',
    opacity: '0.72',
  },
});
