import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, DateFormat } from '@celito.clients/enums';
import {
  CheckBox,
  CustomButton,
  Icon,
  Loader,
  Menu,
  PDFViewer,
} from '@celito.clients/shared';
import { useTheme, ZIndex } from '@celito.clients/theme';
import { formatDate } from '@celito.clients/utils';
import {
  Button,
  CheckboxOnChangeData,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import React, { Fragment, useCallback, useState } from 'react';

import {
  DocVersionHistoryViewProps,
  DocVersions,
  mimeTypes,
} from './doc-version-history.model';
import styles from './doc-version-history.module.css';

const VersionHistoryView: React.FC<DocVersionHistoryViewProps> = ({
  docsSelected,
  docData,
  docUrl,
  docVersions,
  compareVersions,
  onCancelClicked,
  handleDownload,
  handleChange,
  disableButton,
  loading,
  handleContextMenuClick,
  showErrorPreview,
  isLocalPDF,
  ...props
}) => {
  const { cssVariables } = useTheme();
  const [isFullScreen, setIsFullScreen] = useState(false);
  const shouldCompareButtonEnabled = useCallback(() => {
    const MIN_SELECTED_DOCUMENTS_FOR_COMPARISON = 2;
    return docsSelected.length === MIN_SELECTED_DOCUMENTS_FOR_COMPARISON;
  }, [docsSelected]);

  const card = (item: DocVersions, index: number) => {
    const disabled =
      !docsSelected.includes(item.version) && docsSelected.length === 2;

    return (
      <div
        key={item.uploadedAtUtc}
        style={{ ...cssVariables }}
        className={disabled ? styles.disabledCardItem : styles.cardItem}
        onClick={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          isChecked?: CheckboxOnChangeData
        ) => !disabled && handleChange(ev, isChecked?.checked, item)}
        onKeyDown={(
          ev?: React.FormEvent<HTMLElement | HTMLInputElement>,
          isChecked?: CheckboxOnChangeData
        ) => !disabled && handleChange(ev, isChecked?.checked, item)}
      >
        {index === 0 && (
          <span className={styles.cardLink} aria-label="Current Version">
            Current Version
          </span>
        )}

        <div className={styles.cardDetails}>
          <div>
            <Menu positioning={'below-end'}>
              <MenuTrigger>
                <Button
                  className={styles.menuButton}
                  data-testid={`button-more-action`}
                >
                  <Icon iconName="MoreHorizontal24Regular" />
                </Button>
              </MenuTrigger>
              <MenuPopover style={{ zIndex: ZIndex.Dropdown }}>
                <MenuList className={styles.menuList}>
                  <MenuItem
                    key={index}
                    className={styles.actionMenuItem}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleContextMenuClick(item, 'download');
                    }}
                  >
                    <div>Download</div>
                  </MenuItem>
                  {item?.canRestorPastVersion && (
                    <MenuItem
                      key={index}
                      className={styles.actionMenuItem}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleContextMenuClick(item, 'restore');
                      }}
                    >
                      <div>Restore</div>
                    </MenuItem>
                  )}
                </MenuList>
              </MenuPopover>
            </Menu>
          </div>
          <div className={styles.details}>
            <div>
              <Icon iconName="DocumentBulletList16Regular" />{' '}
              <p
                title={`${formatDate(
                  item?.uploadedAtUtc,
                  DateFormat.DateTime
                )} . Ver ${item.version}`}
              >
                {item.uploadedAtUtc
                  ? formatDate(item.uploadedAtUtc, DateFormat.DateTime)
                  : ''}{' '}
                . Ver {item.version}
              </p>
            </div>
            <div>
              <Icon iconName="ContactCard16Regular" />{' '}
              <p
                title={`${item.uploadedByUserName} (${item.uploadedByEmailAddress})`}
              >
                {item.uploadedByUserName} ({item.uploadedByEmailAddress})
              </p>
            </div>
          </div>
          <div className={styles.checkBoxDiv}>
            <CheckBox
              id={item.version.toString()}
              checked={docsSelected.includes(item.version)}
              onChanged={(
                ev?: React.ChangeEvent<HTMLInputElement>,
                isChecked?: CheckboxOnChangeData
              ) => handleChange(ev, isChecked?.checked, item)}
              disabled={
                disabled || loading || (docVersions && docVersions?.length < 2)
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const renderNoPreviewOrUnableToLoadFileOrIframe = () => {
    if (docData && !mimeTypes.includes(docData?.mimeType) && !docUrl) {
      return (
        <div className={styles.buttonContainer}>
          <div>
            <p>No Preview Available</p>
          </div>
          <CustomButton
            buttonTitle="Download"
            buttonType={ButtonTypes.Primary}
            onButtonClicked={handleDownload}
          />
        </div>
      );
    }
    if (showErrorPreview) {
      return (
        <div className={styles.buttonContainer}>
          <div>
            <div>
              <p>{LocalizationString.UNABLE_TO_UPLOAD_FILE}</p>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {' '}
          <div
            className={`${styles.iframewrapper} ${
              isFullScreen ? styles.full_screen : ''
            }`}
          >
            {isLocalPDF ? (
              <PDFViewer url={docUrl} />
            ) : (
              <Fragment>
                <iframe
                  src={docUrl?.concat('#toolbar=0&navpanes=0&zoom=0%')}
                  width="100%"
                  height="100%"
                  allowFullScreen
                  title={docData?.nameWithExtension}
                />
                <button
                  className={styles.full_screen_button}
                  onClick={() => setIsFullScreen((prev) => !prev)}
                >
                  <Icon
                    iconName={
                      isFullScreen
                        ? 'ArrowStepBack16Regular'
                        : 'FullScreenMaximize16Regular'
                    }
                  />
                </button>
              </Fragment>
            )}
          </div>
          <div className={styles.bottomIframeHelper} />
        </>
      );
    }
  };

  return (
    <div style={{ ...cssVariables }} className={styles.versionContainer}>
      <div className={styles.documentView}>
        {loading && <Loader />}
        {!loading && renderNoPreviewOrUnableToLoadFileOrIframe()}
      </div>
      <div className={styles.historyContainer}>
        <div className={styles.historyWrapper}>
          <div className={styles.historyHeader}>
            <h5 className={styles.headerText}>History</h5>
          </div>
          <div className={styles.historyBody}>
            <div className={styles.cardBody}>
              {docVersions?.map((item, index) => (
                <>{card(item, index)}</>
              ))}
            </div>
            <div className={styles.infoDiv}>
              <Icon iconName="Info16Regular" />
              <div>
                <span>Compare any document version with the current one.</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.historyFooter}>
          {docsSelected.length === 0 && (
            <CustomButton
              buttonType={ButtonTypes.Secondary}
              isDisabled={true}
              buttonTitle="Compare Versions"
              onButtonClicked={() => null}
            />
          )}
          {docsSelected.length > 0 && (
            <>
              <CustomButton
                buttonType={ButtonTypes.Ghost}
                buttonTitle="Cancel"
                onButtonClicked={onCancelClicked}
              />
              {shouldCompareButtonEnabled() && (
                <CustomButton
                  buttonType={ButtonTypes.Primary}
                  buttonTitle="Compare"
                  onButtonClicked={compareVersions}
                  disabled={disableButton?.()}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default VersionHistoryView;
