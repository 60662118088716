import { LocalizationString } from '@celito.clients/assets';
import { Picklist, Stack } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { DropdownProps } from '@fluentui/react-components';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { useFormEngineContext } from '../../../hooks';
import { ControlledPicklistProps } from './controlled-picklist.model';
import classes from './controlled-picklist.styles.module.css';

interface ControlledPicklistViewProps<T extends FieldValues>
  extends ControlledPicklistProps<T> {}

export const ControlledPicklistView = <T extends FieldValues>(
  props: ControlledPicklistViewProps<T>
): JSX.Element => {
  const { fieldsState } = useFormEngineContext();
  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => {
        const handleDropdownChange: DropdownProps['onOptionSelect'] = (
          ev,
          data
        ) => {
          onChange(data?.optionText as string);
        };

        const pickOptions = fieldsState[props.attribute?.name]?.picklistFilter;

        return (
          <Stack
            data-testid={`picklist-container-${createTestAttribute(
              props.attribute?.label
            )}`}
            className={classes.section}
          >
            <Picklist
              {...(props.attribute as Record<string, unknown>)}
              data-testid={`dropdown-${createTestAttribute(
                props.attribute?.label
              )}`}
              label={props.attribute?.label}
              onOptionSelect={handleDropdownChange}
              picklistName={props.attribute?.picklistName ?? 'group_type__a'}
              isRequired={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
              placeholder={
                props.attribute.placeholder ??
                LocalizationString.SELECT_PLACEHOLDER
              }
              selectedKey={value}
              disabled={!props.attribute.isEditable}
              onBlur={onBlur}
              errorMessage={
                props.attribute.errorParser
                  ? (props.attribute.errorParser(error) as string)
                  : error?.message
              }
              pickOptions={pickOptions}
              helperLabelText={props.attribute?.helpText}
            />
          </Stack>
        );
      }}
    />
  );
};
