import { createTestAttribute } from '@celito.clients/utils';
import { Input, mergeClasses } from '@fluentui/react-components';
import React from 'react';

import { Field } from '../../shared';
import { TextFieldModel } from './text-field.model';
import { textFieldStyles } from './text-field.styles';

// The view component for the TextField, responsible for rendering the UI
export const TextFieldView: React.FC<TextFieldModel> = (props) => {
  const {
    labelText,
    required,
    errorMessage,
    size = 'large',
    customStyles,
    inputRef,
    dataTestId,
    helperLabelText,
    label,
  } = props;

  const styles = textFieldStyles();

  return (
    <Field
      label={labelText ?? label}
      helperTextLabel={helperLabelText}
      required={required}
      validationMessage={errorMessage}
      validationState={errorMessage ? 'error' : 'none'}
      size={size}
      data-testid={`label-text-${createTestAttribute(label)}`}
    >
      <Input
        ref={inputRef}
        {...props}
        className={mergeClasses(
          styles.textField,
          customStyles,
          errorMessage && styles.textFieldError
        )}
        data-testid={dataTestId ?? `input-field-${createTestAttribute(label)}`}
      />
    </Field>
  );
};
