const en = {
  createNotification: 'Create Notification',
  editNotification: 'Edit Notification',
  viewNotification: 'View Notification',
  customSourceLabel: '__c',
  systemSourceLabel: '__s',
  applicationSourceLabel: '__a',
  createLabel: 'Create',
  saveLabel: 'Save',
  editLabel: 'Edit',
  cancelLabel: 'Cancel',
  greatLabel: 'Great',
  addNewLabel: 'Add New',
  confirmLabel: 'Confirm',
  completeLabel: 'Complete',
  eSignLabel: 'E-Sign',
  deleteLabel: 'Yes, Delete',
  addUser: 'Add New User',
  newUser: 'New User',
  addUserPlaceholder: 'Search',
  userRequired: 'Please select user',
  suggestedUsers: 'Suggested Users',
  noResultFound: 'No results found',
  loading: 'Loading',
  notificationsTitle: 'Notifications',
  markAllReadLabel: 'Mark All Read',
  removeAllLabel: 'Remove All',
  requiredLabel: 'This is required field',
  saveAlertTitle: 'Save Confirmation',
  confirmTitle: 'Confirmation',
  saveConfirm: 'Want to save the changes?',
  createSuccessDelegate: 'You have successfully appointed a new delegate.',
  saveSuccessDelegate: 'You have successfully saved the changes.',
  deleteSuccessDelegate: 'Delegation deleted successfully',
  delegation: 'Delegation',
  versionHistory: 'Version History',
  startWithNewDelegation: 'Start New Delegation',
  recentDelegates: 'Recent Delegation(s)',
  editDelegate: 'Edit Delegate',
  editDelegation: 'Edit Delegation',
  deleteDelegation: 'Delete Delegation',
  createNewDelegate: 'Start New Delegation',
  delegationStartDate: 'Start Date',
  delegationEndDate: 'End Date',
  delegateFrom: 'Delegate From',
  delegateTo: 'Delegate To',
  sratDateRequired: 'Start date is required',
  endDateRequired: 'End date is required',
  delegateFromRequired: 'Delegate from is required',
  delegateToRequired: 'Delegate to is required',
  justificationValidation: 'Justification is required',
  noNotifTitle: 'No Notifications yet',
  noNotifSubtitle: 'When you get notifications, They’ll show up here.',
  deleteConfirmation: 'Delete Confirmation',
  deleteConfirmationDescription: 'Are you sure you want to delete?',
  cancelConfirmationDescription:
    'You have unsaved changes. Are you sure you want to cancel?',
  endDateValidateString: 'End date is required',
  verdictRequired: 'Please select verdict',
  minCharacters: 'Characters length should be less than 200',
  showAllMembers: 'Show all members',
  editCertificate: 'Edit Certificate',
  viewCertificate: 'Certificate',
  previewLabel: 'Preview',
  resetToDefault: 'Reset to Default',
  closeLabel: 'Close',
  deleteName: 'Delete',
  viewDelegation: 'View Delegation',
};

export default en;
