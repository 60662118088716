import { ObjectEnum as ObjectName } from '../enums/objects';
import { ReferencePickerType } from '../enums/reference-picker-type';

export const getReferencePickerType = (
  objectName?: string
): ReferencePickerType => {
  switch (objectName) {
    case ObjectName.DOCUMENT_TYPE:
    case ObjectName.DOCUMENT_SUB_TYPE:
    case ObjectName.GXP_TYPE:
    case ObjectName.DEPARTMENT:
    case ObjectName.TRAINING_ASSIGNMENT:
    case ObjectName.USER:
    case ObjectName.CELITO_MODULE:
      return ReferencePickerType.LABEL__S;
    case ObjectName.CONTROLLED_DOCUMENT:
    case ObjectName.CURRICULUM:
    case ObjectName.COURSE:
    case ObjectName.SMART_GROUP:
      return ReferencePickerType.LABEL_OR_TITLE;
    default:
      return ReferencePickerType.LABEL_TITLE;
  }
};
