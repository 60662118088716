import {
  Field,
  ObjectAttributeDefinition,
  ObjectValidationRule,
  Section,
} from '@celito.clients/types';
import { yupResolver } from '@hookform/resolvers/yup';
import { useLayoutRules } from 'libs/form-engine/src/lib/hooks/useLayoutRules';
import { generateYupSchemaFromLayoutRules } from 'libs/form-engine/src/lib/utils/validator-generator';
import { useState } from 'react';
import {
  useForm as _useForm,
  UseFormProps,
  UseFormReturn,
} from 'react-hook-form';
import useDeepCompareEffect from 'use-deep-compare-effect';
import * as yup from 'yup';

import type { TAttributeDefinition } from './field';

export type TUseFormReturn = ReturnType<typeof useForm>;

const defaultSchema = {} as yup.ObjectSchema<yup.AnyObject>;

/**
 * Wrapper over react-hook-form's useForm hook
 * Includes layout rules and yup schema generation
 * @param attributes - See {@link TAttributeDefinition}
 * @returns form instance with reactive fieldsState and its setter
 */
export default function useForm(
  attributes: TAttributeDefinition[],
  formProps: UseFormProps = {},
  enableLayoutRules = true,
  parentFormInstance?: UseFormReturn,
  setParentValidationSchema?: React.Dispatch<
    React.SetStateAction<
      yup.ObjectSchema<yup.AnyObject, yup.AnyObject, any, ''> | undefined
    >
  >,
  objectValidationRules?: ObjectValidationRule[],
  objectAttributeDef?: ObjectAttributeDefinition[]
) {
  const [validationSchema, setValidationSchema] = useState(defaultSchema);

  const form = parentFormInstance
    ? parentFormInstance
    : _useForm({
        resolver: yupResolver(validationSchema),
        reValidateMode: 'onSubmit',
        mode: 'all',
        ...formProps,
      });

  const formData = form.watch();

  const { fieldsState, setFieldsState } = useLayoutRules(formData, {
    fields: attributes as Field[],
  } as unknown as Section);

  useDeepCompareEffect(
    () => {
      if (!enableLayoutRules) return;
      const newSchema = generateYupSchemaFromLayoutRules(
        formData,
        attributes as Field[],
        objectValidationRules || [],
        objectAttributeDef || [],
        fieldsState
      );

      const schemaIsValid = () =>
        newSchema &&
        typeof newSchema === 'object' &&
        Object.keys(newSchema).length > 0;

      if (schemaIsValid()) {
        if (setParentValidationSchema) {
          setParentValidationSchema(newSchema);
          return;
        }
        setValidationSchema(newSchema);
      }
    },
    enableLayoutRules
      ? [fieldsState, formData, attributes, enableLayoutRules]
      : [enableLayoutRules]
  );

  return { form, fieldsState, setFieldsState };
}
