import { LocalizationString } from '@celito.clients/assets';
import { Menu } from '@celito.clients/shared';
import {
  makeStyles,
  MenuButton,
  MenuList,
  MenuPopover,
  MenuProps,
  MenuTrigger,
  mergeClasses,
} from '@fluentui/react-components';
import { useCloseOnScroll } from 'libs/core/src/hooks/useCloseOnScroll';
import { IFilterView } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { useState } from 'react';

import { getFilterViewsBtnMenuConfig } from '../../utils';
import { actionColumnListViewStyles } from '../action-column';
import { useExportBtnStyles } from '../export-btn';

interface FilterViewsButtonProps {
  options: IFilterView[];
  selected: IFilterView;
  onClick: (item: IFilterView) => void;
  cssObject: object;
  customStyles?: string;
}

const useStyles = makeStyles({
  btn: {
    marginRight: '10px',
  },
});

export const FilterViewsButton: React.FC<FilterViewsButtonProps> = ({
  options,
  selected,
  onClick,
  cssObject,
  customStyles,
}) => {
  const classes = useExportBtnStyles();
  const styles = useStyles();
  const actionColumnStyles = actionColumnListViewStyles();
  const [menuOpen, setMenuOpen] = useState(false);

  useCloseOnScroll(setMenuOpen);

  const inputName = 'selectedFilterView';
  const onChangeHandler: MenuProps['onCheckedValueChange'] = (
    _,
    { checkedItems }
  ) => {
    const selectedItem = options.find(
      (option) => option.label === checkedItems[0]
    );

    // If selected item is found and it is not same as currently
    // selected item then call
    if (selectedItem && selectedItem.label !== selected.label)
      onClick(selectedItem);
  };

  return (
    <Menu
      checkedValues={{ [inputName]: [selected.label] }}
      onCheckedValueChange={onChangeHandler}
      hasCheckmarks
      closeOnScroll
      open={menuOpen}
      onOpenChange={(_, data) => setMenuOpen(data.open)}
    >
      <MenuTrigger disableButtonEnhancement>
        <MenuButton
          className={mergeClasses(classes.root, styles.btn, customStyles)}
          data-testid={`button-${LocalizationString.FILTER_VIEWS.toLowerCase()}`}
        >
          {selected.label}
        </MenuButton>
      </MenuTrigger>

      <MenuPopover className={actionColumnStyles.menuPopover}>
        <MenuList className={actionColumnStyles.menuList}>
          {getFilterViewsBtnMenuConfig(inputName, options, cssObject)}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
