import { LocalizationString } from '@celito.clients/assets';
import { Field, FluentIconNames, Icon } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { createTestAttribute, getNameInitials } from '@celito.clients/utils';
import { SelectionEvents } from '@fluentui/react-combobox';
import { mergeClasses, Tooltip } from '@fluentui/react-components';
import cn from 'classnames';
import { createPortal } from 'react-dom';

import userAvatarColors from '../people-picker/src/config/user-avatar-colors';
import { Virtualizer } from '../virtualizer/virtualizer.component';
import {
  InHouseInputSelectProps,
  IOption,
} from './in-house-input-select.model';
import classes from './in-house-input-select.module.css';
import { customMultiSelectStyles } from './in-house-input-select.styles';
import { ICommonData } from './services';

interface InHouseInputSelectViewProps
  extends Omit<InHouseInputSelectProps, 'onOptionSelect'> {
  isOptionsOpen: boolean;
  toggleDropdown: (isOpen?: boolean) => void;
  selectedOptions: IOption[];
  dropdownRef: React.RefObject<HTMLDivElement>;
  onOptionSelect: (
    _ev: SelectionEvents,
    optionText: string,
    optionValue: string,
    data?: ICommonData
  ) => void;
  handleCustomSearch: (customSearch: string) => void;
  customSearch: string;
  multiselectRef: React.RefObject<HTMLDivElement>;
  inputTextRef: React.MutableRefObject<HTMLInputElement | null>;
  inputTextTagRef: React.MutableRefObject<HTMLInputElement | null>;
  tagContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  onInput: () => void;
  onKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
    options?: IOption[]
  ) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  highligtedOption?: IOption;
  lastElementRef: (node: HTMLDivElement) => void;
  isFetching?: boolean;
}

export const InHouseInputSelectView = ({
  label,
  options,
  isLoadingOptions,
  disabled,
  errorMessage,
  isFullWidth = true,
  showSecondaryText = false,
  onOptionSelect,
  toggleDropdown,
  isOptionsOpen,
  selectedOptions,
  dropdownRef,
  customSearch,
  multiselect,
  handleCustomSearch,
  multiselectRef,
  required = false,
  placeholder,
  inputTextRef,
  inputTextTagRef,
  tagContainerRef,
  onInput,
  isPeoplePicker,
  onKeyDown,
  dataTestId,
  isDropdownOnly = false,
  onBlur,
  virtualized = false,
  highligtedOption,
  helperLabelText,
  dontSortOptions,
  lastElementRef,
  referencePicker,
  dontShowOptions = false,
  fieldClassName,
  isFetching,
}: InHouseInputSelectViewProps) => {
  const styles = customMultiSelectStyles();
  const { disableOptionsWhenFetching = false } = referencePicker || {};
  const { cssVariables } = useTheme();

  const isDisabled =
    (options.length === 0 && !referencePicker?.objectName) || disabled;

  const filteredBySearch =
    dontSortOptions || referencePicker?.objectName
      ? options?.filter((filteredOption) =>
          filteredOption?.text
            ?.toLowerCase()
            ?.includes(customSearch.toLowerCase())
        )
      : options
          ?.filter((filteredOption) =>
            filteredOption.text
              ?.toLowerCase()
              ?.includes(customSearch.toLowerCase())
          )
          .sort((a, b) => a.text.localeCompare(b.text));

  const filterableOptions = filteredBySearch?.filter((filteredOption) => {
    return !selectedOptions.some((selected) => {
      if (filteredOption?.data?.version && selected?.version) {
        return (
          `${selected.value}-${selected.version}` ===
          `${filteredOption.value}-${filteredOption.data.version}`
        );
      }
      return selected?.value === filteredOption.value;
    });
  });

  const getRandomColor = () => {
    const randomIndex = Math.floor(Math.random() * userAvatarColors.length);
    return userAvatarColors[randomIndex];
  };
  const dropdownOption = (
    option: IOption,
    lastElementRef: (node: HTMLDivElement) => void,
    isFetching?: boolean
  ) => {
    const isDisabled =
      option.disabled || (isFetching && disableOptionsWhenFetching);

    const dataTestId = option.text.includes('(')
      ? createTestAttribute(option.text)?.match(/\((.*?)\)/)?.[1]
      : createTestAttribute(option.text);
    return (
      <div
        ref={lastElementRef}
        data-testid={`dropdown-option-${createTestAttribute(dataTestId)}`}
        id="dropdown-with-options"
        key={option.value}
        data-value={option.value}
        className={mergeClasses(
          styles.optionContainer,
          isDisabled && styles.optionDisabled,
          highligtedOption?.value === option.value &&
            styles.optionContainerActive
        )}
        onClick={(_ev) =>
          !isDisabled &&
          onOptionSelect &&
          onOptionSelect(_ev, option.text, option.value, option?.data)
        }
        aria-disabled={isDisabled}
      >
        <div className={styles.optionInnerContainer}>
          <span className={styles.optionLabel}>
            {isPeoplePicker ? (
              <div className={styles.customPersona}>
                <div
                  style={{
                    backgroundColor: getRandomColor(),
                  }}
                  className={styles.customAvatar}
                >
                  {getNameInitials(option.text)}
                </div>
                <div className={styles.personaOptions}>
                  <span>{option.text}</span>
                  {showSecondaryText && <span>{option.secondaryText}</span>}
                </div>
              </div>
            ) : (
              option.text
            )}
          </span>
        </div>
      </div>
    );
  };

  const noResultOption = () => {
    return (
      <div
        className={styles.noOptionsAvailable}
        id="dropdown-with-no-results"
        data-testid={`dropdown-option-${createTestAttribute(
          LocalizationString.NO_RESULTS
        )}`}
      >
        {LocalizationString.NO_RESULTS}
      </div>
    );
  };

  return (
    <Field
      label={label}
      helperTextLabel={helperLabelText}
      required={required}
      size="large"
      className={mergeClasses(
        styles.field,
        isFullWidth && styles.fullWidth,
        fieldClassName
      )}
      validationMessage={errorMessage}
      validationState={errorMessage ? 'error' : 'none'}
    >
      <div className={styles.selectContainer}>
        <div
          ref={multiselectRef}
          className={mergeClasses(
            styles.inputContainer,
            isDisabled && styles.btnTriggerDisabled,
            errorMessage && styles.error,
            isFullWidth && styles.fullWidth,
            !errorMessage && isOptionsOpen && styles.focusedInputContainer
          )}
          onClick={(_ev) => {
            if (!isDisabled) {
              if (selectedOptions?.length === 0) {
                inputTextTagRef.current?.focus();
              } else {
                inputTextRef.current?.focus();
              }
              toggleDropdown(!isOptionsOpen);
            }
          }}
          data-testid={
            isDropdownOnly
              ? `dropdown-${createTestAttribute(label)}`
              : undefined
          }
        >
          <div className={styles.tagContainer} ref={tagContainerRef}>
            {selectedOptions.length > 0 && !dontShowOptions ? (
              <div
                className={styles.tagInputContainer}
                data-testid={`people-picker-selected-user`}
              >
                {!dontShowOptions &&
                  selectedOptions.map((selected, index) => (
                    <div
                      key={`${selected.value}-${index}`}
                      data-testid={`people-picker-selected-user-${createTestAttribute(
                        selected.text
                      )}`}
                      className={mergeClasses(
                        styles.tagInnerContainer,
                        (isDisabled || isDropdownOnly) && styles.tagDisabled,
                        !multiselect && styles.singleSelectTagInnerContainer,
                        isDisabled &&
                          !multiselect &&
                          styles.singleSelectTagInnerContainerDisabled
                      )}
                      style={{
                        maxWidth: `${
                          tagContainerRef.current
                            ? tagContainerRef.current?.offsetWidth - 12
                            : 100
                        }px`,
                      }}
                    >
                      <span className={mergeClasses(styles.tagOption)}>
                        <Tooltip content={selected.text} relationship="label">
                          <span>{selected.text}</span>
                        </Tooltip>
                      </span>

                      {multiselect && (
                        <div
                          data-testid="people-picker-deselect"
                          className={mergeClasses(
                            styles.dismissContainer,
                            (isDisabled || isDropdownOnly) &&
                              styles.dismissContainerDisabled
                          )}
                          onClick={(_ev) => {
                            if (!isDisabled && !isDropdownOnly) {
                              _ev.stopPropagation();
                              onOptionSelect &&
                                onOptionSelect(
                                  _ev,
                                  selected.text,
                                  selected.value,
                                  options.find(
                                    (opt) => opt.value === selected.value
                                  )?.data
                                );
                            }
                          }}
                        >
                          <Icon iconName="Dismiss12Filled" />
                        </div>
                      )}
                    </div>
                  ))}

                {!isDropdownOnly && (
                  <input
                    data-testid={
                      dataTestId ?? `input-field-${createTestAttribute(label)}`
                    }
                    ref={inputTextRef}
                    className={mergeClasses(
                      styles.inputTextCustomized,
                      styles.inputTextAutoWidth,
                      isDisabled && styles.inputDisabled
                    )}
                    type="text"
                    value={customSearch}
                    onChange={(_ev) => {
                      toggleDropdown(true);
                      handleCustomSearch(_ev.target.value);
                    }}
                    disabled={disabled}
                    onInput={onInput}
                    onKeyDown={(_e) => onKeyDown(_e, filterableOptions)}
                    onBlur={onBlur}
                  />
                )}
              </div>
            ) : (
              !isDropdownOnly && (
                <input
                  onBlur={onBlur}
                  data-testid={
                    dataTestId ?? `input-field-${createTestAttribute(label)}`
                  }
                  ref={inputTextTagRef}
                  style={{ width: '100%' }}
                  className={mergeClasses(
                    styles.inputTextCustomized,
                    isDisabled && styles.inputDisabled
                  )}
                  type="text"
                  value={customSearch}
                  placeholder={
                    isLoadingOptions
                      ? LocalizationString.LOADING_PLACEHOLDER
                      : isDisabled
                      ? `No options available`
                      : placeholder ?? LocalizationString.SEARCH_AND_ADD
                  }
                  onChange={(_ev) => {
                    toggleDropdown(true);
                    handleCustomSearch(_ev.target.value);
                  }}
                  onKeyDown={(_e) => onKeyDown(_e, filterableOptions)}
                  disabled={disabled}
                />
              )
            )}
          </div>

          {isLoadingOptions ? (
            <div className={styles.loadingContainer}>
              {isLoadingOptions && (
                <div
                  className={classes.loadingAnimation}
                  style={{ ...cssVariables }}
                  data-testid="loading-animation"
                >
                  <div className={classes.dot}></div>
                  <div className={classes.dot}></div>
                  <div className={classes.dot}></div>
                </div>
              )}

              {!isDropdownOnly ? (
                <Icon iconName="Search16Regular" tabIndex={-1} />
              ) : (
                <Icon iconName="ChevronDown20Regular" tabIndex={-1} />
              )}
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
              }}
            >
              {referencePicker?.onIconClick && (
                <Tooltip
                  content={LocalizationString.SHOW_ALL_RESULTS}
                  relationship="label"
                  withArrow
                >
                  <span
                    className={styles.showAllResultsIcon}
                    data-testid="button-show-all-results"
                  >
                    <Icon
                      iconName={
                        (referencePicker.iconName ??
                          'ContentViewGallery16Regular') as FluentIconNames
                      }
                      tabIndex={-1}
                      className={cn({
                        [styles.pointerCursor]: !isDisabled,
                      })}
                      onClick={
                        !isDisabled
                          ? (_) => {
                              _.stopPropagation();

                              isOptionsOpen && toggleDropdown();

                              referencePicker.onIconClick &&
                                referencePicker.onIconClick();
                            }
                          : undefined
                      }
                      aria-disabled={isDisabled}
                    />
                  </span>
                </Tooltip>
              )}

              {!isDropdownOnly ? (
                <Icon iconName="Search16Regular" tabIndex={-1} />
              ) : (
                <Icon iconName="ChevronDown20Regular" tabIndex={-1} />
              )}
            </div>
          )}
        </div>

        {isOptionsOpen &&
          createPortal(
            virtualized ? (
              <Virtualizer
                items={filterableOptions}
                itemRenderer={dropdownOption}
                positionRef={dropdownRef}
                noItemRenderer={noResultOption}
                styles={styles.dropdownOptions}
                rowHeight={34}
                containerHeight={200}
                lastElementRef={lastElementRef}
              />
            ) : (
              <div className={styles.dropdownOptions} ref={dropdownRef}>
                {filterableOptions.map((option) =>
                  dropdownOption(option, lastElementRef, isFetching)
                )}
                {filterableOptions?.length === 0 &&
                  !isLoadingOptions &&
                  noResultOption()}
              </div>
            ),
            document.body
          )}
      </div>
    </Field>
  );
};
