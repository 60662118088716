import { DateFormat, ROUTES } from '@celito.clients/enums';
import { TrainingAssignmentData } from '@celito.clients/types';
import { formatDate, isDateValid } from '@celito.clients/utils';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { Color } from 'libs/theme/src/lib/colors';

export const getAssignmentViewRow = (
  assignmentData: TrainingAssignmentData[]
) => {
  const rowData: RowItem[] = [];

  assignmentData.forEach((assignment) => {
    rowData.push({
      ...assignment,
      key: assignment.name,
      course: assignment.course.label,
      title: assignment.course.title,
      version: assignment.course.version,
      user__a: assignment.user.label,
      dueDate: isDateValid(assignment.dueDate)
        ? formatDate(assignment.dueDate || '', DateFormat.Date)
        : '',
      rowBackgroundColor: Color.gray_1600,
      onClickNavigateRoute: `../${ROUTES.TAKING_ASSINGMENTS.replace(
        ':name',
        assignment.name
      )}`,
    });
  });

  return rowData;
};
