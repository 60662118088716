export enum Environment {
  Local = 'Local',
  Testing = 'Test',
  Development = 'Develop',
  Experiments = 'Experiments',
  QA = 'QA',
  Staging = 'Staging',
  Production = 'Production',
  Uat = 'Uat',
  Platformqa = 'PlatformQA',
  Automation = 'Automation',
}

export const isValidEnvironment = (value: unknown): value is Environment => {
  return (
    typeof value === 'string' &&
    Object.values<string>(Environment).includes(value)
  );
};
