import {
  FormEngineModeTypeEnum,
  ObjectEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import {
  useLayout,
  useObjectDefinition,
  useQueryParams,
  useView,
} from '@celito.clients/hooks';
import { getAllTaskData } from '@celito.clients/services';
import { ObjectActionDefinition, Task } from '@celito.clients/types';
import { ActionContextProps } from 'libs/core/src/providers/action-provider/action-context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router';

import { TScreenContext } from './context';
import { getRecordDataQueryKey, useRecordData } from './hooks/use-record-data';
import { ControlledDocs } from './types';
import { getUserActions } from './utils';

export const useController = (mode: FormEngineModeTypeEnum) => {
  const [task, setTask] = useState<Task>();
  const [showEditDocumentButton, setShowEditDocumentButton] = useState(false);

  const { configureLayout } = useLayout();

  useEffect(() => {
    configureLayout({
      enablePadding: false,
      pageTitle: '',
    });
  }, []);

  const [userActions, setUserActions] = useState<ObjectActionDefinition[]>([]);

  const { getSearchParams } = useQueryParams();

  const { name: recordName = '' } = useParams();
  const { version = '', taskName } = getSearchParams();

  const { data: response, error } = useRecordData({
    recordParams: { recordName, version, viewType: mode },
    queryOptions: {
      enabled: !!recordName,
      retry: false,
    },
  });

  const queryClient = useQueryClient();

  const invalidateRecordCache = useCallback(() => {
    const queryKey = getRecordDataQueryKey(recordName, version);

    // Popping the view type from the query key
    // to make sure the cache is invalidated for all view types
    queryKey.pop();

    queryClient.invalidateQueries(queryKey);
  }, [queryClient, recordName, version]);

  useEffect(() => {
    if (response)
      configureLayout({
        pageTitle: '',
        enablePadding: false,
        headerTitle: response.title,
        checkInCheckOutIcon: {
          checkoutTime: response?.lastCheckedOutAtUtc,
          checkoutBy: response?.lastCheckedOutByUser?.label,
          checkinTime: response?.lastCheckedInAtUtc,
          checkinBy: response?.lastCheckedInByUser?.label,
          isLocked: response?.isLocked,
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response]);

  useView({
    view: 'controlled_document_custom_edit_view__a',
    queryOptions: {
      enabled: !!recordName,
    },
  });

  const { data: objectDefinition } = useObjectDefinition({
    objectName: ObjectEnum.CONTROLLED_DOCUMENT,
    recordName,
    version,
  });

  const populateUserActions = useCallback(
    (recordData: ControlledDocs) => {
      if (!objectDefinition) return;

      const userActions = getUserActions(
        recordData?.recordUserActions,
        objectDefinition?.objectActionDefinitions,
        !!task
      );

      userActions.sort((a, b) => {
        const attribute1 = a?.label?.toLowerCase() ?? a.name.toLowerCase();
        const attribute2 = b?.label?.toLowerCase() ?? b.name.toLowerCase();
        return attribute1 < attribute2 ? -1 : 1;
      });
      setUserActions(userActions);
      if (
        recordData.recordUserActions.some(
          (action) => action.name === 'cd_edit_document_action__a'
        )
      ) {
        setShowEditDocumentButton(true);
      } else {
        setShowEditDocumentButton(false);
      }
    },
    [setUserActions, task, objectDefinition]
  );

  const {
    isLoading: isTaskResponseLoading,
    isIdle,
    data: allTasks,
  } = useQuery(['allTasks', recordName, taskName, version], {
    queryFn: async () => {
      return await getAllTaskData(
        (() => {
          const filteredData = {
            filters: [
              {
                conditions: {
                  all: [
                    {
                      any: [
                        {
                          attribute: 'objectRecordId',
                          operator: OperatorsEnum.EQUALS,
                          value: {
                            objectName: 'controlled_document__a',
                            recordName: recordName,
                            version: version,
                          },
                        },
                      ],
                    },
                    {
                      any: [
                        {
                          attribute: 'object_id',
                          operator: OperatorsEnum.EQUALS,
                          value: 'controlled_document__a',
                        },
                      ],
                    },
                    {
                      any: [
                        {
                          attribute: 'status',
                          operator: OperatorsEnum.EQUALS,
                          value: 'Todo',
                        },
                      ],
                    },
                    {
                      any: [
                        {
                          attribute: 'owner__s',
                          operator: OperatorsEnum.EQUALS,
                          value: '@me',
                        },
                        {
                          attribute: 'delegates__s',
                          operator: OperatorsEnum.EQUALS,
                          value: '@me',
                        },
                        { attribute: 'owner__s', operator: 'isBlank' },
                      ],
                    },
                  ],
                },
              },
            ],
            sort: {
              attribute: 'modified_at_utc__s',
              order: 'DESC',
            },
          };
          if (taskName) {
            const taskNameFilter = {
              any: [
                {
                  attribute: 'name',
                  operator: OperatorsEnum.EQUALS,
                  value: taskName,
                },
              ],
            };
            filteredData?.filters[0]?.conditions?.all?.push(taskNameFilter);
          }
          return filteredData;
        })()
      );
    },
  });

  useEffect(() => {
    const task = allTasks?.data?.[0];
    if (task) {
      setTask(task);
    }
  }, [allTasks]);

  useEffect(() => {
    if (response) populateUserActions(response);
  }, [response, populateUserActions]);

  const checkActions = useMemo(
    () => ({
      onClickCheckInAction: invalidateRecordCache,
      onClickCheckOutAction: invalidateRecordCache,
    }),
    [invalidateRecordCache]
  );

  const refetchTaskData = async () => {
    const filteredData = {
      filters: [
        {
          conditions: {
            all: [
              {
                any: [
                  {
                    attribute: 'objectRecordId',
                    operator: OperatorsEnum.EQUALS,
                    value: {
                      objectName: 'controlled_document__a',
                      recordName: recordName,
                      version: version,
                    },
                  },
                ],
              },
              {
                any: [
                  {
                    attribute: 'object_id',
                    operator: OperatorsEnum.EQUALS,
                    value: 'controlled_document__a',
                  },
                ],
              },
              {
                any: [
                  {
                    attribute: 'status',
                    operator: OperatorsEnum.EQUALS,
                    value: 'Todo',
                  },
                ],
              },
              {
                any: [
                  {
                    attribute: 'owner__s',
                    operator: OperatorsEnum.EQUALS,
                    value: '@me',
                  },
                  {
                    attribute: 'delegates__s',
                    operator: OperatorsEnum.EQUALS,
                    value: '@me',
                  },
                  { attribute: 'owner__s', operator: 'isBlank' },
                ],
              },
            ],
          },
        },
      ],
      sort: {
        attribute: 'modified_at_utc__s',
        order: 'DESC',
      },
    };
    if (taskName) {
      const taskNameFilter = {
        any: [
          {
            attribute: 'name',
            operator: OperatorsEnum.EQUALS,
            value: taskName,
          },
        ],
      };
      filteredData?.filters[0]?.conditions?.all?.push(taskNameFilter);
    }
    const taskData = await getAllTaskData(filteredData);
    setTask(taskData?.data?.[0] || undefined);
  };
  if (error)
    throw error instanceof Error ? error : new Error('An error occurred');

  return {
    mode,
    recordName,
    populateUserActions,
    isTaskResponseLoading: isTaskResponseLoading || isIdle,
    userActions,
    showEditDocumentButton,
    task,
    version,
    setTask,
    checkActions,
    invalidateRecordCache,
    refetchTaskData,
  } satisfies TScreenContext & {
    checkActions: ActionContextProps;
  };
};
