import { FieldValues } from 'react-hook-form';

import { ControlledRulesPickerProps } from './controlled-rules-picker.model';
import { ControlledRulesPickerView } from './controlled-rules-picker.view';

interface ControlledRulesPickerControllerProps<T extends FieldValues>
  extends ControlledRulesPickerProps<T> {}

export const ControlledRulesPickerController = <T extends FieldValues>(
  props: ControlledRulesPickerControllerProps<T>
): JSX.Element => {
  return <ControlledRulesPickerView {...props} />;
};
