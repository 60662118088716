import { LocalizationString } from '@celito.clients/assets';
import {
  BREAKPOINTS,
  ButtonTypes,
  CompletionTypeEnum,
  FormEngineModeTypeEnum,
} from '@celito.clients/enums';
import {
  CustomButton,
  DatePicker,
  Field,
  FileUploadButton,
  Panel,
  TextField,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { TrainingAssignmentData } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

import { fileUploadConfig, trainingMaterialObjectName } from '../../config';
import classes from './sign-off-panel.module.css';
import { signOffPanelStyles } from './sign-off-panel-styles';

type FormValues = {
  justification: string;
  completionDate: Date;
  file__a: File;
};

interface SignOffPanelProps {
  dataTestId?: string;
  isOpen: boolean;
  closePanel: () => void;
  openEsignModal: (taskPayload?: Record<string, unknown>) => void;
  completionType?: CompletionTypeEnum;
  trainingAssignmentData: TrainingAssignmentData;
  isAdmin?: boolean;
}
const getConditionalValidation = <T extends yup.AnySchema>(
  schema: T,
  completionType: CompletionTypeEnum | undefined,
  conditionType: CompletionTypeEnum,
  isAdmin: boolean | undefined
) => {
  if (completionType !== conditionType || isAdmin) {
    return schema.required(LocalizationString.REQUIRED);
  } else {
    return schema.notRequired();
  }
};
const createValidationSchema = (
  completionType?: CompletionTypeEnum,
  isAdmin?: boolean
) => {
  return yup.object({
    justification: getConditionalValidation(
      yup.string(),
      completionType,
      CompletionTypeEnum.DocumentUpload,
      isAdmin
    ),
    completionDate: getConditionalValidation(
      yup.date(),
      completionType,
      CompletionTypeEnum.DocumentUpload,
      isAdmin
    ),
    file__a: getConditionalValidation(
      yup.mixed(),
      completionType,
      CompletionTypeEnum.TrainerSignOff,
      isAdmin
    ),
  }) as yup.ObjectSchema<FormValues>;
};
export const SignOffPanel = ({
  dataTestId,
  isOpen,
  closePanel,
  completionType,
  openEsignModal,
  trainingAssignmentData,
  isAdmin,
}: SignOffPanelProps): JSX.Element => {
  const { cssVariables } = useTheme();

  const styles = signOffPanelStyles();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      completionDate: new Date(),
      justification: '',
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(createValidationSchema(completionType, isAdmin)),
  });

  const { control, setValue, handleSubmit } = formMethods;

  const onSubmit = (data: FormValues) => {
    const taskFieldsData: Record<string, unknown>[] = [];

    if (data.file__a) {
      taskFieldsData.push({
        name: 'document__a',
        type: 'document',
        value: data.file__a.toString(),
      });
    }

    if (completionType !== CompletionTypeEnum.DocumentUpload || isAdmin) {
      taskFieldsData.push({
        name: 'justification__a',
        value: data.justification,
      });
      taskFieldsData.push({
        name: 'completion_date__a',
        value: data.completionDate.toISOString(),
      });
    }

    closePanel();
    openEsignModal({
      taskFieldsData: JSON.stringify(taskFieldsData),
      file: data.file__a,
    });
  };

  const onFormSubmit = () => handleSubmit(onSubmit)();

  return (
    <Panel
      data-testid={dataTestId}
      open={isOpen}
      onClose={closePanel}
      position="end"
      style={{
        width:
          window.innerWidth <= BREAKPOINTS.TABLET.SAFARI.MIN_WIDTH
            ? '100vw'
            : '465px',
      }}
      headerText={trainingAssignmentData.label}
    >
      <FormProvider {...formMethods}>
        <form noValidate>
          <div
            className={classes.document_completion}
            style={{ ...cssVariables }}
          >
            {(isAdmin ||
              completionType !== CompletionTypeEnum.DocumentUpload) && (
              <>
                <Controller
                  name="justification"
                  control={control}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextField
                        label={LocalizationString.JUSTIFICATION}
                        value={value}
                        onChange={(_e, newVal) => onChange(newVal.value || '')}
                        errorMessage={error?.message}
                        required
                      />
                    );
                  }}
                />

                <div className={classes.spacing}></div>

                <Controller
                  name="completionDate"
                  control={control}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <DatePicker
                        minDate={new Date(trainingAssignmentData.createdAtUtc)}
                        maxDate={new Date()}
                        onDateChange={(date) => onChange(date)}
                        value={value}
                        label={LocalizationString.TRAINING_COMPLETION_DATE}
                        required
                      />
                    );
                  }}
                />
                <div className={classes.spacing}></div>
              </>
            )}

            <div className={classes.document_upload_wrapper}>
              <Controller
                name="file__a"
                control={control}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => {
                  return (
                    <Field
                      label={LocalizationString.UPLOAD_DOCUMENT}
                      validationMessage={error?.message}
                      size="large"
                      validationState={error?.message ? 'error' : 'none'}
                      required={
                        completionType !== CompletionTypeEnum.TrainerSignOff ||
                        isAdmin
                      }
                      data-testid={`label-${createTestAttribute(
                        LocalizationString.UPLOAD_DOCUMENT
                      )}`}
                    >
                      <FileUploadButton
                        onFileSelect={(file) => onChange(file)}
                        attribute={fileUploadConfig}
                        documentId=""
                        objectName={trainingMaterialObjectName}
                        showCustomPreviewComponent={true}
                        mode={FormEngineModeTypeEnum.Edit}
                        error={error}
                        setValue={setValue}
                        fileName={value?.name}
                        allowedExtensions={fileUploadConfig.allowedExtensions}
                      />
                    </Field>
                  );
                }}
              />
            </div>
            <div className={styles.document_sign_off}>
              <CustomButton
                buttonType={ButtonTypes.Primary}
                onClick={onFormSubmit}
                buttonTitle={LocalizationString.TRAINING_SIGN_OFF}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </Panel>
  );
};
