import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { TrainingAssignmentData } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

import { trainingAssignmentObjectName } from '../config';

export const getTrainingAssignmentDetails = async (assignmentName: string) => {
  const response = await performRequest<TrainingAssignmentData>(
    `${Endpoints.OBJECT_DATA_URL}/${trainingAssignmentObjectName}/${assignmentName}`,
    ApiMethodType.GET
  );
  return response.data;
};
