import { ObjectEnum, SortOrderEnum } from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import { SortConfig } from '@celito.clients/list-view-engine';
import { UserContext } from '@celito.clients/provider';
import { GridViewProps } from '@celito.clients/shared';
import { isActionAllowed } from '@celito.clients/utils';
import {
  getIsSortedAscending,
  getSortOrder,
} from 'libs/list-view-engine/src/lib/utils/sort.util';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';
import { sortBy } from 'lodash';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ReactPaginateProps } from 'react-paginate';
import { useQuery } from 'react-query';

import { DocumentTypeService } from '../../../services/document-type';
import { TGetDocumentTypeListReponse } from '../../../services/types';
import { usePagination } from '../../hooks/use-pagination';
import { generateColumns } from './columns';

export type TRowItem = TGetDocumentTypeListReponse[number] & {
  documentSubTypeCode?: string;
  documentSubType?: string;
  documentSubTypeName: string;
  documentTypeName: string;
};

const PAGE_SIZE = 9;

const proxyHandler: ProxyHandler<TRowItem> = {
  get(target, prop: keyof TRowItem) {
    if (prop === 'documentTemplate') return target.documentTemplate?.title;
    const value = target[prop];
    if (typeof value === 'boolean') return value ? 'Yes' : 'No';
    const noDocumentSubTypes = !('documentType' in target);
    switch (prop) {
      case 'documentType':
        if (noDocumentSubTypes) return target.label;
        return target.documentType?.label;
      case 'code':
        if (noDocumentSubTypes) return target.code;
        return target.documentType?.code;
      case 'documentSubType':
        if (noDocumentSubTypes) return undefined;
        return target.label;
      case 'documentSubTypeCode':
        if (noDocumentSubTypes) return undefined;
        return target.code;
      case 'documentSubTypeName':
        if (noDocumentSubTypes) return undefined;
        return target.name;
      case 'documentTypeName':
        if (noDocumentSubTypes) return target.name;
        return target.documentType?.name;
    }
    return target[prop];
  },
};

type RowSortConfig = Omit<SortConfig, 'attribute'> & {
  attribute: keyof TRowItem;
};

export const useController = () => {
  const [columns, setColumns] = useState<GridViewProps['columns']>([]);
  const [data, setData] = useState<TRowItem[]>([]);
  const sortConfig = useRef<RowSortConfig>({
    attribute: 'createdAtUtc',
    order: SortOrderEnum.DESC,
  });
  const { configureLayout } = useLayout();
  const { user } = useContext(UserContext);

  useEffect(() => {
    configureLayout({
      pageTitle: 'Document Type',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sortData = useCallback((sortConf: RowSortConfig, data: TRowItem[]) => {
    const ascSorted = sortBy(data, [
      function (o) {
        let valueToBeCompared = o[sortConf.attribute];
        if (typeof valueToBeCompared === 'string') {
          valueToBeCompared = valueToBeCompared.toLowerCase();
        }
        return valueToBeCompared;
      },
    ]);
    if (sortConf.order === SortOrderEnum.DESC) {
      ascSorted.reverse();
    }
    return ascSorted;
  }, []);

  const onColumnClick: ColumnData['onColumnClick'] = useCallback(
    (_ev: React.MouseEvent<HTMLElement, MouseEvent>, column: ColumnData) => {
      if (column?.data?.isSortableColumn) {
        setColumns((prev) =>
          prev.map((c) => {
            if (c.key === column.key) {
              return {
                ...c,
                isSortedAscending: getIsSortedAscending(
                  c.isSorted,
                  c.isSortedAscending
                ),
                isSorted: true,
              };
            }
            return {
              ...c,
              isSorted: false,
            };
          })
        );
        if (!column.fieldName) return;
        const sortConf: RowSortConfig = {
          attribute: column.fieldName as keyof TRowItem,
          order: getSortOrder(column.isSorted, column.isSortedAscending),
        };
        sortConfig.current = sortConf;
        setData((prev) => sortData(sortConf, prev));
      }
    },
    [setData, sortData]
  );

  const {
    refetch,
    data: documentTypes,
    isLoading,
    isFetching,
  } = useQuery([ObjectEnum.DOCUMENT_TYPE], {
    queryFn: async () => {
      const { data } = await DocumentTypeService.getDocumentTypeList();
      return data;
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (documentTypes) {
      const proxiedData = documentTypes.map((item) => {
        const proxy = new Proxy(item, proxyHandler);
        Object.defineProperties(proxy, {
          documentSubType: { value: undefined, writable: true },
          documentTypeName: { value: undefined, writable: true },
          documentSubTypeName: { value: undefined, writable: true },
          documentSubTypeCode: { value: undefined, writable: true },
        });
        return proxy;
      }) as TRowItem[];

      const rowData = sortConfig.current
        ? sortData(sortConfig.current, proxiedData)
        : proxiedData;
      setData(rowData);
    }
  }, [documentTypes, sortData]);

  useEffect(() => {
    setColumns(generateColumns(onColumnClick, refetch));
  }, [onColumnClick, refetch]);

  const pagination = usePagination({ total: data.length, initialPage: 1 });

  const pageData = useMemo(() => {
    const start = (pagination.active - 1) * PAGE_SIZE;
    const end = start + PAGE_SIZE;
    return data.slice(start, end);
  }, [data, pagination.active]);

  const gridViewProps: GridViewProps = useMemo(() => {
    return {
      columns,
      items: pageData ? pageData : [],
      disableSelection: true,
      disableDefaultSorting: true,
      updateEmptyCells: true,
      enableShimmer: isLoading || isFetching,
    };
  }, [pageData, columns, isLoading, isFetching]);

  const paginationProps: Partial<ReactPaginateProps> = useMemo(() => {
    return {
      ...pagination,
      onPageChange: (page) => pagination.setPage(page.selected + 1),
      forcePage: pagination.active - 1,
      pageCount: Math.ceil(data.length / PAGE_SIZE),
      marginPagesDisplayed: 1,
    };
  }, [pagination, data]);

  const showAddNewButton = useMemo(
    () => isActionAllowed(user?.roles ?? [], null),
    [user?.roles]
  );

  /**
   * @description Hardcoded create route
   */
  const createRoute = useMemo(
    () => window.location.toString().replace(/list/g, 'create'),
    []
  );

  return {
    gridViewProps,
    paginationProps,
    data,
    showAddNewButton,
    createRoute,
  };
};
