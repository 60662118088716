import { environment } from 'libs/core/src/config/environment';

import { Environment } from '../singleton/environment-manager';

Environment.getInstance().setEnvironment(environment);

export const DateFormat = {
  DateTime:
    (Environment.getInstance().getParam('NX_DATE_TIME_FORMAT') as string) ||
    'dd-MMM-yyyy HH:mm',
  Date:
    (Environment.getInstance().getParam('NX_DATE_FORMAT') as string) ||
    'dd-MMM-yyyy',
};

export const SystemTimezone =
  (Environment.getInstance().getParam('NX_SYSTEM_TIMEZONE') as string) ||
  'Asia/Kolkata';

export type DateFormatEnumType = (typeof DateFormat)[keyof typeof DateFormat];

export enum TimeOfDayPrefenceEnum {
  START = 'START',
  END = 'END',
  CURRENT = 'CURRENT',
}
