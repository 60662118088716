import { SearchBoxChangeEvent } from '@fluentui/react-components';

import { SearchInputProps } from './search-input.model';
import SearchInputView from './search-input.view';

const SearchInputController = (props: SearchInputProps): JSX.Element => {
  const handleSearchKeyChange = (event: SearchBoxChangeEvent) => {
    const value = (event.target as HTMLInputElement).value;
    if (value !== undefined) props.onChange(value);
  };

  return (
    <SearchInputView
      value={props.value}
      placeholder={props.placeholder}
      onChange={props.onChange}
      handleSearchKeyChange={handleSearchKeyChange}
      onDismiss={() => props.onChange('')}
    />
  );
};

export default SearchInputController;
