import { UserContext } from '@celito.clients/provider';
import { Task } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';

import { GroupNames, TaskGroupedObject } from './task/types';
import { WorkflowHistoryProps } from './types';
import { getWorkflowHistory } from './workflow-actions/services';
import WorkflowHistoryView from './workflow-history.view';
export const WorkflowHistoryController = (props: WorkflowHistoryProps) => {
  const { objectName, recordName, recordData } = props;
  const [tasks, setTasks] = useState<TaskGroupedObject>();
  const [isLoading, setIsLoading] = useState(false);
  const [delegatedTask, setDelegatedTask] = useState<Task | undefined>();
  const loggedInUserData = useContext(UserContext);
  useEffect(() => {
    // dynamically updating the height of the popup
    if (props?.open) {
      const popup = props?.popupRef?.current;
      const main = document.querySelector('main');
      const showWorkflowButton = props?.showWorkflowDivRef?.current;
      const updatePopupHeight = () => {
        if (showWorkflowButton && main) {
          const mainLeftStyle = main.getBoundingClientRect().left;
          const start =
            showWorkflowButton?.getBoundingClientRect().bottom + window.scrollY;
          const end = main.getBoundingClientRect().bottom - 17 + window.scrollY;

          const height = end - start;
          const topPosition =
            showWorkflowButton.getBoundingClientRect().bottom + window.scrollY;

          if (popup) {
            popup.style.setProperty('left', `${-mainLeftStyle}px`, 'important');
            popup.style.height = `${height}px`;
            popup.style.top = `${topPosition}px`;
          }
        }
      };
      updatePopupHeight();
      window.addEventListener('resize', updatePopupHeight);
      // MutationObserver to observe attribute and style changes of popup if yes then will update its style
      const mutationObserver = new MutationObserver(updatePopupHeight);
      if (popup) {
        mutationObserver.observe(popup, {
          attributes: true,
          attributeFilter: ['style', 'class'],
        });
      }
      return () => {
        window.removeEventListener('resize', updatePopupHeight);
        mutationObserver.disconnect();
      };
    }
  }, [props?.open]);

  useEffect(() => {
    getWorkflowTasks(recordData?.version);
  }, [recordData]);

  const callWorkflowAPIOnEvent = (updatedVersion: string) => {
    getWorkflowTasks(updatedVersion);
  };

  const getWorkflowTasks = async (updatedVersion: string) => {
    setIsLoading(true);
    try {
      const taskList = await getWorkflowHistory(
        objectName!,
        recordName!,
        props?.objectDefinitions?.isVersioningEnabled
          ? updatedVersion ?? recordData?.version
          : ''
      );
      const task: TaskGroupedObject = {};
      if (taskList?.tasks.length) {
        const delegatedTask = taskList?.tasks?.find((task) => {
          if (!task?.delegatedUserNames) return false;

          const delegatedUserNames = JSON.parse(task.delegatedUserNames);
          return delegatedUserNames.includes(loggedInUserData?.user?.name);
        });
        setDelegatedTask(delegatedTask);
        let previousName: GroupNames | null = null;
        let previousId: string | null = null;
        taskList?.tasks.forEach((item) => {
          const { workflowStepInstanceId, objectRecordLifecycleGroup, label } =
            item;
          const id = workflowStepInstanceId.toString();
          const name = objectRecordLifecycleGroup as GroupNames;
          // Check if the current item's name is the same as the previous one
          if (name === previousName && previousId === id) {
            // If the name is the same, use the previousId instead of the current id
            if (!task[previousId]) {
              task[previousId] = {};
            }
            if (!task[previousId][name]) {
              task[previousId][name] = {};
            }
            if (!task[previousId][name]![label]) {
              task[previousId][name]![label] = [];
            }
            task[previousId][name]![label].push(item);
            task[previousId][name]![label] = _.orderBy(
              task[previousId][name]![label],
              ['assignedAtUtc', 'ownerName'],
              ['desc', 'asc']
            );
          } else {
            // If the name is different, use the current id
            if (!task[id]) {
              task[id] = {};
            }
            if (!task[id][name]) {
              task[id][name] = {};
            }
            if (!task[id][name]![label]) {
              task[id][name]![label] = [];
            }
            task[id][name]![label].push(item);
            task[id][name]![label] = _.orderBy(
              task[id][name]![label],
              ['assignedAtUtc', 'ownerName'],
              ['desc', 'asc']
            );
            previousId = id;
          }
          // Update previousName and previousId for the next iteration
          previousName = name;
        });
      }
      setIsLoading(false);
      setTasks(task);
    } catch (error) {
      raiseErrorToast(error);
      setIsLoading(false);
    }
  };

  return (
    <WorkflowHistoryView
      {...props}
      tasks={tasks || {}}
      isLoading={isLoading}
      callWorkflowAPIOnEvent={callWorkflowAPIOnEvent}
      delegatedTask={delegatedTask}
    />
  );
};
