import { useScript } from '@celito.clients/hooks';
import {
  makeStyles,
  mergeClasses,
  shorthands,
} from '@fluentui/react-components';
import React, { memo, useEffect } from 'react';

interface SplitViewProps {
  left: React.ReactElement;
  right: React.ReactElement;
  className?: string;
  showRightPanel?: boolean;
  dataTestId?: string;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const Split: any;

const getSplitStyles = makeStyles({
  splitView: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
  leftSplit: {
    height: '100%',
  },
  rightSplit: {
    ...shorthands.outline('none'),
    ...shorthands.flex(1, 1, '0%'),
    position: 'relative',
    height: '100%',
  },
});

export const SplitView: React.FunctionComponent<SplitViewProps> = memo(
  ({ showRightPanel = true, ...props }) => {
    if (!showRightPanel) return props.left;
    return <SplitComponent {...props} />;
  }
);

export const SplitComponent: React.FunctionComponent<SplitViewProps> = memo(
  ({ left, right, className, dataTestId }) => {
    const styles = getSplitStyles();
    const status = useScript(
      'https://cdnjs.cloudflare.com/ajax/libs/split.js/1.6.5/split.min.js',
      {
        removeOnUnmount: false,
      }
    );

    useEffect(() => {
      if (typeof Split !== 'undefined') {
        Split(['#split-0', '#split-1'], {
          minSize: [350, 400],
          gutterStyle: () => {
            return {
              width: '6px',
            };
          },
        });
      }
    }, [status]);

    return (
      <div
        data-testid={dataTestId}
        className={mergeClasses(styles.splitView, className)}
      >
        <div
          id="split-0"
          className={styles.leftSplit}
          data-testid={`button-dragger`}
        >
          {left}
        </div>
        <div id="split-1" className={styles.rightSplit}>
          {right}
        </div>
      </div>
    );
  }
);
