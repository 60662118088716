import { Color } from 'libs/theme/src/lib/colors';

const userAvatarColors = [
  Color.avatar_blue,
  Color.avatar_burgundy,
  Color.avatar_cyan,
  Color.avatar_darkGreen,
  Color.avatar_darkRed,
  Color.avatar_gold,
  Color.avatar_green,
  Color.avatar_lightRed,
  Color.avatar_magenta,
  Color.avatar_orange,
  Color.avatar_pink,
  Color.avatar_purple,
  Color.avatar_rust,
  Color.avatar_teal,
  Color.avatar_violet,
  Color.avatar_warmGray,
];

export default userAvatarColors;
