import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { removeSpecialCharacterFromSearchQuery } from 'apps/web-client/src/utils/remove-special-character';
import Endpoints from 'libs/core/src/config/endpoint';

export interface ISuggestionResponse {
  suggestions: string[];
  topResults: any;
}

export const getSearchSuggestions = async (
  query: string,
  currentModule: string
) => {
  const updatedQuery = removeSpecialCharacterFromSearchQuery(query);

  const url =
    currentModule.length > 0
      ? `${Endpoints.SEARCH_SUGGESTIONS}?query=${updatedQuery}&module=${currentModule}`
      : `${Endpoints.SEARCH_SUGGESTIONS}?query=${updatedQuery}`;

  const response = await performRequest<ISuggestionResponse>(
    url,
    ApiMethodType.GET
  );
  return response.data;
};
