/* eslint-disable no-case-declarations */
import { LocalizationString } from '@celito.clients/assets';
import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import {
  Field,
  LayoutRulesDataSchema,
  ObjectAttributeDefinition,
  ObjectAttributeType,
} from '@celito.clients/types';
import { OptionProps, RadioProps } from '@fluentui/react-components';
import { Styling } from 'libs/shared/src/lib/user-action-popup/user-action-popup.model';
import {
  Control,
  FieldValues,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';

import {
  ControlledPicklist,
  ControlledReferenceSelector,
  ControlledRulesPicker,
  ObjectDefinitionsDropdown,
} from '../components/form-components';
import ControlledDatePicker from '../components/form-components/controlled-datepicker/controlled-datepicker.component';
import ControlledDaterangePicker from '../components/form-components/controlled-daterange-picker/controlled-daterange-picker.component';
import ControlledDatetimePicker from '../components/form-components/controlled-datetime-picker/controlled-datetime-picker.component';
import ControlledDropdown from '../components/form-components/controlled-dropdown/controlled-dropdown.component';
import ControlledFileUpload from '../components/form-components/controlled-fileupload/controlled.file-upload.component';
import ControlledMultiFileUpload from '../components/form-components/controlled-multi-fileupload/controlled.multi-fileupload.component';
import ControlledNumberRangePicker from '../components/form-components/controlled-numberrange-picker/controlled-numberrange-picker.component';
import ControlledPicker from '../components/form-components/controlled-picker/controlled-picker.component';
import ControlledRadioGroup from '../components/form-components/controlled-radio-group/controlled-radio-group.component';
import ControlledRichText from '../components/form-components/controlled-richtext/controlled-richtext.component';
import ControlledTextFieldArea from '../components/form-components/controlled-textarea/controlled-textarea.component';
import ControlledTextField from '../components/form-components/controlled-textfield/controlled-textfield.component';
import SpaceComponent from '../components/form-components/space-component/space-component';
import { AttributeTypeEnum } from '../enums/attributes-enum';
import { Validation } from '../types/validation-types';
const nonEditableAttributes = [
  'is_approval_required__a',
  'content_approvers__a',
];

export const getAttributeConfig = <T extends FieldValues = FieldValues>(
  objectName: string,
  attribute: ObjectAttributeDefinition,
  control: Control<T>,
  mode: FormEngineModeTypeEnum,
  completeAttributeConfig: ObjectAttributeType,
  watch?: UseFormWatch<T>,
  setValue?: UseFormSetValue<T>,
  _trigger?: UseFormTrigger<T>,
  recordDetails: Record<string, unknown> = {},
  styles: Styling = {},
  excludeUsers?: string[]
) => {
  const props = {
    attribute,
    control,
    watch,
    setValue,
    mode,
    objectName,
    recordDetails,
    completeAttributeConfig,
    styles,
    excludeUsers,
  };

  switch (attribute.dataTypeKeyForFE ?? attribute.dataType) {
    case AttributeTypeEnum.Number:
      return ControlledTextField({ ...props, type: 'number' });
    case AttributeTypeEnum.PlainText:
      return ControlledTextField(props);
    case AttributeTypeEnum.LongText:
      return ControlledTextFieldArea(props);
    case AttributeTypeEnum.YesNo:
    case AttributeTypeEnum.ActiveInactive:
    case AttributeTypeEnum.Dropdown:
    case AttributeTypeEnum.RadioYesNo: {
      const dataType = attribute.dataTypeKeyForFE ?? attribute.dataType;
      let extraOptions: OptionProps[] | RadioProps[] = [
        { value: 'active', text: LocalizationString.ACTIVE },
        { value: 'inactive', text: LocalizationString.INACTIVE },
      ];

      if (dataType === AttributeTypeEnum.YesNo) {
        extraOptions = [
          { value: 'active', text: LocalizationString.YES },
          { value: 'inactive', text: LocalizationString.NO },
        ];
      }

      if (dataType === AttributeTypeEnum.RadioYesNo) {
        extraOptions = [
          { value: 'true', label: LocalizationString.YES },
          { value: 'false', label: LocalizationString.NO },
        ];
      }

      if (dataType === AttributeTypeEnum.RadioYesNo) {
        return ControlledRadioGroup({
          ...props,
          options: extraOptions as RadioProps[],
        });
      }

      return ControlledDropdown({
        ...props,
        options: extraOptions as OptionProps[],
      });
    }
    case AttributeTypeEnum.Reference:
      return ControlledPicker(props);
    case AttributeTypeEnum.RichText:
      return ControlledRichText({ ...props, value: '' });
    case AttributeTypeEnum.Document:
      return ControlledFileUpload(props);
    case AttributeTypeEnum.MultipleDocument:
      return ControlledMultiFileUpload(props);
    case AttributeTypeEnum.Date:
      return ControlledDatePicker(props);
    case AttributeTypeEnum.DateTime:
      return ControlledDatetimePicker(props);
    case AttributeTypeEnum.DateRange:
      return ControlledDaterangePicker(props);
    case AttributeTypeEnum.NumberRange:
      return ControlledNumberRangePicker(props);
    case AttributeTypeEnum.SetRule:
      return ControlledRulesPicker(props);
    case AttributeTypeEnum.Picklist:
      return ControlledPicklist(props);
    case AttributeTypeEnum.ReferenceDocuments:
    case AttributeTypeEnum.ReferenceSelector:
      return ControlledReferenceSelector(props);
    case AttributeTypeEnum.ObjectDefinition:
      return ObjectDefinitionsDropdown(props);
    default:
      return SpaceComponent({});
  }
};

export const getAttributeByColumnName = (
  attributeConfig: ObjectAttributeType,
  columnName: string
) => {
  const attribute = (attributeConfig?.objectAttributeDefinitions || []).filter(
    (attribute) =>
      attribute.name === columnName || attribute.columnName === columnName
  )[0];
  return { ...attribute };
};

export const getAttributeByKeyName = (
  attributeConfig: ObjectAttributeType,
  columnName: string
) => {
  const attribute = (attributeConfig?.objectAttributeDefinitions || []).filter(
    (attribute) => attribute.key === columnName
  )[0];
  return { ...attribute };
};

export const getFieldContainer = <T extends FieldValues = FieldValues>(
  objectName: string,
  attributeConfig: ObjectAttributeType = {} as ObjectAttributeType,
  columnName: string,
  control: Control<T>,
  watch: UseFormWatch<T>,
  setValue: UseFormSetValue<T>,
  trigger: UseFormTrigger<T>,
  mode: FormEngineModeTypeEnum,
  recordDetails: Record<string, unknown> = {},
  rules: Validation[] = [],
  field: (LayoutRulesDataSchema & Field) | undefined = undefined,
  styling: Styling = {}
) => {
  let attribute = getAttributeByColumnName(attributeConfig, columnName);

  if (rules?.length) {
    attribute.rules = rules;
  }

  if (field) {
    attribute = {
      ...attribute,
      ...field,
      isEditable:
        attribute.isEditable === false
          ? false
          : attribute.isEditable === undefined ||
            (attribute.isEditable && field.isEditable),
    };
  }
  return getAttributeConfig<T>(
    objectName,
    attribute,
    control,
    mode,
    attributeConfig,
    watch,
    setValue,
    trigger,
    recordDetails,
    styling
  );
};

export const getFieldDataObject = (
  columnName: string,
  recordDetails: Record<string, unknown>,
  attributeConfig: ObjectAttributeType
) => {
  const attribute = getAttributeByColumnName(attributeConfig, columnName);

  const value = (recordDetails?.[attribute.key] ||
    recordDetails?.[attribute.columnName]) as Record<string, unknown> | string;

  return {
    label: attribute.label,
    value: value,
    dataType: attribute.dataType,
    dataTypeKeyForFE: attribute.dataTypeKeyForFE,
  };
};

export const getLifeCycleStageGroups = (
  attributeObject: ObjectAttributeType,
  currentLifeCycleStage: string
) => {
  const lifecycleStageGroups = attributeObject?.lifecycleStageGroups;
  if (lifecycleStageGroups) {
    const lifecycleStages: string[] = [];
    const lifeCycleStageGroupEntries = Object.entries(lifecycleStageGroups);

    lifeCycleStageGroupEntries
      .sort((a, b) => a[1].displayOrder - b[1].displayOrder)
      .forEach((lifeCycleStageGroupEntry) => {
        const lifeCycleStageGroup = lifeCycleStageGroupEntry[1];
        const stageName = lifeCycleStageGroupEntry[0];

        if (lifeCycleStageGroup.displayActively) {
          lifecycleStages.push(stageName);
        } else {
          // if the displayActively is false check that the current lifecycle state is present in the lifecycleStageGroups, if yes then show in the chevron
          if (lifeCycleStageGroup?.stages?.includes(currentLifeCycleStage)) {
            lifecycleStages.push(stageName);
          }
        }
      });
    return lifecycleStages;
  }
};

export const getActiveLifeCycleStage = (
  attributeObject: ObjectAttributeType,
  recordLifecycleStage: string
) => {
  const wizardStates = attributeObject?.lifecycleStageGroups;
  for (const wizard in wizardStates) {
    if (wizardStates[wizard]?.stages.includes(recordLifecycleStage)) {
      return wizard;
    }
  }
};
