import { NumberRangePickerProps } from './number-range-picker.model';
import NumberRangePickerView from './number-range-picker.view';

interface NumberRangePickerControllerProps extends NumberRangePickerProps {}

const NumberRangePickerController = (
  props: NumberRangePickerControllerProps
): JSX.Element => {
  return <NumberRangePickerView {...props} />;
};

export default NumberRangePickerController;
