import withLayout from 'apps/web-client/src/hoc/with-layout';
import React from 'react';

import DelegationListController from './delegation-list.controller';

const DelegationListScreen: React.FC = () => {
  return <DelegationListController />;
};

export default withLayout(DelegationListScreen);
