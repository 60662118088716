import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledMultiFileUploadController } from './controlled.multi-fileupload.controller';
import { ControlledMultiFileUploadProps } from './controlled.multi-fileupload.model';

const ControlledMultiFileUpload = <T extends FieldValues>(
  props: ControlledMultiFileUploadProps<T>
) => {
  return <ControlledMultiFileUploadController {...props} />;
};

export default ControlledMultiFileUpload;
