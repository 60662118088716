import { ButtonTypes, SpinnerSize } from '@celito.clients/enums';
import { Color } from '@celito.clients/theme';
import { createTestAttribute } from '@celito.clients/utils';
import { Button, mergeClasses } from '@fluentui/react-components';

import { Icon, Loader } from '../../shared';
import { CustomButtonViewProps } from './custom-button.model';
import { customButtonStyles } from './custom-button.styles';

const getIcon = (iconName?: string) => {
  if (iconName)
    switch (iconName) {
      case 'Download':
        return <Icon iconName="ArrowDownload24Regular" />;
      case 'Filter':
        return <Icon iconName="Filter24Regular" />;
      case 'AddIn':
        return <Icon iconName="AppsAddIn24Regular" />;
      case 'Edit':
        return <Icon iconName="Edit24Regular" />;
      case 'Save':
        return <Icon iconName="Save24Regular" />;
      case 'Previous':
        return <Icon iconName="ChevronLeft24Regular" />;
      case 'Next':
        return <Icon iconName="ChevronRight24Regular" />;
      case 'Add':
        return <Icon iconName="Add24Regular" />;
      case 'AddSquare':
        return <Icon iconName="TabAdd24Regular" />;
      case 'Submit':
        return <Icon iconName="Send24Regular" />;
      case 'Delete':
        return (
          <Icon iconName="Delete24Regular" color={Color.DELETE_ICON_COLOR} />
        );
      case 'ArrowDown':
        return <Icon iconName="ChevronDown24Regular" />;
      case 'ArrowUp':
        return <Icon iconName="ChevronUp24Regular" />;
      case 'Close':
        return <Icon iconName="Dismiss24Regular" />;
      case 'More':
        return <Icon iconName="MoreHorizontal24Regular" />;
      case 'Preview':
        return <Icon iconName="Eye16Regular" />;
      case 'Search':
        return <Icon iconName="Search16Regular" />;
      case 'Copy':
        return <Icon iconName="Copy24Regular" />;
      case 'Flow':
        return <Icon iconName="Flowchart24Regular" />;
      case 'Trigger':
        return <Icon iconName="TextColumnOneWideLightning20Regular" />;
    }
};

const CustomButtonView: React.FC<CustomButtonViewProps> = ({
  buttonTitle,
  onButtonClicked,
  isDisabled = false,
  leftIcon,
  rightIcon,
  onSubmit,
  dataTestId,
  isLoading = false,
  size,
  buttonType,
  customStyles,
  disabled,
  ref,
  ...rest
}) => {
  const styles = customButtonStyles();

  const iconName = leftIcon || rightIcon;

  const btnDisabled = isDisabled || disabled;

  return (
    <Button
      ref={ref}
      className={mergeClasses(
        styles.buttonStyles,
        buttonType === ButtonTypes.Primary && styles.primary,
        buttonType === ButtonTypes.Secondary && styles.secondary,
        buttonType === ButtonTypes.Ghost && styles.ghost,
        buttonType === ButtonTypes.Link && styles.link,
        buttonType === ButtonTypes.Blue && styles.blueButton,
        buttonType === ButtonTypes.Text && styles.text,
        buttonType === ButtonTypes.White && styles.white,
        buttonType === ButtonTypes.Transparent && styles.transparent,
        buttonType === ButtonTypes.Destructive && styles.destructive,
        customStyles
      )}
      onClick={typeof onSubmit === 'function' ? onSubmit : onButtonClicked}
      disabled={btnDisabled}
      data-testid={
        dataTestId
          ? dataTestId
          : buttonTitle
          ? `button-${createTestAttribute(buttonTitle)}`
          : `button-${createTestAttribute(iconName)}`
      }
      icon={
        !isLoading ? (
          getIcon(iconName)
        ) : (
          <Loader
            withLabel={false}
            color={Color.gray_100}
            size={SpinnerSize.tiny}
          />
        )
      }
      iconPosition={leftIcon ? 'before' : 'after'}
      {...rest}
    >
      {buttonTitle}
    </Button>
  );
};

export default CustomButtonView;
