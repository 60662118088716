import { ROUTES } from '@celito.clients/enums';
import { ListView } from '@celito.clients/list-view-engine';
import { Navigate } from 'react-router';

interface ListViewScreenViewProps {
  viewName?: string;
}

const ListViewScreenView = ({
  viewName,
}: ListViewScreenViewProps): JSX.Element => {
  if (!viewName) {
    return <Navigate to={`../${ROUTES.PAGE_NOT_FOUND}`} />;
  }

  return (
    <div>
      <ListView viewName={viewName} key={viewName} />
    </div>
  );
};

export default ListViewScreenView;
