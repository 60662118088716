import { WidgetOptions } from '@okta/okta-signin-widget';

interface OktaAuthConfig {
  issuer: string;
  clientId: string;
  redirectUri: string;
}

const oktaAuthConfig: OktaAuthConfig = {
  issuer: 'https://celitotech.okta.com',
  clientId: '0oa2nho6jkvUpBANt697',
  redirectUri: 'https://env79.crm.dynamics.com',
};

const oktaSignInConfig: WidgetOptions = {
  baseUrl: 'https://celitotech.okta.com',
  clientId: '0oa2nho6jkvUpBANt697',
  redirectUri: 'https://env79.crm.dynamics.com',
  useInteractionCodeFlow: true,
  authParams: {
    issuer: 'https://celitotech.okta.com',
  },
  features: {
    rememberMe: false,
  },
  i18n: {
    en: {
      'errors.E0000004':
        'Login verification was unsuccessful. Please try again.',
    },
  },
  // useClassicEngine: true,
};

export { oktaAuthConfig, oktaSignInConfig };
