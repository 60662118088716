import { ButtonTypes, DateFormat } from '@celito.clients/enums';
import { CustomButton, GridView } from '@celito.clients/shared';
import { DocData } from '@celito.clients/types';
import { formatDate, isDateValid } from '@celito.clients/utils';
import { makeStyles } from '@fluentui/react-components';
import { CustomButtonProps } from 'libs/shared/src/lib/custom-button/src/custom-button.model';
import { ColumnData } from 'libs/shared/src/lib/grid-view-new/src/types';

import { getDocumentFile } from '../../utils';
import { FieldContainer, Label } from './field';
import { TCustomFieldProps } from './index';

const useStyles = makeStyles({
  btn: {
    backgroundColor: 'transparent',

    ':hover': {
      backgroundColor: 'transparent',
    },

    ':disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
  },
  gridView: {
    marginTop: '2px',
  },
});

export const Button = (props: CustomButtonProps) => {
  const styles = useStyles();

  return (
    <CustomButton
      as="button"
      buttonType={ButtonTypes.Destructive}
      customStyles={styles.btn}
      leftIcon="Download"
      title="Download file"
      {...props}
    />
  );
};

export default ({ record, attribute, fieldProps }: TCustomFieldProps) => {
  const { document, version } = record ?? {};
  const { disableDownload } = fieldProps ?? {};

  const columns: ColumnData[] = (() => {
    return [
      {
        fieldName: 'label',
        isResizable: true,
        key: 'column1',
        maxWidth: 250,
        minWidth: 150,
        name: 'Document Name',
      },
      {
        name: 'Uploaded Date',
        isResizable: true,
        key: 'column2',
        maxWidth: 250,
        minWidth: 150,
        fieldName: 'uploadedAtUtc',
        onRender: (item: any) =>
          isDateValid(new Date(item?.uploadedAtUtc))
            ? formatDate((item?.uploadedAtUtc as string) || '', DateFormat.Date)
            : '',
        disableAlphanumericSorting: true,
      },
      {
        name: 'Action',
        isResizable: true,
        key: 'actions',
        maxWidth: 250,
        minWidth: 150,
        fieldName: 'action',
        onRender: (item: DocData & { key: string }) => {
          return (
            <Button
              leftIcon="Download"
              disabled={!!disableDownload}
              onClick={
                !disableDownload
                  ? (e) => {
                      return (
                        record?.name &&
                        getDocumentFile(
                          item.key,
                          record.name,
                          version,
                          version // earlier it was document?.version. Now we will have one version which is record's version
                        )
                      );
                    }
                  : undefined
              }
              children="Download"
              dataTestId="button-download-document"
            />
          );
        },
      },
    ];
  })();

  const styles = useStyles();

  return (
    <FieldContainer>
      {attribute?.label && <Label label={attribute.label} />}
      <GridView
        className={styles.gridView}
        columns={columns}
        items={[
          {
            label: document?.nameWithExtension,
            uploadedAtUtc: document?.createdAtUtc,
            action: 'Download',
            key: document?.documentId ?? '',
          },
        ]}
        disableSelection={true}
      />
    </FieldContainer>
  );
};
