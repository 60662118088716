import { LocalizationString } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  ControlledDocumentObjectAttributeNameEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import {
  LayoutRuleConditionEnum,
  ObjectActionDefinition,
  ObjectAttributeType,
  OwnerType,
  WorkflowControlsDefinition,
  WorkflowControlVariableDataTypeEnum,
} from '@celito.clients/types';
import { TimeOfDayPrefenceEnum } from 'libs/core/src/enums/date-formats';
import RolesContextManager from 'libs/core/src/singleton/roles-context-manager';

import { userObjectName } from '../../../config';
import { AttributeTypeEnum } from '../../../enums/attributes-enum';
import { Permission } from '../../../enums/permission-enum';
import { MappingTypeEnum } from '../../form-components/controlled-picker/controlled-picker.model';
import { Controls, WorkflowControlTypeEnum } from './actions-handler.model';
const rolesContextManager = RolesContextManager.getInstance();
export const getControlDataKey = (
  key: string,
  controlActions: WorkflowControlsDefinition[]
) => {
  const filteredControls = controlActions?.filter((control) => {
    if (control?.controlConfig?.attributeName) {
      return control?.controlConfig?.attributeName === key;
    }
    return control?.name === key;
  });
  return filteredControls[0]?.name;
};

export const getDataTypes = (dataType: WorkflowControlVariableDataTypeEnum) => {
  switch (dataType) {
    case WorkflowControlVariableDataTypeEnum.DATE_ON_INITIATION:
      return AttributeTypeEnum.Date;
    case WorkflowControlVariableDataTypeEnum.LONG_TEXT:
      return AttributeTypeEnum.LongText;
    case WorkflowControlVariableDataTypeEnum.PLAIN_TEXT:
      return AttributeTypeEnum.PlainText;
    case WorkflowControlVariableDataTypeEnum.PICKLIST:
      return AttributeTypeEnum.Picklist;
    case WorkflowControlVariableDataTypeEnum.YES_OR_NO:
      return AttributeTypeEnum.YesNo;
    case WorkflowControlVariableDataTypeEnum.USER_ON_INITIATION:
      return AttributeTypeEnum.Reference;
    case WorkflowControlVariableDataTypeEnum.USER_OBJECT_ROLES:
      return AttributeTypeEnum.PlainText;
    default:
      return AttributeTypeEnum.PlainText;
  }
};

export const transformBasedOnControlType = (
  controls: WorkflowControlsDefinition,
  index: number,
  attributeConfig: ObjectAttributeType
) => {
  const tranformControls: Controls[] = [];
  switch (controls?.controlType) {
    case WorkflowControlTypeEnum.FIELD_PROMPT:
      if (controls?.controlConfig?.attributeName) {
        const newRule = {
          columnName: controls.controlConfig.attributeName as string,
          width: 3,
          row: index + 1,
          layoutConfiguration: {
            jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
            layoutConfig: {
              value: {
                isEditable: controls.permission !== Permission.VIEW,
                isRequired:
                  (controls.permission || controls.defaultRule) ===
                  Permission.REQUIRED,
                isVisible:
                  (controls.permission || controls.defaultRule) !==
                  Permission.HIDDEN,
              },
            },
          },
        };
        tranformControls.push(newRule);
        const attrIndex = attributeConfig.objectAttributeDefinitions?.findIndex(
          (obj) => {
            return obj.name === controls?.controlConfig?.attributeName;
          }
        );
        handleAllowMultiSelect(attrIndex, attributeConfig, controls);
      }
      break;
    case WorkflowControlTypeEnum.VARIABLE:
      {
        setVaribaleAttributeConfig(controls, attributeConfig);
        const variableControlsConfig = {
          columnName: controls.name,
          width: 3,
          row: index + 1,
          layoutConfiguration: {
            jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
            layoutConfig: {
              value: {
                isEditable:
                  controls?.controlConfig?.dataType !==
                    WorkflowControlVariableDataTypeEnum.USER_OBJECT_ROLES &&
                  controls.permission !== Permission.VIEW,
                isRequired:
                  (controls.permission || controls.defaultRule) ===
                  Permission.REQUIRED,
                isVisible:
                  (controls.permission || controls.defaultRule) !==
                  Permission.HIDDEN,
              },
            },
          },
        };
        tranformControls.push(variableControlsConfig);
      }
      break;
    default:
      break;
  }
  return tranformControls;
};

export const modifiedPayload = (
  action: ObjectActionDefinition,
  formData: Record<string, string>[]
) => {
  const formValue = formData?.find((data) => !!data.value);
  const value = formValue?.value;
  switch (action.actionType) {
    case ActionTypeEnum.REASSIGN_TASK: {
      if (formValue) {
        return {
          ownerControl: {
            [formValue.label]: value,
          },
        };
      } else {
        return {};
      }
    }
    case ActionTypeEnum.CHNAGE_DUE_DATE_TASK: {
      return {
        dueDate: value,
      };
    }
    default:
      return {};
  }
};
const setVaribaleAttributeConfig = (
  controls: WorkflowControlsDefinition,
  attributeConfig: ObjectAttributeType
) => {
  const variableAttributeConfig = {
    label: controls?.label,
    name: controls?.name,
    isRequired:
      (controls.permission || controls.defaultRule) === Permission.REQUIRED,
    columnName: controls?.name,
    dataType: getDataTypes(
      controls.controlConfig.dataType as WorkflowControlVariableDataTypeEnum
    ),
    displayInList: true,
    isAuditable: true,
    isEditable: true,
    isIdAppendedInColumnNameInDb: false,
    isInternal: false,
    isMandatory:
      (controls.permission || controls.defaultRule) === Permission.REQUIRED,
    key: controls?.name,
    ownerType: OwnerType.System,
    roles: (controls?.controlConfig?.roles as string[])?.length
      ? (controls?.controlConfig?.roles as string[])
      : [],
    picklistName: controls?.controlConfig?.picklistName as string,
    rules: [
      {
        errorMessage: LocalizationString.REQUIRED_MSG,
        type: LocalizationString.REQUIRED.toLocaleLowerCase(),
      },
    ],
    relationship: {
      label: controls?.label,
      ownerType: OwnerType.Custom,
      name: controls?.name,
      createdAtUtc: '',
      modifiedAtUtc: '',
      deletedAtUtc: null,
      isActive: true,
      relationType: '',
      objectName:
        controls?.controlConfig?.dataType ===
        WorkflowControlVariableDataTypeEnum.USER_ON_INITIATION
          ? userObjectName
          : '',
      deletionType: '',
      assosiatedField: '',
      objectMappingType: (controls?.controlConfig?.allowMultiSelect as boolean)
        ? MappingTypeEnum.ManyToMany
        : MappingTypeEnum.OneToOne,
    },
    defaultReferenceFilter: [
      {
        attribute: ControlledDocumentObjectAttributeNameEnum.IS_ACTIVE,
        operator: OperatorsEnum.EQUALS,
        value: true,
      },
    ],
    timeOfDayPreference: TimeOfDayPrefenceEnum.END,
  };
  const variableConfigIndex =
    attributeConfig.objectAttributeDefinitions?.findIndex((obj) => {
      return obj.key === variableAttributeConfig?.key;
    });
  if (variableConfigIndex !== -1) {
    attributeConfig.objectAttributeDefinitions[variableConfigIndex].dataType =
      variableAttributeConfig?.dataType;
  }
  if (variableConfigIndex === -1) {
    attributeConfig.objectAttributeDefinitions.push(variableAttributeConfig);
  }
};
const handleAllowMultiSelect = (
  attrIndex: number,
  attributeConfig: ObjectAttributeType,
  controls: WorkflowControlsDefinition
) => {
  if (attrIndex !== -1) {
    if (attributeConfig.objectAttributeDefinitions[attrIndex].relationship) {
      if ('allowMultiSelect' in controls.controlConfig) {
        attributeConfig.objectAttributeDefinitions[
          attrIndex
        ].relationship!.objectMappingType = (controls.controlConfig
          .allowMultiSelect as boolean)
          ? MappingTypeEnum.ManyToMany
          : MappingTypeEnum.OneToOne;
      }
    }
    attributeConfig.objectAttributeDefinitions[attrIndex].label =
      controls?.label;
  }
};

export const getRolesLabel = (roles: string[]) => {
  const rolesBasedData = rolesContextManager.getRolesByNames(
    roles?.length ? roles : []
  );

  // Initialize an empty array to store the labels
  const roleLabels: string[] = [];

  // Extract the role names (keys) from rolesBasedData
  const roleNames = Object.keys(rolesBasedData);

  // Iterate over the role names to extract labels
  roleNames.forEach((roleName) => {
    const roleData = rolesBasedData[roleName];
    if (roleData?.label) {
      roleLabels.push(roleData.label);
    }
  });

  // Return the array of labels
  return roleLabels?.length ? roleLabels.join(', ') : '';
};
