import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { getSmartGroupViewUrl } from '@celito.clients/utils';
import { convert } from 'html-to-text';
import { Link } from 'react-router-dom';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { CustomButton } from '../../../shared';
import classes from '../reference-selector.module.css';
export const getSmartGroupsSelectorGridColumns = (
  onItemDelete?: (item: RowItem) => void,
  isPreviewTab?: boolean,
  onColumnClick?: ColumnData['onColumnClick']
) => {
  const smartGroupGridColumns: ColumnData[] = [
    {
      name: 'User Group ID',
      isResizable: true,
      key: 'label',
      maxWidth: 200,
      minWidth: 100,
      fieldName: 'label',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item) => (
        <Link
          to={getSmartGroupViewUrl(item.name)}
          className={classes.hyperlink}
          target="_blank"
        >
          {item.label}
        </Link>
      ),
    },
    {
      name: 'Title',
      isResizable: true,
      key: 'title',
      maxWidth: 200,
      minWidth: 100,
      fieldName: 'title',
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      name: 'Description',
      isResizable: true,
      key: 'description',
      maxWidth: 200,
      minWidth: 100,
      fieldName: 'description',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item: RowItem) => {
        const description = (item?.description as string) ?? '';
        return description ? convert(description) : '-';
      },
    },
    {
      key: 'actions',
      minWidth: 75,
      maxWidth: 100,
      name: LocalizationString.ACTIONS,
      fieldName: 'actions',
      isResizable: true,
      onRender: (item?: RowItem, index?: number, column?: ColumnData) => (
        <CustomButton
          as="button"
          buttonType={ButtonTypes.Destructive}
          customStyles={classes.delete_btn}
          leftIcon="Delete"
          title="Delete"
          dataTestId="button-delete-smart-group"
          onClick={isPreviewTab ? undefined : () => onItemDelete?.(item!)}
          disabled={isPreviewTab}
        />
      ),
    },
  ];

  return smartGroupGridColumns;
};
