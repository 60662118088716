import { ENV } from '../config/constants';
import { SCRIPTS, Scripts } from '../config/scripts';

type LoadedScripts = Record<
  Scripts['name'],
  Scripts & {
    loaded: boolean;
  }
>;

export class ScriptService {
  private static instance: ScriptService;
  private static scripts: LoadedScripts = {} as LoadedScripts;

  private constructor() {}

  public static getInstance(): ScriptService {
    if (!ScriptService.instance) {
      ScriptService.instance = new ScriptService();
      ScriptService.instance.init();
    }

    return ScriptService.instance;
  }

  loadAllForCurrentEnv() {
    const allScriptsNamesForEnv = Object.values(ScriptService.scripts)
      .filter(
        (script) =>
          script.environments === undefined || script.environments.includes(ENV)
      )
      .map((script) => script.name);

    if (allScriptsNamesForEnv.length < 1) return;

    this.load(...allScriptsNamesForEnv);
  }

  load(...scriptsNames: Scripts['name'][]) {
    const promises: unknown[] = [];
    scriptsNames.forEach((script) => {
      promises.push(this.loadScript(script));
    });
    return Promise.all(promises);
  }

  private loadScript(name: Scripts['name']) {
    return new Promise((resolve) => {
      // resolve if already loaded
      if (ScriptService.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = ScriptService.scripts[name].src;

        script.onload = () => {
          ScriptService.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };

        script.onerror = () =>
          resolve({ script: name, loaded: false, status: 'Loaded' });
        document.getElementsByTagName('head')[0].appendChild(script);
      }
    });
  }

  private init() {
    SCRIPTS.forEach((script) => {
      ScriptService.scripts[script.name] = {
        ...script,
        loaded: false,
      };
    });
  }
}
