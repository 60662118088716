import { ObjectEnum } from '@celito.clients/enums';
import { useQuery, UseQueryOptions } from 'react-query';

import { useScreenContext } from '../context';
import { getControlledDocsData } from '../services';
import { ControlledDocs } from '../types';

export const getRecordDataQueryKey = (
  recordName?: string,
  version = '',
  viewType = 'view'
) => [ObjectEnum.CONTROLLED_DOCUMENT, recordName, version, 'data', viewType];

export type TUseRecordDataOptions<T> = {
  queryOptions?: UseQueryOptions<T>;
  recordParams?: { recordName: string; version: string; viewType?: string };
};

/**
 * React hook for fetching CD object data from the meta data api
 *
 * cached: boolean - if true, the query result will come from the cache if available
 */
export const useRecordData = <T extends ControlledDocs>(
  { queryOptions, recordParams }: TUseRecordDataOptions<T> = {
    queryOptions: {},
  }
) => {
  const context = useScreenContext();

  const { version, recordName } = recordParams ?? context;
  const viewType = recordParams?.viewType ?? context.mode;
  return useQuery<T>(getRecordDataQueryKey(recordName, version, viewType), {
    queryFn: async () => {
      const res = await getControlledDocsData(recordName!, version, viewType);
      return res as T;
    },
    enabled: !!recordParams,
    ...queryOptions,
  });
};
