import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { CustomButton } from '../../../shared';
import classes from '../reference-selector.module.css';

export const getUserSelectorGridColumn = (
  onItemDelete?: (item: RowItem) => void,
  isPreviewTab?: boolean,
  onUserView?: (name: string) => void,
  onColumnClick?: ColumnData['onColumnClick']
) => {
  const userGridColumn: ColumnData[] = [
    {
      name: 'Member Name',
      isResizable: true,
      key: 'label',
      maxWidth: 200,
      minWidth: 100,
      fieldName: 'label',
      onColumnClick,
      showSortIconWhenUnsorted: true,
      onRender: (item) => (
        <div
          onClick={() => {
            if (onUserView) onUserView(item.name);
          }}
          className={classes.hyperlink}
        >
          {item.label}
        </div>
      ),
    },
    {
      name: 'Department',
      isResizable: true,
      key: 'department',
      maxWidth: 200,
      minWidth: 100,
      fieldName: 'department.label',
      onColumnClick,
      showSortIconWhenUnsorted: true,
    },
    {
      key: 'actions',
      minWidth: 75,
      maxWidth: 100,
      name: LocalizationString.ACTIONS,
      fieldName: 'actions',
      isResizable: true,
      onRender: (item?: RowItem, index?: number, column?: ColumnData) => (
        <CustomButton
          as="button"
          buttonType={ButtonTypes.Destructive}
          customStyles={classes.delete_btn}
          leftIcon="Delete"
          title="Delete"
          onClick={isPreviewTab ? undefined : () => onItemDelete?.(item!)}
          disabled={isPreviewTab}
        />
      ),
    },
  ];
  return userGridColumn;
};
