import { DateFormat } from '@celito.clients/enums';
import { createTestAttribute, getZonedBasedTime } from '@celito.clients/utils';
import { mergeClasses } from '@fluentui/react-components';
import { DatePicker } from '@fluentui/react-datepicker-compat';
import { formatDate } from 'date-fns';
import React from 'react';

import { Field } from '../shared';
import { DatePickerProps } from './date-picker.model';

interface DatePickerViewProps extends DatePickerProps {
  onSelectDateHandler: (date: Date | null | undefined) => void;
}

const DatePickerView: React.FC<DatePickerViewProps> = ({
  label = '',
  minDate,
  value,
  maxDate,
  onDateChange,
  onInputChange,
  error,
  disabled = false,
  stylesProps,
  size = 'large',
  helperLabelText,
  required,
  onSelectDateHandler,
  handleDateChange,
  ...rest
}) => {
  const datePickerRef = React.useRef<HTMLInputElement>(null);

  return (
    <Field
      size={size}
      label={label}
      helperTextLabel={helperLabelText}
      required={required}
      validationMessage={error?.message}
      validationState={error?.message ? 'error' : 'none'}
      data-testid={`label-${createTestAttribute(label)}`}
    >
      <DatePicker
        className={mergeClasses(stylesProps)}
        data-testid={`date-picker-${createTestAttribute(label)}`}
        ref={datePickerRef}
        disabled={disabled}
        showGoToToday={false}
        allowTextInput
        minDate={minDate ? getZonedBasedTime(minDate) : undefined}
        maxDate={maxDate ? getZonedBasedTime(maxDate) : undefined}
        value={value ? getZonedBasedTime(value) : null}
        onSelectDate={onSelectDateHandler}
        onChange={onInputChange}
        today={getZonedBasedTime(new Date())}
        {...rest}
        formatDate={(date) => (date ? formatDate(date, DateFormat.Date) : '')}
      />
    </Field>
  );
};
export default DatePickerView;
