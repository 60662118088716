import { createTestAttribute } from '@celito.clients/utils';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import { components, OptionProps } from 'react-select';

const getStyles = makeStyles({
  disableOption: {
    color: 'dimgray !important',
  },
});

export const Option = <T,>(props: OptionProps<T>) => {
  const styles = getStyles();
  const dataTestId = props.label.includes('(')
    ? createTestAttribute(props.label)?.match(/\((.*?)\)/)?.[1]
    : createTestAttribute(props.label);
  return (
    <components.Option
      {...props}
      className={mergeClasses(
        props.className,
        props.isDisabled && styles.disableOption
      )}
    >
      <span data-testid={`dropdown-option-${dataTestId}`}>{props.label}</span>
    </components.Option>
  );
};
