import { flexRender, Header } from '@tanstack/react-table';
import cn from 'classnames';
import { ConditionalTooltip, Icon } from 'libs/shared/src/lib/shared';
import { MouseEventHandler, useEffect, useRef } from 'react';

import { ColumnMeta } from '../../grid-view.model';
import { RowItem } from '../../types';
import classes from './table-header.styles.module.css';

interface TableCellProps {
  dataTestId?: string;
  header: Header<RowItem, unknown>;
  isClientSortable: boolean;
  onClickHandler: MouseEventHandler<HTMLTableCellElement> | undefined;
}

const TableHeader = ({
  dataTestId,
  header,
  isClientSortable,
  onClickHandler,
}: TableCellProps) => {
  const wasJustResizedRef = useRef(false);
  const thRef = useRef<HTMLTableCellElement | null>(null);

  const isCurrentlyResizing = header.column.getIsResizing();

  /**
   * Accidental taps while resizing can trigger sorting.
   * Prevent this by disabling button for 200ms after resize
   * is complete
   **/
  useEffect(() => {
    let wasJustResizedTimeout: NodeJS.Timeout;

    if (isCurrentlyResizing) {
      wasJustResizedRef.current = true;
    } else {
      wasJustResizedTimeout = setTimeout(() => {
        wasJustResizedRef.current = false;
      }, 200);
    }

    return () => {
      clearTimeout(wasJustResizedTimeout);
    };
  }, [isCurrentlyResizing]);

  const isClientSideSortable = isClientSortable && header.column.getCanSort();
  const clientSideSortingHandler = header.column.getToggleSortingHandler();
  const {
    isFixed = false,
    isServerSorted = false,
    serverSortDirection = false,
    showSortIconWhenUnsorted = false,
  } = (header.column.columnDef.meta || {}) as ColumnMeta;

  const handleOnClick: MouseEventHandler<HTMLTableCellElement> = (e) => {
    if (wasJustResizedRef.current) return;

    if (onClickHandler) onClickHandler(e);
    if (isClientSideSortable && clientSideSortingHandler) {
      clientSideSortingHandler(e);
    }
  };

  const isSortable =
    isClientSideSortable || isServerSorted || showSortIconWhenUnsorted;

  const sortDirection = isClientSideSortable
    ? header.column.getIsSorted()
    : serverSortDirection;

  const headerValue = header.column.columnDef.header;
  const tooltipContent = typeof headerValue === 'string' ? headerValue : '';

  return (
    <th
      data-testid={dataTestId}
      ref={thRef}
      colSpan={header.colSpan}
      style={{
        width: header.getSize(),
      }}
      onClick={handleOnClick}
      className={cn(classes.tableHeader, {
        [classes.isSortable]: isSortable,
        [classes.isFixed]: isFixed,
      })}
    >
      <div
        style={{
          display: 'flex',
        }}
      >
        <ConditionalTooltip
          className={classes.content}
          containerRef={thRef}
          tooltipContent={tooltipContent}
        >
          {/* Content */}
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </ConditionalTooltip>
        {/* Sort Icons */}
        {isSortable && (
          <span className={classes.sortIconWrapper}>
            {sortDirection &&
              {
                asc: (
                  <Icon
                    iconName="ArrowSortUp16Regular"
                    data-testid="sort-arrow-up"
                  />
                ),
                desc: (
                  <Icon
                    iconName="ArrowSortDown16Regular"
                    data-testid="sort-arrow-down"
                  />
                ),
              }[sortDirection]}
            {!sortDirection && (
              <Icon
                iconName="ArrowSort16Regular"
                data-testid="sort-arrow-default"
              />
            )}
          </span>
        )}
        {/* Resizer */}
        {header.column.getCanResize() && (
          <div
            onMouseDown={header.getResizeHandler()}
            onTouchStart={header.getResizeHandler()}
            className={cn(classes.resizer, {
              [classes.isResizing]: isCurrentlyResizing,
            })}
          />
        )}
      </div>
    </th>
  );
};

export default TableHeader;
