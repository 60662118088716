import type { FieldValues } from 'react-hook-form';

import { FileUploadController } from './file-upload.controller';
import { FileUploadButtonProps } from './file-upload.model';

const FileUploadButton = <T extends FieldValues>(
  props: FileUploadButtonProps<T>
): JSX.Element => {
  return <FileUploadController {...props} />;
};

export default FileUploadButton;
