import { DownloadIcon } from '@celito.clients/assets';
import { Icon } from '@celito.clients/shared';
import {
  Button,
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList as TabListPrim,
  TabValue,
} from '@fluentui/react-components';
import * as React from 'react';

import { useSelectedTabHighlighter } from '../hooks/use-tab-highlighter';
import { getStyles } from '../styles';

const SCROLL_OFFSET = 100;

const useScrollTabIntoView = (
  selectedTabView: TabValue,
  hideKeyDates: boolean
) => {
  const generalRef = React.useRef<React.ElementRef<'button'>>(null);
  const keyRef = React.useRef<React.ElementRef<'button'>>(null);
  const userRef = React.useRef<React.ElementRef<'button'>>(null);
  const docRef = React.useRef<React.ElementRef<'button'>>(null);
  const followRef = React.useRef<React.ElementRef<'button'>>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    switch (selectedTabView) {
      case 'General':
        containerRef.current?.scrollTo({
          left: generalRef.current?.offsetLeft,
          behavior: 'smooth',
        });
        break;
      case 'User Roles & Due Dates':
        containerRef.current?.scrollTo({
          left: userRef.current?.offsetLeft,
          behavior: 'smooth',
        });
        break;
      case 'Document':
        containerRef.current?.scrollTo({
          left: docRef.current?.offsetLeft,
          behavior: 'smooth',
        });
        break;
      case 'Follow Up':
        containerRef.current?.scrollTo({
          left: followRef.current?.offsetLeft,
          behavior: 'smooth',
        });
        break;
      case 'Key Dates':
        containerRef.current?.scrollTo({
          left: keyRef.current?.offsetLeft,
          behavior: 'smooth',
        });
        break;
      default:
        break;
    }
  }, [selectedTabView, hideKeyDates]);

  return {
    generalRef,
    keyRef,
    userRef,
    docRef,
    followRef,
    containerRef,
  };
};

const TabList = (props: {
  hideKeyDates: boolean;
  sectionRefs: Array<{ ref: React.RefObject<HTMLDivElement>; tabName: string }>;
  mcContainerRef: React.RefObject<HTMLDivElement>;
  scrollToSelectedPanel: (e: SelectTabEvent, d: SelectTabData) => void;
}) => {
  const newStyles = getStyles();
  const [selectedTabView, setSelectedTabView] =
    React.useState<TabValue>('General');

  useSelectedTabHighlighter(props.sectionRefs, setSelectedTabView, {
    root: props.mcContainerRef.current,
    threshold: [0, 0.2, 0.3, 0.5, 1],
  });

  const { hideKeyDates } = props;

  const { generalRef, keyRef, docRef, followRef, userRef, containerRef } =
    useScrollTabIntoView(selectedTabView, true);

  return (
    <div className={newStyles.tablistContainer}>
      <Button
        appearance="subtle"
        onClick={() => {
          if (containerRef.current)
            containerRef.current.scrollLeft -= SCROLL_OFFSET;
        }}
        className={newStyles.scrollButton}
        icon={<Icon iconName="ArrowLeft24Regular" />}
        data-testid="button-left-arrow"
      />

      <TabListPrim
        className={newStyles.tablist}
        defaultSelectedValue="General"
        ref={containerRef}
        onTabSelect={(event: SelectTabEvent, data: SelectTabData) => {
          props.scrollToSelectedPanel?.(event, data);
        }}
      >
        <>
          <Tab
            value="General"
            className={newStyles.tabs}
            data-testid="tab-general"
            aria-selected={selectedTabView === 'General'}
            ref={generalRef}
            onFocus={(e) => e.preventDefault()}
          >
            <span
              className={selectedTabView === 'General' ? newStyles.tabText : ''}
            >
              General
            </span>

            {selectedTabView === 'General' ? (
              <img src={DownloadIcon} alt="Download" />
            ) : null}
          </Tab>
          <Tab
            value="User Roles & Due Dates"
            className={newStyles.tabs}
            data-testid="tab-user-roles-&-due-dates"
            aria-selected={selectedTabView === 'User Roles & Due Dates'}
            onFocus={(e) => e.preventDefault()}
            ref={userRef}
          >
            <span
              className={
                selectedTabView === 'User Roles & Due Dates'
                  ? newStyles.tabText
                  : ''
              }
            >
              User Roles & Due Dates
            </span>
            {selectedTabView === 'User Roles & Due Dates' ? (
              <img src={DownloadIcon} alt="Download" />
            ) : null}
          </Tab>
          <Tab
            value="Document"
            className={newStyles.tabs}
            onFocus={(e) => e.preventDefault()}
            data-testid="tab-document"
            aria-selected={selectedTabView === 'Document'}
            ref={docRef}
          >
            <span
              className={
                selectedTabView === 'Document' ? newStyles.tabText : ''
              }
            >
              Document
            </span>
            {selectedTabView === 'Document' ? (
              <img src={DownloadIcon} alt="Download" />
            ) : null}
          </Tab>
          <Tab
            value="Follow Up"
            onFocus={(e) => e.preventDefault()}
            className={newStyles.tabs}
            ref={followRef}
            data-testid="tab-follow-up"
            aria-selected={selectedTabView === 'Follow Up'}
          >
            <span
              className={
                selectedTabView === 'Follow Up' ? newStyles.tabText : ''
              }
            >
              Follow Up
            </span>
            {selectedTabView === 'Follow Up' ? (
              <img src={DownloadIcon} alt="Download" />
            ) : null}
          </Tab>
          {!hideKeyDates && (
            <Tab
              value="Key Dates"
              onFocus={(e) => e.preventDefault()}
              className={newStyles.tabs}
              data-testid="tab-key-dates"
              ref={keyRef}
              aria-selected={selectedTabView === 'Key Dates'}
            >
              <span
                className={
                  selectedTabView === 'Key Dates' ? newStyles.tabText : ''
                }
              >
                Key Dates
              </span>
              {selectedTabView === 'Key Dates' ? (
                <img src={DownloadIcon} alt="Download" />
              ) : null}
            </Tab>
          )}
        </>
      </TabListPrim>
      <Button
        onClick={() => {
          if (containerRef.current)
            containerRef.current.scrollLeft += SCROLL_OFFSET;
        }}
        className={newStyles.scrollButton}
        appearance="subtle"
        icon={<Icon iconName="ArrowRight24Regular" />}
        data-testid="button-right-arrow"
      />
    </div>
  );
};

export default TabList;
