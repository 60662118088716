import {
  AttributeTypeEnum,
  DocumentSubTypeObjectKeyEnum,
} from '@celito.clients/enums';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { useWatch } from 'react-hook-form';

import { TFieldValues } from '../screens/edit/controller';
import { Field } from './field';

type DocumentTypeProps = {
  attributes: Record<DocumentSubTypeObjectKeyEnum, ObjectAttributeDefinition>;
};

export const FollowUpFields = ({ attributes }: DocumentTypeProps) => {
  const followUpRequired = useWatch<TFieldValues>({
    name: 'isFollowUpRequired',
  });
  if (!followUpRequired || followUpRequired === 'false')
    // Passing two empty divs to satisfy the css grid
    return (
      <>
        <div />
        <div />
      </>
    );
  return (
    <>
      <Field
        attribute={
          {
            name: 'followUpTriggerPeriodInDays',
            label: attributes.followUpTriggerPeriodInDays?.label,
            helpText: attributes.followUpTriggerPeriodInDays?.helpText,
            dataTypeKeyForFE: AttributeTypeEnum.Number,
            isEditable: true,
            isMandatory: true,
          } as ObjectAttributeDefinition
        }
      />
      <Field
        attribute={
          {
            name: 'followUpTimePeriodInMonths',
            label: attributes.followUpTimePeriodInMonths?.label,
            helpText: attributes.followUpTimePeriodInMonths?.helpText,
            dataTypeKeyForFE: AttributeTypeEnum.Number,
            isEditable: true,
            isMandatory: true,
          } as ObjectAttributeDefinition
        }
      />
    </>
  );
};
