import { AttributeTypeEnum, OperatorsEnum } from '@celito.clients/enums';
import { ObjectAttributeType } from '@celito.clients/types';
import { GetSearchParamsFunc } from 'libs/core/src/hooks/useQueryParams';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { SortConfig, View } from '../types';

export const getDefaultFiltersAndRecordData = (
  viewData: View | undefined,
  rawColumnData: ObjectAttributeType | null,
  getSearchParams: GetSearchParamsFunc
) => {
  const defaultFilters = viewData?.filterViews?.find(
    (filters) => filters.isDefault
  )?.filtersToBeApplied[0];

  let defaultSortConfig: SortConfig | undefined;
  if (viewData?.defaultSortColumn && viewData?.defaultSortOrder) {
    defaultSortConfig = {
      attribute: viewData.defaultSortColumn,
      order: viewData.defaultSortOrder,
    };
  }

  let recordData: RulesComponentListViewFilter | undefined;

  const params = getSearchParams();
  const recordName = params?.recordname;
  const objectName = params?.objectname;

  if (recordName && objectName) {
    const recortAttributeName = rawColumnData?.objectAttributeDefinitions.find(
      (item) => item.dataType === AttributeTypeEnum.Record
    )?.name;
    const objectAttributeName = rawColumnData?.objectAttributeDefinitions.find(
      (item) => item.dataType === AttributeTypeEnum.ObjectDefinition
    )?.name;

    if (recortAttributeName && objectAttributeName) {
      recordData = {
        conditions: {
          all: [
            {
              all: [
                {
                  attribute: recortAttributeName,
                  operator: OperatorsEnum.EQUALS,
                  value: {
                    recordName,
                    objectName,
                  },
                },
                {
                  attribute: objectAttributeName,
                  operator: OperatorsEnum.EQUALS,
                  value: objectName,
                },
              ],
            },
          ],
        },
      };
    }
  }

  return {
    defaultFilters,
    recordData,
    defaultSortConfig,
  };
};
