import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import { ButtonTypes, TrainingPlanStatusEnum } from '@celito.clients/enums';
import { useConfigureLayout, useURLParams } from '@celito.clients/hooks';
import {
  ConfirmDialog,
  CustomButton,
  FormActionButtonsContainer,
  Stack,
} from '@celito.clients/shared';
import {
  ControlledDocumentRequestTypeEnum,
  Field,
  Section,
  TrainingPlanRequestTypeEnum,
} from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { useFormEngineContext } from '../../../hooks';
import { getFieldContainer } from '../../../utils/attribute-config';
import { groupFieldsByRow } from '../../../utils/helper';
import FormContainer from '../../helper-components/form-container/form-container';
import { ConfirmButton } from '../confirm-button/confirm-button.component';
import { getPageComponent } from '../page-component/page-component';
import { SectionView } from '../section/section';
import { AddScreenProps } from './add-screen.model';
import classes from './add-screen.module.css';

const AddScreenView: React.FC<AddScreenProps> = (props) => {
  const {
    sections = [],
    attributeConfig,
    step,
    onCancel,
    onSave,
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    onFormSubmit,
    isSaveModalOpen,
    isWarningModalOpen,
    isSubmitModalOpen,
    toggleWarningModalBtnClick,
    toggleSaveModalBtnClick,
    toggleSubmitModalBtnClick,
    dataTestIdForSaveModal,
    dataTestIdForWarningModal,
    mode,
    objectName = '',
    totalSteps,
    goToNextStep,
    goToPreviousStep,
    goToEditScreen,
    methods,
    isCancelModalOpen,
    toggleCancelModalBtnClick,
    isLoading,
    viewDto,
    isSubmitting,
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    isWizardStateVisible,
    ...rest
  } = props;

  useConfigureLayout({
    pageTitle: '',
    enablePadding: false,
  });

  const { urlParams } = useURLParams();
  const isTrainingPlanRequest =
    urlParams.get('requestType') ===
    TrainingPlanRequestTypeEnum.NewTrainingPlan;
  const isControlledDocumentRequest =
    urlParams.get('requestType') ===
    ControlledDocumentRequestTypeEnum.NewDocument;
  const isTrainingPlanView =
    window.location.pathname.includes('training_plan_create_view__a') ||
    window.location.pathname.includes('training_plan_edit_view__a');

  const isSubmitDisabled =
    isLoading ||
    isSubmitting ||
    props.wizardStepsState.some((step) => step.isValid === false);

  const isVersioningAndLifeCycleDisabled =
    !attributeConfig?.isVersioningEnabled &&
    !attributeConfig?.isLifecycleEnabled;

  const {
    unregister,
    formState: { isDirty },
  } = useFormContext();
  const { fieldsState } = useFormEngineContext();

  const fieldsStateStringified = JSON.stringify(fieldsState);

  // Unregister fields when they become hidden
  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified);

    (sections[step]?.fields || []).forEach((field: Field) => {
      if (
        !fieldsStateParsed[field.columnName]?.isVisible &&
        field.columnName !== 'space'
      ) {
        unregister(field.columnName);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue(field.columnName, null);
      }
    });
  }, [fieldsStateStringified, unregister, props.watch]);

  const groupedFieldsByRow = groupFieldsByRow(sections[step]?.fields || []);

  const Row = useCallback(
    ({ fields }: { fields: Field[] }) => {
      if (fields.every((field) => !fieldsState[field.columnName]?.isVisible)) {
        return null;
      }

      return (
        <Stack className={classes.row}>
          {fields.map((field) => {
            if (!fieldsState[field.columnName]?.isVisible) {
              return null;
            }

            return (
              <SectionView width={field.width} key={field.columnName}>
                {getFieldContainer(
                  objectName,
                  attributeConfig,
                  field.columnName,
                  control,
                  watch,
                  setValue,
                  trigger,
                  mode,
                  undefined,
                  undefined,
                  { ...field, ...fieldsState[field.columnName] }
                )}
              </SectionView>
            );
          })}
        </Stack>
      );
    },
    [fieldsState]
  );

  const sectionTypeLayout = (
    sections: Section[],
    onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => {
    if (sections[step]?.pageComponent) {
      return getPageComponent(sections[step].pageComponent, onCancel, props);
    }
    if (sections[step]?.fields.length > 0) {
      return (
        <>
          <FormContainer className={classes.rowContainer}>
            {Object.entries(groupedFieldsByRow).map(([row, fields]) => (
              <Row key={row} fields={fields} />
            ))}
          </FormContainer>

          <FormActionButtonsContainer>
            <CustomButton
              buttonTitle="Cancel"
              buttonType={ButtonTypes.Ghost}
              onClick={onCancel}
              disabled={isLoading || isSubmitting}
            />
            <div className={classes.formActionBtns}>
              {step > 0 && (
                <CustomButton
                  buttonTitle="Previous"
                  leftIcon="Previous"
                  buttonType={ButtonTypes.Ghost}
                  onClick={goToPreviousStep}
                  disabled={isLoading || isSubmitting}
                />
              )}
              {step < totalSteps - 1 && (
                <CustomButton
                  buttonTitle="Next"
                  leftIcon="Next"
                  buttonType={ButtonTypes.Ghost}
                  onClick={goToNextStep}
                  disabled={isLoading || isSubmitting}
                />
              )}
              {!(
                step === totalSteps - 1 && isVersioningAndLifeCycleDisabled
              ) && (
                <CustomButton
                  buttonTitle={'Save'}
                  leftIcon="Save"
                  buttonType={ButtonTypes.Secondary}
                  isLoading={isLoading}
                  disabled={isLoading || isSubmitting || !isDirty}
                  onSubmit={
                    !isLoading
                      ? () =>
                          onSave?.(false, undefined, {
                            ...(isTrainingPlanRequest && {
                              request_type__s: 'NewTrainingPlan',
                              status__a: TrainingPlanStatusEnum.Draft,
                            }),
                            ...(isControlledDocumentRequest && {
                              initiation_type__a: urlParams.get('requestType'),
                            }),
                          })
                      : undefined
                  }
                />
              )}
              {step === totalSteps - 1 && (
                <ConfirmButton
                  buttonTitle={'Submit'}
                  leftIcon="Submit"
                  buttonType={ButtonTypes.Primary}
                  isLoading={isSubmitting}
                  disabled={isSubmitDisabled}
                  onSubmit={
                    !isSubmitting
                      ? () =>
                          onSave?.(
                            !isTrainingPlanView,
                            {
                              ...(isTrainingPlanRequest && {
                                isSubmit: true,
                              }),
                            },
                            {
                              ...(isTrainingPlanRequest && {
                                request_type__s: 'NewTrainingPlan',
                                status__a: TrainingPlanStatusEnum.Draft,
                              }),
                              ...(isControlledDocumentRequest && {
                                initiation_type__a:
                                  urlParams.get('requestType'),
                              }),
                            }
                          )
                      : undefined
                  }
                  dialogProps={{
                    primaryButtonText: LocalizationString.SUBMIT,
                    title: LocalizationString.SUBMIT,
                    description:
                      viewDto?.view?.[0]?.submitConfirmationPopupMessage,
                    secondaryButtonText: LocalizationString.CANCEL,
                    iconSrc: WarningIcon,
                  }}
                  showSubmitConfirmationPopup={Boolean(
                    viewDto?.view?.[0]?.showSubmitConfirmationPopup
                  )}
                />
              )}
            </div>
          </FormActionButtonsContainer>
        </>
      );
    }
  };

  return (
    <form
      onSubmit={onFormSubmit}
      noValidate
      encType="multipart/form-data"
      className={classes.form}
    >
      <Stack
        data-testid="dialouge-container"
        className={classes.formContainer}
        {...rest}
      >
        {sectionTypeLayout(sections, onCancel)}
      </Stack>
      <ConfirmDialog
        dataTestId={`cancel-modal-${createTestAttribute(
          LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL
        )}`}
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
      <ConfirmDialog
        dataTestId={dataTestIdForWarningModal}
        open={isWarningModalOpen}
        onCancelClicked={toggleWarningModalBtnClick}
        onConfirmClicked={toggleWarningModalBtnClick}
        primaryButtonText={LocalizationString.GOT_IT}
        title={LocalizationString.ERROR}
        iconSrc={WarningIcon}
        description={LocalizationString.VALIDATION_DESCRIPTION}
      />
      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SAVE_REQUEST
        )}`}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.SAVE_REQUEST}
        iconSrc={SaveIcon}
        description={LocalizationString.SAVED_DESCRIPTION}
      />
      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SUBMIT_REQUEST
        )}`}
        open={isSubmitModalOpen}
        onCancelClicked={toggleSubmitModalBtnClick}
        onConfirmClicked={toggleSubmitModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.SUBMIT_REQUEST}
        iconSrc={SaveIcon}
        description={LocalizationString.SUBMIT_DESCRIPTION}
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </form>
  );
};

export default AddScreenView;
