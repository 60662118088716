import { ROUTES } from '@celito.clients/enums';
import { errorToast } from '@celito.clients/utils';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { createSearchParams } from 'react-router-dom';

import { QuickSearchProps } from './quick-search.model';
import QuickSearchView from './quick-search.view';
import { getDetailsViewUrl } from './utils/quick-search.utils';

const QuickSearchController: React.FC<QuickSearchProps> = (props) => {
  const { isOpen, onClose } = props;

  const [quickSearchInput, setQuickSearchInput] = useState<string>('');
  const outSideClickRef = useRef<null | HTMLDivElement>(null);
  const navigate = useNavigate();
  const params = useParams();
  const { moduleName } = params;

  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setQuickSearchInput('');
        onClose();
      }
    };
    const handleOutSideClick = (event: any) => {
      const className =
        typeof event.target.className === 'object'
          ? event.target?.className?.baseVal
          : event.target?.className;
      if (
        outSideClickRef?.current &&
        !outSideClickRef.current?.contains(event.target) &&
        !className.includes('_rightSideActionIcon')
      ) {
        onClose();
      }
    };

    document.addEventListener('keydown', handleEscKeyPress);
    document.addEventListener('click', handleOutSideClick);

    window.addEventListener('beforeunload', () => {
      document.removeEventListener('keydown', handleEscKeyPress);
      document.addEventListener('click', handleOutSideClick);
    });

    return () => {
      document.removeEventListener('keydown', handleEscKeyPress);
      document.removeEventListener('click', handleOutSideClick);
    };
  }, []);

  const onQuickSearchInputChangeHandler = (data: string) => {
    setQuickSearchInput(data);
  };

  const onQuickSearch = (searchData?: string) => {
    const queryValue = searchData ?? quickSearchInput;

    if (queryValue.length > 2) {
      setQuickSearchInput('');
      onClose();
      navigate({
        pathname: `${ROUTES.LAYOUT.replace(':moduleName', `${moduleName}`)
          .replace(':tabName', 'search__s')
          .replace(':subTabName', 'default')}/custom/search`,
        search: `?${createSearchParams({
          value: queryValue,
        })}`,
      });
    }
  };

  const onTopResultClick = (detailViewName: string, recordName: string) => {
    if (detailViewName) {
      const url = getDetailsViewUrl(detailViewName, recordName);
      navigate(url);
      onClose();
    } else {
      errorToast({
        message: `Unable to display ${recordName}`,
      });
    }
  };

  return (
    <QuickSearchView
      isOpen={isOpen}
      onCloseQuickSearchHandler={onClose}
      quickSearchInput={quickSearchInput}
      onInputChangeHandler={onQuickSearchInputChangeHandler}
      onQuickSearch={onQuickSearch}
      onTopResultClickHandler={onTopResultClick}
      outSideClickRef={outSideClickRef}
    />
  );
};

export default QuickSearchController;
