import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledTextFieldAreaController } from './controlled-textarea.controller';
import { ControlledTextFieldAreaProps } from './controlled-textarea.model';

const ControlledTextFieldArea = <T extends FieldValues>(
  props: ControlledTextFieldAreaProps<T>
) => {
  return <ControlledTextFieldAreaController {...props} />;
};

export default ControlledTextFieldArea;
