import { useState } from 'react';

import { Notification } from '../../in-app-notifications.model';
import InAppNotificationsListView from './in-app-notifications-list.view';
interface InAppNotificationsListControllerProps {
  notifications: Notification[] | [];
  readNotification: (id: number) => Promise<void>;
  removeNotification: (id: number) => Promise<void>;
  handleRemove: (id: number) => void;
  hasMoreNotifications: boolean;
  loadMoreNotifications: () => void;
  onDismissInAppNotifPanel: () => void;
  isNotificationsLoading: boolean;
  isRemoveAllNotifsLoading: boolean;
  refreshNotifications: () => Promise<void>;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
}

const InAppNotificationsListController: React.FC<
  InAppNotificationsListControllerProps
> = ({
  notifications,
  readNotification,
  handleRemove,
  loadMoreNotifications,
  hasMoreNotifications,
  onDismissInAppNotifPanel,
  isNotificationsLoading,
  isRemoveAllNotifsLoading,
  refreshNotifications,
  scrollableContainerRef,
}) => {
  const [selectedNotification, setSelectedNotification] =
    useState<Notification | null>(null);
  const handleMoreClick = (
    notification: Notification,
    event: React.MouseEvent<HTMLElement>
  ) => {
    event.stopPropagation();
    setSelectedNotification(notification);
  };

  return (
    <InAppNotificationsListView
      notifications={notifications}
      handleMoreClick={handleMoreClick}
      selectedNotification={selectedNotification}
      handleRemove={handleRemove}
      readNotification={readNotification}
      loadMoreNotifications={loadMoreNotifications}
      hasMoreNotifications={hasMoreNotifications}
      onDismissInAppNotifPanel={onDismissInAppNotifPanel}
      isNotificationsLoading={isNotificationsLoading}
      isRemoveAllNotifsLoading={isRemoveAllNotifsLoading}
      refreshNotifications={refreshNotifications}
      scrollableContainerRef={scrollableContainerRef}
    />
  );
};

export default InAppNotificationsListController;
