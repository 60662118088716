import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  ConfirmDialog,
  Container,
  CustomButton,
  Field,
  FieldSelector,
  InHouseInputSelect,
  Loader,
  Stack,
  StackItem,
  TextField,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { createTestAttribute } from '@celito.clients/utils';
import { mergeClasses, Switch, Textarea } from '@fluentui/react-components';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { ObjectDefinitionType } from '../../../settings/notifications/notification-form/notification-form.model';
import classes from './edit-document-data.module.css';
import { documentTemplateStyles } from './edit-document-data.styles';

const Row: React.FC<{
  children: JSX.Element | JSX.Element[];
  className?: string;
}> = ({ children, className }) => {
  return (
    <Stack className={mergeClasses(classes.row, className)}>{children}</Stack>
  );
};

const Column: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <StackItem className={classes.column}>{children}</StackItem>;
};

export type FormValues = {
  templateName: string;
  description?: string;
  fieldType: string;
  field: string;
  documentTypeTemplate: File | null;
};

export interface ICopyLabel {
  id: number;
  label: string;
}

export type ObjectItem = {
  value: string;
  text: string;
};

export interface DocumentDataViewProps {
  onSubmit: () => Promise<void>;
  handleSubmit: (onSubmit: () => Promise<void>) => void;
  control: Control<FieldValues>;
  onCopyClicked: (value: string, copyLabelId: number) => void;
  objects: Partial<ObjectDefinitionType>[];
  mergeFieldValues: ObjectItem[];
  isLoading: boolean;
  watch: (name: string) => string;
  toggleSaveModalBtnClick: () => void;
  isSaveModalOpen: boolean;
  isSaving: boolean;
  showPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
  isCancelModalOpen: boolean;
  toggleCancelModalBtnClick: (navigateBack?: boolean) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isDirty: boolean;
}

const AdminSettingsDocumentDataView = ({
  onSubmit,
  control,
  handleSubmit,
  onCopyClicked,
  mergeFieldValues,
  objects,
  isLoading,
  watch,
  toggleSaveModalBtnClick,
  isSaveModalOpen,
  isSaving,
  showPrompt,
  confirmNavigation,
  cancelNavigation,
  isCancelModalOpen,
  toggleCancelModalBtnClick,
  onCancel,
  isDirty,
}: DocumentDataViewProps) => {
  const { cssVariables } = useTheme();
  const styles = documentTemplateStyles();

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };
  const watchedFieldType = watch('fieldType') as unknown as IOption;

  if (isLoading) {
    return <Loader fullPage />;
  }

  return (
    <Container
      title="Document Data"
      style={{
        ...cssVariables,
      }}
      className={styles.container}
    >
      <form onSubmit={onFormSubmit} noValidate className={styles.formContainer}>
        <Row>
          <Column>
            <Controller
              name={'expirationInDays'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <TextField
                  name={fieldName}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  type="number"
                  label="Expiration Days"
                  required
                  size="large"
                  errorMessage={error?.message}
                />
              )}
            />
          </Column>
          <Column>
            <Controller
              name={'documentWatermark'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Document Watermark"
                  size="large"
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  {value}
                  <Switch
                    label={'Yes'}
                    onChange={onChange}
                    onBlur={onBlur}
                    checked={value}
                  />
                </Field>
              )}
            />
          </Column>
        </Row>

        <Row>
          <Column>
            <div className={styles.row}>
              <Controller
                name={'fieldType'}
                control={control}
                render={({ field: { value, onChange } }) => (
                  <InHouseInputSelect
                    label="Select Field Type"
                    options={objects.map((obj) => ({
                      text: obj.label as string,
                      value: obj.name as string,
                    }))}
                    selectedOptions={value}
                    onOptionSelect={(_e, option) => {
                      onChange(option?.selectedOptions);
                    }}
                  />
                )}
              />
              <FieldSelector
                objectName={watchedFieldType?.value}
                marginTop={28}
              />
            </div>
          </Column>
        </Row>

        <Row>
          <Column>
            <Controller
              name={'effectiveHeaderMessage'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Effective Header Message"
                  size="large"
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <Textarea
                    name={fieldName}
                    resize="vertical"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                </Field>
              )}
            />
          </Column>
          <Column>
            <Controller
              name={'effectiveFooterMessage'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Effective Footer Message"
                  size="large"
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <Textarea
                    name={fieldName}
                    resize="vertical"
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                  />
                </Field>
              )}
            />
          </Column>
        </Row>
      </form>
      <Stack className={classes.footer}>
        <CustomButton
          data-testid="button-cancel"
          customStyles={classes.btn_container}
          buttonTitle={'Cancel'}
          buttonType={ButtonTypes.Ghost}
          onClick={onCancel}
        />
        <CustomButton
          data-testid="button-save"
          customStyles={classes.btn_container}
          leftIcon="Save"
          buttonTitle={'Save'}
          buttonType={ButtonTypes.Primary}
          onSubmit={onSubmit}
          disabled={!isDirty || isSaving}
          isLoading={isSaving}
        />
      </Stack>
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
      <ConfirmDialog
        dataTestId={`cancel-modal-${createTestAttribute(
          LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL
        )}`}
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
      <ConfirmDialog
        dataTestId={`save-modal-${createTestAttribute(
          LocalizationString.SAVE_REQUEST
        )}`}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.UPDATE_REQUEST}
        iconSrc={SaveIcon}
        dontShowCloseIcon
        description={LocalizationString.UPDATED_DESCRIPTION}
      />
    </Container>
  );
};

export default AdminSettingsDocumentDataView;
