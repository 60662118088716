import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton } from '@celito.clients/shared';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { components, GroupBase, MenuListProps } from 'react-select';

const getStyles = makeStyles({
  p8: {
    ...shorthands.padding('8px !important'),
  },
  button: {
    width: '100%',
    fontWeight: 'bold !important',
  },
});
interface CustomMenuListProps {
  onClick?: (inputValue: string) => void;
}
export const MenuList =
  <T,>(args?: CustomMenuListProps) =>
  (props: MenuListProps<T, false, GroupBase<T>>) => {
    const styles = getStyles();
    return (
      <components.MenuList {...props}>
        {props.children}
        {props.selectProps.inputValue.length > 0 && (
          <div className={styles.p8}>
            <CustomButton
              appearance="primary"
              dataTestId="button-create-doc"
              customStyles={styles.button}
              buttonTitle={'Create'}
              onClick={() => args?.onClick?.(props.selectProps.inputValue)}
              buttonType={ButtonTypes.Primary}
            />
          </div>
        )}
      </components.MenuList>
    );
  };
