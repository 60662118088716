import { ROUTES } from '@celito.clients/enums';
import { lazy } from 'react';
import { Route, Routes } from 'react-router';

const NotInvitedScreen = lazy(
  () => import('../screens/not-authenticated/not-authenticated.screen')
);
const NotActiveUserScreen = lazy(
  () => import('../screens/no-invitation/no-invitation.screen')
);
const PageNotFound = lazy(
  () => import('../screens/page-not-found/page-not-found.screen')
);

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path={ROUTES.NOT_INVITED} element={<NotInvitedScreen />} />
      <Route path={ROUTES.NOT_ACTIVE_USER} element={<NotActiveUserScreen />} />
      <Route path={ROUTES.PAGE_NOT_FOUND} element={<PageNotFound />} />
    </Routes>
  );
};

export default PublicRoutes;
