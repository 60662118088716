import { Icon } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { mergeClasses, Text } from '@fluentui/react-components';

import { padLeftWithZero } from '../../../utils/helper';
import { wizardTypesStyles } from './types.styles';

const ErrorTypeSectionView: React.FC<{
  label: string;
  onClick?: () => void;
  index: number;
}> = ({ label, onClick, index }) => {
  const styles = wizardTypesStyles();

  return (
    <div
      data-testid="error-icon-container"
      className={mergeClasses(styles.section, styles.errorType)}
      onClick={onClick}
    >
      <Text
        data-testid={`label-error-icon-${createTestAttribute(label)}`}
        className={styles.text}
      >
        {padLeftWithZero(index)} {label}
      </Text>

      <Icon iconName="ErrorCircle24Regular" />
    </div>
  );
};

export default ErrorTypeSectionView;
