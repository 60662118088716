import axiosInstance from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import {
  GetNotificationTemplate,
  NotificationTemplatePayload,
  ObjectDefinitionType,
} from '../notification-form/notification-form.model';

interface ObjectsByName {
  objectAttributeDefinitions: ObjectDefinitionType[];
}

export const notificationService = () => {
  const getObjects = async (): Promise<ObjectDefinitionType[]> => {
    const response = await axiosInstance.get(
      `${Endpoints.OBJECT_DEFINITION_URL}`
    );
    return response.data;
  };

  const getObjectsByName = async (name: string): Promise<ObjectsByName> => {
    const response = await axiosInstance.get(
      `${Endpoints.OBJECT_DEFINITION_URL}/${name}?includeNestedRelatioship=true`
    );
    return response.data;
  };

  const generateName = async (label: string): Promise<string> => {
    const response = await axiosInstance.get(
      `${Endpoints.GENERATE_NAME_URL}/?objectName=notification_template__s&label=${label}`
    );
    return response.data;
  };

  const createNotification = async (
    payload: NotificationTemplatePayload
  ): Promise<string> => {
    const response = await axiosInstance.post(
      `${Endpoints.NOTIFICATION_TEMPALTES_URL}`,
      payload
    );
    return response.data;
  };

  const editNotification = async (
    payload: NotificationTemplatePayload,
    id: string
  ): Promise<string> => {
    const response = await axiosInstance.put(
      `${Endpoints.NOTIFICATION_TEMPALTES_URL}/${id}`,
      payload
    );
    return response.data;
  };

  return {
    getObjects,
    getObjectsByName,
    generateName,
    createNotification,
    editNotification,
  };
};
