export enum ObjectEnum {
  DOCUMENT_TEMPLATE = 'document_template__a',
  CONTROLLED_DOCUMENT = 'controlled_document__a',
  USER_LEARNING = 'user_learning__s',
  DEPARTMENT = 'department__s',
  TRAINING_ASSIGNMENT = 'training_assignment__a',
  DOCUMENT_TYPE = 'document_type__a',
  DOCUMENT_SUB_TYPE = 'document_sub_type__a',
  GXP_TYPE = 'gxp_type__a',
  CURRICULUM = 'curriculum__a',
  COURSE = 'course__a',
  SMART_GROUP = 'smart_groups__a',
  USER = 'user__s',
  QUIZ = 'quiz__a',
  WORKFLOW_DEFINITION = 'workflow_definition__s',
  DELEGATION = 'delegation__s',
  UL_ADMIN_SETTINGS = 'user_learning_admin_settings__a',
  CELITO_MODULE = 'celito_application__s',
}
