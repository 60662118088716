import { queryClient } from '@celito.clients/endpoints';
import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { ViewMetadata } from '../types/view-metadata.type';

export const getViewMetadata = async (
  viewName: string
): Promise<ViewMetadata> => {
  const cachedViewMetadata = queryClient.getQueryData<ViewMetadata>(viewName);

  if (cachedViewMetadata) {
    return cachedViewMetadata;
  }

  const response = await performRequest<ViewMetadata>(
    `${Endpoints.VIEW_METADATA_URL}/${viewName}`,
    ApiMethodType.GET,
    null
  );

  queryClient.setQueryData(viewName, response?.data);

  return response?.data || {};
};
