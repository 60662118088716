import { LocalizationString } from '@celito.clients/assets';
import { DocData } from '@celito.clients/types';
import cn from 'classnames';
import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';

import classes from './download-file-btn.module.css';

interface DownloadFileBtnProps {
  dataTestId?: string;
  item: RowItem;
  column?: ColumnData;
  index?: number;
  docData: DocData | undefined;
  handleDownload: () => void;
}

export const DownloadFileBtn = ({
  item,
  column,
  handleDownload,
  docData,
}: DownloadFileBtnProps): JSX.Element | string => {
  const getBtnName = () => {
    if (!column) {
      return `${LocalizationString.LOADING}...`;
    }
    const fieldName = item?.[column.fieldName as string];

    return fieldName;
  };

  return (
    <div>
      <button
        className={cn(classes.btn, {
          [classes.btnDisabled]: !docData,
        })}
        disabled={!docData}
        onClick={handleDownload}
      >
        {getBtnName()}
      </button>
    </div>
  );
};
