import { Icon, IconButton } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Toast,
  ToastBody,
  Toaster,
  ToastIntent,
  ToastTitle,
  useToastController,
} from '@fluentui/react-components';
import React, { ReactNode, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { styles } from './styles';
import { ToastController, ToasterOptions, ToastProviderProps } from './types';

// More than one Toaster is anti-pattern. Use this constant to get the same toaster instance.
const TOASTER_ID = 'toaster';

let toastController: ToastController | null = null;

export const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const controller = useToastController(TOASTER_ID);
  const classNames = styles();

  useEffect(() => {
    toastController = controller;
  }, [controller]);

  return (
    <>
      <Toaster
        toasterId={TOASTER_ID}
        limit={3}
        position="top-end"
        className={classNames.toastContainer}
      />
      {children}
    </>
  );
};

const ToastContent = ({
  title,
  message,
  toastId,
}: {
  title: string;
  message: ReactNode;
  toastId: string;
}) => {
  const controller = useToastController(TOASTER_ID);
  const classNames = styles();
  const messageTestAttribute =
    typeof message === 'string' ? createTestAttribute(message) : 'non-string';

  return (
    <Toast className={classNames.toastRoot}>
      <ToastTitle
        action={
          <IconButton
            onClick={() => controller.dismissToast(toastId)}
            icon={<Icon iconName="Dismiss16Filled" />}
            data-testid="button-close-toast-message"
          />
        }
        className={classNames.toastTitle}
        data-testid={`toast-title-${createTestAttribute(title)}`}
      >
        {title}
      </ToastTitle>
      <ToastBody
        className={classNames.toastBody}
        data-testid={`toast-body-${messageTestAttribute}`}
      >
        {message}
      </ToastBody>
    </Toast>
  );
};

export const showToast = (
  type: ToastIntent,
  title: string,
  message: ReactNode,
  options: ToasterOptions = {}
) => {
  if (!toastController) {
    return;
  }

  const toastId = options.toastId ?? uuidv4();

  toastController.dispatchToast(
    <ToastContent title={title} message={message} toastId={toastId} />,
    {
      intent: type,
      toastId,
      ...options,
    }
  );

  return toastId;
};

export const dismissToast = (toastId?: string) => {
  if (!toastController || !toastId) {
    return;
  }

  toastController.dismissToast(toastId);
};
