import { RowSelectionState } from '@tanstack/react-table';
import { difference, unionBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';

import { ReferenceSelectorItem } from '../reference-selector.model';

/**
 * Is used to manage row selection in grid view
 * @param availableItems Visible items to be selected
 */
export default function useRowSelection(
  availableItems: ReferenceSelectorItem[]
) {
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const selectedRows = useRef<ReferenceSelectorItem[]>([]);

  useEffect(
    function HandleSelection() {
      selectedRows.current = unionBy(
        selectedRows.current,
        availableItems.filter((item) => {
          if (item.version) {
            return rowSelection[`${item.name}-${item.version}`];
          }

          return rowSelection[item.name];
        }),
        (item) => {
          if (item.version) {
            return `${item.name}-${item.version}`;
          }
          return item.name;
        }
      );
    },
    [rowSelection, availableItems]
  );

  useEffect(
    function HandleDeselection() {
      selectedRows.current = difference(
        selectedRows.current,
        availableItems.filter((item) => {
          if (item.version) {
            return !rowSelection[`${item.name}-${item.version}`];
          }

          return !rowSelection[item.name];
        })
      );
    },
    [rowSelection, availableItems]
  );

  return {
    rowSelection,
    setRowSelection,
    selectedRows,
  };
}
