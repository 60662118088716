import {
  CheckIcon,
  LocalizationString,
  WarningIcon,
} from '@celito.clients/assets';
import { useActiveModule } from '@celito.clients/hooks';
import { ModuleNamesEnum } from '@celito.clients/types';
import { errorToast, successToast } from '@celito.clients/utils';
import { NotificationContext } from 'libs/core/src/providers/in-app-notification-provider';
import { useContext, useEffect, useState } from 'react';

import {
  InAppNotificationsProps,
  Notification,
} from './in-app-notifications.model';
import InAppNotificationsView from './in-app-notifications.view';
import { notificationService } from './service/notificationService';

const limit = 10;

const InAppNotificationsController: React.FC<InAppNotificationsProps> = (
  props
) => {
  const { setHasUnreadNotifications, refetchUnreadNotificationsStatus } =
    useContext(NotificationContext);

  const [notifications, setNotifications] = useState<Notification[] | []>([]);
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);
  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [isReadAllNotifsLoading, setIsReadAllNotifsLoading] = useState(false);

  const [isRemoveAllNotifsLoading, setIsRemoveAllNotifsLoading] =
    useState(false);
  const [dialogConfig, setDialogConfig] = useState({
    title: '',
    description: '',
    icon: '',
    onConfirm: () => {},
  });

  const [page, setPage] = useState(1);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const activeModule = useActiveModule();
  const currentModuleNameParam =
    activeModule?.systemName === ModuleNamesEnum.UNIFIED_DASHBOARD
      ? undefined
      : activeModule?.systemName;

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    setIsNotificationsLoading(true);
    const response = await notificationService().getInAppNotifications(
      page,
      limit,
      currentModuleNameParam
    );
    setNotifications(response.data);

    setHasMore(page * limit < response.total);
    setIsNotificationsLoading(false);
    setIsLoadingMore(false);
  };

  const loadMoreNotifications = async () => {
    if (isLoadingMore || !hasMore) {
      return;
    }
    setIsLoadingMore(true);
    try {
      const response = await notificationService().getInAppNotifications(
        page + 1,
        limit,
        currentModuleNameParam
      );
      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...response.data,
      ]);

      setHasMore((page + 1) * limit < response.total);
      setPage(page + 1);
    } catch (error) {
      // Handle error here
    } finally {
      setIsLoadingMore(false);
    }
  };

  const readAllNotifications = async () => {
    setIsReadAllNotifsLoading(true);
    setPage(1);

    try {
      await notificationService().updateAllInAppNotifications(
        'read',
        currentModuleNameParam
      );
      successToast({
        message: 'All notifications marked as read successfully',
      });

      const updatedNotifications = notifications.map((item) => ({
        ...item,
        viewedOnUtc: (item.viewedOnUtc = new Date().toLocaleDateString()),
      }));

      setNotifications(updatedNotifications);
      setHasUnreadNotifications(false);
    } catch (error) {
      errorToast({ message: 'Error marking all notifications as read' });
    } finally {
      setIsReadAllNotifsLoading(false);
      setIsConfirmDialogOpen(false);
    }
  };

  const removeAllNotifications = async () => {
    setIsRemoveAllNotifsLoading(true);
    setPage(1);
    setHasMore(true);

    try {
      await notificationService().updateAllInAppNotifications(
        'remove',
        currentModuleNameParam
      );
      successToast({ message: 'All notifications removed successfully' });
    } catch (error) {
      errorToast({ message: 'Error removing all notifications' });
    } finally {
      setIsRemoveAllNotifsLoading(false);
      setIsConfirmDialogOpen(false);
      setNotifications([]);
      setHasUnreadNotifications(false);
    }
  };

  const readNotification = async (id: number) => {
    try {
      await notificationService().updateInAppNotification(id, 'read');
      setPage(1);
      setHasMore(true);
      await fetchNotifications();
      setNotifications(
        notifications.map((notification) =>
          notification.id === id
            ? { ...notification, viewedOnUtc: new Date().toISOString() }
            : notification
        )
      );

      refetchUnreadNotificationsStatus();

      successToast({
        message: `Notification marked as read successfully`,
      });
    } catch (error) {
      errorToast({ message: `Error marking notification as read` });
    }
  };

  const removeNotification = async (id: number) => {
    try {
      await notificationService().updateInAppNotification(id, 'remove');
      await fetchNotifications();
      setNotifications(
        notifications.filter((notification) => notification.id !== id)
      );

      refetchUnreadNotificationsStatus();

      successToast({ message: `Notification removed successfully` });
    } catch (error) {
      errorToast({ message: `Error removing notification ${id}` });
    } finally {
      setIsConfirmDialogOpen(false);
    }
  };

  const handleReadAll = async () => {
    setDialogConfig({
      title: 'Read Notification Confirmation',
      description:
        LocalizationString.MARK_ALL_NOTIFICATIONS_AS_READ_CONFIRMATION,
      icon: CheckIcon,
      onConfirm: readAllNotifications,
    });
    setIsConfirmDialogOpen(true);
  };

  const handleRemove = async (id: number) => {
    setDialogConfig({
      title: 'Remove Notification Confirmation',
      description: LocalizationString.REMOVE_NOTIFICATION_CONFIRMATION,
      icon: WarningIcon,
      onConfirm: () => removeNotification(id),
    });
    setIsConfirmDialogOpen(true);
  };

  const handleRemoveAll = async () => {
    setDialogConfig({
      title: 'Remove Notifications Confirmation',
      description: LocalizationString.REMOVE_ALL_NOTIFICATIONS_CONFIRMATION,
      icon: WarningIcon,
      onConfirm: removeAllNotifications,
    });
    setIsConfirmDialogOpen(true);
  };

  return (
    <InAppNotificationsView
      notifications={notifications}
      handleReadAll={handleReadAll}
      handleRemoveAll={handleRemoveAll}
      isNotificationsLoading={isNotificationsLoading}
      isReadAllNotifsLoading={isReadAllNotifsLoading}
      isRemoveAllNotifsLoading={isRemoveAllNotifsLoading}
      readNotification={readNotification}
      removeNotification={removeNotification}
      isConfirmDialogOpen={isConfirmDialogOpen}
      setIsConfirmDialogOpen={setIsConfirmDialogOpen}
      dialogConfig={dialogConfig}
      refreshNotifications={fetchNotifications}
      handleRemove={handleRemove}
      loadMoreNotifications={loadMoreNotifications}
      hasMoreNotifications={hasMore}
      onDismissInAppNotifPanel={props.onDismissInAppNotifPanel}
    />
  );
};

export default InAppNotificationsController;
