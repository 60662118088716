import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const getStyles = makeStyles({
  taskRow: {
    display: 'flex',
    width: '100%',
    ...shorthands.overflow('auto'),
    ...shorthands.gap('8px'),
    ...shorthands.paddingBlock('16px'),
  },
  participant: {
    display: 'flex',
    ...shorthands.paddingBlock('16px'),
    alignItems: 'start',
    '&>#data': {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      ...shorthands.gap('4px'),
      '&>.data__row': {
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        alignItems: 'flex-start',
        gap: '3.125rem',
        '& .row__long_text': {
          maxWidth: '100%',
          maxHeight: '1rem',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          ...shorthands.overflow('hidden'),
        },
      },
    },
  },
  firstParticipant: {
    paddingBlockStart: 'none',
  },
  lastParticipant: {
    ...shorthands.borderBottom('none'),
    paddingBlockEnd: 'none',
  },
  taskName: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
  },

  // utility classes
  flex1: {
    ...shorthands.flex(1),
  },
  borderBottom2: {
    ...shorthands.borderBottom('1px', 'solid', 'gray'),
    display: 'flex',
  },
  borderBottom1: {
    ...shorthands.borderBottom('1px', 'solid', 'gray'),
  },
  actionContainer: {
    left: '-22px !important',
  },
  buttonText: {
    whiteSpace: 'nowrap',
  },
  groupStyle: {
    ...shorthands.padding('4px', '0px'),
    ...shorthands.border('1px', 'solid', Color.green),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    color: Color.green,
    backgroundColor: Color.wizard_success_state_color,
    ...shorthands.borderColor(Color.green),
    width: '80%',
    ...shorthands.borderRadius('24px'),
    ...shorthands.gap('5px'),
  },
  activeTask: {
    ...shorthands.border('1px', 'solid', Color.blue_700),
    ...shorthands.borderColor(Color.blue_700),
    color: Color.blue_700,
    ...shorthands.padding('4px', '0px'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '16px',
    backgroundColor: Color.blue_100,
    width: '80%',
    ...shorthands.borderRadius('24px'),
    ...shorthands.gap('5px'),
  },
  groupIcon: {
    width: '20px',
    display: 'flex',
  },
  notFound: {
    paddingBlockStart: '12px',
    textAlign: 'center',
  },
  mt10: {
    marginTop: '-10px',
  },
  notask: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
  },
  displayEnd: {
    justifyContent: 'start',
    marginLeft: '50px',
    display: 'inline',
  },
  flex20: {
    flexBasis: '15%',
    justifyContent: 'start',
  },
  flex10: {
    flexBasis: '10%',
    justifyContent: 'flex-start',
  },
  flex40: {
    flexBasis: '50%',
  },
  flex15: {
    flexBasis: '20%',
  },
  flexEnd: {
    flexBasis: '5%',
    justifyContent: 'flex-start',
  },
  flex50: {
    flexBasis: '50%',
  },
  font600: {
    fontWeight: '600',
    flex: 'none',
  },
  fullWidth: {
    width: '100%',
  },
  mt4: {
    marginTop: '4px',
  },
  mt6: {
    marginTop: '6px',
  },
  mt3: {
    marginTop: '3px',
  },
  mt8: {
    marginTop: '-8px',
  },
  pdLeft: {
    paddingLeft: '2px',
  },
  height34: {
    height: '34px',
  },
  centerLoading: {
    transform: 'translateY(-50%)',
    top: '50%',
    position: 'absolute',
    left: '50%',
  },
  documentStatusText: {
    color: 'rgb(29, 57, 196)',
  },
  showLine: {
    borderBottom: '1px solid var(--border-color)',
    marginLeft: '20%',
  },
});
