import { IconVariants } from '@celito.clients/enums';
import { logger } from '@celito.clients/services';
import { isEqual, isError } from 'lodash';
import { createElement, useEffect, useRef, useState } from 'react';
import { Maybe } from 'yup';

import { PngIcon } from './components';
import { IconComponent, IconProps } from './icon.component';
import { icons, resolveIcon } from './utils';

interface IconViewProps extends IconProps {}

const IconPlaceholder = () => {
  return <i style={{ height: '1rem', width: '1rem' }} />;
};

export function IconView({
  variant = IconVariants.fluent,
  iconName,
  ...props
}: IconViewProps) {
  const [loaded, setLoaded] = useState<boolean>(icons.has(iconName));
  const icon = useRef<Maybe<IconComponent>>(icons.get(iconName));

  useEffect(() => {
    if (!icon.current && isEqual(variant, IconVariants.fluent))
      resolveIcon(
        iconName,
        (loadedIcon) => {
          icon.current = loadedIcon;
          setLoaded(true);
        },
        (error) => {
          if (isError(error)) {
            logger.error(error, { iconName });
          }
        }
      );
  }, [iconName, variant]);

  switch (variant) {
    case IconVariants.png:
      return <PngIcon iconName={iconName} {...props} />;

    case IconVariants.fluent:
    default:
      if (loaded && icon.current) {
        return createElement(icon.current, props);
      }
      return <IconPlaceholder />;
  }
}

export default IconView;
