import { AttributeTypeEnum } from '../../../enums/attributes-enum';
import DisplayView from '../../view-components/display-view/display-view.component';
import { FieldSectionProps, TAttributeDefinition } from './types';

export const getFieldDataObject = (
  recordDetails: Record<string, unknown>,
  attribute: TAttributeDefinition
) => {
  const value = recordDetails?.[attribute.key] as
    | Record<string, unknown>
    | string;

  return {
    label: attribute.label,
    value: value,
    dataType: attribute.dataType,
    dataTypeKeyForFE: attribute.dataTypeKeyForFE,
  };
};
/**
 * Form field section
 *
 * Renders a read only field based on the attribute definition
 * @param props - Field configuration params {@link FieldSectionProps}
 * @returns Form field section
 */
export const FieldSection: React.FC<FieldSectionProps> = ({
  fieldState,
  recordDetails,
  attributeDefinition,
}) => {
  const {
    label = '',
    value,
    dataType,
    dataTypeKeyForFE,
  } = getFieldDataObject(recordDetails, attributeDefinition);

  return (
    <DisplayView
      data-testid={`display-field-section-view-${label}`}
      label={label}
      value={value}
      dataType={dataType as AttributeTypeEnum}
      objectName={attributeDefinition?.relationship?.name ?? ''}
      objectAttributeDefinition={attributeDefinition}
      //FIXME
      attributeConfig={{} as any}
      recordDetails={recordDetails}
      dataTypeKeyForFE={dataTypeKeyForFE}
    />
  );
};
