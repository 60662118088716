import { Relationship } from '@celito.clients/types';
import { errorToast, successToast } from '@celito.clients/utils';

export const copyToClipboard = (infoToCopy: string) => {
  navigator.clipboard
    .writeText(infoToCopy)
    .then(() =>
      successToast({
        message: `Successfully copied ${infoToCopy} to clipboard`,
      })
    )
    .catch((error) =>
      errorToast({ message: 'Failure copying merge field, try again!' })
    );
};

export const generateMergeFieldName = (
  field: string,
  relationship?: Relationship,
  appendInitalObject?: boolean,
  objectName?: string,
  firstLevel?: string
) => {
  const mergeField = creatingMergeFieldName(
    field,
    relationship,
    appendInitalObject,
    objectName
  );

  if (firstLevel) {
    return `\${${firstLevel}.${mergeField}}`;
  }

  return `\${${mergeField}}`;
};

export const creatingMergeFieldName = (
  field?: string,
  relationship?: Relationship,
  appendInitalObject?: boolean,
  objectName?: string
) => {
  const appendedObject = appendInitalObject ? `${objectName}.` : '';

  const appendedField = field ? `.${field}` : '';

  let mergeField = `${appendedObject}${field}`;

  if (relationship) mergeField = `${relationship.name}${appendedField}`;

  return mergeField;
};
