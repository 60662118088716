import { brandLogoWhite2x, NotFoundImage } from '@celito.clients/assets';
import { Footer } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { FooterComponentProps } from 'libs/shared/src/lib/footer/src/footer.model';

import { noInvitedStyles } from './page-not-found.styles';

interface IProps {
  onNavigate: () => void;
  footerData: FooterComponentProps;
  showFooter: boolean;
}
const PageNotFoundView = ({ onNavigate, footerData, showFooter }: IProps) => {
  const { cssVariables } = useTheme();

  const styles = noInvitedStyles();

  return (
    <div className={styles.headerContainer} style={{ ...cssVariables }}>
      <img className={styles.logoImage} alt="" src={brandLogoWhite2x} />
      <div className={styles.extraOverFlow} />
      <div className={styles.contentContainer}>
        <div className={styles.title}>
          The page could not be found or you don't have permission to view it.
        </div>
        <div className={styles.mt2}>
          <span className={styles.message}>
            Please check the address and reach out to your administrator if you
            need help.
          </span>
        </div>
        <div className={styles.imageContainer}>
          <img
            className={styles.NotFoundImageComponenet}
            alt=""
            src={NotFoundImage}
          />
        </div>
        {showFooter ? <Footer {...footerData} /> : null}
      </div>
    </div>
  );
};

export default PageNotFoundView;
