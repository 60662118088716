import { LayoutRulesDataSchema, Section } from '@celito.clients/types';
import { isEqual } from 'lodash';
import { useEffect, useState } from 'react';
import { FieldValues } from 'react-hook-form';

import { getLayoutRules } from '../utils/layout-rules';

export const useLayoutRules = (
  formData: FieldValues,
  rulesConfig: Partial<Section>,
  enableLayoutRules = true
) => {
  const [fieldsState, setFieldsState] = useState<
    Record<string, LayoutRulesDataSchema>
  >({});

  useEffect(() => {
    if (!enableLayoutRules) return;
    const newFieldsState: Record<string, LayoutRulesDataSchema> =
      getLayoutRules(formData, rulesConfig, fieldsState);

    if (!isEqual(newFieldsState, fieldsState)) {
      setFieldsState(newFieldsState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData, rulesConfig]);

  return { fieldsState, setFieldsState };
};
