import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';

export const generateName = async (objectName: string, label: string) => {
  const response = await performRequest<string>(
    `${Endpoints.GENERATE_NAME_URL}/?objectName=${objectName}&label=${label}`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};
