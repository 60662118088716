import { EffectCallback, useEffect, useRef } from 'react';

type ChangedDependencies<T extends PropertyKey, U> = {
  [name in T]?: {
    before: U;
    after: U;
  };
};

export const usePrevious = <T,>(value: T[], initialValue: T[]) => {
  const ref = useRef(initialValue);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const useEffectWithDependencyMonitor = <T extends PropertyKey, U>(
  effectHook: (
    changedDeps: ChangedDependencies<T, U>
  ) => ReturnType<EffectCallback>,
  dependencies: U[],
  // The name of each dependency
  dependencyNames: T[]
) => {
  const previousDeps = usePrevious(dependencies, []);

  const changedDeps = dependencies.reduce<ChangedDependencies<T, U>>(
    (accum, dependency, index) => {
      if (dependency !== previousDeps[index]) {
        const keyName = dependencyNames[index] || index;
        return {
          ...accum,
          [keyName]: {
            before: previousDeps[index],
            after: dependency,
          },
        };
      }

      return accum;
    },
    {}
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => effectHook(changedDeps), dependencies);
};

export default useEffectWithDependencyMonitor;
