export const Color = {
  //primary variants
  white: '#FFFFFF',
  black: '#000000',
  primary_red: '#ec0803',

  //gradiant colors for header
  dark_blue_gradiant: '#050b58',
  light_bluen_gradiant: '#1c37bf',

  // color variants
  blue_100: '#F0F5FF',
  blue_200: '#D6E4FF',
  blue_300: '#ADC6FF',
  blue_400: '#85A5FF',
  blue_500: '#597EF7',
  blue_600: '#2F54EB',
  blue_700: '#1D39C4',
  blue_800: '#10239E',
  blue_900: '#061178',
  blue_1000: '#030852',
  blue_1100: '#2548F7',
  blue_1200: '#162A91',
  blue_1300: '#1D39C414',
  blue_1400: '#1D39C40D',
  blue_1500: '#2548F71F',
  blue_1600: '#162A911F',
  blue_1700: '#2f85b3',
  blue_1800: '#1990ff',
  blue_1900: '#3493c7',
  blue_2000: '#1890ff',
  blue_2100: '#e8ebf9',
  blue_2200: '#f4f5fc',

  // Admin UI
  DARK_BLUE_BORDER: '#1C37BF',
  PAGE_LAYOUT_FIELD_BACKGROUND: '#F9F9F9',
  FOOTER_ACTIONS_BUTTON: '#FAFAFA',
  DELETE_ICON_COLOR: '#DA1E28',
  SELECTED_FIELD_WIDTH: '#14AE5C',

  // gray variants
  gray_100: '#F5F5F5',
  gray_200: '#E8E8E8',
  gray_300: '#D9D9D9',
  gray_400: '#BFBFBF',
  gray_500: '#8C8C8C',
  gray_600: '#595959',
  gray_700: '#222222',
  gray_800: '#FFFFFFE6',
  gray_900: '#FFFFFFCC',
  gray_1000: '#FFFFFF33',
  gray_1100: '#FFFFFF4D',
  gray_1200: '#FFFFFF1A',
  gray_1300: '#fafafa',
  gray_1400: '#f4f5fd',
  gray_1450: '#f3f2f1',
  gray_1500: '#e5e8f2',
  gray_1600: '#f9f9f9',
  gray_bg: '#dddddd',
  scroll_thumb: '#cccccc',
  gray_1700: '#f6f6f6',
  gray_tab_border: '#F0F0F0',

  dividerColor: '#DDE2E5',

  // background colors
  background_primary_blue: '#1D39C4',
  background_primary_blue_dark: '#2C0F62',
  background_body_bg: '#f0f0f0',
  background_dark_blue_quiz: '#281A7B',

  //compoenent colors
  radio_color: '#0D99FF',
  border_color: '#B3B3B3',

  // text colors
  label_color: '#808080',
  bg_color: '#D9D9D9',
  error_color: '#A4262C',
  action_text_color: '#323130',
  wizard_states_color: '#434343',
  wizard_success_state_color: '#F8FFEE',
  neutral_foreground: '#242424',
  error_color_text: '#bc2f32',

  //toast colors variants
  toast_body: '#757575',
  success_bg: '#f6ffed',
  success_border: '#52C41A',
  success_label: '#092b00',
  green: '#61C92D',
  success_status_bg: '#D9F7BE',

  error_bg: '#fff1f0',
  error_border: '#ffccc7',
  error_label: '#5c0011',
  error_status: '#e02020',

  info_bg: '#f5f5f5',
  info_border: '#e8e8e8',
  info_label: '#000000d9',

  warning_bg: '#feffe6',
  warning_border: '#fffb8f',
  warning_label: '#614700',
  warning_status_bg: '#FFD8BF',
  warning_status: '#FF7A45',

  // hover colors
  action_hover: 'rgba(255, 255, 255, 0.16)',
  icon_hover: 'rgba(0, 0, 0, 0.05)',
  icon_button_hover: 'rgba(0, 0, 0, 0.08)',

  // user avatar colors
  avatar_blue: '#0078d4',
  avatar_burgundy: '#750b1c',
  avatar_cyan: '#005b70',
  avatar_darkGreen: '#0b6a0b',
  avatar_darkRed: '#a4262c',
  avatar_gold: '#986f0b',
  avatar_green: '#498205',
  avatar_lightRed: '#d13438',
  avatar_magenta: '#881798',
  avatar_orange: '#ca5010',
  avatar_pink: '#e3008c',
  avatar_purple: '#5c2e91',
  avatar_rust: '#8e562e',
  avatar_teal: '#038387',
  avatar_violet: '#8764b8',
  avatar_warmGray: '#7a7574',

  // action column colors
  action_column_hover_bg: '#2548f70d',

  // delete button colors
  icon_color: '#ec0303',
  icon_background: '#fafafa',

  // taking quiz colors
  quiz_background: '#ddd',
  text_color: '#71B340',
  index_div: '#70BFFF',
  failed_color: '#FbA1A5',
  success_color: '#52C41A',
  sequence_border: '#0066ff',
  side_text: '#d2d2d2',
  que_background: '#fafafa',
  que_heading: '#000000',
  quiz_heading: '#201F1E',

  //Home Screen Colors
  orange_background: '#fddfba',
  melon_background: '#ffb9aa',
  aero_blue_background: '#c7f7d7',

  purple_ish_background: '#572459',
  dark_blue_background: '#345188',
  dark_orange_background: '#EE684C',
  green_background: '#00BC07',

  // Drawer
  drawer_header_color: '#F7F7F7',

  // controlled-docs-color
  line_div_color: '#f2f2f2',
  context_button_color: '#495ecb',
  purple_100: '#bdc5ec',

  reactflow_background: 'rgb(229 231 235)',

  // Admin ui Lifecycle
  bg_react_flow: '#FAFAFA',
};
