import {
  Checkbox,
  CheckboxProps,
  makeStyles,
  mergeClasses,
} from '@fluentui/react-components';

const useStyles = makeStyles({
  root: {
    '> div': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
});

const SelectCheckbox = ({ className, ...props }: CheckboxProps) => {
  const classes = useStyles();

  return (
    <Checkbox className={mergeClasses(classes.root, className)} {...props} />
  );
};

export default SelectCheckbox;
