import { userPermissionType, UserTypes } from '../types/user-types';

class UserContextManager {
  private userContext: UserTypes | null;
  private userPermissionContext: userPermissionType | null;
  private static instance: UserContextManager;

  constructor() {
    this.userContext = null;
    this.userPermissionContext = null;
  }

  static getInstance() {
    if (!UserContextManager.instance) {
      UserContextManager.instance = new UserContextManager();
    }

    return UserContextManager.instance;
  }

  setUserContext(userContext: UserTypes) {
    this.userContext = userContext;
  }

  getUserContext() {
    return this.userContext;
  }

  setUserPermissionContext(permissionContext: userPermissionType) {
    this.userPermissionContext = permissionContext;
  }

  getUserPermissionContext() {
    return this.userPermissionContext;
  }
}

export default UserContextManager;
