import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import {
  CustomButton,
  GridView,
  GridViewProps,
  HeaderActionButtonsContainer,
  Icon,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { isActionAllowed } from '@celito.clients/utils';
import { Skeleton, SkeletonItem } from '@fluentui/react-components';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';

import { ListViewActions } from './components';
import { DelegationModal } from './components/delegation-modal/delegation-modal.component';
import CreateWorkflow from './components/workflow-modal';
import { ListViewProps } from './list-view.model';
import classes from './list-view.module.css';
import { ErrorData, ExportItem, ObjectMetadata, ViewMetadata } from './types';
import { transformObjectMetadata } from './utils';

export interface ListViewUiProps extends ListViewProps {
  isLoading: boolean;
  columnData: GridViewProps['columns'];
  rowData: GridViewProps['items'];
  extraGridProps: Omit<GridViewProps, 'columns' | 'items'>;
  error: ErrorData;
  handlePageChange: (page: number) => void;
  itemsPerRow: number;
  totalItems: number;
  createRoute?: string | null;
  applyFilters: (
    rawNewFilters: RulesComponentListViewFilter,
    doApiCall?: boolean
  ) => void;
  viewMetadata: ViewMetadata | null;
  numberOfFilters: number;
  currentPageNumber: number;
  objectName: string;
  exportFile: (exportItem: ExportItem) => void;
  onCancelPress: () => void;
  objectMetadata: ObjectMetadata | null;
  createRecordViewType?: string;
  handleWorkflowModalOpen: () => void;
  openWorkFlowModal: boolean;
}

const ListViewUi = ({
  isLoading,
  columnData,
  rowData,
  dataTestId,
  extraGridProps,
  error,
  handlePageChange,
  itemsPerRow,
  totalItems,
  createRoute,
  applyFilters,
  viewMetadata,
  numberOfFilters,
  currentPageNumber,
  objectName,
  exportFile,
  onCancelPress,
  objectMetadata,
  createRecordViewType,
  handleWorkflowModalOpen,
  openWorkFlowModal,
  leftActionNode,
}: ListViewUiProps): JSX.Element => {
  const { cssVariables } = useTheme();
  const { user } = useContext(UserContext);
  const { pageTitle } = useLayout();

  // TODO: Use proper error wrapper
  if (error.isError) {
    return <div data-testid={dataTestId}>{error.errorMessage}</div>;
  }

  const viewData = viewMetadata?.viewDto?.view?.[0];
  const pageCount = Math.ceil(totalItems / itemsPerRow);

  const getPageRange = () => {
    if (currentPageNumber === 1) {
      return itemsPerRow + 1;
    }
    if (pageCount - currentPageNumber <= 5) {
      return itemsPerRow + 1;
    }
    if (itemsPerRow - currentPageNumber <= 3) {
      return itemsPerRow - 1;
    }

    return itemsPerRow;
  };

  return (
    <div data-testid={dataTestId}>
      {isLoading ? (
        <Skeleton animation="pulse">
          <SkeletonItem style={{ marginTop: '10px', height: '640px' }} />
          {/* Call the delegation modal initially to load the cached users response */}
          <DelegationModal
            isOpen={false}
            onClose={() => {}}
            rowItem={{ key: 'test' }}
            objectName={objectName}
          />
        </Skeleton>
      ) : (
        <>
          {/* Create Button */}
          {(createRecordViewType ||
            viewData?.showCancelButton ||
            (createRoute &&
              isActionAllowed(
                user?.roles ?? [],
                viewMetadata?.viewDto?.roles
              ))) && (
            <HeaderActionButtonsContainer>
              <div className={classes.listViewActionButtons}>
                {createRecordViewType && (
                  <CustomButton
                    buttonTitle={LocalizationString.ADD_NEW}
                    buttonType={ButtonTypes.Transparent}
                    leftIcon="Add"
                    onClick={handleWorkflowModalOpen}
                  />
                )}
                {viewData?.showCancelButton && (
                  <CustomButton
                    buttonTitle={LocalizationString.CANCEL}
                    buttonType={ButtonTypes.Transparent}
                    onClick={onCancelPress}
                  />
                )}
                {createRoute &&
                  isActionAllowed(
                    user?.roles ?? [],
                    viewMetadata?.viewDto?.roles
                  ) && (
                    <Link
                      to={`../${createRoute}`}
                      className={classes.listViewAddBtn}
                    >
                      <CustomButton
                        buttonTitle={
                          viewData?.createRecordButtonTitle ??
                          LocalizationString.ADD_NEW
                        }
                        buttonType={ButtonTypes.Transparent}
                      />
                    </Link>
                  )}
              </div>
            </HeaderActionButtonsContainer>
          )}
          <div className={classes.container}>
            {pageTitle === undefined && (
              <div className={classes.emptyTitleSpace} />
            )}
            {/* ListView Actions  */}
            {viewMetadata && (
              <ListViewActions
                columnData={columnData}
                applyFilters={applyFilters}
                viewMetadata={viewMetadata}
                numberOfFilters={numberOfFilters}
                objectName={objectName}
                objectMetadata={transformObjectMetadata(objectMetadata!)}
                exportFile={exportFile}
                leftActionNode={leftActionNode}
              />
            )}

            {/* Grid view */}
            <GridView
              className={classes.listGridView}
              columns={columnData}
              items={rowData}
              {...extraGridProps}
            />

            {/* Pagination */}
            {pageCount > 1 && (
              <div className={classes.bottomContainer}>
                <div
                  data-testid="pagination-list-view"
                  className={classes.pagination}
                  style={{ ...cssVariables }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <Icon
                        data-testid="icon-pagination-right"
                        iconName="ChevronRight16Regular"
                      />
                    }
                    previousLabel={
                      <Icon
                        data-testid="icon-pagination-left"
                        iconName="ChevronLeft16Regular"
                      />
                    }
                    forcePage={currentPageNumber - 1}
                    onPageChange={({ selected }) => handlePageChange(selected)}
                    // pageRangeDisplayed={itemsPerRow}
                    pageCount={pageCount}
                    activeClassName={classes.active}
                    disabledClassName={classes.disabled}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={getPageRange()}
                  />
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {openWorkFlowModal && (
        <CreateWorkflow
          openWorkFlowModal={openWorkFlowModal}
          handleWorkflowModalOpen={handleWorkflowModalOpen}
        />
      )}
    </div>
  );
};

export default ListViewUi;
