/**
 * These components are wrapped with `withLayoutMountNode` HOC
 * to ensure that the popovers are also suspended with the whole app.
 */

import {
  Combobox as FluentCombobox,
  Menu as FluentMenu,
  Popover as FluentPopover,
} from '@fluentui/react-components';

import { withLayoutMountNode } from '../../hoc/with-layout-mount-node';

export const Popover = withLayoutMountNode(FluentPopover);
export const Combobox = withLayoutMountNode(FluentCombobox);
export const Menu = withLayoutMountNode(FluentMenu);
