import { useRef, useState } from 'react';
import type { Maybe } from 'yup';

interface ModalState {
  isModalOpen: boolean;
  taskName: string;
  extraPayload: Record<string, unknown>;
  navigateUrl?: string;
  recordData?: Record<string, any>;
  contextMenuAction?: Record<string, any>;
}

interface ModalActions {
  openModal: (
    taskId: string,
    extraPayload?: Record<string, unknown>,
    navigateUrl?: string,
    recordData?: Record<string, any>,
    contextMenuAction?: Record<string, any>,
    onCompleteTask?: () => void
  ) => void;
  closeModal: (successful?: boolean) => void;
}

const useESignModal = (): ModalState & ModalActions => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [taskName, setTaskName] = useState('');
  const [extraPayload, setExtraPayload] = useState<ModalState['extraPayload']>(
    {}
  );
  const [navigateUrl, setNavigateUrl] =
    useState<ModalState['navigateUrl']>(undefined);
  const [recordData, setRecordData] = useState<ModalState['recordData']>();
  const [contextMenuAction, setcontextMenuAction] =
    useState<ModalState['contextMenuAction']>();

  // This function is supposed to run after successful completion of the associated task
  // This is set by the e-sign consumer using the openModal function below
  const onCompleteTask = useRef<Maybe<() => void>>();

  const openModal: ModalActions['openModal'] = (
    taskId,
    payload = {},
    navigateUrl = undefined,
    recordData = {},
    contextMenuAction = {},
    _onCompleteTask
  ) => {
    setIsModalOpen(true);
    setTaskName(taskId);
    setExtraPayload(payload);
    setNavigateUrl(navigateUrl);
    setRecordData(recordData);
    setcontextMenuAction(contextMenuAction);
    onCompleteTask.current = _onCompleteTask;
  };

  const closeModal = (successful?: boolean) => {
    if (successful !== false) onCompleteTask.current?.();
    setIsModalOpen(false);
    setTaskName('');
    setRecordData({});
    setcontextMenuAction({});
    onCompleteTask.current = null;
  };

  return {
    isModalOpen,
    openModal,
    closeModal,
    taskName,
    extraPayload,
    navigateUrl,
    recordData,
    contextMenuAction,
  };
};

export default useESignModal;
