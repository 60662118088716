import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes, ObjectEnum, ROUTES } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { getDelegatedTaskContentString } from '@celito.clients/list-view-engine';
import { ModalContext } from '@celito.clients/provider';
import { ConfirmDialog, CustomButton, Icon } from '@celito.clients/shared';
import { ControlledDocumentRequestTypeEnum } from '@celito.clients/types';
import {
  errorToast,
  getRedirectionBaseUrl,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import { Label } from '@fluentui/react-components';
import cn from 'classnames';
import { convert } from 'html-to-text';
import { capitalize, isEmpty } from 'lodash';
import { useContext, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useScreenContext } from '../context';
import { useRecordData } from '../hooks/use-record-data';
import classes from '../legacy/styles/controlled-document-view-screen.module.css';
import { claimTask, getCollaboratorTaskList } from '../services';
import { nonCollaborativeFiles } from '../utils';

export const userType = {
  Collaborator: 'cd_create_collaboration',
  Owner: 'cd_create_complete_draft',
  Admin: ['cd_create_admin_review', 'cd_pre_approved_doc_admin_review'],
  Functional_Approval: 'cd_create_functional_approval',
  Quality_Approval: 'cd_create_quality_approval',
};

export const CompleteBar = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const { openModal } = useContext(ModalContext);
  const queryClient = useQueryClient();
  const activeModule = useActiveModule();

  const {
    recordName,
    showEditDocumentButton,
    taskName,
    setTask,
    isTaskResponseLoading,
    task,
  } = useScreenContext();

  const { data: response } = useRecordData();
  const isAnyLoading = isLoading || isTaskResponseLoading;

  const onClickCompleteButton = () => {
    if (task?.tag === userType.Owner) {
      setIsLoading(true);
      getCollaboratorTaskList(recordName ?? '')
        .then((res) => {
          setIsLoading(false);
          if (res?.status) {
            setShowConfirmPopup(true);
          } else {
            openEsignModal(response);
          }
        })
        .catch((_error) => {
          setIsLoading(false);
          raiseErrorToast(_error);
        });
    } else if (
      task?.tag &&
      userType.Admin.includes(task?.tag) &&
      task?.ownerName === null
    ) {
      setIsLoading(true);
      claimTask(task?.name)
        .then((res) => {
          if (res) {
            setTask?.(res?.task);
            successToast({
              message: LocalizationString.CLAIM_TASK,
            });
            setIsLoading(false);
            queryClient.refetchQueries({
              queryKey: ['allTasks', recordName],
            });
            queryClient.refetchQueries({
              queryKey: [ObjectEnum.CONTROLLED_DOCUMENT, recordName, 'tasks'],
            });
          }
        })
        .catch((_error) => {
          raiseErrorToast(_error);
          setIsLoading(false);
        });
    } else {
      openEsignModal(response);
    }
  };
  const openEsignModal = (
    recordData?: Record<string, unknown>,
    taskPayload?: Record<string, unknown>
  ) => {
    setShowConfirmPopup(false);
    const redirectUrl = activeModule?.link;
    if (task?.name) {
      openModal(
        task.name,
        taskPayload,
        redirectUrl,
        recordData,
        undefined,
        () =>
          queryClient.invalidateQueries({
            queryKey: ['allTasks', recordName],
          })
      );
    } else {
      errorToast({
        message: LocalizationString.ASSIGNMENT_TASK_PENDING,
      });
    }
  };

  const onClickEditDocument = () => {
    const docLabel = response?.document?.nameWithExtension;
    const isExtNonCollaborative = nonCollaborativeFiles.some((ext) => {
      return docLabel?.endsWith(ext);
    });
    if (
      response?.initiationType ===
        ControlledDocumentRequestTypeEnum.UpdateDocument &&
      isExtNonCollaborative
    ) {
      errorToast({
        message: LocalizationString.ERROR_CANNOT_EDIT_NON_COLLABORATIVE_FILES,
      });
      return;
    }
    const baseUrl = getRedirectionBaseUrl();
    const isEdit = window.location.pathname.includes('/edit/custom/');
    const viewType = isEdit ? 'edit' : 'view';
    const queryParams = taskName
      ? `version=${response?.version}&viewType=${viewType}&taskName=${taskName}`
      : `version=${response?.version}&viewType=${viewType}`;

    window.open(
      `${baseUrl}/${ROUTES.WOPI_EDITOR.replace(
        ':documentId',
        response?.document?.documentId ?? ''
      )}?${queryParams}`,
      '_blank',
      'noreferrer'
    );
  };

  const getTaskTitle = () => {
    if (isAnyLoading) {
      return `${LocalizationString.LOADING}...`;
    }
    if (task) {
      return task.label;
    }
    return LocalizationString.TASK_DATA_NOT_FOUND;
  };
  const togglePopupState = () => {
    setShowConfirmPopup(!showConfirmPopup);
  };
  const getCompleteButtonTitle = () =>
    task?.tag === userType.Owner ? 'Complete Draft' : 'Complete';
  const getButtonTitle = () => {
    if (task?.ownerName === null) {
      return 'Accept';
    }
    return getCompleteButtonTitle();
  };

  const onButtonClick = () => {
    if (response) {
      onClickCompleteButton();
    }
  };

  return (
    <>
      <ConfirmDialog
        dataTestId={''}
        open={showConfirmPopup}
        onCancelClicked={() => togglePopupState()}
        onConfirmClicked={() => openEsignModal(response)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.CANCEL}
        title={LocalizationString.CONFIRM_MESSAGE}
        iconSrc={WarningIcon}
        description={LocalizationString.COLLABORATOR_TASK}
      />
      {task ? (
        <div className={classes.complete_bar}>
          <div className={classes.task_info}>
            <div className={classes.clipboard_icon}>
              <Icon iconName="ClipboardTextRtl20Filled" />
            </div>
            <Label
              style={{
                display: 'flex',
                gap: '3px',
                alignItems: 'center',
              }}
              className={cn({ [classes.mt15]: showMore })}
            >
              {LocalizationString.TASK_TITLE}:{' '}
              <span data-testid={`label-task-title-value`}>
                {getTaskTitle()}
              </span>
            </Label>
            <button
              className={cn(classes.showHideText, { [classes.mt15]: showMore })}
              onClick={() => setShowMore(!showMore)}
              data-testid="button-showmore"
            >
              {!showMore ? 'Show More' : 'Show Less'}
            </button>
          </div>
          {showMore ? (
            <div className={classes.descDiv}>
              <label>
                Description:{' '}
                <span
                  className={classes.descText}
                  data-testid="label-description-value"
                >
                  {task?.description
                    ? capitalize(convert(task?.description))
                    : '-'}
                </span>
              </label>
            </div>
          ) : null}
          {!isEmpty(task.delegates) && (
            <span
              className={cn(classes.delegated_task_content, {
                [classes.mt15]: showMore,
              })}
            >
              {getDelegatedTaskContentString({
                label: getTaskTitle(),
                owner: task.owner.label,
                delegates: task.delegates.map((dele) => dele.label),
              })}
            </span>
          )}
          <div className={classes.completeEditButtonContainer}>
            {showEditDocumentButton && (
              <CustomButton
                buttonType={ButtonTypes.Primary}
                buttonTitle={'Edit Document'}
                disabled={false}
                onClick={onClickEditDocument}
              />
            )}

            <CustomButton
              buttonType={ButtonTypes.Primary}
              buttonTitle={getButtonTitle()}
              disabled={!response}
              isLoading={isAnyLoading}
              iconPosition="before"
              onClick={onButtonClick}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
