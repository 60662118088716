import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

export const getFollowUpInfo = async (data: any = {}) => {
  const response = await performRequest<any>(
    `${Endpoints.OBJECT_DATA_URL}/task__s?limit=10&page=1`,
    ApiMethodType.POST,
    data
  );
  return response.data;
};
