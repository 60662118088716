import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

interface NestedConditions {
  all?: NestedConditions | NestedConditions[];
  any?: NestedConditions | NestedConditions[];
  attribute?: string;
}

const countFilters = (conditions: NestedConditions | NestedConditions[]) => {
  let count = 0;

  if (Array.isArray(conditions))
    conditions.forEach((cond) => {
      if (cond?.attribute) count += 1;
      else if (cond?.all) count += countFilters(cond.all);
      else if (cond?.any) count += countFilters(cond.any);
    });
  else if (conditions?.attribute) count += 1;
  else if (conditions?.all) count += countFilters(conditions.all);
  else if (conditions?.any) count += countFilters(conditions.any);

  return count;
};

export const getNumberOfFilters = (filters?: RulesComponentListViewFilter) => {
  if (!filters?.conditions) return 0;

  const conditions = [filters.conditions];

  return countFilters(conditions as NestedConditions[]);
};
