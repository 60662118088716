import { parseISODate } from '@celito.clients/utils';
import { TimePickerProps } from '@fluentui/react-timepicker-compat-preview';
import { useState } from 'react';

import { DateTimePickerProps } from './date-time-picker.model';
import DateTimePickerView from './date-time-picker.view';

interface DateTimePickerControllerProps extends DateTimePickerProps {}

const DateTimePickerController = (
  props: DateTimePickerControllerProps
): JSX.Element => {
  const [currentTime, setCurrentTime] = useState<Date | null>(props.value);

  const setDateValue = (date: Date, time: Date) => {
    const dateString = date.toISOString().split('T')[0];
    const timeString = time.toISOString().split('T')[1];
    props.onChange?.(parseISODate(`${dateString}T${timeString}`));
  };

  const onSelectDate = (selectedDate: Date) => {
    const modifiedSelectedDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate(),
      currentTime?.getHours() ?? selectedDate.getHours(),
      currentTime?.getMinutes() ?? selectedDate.getMinutes()
    );
    setDateValue(modifiedSelectedDate, currentTime ?? modifiedSelectedDate);
  };

  const onTimePickerChange: TimePickerProps['onTimeChange'] = (_ev, date) => {
    setCurrentTime(date.selectedTime);
    props?.onChange?.(date.selectedTime ?? '');
  };

  return (
    <DateTimePickerView
      onSelectDate={onSelectDate}
      onTimePickerChange={onTimePickerChange}
      {...props}
    />
  );
};

export default DateTimePickerController;
