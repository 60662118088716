import { ROUTES } from '@celito.clients/enums';
import { actionColumnListViewStyles } from '@celito.clients/list-view-engine';
import { FluentIconNames, Icon, IconButton } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { getCurrentSystemTimeInISOString } from '@celito.clients/utils';
import {
  Menu,
  MenuItem,
  MenuList,
  MenuListProps,
  MenuPopover,
  MenuProps,
  MenuTrigger,
} from '@fluentui/react-components';
import cn from 'classnames';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import LocalStrings from '../../../../assets/localisation';
import styles from './list-util.module.css';

interface ActionMenuViewProps {
  item: RowItem;
  onDeleteClick: (item: RowItem) => void;
}

const ActionMenu = (props: ActionMenuViewProps) => {
  const { onDeleteClick, item } = props;
  const { cssVariables } = useTheme();
  const actionColumnStyles = actionColumnListViewStyles();
  const [menuItems, setMenuItems] = useState<MenuListProps[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const navigate = useNavigate();

  const actions = [
    {
      key: 'Delete20Regular',
      title: LocalStrings.deleteDelegation,
      onClick: (
        ev?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>,
        item?: RowItem
      ) => {
        onDeleteClick(item!);
      },
    },
    {
      key: 'Edit20Regular',
      title: LocalStrings.editDelegation,
      onClick: () =>
        navigate(
          `../${ROUTES.EDIT_DELEGATION.replace(':name', item.key as string)}`
        ),
    },
    {
      key: 'Eye20Regular',
      title: LocalStrings.viewDelegation,
      onClick: () =>
        navigate(
          `../${ROUTES.VIEW_DELEGATION.replace(':name', item.key as string)}`
        ),
    },
  ];

  useEffect(() => {
    const currentDate = getCurrentSystemTimeInISOString();
    if (item.startAtUtcdate <= currentDate) {
      actions.shift();
    }
    if (!item.isActive) actions.shift();

    setMenuItems(actions);
  }, [item]);

  const onOpenChange: MenuProps['onOpenChange'] = (e, data) => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div style={{ ...cssVariables }}>
      <Menu open={isMenuOpen} onOpenChange={onOpenChange}>
        <MenuTrigger disableButtonEnhancement>
          <div
            className={cn({
              [styles.moreMenuBtn]: item?.isActive,
            })}
          >
            <IconButton
              icon={
                <Icon
                  iconName="MoreHorizontal24Regular"
                  style={{ width: '19px' }}
                />
              }
            />
          </div>
        </MenuTrigger>

        <MenuPopover>
          <MenuList className={actionColumnStyles.menuList}>
            {menuItems.map((item, index) => (
              <MenuItem
                key={`${index}-${item.key}`}
                className={actionColumnStyles.actionMenuItem}
                onClick={item?.onClick}
              >
                <div className={actionColumnStyles.itemContent}>
                  <Icon iconName={item.key as FluentIconNames} />
                  {item?.title}
                </div>
              </MenuItem>
            ))}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

export default ActionMenu;
