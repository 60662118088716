import { LocalizationString } from '@celito.clients/assets';
import {
  AttributeTypeEnum,
  OperatorsEnum,
  ReferencePickerType,
} from '@celito.clients/enums';
import { FilterableColumn } from '@celito.clients/list-view-engine';
import { ObjectAttributeDefinition, Relationship } from '@celito.clients/types';
import { formatDateInTimezone } from '@celito.clients/utils';
import { TimeOfDayPrefenceEnum } from 'libs/core/src/enums/date-formats';
import { returnSelectedOptionFromOptions } from 'libs/core/src/utils/getSelectedOptionFromOptions';
import { useEffect, useRef } from 'react';
import { Controller, FieldError, UseFormReturn } from 'react-hook-form';

import {
  DatePicker,
  InHouseInputSelect,
  PeoplePicker,
  Picklist,
  TextField,
} from '../../shared';
import { TextFieldMultiple } from '../../text-field-multiple/text-field.multiple.component';
import { IOption } from '../rules-component.model';
import { rulesStyles } from '../rules-component.styles';
import { RulesComponentListViewFilter } from '../types/rules-component.types';
import { isDateValueOptions } from '../utils/utils';

interface InputPerDataTypeProps {
  dataType: string;
  methods: UseFormReturn<any>;
  groupIndex: number;
  ruleIndex: number;
  conditionIndex: number;
  getInputTypeFromColumnAndCondition: (
    dataType: string,
    operator: OperatorsEnum
  ) => string;
  relationship?: Relationship;
  picklistName?: string;
  disableFields?: boolean;
  allObjectOptions: IOption[];
  isLoadingObject: boolean;
  fetchAllObjects: () => Promise<void>;
  interfaceName: string;
  filterableColumns?: FilterableColumn[];
  attributeDefinitionOfColumns?: ObjectAttributeDefinition[];
}

export const InputPerDataType = ({
  dataType,
  groupIndex,
  methods,
  ruleIndex,
  conditionIndex,
  getInputTypeFromColumnAndCondition,
  relationship,
  picklistName,
  disableFields = false,
  allObjectOptions,
  fetchAllObjects,
  isLoadingObject,
  interfaceName,
  filterableColumns,
  attributeDefinitionOfColumns,
}: InputPerDataTypeProps) => {
  const {
    control,
    getValues,
    setValue,
    formState: { errors },
    watch,
  } = methods;

  const styles = rulesStyles();

  const value =
    getValues(
      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.operator`
    ) ?? OperatorsEnum.EQUALS;

  const operator = watch(
    `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.operator`
  );
  const isInitialRender = useRef(true);
  useEffect(() => {
    if (dataType === AttributeTypeEnum.ObjectDefinition) fetchAllObjects();
  }, [dataType]);

  useEffect(() => {
    if (
      isInitialRender &&
      !getValues(
        `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
      )
    )
      switch (operator) {
        case OperatorsEnum.RANGE:
          setValue(
            `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`,
            { min: '', max: '' }
          );
          break;
        case OperatorsEnum.IN:
        case OperatorsEnum.NOT_IN:
          setValue(
            `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`,
            []
          );
          break;
        default:
          setValue(
            `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`,
            ''
          );
      }
    else {
      isInitialRender.current = false;
    }
  }, [operator]);

  const inputType = getInputTypeFromColumnAndCondition(dataType, value);

  const referencePickerFilter: RulesComponentListViewFilter = {
    conditions: {
      all: [
        {
          all: [
            {
              attribute: 'isActive',
              operator: OperatorsEnum.EQUALS,
              value: true,
            },
          ],
        },
      ],
    },
  };

  const getSelectedKey = () => {
    const value = getValues(
      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
    );

    const valueIsArray = Array.isArray(value);

    if (isMultiple && valueIsArray) return value;

    return value ? [value] : [];
  };

  const getSelectedOptionYesOrNo = (value?: string) => {
    if (!value) return { text: '', value: '' };

    return { text: value, value };
  };

  const getAttributeNameToFilter = (factValue: string) => {
    if (!attributeDefinitionOfColumns) {
      return filterableColumns?.find(
        (filterable) => filterable.name.split('__')[0] === factValue
      )?.attributeNameToFilter;
    }

    const attributeDefinition = attributeDefinitionOfColumns.find(
      (attributeDef) => attributeDef.columnName === factValue
    );
    return filterableColumns?.find(
      (filterable) => filterable.name === attributeDefinition?.name
    )?.attributeNameToFilter;
  };

  const isMultiple =
    value === OperatorsEnum.IN || value === OperatorsEnum.NOT_IN;

  return value !== OperatorsEnum.IN_PAST &&
    value !== OperatorsEnum.IS_BLANK &&
    value !== OperatorsEnum.IS_NOT_BLANK ? (
    <Controller
      name={`${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`}
      control={control}
      render={({ field, fieldState: { error } }) => {
        const errorMessage = errors as any;

        const fieldErrorMin: FieldError = {
          type: 'required',
          message:
            errorMessage?.rules?.[ruleIndex]?.groups?.[groupIndex]
              ?.conditions?.[conditionIndex]?.value?.min?.message ||
            errorMessage?.rules?.[ruleIndex]?.groups?.[groupIndex]
              ?.conditions?.[conditionIndex]?.value?.message,
        };

        const fieldErrorMax: FieldError = {
          type: 'required',
          message:
            errorMessage?.rules?.[ruleIndex]?.groups?.[groupIndex]
              ?.conditions?.[conditionIndex]?.value?.max?.message,
        };

        switch (inputType) {
          case AttributeTypeEnum.Number:
            return (
              <TextField
                value={field.value}
                label=""
                type="number"
                onChange={(_ev, data) => field.onChange(data.value)}
                placeholder={LocalizationString.ENTER_VALUE}
                errorMessage={error?.message}
                disabled={disableFields}
                dataTestId="input-field-enter-value"
              />
            );
          case AttributeTypeEnum.NumberRange:
            return (
              <div className={styles.rangeContainer}>
                <TextField
                  value={field.value?.min}
                  label=""
                  type="number"
                  onChange={(_ev, data) => {
                    const value = getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                    );

                    field.onChange({ min: data.value, max: value?.max });
                  }}
                  placeholder={LocalizationString.ENTER_VALUE}
                  errorMessage={fieldErrorMin.message}
                  disabled={disableFields}
                  dataTestId="input-field-enter-value"
                />

                <TextField
                  value={field.value?.max}
                  label=""
                  type="number"
                  onChange={(_ev, data) => {
                    const value = getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                    );

                    field.onChange({ min: value?.min, max: data.value });
                  }}
                  placeholder={LocalizationString.ENTER_VALUE}
                  errorMessage={fieldErrorMax.message}
                  disabled={disableFields}
                  dataTestId="input-field-enter-value"
                />
              </div>
            );
          case AttributeTypeEnum.DateRange:
            return (
              <div className={styles.rangeContainer}>
                <DatePicker
                  label=""
                  value={
                    getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                    )?.min
                  }
                  minDate={undefined}
                  onDateChange={(e) => {
                    const value = getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                    );
                    field.onChange({
                      min: e && formatDateInTimezone(e),
                      max: value.max,
                    });
                  }}
                  error={fieldErrorMin}
                  disabled={disableFields}
                  data-testid="date-picker-start-date"
                  timeOfDayPreference={TimeOfDayPrefenceEnum.START}
                />
                <DatePicker
                  label=""
                  value={
                    getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                    )?.max
                  }
                  minDate={undefined}
                  onDateChange={(e) => {
                    const value = getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                    );
                    field.onChange({
                      min: value.min,
                      max: e && formatDateInTimezone(e),
                    });
                  }}
                  error={fieldErrorMax}
                  disabled={disableFields}
                  data-testid="date-picker-end-date"
                  timeOfDayPreference={TimeOfDayPrefenceEnum.END}
                />
              </div>
            );
          case AttributeTypeEnum.Picklist:
            return (
              <Picklist
                label={''}
                onOptionSelect={(_, data) =>
                  field.onChange(
                    isMultiple ? data.selectedOptions : data.optionValue
                  )
                }
                picklistName={picklistName}
                placeholder={LocalizationString.ENTER_VALUE}
                selectedKey={getSelectedKey()}
                errorMessage={error?.message}
                data-testid="input-field-enter-value"
                multiselect={isMultiple}
                disabled={disableFields}
              />
            );
          case AttributeTypeEnum.Reference:
            return relationship?.objectName === 'user__s' ? (
              <PeoplePicker
                label={''}
                showSystemUser
                multiselect={isMultiple}
                enableMeExpression={true}
                placeholder={LocalizationString.ENTER_VALUE}
                selectedOptions={getValues(
                  `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                )}
                errorMessage={error?.message}
                onOptionSelect={(_, data) => {
                  if (isMultiple) {
                    field.onChange(data.selectedOptions);
                  } else field.onChange(data.optionValue);
                }}
                isDisabled={disableFields}
                dataTestId="input-field-enter-value"
                referencePickerProps={{
                  getSelectedOptionsInformation: true,
                }}
              />
            ) : (
              <InHouseInputSelect
                label=""
                options={[]}
                disabled={disableFields}
                multiselect={isMultiple}
                placeholder={LocalizationString.ENTER_VALUE}
                selectedOptions={getValues(
                  `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.value`
                )}
                valueDefinition={getAttributeNameToFilter(
                  getValues(
                    `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                  )
                )}
                referencePicker={{
                  getSelectedOptionsInformation: true,
                  objectName: relationship?.objectName,
                  filters: referencePickerFilter,
                  pickerType:
                    getAttributeNameToFilter(
                      getValues(
                        `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                      )
                    ) === 'title__a'
                      ? ReferencePickerType.TITLE
                      : ReferencePickerType.LABEL,
                  attributeNameToFilter: getAttributeNameToFilter(
                    getValues(
                      `${interfaceName}.${ruleIndex}.groups.${groupIndex}.conditions.${conditionIndex}.fact`
                    )
                  ),
                }}
                errorMessage={error?.message}
                dataTestId="input-field-enter-value"
                onOptionSelect={(_, data) => {
                  if (isMultiple) {
                    field.onChange(data.selectedOptions);
                  } else field.onChange(data.optionValue);
                }}
              />
            );
          case AttributeTypeEnum.YesNo:
          case AttributeTypeEnum.RadioYesNo:
            return (
              <InHouseInputSelect
                options={[
                  { value: 'Yes', text: 'Yes' },
                  { value: 'No', text: 'No' },
                ]}
                label={''}
                placeholder={LocalizationString.ENTER_VALUE}
                selectedOptions={getSelectedOptionYesOrNo(field.value)}
                onOptionSelect={(_ev, data) => {
                  field.onChange(data.optionValue);
                }}
                errorMessage={error?.message}
                disabled={disableFields}
                dataTestId="input-field-enter-value"
              />
            );

          case AttributeTypeEnum.Dropdown:
            return operator === OperatorsEnum.IS ? (
              <InHouseInputSelect
                label={''}
                errorMessage={error?.message}
                data-testid="input-field-enter-value"
                placeholder={LocalizationString.ENTER_VALUE}
                options={isDateValueOptions}
                selectedOptions={returnSelectedOptionFromOptions(
                  isDateValueOptions,
                  field.value
                )}
                onOptionSelect={(_ev, data) => {
                  field.onChange(data.optionValue);
                }}
              />
            ) : (
              <TextField
                value={field.value}
                label=""
                onChange={(_ev, data) => field.onChange(data.value)}
                placeholder={LocalizationString.ENTER_VALUE}
                dataTestId="input-field-enter-value"
                errorMessage={error?.message}
                disabled={disableFields}
              />
            );

          case AttributeTypeEnum.ObjectDefinition:
            return (
              <InHouseInputSelect
                options={allObjectOptions}
                isLoadingOptions={isLoadingObject}
                label={''}
                placeholder={LocalizationString.ENTER_VALUE}
                selectedOptions={returnSelectedOptionFromOptions(
                  allObjectOptions,
                  field.value
                )}
                onOptionSelect={(_ev, data) => {
                  if (isMultiple && Array.isArray(data.selectedOptions))
                    field.onChange(
                      data.selectedOptions.map((selected) => selected.value)
                    );
                  else field.onChange(data.optionValue);
                }}
                errorMessage={error?.message}
                disabled={disableFields}
                dataTestId="input-field-enter-value"
                multiselect={isMultiple}
              />
            );

          default:
            if (
              operator === OperatorsEnum.IN ||
              operator === OperatorsEnum.NOT_IN
            )
              return (
                <TextFieldMultiple
                  value={
                    Array.isArray(field.value) &&
                    field.value.every((item) => typeof item === 'string')
                      ? field.value
                      : []
                  }
                  label=""
                  placeholder={LocalizationString.ENTER_VALUE}
                  errorMessage={error?.message}
                  disabled={disableFields}
                  onChange={(_, data) => field.onChange(data)}
                  data-testid="input-field-enter-value"
                />
              );
            else
              return (
                <TextField
                  value={field.value}
                  label=""
                  onChange={(_ev, data) => field.onChange(data.value)}
                  placeholder={LocalizationString.ENTER_VALUE}
                  dataTestId="input-field-enter-value"
                  errorMessage={error?.message}
                  disabled={disableFields}
                />
              );
        }
      }}
    />
  ) : null;
};
