import { useState } from 'react';

import { SingleLayoutContainerComponentProps } from './single-layout-container.model';
import { SingleLayoutContainerView } from './single-layout-container.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const SingleLayoutContainerController = (
  props: SingleLayoutContainerComponentProps
) => {
  const [toggleContainer, setToggleContainer] = useState<boolean>(false);
  return (
    <SingleLayoutContainerView
      {...props}
      {...{
        toggleContainer,
        setToggleContainer,
      }}
    />
  );
};
