import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

import { useFullscreen } from '@celito.clients/hooks';
import { logger } from '@celito.clients/services';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { PDFDocumentProxy } from 'pdfjs-dist/types/src/display/api';
import { useCallback, useState } from 'react';

import ErrorFallback from './components/error-fallback';
import Reader from './components/reader';
import Toolbar from './components/toolbar';
import { PageChangeEvent, PDFViewerProps, ReaderAPI } from './types';

const useStyles = makeStyles({
  frame: {
    height: '100%',
    width: '100%',
    display: 'flex',
    backgroundColor: 'var(--pdf-viewer-bg)',
    flexDirection: 'column',
  },

  pagesContainer: {
    ...shorthands.flex(1),
    overflow: 'auto',
    scrollBehavior: 'smooth',
  },
});

/**
 * PDF Viewer Component
 *
 * @param props.hideToolbar - Hides the default toolbar if true
 * @returns a PDF viewer with a toolbar which covers the parent container
 *
 * @author - Shivom Srivastava, July 22 2024
 */
export function PDFViewer(props: Readonly<PDFViewerProps>) {
  const [readerAPI, setReaderAPI] = useState<ReaderAPI>();
  const [numPages, setNumPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [loadError, setLoadError] = useState<boolean>(false);

  const onDocumentLoad = useCallback((pdf?: PDFDocumentProxy) => {
    setLoadError(false);
    if (pdf) setNumPages(pdf.numPages);
  }, []);

  const onLoadError = useCallback((error: Error) => {
    setLoadError(true);
    logger.error(error, {
      origin: 'PDFViewer',
    });
  }, []);

  const {
    ref: fullscreenRef,
    toggle: toggleFullscreen,
    fullscreen: isFullscreen,
  } = useFullscreen();

  const styles = useStyles();

  const onPageChange = useCallback(
    ({ currentPage }: PageChangeEvent) => {
      setCurrentPage(currentPage);
    },
    [setCurrentPage]
  );

  const toolbarProps = {
    toggleFullscreen,
    isFullscreen,
    currentPage,
    numPages,
    ...readerAPI,
  };

  const main = !loadError ? (
    <div
      ref={fullscreenRef}
      className={styles.frame}
      data-testid="pdf-viewer-container"
    >
      {!props.hideToolbar && <Toolbar {...toolbarProps} />}
      <div className={styles.pagesContainer}>
        <Reader
          file={props.url}
          setReaderAPI={setReaderAPI}
          onDocumentLoad={onDocumentLoad}
          onPageChange={onPageChange}
          reactPDFDocumentProps={{ onLoadError }}
        />
      </div>
    </div>
  ) : (
    <ErrorFallback />
  );

  return main;
}

export * from './types';
