import React from 'react';

import { RadioButtonProps } from './radio-button.model';
import { RadioButtonView } from './radio-button.view';

export const RadioButtonController: React.FC<RadioButtonProps> = ({
  options,
  onChange,
  ...restProps
}) => {
  return (
    <RadioButtonView {...restProps} onChange={onChange} options={options} />
  );
};
