import { IOption } from '../../in-house-input-select/in-house-input-select.model';
import { InHouseInputSelect } from '../../shared';
import { PeoplePickerProps } from './people-picker.model';

interface PeoplePickerViewProps extends PeoplePickerProps {
  options: IOption[];
}

const PeoplePickerView = ({
  options,
  dataTestId,
  placeholder,
  label,
  required,
  onOptionSelect,
  errorMessage,
  multiselect,
  isDisabled = false,
  roles,
  filterByExactRoles = false,
  disabled,
  selectedOptions,
  excludeUsers,
  enableMeExpression,
  showSystemUser,
  referencePickerProps,
  helperLabelText,
}: PeoplePickerViewProps): JSX.Element => {
  return (
    <InHouseInputSelect
      options={[]}
      label={label}
      selectedOptions={selectedOptions}
      dataTestId={dataTestId}
      placeholder={placeholder ?? 'Search and add User'}
      multiselect={multiselect}
      isPeoplePicker={true}
      errorMessage={errorMessage}
      referencePicker={{
        objectName: 'user__s',
        peoplePickerRoles: roles,
        filterByExactRoles: filterByExactRoles,
        enableMeExpression,
        showSystemUser,
        ...referencePickerProps,
      }}
      onOptionSelect={(_ev, data) => {
        let selectedOptions: IOption[] = [];

        if (Array.isArray(data.selectedOptions))
          selectedOptions = data.selectedOptions;
        else selectedOptions = [data.selectedOptions];

        const newData = { ...data, selectedOptions };

        onOptionSelect && onOptionSelect(_ev, newData);
      }}
      required={required}
      disabled={isDisabled || disabled}
      exclude={excludeUsers}
      helperLabelText={helperLabelText}
    />
  );
};

export default PeoplePickerView;
