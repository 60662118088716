import {
  ButtonTypes,
  DateFormat,
  FormEngineModeTypeEnum,
} from '@celito.clients/enums';
import { formatDate, isDateValid } from '@celito.clients/utils';
import { makeStyles } from '@fluentui/react-components';
import { useDisplayViewContext } from 'libs/form-engine/src/lib/components/view-components/display-view/display-view.context';
import React from 'react';

import { CustomButtonProps } from '../../custom-button/src/custom-button.model';
import { CustomButton, GridView, Loader, Stack } from '../../shared';
import { DisplayFileViewProps } from './file-view.model';
import classes from './file-view.module.css';

const useStyles = makeStyles({
  btn: {
    backgroundColor: 'transparent',

    ':hover': {
      backgroundColor: 'transparent',
    },

    ':disabled': {
      opacity: 0.7,
      cursor: 'not-allowed',
    },
  },
});

const Button = (props: CustomButtonProps) => {
  const styles = useStyles();

  return (
    <CustomButton
      as="button"
      buttonType={ButtonTypes.Destructive}
      customStyles={styles.btn}
      leftIcon="Download"
      title="Download file"
      {...props}
    />
  );
};

const DisplayFileView: React.FC<DisplayFileViewProps> = ({
  isLoading,
  removeFile,
  handleFileView,
  fileName,
  uploadedDate = new Date(),
  mode = FormEngineModeTypeEnum.View,
  noFile,
  documentId,
  showDownloadBtn = false,
  disabled = false,
  enableShimmer = false,
}) => {
  const { isPreviewTab } = useDisplayViewContext();
  return isLoading ? (
    <Loader />
  ) : (
    <Stack>
      <GridView
        columns={[
          {
            key: '1',
            name: 'Title',
            minWidth: 100,
            maxWidth: 400,
            fieldName: 'title',
            isResizable: true,
            showTooltip: true,
          },
          {
            key: '2',
            name: 'Upload Date',
            minWidth: 100,
            maxWidth: 400,
            fieldName: 'uploadData',
            isResizable: true,
            disableAlphanumericSorting: true,
          },
          {
            key: '3',
            name: 'Action',
            minWidth: 80,
            maxWidth: 80,
            fieldName: 'action',
            isResizable: true,
            onRender: (_item, _index, _column) => {
              return (
                <div style={{ display: 'flex' }}>
                  {showDownloadBtn ? (
                    <Button
                      disabled={isPreviewTab}
                      leftIcon="Download"
                      onClick={handleFileView}
                      dataTestId="button-download-document"
                    />
                  ) : null}
                  {(isPreviewTab || mode !== FormEngineModeTypeEnum.View) && (
                    <Button
                      disabled={isPreviewTab || disabled}
                      leftIcon="Delete"
                      onClick={removeFile}
                      title="Remove file"
                      dataTestId="button-delete-document"
                    />
                  )}
                </div>
              );
            },
          },
        ]}
        items={
          !noFile
            ? [
                {
                  key: '1',
                  title: fileName,
                  uploadData: isDateValid(new Date(uploadedDate))
                    ? formatDate(new Date(uploadedDate), DateFormat.Date)
                    : '-',
                  action: 'action',
                },
              ]
            : []
        }
        disableDefaultSorting
        disableSelection
        enableShimmer={enableShimmer}
        className={classes.grid_view_container}
      />
    </Stack>
  );
};

export default DisplayFileView;
