import { Button, makeStyles, shorthands } from '@fluentui/react-components';
import { Icon } from 'libs/shared/src/lib/shared';
import { Fragment } from 'react';

import { GridViewTableUiProps } from '../grid-view-table/grid-view-table.model';
import TableDataRow, {
  TableDataRowProps,
} from '../table-data-row/table-data-row';
import styles from './table-grouped-rows.styles.module.css';

interface TableGroupedRowsProps
  extends Pick<
      GridViewTableUiProps,
      'dataTestId' | 'table' | 'expandGroupHandler'
    >,
    Pick<
      TableDataRowProps,
      'totalVisibleColumns' | 'showPlaceholderForBlankCells'
    > {
  expanded: GridViewTableUiProps['expandedGroups'];
  groups: string[];
}

const useStyles = makeStyles({
  expandBtn: {
    ...shorthands.padding(0),
    ...shorthands.border('none'),
    justifyContent: 'flex-start',
  },
});

const TableGroupedRows = ({
  dataTestId,
  groups,
  table,
  totalVisibleColumns,
  expanded,
  expandGroupHandler,
  showPlaceholderForBlankCells,
}: TableGroupedRowsProps) => {
  const classes = useStyles();

  return (
    <>
      {groups.map((group, i) => {
        const rows = table
          .getRowModel()
          .rows.filter(({ original }) => original.categoryName === group);

        return (
          <Fragment key={group}>
            <tr
              className={styles.groupTableRow}
              data-testid={`${dataTestId}-tr-${i}-group`}
            >
              <td
                data-testid={`${dataTestId}-tr-${i}-group-expand-td`}
                className={styles.groupExpandCell}
              >
                <Button
                  className={classes.expandBtn}
                  appearance="transparent"
                  icon={
                    expanded.includes(group) ? (
                      <Icon iconName="ChevronDown20Regular" />
                    ) : (
                      <Icon iconName="ChevronRight20Regular" />
                    )
                  }
                  onClick={() => expandGroupHandler(group)}
                />
              </td>
              <td
                data-testid={`${dataTestId}-tr-${i}-group-heading-td`}
                colSpan={totalVisibleColumns - 1}
                className={styles.groupHeading}
              >
                {`${group} (${rows.length})`}
              </td>
            </tr>
            {expanded.includes(group) && (
              <TableDataRow
                dataTestId={dataTestId}
                rows={rows}
                totalVisibleColumns={totalVisibleColumns}
                showPlaceholderForBlankCells={showPlaceholderForBlankCells}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
};

export default TableGroupedRows;
