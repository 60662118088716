import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { AvatarColors, PersonaSize } from '@celito.clients/enums';
import { ConfirmDialog, Popover } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import {
  getAbbreviationForSysTimeZone,
  getTimeDifference,
} from '@celito.clients/utils';
import {
  PopoverSurface,
  PopoverTrigger,
  Tooltip,
} from '@fluentui/react-components';

import { UserPersona } from '../../user-persona/src';
import { UserAvatarProps } from './user-avatar.model';
import classes from './user-avatar.styles.module.css';
import { userAvatarStyles } from './user-avatar-styles';
interface UserAvatarViewProps extends UserAvatarProps {
  showAlert: boolean;
  openAlert: () => void;
  closeAlert: () => void;
  handleSignout: () => void;
}

const UserAvatarView = ({
  dataTestId,
  user,
  showAlert,
  openAlert,
  closeAlert,
  handleSignout,
}: UserAvatarViewProps): JSX.Element => {
  const { cssVariables } = useTheme();

  const styles = userAvatarStyles();

  let avatarText = '?';
  if (user?.label) {
    const splitString = user.label.toUpperCase().split(' ');
    const firstName = splitString?.[0] || '?';
    const lastName = splitString?.[1] || ' ';
    avatarText = `${firstName[0]}${lastName[0]}`;
  }
  const timeZoneAbbreviation = getAbbreviationForSysTimeZone();
  const differenceInTimeZone = getTimeDifference();
  return (
    <div
      data-testid={dataTestId}
      className={classes.avatarContainer}
      style={{ ...cssVariables }}
    >
      <Popover withArrow positioning={'below-start'}>
        <PopoverTrigger disableButtonEnhancement>
          <div>
            <UserPersona
              dataTestId="persona-avatar-profile-button"
              imageInitials={avatarText}
              hidePersonaDetails={true}
              imageAlt={user?.name}
              id="avatar-button"
              userName={user?.label}
              size={PersonaSize.large}
              avatar={{ activeAppearance: 'ring' }}
            />
          </div>
        </PopoverTrigger>

        <PopoverSurface
          className={styles.popoverSurface}
          style={{ ...cssVariables }}
        >
          <div className={classes.calloutContainer}>
            <div className={styles.companyInfo}>
              {LocalizationString.CELITO_NAME}.
              <button
                className={classes.signoutBtn}
                data-testid="button-profile-signout"
                onClick={openAlert}
              >
                Sign Out
              </button>
            </div>
            <UserPersona
              data-testid="text-profile-details"
              userName={user?.label}
              secondaryText={user?.email}
              size={PersonaSize.extraLarge}
              color={AvatarColors['dark-green']}
            />
            {/* <hr></hr> */}
            <div className={styles.timezoneInfo}>
              <div className={styles.timezoneHeading}>
                <b>Timezone: </b>
              </div>
              <Tooltip
                content={`${timeZoneAbbreviation} ${differenceInTimeZone}`}
                relationship="label"
              >
                <div
                  className={styles.timezoneValue}
                >{`${timeZoneAbbreviation} ${differenceInTimeZone}`}</div>
              </Tooltip>
            </div>
          </div>
          <ConfirmDialog
            open={showAlert}
            onConfirmClicked={handleSignout}
            primaryButtonText={LocalizationString.SIGN_OUT_SUCCESS}
            secondaryButtonText={LocalizationString.CANCEL}
            onCancelClicked={closeAlert}
            title={LocalizationString.CONFIRM_SIGN_OUT}
            iconSrc={WarningIcon}
            description={LocalizationString.SIGN_OUT_MSG}
          />
        </PopoverSurface>
      </Popover>
    </div>
  );
};

export default UserAvatarView;
