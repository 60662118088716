import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { logger } from '@celito.clients/services';
import { Environment } from '@celito.clients/singleton';
import { useTheme } from '@celito.clients/theme';
import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { WopiEditorViewProps } from './wopi-editor.model';
import classes from './wopi-editor.module.css';

const envInstance = Environment.getInstance();
const getLockURL = (documentId: string, unlock = false) => {
  const baseURL = envInstance.getParam('NX_API_BASE_URL');
  return `${baseURL}/wopi/files/${documentId}/${unlock ? 'unlock' : 'lock'}`;
};

const LOCK_REQUEST_INTERVAL = envInstance.getParam(
  'NX_WOPI_LOCK_REQUEST_INTERVAL_IN_MILLISECS'
)
  ? Number(envInstance.getParam('NX_WOPI_LOCK_REQUEST_INTERVAL_IN_MILLISECS'))
  : 15 * 1000;

export const WopiEditorView = (props: WopiEditorViewProps) => {
  const { cssVariables } = useTheme();
  const formElemenRef = useRef<HTMLFormElement>(null);
  const iframElemenRef = useRef<HTMLIFrameElement>(null);

  const { documentId } = useParams();

  useEffect(() => {
    if (formElemenRef.current) {
      formElemenRef.current.submit();
    }
  }, []);

  useEffect(
    /**
     * This effect is responsible sending lock request to the server at regular intervals.
     * The cleanup function is used to clear the interval when the component is unmounted.
     */
    function Lock() {
      let interval: NodeJS.Timer;
      if (documentId) {
        try {
          performRequest(getLockURL(documentId), ApiMethodType.POST, {});
          interval = setInterval(() => {
            performRequest(getLockURL(documentId), ApiMethodType.POST, {});
          }, LOCK_REQUEST_INTERVAL);
        } catch (error) {
          error instanceof Error && logger.error(error);
        }
      }
      return () => clearInterval(interval as unknown as number);
    },
    [documentId]
  );

  return (
    <div style={{ ...cssVariables }}>
      <form
        ref={formElemenRef}
        method="POST"
        action={props.url}
        target="iframe"
      >
        <input name="access_token" value={props.accessToken} type="hidden" />
      </form>

      <iframe
        src={props.url}
        title="WOPI"
        className={classes.wopi_editor}
        name="iframe"
        ref={iframElemenRef}
      />
    </div>
  );
};
