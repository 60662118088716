import {
  ICondition,
  IGroup,
} from 'libs/shared/src/lib/rules-component/rules-component.model';
import {
  IFiltersToBeApplied,
  IReferenceFilter,
  ISimplifedFilter,
  RulesComponentListViewFilter,
} from 'libs/shared/src/lib/rules-component/types/rules-component.types';
import { defineEmptyCondition } from 'libs/shared/src/lib/rules-component/utils/utils';

import { IFilterPanelFormData } from '../filter-panel.model';

export const transformSimplifedFilterToConditions = (
  conditions: ISimplifedFilter[]
): ICondition[] => {
  return conditions.map((condition) => {
    let value;

    if (condition.moduleFilterForObjectDefinition)
      value = condition.moduleFilterForObjectDefinition?.[0];
    else if (typeof condition.value === 'boolean')
      value = condition.value ? 'Yes' : 'No';
    else value = condition.value;

    if (condition.referenceFilters)
      return getReferenceFilterValue(condition.referenceFilters, condition);

    return {
      fact: condition.attribute ?? '',
      operator: condition.operator,
      value,
    };
  });
};

const transformConditionsToRules = (
  condition: {
    [conditionType: string]: ISimplifedFilter[];
  }[]
) => {
  return condition?.flatMap((c) => {
    const groups = Object.entries(c).map(([key, value]) => {
      return {
        conditions: transformSimplifedFilterToConditions(value),
        conditionType: key,
      };
    });
    return groups;
  }) as IGroup[];
};

export const getDefaultFilterPanelFormData = (
  existingFilters?: RulesComponentListViewFilter | undefined
): IFilterPanelFormData => {
  const defaultCondition: ICondition[] = [defineEmptyCondition()];
  let groups: IGroup[] = [
    {
      conditions: defaultCondition,
      conditionType: 'all',
    },
  ];
  if (existingFilters?.conditions?.all) {
    groups = transformConditionsToRules(existingFilters.conditions.all ?? []);
  } else if (existingFilters?.conditions?.any) {
    groups = transformConditionsToRules(existingFilters.conditions.any ?? []);
  }

  return {
    rules: [
      {
        conditionType: existingFilters?.conditions
          ? Object.keys(existingFilters?.conditions as object)[0] === 'any'
            ? 'any'
            : 'all'
          : 'all',
        isDefault: true,
        groups: groups,
        label: '',
      },
    ],
  };
};

export const getReferenceFilterValue = (
  referenceFilters: IReferenceFilter[],
  condition: ISimplifedFilter
): ICondition => {
  let referenceFilterObject;

  if (referenceFilters[0].conditions.all)
    referenceFilterObject = getReferenceFilterObject(
      referenceFilters[0].conditions.all[0]
    );
  else if (referenceFilters[0].conditions.any)
    referenceFilterObject = getReferenceFilterObject(
      referenceFilters[0].conditions.any[0]
    );

  if (
    referenceFilterObject?.attribute === 'label' ||
    referenceFilterObject?.attribute === 'label__s' ||
    referenceFilterObject?.attribute === 'name'
  )
    return {
      fact: condition.attribute ?? '',
      operator: condition.operator,
      value: referenceFilterObject.value,
    };

  return {
    fact: `${condition.attribute}.${referenceFilterObject?.attribute}`,
    operator: referenceFilterObject?.operator,
    value: referenceFilterObject?.value,
  };
};

const getReferenceFilterObject = (
  conditions: IFiltersToBeApplied
): ISimplifedFilter => {
  return Object.values(conditions).flatMap((level1Val) => {
    return Object.values(level1Val).flatMap((level2Val) => {
      return Object.values(level2Val).flatMap((level3Val) => {
        return level3Val.flatMap((obj) => {
          return obj;
        });
      });
    });
  })[0];
};

export default getDefaultFilterPanelFormData;
