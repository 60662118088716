import { AttributeTypeEnum, ObjectEnum } from '@celito.clients/enums';
import { ObjectAttributeDefinitionBase } from '@celito.clients/types';
import { userObjectName } from 'libs/form-engine/src/lib/config';

import { ActionColumn } from '../components/action-column/action-column';
import { STATUS_PICKLIST_NAME } from '../consts/constants';
import { ViewTypes } from '../enums/enums';
import { IColumns } from '../training-matrix.model';

export const getUserViewColumns = (
  onColumnClick: IColumns['onColumnClick']
): IColumns[] => {
  const columnConfig: IColumns[] = [
    {
      key: 'user',
      minWidth: 70,
      maxWidth: 80,
      name: 'User Name',
      fieldName: 'user__a',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      isFilterable: true,
      dataType: AttributeTypeEnum.Reference,
      relationship: {
        objectName: userObjectName,
      } as ObjectAttributeDefinitionBase['relationship'],
      data: {
        name: 'user',
        columnNameToBePicked: 'label',
      },
    },
    {
      fieldName: 'course',
      isResizable: true,
      key: 'course',
      maxWidth: 250,
      minWidth: 150,
      name: 'Course ID',
      isFilterable: true,
      dataType: AttributeTypeEnum.Reference,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      showTooltip: true,
      relationship: {
        objectName: ObjectEnum.COURSE,
      } as ObjectAttributeDefinitionBase['relationship'],
      data: {
        name: 'course',
      },
    },
    {
      key: 'courseTitle',
      minWidth: 80,
      maxWidth: 100,
      name: 'Course Name',
      fieldName: 'title',
      isResizable: true,
      isFilterable: true,
      showTooltip: true,
      dataType: AttributeTypeEnum.Reference,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'course',
        columnNameToBePicked: 'title',
        dataType: AttributeTypeEnum.Reference,
      },
    },
    {
      key: 'courseVersion',
      minWidth: 70,
      maxWidth: 80,
      name: 'Course Version',
      fieldName: 'version',
      isResizable: true,
      isFilterable: true,
      dataType: AttributeTypeEnum.Reference,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'course',
        columnNameToBePicked: 'version',
        dataType: AttributeTypeEnum.Reference,
      },
    },
    {
      key: 'status',
      minWidth: 50,
      maxWidth: 60,
      name: 'Status',
      fieldName: 'status',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      isFilterable: true,
      dataType: AttributeTypeEnum.Picklist,
      picklistName: STATUS_PICKLIST_NAME,
      data: {
        name: 'status',
      },
    },
    {
      key: 'dueDate',
      minWidth: 80,
      maxWidth: 95,
      name: 'Assignment Due Date',
      fieldName: 'dueDate',
      isResizable: true,
      onColumnClick,
      showSortIconWhenUnsorted: true,
      isFilterable: true,
      dataType: AttributeTypeEnum.Date,
      data: {
        name: 'dueDate',
      },
    },
    {
      key: 'assignmentCompletionDate',
      minWidth: 130,
      maxWidth: 150,
      name: 'Assignment Completion Date',
      fieldName: 'completionDate',
      isResizable: true,
      isFilterable: true,
      dataType: AttributeTypeEnum.Date,
      showSortIconWhenUnsorted: true,
      onColumnClick,
      data: {
        name: 'completionDate',
      },
    },
    {
      key: 'actions',
      minWidth: 80,
      maxWidth: 80,
      name: 'Actions',
      fieldName: 'actions',
      isFilterable: false,
      isResizable: true,
      onRender: (item, index, column) => (
        <ActionColumn item={item} view={ViewTypes.USER_VIEW} />
      ),
    },
  ];

  return columnConfig;
};
