export enum ModuleEnum {
  USER_LEARNING = 'User Learning',
  ADMIN = 'Admin',
  CONTROLLED_DOCUMENT = 'Controlled Documents',
  UNIFIED_DASHBOARD = 'Dashboards',
}

export enum ModuleNamesEnum {
  USER_LEARNING = 'lms__a',
  ADMIN = 'admin__a',
  CONTROLLED_DOCUMENT = 'controlled_docs__a',
  UNIFIED_DASHBOARD = 'dashboard__a',
}
