import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const textFieldAreaStyles = makeStyles({
  textFieldArea: {
    ...shorthands.flex(1),
    backgroundColor: Color.white,
    ...shorthands.borderRadius('5px'),
    width: '100%',
  },

  textFieldAreaError: {
    ...shorthands.border('1px', 'solid', Color.error_color),

    '&:after': {
      ...shorthands.border('1px', 'solid', Color.error_color),
    },
  },

  field: {
    width: '100%',
    fontWeight: 600,
    fontFamily: ThemeFontFamily.bold,
  },
});
