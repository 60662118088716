import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  Icon,
  Loader,
  RadioButton,
  TextFieldArea,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogSurface,
  DialogTitle,
  mergeClasses,
  useId,
} from '@fluentui/react-components';
import FormGroup from 'libs/form-engine/src/lib/components/form-group';
import { lazy, Suspense, useMemo, useRef } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { Maybe } from 'yup';

import LocalStrings from '../../assets/localisation';
import { ESignDialogProps } from './e-signature.model';
import classes from './e-signature.module.css';
import { eSignatureStyles } from './e-signature.styles';
import { mergeFieldsWithAttributeConfig } from './utils';
import { oktaSignInConfig } from './utils/config';

const OktaSignInWidget = lazy(() => import('./utils/okta-signin-widget'));

export const userType = {
  Collaborator: 'cd_create_collaboration',
  Owner: 'cd_create_complete_draft',
  Admin: ['cd_create_admin_review', 'cd_pre_approved_doc_admin_review'],
  Functional_Approval: 'cd_create_functional_approval',
  Quality_Approval: 'cd_create_quality_approval',
};

const ESignatureView = ({
  open,
  verdictOptions,
  selectedVerdict,
  isEsignRequired,
  comments,
  onInputChange,
  onCancelClicked,
  onSave,
  onVerdictChange,
  onEsignError,
  onEsignSuccess,
  showOktaModal,
  dataTestId,
  loading,
  taskInfo,
  isLoading = false,
  errors,
  formFields = [],
  verdictTaskField = [],
  attributeConfig,
  recordData,
  contextMenuAction,
  signatureInstructions,
  isFieldsLoading,
  isCommentRequired,
  isSignatureMeaningRequired,
  commentTitle,
  methods,
  IsRejectVerdictSelected,
  setValidationSchema,
  validateVerdictAndCommentsField,
  isRejectionCommentRequired,
}: ESignDialogProps): JSX.Element => {
  const { cssVariables } = useTheme();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<Maybe<UseFormReturn>>(null);

  const styles = eSignatureStyles();

  const radioLabel = useId('label');

  const handleChange =
    (key: string) =>
    (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onInputChange(key, event.currentTarget.value);
    };

  const getDialogTitle = () => {
    let label;
    if (showOktaModal) {
      label = `Okta Sign - ${taskInfo?.label}`;
    } else if (taskInfo) {
      label = taskInfo?.label;
    } else {
      label = contextMenuAction?.label;
    }
    return label;
  };

  const taskFields = mergeFieldsWithAttributeConfig(
    formFields,
    attributeConfig!
  );
  const verdictTaskFields = mergeFieldsWithAttributeConfig(
    verdictTaskField,
    attributeConfig!
  );

  const generateFormGroups = () => {
    const signatureMeaningFieldIndex = verdictTaskFields?.findIndex(
      (field: any) => field.key === 'signatureMeaning'
    );

    if (signatureMeaningFieldIndex !== -1) {
      (verdictTaskFields as ObjectAttributeDefinition[])[
        signatureMeaningFieldIndex
      ].row = taskFields?.length + verdictTaskFields?.length + 1;
    }
    return [
      {
        isLoading: isFieldsLoading,
        fields: [...taskFields, ...verdictTaskFields],
      },
    ];
  };

  const commentsLabel: string = useMemo(() => {
    if (commentTitle) {
      return commentTitle;
    }
    return LocalizationString.COMMENTS;
  }, [commentTitle]);

  return (
    <Dialog open={open} data-testid={dataTestId}>
      <DialogSurface
        data-test-id={dataTestId}
        style={{
          padding: '0px',
          overflowY: loading ? 'hidden' : 'auto',
          overflowX: 'hidden',
          ...cssVariables,
        }}
        className={styles.eSignContainer}
      >
        {loading ? (
          <>
            <DialogTitle
              className={mergeClasses(
                styles.modalHeader,
                styles.loadingModalHeader
              )}
            />
            <DialogContent
              className={mergeClasses(
                styles.modalBody,
                (!showOktaModal || loading) && styles.setModalBodyHeight,
                showOktaModal && styles.oktaBody,
                styles.loaderContainer
              )}
            >
              <Loader />
            </DialogContent>
            <DialogActions
              fluid
              className={mergeClasses(
                styles.modalFooter,
                styles.loadingModalFooter
              )}
            />
          </>
        ) : (
          <div>
            <div className={showOktaModal ? styles.overflowHidden : ''}>
              <DialogTitle className={styles.modalHeader}>
                <b data-testid={`dialog-title`}>{getDialogTitle()}</b>

                <Button
                  data-testid="button-dismiss"
                  appearance="subtle"
                  icon={<Icon iconName="Dismiss24Regular" />}
                  onClick={onCancelClicked}
                />
              </DialogTitle>

              <DialogContent
                className={mergeClasses(
                  styles.modalBody,
                  !showOktaModal && styles.setModalBodyHeight,
                  showOktaModal && styles.oktaBody
                )}
              >
                {!showOktaModal ? (
                  <>
                    {taskInfo?.instruction && (
                      <div className={classes.group}>
                        <p
                          data-testid="label-task-instruction"
                          className={classes.fieldLabel}
                        >
                          {LocalizationString.TASK_INSTRUCTION}
                        </p>
                        <p
                          data-testid={`label-task-instruction-value`}
                          className={classes.fieldValue}
                        >
                          {taskInfo?.instruction}
                        </p>
                      </div>
                    )}
                    <div className={classes.group}>
                      <p
                        data-testid="label-record-id"
                        className={classes.fieldLabel}
                      >
                        {LocalizationString.ID}
                      </p>
                      <p
                        data-testid={`label-record-id-value`}
                        className={classes.fieldValue}
                      >
                        {recordData?.label}
                      </p>
                    </div>
                    <div className={classes.group}>
                      <p
                        data-testid="label-document-title"
                        className={`${classes.fieldLabel} ${classes.ellipsis}`}
                      >
                        {LocalizationString.TITLE}
                      </p>
                      <p
                        data-testid={`label-document-title-value`}
                        className={classes.fieldValue}
                      >
                        {recordData?.title ?? recordData?.course?.title}
                      </p>
                    </div>

                    {taskInfo?.verdictAndCommentFromPreviousNode
                      ?.verdictName && (
                      <div className={classes.group}>
                        <p
                          data-testid="label-document-title"
                          className={`${classes.fieldLabel} ${classes.ellipsis}`}
                        >
                          {LocalizationString.VERDICT_FROM_PREVIOUS_NODE}
                        </p>
                        <p
                          data-testid={`label-document-title-value`}
                          className={classes.fieldValue}
                        >
                          {
                            taskInfo?.verdictAndCommentFromPreviousNode
                              ?.verdictName
                          }
                        </p>
                      </div>
                    )}

                    {taskInfo?.verdictAndCommentFromPreviousNode?.comment && (
                      <div className={classes.group}>
                        <p
                          data-testid="label-comment-from-previous-node"
                          className={`${classes.fieldLabel} ${classes.ellipsis}`}
                        >
                          {LocalizationString.COMMENT_FROM_PREVIOUS_NODE}
                        </p>
                        <p
                          data-testid={`label-comment-from-previous-node-value`}
                          className={classes.fieldValue}
                        >
                          {taskInfo?.verdictAndCommentFromPreviousNode?.comment}
                        </p>
                      </div>
                    )}

                    {verdictOptions && verdictOptions?.length > 0 && (
                      <div className={classes.marginBtm}>
                        <RadioButton
                          label={'Verdict'}
                          required={true}
                          onChange={(option) =>
                            onVerdictChange &&
                            onVerdictChange({
                              value: option,
                              isCommentRequired:
                                verdictOptions.find(
                                  (obj) => option === obj.value
                                )?.isCommentRequired ?? false,
                              fields: verdictOptions.find(
                                (obj) => obj.value === option
                              )?.fields,
                              isESignatureRequired:
                                verdictOptions.find(
                                  (obj) => option === obj.value
                                )?.isESignatureRequired ?? false,
                              isSignatureMeaningRequired: verdictOptions.find(
                                (obj) => option === obj.value
                              )?.isSignatureMeaningRequired,
                              signatureInstructions:
                                verdictOptions.find(
                                  (obj) => option === obj.value
                                )?.signatureInstructions ?? undefined,
                              commentTitle:
                                verdictOptions.find(
                                  (obj) => option === obj.value
                                )?.commentTitle ?? '',
                            })
                          }
                          defaultValue={selectedVerdict}
                          errorMessage={errors?.verdict ?? ''}
                          layout="horizontal"
                          aria-labelledby={radioLabel}
                          options={verdictOptions}
                        />
                      </div>
                    )}

                    {signatureInstructions && (
                      <div className={classes.paddingFields}>
                        <TextFieldArea
                          label="Signature Instructions"
                          value={signatureInstructions}
                          className={classes.inputWidth}
                          readOnly={true}
                        />
                      </div>
                    )}

                    <FormGroup
                      groups={generateFormGroups()}
                      attributeConfig={attributeConfig}
                      recordData={recordData}
                      onSave={onSave}
                      formRef={formRef}
                      submitRef={submitRef}
                      className={classes.group}
                      methods={methods}
                      setValidationSchema={setValidationSchema}
                    />

                    {/* comments field.  */}
                    {taskInfo ? (
                      <div
                        className={
                          isSignatureMeaningRequired ? classes.group : ''
                        }
                      >
                        {IsRejectVerdictSelected ? (
                          <TextFieldArea
                            data-testid="input-field-comments"
                            label={LocalizationString.REJECT_REASON}
                            value={comments}
                            onChange={handleChange('rejection')}
                            errorMessage={errors?.rejectionComments}
                            required={isRejectionCommentRequired}
                          />
                        ) : (
                          <TextFieldArea
                            data-testid="input-field-comments"
                            label={commentsLabel}
                            value={comments}
                            onChange={handleChange('comments')}
                            errorMessage={errors?.verdictComments}
                            required={isCommentRequired}
                          />
                        )}
                      </div>
                    ) : null}

                    {isSignatureMeaningRequired ? (
                      <div className={classes.group}>
                        <p
                          data-testid="label-signature-instruction"
                          className={classes.fieldLabel}
                        >
                          {LocalizationString.DISCLAIMER}
                        </p>
                        <p
                          data-testid={`label-document-title-value`}
                          className={classes.fieldValue}
                        >
                          {LocalizationString.ESIGNATURE_TEXT}
                        </p>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <Suspense fallback={<Loader />}>
                    <OktaSignInWidget
                      config={oktaSignInConfig}
                      onSuccess={onEsignSuccess}
                      onError={onEsignError}
                      recordName={recordData?.name}
                      objectName={recordData?.objectDefinitionName}
                    />
                  </Suspense>
                )}
              </DialogContent>

              {!showOktaModal && (
                <DialogActions fluid className={styles.modalFooter}>
                  <CustomButton
                    buttonTitle={LocalStrings.cancelLabel}
                    buttonType={ButtonTypes.Ghost}
                    onButtonClicked={onCancelClicked}
                    disabled={isLoading}
                  />
                  <CustomButton
                    buttonTitle={
                      isEsignRequired
                        ? LocalStrings.eSignLabel
                        : LocalStrings.completeLabel
                    }
                    isLoading={isLoading}
                    buttonType={ButtonTypes.Primary}
                    onClick={() => {
                      if (submitRef.current) {
                        submitRef.current.click();
                        validateVerdictAndCommentsField();
                        return;
                      }

                      onSave();
                    }}
                    disabled={isLoading}
                  />
                </DialogActions>
              )}
            </div>
          </div>
        )}
      </DialogSurface>
    </Dialog>
  );
};

export default ESignatureView;
