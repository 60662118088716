import { Field, MultiFileUploadButton, Stack } from '@celito.clients/shared';
import { FileDataProps } from 'libs/shared/src/lib/multi-file-upload/multi-file-upload.model';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { ControlledMultiFileUploadProps } from './controlled.multi-fileupload.model';
import classes from './controlled.multi-fileupload.module.css';
export const ControlledMultiFileUploadView = <T extends FieldValues>({
  size = 'large',
  ...props
}: ControlledMultiFileUploadProps<T>) => {
  return (
    <Controller<T>
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const docs: Record<string, unknown>[] =
          (props.watch && props.watch(props.attribute.name as Path<T>)) || [];

        const documentIds = docs?.map((doc) => doc.documentId) as string[];

        const handleFilesSelect = (files: File[] | null) => {
          onChange({
            target: {
              value: files,
            },
          });
        };

        return (
          <Stack className={classes.section}>
            <Field
              label={props.attribute?.label}
              helperTextLabel={props.attribute.helpText}
              size={size}
              required={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
            >
              <MultiFileUploadButton
                onFilesSelect={handleFilesSelect}
                fileNames={value?.map(
                  (file: File | FileDataProps) =>
                    (file as File).name || (file as FileDataProps).label
                )}
                attribute={props.attribute}
                watch={props.watch}
                setValue={props.setValue}
                reset={props.reset}
                error={error}
                mode={props.mode}
                documentIds={documentIds}
                objectName={props?.objectName}
                uploadedBy={value?.map((file: File | FileDataProps) =>
                  file instanceof File
                    ? ''
                    : (file as FileDataProps).uploadedByUserName
                )}
                maxFileSizeInBytes={props.attribute?.maxFileSizeInBytes}
              />
            </Field>
          </Stack>
        );
      }}
    />
  );
};
