import { useUser } from '@celito.clients/hooks';
import { UserTypes } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { Loader } from 'libs/shared/src/lib/shared';
import { useCallback, useEffect, useState } from 'react';

import { UserViewProps } from './user-view.model';
import classes from './user-view.module.css';
import { UserViewView } from './user-view.view';

const transformFormData = (userData?: UserTypes) => {
  const formData = [
    {
      label: 'Name',
      value: userData?.label,
    },
    {
      label: 'Email',
      value: userData?.email,
    },
    {
      label: 'Department',
      value: userData?.department,
    },
    {
      label: 'Title',
      value: userData?.title,
    },
    {
      label: 'Phone',
      value: userData?.mobileNumber,
    },
    {
      label: 'Manager',
      value: userData?.manager,
    },
    {
      label: 'Country',
      value: userData?.country,
    },
    {
      label: 'Timezone',
      value: userData?.timezone,
    },
    {
      label: 'Status',
      value: userData?.isActive ? 'Active' : 'Inactive',
    },
  ];

  return formData;
};

export const UserViewController = ({ name }: UserViewProps) => {
  const { fetchUserInfo } = useUser();

  const [userData, setUserData] = useState<UserTypes | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const getUserData = useCallback(() => {
    setIsLoading(true);
    fetchUserInfo(name)
      .then((resp: UserTypes) => {
        setUserData(resp);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [name]);

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return isLoading ? (
    <Loader className={classes.center} />
  ) : (
    <UserViewView userData={userData} transformFormData={transformFormData} />
  );
};
