import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  CustomButton,
  FormActionButtonsContainer,
  Loader,
  Stack,
} from '@celito.clients/shared';
import { Label } from '@fluentui/react-components';

import { FollowUpPageProps } from './follow-up-page.model';
import style from './follow-up-page.module.css';
interface FollowUpPageViewProps extends FollowUpPageProps {
  isLoading: boolean;
  followUpData: Record<string, unknown> | undefined;
  covertTimeFormat: (time: string) => string;
}

export const FollowUpPageView = ({
  isLoading,
  followUpData,
  covertTimeFormat,
  goToEditScreen,
  goToNextStep,
  goToPreviousStep,
  onCancel,
}: FollowUpPageViewProps): JSX.Element => {
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <Stack className={style.container}>
          <div className={style.headerContainer}>
            <div className={style.child}>
              <Label className={style.label}>
                {LocalizationString.DECISION_DUE_DATE}
              </Label>
              <Label className={style.value}>
                {followUpData?.decisionDueDate
                  ? covertTimeFormat(followUpData?.decisionDueDate as string)
                  : '-'}
              </Label>
            </div>
            <div className={style.child}>
              <Label className={style.label}>
                {LocalizationString.APPROVAL_DUE_DATE}
              </Label>
              <Label className={style.value}>
                {followUpData?.approvalDueDate
                  ? covertTimeFormat(followUpData?.approvalDueDate as string)
                  : '-'}
              </Label>
            </div>
          </div>
          <div className={`${style.child} ${style.pd20}`}>
            <Label className={style.label}>
              {LocalizationString.FOLLOW_UP_COMMENT}
            </Label>
            <Label className={style.value}>
              {(followUpData?.comment as string) || '-'}
            </Label>
          </div>
          <div className={style.fullWidth}>
            <Label className={style.label}>
              {LocalizationString.EVALUATION_DATE}
            </Label>
            <Label className={style.value}>
              {followUpData?.evaluationDate
                ? covertTimeFormat(followUpData?.evaluationDate as string)
                : '-'}
            </Label>
          </div>
        </Stack>
      )}
      <FormActionButtonsContainer>
        <CustomButton
          buttonTitle={LocalizationString.CANCEL}
          buttonType={ButtonTypes.Ghost}
          customStyles={style.btn}
          onClick={onCancel}
        />
        <div className={style.formActionBtns}>
          <CustomButton
            buttonTitle={LocalizationString.PREVIOUS}
            leftIcon="Previous"
            buttonType={ButtonTypes.Ghost}
            onClick={goToPreviousStep}
          />
          <CustomButton
            buttonTitle={LocalizationString.NEXT}
            leftIcon="Next"
            buttonType={ButtonTypes.Ghost}
            onClick={goToNextStep}
          />
          <CustomButton
            buttonTitle={'Edit'}
            leftIcon="Edit"
            buttonType={ButtonTypes.Primary}
            onClick={goToEditScreen}
          />
        </div>
      </FormActionButtonsContainer>
    </>
  );
};
