import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledPickerProps } from './controlled-picker.model';
import { ControlledPickerView } from './controlled-picker.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledPickerController = <T extends FieldValues>(
  props: ControlledPickerProps<T>
) => {
  return <ControlledPickerView {...props} />;
};
