import { AssignmentStatusEnum, ROUTES } from '@celito.clients/enums';
import { Icon } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';
import { useNavigate } from 'react-router';

import classes from './action-column.module.css';

interface IActionItem {
  label: string;
  onActionClick: (type: string, item: RowItem) => void;
  icon?: JSX.Element;
  type: string;
}

interface ActionColumnProps {
  dataTestId?: string;
  item: RowItem;
  column?: ColumnData;
  index?: number;
  startTraining: () => void;
  assignmentStatus?: AssignmentStatusEnum;
}

export const ActionColumn = ({
  dataTestId,
  item,
  startTraining,
  assignmentStatus,
}: ActionColumnProps): JSX.Element | null => {
  const navigate = useNavigate();

  const onMenuItemClick = (menuItem: string, rowData?: RowItem) => {
    if (menuItem === 'viewFile') {
      if (assignmentStatus === AssignmentStatusEnum.Assigned) {
        startTraining();
      }

      const assignmentName = rowData?.assignmentName as string;
      navigate(
        `../${ROUTES.DOCUMENT_VIEW}?training_assignment_name=${assignmentName}`
      );
    }
  };

  const menuItems: IActionItem[] = [
    {
      type: 'viewFile',
      label: 'View File',
      icon: <Icon iconName="Circle16Regular" />,
      onActionClick: onMenuItemClick,
    },
  ];

  return (
    <div data-testid={dataTestId}>
      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <div>
            <Icon
              iconName="MoreHorizontal24Regular"
              style={{ width: '19px' }}
            />
          </div>
        </MenuTrigger>

        <MenuPopover>
          <MenuList hidden={!item.isActive} className={classes.menuList}>
            {menuItems.map((menuItem) => {
              return (
                <MenuItem
                  onClick={(e) => {
                    e.stopPropagation();
                    menuItem.onActionClick(menuItem.type, item);
                  }}
                  className={classes.actionMenuItem}
                >
                  <div
                    className={classes.itemContent}
                    data-testid={`context-menu-option-${createTestAttribute(
                      menuItem.label
                    )}`}
                  >
                    {menuItem.label}
                  </div>
                </MenuItem>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};
