import { ObjectEnum } from '@celito.clients/enums';
import {
  ObjectActionDefinition,
  ObjectAttributeType,
  Task,
} from '@celito.clients/types';
import WizardState from 'libs/form-engine/src/lib/components/wizard-state/wizard-state.component';

import { useScreenContext } from '../../context';
import { ControlledDocs } from '../../types';
import { useController } from './controller';

export type WizardDataType = {
  wizardStates: string[] | undefined;
  activeWizardState: string;
};

export type TLifecycleProps = {
  wizardData: WizardDataType;
  response?: ControlledDocs;
  recordName?: string;
  objectActionDefinition: ObjectActionDefinition[];
  objectDefinitions: ObjectAttributeType;
  task: Task | undefined;
};

/**
 *  Wrapper over the form-engine's Wizard State component
 */
export function Lifecycle() {
  const props = useController();
  const { invalidateRecordCache, refetchTaskData } = useScreenContext();

  return (
    <WizardState
      objectName={ObjectEnum.CONTROLLED_DOCUMENT}
      progressTabs={props.wizardData.wizardStates!}
      wizardState={props?.wizardData?.activeWizardState}
      recordName={props.recordName}
      recordData={props.response}
      objectActionDefinitions={props?.objectActionDefinition}
      objectDefinitions={props?.objectDefinitions}
      onRefetchRequest={() => {
        refetchTaskData?.();
        invalidateRecordCache?.();
      }}
      task={props?.task}
    />
  );
}
