import { useEffect } from 'react';
import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledRichTextProps } from './controlled-richtext.model';
import { ControlledRichTextView } from './controlled-richtext.view';

// Decision making component
// This component will be responsible for deciding which UI to render
// based on the current state of the application
export const ControlledRichTextController = <T extends FieldValues>(
  props: ControlledRichTextProps<T>
) => {
  useEffect(() => {
    const adjustTooltipPosition = () => {
      const tooltip = document.querySelector('.ql-tooltip') as HTMLElement;
      if (!tooltip) return;
      if (tooltip?.style?.left) {
        const leftValue = parseInt(tooltip?.style?.left, 10);
        if (leftValue < 0) {
          tooltip.style.left = '0';
        }
      }
    };

    window.addEventListener('click', adjustTooltipPosition);

    return () => {
      window.removeEventListener('click', adjustTooltipPosition);
    };
  }, []);

  return <ControlledRichTextView {...props} />;
};
