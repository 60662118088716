import { ObjectAttributeType, Section } from '@celito.clients/types';
import { changeAtIndex, insertAtIndex } from '@celito.clients/utils';

export const getSectionData = (
  sections: Section[] = [],
  step: number,
  attributeConfig: ObjectAttributeType,
  recordDetails?: Record<string, unknown>
): Section => {
  const section = sections[step];
  let refactoredSectionFields = [...section.fields];

  const conditionalObjectAttributes =
    attributeConfig.objectAttributeDefinitions.filter((item) => !!item.childs);

  let insertedItems = 0;
  section.fields.forEach((item, index) => {
    const foundAttribute = conditionalObjectAttributes.find(
      (c) => c.name === item.columnName
    );

    if (foundAttribute && foundAttribute.childs?.length) {
      const childAttributeName =
        foundAttribute?.childs?.[0]!.lookupAttributeName;
      const childAttributeColumnName =
        attributeConfig.objectAttributeDefinitions.find(
          (c) => c.name === childAttributeName
        )!.columnName;

      refactoredSectionFields = changeAtIndex(
        refactoredSectionFields,
        insertedItems + index,
        {
          ...item,
          width: 1,
        }
      );

      refactoredSectionFields = insertAtIndex(
        refactoredSectionFields,
        insertedItems + index + 1,
        {
          columnName: recordDetails?.[childAttributeColumnName]
            ? childAttributeName
            : 'SPACE',
          width: 1,
        }
      );

      refactoredSectionFields = insertAtIndex(
        refactoredSectionFields,
        insertedItems + index + 2,
        {
          columnName: 'SPACE',
          width: 1,
        }
      );

      insertedItems = insertedItems + 2;
    }
  });

  return { ...section, fields: refactoredSectionFields };
};
