import { actionColumnListViewStyles } from '@celito.clients/list-view-engine';
import {
  FluentIconNames,
  HeaderActionButtonsContainer,
  Icon,
  Menu,
} from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import {
  Button,
  makeStyles,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  shorthands,
} from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { PropsWithChildren, useState } from 'react';
import { Outlet } from 'react-router';

import {
  DocumentTypeLayoutProvider,
  TAction,
  useDocumentTypeLayoutContext,
} from './context';

/**
 *  Layout Component for all Document Type Screens
 */
export default function DocumentTypePage() {
  return (
    <Provider>
      <HeaderActions />
      <Outlet />
    </Provider>
  );
}

const getStyles = makeStyles({
  menuButton: {
    backgroundColor: `${Color.context_button_color} !important`,
    ...shorthands.border('none'),
    width: '40px',
    height: '40px',
    color: `${Color.white} !important`,
  },
});

const HeaderActions = () => {
  const { contextMenuActions } = useDocumentTypeLayoutContext();
  const actionColumnStyles = actionColumnListViewStyles();
  const styles = getStyles();
  if (contextMenuActions.length)
    return (
      <HeaderActionButtonsContainer>
        <Menu positioning={'below-end'}>
          <MenuTrigger>
            <Button
              data-testid={`button-more-action`}
              className={styles.menuButton}
            >
              <Icon iconName="MoreHorizontal24Regular" />
            </Button>
          </MenuTrigger>
          <MenuPopover>
            <MenuList className={actionColumnStyles.menuList}>
              {contextMenuActions.map((action, index) => (
                <MenuItem
                  key={index}
                  data-testid={`context-menu-option-${createTestAttribute(
                    action.label
                  )}`}
                  className={actionColumnStyles.actionMenuItem}
                  onClick={action.onClick}
                >
                  <div className={actionColumnStyles.itemContent}>
                    <Icon iconName={action.iconName as FluentIconNames} />
                    {action.label}
                  </div>
                </MenuItem>
              ))}
            </MenuList>
          </MenuPopover>
        </Menu>
      </HeaderActionButtonsContainer>
    );
  return null;
};

const Provider = ({ children }: PropsWithChildren) => {
  const [contextMenuActions, setContextMenuActions] = useState<TAction[]>([]);
  return (
    <DocumentTypeLayoutProvider
      value={{
        contextMenuActions,
        setContextMenuActions,
      }}
    >
      {children}
    </DocumentTypeLayoutProvider>
  );
};
