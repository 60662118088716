import { createContext } from 'react';

import { ListViewContextType } from '../types';

export const ListViewContext = createContext<ListViewContextType>({
  fetchRowData: async () => {},
  onColumnClick: () => {},
  showShimmeringRows: () => {},
  currentPageNumber: 0,
  filters: { conditions: undefined },
  sortConfig: undefined,
  selectedFilterView: undefined,
  handleFilterViewChange: () => {},
});
