import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledReferenceSelectorController } from './controlled-reference-selector.controller';
import { ControlledReferenceSelectorProps } from './controlled-reference-selector.model';

export const ControlledReferenceSelector = <T extends FieldValues>(
  props: ControlledReferenceSelectorProps<T>
) => {
  return <ControlledReferenceSelectorController {...props} />;
};
