import { Environment } from '@celito.clients/types';

export interface Scripts {
  // Name has to be unique in each case
  name:
    | 'newrelic-automation'
    | 'newrelic-develop'
    | 'newrelic-experiments'
    | 'newrelic-qa'
    | 'newrelic-staging';
  src: string;
  environments?: Environment[];
}

export const SCRIPTS: Scripts[] = [
  {
    name: 'newrelic-develop',
    src: '../assets/scripts/newrelic.develop.js',
    environments: [Environment.Development],
  },
  {
    name: 'newrelic-experiments',
    src: '../assets/scripts/newrelic.experiments.js',
    environments: [Environment.Experiments],
  },
  {
    name: 'newrelic-qa',
    src: '../assets/scripts/newrelic.qa.js',
    environments: [Environment.QA],
  },
  {
    name: 'newrelic-staging',
    src: '../assets/scripts/newrelic.staging.js',
    environments: [Environment.Staging],
  },
];
