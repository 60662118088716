import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  Container,
  CustomButton,
  Field,
  InHouseInputSelect,
  Stack,
  StackItem,
  SwappableSelect,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { Input, mergeClasses, Text } from '@fluentui/react-components';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React from 'react';
import { Controller } from 'react-hook-form';

import { returnSelectedOptionFromOptions } from '../../../Platform/admin-ui-screen/utils/utils';
import { AddGroupModel } from './add-group.model';
import classes from './add-group.module.css';
import { addGroupStyles } from './add-group.styles';

const Row: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <Stack className={classes.row}>{children}</Stack>;
};

const Column: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <StackItem className={classes.column}>{children}</StackItem>;
};

const DisplayItem: React.FC<{ label: string; value?: string }> = ({
  label,
  value = '',
}) => {
  const styles = addGroupStyles();

  return (
    <div className={styles.displayView}>
      <Text data-testid="static-labels-add-group" className={classes.label}>
        {label}
      </Text>
      <Text data-testid="static-values-add-group" className={classes.value}>
        {value}
      </Text>
    </div>
  );
};

const AddGroupView: React.FC<AddGroupModel> = ({
  onSubmit,
  control,
  options,
  isLoading,
  isPermissionGrpCreated,
  onOptionSelect,
  list,
  rightItemList,
  setList,
  setRightItemList,
  handleSubmit,
  selectedList,
  setSelectedList,
  navigateBack,
  navigateBackRoute,
}) => {
  const { cssVariables } = useTheme();

  const styles = addGroupStyles();

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };

  const statusOptions: IOption[] = [
    { text: 'Yes', value: 'yes' },
    { text: 'No', value: 'no' },
  ];

  return (
    <Container
      title="Add Permission Group"
      showBackBtn
      navigateBackRoute={navigateBackRoute}
      style={{
        ...cssVariables,
      }}
      className={styles.container}
    >
      <form onSubmit={onFormSubmit} noValidate>
        <Row>
          <Column>
            <Controller
              name={'label'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Group Name"
                  size="large"
                  required
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <Input
                    className={mergeClasses(
                      styles.textField,
                      error && styles.textFieldError
                    )}
                    data-testid="input-group-name-add-group"
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    aria-errormessage={error && error.message}
                  />
                </Field>
              )}
            />
          </Column>
          <Column>
            <Controller
              name={'description'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Description"
                  size="large"
                  aria-errormessage={error && error.message}
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <Input
                    className={mergeClasses(
                      styles.textField,
                      error && styles.textFieldError
                    )}
                    data-testid="input-group-description-add-group"
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    aria-errormessage={error && error.message}
                  />
                </Field>
              )}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Controller
              name={'roleNames'}
              control={control}
              render={({ field: { onBlur, value }, fieldState: { error } }) => {
                return (
                  <InHouseInputSelect
                    data-testid="select-group-role-add-group"
                    id="combobox"
                    label="Roles"
                    options={options}
                    selectedOptions={returnSelectedOptionFromOptions(
                      options,
                      value[0]
                    )}
                    required
                    placeholder={'Select roles'}
                    onOptionSelect={onOptionSelect}
                    errorMessage={error && error.message}
                  />
                );
              }}
            />
          </Column>
          <Column>
            <Controller
              name={'isActive'}
              control={control}
              render={({
                field: { onBlur, value, onChange },
                fieldState: { error },
              }) => (
                <InHouseInputSelect
                  label={LocalizationString.ACTIVE}
                  data-testid="select-group-status-add-group"
                  options={statusOptions}
                  selectedOptions={returnSelectedOptionFromOptions(
                    statusOptions,
                    value
                  )}
                  onOptionSelect={(_e, option) => {
                    onChange(option?.optionValue);
                  }}
                  errorMessage={error && error.message}
                />
              )}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem label="Created By" />
          </Column>
          <Column>
            <DisplayItem label="Created Date" />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem label="Modified By" />
          </Column>
          <Column>
            <DisplayItem label="Modified Date" />
          </Column>
        </Row>
        <Row>
          {!isLoading ? (
            <SwappableSelect
              list={list}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              setList={setList}
              setRightItemList={setRightItemList}
              rightItemList={rightItemList}
              leftLabel="Available Members"
              rightLabel="Selected Members"
            />
          ) : (
            <div />
          )}
        </Row>
      </form>
      <Stack className={classes.footer}>
        <CustomButton
          data-testid="button-cancel-add-group"
          customStyles={classes.btn_container}
          buttonType={ButtonTypes.Ghost}
          buttonTitle="Cancel"
          onButtonClicked={navigateBack}
        />
        <CustomButton
          data-testid="button-save-add-group"
          customStyles={classes.btn_container}
          leftIcon="Save"
          buttonType={ButtonTypes.Primary}
          buttonTitle={'Save'}
          isLoading={isPermissionGrpCreated}
          onSubmit={onSubmit}
        />
      </Stack>
    </Container>
  );
};

export default AddGroupView;
