import { LocalizationString } from '@celito.clients/assets';
import {
  AssignmentStatusEnum,
  ButtonTypes,
  CompletionTypeEnum,
  TaskStatusEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { fetchObjectTaskInfo } from '@celito.clients/services';
import {
  CompleteTrainingButton,
  CustomButton,
  Icon,
} from '@celito.clients/shared';
import { Task } from '@celito.clients/types';
import { Label } from '@fluentui/react-components';
import cn from 'classnames';
import { convert } from 'html-to-text';
import { useEffect, useState } from 'react';

import { trainingAssignmentObjectName } from '../../document/documents-view-page/config';
import { documentSignOffTag } from '../config';
import { getQuizAttempts } from '../services';
import classes from '../taking-assingment.module.css';
import { TakingAssingmetViewProps } from '../taking-assingment.view';
import { QuizAttemptsData } from '../types';

interface CompleteBarProps extends TakingAssingmetViewProps {}

const takingTrainingTag = documentSignOffTag;

export const CompleteBar = ({
  assignmentData,
  trainingAssignmentType,
  isBizAdmin,
  startTraining,
  viewCertificate,
  user,
  courseData,
}: CompleteBarProps): JSX.Element | null => {
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<Task>();
  const [showMore, setShowMore] = useState(false);
  const [quizAttempts, setQuizAttempts] = useState<
    QuizAttemptsData | undefined
  >(undefined);

  useEffect(() => {
    if (assignmentData) {
      let params: Record<string, string> | undefined;

      if (assignmentData?.status === AssignmentStatusEnum.Completed) {
        params = { status: TaskStatusEnum.Done };
        if (
          trainingAssignmentType === TrainingAssignmentTypeEnum.MyAssignments
        ) {
          params.tag = takingTrainingTag;
        }
      }
      setIsLoading(true);
      fetchObjectTaskInfo(
        trainingAssignmentObjectName,
        assignmentData?.name,
        params
      )
        .then((response) => {
          setResponse(response?.task);
        })
        .catch((_error) => {
          // Do nothing
        })
        .finally(() => {
          setIsLoading(false);
        });
    }

    if (
      assignmentData?.status === AssignmentStatusEnum.InProgress &&
      assignmentData?.course?.completionType === CompletionTypeEnum.Quiz
    ) {
      getQuizAttemptsDetails();
    }
  }, []);

  const getQuizAttemptsDetails = async () => {
    try {
      const quizAttemptsData = await getQuizAttempts(
        assignmentData?.name ?? ''
      );
      setQuizAttempts(quizAttemptsData);
    } catch (error) {
      // Do nothing
    }
  };

  const isMaterialReviewNeededFoQuiz = () => {
    if (
      assignmentData?.course?.completionType === CompletionTypeEnum.Quiz &&
      quizAttempts &&
      !quizAttempts.isPassed &&
      !quizAttempts.hasQuizAttemptLeft
    )
      return true;
    return false;
  };

  const isUserNotOwnerOrDelegatedUser = () => {
    if (
      response &&
      user?.name !== response?.ownerName &&
      !JSON.parse(response?.delegatedUserNames ?? '[]').includes(user?.name)
    )
      return true;

    return false;
  };

  const isDocumentComplete = () => {
    if (assignmentData?.course?.document && assignmentData?.isDocumentComplete)
      return true;
    return false;
  };

  const getButtonData = (): {
    buttonTitle: string | null;
    onClick?: () => void;
    isAdminComplete?: boolean;
  } => {
    if (
      isBizAdmin &&
      trainingAssignmentType !== TrainingAssignmentTypeEnum.MyAssignments
    ) {
      if (assignmentData?.status === AssignmentStatusEnum.Completed) {
        return {
          buttonTitle: 'View Certificate',
          onClick: viewCertificate,
          isAdminComplete: true,
        };
      } else if (assignmentData?.status === AssignmentStatusEnum.UnAssigned) {
        return {
          buttonTitle: '',
          onClick: () => {},
        };
      }
      return {
        buttonTitle: 'Complete',
        isAdminComplete: false,
      };
    }

    if (isUserNotOwnerOrDelegatedUser()) {
      return {
        buttonTitle: '',
        onClick: () => {},
      };
    }

    if (assignmentData?.status === AssignmentStatusEnum.Completed) {
      return {
        buttonTitle: 'View Certificate',
        onClick: viewCertificate,
      };
    }

    if (
      assignmentData?.status === AssignmentStatusEnum.Assigned ||
      assignmentData?.status === AssignmentStatusEnum.Overdue
    ) {
      return {
        buttonTitle: 'Start Training',
        onClick: () => startTraining(true, true),
      };
    }

    if (assignmentData?.status === AssignmentStatusEnum.InProgress) {
      if (isMaterialReviewNeededFoQuiz()) {
        return {
          buttonTitle: 'Review Material',
          onClick: () => startTraining(true, true),
        };
      }
      if (isDocumentComplete()) {
        return {
          buttonTitle: 'Complete',
        };
      }
    }

    if (assignmentData?.status === AssignmentStatusEnum.PendingSignOff) {
      return {
        buttonTitle: 'Complete',
      };
    }

    return {
      buttonTitle: '',
      onClick: () => {},
    };
  };

  const buttonData = getButtonData();

  if (!buttonData.buttonTitle || !assignmentData || !assignmentData?.course) {
    return null;
  }

  const getTaskTitle = () => {
    if (isLoading) {
      return `${LocalizationString.LOADING}...`;
    }
    if (response) {
      return response.label;
    }
    return LocalizationString.TASK_DATA_NOT_FOUND;
  };

  return (
    <div
      className={cn(classes.complete_bar, {
        [classes.complete_bar_no_task]:
          assignmentData?.status === AssignmentStatusEnum.Completed,
      })}
    >
      {assignmentData?.status !== AssignmentStatusEnum.Completed && (
        <div className={classes.task_info}>
          <div className={classes.clipboard_icon}>
            <Icon iconName="ClipboardTextRtl20Filled" />
          </div>
          <div className={classes.parentbtn}>
            <div className={classes.topbtn}>
              <div className={classes.labels}>
                <Label weight="semibold">
                  {LocalizationString.TASK_TITLE}:
                </Label>
                <Label>{getTaskTitle()}</Label>
              </div>

              {response ? (
                <button
                  className={classes.showHideText}
                  onClick={() => setShowMore(!showMore)}
                >
                  {!showMore ? 'Show More' : 'Show Less'}
                </button>
              ) : null}
            </div>
            {showMore ? (
              <div className={classes.descDiv}>
                <Label weight="semibold">
                  Description:{' '}
                  <span className={classes.descText}>
                    {response?.description
                      ? convert(response?.description)
                      : '-'}
                  </span>
                </Label>
              </div>
            ) : null}
          </div>
        </div>
      )}
      {buttonData.buttonTitle === 'Complete' ? (
        <CompleteTrainingButton
          trainingAssignmentData={assignmentData}
          trainingAssignmentType={trainingAssignmentType}
          trainingPlanName={assignmentData?.course?.name || ''}
          showInHeaderActions={false}
        />
      ) : (
        <CustomButton
          buttonType={ButtonTypes.Primary}
          buttonTitle={buttonData.buttonTitle}
          onClick={buttonData.onClick}
          isLoading={isLoading}
          iconPosition="before"
          disabled={
            ['Start Training', 'Review Material'].includes(
              buttonData.buttonTitle
            ) && !courseData
          }
        />
      )}
    </div>
  );
};
