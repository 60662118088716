import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const getStyles = makeStyles({
  pageContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.padding('1.625rem'),
    ...shorthands.flex(1),
    ...shorthands.gap('24px'),
    '& .gutter': {
      ...shorthands.marginInline('0px !important'),
    },
  },
  stickyTop: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    alignSelf: 'start',
    ...shorthands.padding('16px'),
  },
  splitView: {
    ...shorthands.flex(1),
    ...shorthands.border('4px', 'solid', 'var(--gray-100)'),
  },
  rightPane: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    ...shorthands.flex(1),
    '& h2, & h3': {
      fontWeight: 600,
    },
  },
  editButtonContainer: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    width: '100%',
    display: 'flex',
    alignSelf: 'end',
    justifyContent: 'space-between !important',
    zIndex: 999,
    backgroundColor: 'var(--gray-1300)',
    boxShadow: '0px 1px 0px 0px var(--gray-200) inset',
    ...shorthands.padding('16px'),
    '&>button:only-child': {
      marginLeft: 'auto',
    },
  },
  mcContainer: {
    display: ' flex',
    flexDirection: 'column',
    paddingBottom: '10px',
    ...shorthands.flex(1),
    ...shorthands.gap('10px'),
    ...shorthands.overflow('auto'),
    ...shorthands.padding('0px', '16px', '16px', '16px'),
    scrollbarGutter: 'stable both-edges',
    listStylePosition: 'inside',
    '& .ql-container': {
      height: 'auto !important',
    },
    '& [data-testid=dropdown-select-reference-document]': {
      minWidth: '240px',
    },
  },
  iFrameEditor: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    '& iframe': {
      width: '100%',
      height: '100%',
      ...shorthands.border('none'),
    },
  },
  loader: {
    height: 'min-content',
  },
  rightPaneLoader: {
    display: 'flex',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },

  fullPageLoader: {
    height: '100%',
    width: '100%',
    '& > div': {
      ...shorthands.marginBlock('auto'),
    },
  },
  tabs: {
    backgroundColor: Color.background_body_bg,
    width: '216px',
    height: '44px',
    ...shorthands.borderRadius('8px'),
    '& span': {
      display: 'flex',
      fontWeight: 600,
      ...shorthands.gap('1px'),
      flexDirection: 'row',
    },
    '::after': {
      backgroundColor: 'unset !important',
    },
    '::before': {
      backgroundColor: 'unset !important',
    },
    '[aria-selected="true"]': {
      backgroundColor: `${Color.background_dark_blue_quiz} !important`,
      '& span': {
        color: `${Color.white} !important`,
      },
    },
    '&:hover': {
      backgroundColor: `${Color.background_dark_blue_quiz} !important`,
      '::before': {
        backgroundColor: 'unset !important',
      },
      '::after': {
        backgroundColor: 'unset !important',
      },
      '& span': {
        color: `${Color.white} !important`,
      },
    },
  },
  tablist: {
    display: 'flex',
    ...shorthands.gap('25px'),
    width: '100%',
    scrollBehavior: 'smooth',
    overflowY: 'hidden',
    '::-webkit-scrollbar': {
      display: 'none !important',
    },
  },

  tablistContainer: {
    ...shorthands.gap('12px'),
    ...shorthands.flex(1),
    width: '100%',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    paddingTop: '15px',
    paddingBottom: '10px',
  },

  scrollButton: {
    alignSelf: 'center',
  },
  ht300: {
    height: '100% !important',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  tabText: {
    position: 'relative',
    top: '4px',
  },
  scrollButtons: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    pointerEvents: 'none',
    ...shorthands.paddingInline('16px'),
    justifyContent: 'space-between',
    ...shorthands.gap('4px'),
    alignItems: 'center',
    zIndex: 10000,
    '& button': {
      pointerEvents: 'all',
      backgroundColor: 'white',
    },
  },
  menuButton: {
    backgroundColor: `${Color.context_button_color} !important`,
    ...shorthands.border('none'),
    width: '40px',
    height: '40px',
    color: `${Color.white} !important`,
  },

  relative: {
    position: 'relative',
  },

  sectionGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
    alignItems: 'start',
    ...shorthands.gap('32px'),
    ...shorthands.padding('16px'),
  },
  spanAllColumns: {
    gridColumnStart: 1,
    gridColumnEnd: -1,
  },
  doubleColumnGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(350px, 1fr))',
    ...shorthands.gap('32px'),
  },
  fullWidth: {
    width: '100% !important',
  },
  fieldContainerWrapper: {
    '& > div': {
      width: 'unset !important',
    },
  },
});
