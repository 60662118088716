import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

const disabledPrimaryStyles = {
  backgroundColor: 'var(--colorNeutralForegroundDisabled)',

  '> span svg': {
    color: `${Color.white}`,
  },

  '&:hover': {
    backgroundColor: 'var(--colorNeutralForegroundDisabled)',

    '> span svg': {
      color: `${Color.white}`,
    },
  },
};

const disabledStyles = {
  color: 'var(--colorNeutralForegroundDisabled)',
  ...shorthands.border('1px', 'solid', 'var(--colorNeutralForegroundDisabled)'),

  '&:hover': {
    color: 'var(--colorNeutralForegroundDisabled)',
    ...shorthands.border(
      '1px',
      'solid',
      'var(--colorNeutralForegroundDisabled)'
    ),
  },
};

export const customButtonStyles = makeStyles({
  buttonStyles: {
    ...shorthands.border('none'),
    ...shorthands.padding('9px', '22px'),
    ...shorthands.gap('8px'),
    ...shorthands.borderRadius('4px'),

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '1rem',

    '> span': {
      ...shorthands.margin(0),
    },
  },

  destructive: {
    backgroundColor: 'transparent',
    ...shorthands.border('none'),
    ...shorthands.textDecoration('underline'),
    ...shorthands.padding('1rem'),

    '&:hover': {
      backgroundColor: Color.white,
    },
    '&:disabled': {
      '> span svg': {
        color: `var(--colorNeutralForegroundDisabled)`,
      },

      '&:hover': {
        '> span svg': {
          color: `var(--colorNeutralForegroundDisabled)`,
        },
      },
    },
  },
  primary: {
    backgroundColor: Color.blue_700,
    color: Color.white,

    '&:hover': {
      backgroundColor: Color.blue_1100,
      color: Color.white,
    },
    '&:disabled': {
      ...disabledPrimaryStyles,
    },
  },

  secondary: {
    color: Color.blue_700,
    backgroundColor: 'rgba(29, 57, 196, 0.08)',
    ...shorthands.border('1px', 'solid', 'rgba(29, 57, 196, 0.05)'),

    '&:hover': {
      backgroundColor: Color.blue_1500,
      color: Color.black,
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },

  ghost: {
    color: Color.blue_700,
    backgroundColor: 'transparent',
    ...shorthands.border('1px', 'solid', Color.blue_700),

    '&:hover': {
      color: Color.blue_900,
      backgroundColor: 'transparent',
      ...shorthands.border('1px', 'solid', Color.blue_900),
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },

  link: {
    color: Color.blue_700,
    backgroundColor: 'transparent',
    ...shorthands.border('none'),
    ...shorthands.textDecoration('underline'),

    '&:hover': {
      color: Color.blue_700,
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },
  text: {
    color: Color.blue_700,
    backgroundColor: 'transparent',
    ...shorthands.border('none'),

    '&:hover': {
      color: Color.blue_700,
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },

  white: {
    color: Color.neutral_foreground,
    backgroundColor: 'white',
    ...shorthands.border('none'),

    '&:hover': {
      color: Color.neutral_foreground,
      backgroundColor: Color.gray_100,
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },

  blueButton: {
    backgroundColor: Color.blue_2000,
    color: Color.white,

    '&:hover': {
      backgroundColor: Color.blue_1900,
      color: Color.white,
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },

  transparent: {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: Color.white,

    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.3)',
      color: Color.white,
    },
    '&:disabled': {
      ...disabledStyles,
    },
  },
});
