import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import { ConfirmDialog, Icon } from '@celito.clients/shared';
import { ToolbarButton } from '@fluentui/react-components';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';

import { QuestionsColumnActionsProps } from './questions-column-actions.model';
import { questionsColumnActionsStyles } from './questions-column-actions.styles';
import classes from './questions-column-actions.styles.module.css';

interface QuestionsColumnActionsViewProps extends QuestionsColumnActionsProps {
  showDeleteAlert: boolean;
  closeDeleteAlert: (deleteRec?: boolean) => void;
  onEditClick: (rowData: RowItem) => void;
  openDeleteAlert: () => void;
  isDelete: boolean;
}

export const QuestionsColumnActionsView = ({
  dataTestId,
  showDeleteAlert,
  closeDeleteAlert,
  onEditClick,
  openDeleteAlert,
  item,
  isDelete,
}: QuestionsColumnActionsViewProps): JSX.Element => {
  const styles = questionsColumnActionsStyles();

  return (
    <div data-testid={dataTestId}>
      <ToolbarButton
        aria-label="Edit"
        icon={<Icon iconName="Edit24Regular" />}
        data-testid={dataTestId}
        onClick={(e) => onEditClick(item)}
      />
      <ToolbarButton
        aria-label="Delete"
        icon={<Icon iconName="Delete24Regular" color="red" />}
        data-testid={dataTestId}
        onClick={(e) => openDeleteAlert()}
        disabled={isDelete}
        className={classes.deleteButton}
      />
      <ConfirmDialog
        dataTestId={dataTestId}
        open={showDeleteAlert}
        onCancelClicked={() => closeDeleteAlert()}
        onConfirmClicked={() => closeDeleteAlert(true)}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_QUESTION}
        iconSrc={DeleteIcon}
        description={
          LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION
        }
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
      <ConfirmDialog
        dataTestId={dataTestId}
        open={showDeleteAlert}
        onCancelClicked={() => closeDeleteAlert()}
        onConfirmClicked={() => closeDeleteAlert(true)}
        primaryButtonText={LocalizationString.DELETE}
        title={LocalizationString.DELETE_QUESTION}
        iconSrc={DeleteIcon}
        description={
          LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_DELETE_QUESTION
        }
        secondaryButtonText={LocalizationString.CANCEL}
        isBlocking
      />
    </div>
  );
};
