import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { ObjectAttributeDefinition } from '@celito.clients/types';

import { FieldEnum } from '../../config/field-enum';
import { TField } from '../../config/section';
import { ControlledDocs } from '../../types';
import DocumentGrid from './document-grid';
import { Field } from './field';

export type TCustomFieldProps = Partial<{
  mode: FormEngineModeTypeEnum;
  record: ControlledDocs;
  attribute: ObjectAttributeDefinition;
  name: TField;
  fieldProps?: Record<string, unknown>;
}>;

export default Object.assign(
  function CustomField(props: TCustomFieldProps) {
    const { record } = props;
    const name = props.name?.toString();
    switch (name) {
      case FieldEnum.VERSION:
        return (
          <Field
            label="Version"
            value={record?.version}
            visible={record?.version !== undefined}
          />
        );
      case FieldEnum.FILE:
        return <DocumentGrid {...props} />;
      default:
        return null;
    }
  },
  { names: [FieldEnum.VERSION, FieldEnum.FILE] as string[] }
);
