export enum SpinnerSize {
  small = 'small',
  tiny = 'tiny',
  extraSmall = 'extra-small',
  medium = 'medium',
  large = 'large',
  extraLarge = 'extra-large',
  huge = 'huge',
  extraTiny = 'extra-tiny',
}
