import { LocalizationString } from '@celito.clients/assets';
import { Field, Icon } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { InfoLabel, mergeClasses } from '@fluentui/react-components';
import { ChangeEvent } from 'react';

import { TextFieldMultipleProps } from './text-field.multiple.model';
import { TextFieldMultipleStyles } from './text-field.multiple.styles';

interface TextFieldMultipleViewProps
  extends Omit<TextFieldMultipleProps, 'onChange'> {
  inputTextRef: React.MutableRefObject<HTMLInputElement | null>;
  inputTextNoItemsRef: React.MutableRefObject<HTMLInputElement | null>;
  handleCustomSearch: (customSearch: string) => void;
  customSearch: string;
  tagContainerRef: React.MutableRefObject<HTMLDivElement | null>;
  onInput: () => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange: (
    event: ChangeEvent<HTMLInputElement>,
    value?: string,
    isToRemove?: boolean
  ) => void;
}

export const TextFieldMultipleView = ({
  label,
  errorMessage,
  required,
  disabled,
  value,
  placeholder,
  inputTextNoItemsRef,
  inputTextRef,
  customSearch,
  onInput,
  handleCustomSearch,
  tagContainerRef,
  onBlur,
  onKeyDown,
  onChange,
}: TextFieldMultipleViewProps) => {
  const styles = TextFieldMultipleStyles();

  const isDisabled = disabled;

  return (
    <Field
      label={label === '' ? undefined : label}
      size="large"
      className={mergeClasses(styles.field)}
      validationMessage={errorMessage}
      validationState={errorMessage ? 'error' : 'none'}
      required={required}
    >
      <div
        className={mergeClasses(
          styles.inputContainer,
          isDisabled && styles.btnTriggerDisabled,
          errorMessage && styles.error
        )}
        onClick={(_ev) => {
          if (!isDisabled) {
            if (value?.length === 0) {
              inputTextNoItemsRef.current?.focus();
            } else {
              inputTextRef.current?.focus();
            }
          }
        }}
      >
        <div className={styles.tagContainer} ref={tagContainerRef}>
          {value?.length > 0 ? (
            <div className={styles.tagInputContainer}>
              {value?.map?.((value, index) => (
                <div
                  data-testid={`input-text-multiple-${createTestAttribute(
                    value
                  )}`}
                  className={mergeClasses(
                    styles.tagInnerContainer,
                    isDisabled && styles.tagDisabled
                  )}
                >
                  <span className={styles.tagOption}>{value}</span>

                  <div
                    data-testid="input-text-multiple-remove"
                    className={mergeClasses(
                      styles.dismissContainer,
                      isDisabled && styles.dismissContainerDisabled
                    )}
                    onClick={(_ev) => {
                      if (!isDisabled) {
                        _ev.stopPropagation();

                        onChange(
                          _ev as unknown as ChangeEvent<HTMLInputElement>,
                          value,
                          true
                        );
                      }
                    }}
                  >
                    <Icon iconName="Dismiss12Filled" />
                  </div>
                </div>
              ))}

              <input
                ref={inputTextRef}
                className={mergeClasses(
                  styles.inputTextCustomized,
                  styles.inputTextAutoWidth,
                  isDisabled && styles.inputDisabled
                )}
                type="text"
                value={customSearch}
                onChange={(_ev) => {
                  handleCustomSearch(_ev.target.value);
                }}
                disabled={disabled}
                onInput={onInput}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
              />
            </div>
          ) : (
            <input
              onKeyDown={onKeyDown}
              onBlur={onBlur}
              ref={inputTextNoItemsRef}
              style={{ width: '100%' }}
              className={mergeClasses(
                styles.inputTextCustomized,
                isDisabled && styles.inputDisabled
              )}
              type="text"
              value={customSearch}
              placeholder={placeholder ?? LocalizationString.SEARCH_AND_ADD}
              onChange={(_ev) => {
                handleCustomSearch(_ev.target.value);
              }}
              disabled={disabled}
            />
          )}
        </div>

        <InfoLabel
          size="large"
          info={'Enter Value and press enter to add the option'}
          className={styles.infoLabel}
          tabIndex={-1}
        />
      </div>
    </Field>
  );
};
