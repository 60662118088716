import { ApiMethodType, ObjectEnum } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { FilterAsConditions, FilterCriteria } from '@celito.clients/types';
import { AxiosResponse } from 'axios';
import Endpoints from 'libs/core/src/config/endpoint';
import { QueryKey, useQuery, UseQueryOptions } from 'react-query';

interface GetObjectDataOptions {
  filters?: FilterCriteria[] | FilterAsConditions[];
}

type TPerformRequestArgsWithoutURL = {
  data: Parameters<typeof performRequest>[2];
  params: Parameters<typeof performRequest>[3];
  config: Parameters<typeof performRequest>[4];
};

export const getObjectData = async <T = unknown>(
  objectName: string,
  recordName = '',
  method: ApiMethodType = ApiMethodType.POST,
  performRequestArgs?: TPerformRequestArgsWithoutURL
) => {
  const args = {
    data: {} satisfies GetObjectDataOptions,
    params: {
      limit: 1000000,
      page: 1,
      getLatestVersionOnly: true,
    },
    ...performRequestArgs,
  };
  const response = (await performRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
    method,
    args.data,
    args.params,
    args.config
  )) as AxiosResponse<T, never>;
  return response;
};

export type TUseObjectDataArgs<T> = {
  objectName: ObjectEnum;
  recordName?: string;
  method?: ApiMethodType;
  queryKey?: QueryKey;
  requestArgs?: TPerformRequestArgsWithoutURL;
  queryOptions?: UseQueryOptions<T>;
};

/**
 * React hook for fetching object data from the meta data api
 *
 * Supports passing custom `performRequest` args
 */
export const useObjectData = <T = unknown>({
  objectName,
  recordName,
  queryKey,
  requestArgs,
  method,
  queryOptions,
}: TUseObjectDataArgs<T>) => {
  return useQuery(
    queryKey ??
      (recordName ? [objectName, 'data', recordName] : [objectName, 'data']),
    {
      queryFn: async () => {
        const { data } = await getObjectData<T>(
          objectName,
          recordName,
          method,
          requestArgs
        );
        return data;
      },
      staleTime: 10 * 1000,
      retryDelay: () => 10 * 1000,
      ...queryOptions,
    }
  );
};
