import { useCallbackPrompt } from '@celito.clients/hooks';
import { formSubmitApi } from '@celito.clients/services';
import { LayoutRulesDataSchema } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormEngineContextProvider } from 'libs/form-engine/src/lib/context';
import { useLayoutRules } from 'libs/form-engine/src/lib/hooks/useLayoutRules';
import {
  getFormEngineDefaultValues,
  refactorFormData,
} from 'libs/form-engine/src/lib/utils/helper';
import { generateYupSchemaFromLayoutRules } from 'libs/form-engine/src/lib/utils/validator-generator';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { SingleLayoutContainerProps } from '../single-layout-container.model';
import { AddLayoutView } from './add.view';

export const AddLayoutController = (props: SingleLayoutContainerProps) => {
  const {
    sections = [],
    attributeConfig: objectDefinition,
    objectName,
    submitUrl,
  } = props;
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const [isCreatedModalOpen, setIsCreatedModalOpen] = useState(false);
  const [toggleContainer, setToggleContainer] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isDataSaved, setIsDataSaved] = useState(false);
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject>
  >({} as yup.ObjectSchema<yup.AnyObject>);
  const attachmentsPropName = 'attachments__a';
  // for system objects the attachment key name will be attachments__s
  const attachmentSystemPropName = 'attachments__s';

  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
    mode: 'all',
    defaultValues: getFormEngineDefaultValues(objectDefinition),
  });

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    getValues,
    formState: { isDirty },
    reset,
  } = methods;

  const formData = watch();
  // using stringified version to avoid multiple re-renders
  const formDataStringified = JSON.stringify(formData);
  const { fieldsState, setFieldsState } = useLayoutRules(
    formData,
    sections?.[0] ?? []
  );
  const fieldsStateStringified = JSON.stringify(fieldsState);

  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified) as Record<
      string,
      LayoutRulesDataSchema
    >;

    const newSchema = generateYupSchemaFromLayoutRules(
      watch(),
      sections?.[0]?.fields ?? [],
      objectDefinition?.objectValidationRules ?? [],
      objectDefinition?.objectAttributeDefinitions ?? [],
      fieldsStateParsed
    );

    if (
      newSchema &&
      typeof newSchema === 'object' &&
      Object.keys(newSchema).length > 0
    ) {
      setValidationSchema(newSchema);
    }
  }, [
    formDataStringified,
    fieldsStateStringified,
    watch,
    sections?.[0]?.fields,
  ]);

  const { showPrompt, confirmNavigation, cancelNavigation } = useCallbackPrompt(
    !!props.showCancelPopup && isDirty && !isCancelModalOpen
  );

  const toggleWarningModalBtnClick = () => {
    setIsWarningModalOpen(!isWarningModalOpen);
  };

  const toggleCreateModalBtnClick = () => {
    setIsCreatedModalOpen(!isCreatedModalOpen);
    if (isCreatedModalOpen) {
      if (submitUrl) {
        navigate(`../${submitUrl}`);
        return;
      }
      navigate(-1);
    }
  };

  const toggleCancelModalBtnClick = (navigateBack?: boolean) => {
    if (navigateBack) {
      navigate(-1);
    } else {
      setIsCancelModalOpen((prev) => !prev);
    }
  };

  const onCancel = () => {
    if (props.showCancelPopup && methods.formState.isDirty) {
      setIsCancelModalOpen(true);
    } else {
      onRouteBack();
    }
  };

  const onRouteBack = () => {
    reset(undefined, { keepDirty: false });
    confirmNavigation();
    navigate(-1);
  };

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();

    handleSubmit(onSave);
  };

  const callFormApi = async (payload: object) => {
    setIsDataSaved(true);
    try {
      await formSubmitApi(objectName, payload);
      confirmNavigation();

      toggleCreateModalBtnClick();
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsDataSaved(false);
    }
  };

  const onSave = async () => {
    if (!methods.formState.errors) reset({ ...getValues(), isDirty: false });
    await handleSubmit(
      (rawData) => {
        const data = refactorFormData(objectDefinition, rawData);
        data.file__a = watch('file__a');
        if (objectDefinition.isVersioningEnabled) data.version__s = 1;

        if (!data.file__a) {
          delete data?.['file__a'];
        }
        delete data?.['isDirty'];

        delete data?.version__s;

        const attachments =
          watch(attachmentsPropName) ?? watch(attachmentSystemPropName);
        if (attachments?.length > 0) {
          data.secondary_file__a = [...attachments];
        }

        delete data?.[attachmentsPropName];
        delete data?.[attachmentSystemPropName];
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (
            data[key] !== undefined &&
            data[key] !== null &&
            data[key] !== '' &&
            !Number.isNaN(data[key])
          ) {
            if (Array.isArray(data[key]) && key === 'secondary_file__a') {
              for (const item of data[key]) {
                formData.append(key, item);
              }
              return;
            }
            if (Array.isArray(data[key])) {
              return formData.append(key, JSON.stringify(data[key]));
            }
            formData.append(key, data[key]);
          }
        });
        callFormApi(formData);
      },
      (_err) => {
        toggleWarningModalBtnClick();
      }
    )();
  };

  const successDescription = useMemo(() => {
    return `${objectDefinition.label} has been created.`;
  }, [objectDefinition.label]);

  return (
    <FormEngineContextProvider {...{ fieldsState, setFieldsState }}>
      <FormProvider {...methods}>
        <AddLayoutView
          {...{
            onCancel,
            attributeConfig: objectDefinition,
            control,
            onSave,
            handleSubmit,
            onFormSubmit,
            watch,
            setValue,
            trigger,
            sections,
            isWarningModalOpen,
            isCreatedModalOpen,
            onCreationModeModal: 'save',
            toggleWarningModalBtnClick,
            toggleCreateModalBtnClick,
            mode: props.mode,
            toggleContainer,
            setToggleContainer,
            methods,
            isCancelModalOpen,
            toggleCancelModalBtnClick,
            isLoading: isDataSaved,
            showPrompt,
            confirmNavigation,
            cancelNavigation,
            successDescription,
          }}
          {...props}
        />
      </FormProvider>
    </FormEngineContextProvider>
  );
};
