import { ViewTypeEnum } from '../enums/view-type';
import { HeaderConfig } from '../types/header-config';

export const initalHeaderConfig: HeaderConfig = {
  celitoApplicationDtoList: [
    {
      modifiedAtUtc: '',
      createdAtUtc: '',
      isActive: true,
      name: '',
      label: '',
      celitoModules: [
        {
          label: '',
          ownerType: '',
          name: '',
          createdAtUtc: '',
          modifiedAtUtc: '',
          deletedAtUtc: null,
          isActive: true,
          description: '',
          celitoTabs: [
            {
              label: '',
              ownerType: '',
              name: '',
              title: '',
              createdAtUtc: '',
              modifiedAtUtc: '',
              deletedAtUtc: null,
              isActive: true,
              isModuleSettings: false,
              viewType: ViewTypeEnum.List,
              childTabs: [],
              displayOrder: 1,
              iconName: '',
              isPartOfRequestMenu: false,
              viewName: '',
              description: '',
            },
          ],
          displayOrder: 1,
        },
      ],
      ownerType: '',
    },
  ],
};
