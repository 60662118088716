import { UrlString } from '@celito.clients/assets';
import {
  ControlledDocumentStatusEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import { isExistInUrl, requiresVersionParam } from '@celito.clients/utils';

import { Datum } from '../types';

const hasObjectRecordName = (
  val: Datum
): val is Datum & {
  objectRecord: {
    name: string;
  };
} => {
  return Boolean(
    typeof val.objectRecord === 'object' &&
      val.objectRecord &&
      'name' in val.objectRecord
  );
};

const generateRedirectionUrlFromMyTasks = (
  viewName: string,
  objectName: string,
  taskName: string,
  version: string | undefined,
  isCustomView: boolean
): string => {
  const baseViewPath = isCustomView ? '../customview' : '../view';

  return `${baseViewPath}/${viewName}/${objectName}?taskName=${taskName}${version}`;
};

const getNavigateUrl = (
  rowClickableViewName: string | undefined,
  item: Datum
): string => {
  if (
    item?.objectDefinitionName === 'task__s' &&
    (item?.object as Record<string, unknown>)?.name !==
      ObjectEnum.CONTROLLED_DOCUMENT
  ) {
    const rowItem = item as Datum & {
      object: { detailViewName: string };
      objectRecord: { name: string; version: string };
    };
    const version = rowItem?.objectRecord?.version;

    const viewName = rowItem?.object?.detailViewName;
    const objectName = rowItem?.objectRecord?.name;
    const taskName = rowItem?.name as string;
    const versionParam = version ? `&version=${version}` : '';

    return generateRedirectionUrlFromMyTasks(
      viewName,
      objectName,
      taskName,
      versionParam,
      !rowItem?.object?.detailViewName?.includes('custom/')
    );
  }

  if (
    typeof rowClickableViewName !== 'string' ||
    rowClickableViewName.length < 1
  ) {
    throw new Error('rowClickableViewName should be a valid string.');
  }

  if (
    isExistInUrl(UrlString.LIBRARY_TRAINING_PLAN) ||
    isExistInUrl(UrlString.TRAINING_PLAN_VIEW)
  ) {
    return `../view/${rowClickableViewName}/${item.name}?version=${item.version}`;
  }

  if (
    (isExistInUrl(UrlString.CD_MY_TASKS) ||
      isExistInUrl(UrlString.ADMIN_ALL_TASKS)) &&
    hasObjectRecordName(item) &&
    'version' in item.objectRecord &&
    'documentStatus' in item.objectRecord
  ) {
    const versionParam = requiresVersionParam(
      item.objectRecord.version as string,
      item.objectRecord.documentStatus as ControlledDocumentStatusEnum
    )
      ? `&version=${item.objectRecord.version}`
      : '';
    return `../view/${rowClickableViewName}/${item.objectRecord.name}?taskName=${item.name}${versionParam}`;
  }

  if (
    isExistInUrl(UrlString.CD_MY_RECORDS) ||
    isExistInUrl(UrlString.CD_LIBRABY)
  ) {
    const versionParam = requiresVersionParam(
      item.version as string,
      item.documentStatus as ControlledDocumentStatusEnum
    )
      ? `?version=${item.version}`
      : '';
    return `../view/${rowClickableViewName}/${item.name}${versionParam}`;
  }

  if (
    isExistInUrl(UrlString.CD_UNSUBMITTED_LIST_VIEW) ||
    isExistInUrl(UrlString.CD_PENDING_RECORDS_UNSUBMITTED_LIST_VIEW)
  ) {
    return `../view/${rowClickableViewName}/${
      item.name
    }?requestType=${encodeURIComponent(
      item.initiationType as string
    )}&version=${item?.version}`;
  }

  if (isExistInUrl(UrlString.WORKFLOWS)) {
    return `../${rowClickableViewName}/${item.name}/${item.version}`;
  }

  return `../view/${rowClickableViewName}/${item.name}${
    item?.version ? `?version=${item?.version}` : ''
  }`;
};

export default getNavigateUrl;
