import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';

interface IDepartmentResponse {
  label: string;
  ownerType: string;
  name: string;
  createdAtUtc: Date;
  modifiedAtUtc: Date;
  deletedAtUtc: Date | null;
  isActive: boolean;
  objectDefinitionName: string;
  title?: string;
}

export const getAllDepartment = async () => {
  const response = await performRequest<IDepartmentResponse[]>(
    `${Endpoints.ALL_DEPARTMENTS}`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};
