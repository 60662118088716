/**
 * Represents a class that manages postload tasks.
 */
class PostloadTasks {
  /**
   * A list of tasks to be executed.
   */
  public tasks: Array<() => void> = [];

  /**
   * Registers a task to be executed.
   * @param task - The task to be registered.
   */
  register(task: () => void) {
    this.tasks.push(task);
  }

  /**
   * Starts executing all the registered tasks.
   */
  start() {
    this.tasks.forEach((task) => task());
  }
}
export const postload = new PostloadTasks();
