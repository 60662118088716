import { postload } from '@celito.clients/services';
import { Link, Text } from '@fluentui/react-components';
import React, { useEffect } from 'react';

import LogoIcon from '../../../../../assets/src/images/footer-logo.png';
import { LocalizationString } from '../../../../../assets/src/localization';
import { FooterComponentProps } from './footer.model';
import styles from './footer.module.css';

const FooterView: React.FC<FooterComponentProps> = ({ itemList, ...rest }) => {
  const getFooterLinks = (itemList: Array<{ label: string; url: string }>) => {
    return itemList.map(
      (item: { label: string; url: string }, index: number) => (
        <React.Fragment key={index}>
          <Link
            className={`${styles.textStyle}`}
            href={item.url}
            target={'_blank'}
            rel="noreferrer"
            style={{ textDecoration: 'underline' }}
            appearance="subtle"
          >
            {item.label}
          </Link>
          {index !== itemList.length - 1 && (
            <Text className={`${styles.textStyle} ${styles.spaceStyle}`}>
              |
            </Text>
          )}
        </React.Fragment>
      )
    );
  };

  useEffect(() => {
    postload.start();
  }, []);

  return (
    <div className={`${styles.wrapper} ${styles.rows} ${styles.container}`}>
      <Text className={`${styles.textStyle} ${styles.one}`}>
        {LocalizationString.CELITO_COPYWRIGHT_LABEL}
      </Text>
      <div className={`${styles.rows} ${styles.two}`}>
        <img
          src={LogoIcon}
          alt="footer-logo"
          className={`${styles.logoStyle}`}
        />
      </div>
      <div className={`${styles.three}`}>{getFooterLinks(itemList)}</div>
    </div>
  );
};
export default FooterView;
