import { useLayout } from '@celito.clients/hooks';
import { createPortal } from 'react-dom';

import { HeaderActionButtonsContainerProps } from './header-action-buttons-container.model';

interface HeaderActionButtonsContainerViewProps
  extends HeaderActionButtonsContainerProps {}

const HeaderActionButtonsContainerView = ({
  children,
}: HeaderActionButtonsContainerViewProps) => {
  const { screenActionsContainerRef } = useLayout();
  if (screenActionsContainerRef?.current)
    return createPortal(children, screenActionsContainerRef.current);
  return null;
};

export default HeaderActionButtonsContainerView;
