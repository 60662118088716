import { makeStyles } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const headerStyles = makeStyles({
  container: {
    backgroundImage: `linear-gradient(92deg, ${Color.dark_blue_gradiant} -2.93%, ${Color.light_bluen_gradiant}  103.99%)`,
    width: '100%;',
    fontFamily: `'Metropolis', sans-serif`,
    position: 'relative',
  },

  headerExtraOverflow: {
    position: 'absolute',
    height: '8rem',
    width: '100%',
    bottom: '-8rem',
    backgroundImage: `linear-gradient(92deg, ${Color.dark_blue_gradiant} -2.93%, ${Color.light_bluen_gradiant}  103.99%)`,
  },

  logo: {
    paddingTop: '0.3rem',
    width: '7.375rem',
  },

  moreIconColor: {
    color: Color.white,
  },
  heading: {
    color: Color.white,
    marginTop: '-1.5rem',
    paddingLeft: '0.6rem',
    paddingBottom: '1rem',
    paddingTop: '0.3em',
    textOverflow: 'ellipsis',
    maxWidth: '65vw',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '1.5rem',
  },
});
