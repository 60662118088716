import { LocalizationString } from '@celito.clients/assets';
import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';

import { QuestionsColumnActions } from '../components';
import { QuizPreviewPageProps } from '../quiz-preview-page.model';
import { IQuestion } from '../types';

export const getQuizQuestionsGridViewColumnConfig = (
  quizProps: QuizPreviewPageProps
) => {
  const allQuestions: string = quizProps.recordDetails?.questions
    ? (quizProps.recordDetails?.questions as string)
    : '';
  const totalQuestions =
    allQuestions.length > 0 ? JSON.parse(allQuestions).length : 0;

  const questionsListViewColumnConfig: ColumnData[] = [
    {
      key: 'question',
      minWidth: 100,
      maxWidth: 200,
      name: LocalizationString.QUESTION,
      fieldName: 'question',
      isResizable: true,
    },
    {
      key: 'mandatory',
      minWidth: 60,
      maxWidth: 90,
      name: LocalizationString.MANDATORY,
      fieldName: 'mandatory',
      isResizable: true,
    },
    {
      key: 'type',
      minWidth: 80,
      maxWidth: 150,
      name: LocalizationString.TYPE,
      fieldName: 'type',
      isResizable: true,
    },
    {
      key: 'actions',
      minWidth: 40,
      maxWidth: 60,
      name: LocalizationString.ACTIONS,
      fieldName: 'actions',
      isResizable: true,
      onRender: (item, index, column) =>
        QuestionsColumnActions({
          item,
          column,
          index,
          quizProps,
          totalQuestions,
        }),
    },
  ];
  return questionsListViewColumnConfig;
};

export const getQuizQuestionsGridViewItems = (
  recordDetails?: Record<string, unknown>
): RowItem[] => {
  if (recordDetails?.questions) {
    let questions = recordDetails?.questions;
    if (typeof questions === 'string') {
      questions = JSON.parse(recordDetails.questions as string) as IQuestion[];
    }

    if (Array.isArray(questions)) {
      return questions.map((q, index) => ({
        key: q.order,
        order: q.order,
        question: q.questionText,
        mandatory: q.isMandatory ?? false,
        type: q.questionType,
      }));
    }
    return [];
  }
  return [];
};
