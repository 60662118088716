import { IChangeData } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { useState } from 'react';

import { CurriculumPickerModalProps } from './curriculum-picker-modal.model';
import { CurriculumPickerModalView } from './curriculum-picker-modal.view';

export const CurriculumPickerModalController = (
  props: CurriculumPickerModalProps
) => {
  const [selectedValue, setSelectedValue] = useState<IChangeData>();
  const [version, setVersion] = useState<string>();

  const onPickerValChange = (val: IChangeData) => {
    setSelectedValue(val);
  };

  const onClose = () => {
    setSelectedValue(undefined);
    props.onCloseCurriculumUpdatePickerModal();
  };

  return (
    <CurriculumPickerModalView
      {...props}
      {...{
        selectedValue,
        onPickerValChange,
        version,
        setVersion,
        onClose,
      }}
    />
  );
};
