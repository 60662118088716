import { useCallback, useState } from 'react';

import { Item, SwappableSelectViewProps } from './swappable-select.model';
import SwappableSelectView from './swappable-select.view';

const onNameBasis = (a: Item, b: Item) => {
  if (a.name.toLowerCase() < b.name.toLowerCase()) {
    return -1;
  }
  if (a.name.toLowerCase() > b.name.toLowerCase()) {
    return 1;
  }
  return 0;
};

const SwappableSelectController = (props: SwappableSelectViewProps) => {
  const [isLeftCheckAll, setIsLeftCheckAll] = useState<boolean>(false);
  const [isRightCheckAll, setIsRightCheckAll] = useState<boolean>(false);
  const [leftCheckList, setLeftCheckList] = useState<(number | string)[]>([]);
  const [rightCheckList, setRightCheckList] = useState<(number | string)[]>([]);

  const resetList = () => {
    setLeftCheckList([]);
    setRightCheckList([]);
    setIsLeftCheckAll(false);
    setIsRightCheckAll(false);
  };

  const handleLeftSelectAll = () => {
    setIsLeftCheckAll(!isLeftCheckAll);
    setLeftCheckList(props.list.map((li) => li.id));
    if (isLeftCheckAll) {
      setLeftCheckList([]);
    }
  };

  const handleRightSelectAll = () => {
    setIsRightCheckAll(!isRightCheckAll);
    setRightCheckList(props.rightItemList.map((li) => li.id));
    if (isRightCheckAll) {
      setRightCheckList([]);
    }
  };

  const handleLeftItemClick = useCallback((item: Item, isChecked: boolean) => {
    const { id } = item;
    if (isChecked) {
      // push selected value in list
      setLeftCheckList((prev) => {
        return [...prev, id];
      });
    } else {
      // remove unchecked value from the list
      setLeftCheckList((prev) => prev.filter((x) => x !== id));
    }
  }, []);

  const handleRightItemClick = (item: Item, isChecked: boolean) => {
    const { id } = item;
    setRightCheckList([...rightCheckList, id]);
    if (!isChecked) {
      setRightCheckList((current) =>
        current.filter((item: number | string) => item !== id)
      );
    }
  };

  const moveItemsToRight = () => {
    const selectedItemList = props.list.filter((item: Item) =>
      leftCheckList.includes(item.id)
    );
    props.setRightItemList((current) => {
      const newList = [...current, ...selectedItemList];
      props.setSelectedList(newList.map((item) => item.id));
      return newList;
    });
    props.setList((current) => {
      const filterList = current.filter(
        (item: Item) => !leftCheckList.includes(item.id)
      );
      return filterList;
    });
    resetList();
  };

  const moveItemsToLeft = () => {
    const selectedItemList = props.rightItemList.filter((item: Item) =>
      rightCheckList.includes(item.id)
    );
    props.setList((current) =>
      [...current, ...selectedItemList].sort(onNameBasis)
    );
    props.setRightItemList((current) => {
      const filterList = current.filter(
        (item: Item) => !rightCheckList.includes(item.id)
      );
      props.setSelectedList(
        filterList.sort(onNameBasis).map((item) => item.id)
      );
      return filterList;
    });
    resetList();
  };

  return (
    <SwappableSelectView
      {...{
        rightLabel: props.rightLabel,
        leftLabel: props.leftLabel,
        list: props.list,
        selectedList: props.selectedList,
        setSelectedList: props.setSelectedList,
        disabled: props.disabled,
        leftItemList: props.list,
        rightItemList: props.rightItemList,
        handleLeftItemClick,
        handleRightItemClick,
        handleLeftSelectAll,
        handleRightSelectAll,
        moveItemsToLeft,
        moveItemsToRight,
        leftCheckList,
        rightCheckList,
        isLeftCheckAll,
        isRightCheckAll,
        setList: props.setList,
        setRightItemList: props.setRightItemList,
      }}
    />
  );
};

export default SwappableSelectController;
