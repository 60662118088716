import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledDatePickerController } from './controlled-datepicker.controller';
import { ControlledDatePickerProps } from './controlled-datepicker.model';

const ControlledDatePicker = <T extends FieldValues>(
  props: ControlledDatePickerProps<T>
) => {
  return <ControlledDatePickerController {...props} />;
};

export default ControlledDatePicker;
