import { Row } from '@tanstack/react-table';
import cn from 'classnames';
import { Fragment } from 'react';

import { RowItem } from '../../types';
import CustomTable from '../custom-table/custom-table';
import { DraggableRow } from '../draggable-row/draggable-row';
import TableCell from '../table-cell/table-cell';
import classes from './table-data-row.styles.module.css';

export interface TableDataRowProps {
  dataTestId?: string;
  rows: Row<RowItem>[];
  totalVisibleColumns: number;
  showPlaceholderForBlankCells: boolean;
  canReorderRows?: boolean;
  onRowReorder?: (draggedRowIndex: number, targetRowIndex: number) => void;
}

const TableDataRow = ({
  dataTestId,
  rows,
  totalVisibleColumns,
  showPlaceholderForBlankCells,
  canReorderRows,
  onRowReorder,
}: TableDataRowProps) => {
  return (
    <>
      {rows.map((row, i) => {
        return (
          <Fragment key={row.id}>
            {canReorderRows ? (
              <DraggableRow row={row} reorderRow={onRowReorder!} />
            ) : (
              <tr
                data-testid="grid-body-row"
                className={cn({
                  [classes.isClickable]: !!row.original.onClickNavigateRoute,
                })}
              >
                {row.getVisibleCells().map((cell, j) => (
                  <TableCell
                    key={cell.id}
                    cell={cell}
                    showPlaceholderIfEmpty={showPlaceholderForBlankCells}
                  />
                ))}
              </tr>
            )}

            {row.original.subGridData &&
            row.original.subGridData.isSubGridOpen ? (
              <tr data-testid={`${dataTestId}-tr-${i}-subgrid`}>
                <td
                  data-testid={`${dataTestId}-tr-${i}-td-subgrid`}
                  colSpan={totalVisibleColumns}
                  className={classes.customTableContainerTableCell}
                >
                  {row.original.subGridData && (
                    <CustomTable
                      dataTestId={dataTestId}
                      subGridData={row.original.subGridData}
                    />
                  )}
                </td>
              </tr>
            ) : null}
          </Fragment>
        );
      })}
    </>
  );
};

export default TableDataRow;
