import { CheckboxOnChangeData } from '@fluentui/react-components';
import React, { memo } from 'react';

import { CheckBox, Icon, Stack, StackItem } from '../../shared';
import {
  Item,
  ListContainerProps,
  SwappableSelectProps,
} from './swappable-select.model';
import classes from './swappable-select.module.css';

const ListContainer: React.FC<ListContainerProps> = memo(
  ({
    list,
    checkList,
    handleClick,
    onSelectAll,
    checkedAll,
    disabled,
    id,
    label = 'Select All',
  }) => {
    return (
      <Stack className={classes.container}>
        <StackItem className={classes.item_container}>
          <CheckBox
            name="selectAll"
            label={label}
            id={id}
            onChanged={onSelectAll}
            checked={checkedAll}
            disabled={disabled}
            componentStyles={{
              root: classes.checkbox,
              text: classes.bold_style,
            }}
          />
        </StackItem>
        <div className={classes.item_list_container}>
          {list.map((item: Item) => (
            <StackItem>
              <CheckBox
                key={item.id}
                name={item.name}
                label={item.name}
                id={item.id}
                disabled={disabled}
                onChanged={(
                  e,
                  isChecked = false as unknown as CheckboxOnChangeData
                ) => {
                  handleClick(item, isChecked.checked as boolean, e);
                }}
                checked={checkList.includes(item.id)}
                componentStyles={{
                  root: classes.inner_checkbox,
                  text: classes.regular_style,
                }}
              />
            </StackItem>
          ))}
        </div>
      </Stack>
    );
  }
);

const SwappableSelectView: React.FC<SwappableSelectProps> = ({
  handleLeftItemClick,
  handleRightItemClick,
  handleLeftSelectAll,
  handleRightSelectAll,
  moveItemsToLeft,
  moveItemsToRight,
  leftItemList,
  rightItemList,
  leftCheckList,
  rightCheckList,
  isLeftCheckAll,
  isRightCheckAll,
  leftLabel,
  disabled,
  rightLabel,
}): JSX.Element => {
  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <ListContainer
        id="leftSelectAll"
        label={leftLabel}
        list={leftItemList}
        handleClick={handleLeftItemClick}
        checkList={leftCheckList}
        onSelectAll={handleLeftSelectAll}
        checkedAll={isLeftCheckAll}
        disabled={disabled}
      />

      <div style={{ alignSelf: 'center' }}>
        <button
          className={classes.btn_container}
          onClick={moveItemsToRight}
          disabled={disabled}
        >
          <Icon iconName="ChevronRight16Regular" />
        </button>
        <button
          className={classes.btn_container}
          onClick={moveItemsToLeft}
          disabled={disabled}
        >
          <Icon iconName="ChevronLeft16Regular" />
        </button>
      </div>

      <ListContainer
        id="rightSelectAll"
        label={rightLabel}
        list={rightItemList}
        handleClick={handleRightItemClick}
        checkList={rightCheckList}
        onSelectAll={handleRightSelectAll}
        checkedAll={isRightCheckAll}
        disabled={disabled}
      />
    </div>
  );
};

export default SwappableSelectView;
