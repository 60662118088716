import { CustomButtonProps } from './custom-button.model';
import CustomButtonView from './custom-button.view';

const CustomButtonController = ({
  ...props
}: CustomButtonProps): JSX.Element => {
  return <CustomButtonView {...props} />;
};

export default CustomButtonController;
