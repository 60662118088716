export enum PageComponentEnum {
  ADDQUESTIONS = 'AddQuestions',
  EDITQUESTIONS = 'EditQuestions',
  VIEWQUESTIONS = 'ViewQuestions',
  PREVIEW_QUIZ = 'PreviewQuiz',
  FOLLOW_UP_VIEW = 'FollowUpView',
  RELATED_TRAINING_PLAN = 'RelatedTrainingViewPage',
  CONTROLLED_DOCUMENT_PREVIEW = 'ControlledDocumentPreview',
  COURSE_PREVIEW = 'CoursePreview',
  CURRICULUM_PREVIEW = 'CurriculumPreview',
  COURSE_ASSOCIATED_CURRICULA = 'CourseAssociatedCurricula',
}
