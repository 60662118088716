import {
  ColumnData,
  RowItem,
} from 'libs/shared/src/lib/grid-view-new/src/types';

import { ObjectMetadata } from '../../types';
import { RecordAction, ViewMetadata } from './../../types/view-metadata.type';

export type ActionColumnProps = {
  objectName: string;
  defaultActions: RecordAction[];
  dataTestId?: string;
  column?: ColumnData;
  rowItem: RowItem;
  objectMetadata: ObjectMetadata;
  viewMetadata: ViewMetadata;
};

export enum LifeCycleStageUserActionsPermissionEnum {
  EXECUTE = 'Execute',
  VIEW = 'View',
  HIDE = 'Hide',
}

export type UserActionWithPermisson = {
  name: string;
  permission: LifeCycleStageUserActionsPermissionEnum;
};

export interface IActionItem {
  label?: string;
  onActionClick: (event: React.MouseEvent<HTMLDivElement>) => void;
  iconName?: string;
  permission?: LifeCycleStageUserActionsPermissionEnum;
}
