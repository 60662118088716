import { ROUTES } from '@celito.clients/enums';
import { actionColumnListViewStyles } from '@celito.clients/list-view-engine';
import { Icon, Menu } from '@celito.clients/shared';
import {
  Button,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import { useMemo } from 'react';
import { useNavigate } from 'react-router';

import useToggleRecordActivation from '../../../hooks/use-toggle-record-activation';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type TODO = any;

export const EditButton = ({
  item,
  onActivationToggle,
}: {
  item: TODO;
  onActivationToggle?: () => void;
}) => {
  const navigate = useNavigate();
  const styles = actionColumnListViewStyles();
  const toggleRecordActivation = useToggleRecordActivation(
    {
      name: item.documentSubTypeName ?? item.name,
      label: item.documentSubType ?? item.label,
      isActive: item.isActive === 'Yes',
      noSubTypes: !item.documentSubTypeName,
    },
    () => onActivationToggle?.()
  );
  const actions = useMemo(() => {
    return [
      {
        key: 'edit',
        content: 'Edit',
        onClick: () => {
          navigate(
            (function EditRoute() {
              const currentLocation = window.location;
              const newLocation =
                currentLocation.pathname.replace(
                  'list/custom/document_type_list_view__a',
                  'edit/custom/'
                ) +
                (ROUTES.EDIT_ADMIN_DOCUMENT_TYPE.replace(
                  ':documentType',
                  item.documentTypeName
                ) +
                  (item.documentSubTypeName
                    ? `?documentSubType=${item.documentSubTypeName}`
                    : ''));
              return newLocation;
            })()
          );
        },
        disabled: false,
      },
      {
        key: 'activate',
        content: item.isActive === 'Yes' ? 'Deactivate' : 'Activate',
        onClick: toggleRecordActivation,
        disabled: false,
      },
      {
        key: 'view',
        content: 'View',
        onClick: () => {
          navigate(
            (function ViewRoute() {
              const currentLocation = window.location;
              const newLocation =
                currentLocation.pathname.replace(
                  'list/custom/document_type_list_view__a',
                  'view/custom/'
                ) +
                (ROUTES.VIEW_ADMIN_DOCUMENT_TYPE.replace(
                  ':documentType',
                  item.documentTypeName
                ) +
                  (item.documentSubTypeName
                    ? `?documentSubType=${item.documentSubTypeName}`
                    : ''));
              return newLocation;
            })()
          );
        },
        disabled: false,
      },
    ];
  }, [item]);

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          icon={<Icon iconName={'MoreHorizontal24Regular'} />}
          onClick={(e) => e.stopPropagation()}
        />
      </MenuTrigger>

      <MenuPopover>
        <MenuList>
          {actions.map((action) => {
            return (
              <MenuItem
                key={action.key}
                className={styles.actionMenuItem}
                disabled={action.disabled}
                onClick={action.onClick}
              >
                <div className={styles.itemContent}>{action.content}</div>
              </MenuItem>
            );
          })}
        </MenuList>
      </MenuPopover>
    </Menu>
  );
};
