import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { raiseErrorToast } from '@celito.clients/utils';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import {
  AssociatedCurriculum,
  CourseAssociatedCurriculaProps,
} from './course-associated-curricula.model';
import { CourseAssociatedCurriculaView } from './course-associated-curricula.view';
import { getAssociatedCurricula } from './services';

interface CourseAssociatedCurriculaControllerProps
  extends CourseAssociatedCurriculaProps {}

const ITEM_LIMIT_PER_PAGE = 10;

export const CourseAssociatedCurriculaController = (
  props: CourseAssociatedCurriculaControllerProps
): JSX.Element => {
  const params = useParams();

  const [associatedCurricula, setAssociatedCurricula] = useState<
    AssociatedCurriculum[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPageCount: 1,
    isLoading: true,
  });

  const handleRelatedTrainingPlan = async (currentPage: number) => {
    try {
      setIsLoading(true);
      setPagination((prev) => ({
        ...prev,
        isLoading: true,
      }));
      const trainingMaterialName = params.recordName;

      if (!trainingMaterialName) return;
      const associatedCurriculaData = await getAssociatedCurricula(
        trainingMaterialName,
        currentPage,
        props.recordData?.version as string
      );
      const associatedCurricula = associatedCurriculaData.data.map(
        (associatedCurriculum): AssociatedCurriculum => ({
          createdAt: associatedCurriculum.createdAtUtc,
          createdBy: associatedCurriculum.createdBy.label,
          title: associatedCurriculum.title,
          key: associatedCurriculum.name,
          label: associatedCurriculum.label,
        })
      );
      setAssociatedCurricula(associatedCurricula);
      setPagination((prev) => ({
        ...prev,
        totalPageCount: associatedCurriculaData.total / ITEM_LIMIT_PER_PAGE,
      }));
    } catch (_error) {
      raiseErrorToast(_error);
    } finally {
      setIsLoading(false);
      setPagination((prev) => ({
        ...prev,
        isLoading: false,
      }));
    }
  };

  const handlePageUpdate = (page: number) => {
    setPagination((prev) => ({ ...prev, currentPage: page }));
    handleRelatedTrainingPlan(page);
  };

  useEffect(() => {
    handleRelatedTrainingPlan(1);
  }, [params]);

  const handleGoToNextStep = (e: React.MouseEvent<HTMLButtonElement>) => {
    props?.onSave?.(false, undefined, undefined, true);
    props.goToNextStep(e);
  };

  useEffect(() => {
    if (props.mode === FormEngineModeTypeEnum.View) {
      return;
    }

    props.setWizardStepsState((prev) =>
      prev.map((prevStep) =>
        prevStep.number === props.step
          ? { ...prevStep, isValid: true }
          : prevStep
      )
    );
  }, []);

  return (
    <CourseAssociatedCurriculaView
      {...props}
      associatedCurricula={associatedCurricula}
      isCurriculaLoading={isLoading}
      paginationProps={{
        handlePageUpdate: handlePageUpdate,
        currentPage: pagination.currentPage,
        totalPageCount: pagination.totalPageCount,
        isLoading: pagination.isLoading,
      }}
      goToNextStep={handleGoToNextStep}
    />
  );
};
