import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

export type GetSearchParamsFunc = () => {
  [x: string]: string;
};

const useQueryParams = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const getSearchParams: GetSearchParamsFunc = useCallback(() => {
    const paramsObj: { [key: string]: string } = {};
    for (const [obj, key] of searchParams) {
      paramsObj[obj] = key;
    }
    return { ...paramsObj };
  }, [searchParams]);

  const clearUrlParams = useCallback(
    (param: string) => {
      setSearchParams((prev) => {
        if (prev.has(param)) {
          prev.delete(param);
        }

        return prev;
      });
    },
    [setSearchParams]
  );

  const clearMultipleUrlParams = useCallback(
    (params: string[]) => {
      setSearchParams((prev) => {
        params.forEach((param) => {
          prev.delete(param);
        });
        return prev;
      });
    },
    [setSearchParams]
  );

  const setUrlParams = useCallback(
    (param: string, value: string) => {
      setSearchParams((prev) => {
        prev.set(param, value);
        return prev;
      });
    },
    [setSearchParams]
  );

  const addMultipleUrlParams = useCallback(
    (params: { param: string; value: string }[]) => {
      setSearchParams((prev) => {
        params.forEach(({ param, value }) => {
          prev.set(param, value);
        });
        return prev;
      });
    },
    [setSearchParams]
  );

  const addAndRemoveParams = useCallback(
    (
      urlParamsToAdd: { param: string; value: string }[],
      urlParamsToRemove: string[]
    ) => {
      setSearchParams((prev) => {
        urlParamsToRemove.forEach((param) => {
          prev.delete(param);
        });

        urlParamsToAdd.forEach(({ param, value }) => {
          prev.set(param, value);
        });

        return prev;
      });
    },
    [setSearchParams]
  );

  return {
    searchParams,
    getSearchParams,
    clearUrlParams,
    setUrlParams,
    clearMultipleUrlParams,
    addMultipleUrlParams,
    addAndRemoveParams,
  };
};

export default useQueryParams;
