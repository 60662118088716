import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const editGroupStyles = makeStyles({
  field: {
    fontSize: '1rem',
  },

  textField: {
    ...shorthands.flex(1),
    backgroundColor: Color.white,
    ...shorthands.borderRadius('5px'),
    height: '40px',
    width: '100%',
  },

  textFieldError: {
    ...shorthands.border('1px', 'solid', Color.error_color),

    '&:after': {
      ...shorthands.border('1px', 'solid', Color.error_color),
    },
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
});
