import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { CoursePreviewPageProps } from './course-preview-page.model';
import { CoursePreviewPageView } from './course-preview-page.view';

interface CoursePreviewPageControllerProps extends CoursePreviewPageProps {}

export const CoursePreviewPageController = (
  props: CoursePreviewPageControllerProps
): JSX.Element => {
  const { watch } = useFormContext() || {};

  let recordDetails = props.recordDetails;

  if (props.mode !== FormEngineModeTypeEnum.View) {
    const formValues = watch?.();

    recordDetails = {};
    props.attributeConfig.objectAttributeDefinitions?.forEach((item) => {
      recordDetails![item.columnName] = formValues?.[item.name];
    });
  }

  useEffect(() => {
    if (props.mode === FormEngineModeTypeEnum.View) {
      return;
    }

    props.setWizardStepsState((prev) =>
      prev.map((prevStep) =>
        prevStep.number === props.step
          ? { ...prevStep, isValid: true }
          : prevStep
      )
    );
  }, []);

  return (
    <CoursePreviewPageView
      {...props}
      onSave={props.onSave}
      recordDetails={recordDetails}
    />
  );
};
