import { BellIcon } from '@celito.clients/assets';
import { ButtonTypes, DateFormat } from '@celito.clients/enums';
import { CustomButton, Icon, Loader } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { formatDate } from '@celito.clients/utils';
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  mergeClasses,
  Tooltip,
} from '@fluentui/react-components';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { convert } from 'html-to-text';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { Link, useNavigate } from 'react-router-dom';

import { Notification } from '../../in-app-notifications.model';
import styles from './in-app-notifications.module.css';
interface InAppNotificationsListViewProps {
  notifications: Notification[] | [];
  handleMoreClick: (
    notification: Notification,
    event: React.MouseEvent<HTMLElement>
  ) => void;
  hasMoreNotifications: boolean;
  loadMoreNotifications: () => void;
  handleRemove: (id: number) => void;
  readNotification: (id: number) => void;
  selectedNotification: Notification | null;
  onDismissInAppNotifPanel: () => void;
  isNotificationsLoading: boolean;
  isRemoveAllNotifsLoading: boolean;
  refreshNotifications: () => Promise<void>;
  scrollableContainerRef: React.RefObject<HTMLDivElement>;
}

const LoaderDiv = () => {
  return (
    <div
      className={mergeClasses(styles.inAppListItem, styles.inAppListItemLoader)}
    >
      <Loader />
    </div>
  );
};

const InAppNotificationsListView: React.FC<InAppNotificationsListViewProps> = ({
  notifications,
  handleMoreClick,
  readNotification,
  handleRemove,
  selectedNotification,
  loadMoreNotifications,
  hasMoreNotifications,
  onDismissInAppNotifPanel,
  isNotificationsLoading,
  isRemoveAllNotifsLoading,
  refreshNotifications,
  scrollableContainerRef,
}) => {
  const { cssVariables } = useTheme();
  const navigate = useNavigate();

  const handleOnClickNavigate = (navigateTo: string) => {
    if (navigateTo) {
      if (navigateTo.includes('blob')) {
        const blobUrl = navigateTo.split('?url=')[1];
        window.open(blobUrl, '_blank');
      } else {
        const url = new URL(navigateTo);
        let updatedUrl = url.pathname;
        if (url?.search) {
          updatedUrl = url.pathname + url.search;
        }
        navigate(updatedUrl);
      }
      onDismissInAppNotifPanel();
    }
  };

  return notifications.length === 0 && !isNotificationsLoading ? (
    <div
      data-testid="inapp-notification-panel-no-notifications"
      className={styles.no_notif_container}
    >
      <div className={styles.bell_icon_container}>
        <img src={BellIcon} alt="Bell Icon" />
      </div>
      <div className={styles.no_notif_text_container}>
        <div className={styles.no_notif_title}>{LocalStrings.noNotifTitle}</div>
        <div className={styles.no_notif_subtitle}>
          {LocalStrings.noNotifSubtitle}
        </div>
      </div>
      <CustomButton
        buttonType={ButtonTypes.Primary}
        buttonTitle={'Refresh'}
        onButtonClicked={refreshNotifications}
        isDisabled={isRemoveAllNotifsLoading}
        dataTestId="inapp-notification-panel-refresh-button"
      >
        Refresh
      </CustomButton>
    </div>
  ) : (
    <div style={{ ...cssVariables }} className={styles.notificationContainer}>
      <InfiniteScroll
        pageStart={0}
        loadMore={loadMoreNotifications}
        hasMore={hasMoreNotifications}
        loader={<LoaderDiv />}
        useWindow={false}
        getScrollParent={() => scrollableContainerRef.current}
      >
        {notifications.map((notification, index) => (
          <div
            data-testid="inapp-notification-item"
            className={
              notifications?.length - 1 === index
                ? styles.inAppListItemLast
                : styles.inAppListItem
            }
            key={notification.id}
            onClick={() => {
              handleOnClickNavigate(notification?.urlPath);
              readNotification(notification?.id);
            }}
          >
            <div className={styles.inAppListDetailsRowWrapper}>
              <div className={styles.inAppNotifListRow}>
                <span className={styles.alignCenter}>
                  {notification.viewedOnUtc === null ? (
                    <div
                      data-testid="inapp-notification-item-unread-indicator"
                      className={styles.inAppTextUnreadDot}
                    ></div>
                  ) : (
                    <div className={styles.inAppTextReadBlankSpace}></div>
                  )}
                  <span className={styles.inAppTextUnread}>
                    {notification.urlPath ? (
                      <Link to={notification.urlPath}></Link>
                    ) : (
                      ''
                    )}
                  </span>
                  <span className={styles.inAppTextRead}>
                    {convert(notification.inappText)}
                  </span>
                </span>
                <span className={styles.alignCenter}>
                  <div className={styles.inAppTextReadBlankSpace}></div>
                  <span className={styles.inAppNotificationTime}>
                    {formatDate(notification.createdAtUtc, DateFormat.DateTime)}
                  </span>
                </span>
              </div>
            </div>
            <div
              data-testid="inapp-notification-item-more-button"
              className={styles.moreMenuBtn}
              onClick={(event) => handleMoreClick(notification, event)}
            >
              <Menu>
                <MenuTrigger disableButtonEnhancement>
                  <Tooltip content="View More" relationship="label">
                    <MenuButton
                      appearance="transparent"
                      icon={<Icon iconName="MoreHorizontal16Regular" />}
                      className={styles.menuButton}
                    />
                  </Tooltip>
                </MenuTrigger>
                <MenuPopover>
                  <MenuList>
                    {selectedNotification &&
                      selectedNotification.viewedOnUtc === null && (
                        <MenuItem data-testid="inapp-notification-menu-item-mark-as-read">
                          <div
                            onClick={() =>
                              readNotification(selectedNotification.id)
                            }
                            style={{
                              display: 'flex',
                              gap: '2px',
                              justifyContent: 'start',
                              alignItems: 'center',
                              color: '#000',
                            }}
                          >
                            <Icon iconName="Checkmark24Regular" />
                            Mark as Read
                          </div>
                        </MenuItem>
                      )}
                    {selectedNotification && (
                      <MenuItem data-testid="inapp-notification-menu-item-remove">
                        <div
                          onClick={() => handleRemove(selectedNotification.id)}
                          style={{
                            display: 'flex',
                            gap: '2px',
                            justifyContent: 'start',
                            alignItems: 'center',
                            color: 'red',
                          }}
                        >
                          <Icon iconName="Dismiss24Regular" />
                          Remove this Notification
                        </div>
                      </MenuItem>
                    )}
                  </MenuList>
                </MenuPopover>
              </Menu>
            </div>
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default InAppNotificationsListView;
