import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import { ConfirmDialog, GridView } from '@celito.clients/shared';

import { UserViewPanelWrapper } from '../../reference-selector/src/components/user-view-panel.component';
import { ReferenceSelectorPreviewViewProps } from './reference-selector-preview.model';
import classes from './reference-selector-preview.module.css';

const ReferenceSelectorPreviewView = (
  props: ReferenceSelectorPreviewViewProps
): JSX.Element => {
  const {
    userName,
    onDismiss,
    isUserViewPanelOpen,
    columnData,
    isLoading,
    isConfirmDialogOpen,
    handleCancelClick,
    handleConfirmClick,
  } = props;
  return (
    <div className={classes.member_selector_container}>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={LocalizationString.REMOVE}
        description={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_REMOVE}
        iconSrc={DeleteIcon}
        onCancelClicked={handleCancelClick}
        onConfirmClicked={handleConfirmClick}
        primaryButtonText={LocalizationString.REMOVE}
        secondaryButtonText={LocalizationString.CANCEL}
      />
      <GridView
        columns={columnData}
        items={props.items}
        disableSelection
        className={classes.grid_view_container}
        enableShimmer={isLoading}
        disableDefaultSorting
      />
      {isUserViewPanelOpen && userName && (
        <UserViewPanelWrapper
          isOpen={isUserViewPanelOpen}
          label={LocalizationString.INDIVIDUAL_USERS}
          name={userName}
          onDismiss={onDismiss}
        />
      )}
    </div>
  );
};

export default ReferenceSelectorPreviewView;
