import { Relationship } from '@celito.clients/types';
import { useEffect, useState } from 'react';

import { useGetObjectData } from '../../hooks/get-object-data';
import { FieldSelectorProps } from './field-selector.model';
import { FieldSelectorView } from './field-selector.view';
import { copyToClipboard, generateMergeFieldName } from './utils';

export const FieldSelectorController = (props: FieldSelectorProps) => {
  const { objectName } = props;

  const [customSearch, setCustomSearch] = useState('');

  const {
    objectData: { data: object, isLoading },
    setObjectName,
  } = useGetObjectData(objectName);

  useEffect(() => {
    if (objectName) setObjectName(objectName);
  }, [objectName, setObjectName]);

  const onCustomSearch = (search: string) => {
    setCustomSearch(search);
  };

  const onFieldClick = (
    fieldName: string,
    relationship?: Relationship,
    appendInitalObject?: boolean,
    objectName?: string,
    firstLevel?: string
  ) => {
    setCustomSearch('');

    const toCopy = generateMergeFieldName(
      fieldName,
      relationship,
      appendInitalObject,
      objectName,
      firstLevel
    );

    copyToClipboard(toCopy);
    props.onCopy?.(toCopy, 1);
  };

  return (
    <FieldSelectorView
      {...{
        ...props,
        fields: object,
        isLoading,
        onFieldClick,
        customSearch,
        onCustomSearch,
      }}
    />
  );
};
