import cn from 'classnames';

import { useFormEngineContext } from '../../../hooks';
import classes from './form-container.module.css';

const FormContainer: React.FC<
  React.PropsWithChildren<{ className?: string }>
> = ({ children, className = '' }) => {
  const { isWizardStateVisible, isCompleterBarVisible } =
    useFormEngineContext();

  return (
    <div
      className={cn(classes.formContainer, className, {
        [classes.wizardStateVisible]: isWizardStateVisible,
        [classes.completerBarVisible]: isCompleterBarVisible,
        [classes.wizardAndCompleterVisible]:
          isWizardStateVisible && isCompleterBarVisible,
      })}
    >
      {children}
    </div>
  );
};

export default FormContainer;
