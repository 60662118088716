import { ControlledDocumentRequestTypeEnum } from '@celito.clients/types';

export interface ObjectInitiationProps {
  dataTestId?: string;
}

export enum ObjectInitiationViewNameEnum {
  CourseInitiation = 'course_list_view_name__a',
  ControlledDocumentInitiation = 'controlled_document_view__a',
  CurriculumInitiation = 'curriculum_list_view_name__a',
}

// Course Initiation enums
export enum CourseInitiationTypeEnum {
  NewCourse = 'New Course',
  UpdateCourse = 'Update Course',
  RetireCourse = 'Retire Course',
}

export const CourseInitiationType = ['New Course', 'Update Course'];

// Controlled Document Initiation enums
export const ControlledDocumentInitiationType = [
  { value: 'New Document', label: 'New Document' },
  { value: 'New Pre Approved Document', label: 'New Pre Approved Document' },
  { value: 'Update Document', label: 'Update Document' },
];

export type InitiationType =
  | CourseInitiationTypeEnum
  | ControlledDocumentRequestTypeEnum
  | CurriculumInitiationTypeEnum;

// Curriculum Initiation enums
export enum CurriculumInitiationTypeEnum {
  NewCurriculum = 'New Curriculum',
  UpdateCurriculum = 'Update Curriculum',
  RetireCurriculum = 'Retire Curriculum',
}

export const CurriculumInitiationType = ['New Curriculum', 'Update Curriculum'];
