import { performFormRequest, performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { ObjectAttributeType } from '../types/object-attribute-types';
import { WizardSectionType } from '../types/wizard-config';

export const getWizardConfig = async (viewName: string) => {
  const response = await performRequest<WizardSectionType>(
    `${Endpoints.WIZARD_CONFIG_URL}/${viewName}`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};

export const getAttributeConfig = async (
  objectName: string,
  recordName?: string,
  version?: string
) => {
  const response = await performRequest<ObjectAttributeType>(
    recordName
      ? `${Endpoints.ATTRIBUTE_CONFIG_URL}/${objectName}?record_name=${recordName}&record_version=${version}`
      : `${Endpoints.ATTRIBUTE_CONFIG_URL}/${objectName}`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};

export const formSubmitApi = async (
  objectName = '',
  payload: object,
  params: object = {}
) => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/save`,
    ApiMethodType.POST,
    payload,
    params
  );
  return response.data as Record<'record', Record<string, unknown>>;
};

export const editFormSubmitApi = async (
  objectName = '',
  recordName = '',
  payload: object,
  params: object = {}
) => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
    ApiMethodType.PUT,
    payload,
    params
  );
  return response.data as Record<'record', Record<string, unknown>>;
};

export const getRecordDetailApi = async (
  objectName = '',
  recordId: string,
  params: Record<string, unknown> = {}
) => {
  const response = await performRequest<Record<string, unknown>>(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordId}`,
    ApiMethodType.GET,
    null,
    params
  );
  return response.data;
};
