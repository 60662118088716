import { MultipleChoice } from '../types';

export enum QuestionTypes {
  MULTIPLE_CHOICE = 'MultipleChoice',
  MULTIPLE_RESPONSE = 'MultipleResponse',
  BOOLEAN = 'TrueOrFalse',
  SEQUENCE = 'Sequence',
}

// export const checkedSelectedValue: string[] = [];
export const checkedSelectedValue: MultipleChoice = {
  multipleChoice: [],
};
export const apiChoicesList: MultipleChoice = {
  multipleChoice: [],
};
export const radioSelectedValue: MultipleChoice = {
  multipleChoice: [],
};

export const choiceSelectedValue: MultipleChoice = {
  multipleChoice: [],
};

export const feedbackObj: any = {
  obj: {},
};
