import { LocalizationString } from '@celito.clients/assets';
import { FormEngineModeTypeEnum, OperatorsEnum } from '@celito.clients/enums';
import { useQueryParams } from '@celito.clients/hooks';
import {
  claimTask,
  getAllTaskData,
  getRecordDetailApi,
} from '@celito.clients/services';
import { raiseErrorToast, successToast } from '@celito.clients/utils';
import { head } from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { CompleteTaskViewProps } from './complete-view.model';
import { CompleteTaskViewBar } from './complete-view.view';

export const CompleteTaskViewController: React.FC<CompleteTaskViewProps> = ({
  recordData,
  objectName,
  setRecordData,
  task,
  setTask,
}) => {
  const { getSearchParams } = useQueryParams();
  const searchParams = getSearchParams();

  const [showDescription, setShowDescription] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isShowCompleteViewBar, setIsShowCompleteViewBar] = useState(false);

  let { taskName = '' } = searchParams;
  taskName = task?.name ?? taskName ?? '';

  const queryClient = useQueryClient();

  const toggleDescription = () => {
    setShowDescription(!showDescription);
  };

  const getTaskInformation = async () => {
    try {
      const response = await getAllTaskData(
        (() => {
          const filteredData = {
            filters: [
              {
                conditions: {
                  all: [
                    {
                      any: [
                        {
                          attribute: 'objectRecordId',
                          operator: OperatorsEnum.EQUALS,
                          value: {
                            objectName: objectName,
                            recordName: recordData?.name,
                            version: recordData?.version,
                          },
                        },
                      ],
                    },
                    {
                      any: [
                        {
                          attribute: 'object_id',
                          operator: OperatorsEnum.EQUALS,
                          value: objectName,
                        },
                      ],
                    },
                    {
                      any: [
                        {
                          attribute: 'status',
                          operator: OperatorsEnum.EQUALS,
                          value: 'Todo',
                        },
                      ],
                    },
                    {
                      any: [
                        {
                          attribute: 'owner__s',
                          operator: OperatorsEnum.EQUALS,
                          value: '@me',
                        },
                        {
                          attribute: 'delegates__s',
                          operator: OperatorsEnum.EQUALS,
                          value: '@me',
                        },
                        { attribute: 'owner__s', operator: 'isBlank' },
                      ],
                    },
                  ],
                },
              },
            ],
            sort: {
              attribute: 'modified_at_utc__s',
              order: 'DESC',
            },
          };
          if (taskName) {
            const taskNameFilter = {
              any: [
                {
                  attribute: 'name',
                  operator: OperatorsEnum.EQUALS,
                  value: taskName,
                },
              ],
            };
            filteredData?.filters[0]?.conditions?.all?.push(taskNameFilter);
          }
          return filteredData;
        })()
      );
      const task = head(response.data);

      if (!task) {
        setIsShowCompleteViewBar(false);
        return;
      }

      setTask?.(task);
      setIsShowCompleteViewBar(true);
    } catch (err) {
      setIsShowCompleteViewBar(false);
    }
  };

  useEffect(() => {
    if (
      recordData?.objectDefinitionName &&
      (recordData?.name || recordData?.version)
    )
      getTaskInformation();
  }, [recordData]);

  const handleClaimTask = () => {
    setLoading(true);
    claimTask(taskName)
      .then((res) => {
        if (res) {
          setTask?.(res?.task);
          successToast({
            message: LocalizationString.CLAIM_TASK,
          });
          setLoading(false);
          queryClient.refetchQueries({
            queryKey: [
              recordData?.objectDefinitionName,
              recordData?.name,
              'tasks',
            ],
          });
          if (setRecordData && recordData) {
            callRecordDetailApi(
              objectName,
              recordData?.name as string,
              (recordData?.version as string) ?? null
            );
          }
        }
      })
      .catch((_error) => {
        raiseErrorToast(_error);
        setLoading(false);
      });
  };

  const callRecordDetailApi = async (
    objectName: string,
    recordName: string,
    recordVersion: string | null
  ) => {
    const response = await getRecordDetailApi(
      objectName,
      recordName,
      recordVersion
        ? {
            version: recordVersion,
            view_type: FormEngineModeTypeEnum.View,
          }
        : { view_type: FormEngineModeTypeEnum.View }
    );
    setRecordData?.(response);
  };

  return (
    <CompleteTaskViewBar
      {...{
        showDescription,
        toggleDescription,
        title: task?.label ?? '',
        description: task?.description ?? '',
        recordData,
        taskName,
        isShowCompleteViewBar,
        task,
        handleClaimTask,
        loading,
        objectName,
        owner: task?.owner,
        delegates: task?.delegates,
      }}
    />
  );
};
