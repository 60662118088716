import { createTestAttribute } from '@celito.clients/utils';
import { components, GroupBase, SingleValueProps } from 'react-select';

export const SingleValueComponent = <T,>(
  singleValProps: SingleValueProps<T, false, GroupBase<T>> & { label: string }
) => {
  return (
    <components.SingleValue {...singleValProps}>
      <span
        data-testid={`single-value-${createTestAttribute(
          singleValProps.label
        )}`}
      >
        {singleValProps.children}
      </span>
    </components.SingleValue>
  );
};
