export enum CourseObjectAttributeNameEnum {
  TITLE = 'title__a',
  IS_GXP = 'is_gxp__a',
  DEPARTMENT = 'department__s',
  DESCRIPTION = 'description__a',
  INSTRUCTIONS = 'instructions__a',
  OWNER = 'owner__a',
  TRAINING_TYPE = 'training_type__a',
  RETRAINING_TIME_PERIOD_IN_MONTHS = 'retraining_frequency__a',
  IS_APPROVAL_REQUIRED = 'is_approval_required__a',
  IS_RETRAINING_REQUIRED = 'is_retraining_required__a',
  CONTENT_APPROVERS = 'content_approvers__a',
  INITIAL_TRAINING_COMPLETION_DUE_DATE = 'initial_training_completion_due_date__a',
  STATUS = 'status__a',
  INITIATION_TYPE = 'initiation_type__a',
  QUIZ = 'quiz__a',
  TRAINER = 'trainer__a',
  SEQUENCE = 'sequence__a',
  TRAINEES = 'trainees__a',
  SMART_GROUPS = 'smart_groups__a',
  VERSION = 'version__s',
  TRAINING_COMPLETION_PERIOD_IN_DAYS = 'training_completion_period_in_days__a',
  COMPLETION_TYPE = 'completion_type__a',
  DOCUMENT = 'file__a',
  COURSE_TYPE = 'course_type__a',
  IS_OBSOLETE_APPROVED = 'is_obsolete_approved__a',
  PROPOSED_OBSOLETION_DATE = 'proposed_obsoletion_date__a',
  OBSOLETE_JUSTIFICATION = 'obsolete_justification__a',
  OBSOLETION_DATE = 'obsoletion_date__a',
  APPROVAL_DUE_DATE = 'approval_due_date__a',
  CONTROLLED_DOCUMENT = 'controlled_document__a',
  CONTROLLED_DOCUMENT_LABEL = 'controlled_document_label__a',
  APPROVED_DATE = 'approved_date__a',
  SUPERSEDED_DATE = 'superseded_date__a',
}

export enum CourseObjectKeyEnum {
  TITLE = 'title',
  IS_GXP = 'isGxp',
  DEPARTMENT = 'department',
  DESCRIPTION = 'description',
  INSTRUCTIONS = 'instructions',
  OWNER = 'owner',
  TRAINING_TYPE = 'trainingType',
  RETRAINING_TIME_PERIOD_IN_MONTHS = 'retrainingTimePeriodInMonths',
  FOLLOW_UP_DUE_DATE = 'followUpDueDate',
  IS_APPROVAL_REQUIRED = 'isApprovalRequired',
  IS_RETRAINING_REQUIRED = 'isRetrainingRequired',
  CONTENT_APPROVERS = 'contentApprovers',
  INITIAL_TRAINING_COMPLETION_DUE_DATE = 'initialTrainingCompletionDueDate',
  STATUS = 'status',
  INITIATION_TYPE = 'initiationType',
  QUIZ = 'quiz',
  TRAINER = 'trainer',
  SEQUENCE = 'sequence',
  TRAINEES = 'trainees',
  SMART_GROUPS = 'smartGroups',
  COURSES = 'courses',
  VERSION = 'version',
  TRAINING_COMPLETION_PERIOD_IN_DAYS = 'trainingCompletionPeriodInDays',
  COMPLETION_TYPE = 'completionType',
  DOCUMENT = 'document',
  COURSE_TYPE = 'courseType',
  IS_OBSOLETE_APPROVED = 'isObsoleteApproved',
  PROPOSED_OBSOLETION_DATE = 'proposedObsoletionDate',
  OBSOLETION_DATE = 'obsoletionDate',
  OBSOLETE_JUSTIFICATION = 'obsoletionJustification',
  APPROVAL_DUE_DATE = 'approvalDueDate',
  CONTROLLED_DOCUMENT = 'controlledDocument',
  CONTROLLED_DOCUMENT_LABEL = 'controlledDocumentLabel',
  APPROVED_DATE = 'approvedDate',
  SUPERSEDED_DATE = 'supersededDate',
}

export enum CourseObjectColumnNameEnum {
  TITLE = 'title',
  IS_GXP = 'isGxp',
  DEPARTMENT = 'department',
  DESCRIPTION = 'description',
  INSTRUCTIONS = 'instructions',
  OWNER = 'owner',
  TRAINING_TYPE = 'trainingType',
  RETRAINING_TIME_PERIOD_IN_MONTHS = 'retrainingTimePeriodInMonths',
  FOLLOW_UP_DUE_DATE = 'followUpDueDate',
  IS_APPROVAL_REQUIRED = 'isApprovalRequired',
  IS_RETRAINING_REQUIRED = 'isRetrainingRequired',
  CONTENT_APPROVERS = 'contentApprovers',
  INITIAL_TRAINING_COMPLETION_DUE_DATE = 'initialTrainingCompletionDueDate',
  STATUS = 'status',
  INITIATION_TYPE = 'initiationType',
  QUIZ = 'quiz',
  TRAINER = 'trainer',
  SEQUENCE = 'sequence',
  TRAINEES = 'trainees',
  SMART_GROUPS = 'smartGroups',
  VERSION = 'version',
  TRAINING_COMPLETION_PERIOD_IN_DAYS = 'trainingCompletionPeriodInDays',
  COMPLETION_TYPE = 'completionType',
  DOCUMENT = 'document',
  COURSE_TYPE = 'courseType',
  IS_OBSOLETE_APPROVED = 'isObsoleteApproved',
  PROPOSED_OBSOLETION_DATE = 'proposedObsoletionDate',
  OBSOLETION_DATE = 'obsoletionDate',
  OBSOLETE_JUSTIFICATION = 'obsoletionJustification',
  APPROVAL_DUE_DATE = 'approvalDueDate',
  CONTROLLED_DOCUMENT = 'controlledDocument',
  CONTROLLED_DOCUMENT_LABEL = 'controlledDocumentLabel',
  APPROVED_DATE = 'approvedDate',
  SUPERSEDED_DATE = 'supersededDate',
}
