import {
  ICompressedFiltersToBeApplied,
  ICompressedReferenceFilter,
  ICompressedSimplifedFilter,
  IFiltersToBeApplied,
  IReferenceFilter,
  ISimplifedFilter,
} from './rules-component.types';

const compressFilterRules = (
  data: IFiltersToBeApplied
): ICompressedFiltersToBeApplied => {
  // IFiltersToBeApplied has the following structure :
  // object -> array -> object -> array -> object (ISimplifedFilter)

  return Object.fromEntries(
    Object.entries(data).map(([level1Key, level1Val], i) => {
      return [
        level1Key,
        level1Val.map((level2Val, i) => {
          return Object.fromEntries(
            Object.entries(level2Val).map(([level3Key, level3Val], i) => {
              const compressedLevel3Val: ICompressedSimplifedFilter[] =
                level3Val.map(
                  ({ attribute, operator, value, referenceFilters }) => {
                    if (referenceFilters)
                      return {
                        a: attribute,
                        o: operator,
                        r: compressReferenceFilters(referenceFilters),
                      };
                    return {
                      a: attribute,
                      o: operator,
                      v: value,
                    };
                  }
                );

              return [level3Key, compressedLevel3Val];
            })
          );
        }),
      ];
    })
  );
};

const decompressFilterRules = (
  data: ICompressedFiltersToBeApplied
): IFiltersToBeApplied => {
  return Object.fromEntries(
    Object.entries(data).map(([level1Key, level1Val], i) => {
      return [
        level1Key,
        level1Val.map((level2Val, i) => {
          return Object.fromEntries(
            Object.entries(level2Val).map(([level3Key, level3Val], i) => {
              const uncompressedLevel3Val: ISimplifedFilter[] = level3Val.map(
                ({ a, o, v, r }) => {
                  if (r) {
                    return {
                      attribute: a,
                      operator: o,
                      referenceFilters: decompressReferenceFilters(r),
                    };
                  }
                  return {
                    attribute: a,
                    operator: o,
                    value: v,
                  };
                }
              );

              return [level3Key, uncompressedLevel3Val];
            })
          );
        }),
      ];
    })
  );
};

export const convertFilterRulesToQueryString = (
  conditions: IFiltersToBeApplied
): string => {
  return encodeURI(JSON.stringify(compressFilterRules(conditions)));
};

export const convertQueryStringToFilterRules = (
  conditions: string | undefined
): IFiltersToBeApplied | undefined => {
  if (!conditions) return undefined;

  return decompressFilterRules(JSON.parse(decodeURI(conditions)));
};

const compressReferenceFilters = (
  data: IReferenceFilter[]
): ICompressedReferenceFilter[] => {
  // ISimplifedFilter['referenceFilters'] has the following structure :
  // array -> object -> object -> array -> object (IFiltersToBeApplied)

  return data.map((condtion) => {
    return Object.fromEntries(
      Object.entries(condtion).map(([level1Key, level1Val], i) => {
        return [
          level1Key,
          Object.fromEntries(
            Object.entries(level1Val).map(([level2Key, level2Val], i) => {
              return [
                level2Key,
                level2Val.map((level3Val) => compressFilterRules(level3Val)),
              ];
            })
          ),
        ];
      })
    );
  });
};

const decompressReferenceFilters = (
  data: ICompressedReferenceFilter[]
): IReferenceFilter[] => {
  return data.map((condtion) => {
    return Object.fromEntries(
      Object.entries(condtion).map(([level1Key, level1Val], i) => {
        return [
          level1Key,
          Object.fromEntries(
            Object.entries(level1Val).map(([level2Key, level2Val], i) => {
              return [
                level2Key,
                level2Val.map((level3Val) => decompressFilterRules(level3Val)),
              ];
            })
          ),
        ];
      })
    );
  });
};
