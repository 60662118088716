import {
  ApiMethodType,
  ObjectEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import { performFormRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { ObjectDefinitionType } from '../../settings/notifications/notification-form/notification-form.model';

const objectName = ObjectEnum.DOCUMENT_TEMPLATE;

export const getDocumentTemplate = async (recordName = '') => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
    ApiMethodType.GET,
    null
  );
  return response.data;
};

export const createDocumentTemplate = async (payload: object) => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/save`,
    ApiMethodType.POST,
    payload
  );
  return response.data;
};

export const updateDocumentTemplate = async (
  recordName: string,
  payload: object,
  params: object = {}
) => {
  const response = await performFormRequest(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}/${recordName}`,
    ApiMethodType.PUT,
    payload,
    params
  );
  return response.data as Record<'record', Record<string, unknown>>;
};

export const getObjects = async () => {
  const response = await performFormRequest(
    `${Endpoints.OBJECTS_URL}`,
    ApiMethodType.POST,
    {
      filters: [
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    attribute: 'name__s',
                    operator: OperatorsEnum.IN,
                    value: [
                      'document_sub_type__a',
                      'controlled_document__a',
                      'gxp_type__a',
                      'document_type__a',
                      'department__s',
                      'document_data__a',
                      'document_template__a',
                      'document_type_template_mapping__a',
                      'user__s',
                    ],
                  },
                ],
              },
            ],
          },
        },
      ],
    },
    { page: 1, limit: 1000 }
  );
  return response.data as Record<'data', ObjectDefinitionType[]>;
};
