import { FieldValues } from 'react-hook-form';

import { ControlledDatetimePickerProps } from './controlled-datetime-picker.model';
import ControlledDatetimePickerView from './controlled-datetime-picker.view';

interface ControlledDatetimePickerControllerProps<T extends FieldValues>
  extends ControlledDatetimePickerProps<T> {}

const ControlledDatetimePickerController = <T extends FieldValues>(
  props: ControlledDatetimePickerControllerProps<T>
): JSX.Element => {
  return <ControlledDatetimePickerView {...props} />;
};

export default ControlledDatetimePickerController;
