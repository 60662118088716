import {
  DocumentSubTypeObjectKeyEnum,
  DocumentTypeObjectKeyEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import {
  useCallbackPrompt,
  useLayout,
  useObjectAttributeDefinitions,
  useObjectData,
  useQueryParams,
} from '@celito.clients/hooks';
import { ObjectAttributeDefinition } from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router';
import * as yup from 'yup';

import { FormMode } from '../../../document-templates/types';
import { DocumentSubTypeService } from '../../../services/document-sub-type';
import { DocumentTypeService } from '../../../services/document-type';
import { TDocumentTemplatesResponse } from '../../../services/types';
import { formSchema } from '../../schema';
import { getStyles } from '../../styles';
import { MyObjectAttributeDefinition, TComponentProps } from '../../types';

const fields = Object.entries(formSchema.fields).map(([key, schema]) => {
  return {
    name: key as keyof typeof formSchema.fields,
    attribute: (schema.describe() as yup.SchemaDescription)
      .meta as ObjectAttributeDefinition,
  };
}) satisfies {
  name: string;
  attribute?: MyObjectAttributeDefinition;
}[];

export type TFieldValues = yup.InferType<typeof formSchema>;

export const useController = ({ mode = FormMode.CREATE }: TComponentProps) => {
  const { documentType } = useParams();
  const { getSearchParams } = useQueryParams();

  const { configureLayout } = useLayout();

  const { data: docTypeAttributes, isLoading: docTypeAttributesLoading } =
    useObjectAttributeDefinitions<DocumentTypeObjectKeyEnum>({
      objectName: ObjectEnum.DOCUMENT_TYPE,
    });

  const { data: docSubtypeAttributes, isLoading: docSubTypeAttributesLoading } =
    useObjectAttributeDefinitions<DocumentSubTypeObjectKeyEnum>({
      objectName: ObjectEnum.DOCUMENT_SUB_TYPE,
    });

  const form = useForm<TFieldValues>({
    resolver: yupResolver(formSchema),
    defaultValues: {
      documentType: '',
      documentSubType: '',
      subTypesDisabled: false,
      templateName: '',
      isRawFileDownloadAllowed: false,
      isAdminReviewRequired: false,
      effectivenessPeriodInDays: 0,
      formMode: mode,
      isActive: false,
      isTrainable: false,
      isFollowUpRequired: false,
      newDocumentType: false,
    },
  });

  useEffect(() => {
    configureLayout({
      pageTitle: '',
      enablePadding: false,
      headerTitle: 'Document Type',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateFormWithSubType = useCallback(
    async (documentSubTypeName: string) => {
      const {
        data: { data },
      } = await DocumentSubTypeService.getDocumentSubType(documentSubTypeName);
      const {
        isFollowUpRequired,
        followUpTimePeriodInMonths,
        followUpTriggerPeriodInDays,
        effectivenessPeriodInDays,
        isTrainable,
        isAdminReviewRequired,
        code,
        isRawFileDownloadAllowed,
        isActive,
        documentTemplate,
        documentType,
        label,
        name,
      } = data[0];
      DocumentTypeService.setLocalDocumentType(documentType);
      const templateName = documentTemplate?.name ?? '';

      form.reset({
        ...form.getValues(),
        newDocumentType: false,
        documentType: documentType.label,
        documentTypeName: documentType.name,
        isActive: isActive,
        documentTypeCode: documentType.code,
        formMode: mode,
        documentTypeDisabled: true,
        subTypesDisabled: true,
        documentSubType: label,
        documentSubTypeCode: code,
        documentSubTypeName: name,
        isRawFileDownloadAllowed,
        isFollowUpRequired,
        followUpTimePeriodInMonths,
        followUpTriggerPeriodInDays,
        effectivenessPeriodInDays,
        isTrainable,
        templateName,
        isAdminReviewRequired,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [mode, form]
  );

  const populateFormWithDocumentType = useCallback(
    async (documentTypeName: string) => {
      const { data } = await DocumentTypeService.getDocumentType(
        documentTypeName
      );
      DocumentTypeService.setLocalDocumentType(data);
      const {
        documentTemplate,
        isFollowUpRequired,
        followUpTimePeriodInMonths,
        followUpTriggerPeriodInDays,
        effectivenessPeriodInDays,
        isTrainable,
        isAdminReviewRequired,
        code,
        isRawFileDownloadAllowed,
        isActive,
        label,
        name,
      } = data;

      const templateName = documentTemplate?.name ?? '';

      form.reset({
        ...form.getValues(),
        newDocumentType: false,
        documentType: label,
        documentTypeName: name,
        documentTypeCode: code,
        formMode: mode,
        documentTypeDisabled: true,
        subTypesDisabled: false,
        isActive: isActive,
        isRawFileDownloadAllowed,
        isFollowUpRequired,
        followUpTimePeriodInMonths,
        followUpTriggerPeriodInDays,
        effectivenessPeriodInDays,
        isTrainable,
        templateName,
        isAdminReviewRequired,
      });
    },
    [mode, form]
  );

  useEffect(() => {
    if (mode === FormMode.CREATE || !documentType) return;
    const searchParams = getSearchParams();
    if (searchParams.documentSubType)
      populateFormWithSubType(searchParams.documentSubType);
    else populateFormWithDocumentType(documentType);
  }, [
    mode,
    documentType,
    getSearchParams,
    populateFormWithDocumentType,
    populateFormWithSubType,
  ]);

  const {
    data: response,
    isLoading: loading,
    error,
  } = useObjectData<TDocumentTemplatesResponse>({
    objectName: ObjectEnum.DOCUMENT_TEMPLATE,
  });

  const templateNames = useMemo(() => {
    if (loading || !response || error) return [];
    return response.data.reduce((acc, templateName) => {
      if (templateName.isActive)
        acc.push({
          value: templateName.name,
          text: templateName.title,
        });
      return acc;
    }, [] as { value: string; text: string }[]);
  }, [response, loading, error]);

  const navigate = useNavigate();

  const styles = getStyles();

  const handleSubmit = form.handleSubmit(async (data) => {
    const documentTypeService = new DocumentTypeService(data, documentType);

    try {
      await documentTypeService.submitForm();
      form.reset({ ...form.getValues() }, { keepDirty: false });
      setIsSaveModalOpen(true);
    } catch (_error) {
      raiseErrorToast(_error);
    }
  });

  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState<boolean>(false);

  const navigation = useCallbackPrompt(form.formState.isDirty);

  const toggleSaveModalBtnClick = () => {
    setIsSaveModalOpen(!isSaveModalOpen);
    form.reset({ ...form.getValues() });

    if (isSaveModalOpen) navigate(-1);
  };

  const toggleCancelModalBtnClick = useCallback(
    (navigateBack?: boolean) => {
      setIsCancelModalOpen((prev) => !prev);
      if (navigateBack) {
        form.reset({}, { keepDirty: false });
        navigation.confirmNavigation();
        navigate(-1);
      }
    },
    [navigation, form, navigate]
  );

  const onCancel = useCallback(() => {
    if (!form.formState.isDirty) {
      navigate(-1);
    } else {
      setIsCancelModalOpen(true);
    }
  }, [form.formState.isDirty, navigate]);

  return {
    form,
    toggleSaveModalBtnClick,
    toggleCancelModalBtnClick,
    onCancel,
    isSaveModalOpen,
    isCancelModalOpen,
    styles,
    handleSubmit,
    fields,
    templateNames,
    formSchema,
    navigation,
    docTypeAttributes,
    docSubtypeAttributes,
    isLoading: docTypeAttributesLoading || docSubTypeAttributesLoading,
  };
};
