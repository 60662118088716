import { PrimaryDropdownProps } from './primary-dropdown.component';
import PrimaryDropdownView from './primary-dropdown.view';

interface PrimaryDropdownControllerProps extends PrimaryDropdownProps {}

const PrimaryDropdownController = (
  props: PrimaryDropdownControllerProps
): JSX.Element => {
  return <PrimaryDropdownView {...props} />;
};

export default PrimaryDropdownController;
