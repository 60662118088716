import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { ObjectDefinitionDto } from 'apps/web-client/src/types/object-definition-dto';
import Endpoints from 'libs/core/src/config/endpoint';
import { useState } from 'react';
import { useQuery } from 'react-query';

export function useGetObjectData(objectNameFromProps?: string) {
  const [objectName, setObjectName] = useState<string | undefined>();

  async function fetchObjectData() {
    if (!objectName) return {} as ObjectDefinitionDto;

    const response = await performRequest<ObjectDefinitionDto>(
      `${Endpoints.ATTRIBUTE_CONFIG_URL}/${objectName}`,
      ApiMethodType.GET
    );

    return response.data;
  }

  return {
    objectData: useQuery(['object-data', objectName], fetchObjectData, {
      refetchOnWindowFocus: false,
    }),
    setObjectName,
    objectName,
  };
}
