import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useMemo } from 'react';
import { useFormContext } from 'react-hook-form';

import { getAttributeConfig } from '../../../utils/attribute-config';
import { FieldContainerProps, TAttributeDefinition } from './types';

/**
 * Form field container
 *
 * Renders an editable field based on the attribute definition
 * @param props - Field configuration params {@link FieldContainerProps}
 * @returns Form field container
 */
export const FieldContainer: React.FC<FieldContainerProps> = ({
  attributeDefinition,
  recordDetails,
  fieldState,
  excludeUsers,
}) => {
  const attribute = useMemo(() => {
    let attribute = {};

    if (fieldState) {
      attribute = {
        ...attributeDefinition,
        ...fieldState,
      };
    }

    return attribute as TAttributeDefinition;
  }, [attributeDefinition, fieldState]);

  const { control, watch, setValue, trigger } = useFormContext();

  const attributeComponent = useMemo(() => {
    return getAttributeConfig(
      attribute?.relationship?.name ?? '',
      attribute,
      control,
      FormEngineModeTypeEnum.Edit,
      // REMOVE THIS
      {} as any,
      watch,
      setValue,
      trigger,
      recordDetails,
      {},
      excludeUsers
    );
  }, [attribute, control, watch, setValue, trigger, recordDetails]);

  return attributeComponent;
};
