import { performRequest } from '@celito.clients/network';
import { logger } from '@celito.clients/services';

import Endpoints from '../../config/endpoint';
import { ApiMethodType } from '../../enums/api-constants';
import { LoginType } from '../../types/login-types';

interface IPostLoginLog {
  isSuccessful: boolean;
  requestId?: string;
  accessToken: string;
  userDetails?: {
    userName?: string;
    oid?: string;
    tenantId?: string;
  };
}

export const postLoginLog = async ({
  isSuccessful,
  accessToken,
  requestId,
  userDetails,
}: IPostLoginLog) => {
  const newLoginLog: LoginType = {
    loginLog: { isSuccessful, requestId },
  };

  const headers = {
    Authorization: `Bearer ${accessToken}`,
  };
  try {
    const response = await performRequest<LoginType>(
      `${Endpoints.LOGIN_LOG}`,
      ApiMethodType.POST,
      newLoginLog,
      { headers }
    );
    logger.debug('Logging post login event', userDetails);
    return response.data;
  } catch (e) {
    //error handler
    logger.error('Failed to log post login event', userDetails);
  }
};

export function useLoginLog() {
  return { postLoginLog };
}
