import { makeStyles } from '@fluentui/react-components';

export const TrainingMatrixStyles = makeStyles({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  actionsContainer: { display: 'flex', gap: '10px' },

  headerTitle: {
    fontSize: '1.25rem',
    fontWeight: 600,
  },
});
