import { OperatorsEnum } from '@celito.clients/enums';
import {
  getReferenceFilterValue,
  getUiTextFromOperatorEnum,
  parseFilterUrlValue,
} from '@celito.clients/list-view-engine';
import { IFilterPanelFormData } from '@celito.clients/list-view-engine';
import { FilterCriteria, RangeTypeValue } from '@celito.clients/types';
import { AttributeTypeEnum } from 'libs/form-engine/src/lib/enums/attributes-enum';

import {
  IConditionType,
  IOption,
  IRule,
} from '../../../rules-component/rules-component.model';
import { RulesComponentListViewFilter } from '../../../rules-component/types/rules-component.types';
import {
  FilterColumnConfig,
  FiltersFormData,
  ObjectMetadata,
  SimplifiedFilterConfig,
} from '../types';

export const getFilterFormConfigFromFilterCriteria = (
  filterConfig: FilterCriteria[],
  allColumns: FilterColumnConfig[]
): FiltersFormData[] => {
  const existingFilters: FiltersFormData[] = filterConfig.map(
    ({ attribute, operator: op, value }) => {
      const operator = op === OperatorsEnum.IN ? OperatorsEnum.EQUALS : op;

      const foundColumn = allColumns.find(
        (col) => col?.columnName === attribute
      );

      const a: FiltersFormData = {
        filterName: {
          key: attribute,
          text: foundColumn?.text ?? attribute,

          dataType: foundColumn?.dataType ?? AttributeTypeEnum.PlainText,
          name: attribute,
          relationship: foundColumn?.relationship,
          columnName: attribute,
        },
        condition: {
          key: operator,
          text: getUiTextFromOperatorEnum(operator),
          operator: operator,
        },
        value: parseFilterUrlValue(
          foundColumn?.dataType ?? AttributeTypeEnum.PlainText,
          operator,
          getFilterUrlValFromCriteria(
            foundColumn?.dataType ?? AttributeTypeEnum.PlainText,
            operator,
            value
          )
        ),
      };
      return a;
    }
  );
  return existingFilters;
};

export const getFilterUrlValFromCriteria = (
  datatype: AttributeTypeEnum,
  operator: OperatorsEnum,
  value: FilterCriteria['value']
): string => {
  if (operator === OperatorsEnum.RANGE) {
    return `${(value as RangeTypeValue<number>).min}--${
      (value as RangeTypeValue<number>).max
    }`;
  } else {
    if (datatype === AttributeTypeEnum.Reference)
      return `json__${JSON.stringify(value)}`;
    return value as string;
  }
};

export const transformToApiFilterCriteria = (
  filterCriteria: FilterCriteria[],
  objectMetadata: ObjectMetadata
): FilterCriteria[] => {
  return filterCriteria.map((filter) => ({
    ...filter,
    attribute:
      objectMetadata.objectAttributeDefinitions.find(
        (objectConfig) => objectConfig.name === filter.attribute
      )?.columnName ?? filter.attribute,
  }));
};

export const transformToApiFilterConfig = (
  filterCriteria: SimplifiedFilterConfig[],
  objectMetadata: ObjectMetadata
): SimplifiedFilterConfig[] => {
  return filterCriteria.map((filter) => ({
    ...filter,
    attr:
      objectMetadata.objectAttributeDefinitions.find(
        (objectConfig) => objectConfig.name === filter.attr
      )?.columnName ?? filter.attr,
  }));
};

export const updateUserNameFieldForUserObject = (
  fieldData: IOption[],
  objectName: string
) => {
  const userNameField = fieldData.find((obj) => obj.value === 'username');
  if (userNameField && objectName === 'user__s') {
    userNameField.value = 'label';
  }
};

export const transformRulesComponentListViewFilterToIRule = (
  filtersApplied: RulesComponentListViewFilter[]
): IFilterPanelFormData => {
  const rules: IFilterPanelFormData = {
    rules: [
      {
        conditionType:
          (Object.keys(
            filtersApplied[0].conditions ?? {}
          )[0] as IConditionType) ?? 'all',
        isDefault: true,
        groups: [],
        label: '',
      },
    ],
  };

  const groupFilterList = Object.values(filtersApplied[0].conditions ?? {})[0];

  groupFilterList.forEach((filterObj) => {
    const groupObj = {
      conditions: [],
      conditionType: Object.keys(filterObj)[0],
    } as IRule['groups'][0];

    const conditionsList = Object.values(filterObj)[0];

    conditionsList.forEach((condition) => {
      if (condition.referenceFilters)
        groupObj.conditions.push(
          getReferenceFilterValue(condition.referenceFilters, condition)
        );
      else
        groupObj.conditions.push({
          fact: condition.attribute ?? '',
          value: condition.value ?? undefined,
          operator: condition.operator ?? undefined,
        });
    });
    const object = Object.values(rules)[0];
    object[0].groups.push(groupObj);
  });

  return rules;
};
