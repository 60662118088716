export enum AvatarColors {
  neutral = 'neutral',
  brand = 'brand',
  colorful = 'colorful',
  'dark-red' = 'dark-red',
  cranberry = 'cranberry',
  red = 'red',
  pumpkin = 'pumpkin',
  peach = 'peach',
  marigold = 'marigold',
  gold = 'gold',
  brass = 'brass',
  brown = 'brown',
  forest = 'forest',
  seafoam = 'seafoam',
  'dark-green' = 'dark-green',
  'light-teal' = 'light-teal',
  teal = 'teal',
  steel = 'steel',
  blue = 'blue',
  'royal-blue' = 'royal-blue',
  cornflower = 'cornflower',
  navy = 'navy',
  lavender = 'lavender',
  purple = 'purple',
  grape = 'grape',
  lilac = 'lilac',
  pink = 'pink',
  magenta = 'magenta',
  plum = 'plum',
  beige = 'beige',
  mink = 'mink',
  platinum = 'platinum',
  anchor = 'anchor',
}
