import { HeaderViewProps } from '../../header.view';
import PrimaryDropdownController from './primary-dropdown.controller';

export type PrimaryDropdownProps = HeaderViewProps;

const PrimaryDropdown = (props: PrimaryDropdownProps): JSX.Element => {
  return <PrimaryDropdownController {...props} />;
};

export default PrimaryDropdown;
