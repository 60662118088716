import { TrainingAssignmentTypeEnum } from '@celito.clients/enums';
import { AssignmentsList } from '@celito.clients/shared';

interface ILCTrainingAssignmentListScreenProps {
  trainingAssignmentType: TrainingAssignmentTypeEnum;
}

const ILCTrainingAssignmentListScreen = (
  props: ILCTrainingAssignmentListScreenProps
): JSX.Element => {
  return <AssignmentsList {...props} />;
};

export default ILCTrainingAssignmentListScreen;
