import { Icon } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { tokens } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ComponentProps, useState } from 'react';
import { components, GroupBase } from 'react-select';
import AsyncCreatableSelect, {
  AsyncCreatableProps,
} from 'react-select/async-creatable';

import { InputComponent as Input } from './input';
import { MenuList } from './menu-list';
import { Option } from './option';
import { SingleValueComponent } from './single-value';

interface SelectProps<T> extends AsyncCreatableProps<T, false, GroupBase<T>> {}

export default function Select<T>({
  errorMessage,
  sizeDefinition = {
    large: { fontSize: '1rem', height: '39px' },
    medium: { fontSize: '14px', height: '29px' },
    small: { fontSize: '12px', height: '21px' },
  },
  ...props
}: SelectProps<T> & {
  label: string;
  errorMessage?: string;
  sizeDefinition?: any;
}) {
  const [isOpen, setOpen] = useState<boolean>(false);
  const { theme } = useTheme();
  const size = 'large';
  const enableBaseStyles = true;
  return (
    <AsyncCreatableSelect
      cacheOptions
      defaultOptions
      loadOptions={() => Promise.resolve([])}
      styles={{
        control: (baseStyles) => ({
          ...baseStyles,

          borderColor: errorMessage
            ? theme.colors.error_color
            : theme.colors.gray_400,
          borderBottom: errorMessage
            ? '1px solid  theme.colors.error_color'
            : '1px solid var(--colorNeutralStrokeAccessible)',
          '&:focus-within': {
            borderBottomLeftRadius: 'var(--borderRadiusMedium)',
            borderBottomRightRadius: 'var(--borderRadiusMedium)',
            borderBottom: errorMessage
              ? '1px solid  theme.colors.error_color'
              : '2px solid var(--colorCompoundBrandStroke)',
          },
          '&:hover': {
            boxShadow: 'none',
          },
          boxShadow: 'none',
          minHeight: sizeDefinition[size].height,
          fontSize: sizeDefinition[size].fontSize,
          padding: 0,
          margin: 0,
          borderRadius: tokens.borderRadiusLarge,
        }),

        valueContainer: (baseStyles, state) => ({
          ...baseStyles,
          minHeight: sizeDefinition[size].height,
          padding: '0',
          width: '100px !important',
          paddingLeft: '12px',
        }),

        indicatorsContainer: (baseStyles) => ({
          ...baseStyles,
          alignItems: 'center',
          minHeight: sizeDefinition[size].height,
        }),

        dropdownIndicator: (baseStyles) => ({
          ...baseStyles,
          alignSelf: 'center',
          padding: '0',
          marginLeft: '8px',
          marginRight: '8px',
        }),

        clearIndicator: (baseStyles, state) => ({
          ...baseStyles,
          padding: '0',
          marginRight: '8px',
        }),

        indicatorSeparator: (baseStyles, state) => ({
          ...baseStyles,
          padding: '0',
        }),

        menuList: (baseStyles) => ({
          ...(enableBaseStyles && baseStyles),
          padding: '4px',
        }),

        menu: (baseStyles) => ({
          ...(enableBaseStyles && baseStyles),
          zIndex: 9999,
          marginTop: '3px',
        }),
        menuPortal(baseStyles) {
          return { ...baseStyles, zIndex: 9999 };
        },

        option: (baseStyles, { isSelected, isFocused, isDisabled }) => ({
          ...(enableBaseStyles && baseStyles),
          backgroundColor:
            isSelected || (isFocused && !isDisabled)
              ? tokens.colorNeutralBackground1Hover
              : undefined,
          color: isSelected ? theme.colors.action_text_color : baseStyles.color,
          position: isSelected ? 'relative' : undefined,
          borderRadius: tokens.borderRadiusMedium,
          ':before': {
            content: isSelected ? '""' : undefined,
            position: 'absolute',
            left: 0,
            top: 0,
            borderRadius: tokens.borderRadiusMedium,
            display: 'inline-block',
            width: '4px',
            height: '100%',
            backgroundColor: theme.colors.DARK_BLUE_BORDER,
            marginRight: '8px',
          },
        }),

        placeholder: (baseStyles) => ({
          ...baseStyles,
          fontFamily: theme.fontFamily.regular,
          fontSize: sizeDefinition[size].fontSize,
          fontWeight: 'normal',
        }),
      }}
      menuIsOpen={isOpen}
      onMenuClose={() => setOpen(false)}
      onMenuOpen={() => setOpen(true)}
      isClearable={true}
      createOptionPosition={'first'}
      isValidNewOption={() => false}
      placeholder={'Create...'}
      options={[]}
      closeMenuOnSelect
      noOptionsMessage={({ inputValue }) =>
        `No types found${!inputValue ? ', start typing to create one' : ''}`
      }
      {...props}
      components={{
        MenuList: MenuList({
          onClick: (inputValue) => {
            props.onCreateOption?.(inputValue);
            setOpen(false);
          },
        }),
        Input,
        DropdownIndicator,
        IndicatorSeparator: () => null,
        Option,
        SingleValue: (singleValueProps) =>
          SingleValueComponent({ ...singleValueProps, label: props.label }),
      }}
    />
  );
}
const DropdownIndicator = <T,>(
  props: ComponentProps<
    typeof components.DropdownIndicator<T, false, GroupBase<T>>
  >
) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icon
        style={{ position: 'relative', left: -4 }}
        iconName="Search16Regular"
        color={Color.black}
      />
    </components.DropdownIndicator>
  );
};
