/* eslint-disable no-case-declarations */
import 'react-quill/dist/quill.snow.css';

import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { useLayout } from '@celito.clients/hooks';
import {
  ConfirmDialog,
  CustomButton,
  FormActionButtonsContainer,
  Icon,
  Loader,
} from '@celito.clients/shared';
import { TrainingAssignmentData } from '@celito.clients/types';
import {
  Checkbox,
  mergeClasses,
  Radio,
  RadioGroup,
  Tooltip,
} from '@fluentui/react-components';
import failureImg from 'libs/assets/src/images/failed.svg';
import startQuizImage from 'libs/assets/src/images/startQuiz.svg';
import success from 'libs/assets/src/images/success.svg';
import successBg from 'libs/assets/src/images/successBack.svg';
import thumbsdown from 'libs/assets/src/images/Thumbsdown.svg';
import thumbsUp from 'libs/assets/src/images/ThumbsUp.svg';

import { QuestionTypes } from './enums';
import { TakingQuizProps } from './taking-quiz.model';
import style from './taking-quiz.module.css';
import { Choice, Question, QuizInfo } from './types';

interface TakingQuizViewProps extends TakingQuizProps {
  isLoading: boolean;
  questions: Question[];
  isError: boolean;
  errorMessage: string;
  isQuizStarted: boolean;
  startQuiz: () => void;
  showResultPage: boolean;
  defaultIndex: number;
  showFeedback: boolean;
  totalPercentage: number;
  showFailurePage: boolean;
  retakeQuiz: boolean;
  onSubmit: () => void;
  isSuccess: boolean;
  onNextButtonClick: () => Promise<void>;
  handleRadioSelection: (name: string, queId: string) => void;
  onDragStart: (
    e: React.DragEvent<HTMLDivElement>,
    item: Choice,
    name: string
  ) => void;
  onDrop: (
    e: React.DragEvent<HTMLDivElement>,
    index: number,
    name: string
  ) => void;
  onDragOver: (e: React.DragEvent<HTMLDivElement>) => void;
  items: Choice[];
  quizInfo: QuizInfo | undefined;
  currChoiceId: string | undefined;
  onPrevClick: () => void;
  queAns: number;
  onClickRetakeQuiz: () => void;
  onClickReviewMaterial: () => void;
  incDecQues: (type: string) => void;
  handleMultipleSelection: (
    checked: boolean | undefined,
    name: string,
    choices: Choice,
    queId: string
  ) => void;
  checkedCheckbox: (type: QuestionTypes, option: Choice) => boolean | undefined;
  reRender: boolean;
  isViewMode: boolean;
  currBooleanId: string | undefined;
  updateDefaultIndex: (e: React.ChangeEvent<HTMLInputElement>) => void;
  nullIndex: number | undefined;
  taskName?: string;
  validQuiz: boolean;
  onUpdatedQuiz: () => void;
  onTrainingSignOffBtnClick: () => void;
  isQuestionAttempted: boolean;
  trainingAssignmentData: TrainingAssignmentData | undefined;
  getRadioValue: (option: Choice[]) => string | undefined;
  isNextDisabled: boolean;
  isPreviousDisabled: boolean;
}
export const TakingQuizView = ({
  dataTestId,
  isLoading,
  questions,
  isError,
  errorMessage,
  isQuizStarted,
  startQuiz,
  showResultPage,
  defaultIndex,
  showFeedback,
  totalPercentage,
  showFailurePage,
  retakeQuiz,
  onSubmit,
  isSuccess,
  onNextButtonClick,
  onDragOver,
  onDragStart,
  onDrop,
  items,
  handleRadioSelection,
  quizInfo,
  currChoiceId,
  onPrevClick,
  queAns,
  onClickRetakeQuiz,
  onClickReviewMaterial,
  incDecQues,
  handleMultipleSelection,
  checkedCheckbox,
  reRender,
  isViewMode,
  currBooleanId,
  updateDefaultIndex,
  nullIndex,
  taskName = '',
  validQuiz,
  onUpdatedQuiz,
  onTrainingSignOffBtnClick,
  isQuestionAttempted,
  trainingAssignmentData,
  getRadioValue,
  isNextDisabled,
  isPreviousDisabled,
}: TakingQuizViewProps): JSX.Element => {
  const { configureLayout } = useLayout();
  const bgImageStyling = `${style.flexDirec} ${style.ml35} ${style.posRel}`;
  const isNextButtonDisabled =
    (showFeedback && defaultIndex === questions?.length - 1) ||
    (!isQuestionAttempted && !showFeedback) ||
    isNextDisabled;
  const isQuestionDisabled =
    queAns !== defaultIndex ||
    (isQuestionAttempted && isNextDisabled) ||
    isViewMode;

  configureLayout({
    pageTitle: '',
    enablePadding: false,
  });

  if (isLoading) {
    return <Loader className={style.center} />;
  }

  function renderQuestions(
    choices: Choice[],
    questionType: string,
    name: string
  ) {
    switch (questionType) {
      case QuestionTypes.MULTIPLE_CHOICE:
      case QuestionTypes.BOOLEAN:
        return (
          <>
            <h4>
              {LocalizationString.ANSWER}{' '}
              <span className={style.sideText}>
                {questionType === QuestionTypes.MULTIPLE_CHOICE
                  ? LocalizationString.SINGLE_SELECTION
                  : LocalizationString.BOOLEAN_CHOICE}
              </span>
            </h4>
            <RadioGroup
              layout="horizontal"
              value={getRadioValue(choices)}
              disabled={isQuestionDisabled}
              onChange={(_, data) => handleRadioSelection(data.value, name)}
              className={style.wrapWords}
            >
              {choices.map((choice, choiceIndex) => {
                return (
                  <Radio
                    disabled={isQuestionDisabled}
                    label={choice.choiceText}
                    value={choice.name}
                    data-testid={`radio-button-${choice?.choiceText}`}
                  />
                );
              })}
            </RadioGroup>
          </>
        );

      case QuestionTypes.MULTIPLE_RESPONSE:
        return (
          <>
            <h4>
              {LocalizationString.ANSWER}{' '}
              <span className={style.sideText}>
                {' '}
                {questionType === QuestionTypes.MULTIPLE_RESPONSE
                  ? LocalizationString.MULTIPLE_SELECTION
                  : LocalizationString.BOOLEAN_CHOICE}
              </span>
            </h4>
            {reRender
              ? choices?.map((options: Choice) => (
                  <div className={style.wrapWords}>
                    <Checkbox
                      data-testid={`checkbox-${options?.choiceText}`}
                      checked={checkedCheckbox(questionType, options)}
                      disabled={isQuestionDisabled}
                      className={style.checkboxDiv}
                      onChange={(e, data) =>
                        handleMultipleSelection(
                          data.checked as boolean,
                          options?.name,
                          options,
                          name
                        )
                      }
                      id={options?.name}
                    />
                    <div className={style.choiceText}>
                      {options?.choiceText}
                    </div>
                  </div>
                ))
              : null}
          </>
        );
      case QuestionTypes.SEQUENCE:
        return (
          <div className={style.ml35}>
            <h4 className={style.mt27}>
              {LocalizationString.ANSWER}{' '}
              <span className={style.sideText}> {LocalizationString.DRAG}</span>
            </h4>
            {reRender
              ? items.map((item, index) => (
                  <div className={`${style.dragDropContainer}`}>
                    <div
                      role="button"
                      className={`${style.numberDiv} ${style.ml35} ${
                        isQuestionDisabled ? style.opt1 : null
                      }`}
                      draggable={!isQuestionDisabled}
                      onDragStart={(ev) => onDragStart(ev, item, name)}
                      onDragOver={onDragOver}
                      onDrop={(ev) => onDrop(ev, index, name)}
                    >
                      <Icon
                        iconName="ReOrderDotsVertical24Regular"
                        className={style.sequenceIcon}
                      />
                    </div>
                    <div className={style.choiceTextDiv}>
                      <Tooltip relationship="label" content={item?.choiceText}>
                        <div
                          role="button"
                          draggable={false}
                          onDragStart={(ev) => ev.stopPropagation()}
                          onDrop={(ev) => ev.stopPropagation()}
                          style={{
                            padding: '10px',
                            backgroundColor: '#fff',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            margin: '15px 0px',
                            marginRight: '30px',
                            opacity: isQuestionDisabled ? '0.4' : '1',
                            minWidth: '310px',
                            maxWidth: '310px',
                            overflowWrap: 'anywhere',
                            height: '40px',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div className={style.choiceTextDiv}>
                            {item?.choiceText}
                          </div>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                ))
              : null}
          </div>
        );
    }
  }

  function renderResultPage(
    hasPassedQuiz: boolean,
    percentage: number,
    classname?: string
  ) {
    return (
      <div className={classname}>
        <div>
          <img
            src={hasPassedQuiz ? successBg : successBg}
            className={style.bgImage}
            alt="failure"
          />
          <div className={style.successFailedDiv}>
            <div>
              <h3>Quiz Score: {percentage}%</h3>
              <p>
                {!hasPassedQuiz
                  ? LocalizationString.FAILED_QUIZ
                  : LocalizationString.PASSED_QUIZ}
              </p>
            </div>
            <div>
              <img src={!hasPassedQuiz ? failureImg : success} alt=""></img>
            </div>
            <div>
              {hasPassedQuiz ? (
                <h3 className={style.text}>
                  {LocalizationString.CONGRATULATION}
                </h3>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div data-testid={dataTestId} style={{ height: '100%' }}>
      <div className={style.headerButtonContainer}>
        {!isQuizStarted ? (
          !trainingAssignmentData?.hasPassedQuiz ? (
            <CustomButton
              buttonType={ButtonTypes.Primary}
              buttonTitle={LocalizationString.START_QUIZ}
              onButtonClicked={startQuiz}
            >
              {LocalizationString.START_QUIZ}
            </CustomButton>
          ) : (
            <CustomButton
              buttonType={ButtonTypes.Primary}
              buttonTitle={LocalizationString.TRAINING_SIGNOFF}
              onButtonClicked={onTrainingSignOffBtnClick}
            >
              {LocalizationString.TRAINING_SIGNOFF}
            </CustomButton>
          )
        ) : null}
        {showResultPage ? (
          showFailurePage ? (
            retakeQuiz ? (
              <CustomButton
                buttonType={ButtonTypes.Primary}
                buttonTitle={LocalizationString.RETAKE_QUIZ}
                onButtonClicked={onClickRetakeQuiz}
              >
                {LocalizationString.RETAKE_QUIZ}
              </CustomButton>
            ) : (
              <CustomButton
                buttonType={ButtonTypes.Primary}
                buttonTitle={LocalizationString.REVIEW_MATERIAL}
                onButtonClicked={onClickReviewMaterial}
              >
                {LocalizationString.REVIEW_MATERIAL}
              </CustomButton>
            )
          ) : (
            <CustomButton
              buttonType={ButtonTypes.Primary}
              buttonTitle={LocalizationString.TRAINING_SIGNOFF}
              onButtonClicked={onTrainingSignOffBtnClick}
            >
              {LocalizationString.TRAINING_SIGNOFF}
            </CustomButton>
          )
        ) : null}
      </div>
      <div className={style.flexDiv}>
        <div className={style.quizDetails}>
          <h3 style={{ padding: '20px' }} className={style.queHeadingColor}>
            Quiz Details
          </h3>
          <hr></hr>
          <div className={style.flexDirec}>
            <div className={style.pad20}>
              <h4>Quiz Name</h4>
              <p>{quizInfo?.title}</p>
            </div>
            <div className={style.pad20}>
              <h4>
                Passing Score <span className={style.sideText}>(%)</span>
              </h4>
              <p>{quizInfo?.passingScore}</p>
            </div>
            <div className={style.pad20}>
              <h4>Quiz Description</h4>
              {quizInfo?.description ? (
                <div
                  className={mergeClasses(
                    style.value,
                    style.richText,
                    'ql-editor'
                  )}
                  dangerouslySetInnerHTML={{
                    __html: `${quizInfo?.description}`,
                  }}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        <div className={style.divider}></div>
        <div className={style.quizQue}>
          {!isQuizStarted ? (
            trainingAssignmentData?.hasPassedQuiz ? (
              renderResultPage(
                trainingAssignmentData?.hasPassedQuiz,
                totalPercentage,
                bgImageStyling
              )
            ) : (
              <img
                src={startQuizImage}
                className={style.startQuiz}
                alt="start quiz"
              />
            )
          ) : (
            <>
              <div className={style.queHeadingBlock}>
                <h3
                  style={{ padding: '20px', backgroundColor: 'white' }}
                  className={style.queHeadingColor}
                >
                  Questions{' '}
                </h3>
                <div className={style.incDiv}>
                  <span style={{ float: 'right' }} className={style.incSpan}>
                    {!showResultPage ? (
                      <>
                        <span style={{ fontWeight: 'normal' }}>
                          <button
                            onClick={() => incDecQues('dec')}
                            className={style.removeBorder}
                            disabled={defaultIndex === 0}
                          >
                            {' '}
                            <Icon
                              iconName="ChevronLeft16Regular"
                              className={style.pdR15}
                            ></Icon>
                          </button>{' '}
                          <input
                            className={style.indexDiv}
                            maxLength={1}
                            value={nullIndex === -1 ? '' : defaultIndex + 1}
                            onInput={(e: React.ChangeEvent<HTMLInputElement>) =>
                              updateDefaultIndex(e)
                            }
                          />
                          &nbsp; &nbsp;
                          {'  /  '} &nbsp; &nbsp;{questions?.length}
                        </span>
                        <button
                          onClick={() => incDecQues('inc')}
                          className={style.removeBorder}
                          disabled={
                            defaultIndex === questions?.length - 1 ||
                            queAns <= defaultIndex
                          }
                        >
                          {' '}
                          <Icon
                            iconName="ChevronRight16Regular"
                            className={style.pdL15}
                          ></Icon>
                        </button>
                      </>
                    ) : null}
                  </span>
                </div>
              </div>

              <hr></hr>
              <div className={style.flexGrow}>
                <div className={bgImageStyling}>
                  {!showResultPage
                    ? questions?.map((value, index) => (
                        <>
                          {defaultIndex === index ? (
                            <>
                              <span className={style.circle}>
                                <span className={style.indexClass}>
                                  <b>{index + 1}</b>
                                </span>
                              </span>
                              <div
                                className={`${style.pad20} ${style.wrapWords}`}
                              >
                                <h4 className={style.queHeadingColor}>
                                  Question
                                </h4>
                                <p>{value?.questionText}</p>
                                {/* <hr className={style.line}></hr> */}
                              </div>
                              <div className={style.pad20}>
                                {renderQuestions(
                                  value?.choices,
                                  value?.questionType,
                                  value?.name
                                )}
                              </div>
                            </>
                          ) : null}
                          {defaultIndex === index && showFeedback ? (
                            <>
                              <div
                                className={`${style.resultContainer} ${style.ml20}`}
                              >
                                <div className={style.resultDiv}>
                                  <img
                                    src={isSuccess ? thumbsUp : thumbsdown}
                                    className={style.resultImage}
                                    alt="result"
                                  />{' '}
                                  <span
                                    className={
                                      isSuccess
                                        ? style.resultText
                                        : style.resultTextFail
                                    }
                                  >
                                    {isSuccess
                                      ? LocalizationString.CONGRATULATION
                                      : LocalizationString.OOPS}
                                  </span>
                                </div>
                                <hr className={style.line}></hr>
                              </div>
                              <div className={style.ml20}>
                                {value.feedback && (
                                  <>
                                    <h3>Feedback</h3>
                                    <div>{value.feedback}</div>
                                  </>
                                )}
                              </div>
                            </>
                          ) : null}
                        </>
                      ))
                    : renderResultPage(!showFailurePage, totalPercentage)}
                </div>
              </div>
              {!showResultPage && (
                <FormActionButtonsContainer
                  className={style.formActionButtonsContainer}
                >
                  <>
                    {defaultIndex !== 0 ? (
                      <CustomButton
                        buttonType={ButtonTypes.Ghost}
                        buttonTitle={LocalizationString.PREVIOUS}
                        leftIcon="Previous"
                        onButtonClicked={
                          isPreviousDisabled ? undefined : onPrevClick
                        }
                        isDisabled={isPreviousDisabled}
                      >
                        {LocalizationString.PREVIOUS}
                      </CustomButton>
                    ) : null}
                    <CustomButton
                      buttonType={ButtonTypes.Ghost}
                      buttonTitle={LocalizationString.NEXT}
                      leftIcon="Next"
                      onButtonClicked={
                        isNextButtonDisabled ? undefined : onNextButtonClick
                      }
                      isDisabled={isNextButtonDisabled}
                    >
                      {LocalizationString.NEXT}
                    </CustomButton>
                    {defaultIndex === questions?.length - 1 &&
                    queAns === questions?.length ? (
                      <CustomButton
                        buttonType={ButtonTypes.Primary}
                        leftIcon="Submit"
                        buttonTitle={LocalizationString.SUBMIT}
                        onButtonClicked={onSubmit}
                      >
                        {LocalizationString.SUBMIT}
                      </CustomButton>
                    ) : null}
                  </>
                </FormActionButtonsContainer>
              )}
            </>
          )}
        </div>
      </div>

      <ConfirmDialog
        open={!validQuiz}
        title={'Quiz updated'}
        description={LocalizationString.QUIZ_UPDATED}
        dontShowCloseIcon
        isBlocking
        iconSrc={WarningIcon}
        onConfirmClicked={onUpdatedQuiz}
        primaryButtonText={LocalizationString.OK}
      />
    </div>
  );
};
