import React from 'react';

import { RadioButtonController } from './radio-button.controller';
import { RadioButtonProps } from './radio-button.model';

const RadioButton: React.FC<RadioButtonProps> = (props) => {
  return <RadioButtonController {...props} />;
};

export default RadioButton;
