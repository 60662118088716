import './in-app-notifications.css';

import { ButtonTypes } from '@celito.clients/enums';
import { ConfirmDialog, CustomButton, Loader } from '@celito.clients/shared';
import {
  SelectTabData,
  SelectTabEvent,
  Tab,
  TabList,
  TabValue,
} from '@fluentui/react-components';
import React, { useRef, useState } from 'react';

import LocalStrings from '../../assets/localisation';
import { InAppNotificationsListComponent } from './components/in-app-notifcations-list/in-app-notifications-list.component';
import { Notification } from './in-app-notifications.model';
import { inAppNotificationStyles } from './in-app-notifications.styles';

interface InAppNotificationsViewProps {
  notifications: Notification[] | [];
  isNotificationsLoading: boolean;
  isReadAllNotifsLoading: boolean;
  isRemoveAllNotifsLoading: boolean;
  refreshNotifications: () => Promise<void>;
  readNotification: (id: number) => Promise<void>;
  removeNotification: (id: number) => Promise<void>;
  isConfirmDialogOpen: boolean;
  setIsConfirmDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  dialogConfig: {
    title: string;
    description: string;
    icon: string;
    onConfirm: () => void;
  };
  handleReadAll: () => void;
  handleRemoveAll: () => void;
  handleRemove: (id: number) => void;
  hasMoreNotifications: boolean;
  loadMoreNotifications: () => void;
  onDismissInAppNotifPanel: () => void;
}

const InAppNotificationsView: React.FC<InAppNotificationsViewProps> = ({
  notifications,
  isNotificationsLoading,
  isReadAllNotifsLoading,
  isRemoveAllNotifsLoading,
  readNotification,
  removeNotification,
  isConfirmDialogOpen,
  setIsConfirmDialogOpen,
  dialogConfig,
  handleReadAll,
  handleRemoveAll,
  refreshNotifications,
  handleRemove,
  loadMoreNotifications,
  hasMoreNotifications,
  onDismissInAppNotifPanel,
}) => {
  const scrollableContainerRef = useRef<HTMLDivElement>(null);

  const styles = inAppNotificationStyles();

  const [selectedValue, setSelectedValue] = useState<TabValue>('unread');

  const onTabSelect = (event: SelectTabEvent, data: SelectTabData) => {
    setSelectedValue(data.value);
  };

  const unreadNotifications = notifications.filter(
    (n) => n.viewedOnUtc === null
  );

  const allNotifications = notifications;

  return (
    <>
      <div className={styles.container} ref={scrollableContainerRef}>
        {isNotificationsLoading ? (
          <div className="in-app-notifications-loader-wrapper">
            <Loader />
          </div>
        ) : (
          <TabList
            selectedValue={selectedValue}
            onTabSelect={onTabSelect}
            appearance="subtle"
          >
            <Tab
              data-testid="inapp-notification-panel-tab-unread"
              value="unread"
            >
              Unread
            </Tab>
            <Tab data-testid="inapp-notification-panel-tab-all" value="all">
              All
            </Tab>
          </TabList>
        )}

        {selectedValue === 'unread' ? (
          isNotificationsLoading ? (
            <div className="in-app-notifications-loader-wrapper">
              <Loader />
            </div>
          ) : (
            <InAppNotificationsListComponent
              notifications={unreadNotifications}
              readNotification={readNotification}
              removeNotification={removeNotification}
              handleRemove={handleRemove}
              loadMoreNotifications={loadMoreNotifications}
              hasMoreNotifications={
                !unreadNotifications.length ? false : hasMoreNotifications
              }
              onDismissInAppNotifPanel={onDismissInAppNotifPanel}
              isNotificationsLoading={isNotificationsLoading}
              isRemoveAllNotifsLoading={isRemoveAllNotifsLoading}
              refreshNotifications={refreshNotifications}
              scrollableContainerRef={scrollableContainerRef}
            />
          )
        ) : isNotificationsLoading ? (
          <div className="in-app-notifications-loader-wrapper">
            <Loader />
          </div>
        ) : (
          <InAppNotificationsListComponent
            notifications={allNotifications}
            readNotification={readNotification}
            removeNotification={removeNotification}
            handleRemove={handleRemove}
            loadMoreNotifications={loadMoreNotifications}
            hasMoreNotifications={hasMoreNotifications}
            onDismissInAppNotifPanel={onDismissInAppNotifPanel}
            isNotificationsLoading={isNotificationsLoading}
            isRemoveAllNotifsLoading={isRemoveAllNotifsLoading}
            refreshNotifications={refreshNotifications}
            scrollableContainerRef={scrollableContainerRef}
          />
        )}
      </div>

      <div className={styles.inAppNotificationFooter}>
        <CustomButton
          buttonType={ButtonTypes.Secondary}
          buttonTitle={LocalStrings.removeAllLabel}
          onButtonClicked={handleRemoveAll}
          isDisabled={
            isRemoveAllNotifsLoading ||
            (unreadNotifications.length === 0 && selectedValue === 'unread') ||
            (allNotifications.length === 0 && selectedValue === 'all')
          }
          dataTestId="inapp-notification-remove-all-button"
        />
        <CustomButton
          buttonType={ButtonTypes.Primary}
          buttonTitle={LocalStrings.markAllReadLabel}
          onButtonClicked={handleReadAll}
          isDisabled={
            isRemoveAllNotifsLoading ||
            (unreadNotifications.length === 0 && selectedValue === 'unread') ||
            (allNotifications.length === 0 && selectedValue === 'all')
          }
          dataTestId="inapp-notification-mark-all-read-button"
        />
      </div>
      <ConfirmDialog
        open={isConfirmDialogOpen}
        title={dialogConfig.title}
        description={dialogConfig.description}
        iconSrc={dialogConfig.icon}
        onCancelClicked={() => setIsConfirmDialogOpen(false)}
        onConfirmClicked={dialogConfig.onConfirm}
        primaryButtonText="Yes"
        secondaryButtonText="Cancel"
      />
    </>
  );
};

export default InAppNotificationsView;
