import { LocalizationString } from '@celito.clients/assets';
import { DocumentDownloadActionEnum } from '@celito.clients/enums';
import { useConfigureLayout, useQueryParams } from '@celito.clients/hooks';
import { dismissToast } from '@celito.clients/provider';
import { files } from '@celito.clients/services';
import {
  errorToast,
  infoToast,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import {
  DocData,
  DocVersionRestorePayload,
  DocVersions,
  FileBuffer,
  mimeTypes,
} from './doc-version-history.model';
import VersionHistoryView from './doc-version-history.view';
import { versionHistoryService } from './hooks/versionHistoryService';

const VersionHistoryController: React.FC = () => {
  const [docsSelected, setDocsSelected] = useState<number[]>([]);
  const [docData, setDocData] = useState<DocData>();
  const [docVersions, setDocVersions] = useState<DocVersions[]>();
  const [docUrl, setDocUrl] = useState<string>('');
  const [isLocalPDF, setIsLocalPDF] = useState<boolean>(false);
  const [currentVersionUrl, setCurrentVersionUrl] = useState<string>('');
  const [currentVersionResp, setCurrentVersionResp] = useState<DocData>();
  const [loading, setLoading] = useState<boolean>(false);
  const [showErrorPreview, setShowErrorPreview] = useState<boolean>(false);

  const params = useParams();
  const { getSearchParams } = useQueryParams();
  const searchParams = getSearchParams();
  const {
    getDocumentData,
    getDocumentVersions,
    compareSelectedVersions,
    restorePastVersion,
  } = versionHistoryService();

  useConfigureLayout({
    pageTitle: LocalStrings.versionHistory,
  });

  const onDocSelected = (
    id: number,
    isChecked: boolean | 'mixed' | undefined
  ) => {
    if (isChecked !== undefined) {
      setShowErrorPreview(false);
      if (isChecked) {
        setDocsSelected([...docsSelected, id].sort((a, b) => a - b));
        setVersion(id);
      } else {
        const index = docsSelected?.findIndex((version) => version === id);
        docsSelected.splice(index, 1);
        setDocsSelected([...docsSelected].sort((a, b) => a - b));
        if (docsSelected?.length === 1) {
          if (docsSelected[0] === Number(currentVersionResp?.version)) {
            getCurrentVersionDocData();
          } else {
            setVersion(docsSelected[0]);
          }
        }
      }
    }
  };

  const getCurrentVersionDocData = () => {
    setDocUrl(currentVersionUrl);
    setDocData(currentVersionResp);
    if (currentVersionResp?.fileUrl) {
      //Note: Sharepoint files
      const { fileUrl } = currentVersionResp;
      const parts = fileUrl.split('&action=');
      const url = parts[0].concat('&action=embedview');
      setDocUrl(url);
    } else if (
      currentVersionResp &&
      mimeTypes.includes(currentVersionResp?.mimeType)
    ) {
      bufferToURL(currentVersionResp?.fileBuffer, currentVersionResp?.mimeType);
    }
  };

  const restoreVersion = async (documentId: string, version: string) => {
    const payload: DocVersionRestorePayload = {
      objectName: searchParams?.objectName,
      recordName: searchParams?.recordName,
      attributeName: 'file__a',
    };
    const toastId = infoToast(
      { message: LocalizationString.RESTORING_PLACEHOLDER },
      { timeout: -1 }
    );
    await restorePastVersion(payload, documentId, version)
      .then((resp) => {
        setVersion(resp?.version);
        successToast({ message: LocalizationString.RESTORED_SUCCESSFULLY });
      })
      .catch((_error) => {
        raiseErrorToast(_error);
        setLoading(false);
      });
    dismissToast(toastId);
  };

  const fetchDocumentVersions = async () => {
    params.documentId &&
      (await getDocumentVersions(params.documentId, searchParams?.objectName)
        .then((resp) => {
          setDocVersions(resp);
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        }));
  };

  const fetchDownloadData = async (version: number) => {
    params.documentId &&
      files.downloadFile(
        getDocumentData(
          params.documentId,
          searchParams?.objectName,
          searchParams?.recordName,
          version,
          DocumentDownloadActionEnum.Download
        ),
        (resp) => {
          handleDownload(resp, true);
          setShowErrorPreview(false);
        },
        (error) => {
          raiseErrorToast(error);
          setShowErrorPreview(true);
          setLoading(false);
        }
      );
  };
  const [version, setVersion] = useState<number | undefined>(undefined);
  const { data: documentData, isLoading: documentDataLoading } = useQuery(
    ['documentType', version],
    {
      queryFn: () =>
        getDocumentData(
          params.documentId ?? '',
          searchParams?.objectName,
          searchParams?.recordName,
          version
        ),
      enabled: version !== undefined,
      staleTime: Infinity,
      onError: (_error) => {
        setLoading(false);
        raiseErrorToast(_error);
        setShowErrorPreview(true);
      },
    }
  );

  useEffect(() => {
    if (documentData?.fileUrl) {
      //Note: Sharepoint files
      const { fileUrl } = documentData;
      const parts = fileUrl.split('&action=');
      const url = parts[0].concat('&action=embedview');
      setDocUrl(url);
    } else if (
      mimeTypes.includes(documentData?.mimeType ?? '') ||
      documentData?.label?.endsWith('.pdf')
    ) {
      if (documentData?.fileBuffer) {
        bufferToURL(
          documentData?.fileBuffer,
          documentData?.label?.endsWith('.pdf')
            ? 'application/pdf'
            : documentData?.mimeType ?? ''
        );
        setIsLocalPDF(true);
      }
    }
    setDocData(documentData);
    setLoading(documentDataLoading);
  }, [documentData, documentDataLoading]);

  const bufferToURL = async (fileBuffer: FileBuffer, fileType: string) => {
    try {
      const uint8Array = new Uint8Array(fileBuffer?.data);
      const blob = new Blob([uint8Array], { type: fileType });
      const url = URL.createObjectURL(blob);
      setDocUrl(url);
    } catch (e) {
      errorToast({ message: 'Error in converting buffer to preview Url' });
    }
  };

  const onCancelClicked = () => {
    setDocsSelected([]);
    if (currentVersionResp) {
      if (currentVersionResp?.fileUrl) {
        //Note: Sharepoint files
        const { fileUrl } = currentVersionResp;
        const parts = fileUrl.split('&action=');
        const url = parts[0].concat('&action=embedview');
        setDocUrl(url);
      } else if (mimeTypes.includes(currentVersionResp?.mimeType)) {
        bufferToURL(
          currentVersionResp?.fileBuffer,
          currentVersionResp?.mimeType
        );
      }
    }
  };

  const compareVersions = () => {
    if (!params.documentId) {
      return;
    }
    setLoading(true);
    const selectedDocs = docsSelected.sort();
    const payload = {
      documentId: params.documentId,
      versionLeft: selectedDocs[0],
      versionRight: selectedDocs[1],
      objectName: searchParams?.objectName,
    };

    compareSelectedVersions(payload)
      .then((resp) => {
        setDocUrl(resp);
        setLoading(false);
        setIsLocalPDF(false);
      })
      .catch((_error) => {
        setLoading(false);
        raiseErrorToast(_error);
      });
  };

  const handleDownload = (resp?: DocData, fetchForDownloadDocFlag = false) => {
    let downloadData: DocData | undefined;
    try {
      downloadData = fetchForDownloadDocFlag ? resp : docData;

      if (downloadData?.fileBuffer?.data) {
        files.downloadFileBuffer({
          data: downloadData.fileBuffer.data,
          label: downloadData.label,
          mimeType: downloadData.mimeType,
        });
      }
      if (
        downloadData?.certificateBuffer?.data &&
        downloadData?.version.endsWith('.0')
      ) {
        const parts = downloadData.label.split('.');
        parts.pop(); // popping file extension
        const name = `${parts.join('.')} Approval Page.pdf`;
        files.downloadFileBuffer({
          data: downloadData.certificateBuffer.data,
          label: name,
          mimeType: downloadData.mimeType,
        });
      }
    } catch (error) {
      errorToast({ message: `${error}` });
    }
  };

  const handleContextMenuClick = async (
    item: DocVersions,
    actionType: string
  ) => {
    switch (actionType) {
      case 'download':
        await fetchDownloadData(item?.version);
        break;
      case 'restore':
        setLoading(true);
        await restoreVersion(item?.documentId, item?.version.toString());
        await fetchDocumentVersions();
        setLoading(false);
        break;
      default:
        break;
    }
  };

  const handleChange = (
    ev?:
      | React.ChangeEvent<HTMLElement | HTMLInputElement>
      | React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean | 'mixed' | undefined,
    item?: DocVersions
  ) => {
    if (item) {
      onDocSelected(item.version, isChecked);
    }
  };

  useEffect(() => {
    setLoading(true);
    params.documentId &&
      getDocumentData(
        params.documentId,
        searchParams?.objectName,
        searchParams?.recordName
      )
        .then((resp: DocData) => {
          if (resp?.fileUrl) {
            //Note: Sharepoint files
            const { fileUrl } = resp;
            const parts = fileUrl.split('&action=');
            const url = parts[0].concat('&action=embedview');
            setCurrentVersionUrl(url);
            setDocUrl(url);
          } else if (
            mimeTypes.includes(resp.mimeType) ||
            resp.label.endsWith('.pdf')
          ) {
            bufferToURL(
              resp.fileBuffer,
              resp.label.endsWith('.pdf') ? 'application/pdf' : resp.mimeType
            );
            setIsLocalPDF(true);
          }
          setCurrentVersionResp(resp);
          setDocData(resp);
          setLoading(false);
          setShowErrorPreview(false);
        })
        .catch((_error) => {
          setLoading(false);
          raiseErrorToast(_error);
          setShowErrorPreview(true);
        });

    fetchDocumentVersions();
  }, []);

  const disableButton = useCallback(() => {
    if (loading) {
      return true;
    }
    if (docData && !mimeTypes.includes(docData?.mimeType) && !docUrl) {
      return true;
    }
    return false;
  }, [loading, docData, docUrl]);

  return (
    <VersionHistoryView
      docsSelected={docsSelected}
      docUrl={docUrl}
      docData={docData}
      docVersions={docVersions}
      handleChange={handleChange}
      onCancelClicked={onCancelClicked}
      compareVersions={compareVersions}
      loading={loading}
      handleDownload={handleDownload}
      disableButton={disableButton}
      handleContextMenuClick={handleContextMenuClick}
      showErrorPreview={showErrorPreview}
      isLocalPDF={isLocalPDF}
    />
  );
};
export default VersionHistoryController;
