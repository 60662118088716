import { getWidthInPercentage } from '@celito.clients/utils';
import cn from 'classnames';
import React from 'react';

import classes from './section.module.css';

type SectionProps = {
  className?: string;
  width: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const Section: React.FC<SectionProps> = ({
  className,
  width,
  children,
  style,
}) => {
  let offsetWidth = '0px';

  if (width === 2) {
    offsetWidth = '27px';
  } else if (width === 1) {
    offsetWidth = '30px';
  }

  return (
    <div
      style={{
        width: `calc(${getWidthInPercentage(width)}% - ${offsetWidth})`,
        ...style,
      }}
      className={cn(classes.section, className)}
    >
      {children}
    </div>
  );
};

export const SectionView = React.memo(Section);
