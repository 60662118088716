import { OperatorsEnum } from '@celito.clients/enums';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { FollowUpPageProps } from './follow-up-page.model';
import { FollowUpPageView } from './follow-up-page.view';
import { getFollowUpInfo } from './services/getFollowUpPageData';

interface FollowUpPageControllerProps extends FollowUpPageProps {}

export const FollowUpPageController = (
  props: FollowUpPageControllerProps
): JSX.Element => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [followUpData, setFollowUpData] = useState<Record<string, unknown>>({});

  const getFollowUpTaskInfo = async (recordName: string) => {
    const filterData = {
      filters: [
        {
          attribute: 'object_id',
          operator: OperatorsEnum.EQUALS,
          value: 'training_plan__s',
        },
        {
          attribute: 'objectRecordId',
          operator: OperatorsEnum.EQUALS,
          value: { objectName: 'training_plan__s', recordName: recordName },
        },
        {
          attribute: 'tag',
          operator: OperatorsEnum.IN,
          value: ['tp_follow_up', 'tp_retire_approval'],
        },
      ],
      sort: { attribute: 'created_at_utc__s', order: 'DESC' },
    };

    getFollowUpInfo(filterData)
      .then((response) => {
        const followUpData = response?.data?.find(
          (item: any) => item.tag === 'tp_follow_up'
        );
        const retireData = response?.data?.find(
          (item: any) => item.tag === 'tp_retire_approval'
        );
        if (followUpData) {
          setFollowUpData((prev) => ({
            ...prev,
            evaluationDate:
              followUpData?.status === 'Done'
                ? followUpData?.modifiedAtUtc
                : undefined,
            comment: followUpData?.selectedVerdictData
              ? JSON.parse(followUpData.selectedVerdictData)?.comment
              : undefined,
            decisionDueDate: followUpData?.dueDate,
          }));
        }

        if (retireData) {
          setFollowUpData((prev) => ({
            ...prev,
            approvalDueDate: retireData?.dueDate,
          }));
        }
      })
      .catch((_e) => {})
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (params.recordName) {
      setIsLoading(true);
      getFollowUpTaskInfo(params.recordName);
    }
  }, []);

  const covertTimeFormat = (time: string) => {
    const inputDate = new Date(time);
    const day = String(inputDate.getDate()).padStart(2, '0');
    const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // Month is zero-based, so we add 1
    const year = inputDate.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  };

  return (
    <FollowUpPageView
      {...{
        ...props,
        isLoading,
        followUpData,
        covertTimeFormat,
      }}
    />
  );
};
