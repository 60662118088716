import DisplayViewProvider from './display-view.context';
import { DisplayViewComponentProps } from './display-view.model';
import DisplayViewComponent from './display-view.view';

export const DisplayViewController = (props: DisplayViewComponentProps) => {
  const { isPreviewTab } = props;
  return (
    <DisplayViewProvider isPreviewTab={isPreviewTab}>
      <DisplayViewComponent {...props} />
    </DisplayViewProvider>
  );
};
