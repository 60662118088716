import {
  Button,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
} from '@fluentui/react-components';
import React from 'react';

import { Icon } from '../../shared';
import type { IPanelComponentProps } from './panel.model';
import { celitoPanelStyles } from './panel.styles';

export const PanelView: React.FC<IPanelComponentProps> = ({
  open,
  children,
  dataTestId,
  onClose,
  headerText,
  drawerBodyStyles,
  ...restProps
}) => {
  const styles = celitoPanelStyles();

  return (
    <Drawer
      {...restProps}
      open={open}
      data-testid={dataTestId}
      type={'overlay'}
      onOpenChange={onClose}
    >
      <DrawerHeader className={styles.drawerHeader}>
        <DrawerHeaderTitle
          data-testid={`${dataTestId}-header-title`}
          className={styles.drawerHeaderTitle}
          action={
            <Button
              appearance="subtle"
              aria-label="Close"
              icon={<Icon iconName="Dismiss24Regular" />}
              onClick={onClose && onClose}
              data-testid={`${dataTestId}-button-dismiss`}
            />
          }
        >
          <div className={styles.overflowEllipsis}>{headerText}</div>
        </DrawerHeaderTitle>
      </DrawerHeader>

      <DrawerBody style={drawerBodyStyles}>{children}</DrawerBody>
    </Drawer>
  );
};
