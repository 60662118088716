import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { Link } from 'react-router-dom';

import classes from '../related-course.module.css';

export const relatedCourseColumns = [
  {
    fieldName: 'docId',
    isResizable: true,
    key: 'column1',
    maxWidth: 250,
    minWidth: 150,
    name: 'Course ID',
    onRender: (item: RowItem) => (
      <Link
        to={`../view/course_detail_view__a/${item.key}?readonly=1`}
        className={classes.hyperlink}
        target="_blank"
      >
        {item.docId as string}
      </Link>
    ),
  },
  {
    name: 'Course Name',
    isResizable: true,
    key: 'column2',
    maxWidth: 350,
    minWidth: 150,
    fieldName: 'courseName',
  },
  {
    name: 'Version',
    isResizable: true,
    key: 'column3',
    maxWidth: 250,
    minWidth: 150,
    fieldName: 'version',
  },
  {
    name: 'Status',
    isResizable: true,
    key: 'column4',
    maxWidth: 250,
    minWidth: 150,
    fieldName: 'status',
  },
];
