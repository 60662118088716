import { LocalizationString } from '@celito.clients/assets';
import { Label } from '@fluentui/react-components';

import DisplayView from '../../view-components/display-view/display-view.component';
import { DisplayViewComponentProps } from '../../view-components/display-view/display-view.model';
import classes from './conditional-picker-view.module.css';

interface ConditionalPickerViewProps extends DisplayViewComponentProps {}

const Section = ({ value }: { value: string }) => {
  return (
    <Label className={classes.value}>
      {typeof value !== 'string'
        ? value
          ? LocalizationString.YES
          : LocalizationString.NO
        : value}
    </Label>
  );
};

export const ConditionalPickerView = (
  props: ConditionalPickerViewProps
): JSX.Element => {
  const { objectAttributeDefinition, attributeConfig, recordDetails, value } =
    props;

  const lookupAttributeName =
    objectAttributeDefinition?.childs![0].lookupAttributeName;
  const correctValue = objectAttributeDefinition?.childs![0].value;

  const childAttributeDefinition =
    attributeConfig.objectAttributeDefinitions.find(
      (i) => i.name === lookupAttributeName
    )!;
  const childValue = recordDetails[
    childAttributeDefinition.columnName
  ] as string;

  return (
    <div className={classes.container}>
      <Section value={value as string} />
      {value === correctValue && (
        <div className={classes.child}>
          <DisplayView
            {...props}
            dataType={childAttributeDefinition.dataType}
            dataTypeKeyForFE={childAttributeDefinition.dataTypeKeyForFE}
            objectAttributeDefinition={childAttributeDefinition}
            label={childAttributeDefinition.label}
            value={childValue}
          />
        </div>
      )}
    </div>
  );
};
