interface StringListProps {
  content: string[];
  as: 'div' | 'ol' | 'ul';
}

const StringList = ({ content, as }: StringListProps) => {
  const Wrapper = as;
  const Child = as === 'div' ? 'div' : 'li';

  return (
    <Wrapper>
      {content.map((item, index) => (
        <Child key={index}>{item}</Child>
      ))}
    </Wrapper>
  );
};

export default StringList;
