export enum OperatorsEnum {
  EQUALS = 'equals',
  NOT_EQUALS = 'notEquals',
  GREATER_THAN = 'greaterThan',
  LESS_THAN = 'lessThan',
  GREATER_THAN_EQUALS = 'greaterThanOrEqual',
  LESS_THAN_EQUALS = 'lessThanOrEqual',
  RANGE = 'range',
  IN = 'in',
  NOT_IN = 'notIn',
  CONTAINS = 'contains',
  NOT_CONTAINS = 'notContains',
  BEGINS_WITH = 'beginsWith',
  NOT_BEGINS_WITH = 'notBeginsWith',
  ENDS_WITH = 'endsWith',
  NOT_ENDS_WITH = 'notEndsWith',
  IS_BLANK = 'isBlank',
  IS_NOT_BLANK = 'isNotBlank',
  IN_LAST_DAYS = 'inLastDays',
  IN_LAST_WEEKS = 'inLastWeeks',
  IN_LAST_MONTHS = 'inLastMonths',
  IN_NEXT_DAYS = 'inNextDays',
  IN_NEXT_WEEKS = 'inNextWeeks',
  IN_NEXT_MONTHS = 'inNextMonths',
  IS = 'is',
  AFTER_ON_DAYS = 'afterOnDays',
  IN_PAST = 'inPast',
  IS_BIZ_ADMIN = 'isBizAdmin',
  IS_NOT_BIZ_ADMIN = 'isNotBizAdmin',
  IN_FUTURE = 'inFuture',
}
