import {
  FormEngineModeTypeEnum,
  QuizObjectAttributeNameEnum,
} from '@celito.clients/enums';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';

import { QuizPreviewPageProps } from './quiz-preview-page.model';
import { QuizPreviewPageView } from './quiz-preview-page.view';

interface QuizPreviewPageControllerProps extends QuizPreviewPageProps {}

export const QuizPreviewPageController = (
  props: QuizPreviewPageControllerProps
): JSX.Element => {
  const { getValues, setValue, watch } = useFormContext();

  const { goToPreviousStep } = props;

  const isNotValid = props.wizardStepsState.some(
    (wstep) => props.step !== wstep.number && !wstep.isValid
  );

  const setDataForPreviousPage = () => {
    const formValues = getValues?.();
    const questions = JSON.parse(
      formValues[QuizObjectAttributeNameEnum.QUESTIONS] ?? '[]'
    );
    if (questions.length !== 0) {
      setValue('currentQuestion', JSON.stringify(questions[0]));
      setValue('isEditMode', 'true');
    }
  };

  const changePage = (page: number) => {
    setDataForPreviousPage();
    props.onStepClick(page);
  };

  const onPreviousClicked = (ev: React.MouseEvent<HTMLButtonElement>) => {
    setDataForPreviousPage();
    goToPreviousStep(ev);
  };

  let recordDetails = props.recordDetails;
  if (
    props.mode === FormEngineModeTypeEnum.Create ||
    props.mode === FormEngineModeTypeEnum.Edit ||
    props.mode === FormEngineModeTypeEnum.Submit
  ) {
    const formValues = watch?.();
    recordDetails = {};
    props.attributeConfig.objectAttributeDefinitions?.forEach((item) => {
      recordDetails![item.columnName] = formValues?.[item.name];
    });
  }

  useEffect(() => {
    if (props.mode === FormEngineModeTypeEnum.View) {
      return;
    }

    if (!props.wizardStepsState[props.step].hasNavigated) {
      props.setWizardStepsState((prev) =>
        prev.map((step) =>
          step.number === props.step ? { ...step, hasNavigated: true } : step
        )
      );
    }

    return () => {
      props.setWizardStepsState((prev) =>
        prev.map((step) =>
          step.number === props.step
            ? {
                ...step,
                isValid: !isNotValid,
              }
            : step
        )
      );
    };
  }, [isNotValid]);

  return (
    <QuizPreviewPageView
      changePage={changePage}
      onPreviousClicked={onPreviousClicked}
      {...props}
      recordDetails={recordDetails}
    />
  );
};
