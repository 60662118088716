import { LocalizationString } from '@celito.clients/assets';
import {
  CustomDialogView,
  InHouseInputSelect,
  TextField,
} from '@celito.clients/shared';
import { returnSelectedOptionFromOptions } from 'libs/core/src/utils/getSelectedOptionFromOptions';
import { Controller } from 'react-hook-form';

import {
  CreateWorkflowModalViewProps,
  CreateWorkflowProps,
  executionTypeOptions,
  lifeCycleEventOptions,
} from './create-workflow-modal.model';
import classes from './create-workflow-modal.module.css';

const CreateWorkflowForm = (props: CreateWorkflowProps) => {
  const { watch } = props.methods;

  const executionType = watch('executionType');

  return (
    <div className={classes.workflow_modal} data-testId={props.dataTestId}>
      <div className={classes.top_div}>
        <div className={classes.controller_div}>
          <Controller
            control={props.control}
            name="label"
            rules={{ required: true, minLength: 5, maxLength: 128 }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                placeholder={LocalizationString.ENTER_WORFLOW_NAME_PLACEHOLDER}
                label={LocalizationString.WORKFLOW_NAME}
                labelText={LocalizationString.WORKFLOW_NAME}
                required={true}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                errorMessage={
                  props.errors.label
                    ? LocalizationString.WORKFLOW_LABEL_VALIDATION
                    : undefined
                }
                minLength={5}
                maxLength={128}
              />
            )}
          />
        </div>
        <div className={classes.controller_div}>
          <Controller
            control={props.control}
            name="objectName"
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <InHouseInputSelect
                selectedOptions={returnSelectedOptionFromOptions(
                  props.objectNameOptions,
                  value
                )}
                required
                placeholder={LocalizationString.SELECT_OBJECT_TYPE}
                label={LocalizationString.OBJECT}
                options={props.objectNameOptions}
                onOptionSelect={(event, option) => {
                  onChange(option.optionValue);
                }}
                errorMessage={
                  props.errors.objectName
                    ? LocalizationString.SELECT_OBJECT_VALIDATION
                    : undefined
                }
              />
            )}
          />
        </div>
        <div className={classes.controller_div}>
          <Controller
            control={props.control}
            name="description"
            rules={{ required: false, maxLength: 512 }}
            render={({ field: { onChange, onBlur, value } }) => (
              <TextField
                labelText="Description"
                placeholder={LocalizationString.WORKLOW_DESCRIPTION_PLACEHOLDER}
                label={LocalizationString.WORKFLOW_DESCRIPTION_LABEL}
                required={false}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
                errorMessage={
                  props.errors.description
                    ? LocalizationString.WORKFLOW_DESCRIPTION_VALIDATION
                    : undefined
                }
                size="large"
                maxLength={512}
              />
            )}
          />
        </div>
      </div>
      <div className={classes.top_div}>
        <div className={classes.controller_div}>
          <Controller
            control={props.control}
            name="executionType"
            rules={{ required: true }}
            render={({ field: { onChange, onBlur, value } }) => (
              <InHouseInputSelect
                selectedOptions={returnSelectedOptionFromOptions(
                  executionTypeOptions,
                  value
                )}
                required
                placeholder={LocalizationString.SELECT_EXECUTION_TYPE}
                label={LocalizationString.EXECUTION_TYPE}
                options={executionTypeOptions ?? []}
                onOptionSelect={(event, option) => {
                  onChange(option.optionValue);
                }}
                errorMessage={
                  props.errors.executionType
                    ? LocalizationString.SELECT_EXECUTION_VALIDATION
                    : undefined
                }
              />
            )}
          />
        </div>
        <div className={classes.controller_div}>
          {executionType === 'OBJECT-EVENT' && (
            <Controller
              control={props.control}
              name="lifeCycleEvent"
              rules={{ required: true }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <InHouseInputSelect
                  selectedOptions={returnSelectedOptionFromOptions(
                    lifeCycleEventOptions,
                    value
                  )}
                  required
                  placeholder={LocalizationString.SELECT_LIFECYCLE_EVENT}
                  label={LocalizationString.LIFECYCLE_EVENT}
                  errorMessage={
                    props.errors.lifeCycleEvent
                      ? LocalizationString.SELECT_LIFECYCLE_VALIDATION
                      : undefined
                  }
                  options={lifeCycleEventOptions ?? []}
                  onOptionSelect={(event, option) => {
                    onChange(option.optionValue);
                  }}
                />
              )}
            />
          )}
        </div>
        <div className={classes.controller_div}></div>
      </div>
    </div>
  );
};

export const CreateWorklowModalView = (props: CreateWorkflowModalViewProps) => {
  return (
    <CustomDialogView
      headerTitle={LocalizationString.WORKFLOW_HEADER}
      children={
        <CreateWorkflowForm
          control={props.control}
          errors={props.errors}
          objectNameOptions={props.objectNameOptions}
          methods={props.methods}
        />
      }
      showFooter
      cancelButtonTitle={LocalizationString.CANCEL}
      confirmButtonTitle={LocalizationString.CONTINUE}
      open={props.openWorkFlowModal}
      cancel={() => {
        props.reset();
        props.handleWorkflowModalOpen();
      }}
      isLoading={props?.isLoading}
      confirm={props.handleSubmit(props.onCreateWorkflowSubmit)}
    />
  );
};
