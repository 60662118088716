import { DateFormat } from '@celito.clients/enums';
import { Icon } from '@celito.clients/shared';
import { formatDate } from '@celito.clients/utils';
import {
  Card,
  CardFooter,
  CardHeader,
  Menu,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  ToolbarButton,
} from '@fluentui/react-components';

import { ResultCardProps } from '../search-result.model';
import classes from '../search-result.module.css';

const ResultCard = (props: ResultCardProps) => {
  const cardHeader = Object.values(props.result.cardHeaderContent).filter(
    (itm) => itm !== null
  );

  return (
    <Card className={classes.card} appearance="subtle">
      <CardHeader
        header={
          <div
            className={classes.card_header}
            role="button"
            onClick={() => {
              props.onViewSearchDoc(
                props.result.detailViewName ?? '',
                props.result.resultName ?? '',
                props.result.version ?? ''
              );
            }}
          >
            {cardHeader.join(' | ')}
          </div>
        }
        description={
          <div className={classes.card_description}>
            {props.result.moduleName}
          </div>
        }
        action={
          <Menu>
            <MenuTrigger>
              <ToolbarButton
                aria-label="More"
                icon={<Icon iconName="MoreHorizontal20Regular" />}
              />
            </MenuTrigger>

            <MenuPopover>
              <MenuList>
                <MenuItem
                  icon={<Icon iconName="Eye16Regular" />}
                  onClick={() => {
                    props.onViewSearchDoc(
                      props.result.detailViewName ?? '',
                      props.result.resultName ?? '',
                      props.result.version ?? ''
                    );
                  }}
                >
                  View
                </MenuItem>
              </MenuList>
            </MenuPopover>
          </Menu>
        }
      />

      <div className={classes.card_content}>
        {Object.keys(props.result.cardBodyContent).map((key) => (
          <p key={key}>
            {key} : {`${props.result.cardBodyContent[key]}`}
          </p>
        ))}
      </div>

      <CardFooter className={classes.card_footer}>
        {props.result.modifiedAtDate &&
          formatDate(props.result.modifiedAtDate, DateFormat.Date)}
      </CardFooter>
    </Card>
  );
};

export default ResultCard;
