import { Button, mergeClasses } from '@fluentui/react-components';

import { IconButtonProps } from './icon-button.model';
import { iconButtonStlyes } from './icon-button.styles';

export const IconButtonView = ({
  icon,
  disabled,
  className,
  ...props
}: IconButtonProps) => {
  const styles = iconButtonStlyes();

  return (
    <Button
      {...props}
      disabled={disabled}
      icon={icon}
      className={mergeClasses(
        styles.btn,
        disabled && styles.disabled,
        className
      )}
    ></Button>
  );
};
