import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledReferenceSelectorProps } from './controlled-reference-selector.model';
import { ControlledReferencePickerView } from './controlled-reference-selector.view';

export const ControlledReferenceSelectorController = <T extends FieldValues>(
  props: ControlledReferenceSelectorProps<T>
) => {
  return <ControlledReferencePickerView {...props} />;
};
