import { useActiveModule, useLayout } from '@celito.clients/hooks';
import { errorToast, raiseErrorToast } from '@celito.clients/utils';
import { MenuProps } from '@fluentui/react-components';
import { removeSpecialCharacterFromSearchQuery } from 'apps/web-client/src/utils/remove-special-character';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import {
  allApplicationsRegex,
  resultCountRegex,
  resultModuleRegex,
} from './consts/constants';
import {
  FacetObject,
  ResultCardObject,
  SelectedFacetsObject,
  SelectedSortObject,
  ViewType,
} from './search-result.model';
import SearchResultView from './search-result.view';
import {
  getAdvancedSearchResults,
  IAdvancedSearch,
  IFactStatus,
} from './services/apiServices';
import { AdvancedSearchPayload } from './services/apiServices.model';
import {
  getFaucetListFromApiResponse,
  getQuickFiltersFromApiResponse,
  getResultCardDataFromApiResponse,
} from './utils/api-service.util';
import {
  getCurrentModule,
  getDetailsViewUrl,
  getSelectedQuickFilterBasedOnApiResponse,
} from './utils/search-result.util';

const CARD_PAGE_LIMIT = 20;
const TILE_PAGE_LIMIT = 10;
const initialSearchWithinValue = 'Metadata';

const SearchResultController: React.FC = () => {
  const isInitialRenderRefForPreventingFacetRerender = useRef(true);
  const isInitialRenderRefForPreventingSortRerender = useRef(true);
  const isNewFacetGenerated = useRef(false);
  const isSortFacetRegenerated = useRef(false);

  const { configureLayout } = useLayout();
  const navigate = useNavigate();
  const activeModule = useActiveModule();
  const [searchParams, setSearchParams] = useSearchParams();

  const [searchValue, setSearchValue] = useState<string>(
    searchParams.get('value') ?? ''
  );
  const [facetsList, setFacetsList] = useState<FacetObject[]>([]);
  const [quickFilters, setQuickFilters] = useState<string[]>([]);
  const [selectedFacets, setSelectedFacets] = useState<SelectedFacetsObject[]>(
    []
  );
  const [apiResponse, setApiResponse] = useState<IAdvancedSearch>();
  const [selectedQuickFilter, setSelectedQuickFilter] = useState<string | null>(
    null
  );
  const [selectedViewType, setSelectedViewType] = useState<ViewType>({
    view: ['card'],
  });
  const [resultCount, setResultCount] = useState<number>(-1);
  const [resultCountInTab, setResultCountInTab] = useState<number>(-1);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [searchResult, setSearchResult] = useState<ResultCardObject[]>([]);
  const [isRefineSearchDisabled, setIsRefineSearchDisabled] =
    useState<boolean>(true);
  const [enteredSearchValue, setEnteredSearchValue] = useState<string>(
    searchParams.get('value') || ''
  );
  const [selectedSortOptions, setSelectedSortOptions] = useState<
    SelectedSortObject[]
  >([]);
  const [isPageLoading, setIsPageLoading] = useState<boolean>(false);
  const [isCardLoading, setIsCardLoading] = useState<boolean>(false);
  const [searchWithinFilterValue, setSearchWithinFilterValue] =
    useState<string>(initialSearchWithinValue);

  const PAGE_LIMIT =
    selectedViewType.view[0] === 'card' ? CARD_PAGE_LIMIT : TILE_PAGE_LIMIT;
  const currentModule = getCurrentModule(activeModule?.systemName);
  const isFileContentInclude =
    searchWithinFilterValue !== initialSearchWithinValue;

  const striginifiedSearch = JSON.stringify(searchParams.get('value') ?? '');
  useEffect(() => {
    if (
      striginifiedSearch &&
      JSON.parse(striginifiedSearch).length > 2 &&
      !isNewFacetGenerated.current &&
      !isSortFacetRegenerated.current &&
      !isInitialRenderRefForPreventingFacetRerender.current &&
      !isInitialRenderRefForPreventingSortRerender.current
    ) {
      onSearch(JSON.parse(striginifiedSearch));
      setSearchValue(JSON.parse(striginifiedSearch));
    }
  }, [striginifiedSearch]);

  useEffect(() => {
    configureLayout({
      pageTitle: '',
      headerTitle: 'Search Results',
    });
  }, []);

  useEffect(() => {
    if (isInitialRenderRefForPreventingFacetRerender.current) {
      onSearch();
      isInitialRenderRefForPreventingFacetRerender.current = false;
      return;
    }
    if (isNewFacetGenerated.current) {
      isNewFacetGenerated.current = false;
      return;
    }

    if (
      !isNewFacetGenerated.current &&
      !isInitialRenderRefForPreventingFacetRerender.current &&
      searchValue.length > 2
    ) {
      updateResultsOnFacetUpdation();
    }
  }, [selectedFacets, searchWithinFilterValue]);

  useEffect(() => {
    if (isInitialRenderRefForPreventingSortRerender.current) {
      isInitialRenderRefForPreventingSortRerender.current = false;
      return;
    }
    if (isSortFacetRegenerated.current) {
      isSortFacetRegenerated.current = false;
      return;
    }
    if (
      !isSortFacetRegenerated.current &&
      !isInitialRenderRefForPreventingSortRerender.current &&
      searchValue.length > 2
    ) {
      updateResultsOnSortUpdation();
    }
  }, [selectedSortOptions]);

  const facetToQueryStringHandler = (facets: SelectedFacetsObject[]) => {
    const createFacetQueryString: any = {};
    facets.forEach((state: SelectedFacetsObject) => {
      if (state?.values) {
        createFacetQueryString[`${state.columnKey}`] = state?.values;
      }
    });
    return createFacetQueryString;
  };

  useEffect(() => {
    if (apiResponse) {
      const newFacetList = getFaucetListFromApiResponse(
        apiResponse,
        selectedQuickFilter
      );

      setFacetsList(newFacetList);
    }
  }, [apiResponse, selectedQuickFilter]);

  const onSearch = (searchData?: string) => {
    const newFacetsList: FacetObject[] = [];
    let newSearchResult: ResultCardObject[] = [];
    let newResultCount = -1;
    let newQuickFilters: string[] = [];
    const newSelectedFacets: SelectedFacetsObject[] = [];
    const newCurrentPage = 0;
    const newSelectedSortOptions: SelectedSortObject[] = [];
    const queryValue = searchData ?? searchValue;

    const params: [string, string][] = [];

    for (const entry of searchParams.entries()) {
      params.push(entry);
    }

    params.shift();

    const tempSelectedfacets: any = {};
    params.forEach((param: [string, string]) => {
      const [columnKey, module] = param[0].split('_');
      const values = param[1].split(',');

      if (tempSelectedfacets[columnKey]) {
        const tempValues: string[] = [...tempSelectedfacets[columnKey].values];
        tempValues.push(values[0]);
        tempSelectedfacets[columnKey] = {
          columnKey,
          module,
          type: tempSelectedfacets[columnKey].dataType,
          values: tempValues,
        };
      } else {
        const payload: AdvancedSearchPayload = {
          query: removeSpecialCharacterFromSearchQuery(queryValue),
          // size: PAGE_LIMIT,
          size: 1000,
          from: 0,
          sorting: [],
          refiners: [],
          module: currentModule,
          isFileContentInclude: isFileContentInclude,
        };

        getAdvancedSearchResults(payload)
          .then((res) => {
            const findDataType = (key: string): string | null => {
              for (const section in res.Fact) {
                const sectionArray = res.Fact[section];
                for (const item of sectionArray as unknown as IFactStatus[]) {
                  if (item.key === key) {
                    return item.dataType;
                  }
                }
              }
              return null;
            };

            tempSelectedfacets[columnKey] = {
              columnKey,
              module,
              type: findDataType(columnKey),
              values,
            };
          })
          .catch((_error) => {
            raiseErrorToast(_error);
          });
      }
    });
    const selectedFacetsFromQuery = searchData
      ? []
      : Object.keys(tempSelectedfacets).map((facet: string) => {
          return tempSelectedfacets[facet];
        });

    const addFacetsToQuery = facetToQueryStringHandler(selectedFacetsFromQuery);

    const payload: AdvancedSearchPayload = {
      query: removeSpecialCharacterFromSearchQuery(queryValue),
      // size: PAGE_LIMIT,
      size: 1000,
      from: 0,
      sorting: [],
      refiners: selectedFacetsFromQuery,
      module: currentModule,
      isFileContentInclude: isFileContentInclude,
    };

    setIsPageLoading(true);
    isNewFacetGenerated.current = true;
    isSortFacetRegenerated.current = true;
    setSearchParams({ value: queryValue, ...addFacetsToQuery });

    if (queryValue.length > 2) {
      getAdvancedSearchResults(payload)
        .then((res) => {
          setApiResponse(res);

          newResultCount = res.result?.[0]?.resultCount ?? 0;
          newSearchResult = getResultCardDataFromApiResponse(res);
          newQuickFilters = getQuickFiltersFromApiResponse(res);

          setFacetsList(newFacetsList);
          setSelectedSortOptions(newSelectedSortOptions);

          setResultCount(newResultCount);
          setResultCountInTab(newResultCount);
          setSearchResult(newSearchResult);
          setQuickFilters(newQuickFilters);
          setSelectedQuickFilter(newQuickFilters[0]);
          setCurrentPage(newCurrentPage);
          setIsRefineSearchDisabled(false);
          setSelectedFacets(newSelectedFacets);
          setEnteredSearchValue(queryValue);
          setSelectedFacets(selectedFacetsFromQuery);
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        })
        .finally(() => setIsPageLoading(false));
    } else {
      setIsPageLoading(false);
    }
  };

  const updateResultsOnFacetUpdation = () => {
    let newSearchResult: ResultCardObject[] = [];
    let newResultCount = -1;
    let newQuickFilters: string[] = [];
    let newSelectedQuickFilter: string =
      selectedQuickFilter?.replace(resultModuleRegex, '') || '';
    const newCurrentPage = 0;

    const payload: AdvancedSearchPayload = {
      query: removeSpecialCharacterFromSearchQuery(searchValue),
      // size: PAGE_LIMIT,
      size: 1000,
      from: 0,
      sorting: selectedSortOptions,
      refiners: selectedFacets.filter(
        (selected) => selected.values.length !== 0
      ),
      module: currentModule,
      isFileContentInclude: isFileContentInclude,
    };

    setIsPageLoading(true);

    getAdvancedSearchResults(payload)
      .then((res) => {
        newResultCount = res?.result?.[0]?.resultCount ?? 0;
        newSearchResult = getResultCardDataFromApiResponse(res);
        newQuickFilters = getQuickFiltersFromApiResponse(res);
        newSelectedQuickFilter = getSelectedQuickFilterBasedOnApiResponse(
          newSelectedQuickFilter,
          newQuickFilters
        );

        setResultCount(newResultCount);
        setResultCountInTab(newResultCount);
        setSearchResult(newSearchResult);
        setQuickFilters(newQuickFilters);
        setSelectedQuickFilter(newSelectedQuickFilter);
        setCurrentPage(newCurrentPage);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => setIsPageLoading(false));
  };

  const updateResultsOnSortUpdation = () => {
    let newSearchResult: ResultCardObject[] = [];
    const newCurrentPage = 0;

    const payload: AdvancedSearchPayload = {
      query: removeSpecialCharacterFromSearchQuery(searchValue),
      // size: PAGE_LIMIT,
      size: 1000,
      from: 0,
      sorting: selectedSortOptions,
      refiners: selectedFacets.filter(
        (selected) => selected.values.length !== 0
      ),
      module: currentModule,
      isFileContentInclude: isFileContentInclude,
    };

    setIsCardLoading(true);

    getAdvancedSearchResults(payload)
      .then((res) => {
        newSearchResult = getResultCardDataFromApiResponse(res);

        setSearchResult(newSearchResult);
        setCurrentPage(newCurrentPage);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => setIsCardLoading(false));
  };

  const onSearchInputChangeHandler = (data: string) => {
    setSearchValue(data);
  };

  const onClearSearchHandler = () => {
    setSearchValue('');
    setResultCount(-1);
    setResultCountInTab(-1);
    setSearchResult([]);
    setQuickFilters([]);
    setSelectedQuickFilter(null);
    setCurrentPage(0);
    setFacetsList([]);
    setSelectedFacets([]);
    setIsRefineSearchDisabled(true);
    setEnteredSearchValue('');
    setSelectedSortOptions([]);
    setSearchParams({});
  };

  const onClearFilterHandler = () => {
    setSelectedFacets([]);
    setSearchParams({ value: enteredSearchValue });
  };

  const onQuickFilterChangeHandler = (tabId: string) => {
    setSelectedQuickFilter(tabId);
    setCurrentPage(0);

    if (allApplicationsRegex.test(tabId)) {
      setResultCountInTab(resultCount);
    } else {
      const match = tabId.match(resultCountRegex);
      setResultCountInTab(match ? Number(match[1]) : -1);
    }
  };

  const setSelectedViewTypeHandler: MenuProps['onCheckedValueChange'] = (
    e,
    { name, checkedItems }
  ) => {
    setSelectedViewType((prevState) => ({
      ...prevState,
      [name]: checkedItems,
    }));
  };

  const onFilterChipDismissHandler = (
    facetToRemove: string,
    indexOfFacetKey: number
  ) => {
    setSelectedFacets((prevState) => {
      prevState[indexOfFacetKey].values = prevState[
        indexOfFacetKey
      ].values.filter((item) => item !== facetToRemove);
      const createFacetQueryString: any = {};
      prevState.forEach((state: SelectedFacetsObject) => {
        const values = state?.values?.join(',');
        if (values) {
          createFacetQueryString[`${state.columnKey}`] = values;
        }
      });
      setSearchParams({ value: enteredSearchValue, ...createFacetQueryString });
      return [...prevState];
    });
  };

  const onFacetUpdationHandler = (
    option: string,
    key: string,
    type: string
  ) => {
    setSelectedFacets((prevState) => {
      const indexOfKeyIfItExists = prevState.findIndex(
        (facetObj) => facetObj.columnKey === key
      );

      if (indexOfKeyIfItExists === -1) {
        const newObject: SelectedFacetsObject = {
          columnKey: key,
          values: [option],
          type: type,
        };
        const createFacetQueryString: any = {};
        prevState.forEach((state: SelectedFacetsObject) => {
          if (state?.values) {
            createFacetQueryString[`${state.columnKey}`] = state?.values;
          }
        });
        createFacetQueryString[`${newObject.columnKey}`] = newObject.values[0];
        setSearchParams({
          value: enteredSearchValue,
          ...createFacetQueryString,
        });
        return [...prevState, newObject];
      }

      const isOptionAlreadySelected =
        prevState[indexOfKeyIfItExists].values.includes(option);

      if (isOptionAlreadySelected) {
        prevState[indexOfKeyIfItExists].values = prevState[
          indexOfKeyIfItExists
        ].values.filter((facetObj) => facetObj !== option);
      } else {
        prevState[indexOfKeyIfItExists].values.push(option);
      }
      const createFacetQueryString: any = {};
      prevState.forEach((state: SelectedFacetsObject) => {
        const values = state?.values?.join(',');
        if (values) {
          createFacetQueryString[`${state.columnKey}`] = values;
        }
      });
      setSearchParams({ value: enteredSearchValue, ...createFacetQueryString });
      return [...prevState];
    });
  };

  const onSortWithinUpdationHandler = (key: string) => {
    setSearchWithinFilterValue(key);
  };

  const onSortUpdationHandler = (column: string, isAscending: boolean) => {
    if (column.length > 0) {
      setSelectedSortOptions([
        {
          column: column,
          isAscending: isAscending,
        },
      ]);
    } else {
      setSelectedSortOptions([]);
    }
  };

  const onSearchCardClick = (
    detailViewName: string,
    recordName: string,
    version: string
  ) => {
    if (detailViewName) {
      const url = getDetailsViewUrl(detailViewName, recordName, version);
      navigate(url);
    } else {
      errorToast({
        message: `Unable to display ${recordName}`,
      });
    }
  };

  return (
    <SearchResultView
      searchValue={searchValue}
      resultCount={resultCount}
      onSearchInputChange={onSearchInputChangeHandler}
      onSearch={onSearch}
      onClearSearch={onClearSearchHandler}
      facetsList={facetsList}
      selectedFacets={selectedFacets}
      onFacetUpdationHandler={onFacetUpdationHandler}
      quickFilters={quickFilters}
      selectedQuickFilter={selectedQuickFilter}
      setSelectedQuickFilter={onQuickFilterChangeHandler}
      selectedViewType={selectedViewType}
      setSelectedViewTypeHandler={setSelectedViewTypeHandler}
      pageLimit={PAGE_LIMIT}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      searchResult={searchResult}
      onFilterChipDismissHandler={onFilterChipDismissHandler}
      onClearFilter={onClearFilterHandler}
      isRefineSearchDisabled={isRefineSearchDisabled}
      resultCountInTab={resultCountInTab}
      enteredSearchValue={enteredSearchValue}
      onSortUpdationHandler={onSortUpdationHandler}
      selectedSortOptions={selectedSortOptions}
      onSearchCardClick={onSearchCardClick}
      isPageLoading={isPageLoading}
      isCardLoading={isCardLoading}
      searchWithinFilterValue={searchWithinFilterValue}
      onSortWithinUpdationHandler={onSortWithinUpdationHandler}
    />
  );
};

export default SearchResultController;
