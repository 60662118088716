import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, DateFormat } from '@celito.clients/enums';
import {
  Container,
  CustomButton,
  Field,
  InHouseInputSelect,
  Stack,
  StackItem,
  SwappableSelect,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { formatDate } from '@celito.clients/utils';
import { Input, mergeClasses, Text } from '@fluentui/react-components';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React from 'react';
import { Controller } from 'react-hook-form';

import { returnSelectedOptionFromOptions } from '../../../Platform/admin-ui-screen/utils/utils';
import { EditGroupModel } from './edit-group.model';
import classes from './edit-group.module.css';
import { editGroupStyles } from './edit-group.styles';

const Row: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <Stack className={classes.row}>{children}</Stack>;
};

const Column: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <StackItem className={classes.column}>{children}</StackItem>;
};

const DisplayItem: React.FC<{ label: string; value?: number | string }> = ({
  label,
  value = '',
}) => {
  return (
    <Stack>
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.value}>{value}</Text>
    </Stack>
  );
};

const EditGroupView: React.FC<EditGroupModel> = ({
  onSubmit,
  control,
  onOptionSelect,
  options,
  isLoading,
  list,
  rightItemList,
  setList,
  setRightItemList,
  isPermissionGrpUpdated,
  permissionGrpData,
  handleSubmit,
  selectedList,
  navigateBack,
  navigateBackRoute,
  setSelectedList,
}) => {
  const { cssVariables } = useTheme();

  const styles = editGroupStyles();

  const onFormSubmit = (e: React.BaseSyntheticEvent) => {
    e.preventDefault();
    handleSubmit(onSubmit);
  };

  const statusOptions: IOption[] = [
    { text: 'Yes', value: 'yes' },
    { text: 'No', value: 'no' },
  ];

  return (
    <Container
      style={{ ...cssVariables }}
      showBackBtn
      navigateBackRoute={navigateBackRoute}
      name={permissionGrpData?.label}
      className={styles.container}
    >
      <form onSubmit={onFormSubmit} noValidate>
        <Row>
          <Column>
            <Controller
              name={'label'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Group Name"
                  size="large"
                  required
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <Input
                    className={mergeClasses(
                      styles.textField,
                      error && styles.textFieldError
                    )}
                    data-testid="input-group-name-edit-group"
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    aria-errormessage={error && error.message}
                  />
                </Field>
              )}
            />
          </Column>
          <Column>
            <Controller
              name={'description'}
              control={control}
              render={({
                field: { onChange, onBlur, name: fieldName, value },
                fieldState: { error },
              }) => (
                <Field
                  className={styles.field}
                  label="Description"
                  size="large"
                  validationMessage={error?.message}
                  validationState={error?.message ? 'error' : 'none'}
                >
                  <Input
                    className={mergeClasses(
                      styles.textField,
                      error && styles.textFieldError
                    )}
                    data-testid="input-group-description-edit-group"
                    onChange={onChange}
                    value={value}
                    onBlur={onBlur}
                    name={fieldName}
                    aria-errormessage={error && error.message}
                  />
                </Field>
              )}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <Controller
              name={'roleNames'}
              control={control}
              render={({ field: { onBlur, value }, fieldState: { error } }) => {
                return (
                  <InHouseInputSelect
                    data-testid="select-group-role-edit-group"
                    label="Roles"
                    options={options}
                    selectedOptions={returnSelectedOptionFromOptions(
                      options,
                      value
                    )}
                    required
                    placeholder={'Select roles'}
                    onOptionSelect={onOptionSelect}
                    aria-errormessage={error && error.message}
                  />
                );
              }}
            />
          </Column>
          <Column>
            <Controller
              name={'isActive'}
              control={control}
              render={({
                field: { onBlur, value, onChange },
                fieldState: { error },
              }) => (
                <InHouseInputSelect
                  label={LocalizationString.ACTIVE}
                  data-testid="select-group-status-edit-group"
                  options={statusOptions}
                  selectedOptions={returnSelectedOptionFromOptions(
                    statusOptions,
                    value
                  )}
                  onOptionSelect={(_e, option) => {
                    onChange(option?.optionValue);
                  }}
                  errorMessage={error && error.message}
                />
              )}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem
              label="Created By"
              value={permissionGrpData?.createdBy.label}
            />
          </Column>
          <Column>
            <DisplayItem
              label="Created Date"
              value={
                permissionGrpData?.createdAtUtc
                  ? formatDate(
                      permissionGrpData.createdAtUtc,
                      DateFormat.DateTime
                    )
                  : ''
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem
              label="Modified By"
              value={permissionGrpData?.modifiedBy.label}
            />
          </Column>
          <Column>
            <DisplayItem
              label="Modified Date"
              value={
                permissionGrpData?.modifiedAtUtc
                  ? formatDate(
                      permissionGrpData.modifiedAtUtc,
                      DateFormat.DateTime
                    )
                  : ''
              }
            />
          </Column>
        </Row>
        <Row>
          {!isLoading ? (
            <SwappableSelect
              list={list}
              setList={setList}
              setRightItemList={setRightItemList}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              rightItemList={rightItemList}
              leftLabel="Available Members"
              rightLabel="Selected Members"
            />
          ) : (
            <div />
          )}
        </Row>
      </form>
      <div className={classes.footer}>
        <CustomButton
          customStyles={classes.btn_container}
          buttonType={ButtonTypes.Ghost}
          buttonTitle="Cancel"
          onButtonClicked={navigateBack}
        />
        <CustomButton
          customStyles={classes.btn_container}
          leftIcon="Save"
          buttonType={ButtonTypes.Primary}
          buttonTitle={'Save'}
          isLoading={isPermissionGrpUpdated}
          onSubmit={onSubmit}
        />
      </div>
    </Container>
  );
};

export default EditGroupView;
