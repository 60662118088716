export const createTestAttribute = (field: string | undefined) => {
  if (field && typeof field === 'string') {
    return field?.toLowerCase().replace(/ /g, '-');
  }
  return '';
};

// eslint-disable-next-line @typescript-eslint/naming-convention
export const TEST_ATTRIBUTE_REGEX = /\((.*?)\)/;

export const findDataTestIdFromField = (field: string): string | undefined => {
  return field.includes('(')
    ? TEST_ATTRIBUTE_REGEX.exec(createTestAttribute(field))?.[1]
    : createTestAttribute(field);
};
