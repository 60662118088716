import {
  ApiMethodType,
  ObjectEnum,
  SortOrderEnum,
} from '@celito.clients/enums';
import {
  Datum,
  ObjectData,
  SortConfig,
} from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import { getModuleUsers } from '@celito.clients/services';
import { FilterAsConditions, ParamsType } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';

const SORT_ATTRIBUTE_COLUMN_NAME = 'modifiedAtUtc';

export const getObjects = async <T = Datum>(
  objectName: string,
  modules: string[],
  params?: ParamsType,
  filters?: FilterAsConditions[],
  signal?: AbortSignal,
  sortConfig?: SortConfig | undefined | '',
  filterByExactRoles?: boolean
): Promise<Datum> => {
  const requestBody: Record<string, any> = {
    filters,
  };

  if (sortConfig !== '') {
    requestBody.sort = sortConfig ?? {
      attribute: SORT_ATTRIBUTE_COLUMN_NAME,
      order: SortOrderEnum.DESC,
    };
  }

  if (objectName === ObjectEnum.USER) {
    const response = await getModuleUsers<T>(
      requestBody,
      {
        modules,
        filterByExactRoles,
      },
      {},
      signal
    );

    return { objects: response.data.data, total: response.data.total };
  }

  const response = await performRequest<ObjectData<T>>(
    `${Endpoints.OBJECT_DATA_URL}/${objectName}`,
    ApiMethodType.POST,
    requestBody,
    params,
    undefined,
    signal
  );

  return { objects: response.data.data, total: response.data.total };
};
