import { LocalizationString } from '@celito.clients/assets';
import {
  getMaxWidthForColumn,
  getMinWidthForColumn,
  getRowValueFromDataType,
  ObjectData,
} from '@celito.clients/list-view-engine';
import { AttributeTypeEnum } from 'libs/form-engine/src/lib/enums/attributes-enum';

import { ColumnData, RowItem } from '../../../grid-view-new/src/types';
import { UserNameColumn } from '../components';
import { User } from '../types';

export const getUsersListViewColumnConfig = (
  onUserView: (name: string) => void
): ColumnData[] => {
  return [
    {
      key: 'userName',
      minWidth: getMinWidthForColumn(AttributeTypeEnum.Email),
      maxWidth: getMaxWidthForColumn(AttributeTypeEnum.Email),
      name: LocalizationString.USER_NAME,
      fieldName: 'userName',
      isResizable: true,
      onRender: (item, index, column) =>
        UserNameColumn({ item, index, column, onUserView }),
    },
    {
      key: 'department',
      minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
      maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
      name: LocalizationString.DEPARTMENT,
      fieldName: 'department',
      isResizable: true,
    },
    {
      key: 'location',
      minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
      maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
      name: LocalizationString.LOCATION,
      fieldName: 'location',
      isResizable: true,
    },
    {
      key: 'lastModifiedBy',
      minWidth: getMinWidthForColumn(AttributeTypeEnum.PlainText),
      maxWidth: getMaxWidthForColumn(AttributeTypeEnum.PlainText),
      name: LocalizationString.LAST_MODIFIED_BY,
      fieldName: 'lastModifiedBy',
      isResizable: true,
    },
    {
      key: 'lastModifiedDate',
      minWidth: getMinWidthForColumn(AttributeTypeEnum.Date),
      maxWidth: getMaxWidthForColumn(AttributeTypeEnum.Date),
      name: LocalizationString.LAST_MODIFIED_DATE,
      fieldName: 'lastModifiedDate',
      isResizable: true,
      disableAlphanumericSorting: true,
    },
  ];
};

export const getUsersListViewRowData = (
  objectData: ObjectData<User>
): RowItem[] => {
  return objectData.data.map((item) => ({
    key: item.username,
    name: item.name,
    userName: getRowValueFromDataType(AttributeTypeEnum.PlainText, item.label),
    department: getRowValueFromDataType(
      AttributeTypeEnum.Reference,
      item.department
    ),
    location: getRowValueFromDataType(
      AttributeTypeEnum.PlainText,
      item.country
    ),
    lastModifiedBy: getRowValueFromDataType(
      AttributeTypeEnum.User,
      item.modifiedBy
    ),
    lastModifiedDate: getRowValueFromDataType(
      AttributeTypeEnum.Date,
      item.modifiedAtUtc
    ),
  }));
};
