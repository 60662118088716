import { LocalizationString } from '@celito.clients/assets';

import { TextField } from '../../shared';
import { NumberRangePickerProps } from './number-range-picker.model';
import classes from './number-range-picker.styles.module.css';

interface NumberRangePickerViewProps extends NumberRangePickerProps {}

const NumberRangePickerView = ({
  dataTestId,
  value,
  onChange,
  ...textFieldProps
}: NumberRangePickerViewProps): JSX.Element => {
  return (
    <div data-testid={dataTestId} className={classes.container}>
      <TextField
        type="number"
        value={String(value.start)}
        label={LocalizationString.FROM}
        onChange={(_e, newValue) => {
          if (value) {
            onChange?.({
              ...value,
              start: Number(newValue),
            });
          }
        }}
        {...textFieldProps}
      />
      <TextField
        type="number"
        value={value.end as unknown as string}
        label={LocalizationString.TO}
        onChange={(_e, newValue) => {
          if (value) {
            onChange?.({
              ...value,
              end: Number(newValue),
            });
          }
        }}
        {...textFieldProps}
      />
    </div>
  );
};

export default NumberRangePickerView;
