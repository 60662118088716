import { LocalizationString } from '@celito.clients/assets';
import { ReactNode } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { showToast } from '../../providers/toast-provider';
import { ToasterOptions } from '../../providers/toast-provider/types';

export type ToastContent = { title?: string; message: ReactNode };

export const errorToast = (
  { title, message }: ToastContent,
  options: ToasterOptions = {}
) => {
  return showToast('error', title ?? LocalizationString.ERROR, message, {
    toastId: options?.toastId ?? options?.toastId ?? uuidv4(),
    ...options,
  });
};

export const successToast = (
  { title, message }: ToastContent,
  options?: ToasterOptions
) => {
  return showToast('success', title ?? LocalizationString.SUCCESS, message, {
    toastId: options?.toastId ?? uuidv4(),
    ...options,
  });
};

export const infoToast = (
  { title, message }: ToastContent,
  options?: ToasterOptions
) => {
  return showToast('info', title ?? LocalizationString.INFO, message, {
    toastId: options?.toastId ?? uuidv4(),
    ...options,
  });
};

export const warningToast = (
  { title, message }: ToastContent,
  options?: ToasterOptions
) => {
  return showToast('warning', title ?? LocalizationString.WARNING, message, {
    toastId: options?.toastId ?? uuidv4(),
    ...options,
  });
};
