import { HeaderActionButtonsContainerProps } from './header-action-buttons-container.model';
import HeaderActionButtonsContainerView from './header-action-buttons-container.view';

interface HeaderActionButtonsContainerControllerProps
  extends HeaderActionButtonsContainerProps {}

const HeaderActionButtonsContainerController = (
  props: HeaderActionButtonsContainerControllerProps
): JSX.Element => {
  return <HeaderActionButtonsContainerView {...props} />;
};

export default HeaderActionButtonsContainerController;
