import { constant, isFunction } from 'lodash';
import { createElement, forwardRef, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';

import { Field, IFormProps, TAttributeDefinition } from './field';
import { withRowSupport } from './hoc/with-row-support';
import useForm from './use-form';
import { isJSX } from './utils';

/**
 * Form Engine
 * @param props - Form configuration params {@link IFormProps}
 * @todo Add usage example
 * @todo add optional layout rules support
 * @todo add a readonly param
 * @todo Better default actions
 *
 * @returns Form container
 */
export const Form = Object.assign(
  forwardRef<HTMLFormElement, IFormProps>(function (props, ref) {
    const {
      attributes,
      enableLayoutRules,
      formProps,
      form: formRef,
      data: record = {},
      onSubmit,
      readonly = false,
      children,
      renderAttributes,
      submitButtonRef,
      formInstance: parentFormInstance,
      setValidationSchema,
      objectValidationRules,
      objectAttributeDef,
      ...rest
    } = props;

    const attributeDefinitions = useMemo(() => {
      return attributes.filter(
        (attr): attr is TAttributeDefinition =>
          !isFunction(attr) && !isJSX(attr)
      );
    }, [attributes]);

    const { form: localFormInstance, fieldsState } = useForm(
      attributeDefinitions,
      formProps,
      enableLayoutRules,
      parentFormInstance,
      setValidationSchema,
      objectValidationRules,
      objectAttributeDef
    );

    const formInstance = parentFormInstance ?? localFormInstance;

    if (formRef?.current === null) formRef.current = formInstance;

    return (
      <FormProvider {...formInstance}>
        <form
          onSubmit={formInstance.handleSubmit(onSubmit ?? constant(null))}
          noValidate
          {...rest}
        >
          {renderAttributes?.(attributeDefinitions, fieldsState, record) ??
            attributes.map((attribute) => {
              if (isJSX(attribute)) return attribute;
              if (isFunction(attribute)) return createElement(attribute);
              return (
                <Field
                  key={attribute.columnName}
                  readonly={readonly}
                  recordDetails={record}
                  attributeDefinition={attribute}
                  fieldState={fieldsState[attribute.name]}
                />
              );
            })}
          {children ?? (
            <button
              type="submit"
              style={{ display: 'none' }}
              ref={submitButtonRef}
            >
              submit
            </button>
          )}
        </form>
      </FormProvider>
    );
  }),
  { Field }
);

export default Form;

/**
 * Form with Row Support @see {@link Form}
 */
export const FormWithRowSupport = withRowSupport(Form);
