import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import {
  ConfirmDialog,
  CustomButton,
  Icon,
  InHouseInputSelect,
} from '@celito.clients/shared';
import { AdUserTypes } from '@celito.clients/types';
import {
  Button,
  Dialog,
  DialogSurface,
  SelectionEvents,
  Spinner,
} from '@fluentui/react-components';
import {
  IChangeData,
  IOption,
} from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { Controller, UseFormReturn } from 'react-hook-form';

import { returnSelectedOptionFromOptions } from '../../../Platform/admin-ui-screen/utils/utils';
import { IFormData, PreUserAddProps } from './pre-user-add.model';
import { PreUserAddStyles } from './pre-user-add.styles';

interface PreUserAddViewProps extends PreUserAddProps {
  methods: UseFormReturn<IFormData>;
  isLoading: boolean;
  onSubmit: (data: IFormData) => void;
  isDepartmentLoading: boolean;
  departmentOptions: IOption[];
  isUserLoading: boolean;
  userOptions: IOption[];
  isUserAddLoading: boolean;
  isCancelModalOpen: boolean;
  handleCancelModal: () => void;
  handleConfirmCancelDialog: () => void;
  isLoadingAllAdUsers: boolean;
  allAdUsers?: AdUserTypes[];
}

export const PreUserAddView = ({
  isOpen,
  onClose,
  methods,
  isLoading,
  onSubmit,
  departmentOptions,
  isDepartmentLoading,
  isUserLoading,
  userOptions,
  isUserAddLoading,
  isCancelModalOpen,
  handleCancelModal,
  handleConfirmCancelDialog,
  departmentIsSynced,
  managerIsSynced,
  isLoadingAllAdUsers,
  allAdUsers,
}: PreUserAddViewProps) => {
  const styles = PreUserAddStyles();

  const { handleSubmit, control } = methods;

  const options = allAdUsers?.map((user) => {
    return {
      value: user.id,
      text: user.displayName,
      secondaryText: user.mail ?? '',
    };
  });

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogSurface className={styles.container}>
          <form
            noValidate
            onSubmit={handleSubmit(onSubmit)}
            style={{ height: '100%' }}
          >
            <div className={styles.formContainer}>
              <div className={styles.modalHeader}>
                Validate & Add user
                <Button
                  appearance="subtle"
                  icon={<Icon iconName="Dismiss24Regular" />}
                  onClick={onClose}
                />
              </div>

              {isLoading || isUserLoading || isDepartmentLoading ? (
                <div className={styles.loadingContainer}>
                  <Spinner
                    label={LocalizationString.LOADING}
                    labelPosition="below"
                  />
                </div>
              ) : (
                <>
                  <div className={styles.modalBody}>
                    <div className={styles.rowForm}>
                      <Controller
                        control={control}
                        name="idpOid"
                        render={({ field, fieldState: { error } }) => (
                          <InHouseInputSelect
                            label={'User'}
                            options={userOptions}
                            isLoadingOptions={isDepartmentLoading}
                            selectedOptions={returnSelectedOptionFromOptions(
                              userOptions,
                              field.value
                            )}
                            isPeoplePicker={true}
                            disabled
                            onOptionSelect={(
                              event: SelectionEvents,
                              data: IChangeData
                            ) => field.onChange(data.optionValue)}
                            required
                            virtualized
                          />
                        )}
                      />
                    </div>

                    <div className={styles.rowForm}>
                      {departmentIsSynced && (
                        <Controller
                          control={control}
                          name="department"
                          render={({ field, fieldState: { error } }) => (
                            <InHouseInputSelect
                              label={'Department'}
                              options={departmentOptions}
                              isLoadingOptions={isDepartmentLoading}
                              selectedOptions={returnSelectedOptionFromOptions(
                                departmentOptions,
                                field.value
                              )}
                              onOptionSelect={(
                                event: SelectionEvents,
                                data: IChangeData
                              ) => field.onChange(data.optionValue)}
                            />
                          )}
                        />
                      )}
                    </div>

                    <div className={styles.rowForm}>
                      {managerIsSynced && (
                        <Controller
                          control={control}
                          name="manager"
                          render={({ field, fieldState: { error } }) => (
                            <InHouseInputSelect
                              label={'Manager'}
                              options={options ?? []}
                              isLoadingOptions={isLoadingAllAdUsers}
                              selectedOptions={returnSelectedOptionFromOptions(
                                options,
                                field.value
                              )}
                              onOptionSelect={(
                                event: SelectionEvents,
                                data: IChangeData
                              ) => field.onChange(data.optionValue)}
                            />
                          )}
                        />
                      )}
                    </div>
                  </div>

                  <div className={styles.modalFooter}>
                    <CustomButton
                      buttonType={ButtonTypes.Ghost}
                      buttonTitle={LocalizationString.CANCEL}
                      onClick={handleCancelModal}
                    />

                    <CustomButton
                      type="submit"
                      leftIcon={'Add'}
                      buttonType={ButtonTypes.Primary}
                      buttonTitle={'Confirm & Add User'}
                      isLoading={isUserAddLoading}
                      isDisabled={isLoading}
                    />
                  </div>
                </>
              )}
            </div>
          </form>
        </DialogSurface>
      </Dialog>

      <ConfirmDialog
        dataTestId={'cancel-form-modal'}
        open={isCancelModalOpen}
        onConfirmClicked={handleConfirmCancelDialog}
        onCancelClicked={handleCancelModal}
        primaryButtonText={LocalizationString.OK}
        secondaryButtonText={LocalizationString.CANCEL}
        title={LocalizationString.CANCEL}
        iconSrc={WarningIcon}
        description={
          LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_FORM_DIRTY
        }
      />
    </>
  );
};
