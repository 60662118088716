import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton, Icon } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Text,
} from '@fluentui/react-components';

import { ConfirmDialogProps } from './confirm-dialog.model';
import { confirmDialogStyles } from './confirm-dialog.styles';

interface ConfirmDialogViewProps extends ConfirmDialogProps {}

const ConfirmDialogView = ({
  open,
  title,
  description,
  primaryButtonText,
  secondaryButtonText,
  iconSrc,
  onCancelClicked,
  onConfirmClicked,
  dataTestId,
  dontShowCloseIcon,
  isPrimaryButtonLoading = false,
  isBlocking = false,
  isPrimaryButtonDisabled = false,
}: ConfirmDialogViewProps): JSX.Element => {
  const { cssVariables } = useTheme();

  const styles = confirmDialogStyles();

  return (
    <Dialog
      open={open}
      onOpenChange={onCancelClicked}
      modalType={isBlocking ? 'alert' : 'modal'}
    >
      <DialogSurface
        data-testid={dataTestId}
        style={{ padding: '0px', ...cssVariables }}
        className={styles.container}
      >
        <DialogBody>
          <DialogTitle
            as="div"
            className={styles.modalHeader}
            data-testid={`dialog-title`}
          >
            {title}
            {!dontShowCloseIcon && (
              <Button
                onClick={onCancelClicked}
                appearance="subtle"
                icon={<Icon iconName="Dismiss16Regular" />}
              />
            )}
          </DialogTitle>

          <DialogContent className={styles.modalBody}>
            {iconSrc && <img src={iconSrc} alt="Confirm Dialog Icon" />}
            <Text data-testid={'dialog-text'} className={styles.description}>
              {description}
            </Text>
          </DialogContent>

          <DialogActions fluid className={styles.modalFooter}>
            {secondaryButtonText && (
              <CustomButton
                buttonTitle={secondaryButtonText}
                buttonType={ButtonTypes.Ghost}
                onButtonClicked={onCancelClicked}
              />
            )}

            <CustomButton
              buttonTitle={primaryButtonText}
              buttonType={ButtonTypes.Primary}
              isLoading={isPrimaryButtonLoading}
              onButtonClicked={onConfirmClicked}
              disabled={isPrimaryButtonDisabled}
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};

export default ConfirmDialogView;
