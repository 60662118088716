import { LocalizationString } from '@celito.clients/assets';
import { DocumentDownloadActionEnum } from '@celito.clients/enums';
import { files, getDocumentData } from '@celito.clients/services';
import { errorToast } from '@celito.clients/utils';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { DisplayFileViewProps } from './file-view.model';
import DisplayFileView from './file-view.view';

export const FileViewController = (props: DisplayFileViewProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const formContext = useFormContext();

  const { objectName } = props;

  const handleFileView = async () => {
    try {
      // FIXME: Remove hardcoded attribute name
      const targetDoc = formContext?.getValues?.('file__a');
      if (targetDoc instanceof File) {
        files.downloadFileBuffer({
          data: targetDoc,
          label: targetDoc.name,
        });
      } else {
        if (!targetDoc?.documentId) throw new Error('Unable to view the file');
        files.downloadFile(
          getDocumentData(
            targetDoc.documentId,
            objectName,
            props.recordName,
            props.version,
            DocumentDownloadActionEnum.DownloadRaw
          ),
          (fileDocument) => {
            if (!fileDocument) throw new Error('Unable to view file');
            files.downloadFileBuffer({
              data: fileDocument.fileBuffer.data,
              label: fileDocument.label,
              mimeType: fileDocument.mimeType,
            });
          }
        );
      }
    } catch (_error) {
      errorToast({
        message: LocalizationString.THERE_WAS_SOME_PROBLEM_DOWNLOADING_THE_FILE,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <DisplayFileView
      {...props}
      showDownloadBtn={true}
      handleFileView={handleFileView}
      isLoading={loading}
    />
  );
};
