import { ApiMethodType, ExportFileType } from '@celito.clients/enums';
import {
  SortConfig,
  TemporaryUnifyDefaultAndUserFilters,
} from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import endpoint from 'libs/core/src/config/endpoint';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { TRAINING_ASSIGNMENT_OBJECT_NAME } from '../../taking-quiz/enums';
import { assignmentViewDefaultFilter, defaultSortConfig } from '../config';

export const getAssignmentViewExportData = async (
  fileType: ExportFileType,
  filters: RulesComponentListViewFilter,
  sortConfig?: SortConfig
) => {
  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    assignmentViewDefaultFilter,
    filters
  );

  return await performRequest<{ message: string }>(
    `${endpoint.OBJECT_DATA_URL}/${TRAINING_ASSIGNMENT_OBJECT_NAME}/export`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: sortConfig ?? defaultSortConfig,
    },
    {
      format: fileType,
      moduleName: 'lms__a',
    }
  );
};
