import { ApiMethodType, OperatorsEnum } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

export const createWorkflowAPI = async (payload: any) => {
  const response = await performRequest(
    Endpoints.WORKFLOW_DEFINITION,
    ApiMethodType.POST,
    payload
  );
  return response;
};

export const getObjectName = async () => {
  const respone = await performRequest(
    `${Endpoints.OBJECTS_URL}`,
    ApiMethodType.POST,
    {
      filters: [
        {
          conditions: {
            all: [
              {
                all: [
                  {
                    attribute: 'isSystemObject',
                    operator: OperatorsEnum.EQUALS,
                    value: false,
                  },
                ],
              },
            ],
          },
        },
      ],
    }
  );
  return respone.data;
};
