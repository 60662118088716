import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, ObjectEnum, OperatorsEnum } from '@celito.clients/enums';
import { CustomButton, Icon, InHouseInputSelect } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import {
  createTestAttribute,
  findDataTestIdFromField,
  getReferencePickerType,
} from '@celito.clients/utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
} from '@fluentui/react-components';
import { IChangeData } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { components, OptionProps } from 'react-select';

import { CurriculumInitiationTypeEnum } from '../../object-initiation-screen.model';
import { CurriculumPickerModalProps } from './curriculum-picker-modal.model';
import { curriculumModalStyles } from './curriculum-picker-modal-styles';

export const Option = (props: OptionProps) => {
  const dataTestId = findDataTestIdFromField(props.label);

  return (
    <components.Option {...props}>
      <span
        data-testid={`dropdown-reference-document-${createTestAttribute(
          dataTestId
        )}`}
      >
        {props.label}
      </span>
    </components.Option>
  );
};

interface CurriculumPickerModalViewProps extends CurriculumPickerModalProps {
  selectedValue: IChangeData | undefined;
  onPickerValChange: (val: IChangeData) => void;
  version: string | undefined;
  setVersion: (val: string | undefined) => void;
  onClose: () => void;
}

export const CurriculumPickerModalView: React.FC<
  CurriculumPickerModalViewProps
> = ({
  curriculumUpdatePickerModalOpen,
  onCloseCurriculumUpdatePickerModal,
  selectedValue,
  onPickerValChange,
  version,
  setVersion,
  onUpdateInitiate,
  onClose,
}) => {
  const { cssVariables } = useTheme();

  const styles = curriculumModalStyles();

  return (
    <Dialog open={curriculumUpdatePickerModalOpen}>
      <DialogSurface
        className={styles.container}
        style={{ padding: 0, ...cssVariables }}
      >
        <DialogBody className={styles.dialogBody}>
          <DialogTitle
            className={styles.modalHeader}
            data-testid="dialog-title"
          >
            Update Curriculum
            <Button
              appearance="subtle"
              icon={<Icon iconName="Dismiss24Regular" />}
              onClick={onCloseCurriculumUpdatePickerModal}
            />
          </DialogTitle>

          <DialogContent className={styles.modalBody}>
            <div className={styles.referencePickerSize}>
              <InHouseInputSelect
                multiselect={false}
                label="Curriculum"
                referencePicker={{
                  objectName: ObjectEnum.CURRICULUM,
                  filters: {
                    conditions: {
                      all: [
                        {
                          all: [
                            {
                              attribute: 'version',
                              operator: OperatorsEnum.ENDS_WITH,
                              value: '.0',
                            },
                            {
                              attribute: 'isActive',
                              operator: OperatorsEnum.EQUALS,
                              value: true,
                            },
                            {
                              attribute: 'activeFlowName',
                              operator: OperatorsEnum.IS_BLANK,
                              value: null,
                            },
                            {
                              attribute: 'status',
                              operator: OperatorsEnum.EQUALS,
                              value: 'Approved',
                            },
                          ],
                        },
                      ],
                    },
                  },
                  pickerType: getReferencePickerType(ObjectEnum.CURRICULUM),
                }}
                onOptionSelect={(_, data) => {
                  const version = data?.data?.version;
                  if (version) setVersion(version as string);
                  onPickerValChange(data);
                }}
                options={[]}
                selectedOptions={selectedValue?.selectedOptions}
                fieldClassName={styles.fieldContainer}
              />
            </div>
          </DialogContent>

          <DialogActions className={styles.modalFooter}>
            <CustomButton
              buttonType={ButtonTypes.Ghost}
              buttonTitle={LocalizationString.CANCEL}
              onClick={onClose}
            />

            <CustomButton
              buttonType={ButtonTypes.Primary}
              buttonTitle={'Update'}
              isDisabled={selectedValue === undefined}
              onClick={() =>
                onUpdateInitiate({
                  ...selectedValue?.data,
                  version: version!,
                  objectDefinitionName: ObjectEnum.CURRICULUM,
                  requestType: CurriculumInitiationTypeEnum.UpdateCurriculum,
                  editViewName: 'curriculum_edit_view__a',
                })
              }
            />
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
