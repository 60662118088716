import { useState } from 'react';

import { VirtualizerProps } from './virtualizer.model';
import { VirtualizerView } from './virtualizer.view';

export const VirtualizerController = <T,>({
  items,
  rowHeight,
  containerHeight,
  ...props
}: VirtualizerProps<T>) => {
  const [scrollPosition, setScrollPosition] = useState(0);

  const visibleItemsLength = Math.ceil(containerHeight / rowHeight);

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop } = event.target as HTMLDivElement;
    setScrollPosition(scrollTop);
  };

  const start = Math.floor(scrollPosition / rowHeight);
  const end = Math.ceil(
    (scrollPosition + visibleItemsLength * rowHeight) / rowHeight
  );
  const visibleItems = items.slice(start, end);
  const totalHeight = items.length * rowHeight;
  const offsetY = start * rowHeight;

  return (
    <VirtualizerView
      items={items}
      handleScroll={handleScroll}
      visibleItemsLength={visibleItemsLength}
      totalHeight={totalHeight}
      offsetY={offsetY}
      visibleItems={visibleItems}
      rowHeight={rowHeight}
      containerHeight={containerHeight}
      {...props}
    />
  );
};
