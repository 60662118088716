import { getWidthInPercentage } from '@celito.clients/utils';
import React from 'react';

import classes from './section.module.css';

type SectionProps = {
  width: number;
  children?: React.ReactNode;
  style?: React.CSSProperties;
};

const SectionView: React.FC<SectionProps> = ({ width, children, style }) => {
  return (
    <div
      style={{
        width: `calc(${getWidthInPercentage(width)}% - 30px)`,
        ...style,
      }}
      className={classes.section}
    >
      {children}
    </div>
  );
};

export const Section = React.memo(SectionView);
