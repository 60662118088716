import { performRequest } from '@celito.clients/network';
import { LocalizationString } from 'libs/assets/src/localization/en';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { FileBuffer } from '../types/document-type';
import { errorToast } from '../utils/toast-util/toast';

export const downloadTrainingCertificate = async (assignmentName: string) => {
  const response = await performRequest<FileBuffer>(
    `${Endpoints.ASSIGNMENTS_URL}/${assignmentName}/certificate`,
    ApiMethodType.GET,
    undefined,
    undefined,
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/pdf',
      },
    }
  );
  const url = await getUrlFromBuffer(response.data);
  downloadFileFromUrl(url!);
};

const getUrlFromBuffer = async (fileBuffer: FileBuffer) => {
  try {
    const uint8Array = new Uint8Array(fileBuffer?.data);
    const blob = new Blob([uint8Array], { type: 'application/pdf' });
    const url = URL.createObjectURL(blob);
    return url;
  } catch (e) {
    errorToast({
      message: LocalizationString.ERROR_IN_CONVERTING_BUFFER_TO_URL_MESSAGE,
    });
    return null;
  }
};

const downloadFileFromUrl = async (url: string) => {
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'certificate.pdf');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
