import { countBy, filter, flattenDeep } from 'lodash';

import { ObjectAttributeDefinition } from '../types/object-attribute-types';

export const hasUniqueValuesBetweenFields = (
  fields: Record<string, any>,
  uniqueCountNeeded: number
) => {
  const filledFields = getFilledFields(fields);
  if (Object.keys(filledFields).length < 2) {
    return true;
  }

  const allValues = flattenDeep(Object.values(filledFields));
  const countMap = countBy(allValues, (item) => getKey(item));
  const uniqueValues = filter(
    allValues,
    (item) => countMap[getKey(item)] === 1
  );

  return uniqueValues.length >= uniqueCountNeeded;
};

export const getObjctAttributeLabels = (
  fieldsNames: string[],
  objAttributeDefinition: ObjectAttributeDefinition[]
) => {
  const labelList: string[] = [];
  let label = '';
  for (const element of fieldsNames) {
    label =
      objAttributeDefinition.find((def) => def.name === element)!.label ??
      objAttributeDefinition.find((def) => def.name === element)!.name;
    labelList.push(label);
  }
  return getStringFromList(labelList);
};

const getStringFromList = (list: string[]) => {
  return [list.slice(0, -1).join(', '), list.slice(-1)[0]].join(
    list.length < 2 ? '' : ' and '
  );
};

const getFilledFields = (fields: Record<string, any>) => {
  return Object.fromEntries(
    Object.entries(fields).filter(([key, value]) => !isEmpty(value))
  );
};

const isEmpty = (value: any) => {
  if (!value) return true;
  if (Array.isArray(value)) return value.length === 0;
  if (typeof value === 'object') return Object.keys(value).length === 0;
  return false;
};

const getKey = (item: any) => {
  return typeof item === 'object' ? item.name : item;
};
