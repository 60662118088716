import { LocalizationString } from '@celito.clients/assets';
import { AttributeTypeEnum } from '@celito.clients/enums';
import { triggerWorkflow } from '@celito.clients/list-view-engine';
import { ModalContext, UserContext } from '@celito.clients/provider';
import { getAttributeConfig } from '@celito.clients/services';
import {
  LayoutRuleConditionEnum,
  ObjectAttributeType,
  OwnerType,
} from '@celito.clients/types';
import {
  errorToast,
  formatDateInTimezone,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import { yupResolver } from '@hookform/resolvers/yup';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { MappingTypeEnum } from 'libs/form-engine/src/lib/components/form-components/controlled-picker/controlled-picker.model';
import { getAttributeByColumnName } from 'libs/form-engine/src/lib/utils/attribute-config';
import { getFormValues } from 'libs/form-engine/src/lib/utils/helper';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router';
import * as yup from 'yup';

import { saveDetailsAPI } from '../controlled-document/services';
import { FieldOption, TaskInfo, VerdictOptions } from './e-signature.model';
import ESignatureView from './e-signature.view';
import { eSignatureService } from './hooks/e-signature-service';

const initialvalidationErrors = {
  verdict: '',
  verdictComments: '',
  rejectionComments: '',
};

const signatureMeaningField = [
  {
    columnName: 'signatureMeaning',
    width: 3,
    row: 1,
    layoutConfiguration: {
      jsonRuleCondition: LayoutRuleConditionEnum.VALUE,
      layoutConfig: {
        value: {
          isEditable: true,
          isRequired: true,
          isVisible: true,
        },
      },
    },
  },
];

const ESignatureController = () => {
  const { fetchTaskInfo, updateTask } = eSignatureService();
  const {
    isModalOpen,
    closeModal,
    taskName,
    extraPayload = {},
    navigateUrl,
    recordData,
    contextMenuAction,
  } = useContext(ModalContext);
  const [showOktaModal, setShowOktaModal] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [taskInfo, setTaskInfo] = useState<TaskInfo>();
  const [options, setOptions] = useState<VerdictOptions[]>();
  const [selectedVerdict, setSelectedVerdict] = useState<string>('');
  const [formFields, setFormFields] = useState<FieldOption[]>([]);
  const [verdictTaskField, setVerdictTaskField] = useState<FieldOption[]>([]);
  const [signatureInstructions, setSignatureInstructions] = useState<
    string | undefined
  >(undefined);
  const [isSignatureMeaningRequired, setIsSignatureMeaningRequired] =
    useState(false);
  const [commentTitle, setCommentTitle] = useState<string>();
  const [isCommentRequired, setIsCommentRequired] = useState(false);
  const [isRejectVerdictSelected, setIsRejectVerdictSelected] = useState(false);
  const [isEsignRequired, setIsEsignRequired] = useState<boolean>(false);
  const [verdictComments, setVerdictComments] = useState<string>('');
  const [rejectionComments, setRejectionComments] = useState<string>('');
  const [errors, setErrors] = useState(initialvalidationErrors);
  const [isCompleteTaskResponseLoading, setIsCompleteTaskResponseLoading] =
    useState<boolean>(false);
  const navigate = useNavigate();
  const [showVerdict, setShowVerdict] = useState<boolean>(false);
  const [formDataState, setFormDataState] = useState<Record<string, string>[]>(
    []
  );
  const [isFieldsLoading, setIsFieldsLoading] = useState(false);
  const [isRejectionCommentRequired, setIsRejectionCommentRequired] =
    useState(false);
  const [signatureMeaningValue, setSignatureMeaningValue] = useState('');
  const [objectAttributeDefinition, setObjectAttributeDefinition] = useState<
    ObjectAttributeType | undefined
  >();
  const [validationSchema, setValidationSchema] = useState<
    yup.ObjectSchema<yup.AnyObject> | undefined
  >(yup.object().shape({}));
  const { user } = useContext(UserContext);
  const submitCountRef = useRef(0);

  const methods = useForm({
    resolver: yupResolver(validationSchema!),
  });

  const { handleSubmit, reset } = methods;

  const verdictCommentLimit = 200;

  const validateVerdictAndCommentsField = () => {
    const validationErrors = {
      verdict: '',
      verdictComments: '',
      rejectionComments: '',
    };

    if (showVerdict && !selectedVerdict) {
      validationErrors.verdict = LocalStrings.verdictRequired;
      setErrors(validationErrors);
    }

    const hasCommentRequiredError =
      verdictComments.length === 0 && isCommentRequired;
    const hasRejectionRequiredError =
      rejectionComments.length === 0 && isRejectionCommentRequired;

    if (hasRejectionRequiredError) {
      validationErrors.rejectionComments = LocalStrings.requiredLabel;
      setErrors(validationErrors);
    }
    if (hasCommentRequiredError) {
      validationErrors.verdictComments = LocalStrings.requiredLabel;
      setErrors(validationErrors);
    }
    if (rejectionComments.length > verdictCommentLimit) {
      validationErrors.rejectionComments = LocalStrings.minCharacters;
      setErrors(validationErrors);
    }
    if (verdictComments.length > verdictCommentLimit) {
      validationErrors.verdictComments = LocalStrings.minCharacters;
      setErrors(validationErrors);
    }
    if (
      (showVerdict && !selectedVerdict) ||
      rejectionComments.length > verdictCommentLimit ||
      hasRejectionRequiredError ||
      verdictComments.length > verdictCommentLimit ||
      hasCommentRequiredError
    )
      return false;
    else {
      return true;
    }
  };

  const completeTask = async (
    formDataObj: Record<string, string>[] = [],
    oktaResponse?: Record<string, unknown>
  ) => {
    if (validateVerdictAndCommentsField()) {
      if (submitCountRef.current + 1 > 1) return;
      submitCountRef.current = submitCountRef.current + 1;
      const { file, ...rest } = extraPayload || {};
      setLoading(true);
      const payload: Record<string, unknown> = {
        status: 'Done',
        verdictName: showVerdict ? selectedVerdict : 'complete',
        verdictComment: isRejectVerdictSelected
          ? rejectionComments
          : verdictComments,
        ...(!showVerdict && { taskFieldsData: JSON.stringify([]) }),
        eSignatureObj: JSON.stringify({
          token: oktaResponse?.accessToken as string,
        }),
        ...(formDataObj.length > 0 && {
          verdictFieldsData: JSON.stringify(
            extractVerdictFields(verdictTaskField, formDataObj)
          ),
        }),
        ...(formDataObj.length > 0 && {
          taskFieldsData: JSON.stringify(
            extractVerdictFields(formFields, formDataObj)
          ),
        }),
        ...rest,
      };
      if (objectAttributeDefinition?.signatureMeaningPicklistName) {
        verdictTaskField?.forEach((fields) => {
          if (fields?.columnName === 'signatureMeaning') {
            payload['signatureMeaning'] = signatureMeaningValue || '';
          }
        });
      }
      const formData = new FormData();
      if (!payload?.verdictName) payload.verdictName = 'complete';
      Object.keys({ ...payload }).forEach((key) => {
        formData.append(key, payload[key] as string);
      });
      if (file) {
        formData.append('file', file);
      }

      setIsCompleteTaskResponseLoading(true);
      try {
        const resp = await updateTask(taskName, formData);
        setFormDataState([]);
        successToast({
          message: LocalizationString.TASK_COMPLETED_SUCCESSFULLY,
        });
        closeModal();
        // pushing the navigation to the end of callstack so the toaster finishes it's job
        if (resp?.task?.label) {
          setTimeout(() => {
            if (navigateUrl) {
              navigate(navigateUrl);
            }
          }, 1000);
        }
      } catch (_error) {
        closeModal(false);
        raiseErrorToast(_error);
      } finally {
        submitCountRef.current = 0;
        setFormFields([]);
        setVerdictTaskField([]);
        setShowOktaModal(false);
        setIsCompleteTaskResponseLoading(false);
        setIsCommentRequired(false);
        setIsRejectVerdictSelected(false);
        setIsSignatureMeaningRequired(false);
        setCommentTitle(undefined);
        setIsRejectVerdictSelected(false);
        setIsRejectionCommentRequired(false);
      }
    }
  };

  const filteredFormData = (formDataObj: Record<string, string>[]) => {
    const filteredData: Record<string, string>[] = [];
    formFields?.forEach((field) => {
      formDataObj?.forEach((formData) => {
        if (field?.columnName === formData?.label) {
          filteredData.push(formData);
        }
      });
    });
    const formData = new FormData();
    Object.keys({ ...filteredData }).forEach((key) => {
      const value = filteredData[Number(key)]?.value;
      if (value !== undefined && value !== null) {
        formData.append(filteredData[Number(key)]?.label, value);
      }
    });

    if (formData) {
      saveFormDetails(recordData?.name, formData);
    }
  };

  const extractVerdictFields = (
    fieldData: FieldOption[],
    formDataObj: Record<string, string>[] = []
  ) => {
    const payloadVerdictData: Record<string, string>[] = [];

    if (fieldData.length) {
      formDataObj.length > 0 &&
        formDataObj.forEach((obj) => {
          fieldData?.forEach((verdict) => {
            if (verdict?.columnName === obj.label) {
              if (verdict?.columnName === 'signatureMeaning') {
                return;
              }

              payloadVerdictData.push({
                name: obj?.label,
                value: obj.value,
              });
            }
          });
        });
      return payloadVerdictData;
    } else {
      return [];
    }
  };

  const onCloseModal = () => {
    if (showOktaModal) {
      setShowOktaModal(false);
    } else {
      setIsCommentRequired(false);
      setIsRejectVerdictSelected(false);
      setSignatureInstructions(undefined);
      setIsSignatureMeaningRequired(false);
      setCommentTitle(undefined);
      setIsRejectVerdictSelected(false);
      setIsRejectionCommentRequired(false);
      submitCountRef.current = 0;
      closeModal();
    }
  };

  const onSave = async () => {
    // on save check for comments validation
    validateVerdictAndCommentsField();
    await handleSubmit((data: Record<string, any>) => {
      const formData = Object.keys(data).map((key) => {
        if (key === 'signatureMeaning') {
          setSignatureMeaningValue(data[key]);
        }
        return {
          label: key,
          value: getRequiredFormatData(data[key], key),
        };
      });
      onCompleteClicked(formData);
    })();
  };

  const getRequiredFormatData = (
    value: Record<string, any>[] | string,
    columnName: string
  ) => {
    if (value !== undefined && value !== null && !(value instanceof Date)) {
      const attribute =
        objectAttributeDefinition &&
        getAttributeByColumnName(objectAttributeDefinition, columnName);
      if (attribute && attribute.dataType === AttributeTypeEnum.Reference) {
        const isMulti = [
          MappingTypeEnum.OneToMany,
          MappingTypeEnum.ManyToMany,
        ].includes(
          attribute.relationship?.objectMappingType as MappingTypeEnum
        );

        if (!isMulti) {
          return (value as Record<string, any>)?.name;
        }
      }
      if (Array.isArray(value)) {
        if (value.length > 0) {
          return JSON.stringify(value.map((val) => val?.name));
        }
        return JSON.stringify(null);
      } else {
        return value;
      }
    }
    if (value instanceof Date) {
      return formatDateInTimezone(value);
    }

    return value;
  };

  const onCompleteClicked = (formData: Record<string, string>[]) => {
    setFormDataState(formData);
    if (isEsignRequired) {
      if (validateVerdictAndCommentsField()) {
        setShowOktaModal(!showOktaModal);
      }
    } else {
      if (contextMenuAction?.properties?.fields?.length) {
        filteredFormData(formData);
        return;
      }
      if (taskName) {
        completeTask(formData);
      }
    }
  };

  const onEsignSuccess = (tokens: Record<string, string>) => {
    if (tokens)
      completeTask(
        formDataState.length > 0 ? formDataState : undefined,
        tokens
      );
  };

  const onEsignError = (err: Error) => {
    closeModal();
    setShowOktaModal(false);
    errorToast({
      message: err.message,
    });
  };

  const setVerdictOptions = (verdictOptions: VerdictOptions[]) => {
    const verdicts: VerdictOptions[] = [];
    let hasVerdicts;
    let parsedVerdictOptions = verdictOptions;
    try {
      if (typeof verdictOptions === 'string') {
        parsedVerdictOptions = JSON.parse(verdictOptions);
      }

      hasVerdicts =
        parsedVerdictOptions?.length > 0 && parsedVerdictOptions[0]?.label;
    } catch (error) {
      hasVerdicts = false;
    }
    if (hasVerdicts) setShowVerdict(true);
    else
      setIsEsignRequired(
        verdictOptions?.length > 0 && verdictOptions?.[0].isESignatureRequired
      );
    verdictOptions
      ?.filter((item) => item.label)
      .forEach((verdict) => {
        verdicts.push({
          value: verdict.name,
          label: verdict.label,
          isCommentRequired: verdict.isCommentRequired,
          isESignatureRequired: verdict?.isESignatureRequired || false,
          fields: verdict?.fields,
          isSignatureMeaningRequired: verdict?.isESignatureRequired
            ? verdict?.isSignatureMeaningRequired
            : false,
          signatureInstructions: verdict?.signatureInstructions
            ? verdict?.signatureInstructions
            : '',
          commentTitle: verdict?.commentTitle ?? '',
        });
      });
    if (!parsedVerdictOptions[0]?.label && parsedVerdictOptions.length) {
      setIsCommentRequired(verdictOptions[0]?.isCommentRequired);

      if (
        verdictOptions[0]?.isSignatureMeaningRequired &&
        verdictOptions[0]?.isESignatureRequired
      ) {
        setIsSignatureMeaningRequired(true);

        setVerdictTaskField(signatureMeaningField);
      }

      if (
        typeof verdictOptions[0]?.commentTitle === 'string' &&
        verdictOptions[0]?.commentTitle.length
      ) {
        setCommentTitle(verdictOptions[0]?.commentTitle);
      }

      if (verdictOptions[0]?.signatureInstructions) {
        setSignatureInstructions(verdictOptions[0]?.signatureInstructions);
      }
    }
    setOptions(verdicts);
  };

  const fetchInputObjectAttributeDefintion = async (
    taskInfoResponse: Record<string, any>
  ) => {
    const attributeConfig = await getAttributeConfig(
      taskInfoResponse?.objectName,
      recordData?.name,
      recordData?.version
    );

    if (taskInfoResponse?.availableVerdicts?.length) {
      taskInfoResponse?.availableVerdicts?.forEach(
        (verdictOptions: VerdictOptions) => {
          if (
            attributeConfig?.signatureMeaningPicklistName &&
            verdictOptions?.isESignatureRequired
          ) {
            const signatureAttributeConfig = {
              label: 'Signature Meaning',
              name: 'signatureMeaning',
              isRequired: true,
              columnName: 'signatureMeaning',
              dataType: AttributeTypeEnum.Picklist,
              displayInList: true,
              isAuditable: true,
              isEditable: true,
              isIdAppendedInColumnNameInDb: false,
              isInternal: false,
              isMandatory: true,
              key: 'signatureMeaning',
              ownerType: OwnerType.System,
              picklistName: attributeConfig?.signatureMeaningPicklistName,
              rules: [
                {
                  errorMessage: LocalizationString.REQUIRED_MSG,
                  type: LocalizationString.REQUIRED.toLocaleLowerCase(),
                },
              ],
            };
            attributeConfig.objectAttributeDefinitions.push(
              signatureAttributeConfig
            );
          }
        }
      );
    }

    setLoading(false);
    setIsFieldsLoading(false);
    reset(getFormValues(attributeConfig, recordData!));
    setObjectAttributeDefinition({ ...attributeConfig });
  };

  const getTaskInfo = (taskName: string) => {
    fetchTaskInfo(taskName)
      .then((resp: TaskInfo) => {
        if (resp) {
          setTaskInfo(resp);
          setLoading(false);
          setIsFieldsLoading(true);
          setVerdictOptions(resp?.availableVerdicts);
          patchFieldsBasdOnUserRole(resp);
          let isEsignRequired;
          if (resp?.tag === 'tp_follow_up_update') {
            isEsignRequired = false;
          } else if (resp?.availableVerdicts?.length) {
            isEsignRequired = resp?.availableVerdicts[0]?.isESignatureRequired;
          } else {
            isEsignRequired = false;
          }
          if (isEsignRequired) {
            setIsEsignRequired(true);
          } else {
            setIsEsignRequired(false);
          }
          fetchInputObjectAttributeDefintion(resp);
        }
      })
      .catch((_error) => {
        setLoading(false);
        closeModal();
        raiseErrorToast(_error);
      });
  };

  useEffect(() => {
    if (isModalOpen) {
      setLoading(true);
      setSelectedVerdict('');
      setVerdictComments('');
      setRejectionComments('');
      setShowVerdict(false);
      setErrors(initialvalidationErrors);
      if (taskName) {
        getTaskInfo(taskName);
      } else if (contextMenuAction?.properties?.fields?.length) {
        setTaskInfo(undefined);
        setOptions([]);
        setIsEsignRequired(false);
        setLoading(true);
        setFormFields(contextMenuAction?.properties?.fields || []);
        fetchInputObjectAttributeDefintion(contextMenuAction);
      }
    }
    return () => {
      setSelectedVerdict('');
      setFormFields([]);
      setVerdictTaskField([]);
      setShowVerdict(false);
      reset();
    };
  }, [isModalOpen, taskName]);

  const patchFieldsBasdOnUserRole = (resp: TaskInfo) => {
    // if tag is trainer sign off then don't show the fields array as we are showing these fileds in the sign off panel
    if (
      resp?.tag !== 'ta_trainer_sign_off' &&
      resp.tag !== 'ta_take_training'
    ) {
      // if user is not owner and not in delegation then patch overrideFields otherwise task field
      if (
        user &&
        user.name !== resp?.ownerName &&
        !resp?.delegatedUserNames?.includes(user?.name)
      ) {
        if (resp?.overrideFields?.length) {
          setIsFieldsLoading(true);
        }
        setFormFields(resp?.overrideFields || []);
      } else {
        if (resp?.fields?.length) {
          setIsFieldsLoading(true);
        }
        setFormFields(resp?.fields || []);
      }
    }
  };

  const saveFormDetails = (name: string, payload: FormData) => {
    setLoading(true);

    const params = contextMenuAction?.name?.includes('retire')
      ? {
          skipVersionUpgrade: true,
          action: 'retire',
          version: recordData?.version,
        }
      : {};

    saveDetailsAPI(name, payload, objectAttributeDefinition?.name, params)
      .then((res) => {
        if (res) {
          successToast({
            message: LocalizationString.FORM_DETAILS_Updated,
          });
          callTriggerWorkflow(
            contextMenuAction?.name,
            recordData?.name,
            recordData?.version
          );
        }
      })
      .catch((_error) => {
        closeModal();
        raiseErrorToast(_error);
      });
  };

  const callTriggerWorkflow = async (
    actionName: string,
    recordName: string,
    version: string
  ) => {
    try {
      await triggerWorkflow(actionName, recordName, version);
      setLoading(false);
      closeModal();
      successToast({
        message: LocalizationString.RETIRE_DOCUMENT,
      });
      setTimeout(() => {
        if (navigateUrl) {
          navigate(navigateUrl);
        }
      }, 100);
    } catch (_error) {
      closeModal();
      raiseErrorToast(_error);
    }
  };

  const handleInputChange = useCallback((_key: string, value: string) => {
    if (_key === 'comments') {
      setVerdictComments(value);
    } else {
      setRejectionComments(value);
    }
    if (value?.length <= verdictCommentLimit && _key === 'comments') {
      setErrors((prev) => ({ ...prev, verdictComments: '' }));
    }
    if (value?.length <= verdictCommentLimit && _key === 'rejection') {
      setErrors((prev) => ({ ...prev, rejectionComments: '' }));
    }
  }, []);

  const onVerdictChange = useCallback(
    (option?: VerdictOptions) => {
      if (option) {
        setValidationSchema(yup.object().shape({}));
        setErrors((prev) => ({
          ...prev,
          verdict: '',
          verdictComments: '',
          rejectionComments: '',
        }));
        setSelectedVerdict(option.value as string);
        setIsEsignRequired(option?.isESignatureRequired);
        setCommentTitle(
          option?.commentTitle ? option?.commentTitle : undefined
        );
        checkIfRejectVerdictSelected(option);

        if (option?.isESignatureRequired) {
          if (option?.isSignatureMeaningRequired) {
            setIsSignatureMeaningRequired(option?.isSignatureMeaningRequired);
          }
          if (option?.signatureInstructions) {
            setSignatureInstructions(option?.signatureInstructions);
          }
          const arr = [
            ...option.fields!,
            ...(option?.isSignatureMeaningRequired
              ? signatureMeaningField
              : []),
          ];

          setVerdictTaskField(arr || []);
          reset();
          return;
        } else {
          setSignatureInstructions(undefined);
          setIsSignatureMeaningRequired(false);
        }
        setVerdictTaskField(option?.fields || []);
      }
    },
    [taskInfo]
  );

  const checkIfRejectVerdictSelected = (option: VerdictOptions) => {
    if (option?.value === 'reject') {
      setIsRejectVerdictSelected(true);
      setIsRejectionCommentRequired(option?.isCommentRequired);
      setIsCommentRequired(false);
    } else {
      setIsRejectVerdictSelected(false);
      setIsCommentRequired(option?.isCommentRequired);
      setIsRejectionCommentRequired(false);
    }
  };
  return (
    <ESignatureView
      open={isModalOpen}
      taskInfo={taskInfo}
      showOktaModal={showOktaModal}
      onCancelClicked={onCloseModal}
      onSave={onSave}
      onCompleteClicked={() => onCompleteClicked([])}
      onEsignSuccess={onEsignSuccess}
      onInputChange={handleInputChange}
      comments={isRejectVerdictSelected ? rejectionComments : verdictComments}
      onEsignError={onEsignError}
      loading={loading}
      verdictOptions={options}
      selectedVerdict={selectedVerdict}
      showVerdict={showVerdict}
      onVerdictChange={onVerdictChange}
      isEsignRequired={isEsignRequired}
      formFields={formFields}
      verdictTaskField={verdictTaskField}
      isLoading={isCompleteTaskResponseLoading}
      errors={errors}
      attributeConfig={objectAttributeDefinition}
      recordData={recordData}
      contextMenuAction={contextMenuAction}
      signatureInstructions={signatureInstructions}
      isFieldsLoading={isFieldsLoading}
      isCommentRequired={isCommentRequired}
      isSignatureMeaningRequired={isSignatureMeaningRequired}
      commentTitle={commentTitle}
      methods={methods}
      setValidationSchema={setValidationSchema}
      IsRejectVerdictSelected={isRejectVerdictSelected}
      validateVerdictAndCommentsField={validateVerdictAndCommentsField}
      isRejectionCommentRequired={isRejectionCommentRequired}
    />
  );
};

export default ESignatureController;
