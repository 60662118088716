import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  ButtonTypes,
  FormEngineModeTypeEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import {
  ConfirmDialog,
  CustomButton,
  Loader,
  PreviewPanel,
} from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { getFieldSection } from 'libs/form-engine/src/lib/components/helper-components';
import { FormEngineContextProvider } from 'libs/form-engine/src/lib/context';
import { getFieldContainer } from 'libs/form-engine/src/lib/utils/attribute-config';
import { FormProvider } from 'react-hook-form';

import { sections } from '../../config/section';
import { getStyles } from '../../styles';
import { hasAction } from '../../utils';
import CustomField from '../custom-fields';
import TabList from '../tab-list';
import { useController } from './controller';

const customFields = CustomField.names;

const getFullWidthStyles = (fullWidth: boolean) =>
  fullWidth
    ? {
        gridColumnStart: 1,
        gridColumnEnd: -1,
      }
    : {};

/**
 *  Right pane of the Controlled Document View Screen
 *  Renders all the form field sections
 */
export function FormWizard() {
  const {
    objectDefinition,
    setFieldsState,
    record,
    mode,
    fieldsState,
    form,
    disableSaveButton,
    onSubmitClick,
    mcContainerRef,
    sectionRefsMap: sectionRefs,
    tablistProps,
    attributeDefinitions,
    onCancelClick,
    recordDataIsLoading,
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    fieldsMap,
    userActions,
  } = useController();

  const styles = getStyles();

  if (recordDataIsLoading)
    return (
      <div className={styles.rightPaneLoader}>
        <Loader />
      </div>
    );

  return (
    <FormEngineContextProvider {...{ fieldsState, setFieldsState }}>
      <FormProvider {...form}>
        <div className={styles.rightPane}>
          <div className={styles.stickyTop}>
            <h2>Controlled Documents Details</h2>
            <TabList {...tablistProps} />
          </div>
          <div ref={mcContainerRef} className={styles.mcContainer}>
            {sections.map(({ fields, name }) => {
              const hidden =
                (tablistProps.hideKeyDates && name === 'Key Dates') ||
                fields.length === 0;
              if (hidden) return null;
              return (
                <PreviewPanel
                  showEditIcon={false}
                  key={name}
                  isPanelOpenClose={true}
                  title={name}
                  ref={sectionRefs[name]}
                >
                  <div className={styles.sectionGrid}>
                    {fields.map((field, idx) => {
                      const attribute = attributeDefinitions?.[field];

                      const fieldConfig = fieldsMap[field];

                      const isEditable = () => {
                        const isEditMode = mode === FormEngineModeTypeEnum.Edit;
                        const isFieldEditable =
                          attribute?.isEditable &&
                          fieldsState[field]?.isEditable;
                        return isEditMode && isFieldEditable;
                      };

                      const isCustomField = () =>
                        customFields.includes(field.toString());

                      const fullWidth = !!field.fullWidth;

                      const isHidden =
                        (attribute?.isHidden ||
                          fieldsState[field]?.isVisible === false) &&
                        !field.alwaysVisible;

                      if (isHidden) return null;

                      const Field = () => {
                        if (isEditable()) {
                          return getFieldContainer(
                            ObjectEnum.CONTROLLED_DOCUMENT,
                            objectDefinition,
                            field.toString(),
                            form.control,
                            form.watch,
                            form.setValue,
                            form.trigger,
                            mode ?? FormEngineModeTypeEnum.View,
                            record ?? {},
                            undefined,
                            {
                              ...fieldConfig,
                              ...fieldsState[field],
                            }
                          );
                        }

                        if (objectDefinition) {
                          return getFieldSection(
                            field.toString(),
                            attribute?.width ?? 3,
                            record ?? {},
                            objectDefinition,
                            ObjectEnum.CONTROLLED_DOCUMENT,
                            idx,
                            undefined,
                            `label-${createTestAttribute(attribute?.label)}`
                          );
                        }
                        return null;
                      };

                      return (
                        <div
                          style={getFullWidthStyles(fullWidth)}
                          className={styles.fieldContainerWrapper}
                          key={field}
                        >
                          {isCustomField() ? (
                            <CustomField
                              mode={mode}
                              record={record}
                              attribute={attribute}
                              name={field}
                              fieldProps={{
                                disableDownload: !hasAction(
                                  userActions ?? [],
                                  ActionTypeEnum.DOWNLOAD_RAW
                                ),
                              }}
                            />
                          ) : (
                            Field()
                          )}
                        </div>
                      );
                    })}
                  </div>
                </PreviewPanel>
              );
            })}
          </div>
          {mode === FormEngineModeTypeEnum.Edit && (
            <div className={styles.editButtonContainer}>
              <CustomButton
                buttonTitle="Cancel"
                buttonType={ButtonTypes.Ghost}
                typeof="button"
                onClick={onCancelClick}
              />
              <CustomButton
                buttonTitle="Save"
                leftIcon="Submit"
                appearance="primary"
                isLoading={form.formState.isSubmitting}
                disabled={disableSaveButton}
                typeof="button"
                onClick={onSubmitClick}
              />
            </div>
          )}
        </div>
      </FormProvider>
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </FormEngineContextProvider>
  );
}
