import {
  createContext,
  createRef,
  ReactNode,
  useCallback,
  useRef,
  useState,
} from 'react';

import { LayoutPageTitleEnum } from '../enums/layout-enums';
import { LayoutContextType, LockIconType } from '../types';

interface LayoutProviderProps {
  children: ReactNode;
}

export const LayoutContext = createContext<LayoutContextType>({
  pageTitle: '',
  layoutRef: null,
  screenActionsContainerRef: createRef(),
  configureLayout: () => {},
  showBackButton: false,
  pageTitleVariant: LayoutPageTitleEnum.Primary,
  checkInCheckOutIcon: undefined,
});

export const LayoutProvider = ({
  children,
}: LayoutProviderProps): JSX.Element => {
  const ref = useRef<HTMLDivElement | null>(null);
  const screenActionsContainerRef = useRef<HTMLDivElement>(null);
  const [title, setTitle] = useState('');
  const [showBackButton, setShowBackButton] = useState(false);
  const [enablePadding, setEnablePadding] = useState(true);
  const [backRoute, setBackRoute] =
    useState<LayoutContextType['backRoute']>(null);
  const [pageTitleVariant, setPageTitleVariant] = useState(
    LayoutPageTitleEnum.Primary
  );
  const [checkInCheckOutIcon, setShowLockIcon] = useState<LockIconType>();
  const [headerTitle, setHeaderTitle] = useState('');
  const [isPageHeadingLoading, setIsPageHeadingLoading] = useState(false);
  const [breadcrumbTitle, setBreadcrumbTitle] = useState('');

  const configureLayout: LayoutContextType['configureLayout'] = useCallback(
    (options) => {
      setTitle(options.pageTitle);
      if (options.enablePadding !== undefined)
        setEnablePadding(options?.enablePadding);
      else setEnablePadding(true);

      if (options.showBackButton) {
        setShowBackButton(true);
      } else {
        if (showBackButton) {
          setShowBackButton(false);
        }
      }

      if (options.navigateBackRoute) {
        setBackRoute(options.navigateBackRoute);
      } else {
        if (backRoute !== null) {
          setBackRoute(null);
        }
      }

      if (options.headerTitle) {
        setHeaderTitle(options.headerTitle);
      } else {
        if (headerTitle) {
          setHeaderTitle('');
        }
      }

      if (options.showHeadingLoader) {
        setIsPageHeadingLoading(true);
      } else {
        setIsPageHeadingLoading(false);
      }

      if (options.breadcrumbTitle) {
        setBreadcrumbTitle(options.breadcrumbTitle);
      } else {
        setBreadcrumbTitle('');
      }

      if (options?.checkInCheckOutIcon) {
        setShowLockIcon(options?.checkInCheckOutIcon);
      } else {
        setShowLockIcon(undefined);
      }

      setPageTitleVariant(
        options.pageTitleVariant || LayoutPageTitleEnum.Primary
      );
    },
    [showBackButton, backRoute, headerTitle, isPageHeadingLoading]
  );

  return (
    <LayoutContext.Provider
      value={{
        pageTitle: title,
        layoutRef: ref,
        screenActionsContainerRef,
        configureLayout,
        showBackButton,
        enablePadding: enablePadding,
        backRoute,
        pageTitleVariant: pageTitleVariant,
        headerTitle,
        showHeadingLoader: isPageHeadingLoading,
        breadcrumbTitle,
        checkInCheckOutIcon,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
