import React from 'react';

import ModalContext from './e-sign-modal-context';
import useESignModal from './use-e-sign-modal';

type props = {
  children: React.ReactNode;
};

const ESignModalProvider: React.FC<props> = ({ children }) => {
  const modal = useESignModal();

  return (
    <ModalContext.Provider value={modal}>{children}</ModalContext.Provider>
  );
};

export default ESignModalProvider;
