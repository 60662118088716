import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledFileUploadController } from './controlled.file-upload.controller';
import { ControlledFileUploadProps } from './controlled.file-upload.model';

const ControlledFileUpload = <T extends FieldValues>(
  props: ControlledFileUploadProps<T>
) => {
  return <ControlledFileUploadController {...props} />;
};

export default ControlledFileUpload;
