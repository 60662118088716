import { OperatorsEnum } from '@celito.clients/enums';
import {
  ConditionProperties,
  LayoutRuleConditionEnum,
  LayoutRuleProperties,
  LayoutRulesDataSchema,
  Section,
  TopLevelCondition,
} from '@celito.clients/types';
import UserContextManager from 'libs/core/src/singleton/user-context-manager';
import { get } from 'lodash';
import { FieldValues } from 'react-hook-form';

export const getLayoutRules = (
  formData: FieldValues,
  rulesConfig: Partial<Section>,
  defaultFieldsState?: Record<string, LayoutRulesDataSchema>
) => {
  const newFieldsState: Record<string, LayoutRulesDataSchema> =
    defaultFieldsState ?? {};

  // Initialize default states for each field
  if (!defaultFieldsState) {
    rulesConfig?.fields?.forEach((field) => {
      newFieldsState[field.columnName] = {
        isVisible: false,
        isEditable: false,
        isRequired: false,
      };
    });
  }

  // Apply rules for each field
  rulesConfig?.fields?.forEach((field) => {
    const { columnName, layoutConfiguration } = field;

    if (layoutConfiguration) {
      const { jsonRuleCondition, layoutConfig } = layoutConfiguration;

      switch (jsonRuleCondition) {
        case LayoutRuleConditionEnum.VALUE:
          // Direct assignment of states
          if ('value' in layoutConfig) {
            newFieldsState[columnName] = {
              ...newFieldsState[columnName],
              ...layoutConfig.value,
            };
          }
          break;
        case LayoutRuleConditionEnum.VALUE_FROM_CONDITIONS:
          // initially set to default states
          newFieldsState[columnName] = {
            ...layoutConfiguration?.defaultLayoutRules,
          };

          // Evaluate conditions and apply states.
          if ('rules' in layoutConfig) {
            layoutConfig.rules.forEach((rule) => {
              const conditionsMet = evaluateConditions(
                rule.conditions,
                formData
              );

              if (conditionsMet) {
                newFieldsState[columnName] = {
                  ...newFieldsState[columnName],
                  ...rule.event.params,
                };
              }
            });
          }
          break;
      }
    }
  });

  return newFieldsState;
};

export const evaluateCondition = (
  condition: ConditionProperties,
  formData: FieldValues
) => {
  const { fact, operator, path } = condition;
  let { value } = condition;
  const factValue = path
    ? get(formData, `${fact}.${path}`)
    : get(formData, fact);

  let userPermissionContext;

  if (operator === OperatorsEnum.IS_BIZ_ADMIN) {
    userPermissionContext =
      UserContextManager.getInstance().getUserPermissionContext();
  }

  if (value === '@me') {
    value = UserContextManager.getInstance().getUserContext()?.name;
  }

  switch (operator) {
    case OperatorsEnum.EQUALS:
      return String(factValue) === String(value);
    case OperatorsEnum.NOT_EQUALS:
      return factValue !== value;
    case OperatorsEnum.GREATER_THAN:
      return factValue > value;
    case OperatorsEnum.LESS_THAN:
      return factValue < value;
    case OperatorsEnum.GREATER_THAN_EQUALS:
      return factValue >= value;
    case OperatorsEnum.LESS_THAN_EQUALS:
      return factValue <= value;
    case OperatorsEnum.RANGE:
      if (
        typeof factValue === 'number' &&
        typeof value['min'] === 'number' &&
        typeof value['max'] === 'number'
      ) {
        return factValue >= value['min'] && factValue <= value['max'];
      } else if (factValue instanceof Date) {
        let minValue = value['min'];
        let maxValue = value['max'];

        try {
          if (typeof minValue === 'string') minValue = new Date(minValue);
          if (typeof maxValue === 'string') maxValue = new Date(maxValue);
        } catch (error) {
          return false;
        }

        return factValue >= minValue && factValue <= maxValue;
      }

      return false;
    case OperatorsEnum.IN:
      return value.includes(factValue);
    case OperatorsEnum.NOT_IN:
      return !value.includes(factValue);
    case OperatorsEnum.CONTAINS:
      return factValue?.includes?.(value);
    case OperatorsEnum.NOT_CONTAINS:
      return !factValue?.includes?.(value);
    case OperatorsEnum.BEGINS_WITH:
      return factValue?.startsWith?.(value);
    case OperatorsEnum.NOT_BEGINS_WITH:
      return !factValue?.startsWith?.(value);
    case OperatorsEnum.ENDS_WITH:
      return factValue?.endsWith?.(value);
    case OperatorsEnum.NOT_ENDS_WITH:
      return !factValue?.endsWith?.(value);
    case OperatorsEnum.IS_NOT_BLANK:
      if (Array.isArray(factValue)) {
        return factValue.length > 0;
      }
      return factValue !== null && factValue !== undefined && factValue !== '';
    case OperatorsEnum.IS_BLANK:
      if (Array.isArray(factValue)) {
        return factValue.length === 0;
      }
      return factValue === null || factValue === undefined || factValue === '';
    case OperatorsEnum.IN_LAST_DAYS:
      if (factValue instanceof Date) {
        const date = new Date();
        date.setDate(date.getDate() - value);
        return factValue >= date;
      }
      return false;
    case OperatorsEnum.IN_LAST_WEEKS:
      if (factValue instanceof Date) {
        const date = new Date();
        date.setDate(date.getDate() - value * 7);
        return factValue >= date;
      }
      return false;
    case OperatorsEnum.IN_LAST_MONTHS:
      if (factValue instanceof Date) {
        const date = new Date();
        date.setMonth(date.getMonth() - value);
        return factValue >= date;
      }
      return false;
    case OperatorsEnum.IN_NEXT_DAYS:
      if (factValue instanceof Date) {
        const date = new Date();
        date.setDate(date.getDate() + value);
        return factValue <= date;
      }
      return false;
    case OperatorsEnum.IN_NEXT_WEEKS:
      if (factValue instanceof Date) {
        const date = new Date();
        date.setDate(date.getDate() + value * 7);
        return factValue <= date;
      }
      return false;
    case OperatorsEnum.IN_NEXT_MONTHS:
      if (factValue instanceof Date) {
        const date = new Date();
        date.setMonth(date.getMonth() + value);
        return factValue <= date;
      }
      return false;
    case OperatorsEnum.IS_BIZ_ADMIN:
      return userPermissionContext?.isBizAdmin === true;
    case OperatorsEnum.IS_NOT_BIZ_ADMIN:
      return userPermissionContext?.isBizAdmin === false;

    default:
      return false;
  }
};

export const evaluateConditions = (
  conditions: TopLevelCondition | ConditionProperties,
  formData: FieldValues
): boolean => {
  if ('all' in conditions && conditions.all.length) {
    return conditions.all.every((cond) => {
      if (isTopLevelCondition(cond)) {
        // Recursively evaluate if it's a nested condition
        return evaluateConditions(cond, formData);
      }
      return evaluateCondition(cond as ConditionProperties, formData);
    });
  }

  if ('any' in conditions && conditions.any.length) {
    return conditions.any.some((cond) => {
      if (isTopLevelCondition(cond)) {
        // Recursively evaluate if it's a nested condition
        return evaluateConditions(cond, formData);
      }
      return evaluateCondition(cond as ConditionProperties, formData);
    });
  }

  return false;
};

const isTopLevelCondition = (
  condition: any
): condition is TopLevelCondition => {
  return 'any' in condition || 'all' in condition;
};

export const evaluateRulesToAnOutcome = (
  rules: LayoutRuleProperties[],
  formData: FieldValues
) => {
  const outcome = rules.reduce((acc, rule) => {
    const conditionsMet = evaluateConditions(rule.conditions, formData);

    if (conditionsMet) {
      return rule.event;
    }

    return acc;
  }, {} as LayoutRuleProperties['event']);

  return outcome;
};
