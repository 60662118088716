import { createTestAttribute } from '@celito.clients/utils';
import { components, InputProps } from 'react-select';

export const InputComponent = <T,>(props: InputProps<T>) => {
  const { label } = props.selectProps as unknown as any;
  return (
    <components.Input
      {...props}
      data-testid={`input-field-${createTestAttribute(label)}`}
    />
  );
};
