import { ZIndex } from '@celito.clients/theme';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const secondaryDropdownStyles = makeStyles({
  popoverSurface: {
    maxWidth: '700px',
    ...shorthands.borderRadius('16px'),
    ...shorthands.overflow('hidden'),
    zIndex: ZIndex.Dropdown,
  },

  customMenuDropdownButton: {
    backgroundColor: 'transparent',
    ...shorthands.borderWidth(0),
    color: Color.white,
    fontSize: '1rem',
    lineHeight: '1rem',
    ...shorthands.padding('8px', '0.7rem'),
    display: 'flex',
    alignItems: 'center',
    ...shorthands.gap('0.375rem'),
    cursor: 'pointer',
    ...shorthands.borderRadius('0.357rem'),
    fontWeight: '500',
    fontFamily: 'Metropolis',
    fontStyle: 'normal',
    whiteSpace: 'nowrap',
    '&:hover': {
      fontWeight: 600,
    },
  },
});
