import { KeyboardEvent, MouseEvent, SyntheticEvent } from 'react';

export const objectKeys = <T extends object>(obj: T) =>
  Object.keys(obj) as (keyof T)[];

export const isEnumValue = <T extends object>(
  e: T
): ((token: unknown) => token is T[keyof T]) => {
  const keys = Object.keys(e).filter((k) => {
    return !/^\d/.test(k);
  });
  const values = keys.map((k) => {
    return e[k as keyof T];
  });
  return (token: unknown): token is T[keyof T] => {
    return values.includes(token as T[keyof T]);
  };
};

export const isKeyboardEvent = (e: SyntheticEvent): e is KeyboardEvent => {
  return (e as React.KeyboardEvent).getModifierState !== undefined;
};

export const isMouseEvent = (e: SyntheticEvent): e is MouseEvent => {
  return e.type === 'click' || e.type === 'mouseup' || e.type === 'mousedown';
};
