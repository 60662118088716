import { ResultCardObject } from '../search-result.model';
import { IAdvancedSearch } from '../services/apiServices';
import { getResultCardContent } from './result-card.util';

export const getResultCardDataFromApiResponse = (
  apiResponse: IAdvancedSearch
): ResultCardObject[] => {
  return apiResponse.result?.flatMap((module) =>
    module.searchResult?.flatMap((result) =>
      getResultCardContent(result.fields, module.module)
    )
  );
};

export const getQuickFiltersFromApiResponse = (
  apiResponse: IAdvancedSearch
) => {
  if (apiResponse.result.length > 1) {
    const modules = apiResponse.result.map(
      (result) => `${result.module} (${result?.resultCount})`
    );

    const resultCountAllApplications = apiResponse.result.reduce(
      (sum, result) => sum + result.resultCount,
      0
    );

    return [`All Application (${resultCountAllApplications})`, ...modules];
  }

  return [
    `${apiResponse.result?.[0]?.module} (${apiResponse.result?.[0]?.resultCount})`,
  ];
};

export const getFacetApiPayloadFromApiResponse = (
  apiResponse: IAdvancedSearch
) => {
  return apiResponse.result?.flatMap((module) =>
    module.searchResult?.flatMap((result) => {
      return result.index;
    })
  );
};

export const getFaucetListFromApiResponse = (
  apiResponse: IAdvancedSearch,
  currentModule: string | null
) => {
  const currentModuleNoCount = currentModule?.split('(')?.[0].trim();

  if (
    !currentModuleNoCount ||
    currentModuleNoCount === 'undefined' ||
    !apiResponse ||
    apiResponse.result.length === 0
  )
    return [];

  const currentFactByModule = Object?.values(
    apiResponse.Fact[currentModuleNoCount]
  );

  return currentFactByModule.map((fact) => {
    return {
      key: fact.key,
      label: fact.name,
      items: fact.values,
      dataType: fact.dataType,
      module: fact.module,
    };
  });
};
