import {
  Field,
  ObjectAttributeDefinition,
  ObjectAttributeType,
} from '@celito.clients/types';

import { Validation } from '../types/validation-types';
import { getAttributeByColumnName } from './attribute-config';

export const getValidations = (
  attributeConfig = {} as ObjectAttributeType,
  fields: Field[] = []
) => {
  const validations = Array<ObjectAttributeDefinition>();
  const filteredFields = (fields || []).filter(
    (field: Field) => field.columnName !== 'space'
  );
  filteredFields.forEach((field: Field) => {
    const attribute = getAttributeByColumnName(
      attributeConfig,
      field.columnName
    );
    const isChildValidationPresent = attribute?.childs?.[0]?.rules?.length;

    if (attribute?.rules?.length) {
      validations.push(attribute);
    }
    if (
      isChildValidationPresent &&
      attribute?.childs?.[0].lookupAttributeName
    ) {
      const childAttribute = getAttributeByColumnName(
        attributeConfig,
        attribute.childs[0].lookupAttributeName
      );

      childAttribute.rules = attribute.childs[0].rules?.map(
        (rule: Validation) => {
          return {
            ...rule,
            referenceLookName: attribute.name,
            compareValue: attribute?.childs?.[0].value,
            isChildValidation: true,
          };
        }
      );
      validations.push(childAttribute);
    }
  });
  return validations;
};

export const ruleValue = (type: string, rules: Validation[] | undefined) =>
  rules?.find((rule) => rule.type === type)?.value;
