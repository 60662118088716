import { Icon } from '@celito.clients/shared';
import {
  Menu,
  MenuButton,
  MenuItemRadio,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';

import { ViewTypeMenuProps } from '../search-result.model';
import classes from '../search-result.module.css';

const ViewTypeMenu: React.FC<ViewTypeMenuProps> = (props) => {
  return (
    <div className={classes.view_menu}>
      <Menu
        checkedValues={props.selectedOption}
        onCheckedValueChange={props.onChange}
      >
        <MenuTrigger disableButtonEnhancement>
          <MenuButton
            icon={
              props.selectedOption.view[0] === 'tile' ? (
                <Icon iconName="TextBulletListLtr16Filled" />
              ) : (
                <Icon iconName="AppFolder20Regular" />
              )
            }
            className={classes.view_type_button}
          ></MenuButton>
        </MenuTrigger>
        <MenuPopover>
          <MenuList>
            <MenuItemRadio
              icon={<Icon iconName="TextBulletListLtr16Filled" />}
              name="view"
              value="tile"
            >
              Tile View
            </MenuItemRadio>
            <MenuItemRadio
              icon={<Icon iconName="AppFolder20Regular" />}
              name="view"
              value="card"
            >
              Card View
            </MenuItemRadio>
          </MenuList>
        </MenuPopover>
      </Menu>
    </div>
  );
};

export default ViewTypeMenu;
