import {
  ActionTypeEnum,
  FormEngineModeTypeEnum,
  ReferencePickerType,
} from '@celito.clients/enums';
import {
  LifeCycleStageUserActionsPermissionEnum,
  RecordAction,
  validateActionRules,
} from '@celito.clients/list-view-engine';
import {
  Field,
  ObjectActionDefinition,
  ObjectAttributeType,
  ViewDto,
} from '@celito.clients/types';
import { formatDateInTimezone } from '@celito.clients/utils';
import { ActionLevelEnum } from 'libs/core/src/enums/action-type';
import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { getText } from 'libs/shared/src/lib/in-house-input-select/utils';
import { transformMultipleRulesIntoFilter } from 'libs/shared/src/lib/rules-component/utils/utils';
import { get, isBoolean, isNil } from 'lodash';
import {
  DefaultValues,
  FieldErrors,
  FieldValues,
} from 'react-hook-form/dist/types';

import { MappingTypeEnum } from '../components/form-components/controlled-picker/controlled-picker.model';
import { UserActions } from '../components/form-wizard/form-wizard.model';
import { userObjectName } from '../config';
import { AttributeTypeEnum } from '../enums/attributes-enum';
import { RecorLevelUserContextFields } from '../types/common-types';
import {
  getAttributeByColumnName,
  getAttributeByKeyName,
} from './attribute-config';

type ViewAction = ViewDto['view'][number]['actions'][number];

export const getFormValues = (
  attributeConfig: ObjectAttributeType,
  recordDetails: Record<string, unknown>
) => {
  const formValue: Record<string, unknown> = {};
  Object.keys?.(recordDetails)?.forEach((key) => {
    // storing user's record level context in form values for layout rules and further use
    if (key === RecorLevelUserContextFields.RECORD_AND_SYSTEM_ROLES) {
      formValue[key] = recordDetails[key];
      return;
    }

    const attribute = getAttributeByKeyName(attributeConfig, key);
    if (!attribute.name) return;

    if (
      Array.isArray(recordDetails[key]) &&
      attribute.dataType !== AttributeTypeEnum.Reference &&
      attribute.dataType !== AttributeTypeEnum.MultipleDocument
    ) {
      formValue[attribute.name] = JSON.stringify(recordDetails[key]);
    } else if (typeof recordDetails[key] === 'object') {
      if (attribute.dataType === AttributeTypeEnum.Reference) {
        if (attribute.relationship?.objectName === userObjectName) {
          formValue[attribute.name] = recordDetails[key];
        } else {
          formValue[attribute.name] = recordDetails[key] as Record<
            string,
            unknown
          >;
        }
      } else {
        formValue[attribute.name] = (
          recordDetails[key] as Record<string, unknown>
        )?.name;
      }
    } else if (!isNil(recordDetails[key])) {
      formValue[attribute.name] = recordDetails[key];
    }

    if (['users__s', 'available_members__a'].includes(attribute.name)) {
      formValue[attribute.name] = recordDetails[attribute.key] || [];
    }
    if (
      ['file__a', 'attachments__a', 'attachments__s'].includes(attribute.name)
    ) {
      formValue[attribute.name] = recordDetails[attribute.key];
    }
  });
  return formValue;
};

export const isFormValid = (errors: FieldErrors<FieldValues>) => {
  return Object.keys(errors).length === 0;
};

export const refactorFormData = (
  attributeConfig: ObjectAttributeType,
  data: FieldValues
) => {
  const copiedObject = { ...data };

  Object.keys(copiedObject).forEach((key) => {
    if (typeof copiedObject[key] === 'object') {
      const attribute = attributeConfig.objectAttributeDefinitions.find(
        (definition) => definition.name === key
      );
      if (attribute?.dataType === AttributeTypeEnum.Reference) {
        const isMulti =
          attribute?.relationship?.objectMappingType ===
            MappingTypeEnum.OneToMany ||
          attribute?.relationship?.objectMappingType ===
            MappingTypeEnum.ManyToMany;
        if (isMulti) {
          copiedObject[key] =
            copiedObject[key]?.map((item: Record<string, string>) => {
              return attributeConfig.isVersioningEnabled && item?.version
                ? {
                    name: item?.name,
                    version: item.version,
                  }
                : item?.name;
            }) ?? null;
        } else if (!copiedObject[key]?.name) {
          copiedObject[key] = null;
        } else {
          copiedObject[key] =
            attributeConfig.isVersioningEnabled && copiedObject[key]?.version
              ? JSON.stringify({
                  name: copiedObject[key]?.name,
                  version: copiedObject[key].version,
                })
              : copiedObject[key]?.name;
        }
      } else if (attribute?.dataTypeKeyForFE === AttributeTypeEnum.SetRule) {
        if (!copiedObject[key]?.rules) {
          copiedObject[key] = undefined;
        } else {
          const transformedRules = transformMultipleRulesIntoFilter(
            copiedObject[key].rules,
            attributeConfig
          );
          copiedObject[key] = [transformedRules?.[0]?.filtersToBeApplied?.[0]];
        }
      } else if (
        attribute?.dataType === AttributeTypeEnum.Date ||
        attribute?.dataType === AttributeTypeEnum.DateTime
      ) {
        copiedObject[key] = copiedObject[key]
          ? copiedObject[key].toISOString()
          : null;
      }
    }
    if (copiedObject[key] === undefined) {
      delete copiedObject[key];
    }
  });

  return copiedObject;
};

export const getFormEngineDefaultValues = (
  attributeConfig: ObjectAttributeType
) => {
  const defaultValues: DefaultValues<FieldValues> = {};

  if (!attributeConfig) {
    return defaultValues;
  }

  attributeConfig.objectAttributeDefinitions.forEach((attribute) => {
    if (attribute.defaultValue) {
      if (attribute.dataType === AttributeTypeEnum.YesNo) {
        defaultValues[attribute.name] = isBoolean(attribute.defaultValue)
          ? attribute.defaultValue
          : attribute.defaultValue === 'Yes';
        return;
      }

      defaultValues[attribute.name] = attribute.defaultValue;
    }
  });

  return defaultValues;
};

export const atLeastOneValueExists = (
  data: Record<string, unknown>,
  fields: Field[]
) => {
  const fieldNames = fields.map((field) => field.columnName);
  return fieldNames.some((fieldName) => {
    const value = data[fieldName];
    if (Array.isArray(value)) {
      return value.length > 0;
    }
    return value;
  });
};

export const groupFieldsByRow = (fields: Field[]): Record<number, Field[]> => {
  return fields.reduce((acc, field) => {
    const row = field.row as number;
    if (!acc[row]) {
      acc[row] = [];
    }
    acc[row].push(field);
    return acc;
  }, {} as Record<number, Field[]>);
};

export const getFormActionsVisibilityRules = (
  lifecycleStageGroups: ObjectAttributeType['lifecycleStageGroups'] | undefined,
  recordLifecycleStage: string | undefined | null,
  actionType: 'edit' | 'update',
  mode?: string
) => {
  const formActionRuleByRecordStageAndAction = lifecycleStageGroups?.[
    recordLifecycleStage ?? ''
  ]?.actionTypeRules?.find?.((rule) => rule.actionType === actionType);

  const showSubmitButton = (() => {
    if (mode === FormEngineModeTypeEnum.View) {
      return false;
    }
    if (formActionRuleByRecordStageAndAction?.showSubmitButton !== undefined) {
      return formActionRuleByRecordStageAndAction?.showSubmitButton;
    }
    return true;
  })();

  const showSaveButton =
    formActionRuleByRecordStageAndAction?.showSaveButton !== undefined
      ? formActionRuleByRecordStageAndAction?.showSaveButton
      : true;

  return { showSaveButton, showSubmitButton };
};

export const getUserActions = (
  useractions: UserActions[],
  attributeConfig: ObjectAttributeType,
  rowItem: RowItem,
  view?: ViewDto,
  isValidateActionRules = false
) => {
  const listOfActions = attributeConfig.objectActionDefinitions ?? [];
  const viewActions = new Set<ViewAction>(get(view, 'view[0].actions', []));
  const filterActions = new Array<ObjectActionDefinition>();
  if (useractions?.length) {
    useractions
      .filter(
        (userAction) =>
          userAction.permission !== LifeCycleStageUserActionsPermissionEnum.HIDE
      )
      .forEach((useraction: UserActions) => {
        listOfActions?.forEach((actions: ObjectActionDefinition) => {
          if (
            actions.name === useraction.name &&
            actions.actionType !== ActionTypeEnum.EDIT_DOCUMENT &&
            (isValidateActionRules
              ? validateActionRules(
                  actions.actionRule,
                  rowItem,
                  attributeConfig
                )
              : true)
          )
            filterActions.push(actions);
        });
      });
  } else {
    listOfActions
      .filter(
        (action: ObjectActionDefinition) =>
          action.actionType !== ActionTypeEnum.VIEW
      )
      .forEach((action: ObjectActionDefinition) => {
        const isPushable = !viewActions.size || viewActions.has(action.name);
        if (isPushable)
          if (validateActionRules(action.actionRule, rowItem, attributeConfig))
            filterActions.push(action);
      });
  }

  return filterActions.filter(
    (a: RecordAction) => a?.actionLevel === ActionLevelEnum.RecordAction
  );
};

export const padLeftWithZero = (index: number) => {
  return index < 10 ? `0${index}` : `${index}`;
};

export interface IValue {
  name?: string;
  label?: string;
  title?: string;
}

export const transformPickerValueObject = (
  value?: IValue | IValue[],
  pickerType?: ReferencePickerType
) => {
  if (Array.isArray(value))
    return value.map((v) => {
      return {
        text: getText(v?.label, v?.title, pickerType) ?? '',
        value: v?.name ?? '',
      };
    });

  if (!value) return undefined;

  return {
    text: getText(value?.label, value?.title, pickerType) ?? '',
    value: value?.name ?? '',
  };
};

const checkIsValueAnArray = (value: Record<string, any>[] | string) => {
  if (Array.isArray(value)) {
    if (value.length > 0) {
      return JSON.stringify(value.map((val) => val?.name));
    }
    return JSON.stringify(null);
  } else {
    return value;
  }
};

export const getRequiredFormatData = (
  value: Record<string, any>[] | string,
  columnName: string,
  attributeConfig: ObjectAttributeType
) => {
  if (value !== undefined && value !== null && !(value instanceof Date)) {
    const attribute =
      attributeConfig && getAttributeByColumnName(attributeConfig, columnName);
    if (attribute && attribute.dataType === AttributeTypeEnum.Reference) {
      const isMulti = [
        MappingTypeEnum.OneToMany,
        MappingTypeEnum.ManyToMany,
      ].includes(attribute.relationship?.objectMappingType as MappingTypeEnum);

      if (!isMulti) {
        return (value as Record<string, any>)?.name;
      }
    }
    return checkIsValueAnArray(value);
  }
  if (value instanceof Date) {
    return formatDateInTimezone(value);
  }

  return value;
};

export const getFormActionPermission = (
  recordDetails: Record<string, unknown>,
  attributeConfig: ObjectAttributeType
) => {
  const editActionDefination = attributeConfig?.objectActionDefinitions.find(
    (action) => action.actionType === ActionTypeEnum.EDIT
  );
  const showEditButton = editActionDefination
    ? getUserActions(
        (recordDetails?.recordUserActions ??
          recordDetails?.recordObjectUserActions ??
          []) as UserActions[],
        attributeConfig,
        recordDetails as RowItem,
        undefined,
        (recordDetails?.recordObjectUserActions as UserActions[])?.length > 0
      ).filter((action) => action.name === editActionDefination.name).length > 0
    : true;

  return { showEditButton };
};
