import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const celitoPanelStyles = makeStyles({
  drawerHeaderTitle: {
    color: Color.gray_700,
    textAlign: 'left',
    fontFamily: ThemeFontFamily.sans,
  },

  drawerHeader: {
    backgroundColor: Color.drawer_header_color,
    ...shorthands.padding('1rem'),
  },

  overflowEllipsis: {
    maxWidth: '30rem',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
});
