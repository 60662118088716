import { PeoplePickerProps } from './people-picker.model';
import PeoplePickerView from './people-picker.view';

interface PeoplePickerControllerProps extends PeoplePickerProps {}

const PeoplePickerController = ({
  onChange,
  ...props
}: PeoplePickerControllerProps): JSX.Element | null => {
  return <PeoplePickerView options={[]} {...props} />;
};

export default PeoplePickerController;
