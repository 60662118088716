import {
  DelegationIcon,
  LocalizationString,
  UserIcon,
} from '@celito.clients/assets';
import { SecondaryDropdownConfig } from '@celito.clients/shared';

import { ROUTES } from '../enums/routes';

export const headerSecondaryDropdown: SecondaryDropdownConfig = {
  name: LocalizationString.REQUEST,
  data: [
    {
      name: LocalizationString.MATERIAL,
      descripton: LocalizationString.MATERIAl_dESCRIPTION,
      iconSrc: UserIcon,
      route: ROUTES.LIST_VIEW.replace(':viewName', 'training_material_view__a'),
    },
    {
      name: LocalizationString.DELEGATION,
      descripton: LocalizationString.DELEGATE_TO_OTHER_USERS,
      iconSrc: DelegationIcon,
      route: ROUTES.DELEGATION,
    },
  ],
};
