import {
  ObjectAttributeDefinition,
  ObjectAttributeType,
  OwnerType,
} from '../types/object-attribute-types';

export const createFormEngineConfig = (
  objectAttributeDefinitions: ObjectAttributeDefinition[]
): ObjectAttributeType => {
  // Since form engine works via meta-data, these extra props must be hardcoded (they won't be displayed if form-engine is being used internally)
  return {
    modifiedAtUtc: '2023-06-02T10:49:08.206Z',
    createdAtUtc: '2023-05-24T09:25:36.473Z',
    name: 'notification_template__s',
    label: 'Notification Template',
    description:
      'Email Notification table to store email template and in app notification text',
    isAuditable: true,
    eSignatureRequired: false,
    isDomainEntity: false,
    tableName: 'NotificationTemplate__s',
    isDeletable: true,
    createRoute: 'create/custom/notification_template__s',
    objectActionDefinitions: [],
    isActive: true,
    ownerType: OwnerType.System,
    objectAttributeDefinitions,
    isVersioningEnabled: false,
    objectValidationRules: [],
    isLifecycleEnabled: false,
  };
};
