import { LocalizationString } from '@celito.clients/assets';
import { DocumentDownloadActionEnum } from '@celito.clients/enums';
import { files, getDocumentData } from '@celito.clients/services';
import { DocData } from '@celito.clients/types';
import { errorToast } from '@celito.clients/utils';
import { useCallback, useState } from 'react';
import { useFormContext } from 'react-hook-form';

import { FileDataProps } from '../multi-file-upload.model';
import { MultiFileViewProps } from './multi-file-view.model';
import MultiFileViewView from './multi-file-view.view';

export const MultiFileViewController = (props: MultiFileViewProps) => {
  const [loading, setLoading] = useState<boolean>(false);

  const { getValues } = useFormContext() ?? {};

  const { fileNames, objectName } = props;

  const handleDownload = (fileName: string) => async () => {
    try {
      // FIXME: Remove hardcoded attribute name
      const values = fileNames;
      const [, recordName] = getValues(['attachments__a', 'name__s']);
      const targetDoc = values?.find?.((doc: File | DocData) =>
        doc instanceof File ? doc.name === fileName : doc.label === fileName
      );
      if (targetDoc instanceof File) {
        files.downloadFileBuffer({
          data: targetDoc,
          label: targetDoc.name,
        });
      } else {
        if (!targetDoc.documentId) throw new Error("Can't download file");
        files.downloadFile(
          getDocumentData(
            targetDoc.documentId,
            objectName,
            recordName,
            undefined,
            DocumentDownloadActionEnum.DownloadRaw
          ),
          (fileDocument) => {
            if (!fileDocument) throw new Error("Can't download file");
            files.downloadFileBuffer({
              data: fileDocument.fileBuffer.data,
              label: fileDocument.label,
              mimeType: fileDocument.mimeType,
            });
          }
        );
      }
    } catch (error) {
      errorToast({
        message: LocalizationString.THERE_WAS_SOME_PROBLEM_DOWNLOADING_THE_FILE,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleClientDownload = useCallback(
    (idx: number) => {
      files.downloadFileBuffer({
        data:
          getValues('attachments__a')[idx] ?? getValues('attachments__s')[idx],
        label: fileNames[idx],
      });
    },
    [getValues, fileNames]
  );

  return (
    <MultiFileViewView
      {...props}
      handleClientDownload={handleClientDownload}
      handleDownload={handleDownload}
      showDownloadBtn={true}
      fileNames={(() => {
        const value = fileNames;
        if (!value) return [];
        const fileArray = value as unknown as (File | FileDataProps)[];
        return fileArray.map((file) =>
          file instanceof File ? file?.name : file?.label
        );
      })()}
      isLoading={loading}
    />
  );
};
