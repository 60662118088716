import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { ListView } from '@celito.clients/list-view-engine';
import {
  ConfirmDialog,
  CustomButton,
  HeaderActionButtonsContainer,
  RadioButton,
  Stack,
} from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { RadioProps } from '@fluentui/react-components';
import { Label } from 'libs/shared/src/lib/label/src';
import { ReactNode } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  InitiationType,
  ObjectInitiationProps,
  ObjectInitiationViewNameEnum,
} from './object-initiation-screen.model';
import classes from './object-initiation-screen.module.css';

interface ObjectInitiationViewScreenProps extends ObjectInitiationProps {
  initiationType: InitiationType;
  openUpdatePickerModal: boolean;
  options: RadioProps[];
  handleChange: (value: string) => void;
  handleInitiateBtnClick: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  renderUpdatePickerModal: () => ReactNode;
  showUpdateInititationConfirmationModal: boolean;
  setShowUpdateInititationConfirmationModal: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  onUpdateInitiationConfirmation: () => void;
  loading: boolean;
  objectLabel: string;
}

export const ObjectInitiationView: React.FC<ObjectInitiationViewScreenProps> = (
  props
) => {
  const { viewName = '' } = useParams();
  const {
    options,
    initiationType,
    handleChange,
    handleInitiateBtnClick,
    renderUpdatePickerModal,
    showUpdateInititationConfirmationModal,
    setShowUpdateInititationConfirmationModal,
    onUpdateInitiationConfirmation,
    loading,
    objectLabel,
  } = props;

  const config = (() => {
    switch (viewName) {
      case ObjectInitiationViewNameEnum.ControlledDocumentInitiation:
        return {
          title: LocalizationString.UNSUBMITTED_CONTROLLED_DOCUMENTS,
          createViewName: 'controlled_document_create_view__a',
        };

      case ObjectInitiationViewNameEnum.CourseInitiation:
        return {
          title: LocalizationString.ALL_UNSUBMITTED_COURSES,
          createViewName: 'course_create_view__a',
        };
      default:
        return {
          title: LocalizationString.ALL_UNSUBMITTED_CURRICULUM,
          createViewName: 'curriculum_create_view__a',
        };
    }
  })();

  return (
    <div className={classes.mainContainer}>
      <Stack className={classes.stackContainer}>
        <Label data-testid={`tab-heading`} className={classes.label}>
          {LocalizationString.INITIATION_TYPE}
        </Label>
        <RadioButton
          layout="horizontal"
          data-testid="subheading-request-type"
          options={options}
          label=""
          style={{
            // NOTE: To compensate for the default input field label
            marginTop: -2,
          }}
          value={initiationType}
          onChange={handleChange}
        />
      </Stack>
      <Stack className={classes.stackContainer}>
        <Label data-testid={`tab-heading`} className={classes.label}>
          {config.title}
        </Label>
        <ListView viewName={viewName} dontSetHeader />

        {renderUpdatePickerModal()}
      </Stack>

      <HeaderActionButtonsContainer>
        <Link
          to={{
            search: `?requestType=${initiationType}`,
            pathname: `../create/${config.createViewName}`,
          }}
          className={classes.listViewAddBtn}
        >
          <CustomButton
            as="button"
            buttonTitle={LocalizationString.INITIATE}
            buttonType={ButtonTypes.Transparent}
            appearance="primary"
            onClick={handleInitiateBtnClick}
            customStyles={classes.listViewAddBtn}
          />
        </Link>
      </HeaderActionButtonsContainer>

      <ConfirmDialog
        dataTestId={`${createTestAttribute(
          LocalizationString.UPDATE_INITIATION_CONFIRMATION
        )}-modal`}
        open={showUpdateInititationConfirmationModal}
        onCancelClicked={() => setShowUpdateInititationConfirmationModal(false)}
        onConfirmClicked={onUpdateInitiationConfirmation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UPDATE_INITIATION_CONFIRMATION}
        iconSrc={WarningIcon}
        description={LocalizationString.UPDATE_INITIATION_CONFIRMATION_MSG.replace(
          '{objectName}',
          objectLabel
        )}
        isPrimaryButtonLoading={loading}
      />
    </div>
  );
};
