import { RadioButton, Stack } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { ControlledRadioGroupProps } from './controlled-radio-group.model';
import classes from './controlled-radio-group.module.css';

export const ControlledRadioGroupView = <T extends FieldValues>({
  ...props
}: ControlledRadioGroupProps<T> & { inferredValue: boolean | null }) => {
  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({
        field: { onBlur, onChange, value },
        fieldState: { error },
      }) => {
        return (
          <Stack
            data-testid={`radio-group-container-${createTestAttribute(
              props.attribute?.label
            )}`}
            className={classes.section}
          >
            <RadioButton
              dataTestId={`radio-button-label-${createTestAttribute(
                props.attribute.label
              )}`}
              value={
                props.inferredValue !== null
                  ? `${props.inferredValue}`
                  : value !== undefined
                  ? `${value}`
                  : undefined
              }
              label={props.attribute.label}
              options={props.options}
              onChange={onChange}
              onBlur={onBlur}
              errorMessage={error?.message}
              required={
                'isRequired' in props.attribute
                  ? props.attribute.isRequired
                  : props.attribute.isMandatory
              }
              disabled={!props.attribute?.isEditable}
              helperLabelText={props.attribute?.helpText}
            />
          </Stack>
        );
      }}
    />
  );
};
