import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const delagationModalStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.borderRadius('6px'),
    boxSizing: 'border-box',
    width: '25rem',
  },

  modalHeader: {
    display: 'flex',
    ...shorthands.padding('0.75rem'),
    borderTopRightRadius: '5px',
    borderTopLeftRadius: '5px',
    backgroundColor: Color.drawer_header_color,
    alignItems: 'center',
    fontSize: '1rem',
    fontWeight: 700,
    justifyContent: 'space-between',
    width: '24.9rem',
  },

  modalBody: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    ...shorthands.padding('1.5rem'),

    ...shorthands.gap('1rem'),
  },

  modalFooter: {
    ...shorthands.gap('1rem'),
    ...shorthands.padding('0.75rem'),
    width: '24.8rem',
    backgroundColor: Color.drawer_header_color,
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
    justifyContent: 'center',
  },

  referencePickerSize: {
    width: '100%',
  },
  fieldsWidth: {
    width: '100%',
  },
  modalWidth: {
    width: '25rem',
  },
});
