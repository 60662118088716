import { LocalizationString, UrlString } from '@celito.clients/assets';
import {
  ActionTypeEnum,
  DateFormat,
  TaskStatusEnum,
} from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { UserContext } from '@celito.clients/provider';
import { Icon } from '@celito.clients/shared';
import {
  ObjectActionDefinition,
  ObjectAttributeType,
  Task,
} from '@celito.clients/types';
import { createTestAttribute, formatDate } from '@celito.clients/utils';
import { Label, mergeClasses, Persona } from '@fluentui/react-components';
import cn from 'classnames';
import { memo, useContext } from 'react';

import WorkflowActions from '../workflow-actions/workflow-actions.component';
import { TaskActions } from '../workflow-actions/workflow-actions.model';
import { getStyles } from './styles';
import {
  ActionButtonType,
  GroupNames,
  TaskOutCome,
  VerdictSchema,
} from './types';

interface WorkflowTaskProps {
  tasks: Task;
  idx?: number;
  groupName: GroupNames | null;
  isActive: boolean;
  labelName: string;
  showLine: boolean;
  isFirstTaskIndex: boolean;
  ownerName: string;
  objectActionDefinitions: ObjectActionDefinition[];
  objectDefinitions?: ObjectAttributeType;
  recordData?: Record<string, unknown>;
  currentTaskOwners: string[];
  callWorkflowAPIOnEvent: (updatedVersion: string) => void;
  refetchRecordData: () => void;
  taskActions: TaskActions[];
  loading: boolean;
  popupRef?: React.RefObject<HTMLDivElement>;
  isDelegatedTask: boolean;
}

function LabelValue({
  label,
  value,
  dataTestId,
  customValue,
  className,
}: {
  label?: string;
  value?: string;
  dataTestId?: string;
  customValue?: React.ReactNode;
  className?: string;
}) {
  const styles = getStyles();
  if (!value && !label) return null;
  return (
    <div className={mergeClasses(styles.flex50, className)}>
      {label ? (
        <Label data-testid={`${dataTestId}`}>
          {label}
          {': '}
        </Label>
      ) : null}
      <Label
        weight={
          label !== LocalizationString.COMMENT &&
          label !== LocalizationString.REJECT_REASON
            ? 'semibold'
            : 'regular'
        }
        className={cn(
          label === LocalizationString.STATUS ? styles.documentStatusText : null
        )}
        data-testid={`${dataTestId}-value`}
      >
        {value}
      </Label>
    </div>
  );
}

const TaskListComponent = memo((props: WorkflowTaskProps) => {
  const { tasks, isDelegatedTask } = props;
  const styles = getStyles();
  const rootTasksDataTestId = `${createTestAttribute(tasks?.label)}-task`;

  const loggedInUserData = useContext(UserContext);
  const activeModule = useActiveModule();
  const currentModuleBizRole =
    activeModule?.systemName === UrlString.CD_BASE_URL
      ? 'cd_biz_admin_role__a'
      : 'ul_biz_admin_role__a';
  const verdict = (() => {
    try {
      return tasks.selectedVerdictData &&
        typeof tasks.selectedVerdictData === 'string'
        ? JSON.parse(tasks?.selectedVerdictData)
        : null;
    } catch (e) {
      return null;
    }
  })() as VerdictSchema | null;

  const taskStatus = (() => {
    const outcome = tasks?.status;

    switch (outcome) {
      case 'Done':
        return TaskOutCome.COMPLETE;
      case 'Cancel':
        return TaskOutCome.CANCELED;
      case 'Todo':
        return TaskOutCome.ASSIGNED;
      default:
        return '';
    }
  })();

  const taskDateTime = () => {
    return tasks?.assignedAtUtc ?? tasks?.claimedAtUtc ?? tasks?.createdAtUtc;
  };
  const taskDueDate = tasks?.dueDate;

  const getTaskAssignee = () => {
    if (tasks?.owner?.label) {
      return tasks?.owner?.label;
    }
    if (typeof tasks.assignedTo === 'string') {
      return JSON.parse(tasks.assignedTo).assignedToDisplayName;
    }
    if (tasks.assignedTo) {
      return tasks.assignedTo.assignedToDisplayName;
    }
  };
  const isTaskCompleted = () => {
    if (
      tasks?.status === TaskStatusEnum.Done ||
      tasks?.status === TaskStatusEnum.Cancel
    ) {
      return true;
    }
    return false;
  };

  const checkIsOwner = () => props?.ownerName === loggedInUserData?.user?.name;

  // if the logged in user has the current module biz admin role e.g if module id cd then user should have cd_biz_admin_role__a
  const checkIsModuleBizAdmin = () =>
    loggedInUserData?.roles?.includes(currentModuleBizRole);

  const renderStageActions = () => {
    return !props?.isActive &&
      props?.labelName &&
      (checkIsOwner() || checkIsModuleBizAdmin() || isDelegatedTask) ? (
      <WorkflowActions
        buttonType={ActionButtonType.ICON}
        taskName={tasks.name}
        taskActions={props?.taskActions}
        actionType={ActionTypeEnum.STAGE}
        objectActionDefinitions={props?.objectActionDefinitions}
        objectDefinitions={props?.objectDefinitions}
        recordData={props?.recordData}
        users={!props?.isActive ? props?.currentTaskOwners : []}
        callWorkflowAPIOnEvent={props?.callWorkflowAPIOnEvent}
        loading={props?.loading}
        refetchRecordData={props?.refetchRecordData}
        popupRef={props?.popupRef}
        dataTestId={`${createTestAttribute(tasks.label)}-add-participants`}
        currentTaskTag={tasks?.tag}
      />
    ) : null;
  };

  const renderTaskActions = () => {
    return !isTaskCompleted() &&
      (checkIsOwner() || checkIsModuleBizAdmin() || isDelegatedTask) ? (
      <WorkflowActions
        buttonType={ActionButtonType.ICON}
        taskName={tasks.name}
        taskActions={props?.taskActions}
        actionType={ActionTypeEnum.TASK}
        objectActionDefinitions={props?.objectActionDefinitions}
        objectDefinitions={props?.objectDefinitions}
        recordData={props?.recordData}
        users={!props?.isActive ? props?.currentTaskOwners : []}
        callWorkflowAPIOnEvent={props?.callWorkflowAPIOnEvent}
        currentTaskDueDate={tasks?.dueDate}
        loading={props?.loading}
        refetchRecordData={props?.refetchRecordData}
        popupRef={props?.popupRef}
        dataTestId={`${createTestAttribute(tasks.label)}-${createTestAttribute(
          getTaskAssignee()
        )}-reassign`}
        currentTaskTag={tasks?.tag}
      />
    ) : null;
  };
  return (
    <>
      <tr
        className={mergeClasses(styles.taskRow)}
        data-testid={rootTasksDataTestId}
      >
        <td className={mergeClasses(styles.taskName, styles.flex15)}>
          {props?.isFirstTaskIndex && props?.groupName ? (
            <div
              className={cn(
                !props?.isActive ? styles.activeTask : styles.groupStyle,
                styles.height34
              )}
            >
              {props?.isActive ? (
                <span className={styles.groupIcon}>
                  <Icon iconName="Checkmark24Filled" />
                </span>
              ) : null}
              <span>{props?.groupName}</span>
            </div>
          ) : null}
        </td>
        <td
          className={mergeClasses(styles.taskName, styles.flex20, styles.mt6)}
        >
          <Label weight="semibold">{props?.labelName}</Label>
        </td>
        <td
          className={mergeClasses(styles.taskName, styles.flex10, styles.mt4)}
        >
          {renderStageActions()}
        </td>
        <td
          data-testid={`${rootTasksDataTestId}-list`}
          className={mergeClasses(styles.flex1, styles.flex40, styles.mt3)}
        >
          <div className={mergeClasses(styles.participant, styles.mt10)}>
            <Persona
              name={getTaskAssignee()}
              primaryText={null}
              size="large"
              textAlignment="center"
              className={styles.mt8}
            />
            <div id="data">
              {getTaskAssignee() && (
                <div className="data__row">
                  <LabelValue
                    value={getTaskAssignee()}
                    dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                      getTaskAssignee()
                    )}-user-name`}
                  />
                </div>
              )}
              <div className="data__row">
                <LabelValue
                  label={LocalizationString.ASSIGMENT_DATE}
                  dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                    getTaskAssignee()
                  )}-assignment-date`}
                  value={formatDate(taskDateTime(), DateFormat.Date)}
                />
                <LabelValue
                  dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                    getTaskAssignee()
                  )}-task-status`}
                  label={LocalizationString.TASK_STATUS}
                  value={taskStatus}
                  className={styles.displayEnd}
                />
              </div>
              <div className="data__row">
                <LabelValue
                  label={LocalizationString.DUE_DATE}
                  dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                    getTaskAssignee()
                  )}-task-due-date`}
                  value={
                    taskDueDate ? formatDate(taskDueDate, DateFormat.Date) : ''
                  }
                />
                <LabelValue
                  label={LocalizationString.STATUS}
                  dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                    getTaskAssignee()
                  )}-document-status`}
                  value={`${tasks?.objectRecordDocumentStatus} ${
                    props?.objectDefinitions?.isVersioningEnabled
                      ? `(V${tasks.objectRecordVersion})`
                      : ''
                  }`}
                  className={mergeClasses(styles.displayEnd)}
                />
              </div>
              <div className="data__row">
                {tasks?.status !== TaskStatusEnum.Todo && (
                  <LabelValue
                    label={LocalizationString.COMPLETED_DATE}
                    dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                      getTaskAssignee()
                    )}-task-completed-date`}
                    value={
                      tasks?.completedAtUtc
                        ? formatDate(tasks?.completedAtUtc, DateFormat.Date)
                        : ''
                    }
                  />
                )}
                {tasks?.status !== TaskStatusEnum.Todo && (
                  <LabelValue
                    label={LocalizationString.TASK_OUTCOME}
                    dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                      getTaskAssignee()
                    )}-task-outcome`}
                    value={verdict?.label}
                    className={styles.displayEnd}
                  />
                )}
              </div>
              {tasks?.status !== TaskStatusEnum.Todo &&
                (tasks?.rejectReason ? (
                  <div className="data__row">
                    <LabelValue
                      dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                        getTaskAssignee()
                      )}-task-verdict-reject-reason`}
                      label={LocalizationString.REJECT_REASON}
                      value={tasks.rejectReason}
                    />
                  </div>
                ) : (
                  <div className="data__row">
                    <LabelValue
                      dataTestId={`${rootTasksDataTestId}-list-${createTestAttribute(
                        getTaskAssignee()
                      )}-task-verdict-comments`}
                      label={LocalizationString.COMMENT}
                      value={tasks.comment}
                    />
                  </div>
                ))}
            </div>
          </div>
        </td>
        <td
          className={mergeClasses(styles.taskName, styles.flexEnd, styles.mt4)}
        >
          {renderTaskActions()}
        </td>
      </tr>
      {props?.showLine && <div className={styles.showLine}></div>}
    </>
  );
});

TaskListComponent.displayName = 'Workflow Task';

export default TaskListComponent;
