export type EmptyObject = Record<PropertyKey, never>;

export type PartialBy<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

export type PartialExcept<T, K extends keyof T> = Partial<T> & Pick<T, K>;

export type NonEmptyArray<T> = [T, ...T[]] | [...T[], T] | [T, ...T[], T];

export const isNonEmptyArray = <T>(arr: T[]): arr is NonEmptyArray<T> =>
  Array.isArray(arr) && arr.length > 0;
