import { FormEngineModeTypeEnum, ObjectEnum } from '@celito.clients/enums';
import { useActiveModule } from '@celito.clients/hooks';
import { errorToast } from '@celito.clients/utils';
import { SelectionEvents } from '@fluentui/react-combobox';
import { AxiosError } from 'axios';
import cn from 'classnames';
import { useEffect, useRef } from 'react';

import { RowItem } from '../../grid-view-new/src/types';
import { IOption } from '../../in-house-input-select/in-house-input-select.model';
import { ICommonData } from '../../in-house-input-select/services';
import { InHouseInputSelect } from '../../shared';
import ReferenceSelectorGrid from './components/reference-selector-grid';
import { ShowAllResultPanel } from './components/show-all-results-panel/show-all-results-panel.component';
import {
  ReferenceSelectorItem,
  ReferenceSelectorProps,
} from './reference-selector.model';
import classes from './reference-selector.module.css';
import { getObjects } from './services/getSelectorData';
import { getUserFilter } from './utils/filters-by-object';

interface ReferenceSelectorViewProps extends ReferenceSelectorProps {
  onRemoveItem: (item: RowItem) => void;
  onDownloadItem?: (item: RowItem) => void;
  showDownloadButton: boolean | undefined;
  onOptionSelect: (
    _ev: SelectionEvents,
    optionText: string,
    optionValue: string,
    selectedItems: IOption[] | IOption,
    data?: ICommonData
  ) => void;
  searchKey: string;
  setSearchKey: (searchKey: string) => void;
  isShowAllResultsViewOpen: boolean;
  onShowAllResult: () => void;
  handleOnAddClick: (items: ReferenceSelectorItem[]) => void;
}

const ReferenceSelectorView: React.FC<ReferenceSelectorViewProps> = ({
  selectedItems,
  onRemoveItem,
  onDownloadItem,
  label,
  referencePickerProps,
  objectName,
  onRowReorder,
  canReorderRows,
  pickerClassName = '',
  showDownloadButton = false,
  required = false,
  mode,
  searchKey,
  setSearchKey,
  isShowAllResultsViewOpen,
  onOptionSelect,
  onShowAllResult,
  handleOnAddClick,
  onSelectorChange,
  getLatestVersionOnly,
  filterByExactRoles = false,
  excludeSelfAttachedId,
  setIsLoading,
  isLoading,
  getAllVersions,
}): JSX.Element => {
  const hasUpdatedItemsWithDepartment = useRef(false);

  const activeModule = useActiveModule();

  useEffect(() => {
    if (
      mode &&
      mode === FormEngineModeTypeEnum.Edit &&
      objectName === ObjectEnum.USER &&
      selectedItems?.length > 0 &&
      !hasUpdatedItemsWithDepartment.current
    ) {
      const items = selectedItems as unknown as ReferenceSelectorItem[];

      setIsLoading?.(true);
      const userNameList = items.map((item) => item.name);
      const params = {
        limit: userNameList.length,
        page: 1,
      };

      getObjects(
        objectName,
        activeModule ? [activeModule?.systemName] : [],
        params,
        getUserFilter(userNameList),
        undefined,
        undefined,
        filterByExactRoles
      )
        .then((res) => {
          const objects = res.objects as ReferenceSelectorItem[];

          onSelectorChange(objects);

          setIsLoading?.(false);
          hasUpdatedItemsWithDepartment.current = true;
        })
        .catch((error) => {
          if ((error as AxiosError)?.name !== 'TypeError') {
            errorToast({
              message: `Could not fetch the items`,
            });
          }
        });
    }
  }, [selectedItems]);

  return (
    <div className={classes.selectorContainer}>
      <div className={cn(classes.pickerContainer, pickerClassName)}>
        <InHouseInputSelect
          onOptionSelect={(_, data) =>
            onOptionSelect(
              _,
              data.optionText,
              data.optionValue,
              data.selectedOptions,
              data.data
            )
          }
          label={label}
          required={referencePickerProps.required || required}
          options={[]}
          referencePicker={{
            objectName,
            onIconClick: onShowAllResult,
            pickerType: referencePickerProps.referencePicker?.pickerType,
            getLatestVersionOnly: getLatestVersionOnly,
            getAllVersions: getAllVersions,
            ...referencePickerProps.referencePicker,
          }}
          selectedOptions={selectedItems?.map((selected) => {
            return {
              text: selected.label,
              value: selected.name,
              version: selected.version ? selected.version : '',
            };
          })}
          dontShowOptions
          errorMessage={referencePickerProps.errorMessage}
          disabled={referencePickerProps.disabled}
        />
      </div>

      {selectedItems?.length > 0 && (
        <ReferenceSelectorGrid
          items={selectedItems}
          onRemoveItem={onRemoveItem}
          onDownloadItem={onDownloadItem}
          objectName={objectName}
          onRowReorder={onRowReorder}
          canReorderRows={canReorderRows}
          showDownloadButton={showDownloadButton}
          loading={isLoading}
          disabled={referencePickerProps.disabled}
          label={label}
        />
      )}

      {isShowAllResultsViewOpen && (
        <ShowAllResultPanel
          excludeItems={selectedItems}
          objectName={objectName}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          isOpen={isShowAllResultsViewOpen}
          onDismiss={onShowAllResult}
          onAddClick={handleOnAddClick}
          label={label}
          defaultReferenceFilter={
            referencePickerProps?.referencePicker?.defaultReferenceFilter
          }
          multiSelect={referencePickerProps.multiselect}
          excludeSelfAttachedId={excludeSelfAttachedId}
        />
      )}
    </div>
  );
};

export default ReferenceSelectorView;
