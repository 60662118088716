export const enum WizardStepType {
  SUCCESS = 'Success',
  ERROR = 'Error',
  CURRENT = 'Current',
  INACTIVE = 'Inactive',
}

export const enum WizardStateStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  UNSUBMITTED = 'Unsubmitted',
}

export const enum ControlledDocsWizardState {
  DRAFT = 'Draft',
  Approved = 'Approved',
  Effective = 'Effective',
  'Effective-RR' = 'Effective-RR',
}
