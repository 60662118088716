import {
  ApiMethodType,
  ExportFileType,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { TemporaryUnifyDefaultAndUserFilters } from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { RulesComponentListViewFilter } from '../../../rules-component/types/rules-component.types';
import { defaultCompanyAssignmentFilters } from '../config';
import { SortConfig } from '../types';
import { defaultSortConfig } from './get-assignments-data';

export const getCompanyAssignmentExportData = async (
  trainingAssignmentType: TrainingAssignmentTypeEnum,
  fileType: ExportFileType,
  filters: RulesComponentListViewFilter,
  sortConfig?: SortConfig
) => {
  const groupType =
    trainingAssignmentType === TrainingAssignmentTypeEnum.CompanyAssignments
      ? 'company'
      : 'team';

  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    defaultCompanyAssignmentFilters,
    filters
  );

  return await performRequest<{ message: string }>(
    `${Endpoints.ASSIGNMENTS_URL}/${groupType}`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: sortConfig ?? defaultSortConfig,
    },
    {
      export: true,
      format: fileType,
    }
  );
};
