import { ROUTES } from '@celito.clients/enums';
import {
  PermissionGroupType,
  PermissionResponseType,
  UserTypes,
} from '@celito.clients/types';
import { raiseErrorToast } from '@celito.clients/utils';
import { usePermission, useUser } from 'libs/core/src/hooks';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { Item } from './view-group.model';
import ViewGroupView from './view-group.view';

const ViewGroupController: React.FC = () => {
  const [selectedList, setSelectedList] = React.useState<(string | number)[]>(
    []
  );
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [permissionGrpData, setPermissionGrpData] =
    useState<PermissionGroupType>();
  const [list, setList] = React.useState<[] | Item[]>([]);
  const [rightItemList, setRightItemList] = useState<[] | Item[]>([]);
  const { name = '' } = useParams();
  const { fetchPermissionGroupInfo } = usePermission();
  const { fetchAllUsers } = useUser();

  const navigateBackRoute = `../${ROUTES.LIST}/permission_group_view__s`;

  const navigateBack = () => {
    navigate(-1);
  };

  const onEditBtnClick = () => {
    navigate(`../${ROUTES.EDIT_PERMISSION_GROUP.replace(':name', name)}`);
  };

  const getPermissionGroupData = useCallback(() => {
    setIsLoading(true);
    fetchPermissionGroupInfo(name)
      .then((resp: PermissionResponseType) => {
        const data = resp.permissionGroup;
        setPermissionGrpData(data);
        setRightItemList(
          data.users.map((user: UserTypes) => {
            return { id: user.name, name: user.label };
          })
        );
        getAllUsers(data.users);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [name]);

  const getAllUsers = (selectedUsers: UserTypes[]) => {
    setIsLoading(true);
    const selectedUserIds = selectedUsers.map((user: UserTypes) => user.name);
    fetchAllUsers()
      .then((users: UserTypes[] | []) => {
        const userList = users
          .filter((user: UserTypes) => !selectedUserIds.includes(user.name))
          .map((user) => ({
            id: user.name,
            name: user.label,
          }));
        setList(
          userList.sort(function (a, b) {
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            return 0;
          }) as Item[]
        );
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    getPermissionGroupData();
  }, [getPermissionGroupData]);

  return (
    <ViewGroupView
      {...{
        isLoading,
        list,
        selectedList,
        setList,
        setRightItemList,
        permissionGrpData,
        setSelectedList,
        navigateBackRoute,
        rightItemList,
        onEditBtnClick,
        navigateBack,
      }}
    />
  );
};

export default ViewGroupController;
