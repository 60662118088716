import { ApiMethodType, ExportFileType } from '@celito.clients/enums';
import {
  SortConfig,
  TemporaryUnifyDefaultAndUserFilters,
} from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { defaultSortConfig, userViewDefaultFilter } from '../config';

export const getUserViewExportData = async (
  fileType: ExportFileType,
  filters: RulesComponentListViewFilter,
  sortConfig?: SortConfig
) => {
  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    userViewDefaultFilter,
    filters
  );

  return await performRequest<{ message: string }>(
    `${Endpoints.ASSIGNMENTS_URL}/company`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: sortConfig ?? defaultSortConfig,
    },
    {
      export: true,
      format: fileType,
    }
  );
};
