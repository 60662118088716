import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { IDocumentCompletion } from '../document-viewer.model';

const COURSE_OBJECT_NAME = 'course__a';

export const getDocumentCompletionStatus = async (
  documentId: string
): Promise<IDocumentCompletion> => {
  const { data } = await performRequest<IDocumentCompletion>(
    `${Endpoints.DOCUMENT_DATA_URL}/${documentId}/${Endpoints.COMPLETION_STATUS}/?objectName=${COURSE_OBJECT_NAME}`,
    ApiMethodType.GET,
    undefined
  );
  return data;
};
