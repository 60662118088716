import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { PDFViewer } from '@celito.clients/shared';
import { useState } from 'react';

import { CustomButton, Icon } from '../../shared';
import { DocumentViewerViewProps } from './document-viewer.model';
import classes from './document-viewer.module.css';

export const DocumentViewerView = (props: DocumentViewerViewProps) => {
  const [isFullScreen, setFullScreen] = useState(false);
  const viewer = props.isLocalPDF ? (
    <PDFViewer url={props.documentUrl} />
  ) : (
    <iframe
      className={classes.document_view_iframe}
      width="100%"
      height="100%"
      allowFullScreen
      title={props.documentName}
      src={props.documentUrl}
    />
  );
  return (
    <div className={classes.document_wrapper}>
      {props.previewAvailable ? (
        <div
          className={`${classes.iframe_container} ${
            isFullScreen ? `${classes.full_screen}` : ''
          }`}
        >
          {viewer}
          <button
            className={classes.full_screen_button}
            onClick={() => setFullScreen((prev) => !prev)}
            hidden={
              props?.mimeType === 'video/mp4' ||
              props?.mimeType === 'video/webm' ||
              props.isLocalPDF
            }
          >
            <Icon
              iconName={
                isFullScreen
                  ? 'ArrowStepBack16Regular'
                  : 'FullScreenMaximize16Regular'
              }
            />
          </button>
        </div>
      ) : (
        <div className={`${classes.iframe_container}`}>
          {props.errorInDocumentDownload ? (
            <div className={classes.button_container}>
              <p>{LocalizationString.DOCUMENT_NOT_AVAILABLE}</p>
            </div>
          ) : (
            <div className={classes.button_container}>
              <p>{LocalizationString.PREVIEW_NOT_AVAILABLE}</p>
              <CustomButton
                buttonTitle={LocalizationString.DOWNLOAD}
                buttonType={ButtonTypes.Primary}
                onButtonClicked={props.handleDownload}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
