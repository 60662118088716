import { ApiMethodType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { QuizAttemptsData } from '../types';

export const getQuizAttempts = async (assignmentName: string) => {
  const response = await performRequest<QuizAttemptsData>(
    `${Endpoints.ASSIGNMENTS_URL}/${assignmentName}/quiz-attempts`,
    ApiMethodType.GET
  );
  return response.data;
};
