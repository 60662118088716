/* eslint-disable prefer-const */
import { ROUTES } from '@celito.clients/enums';
import { useLayout, useQueryParams } from '@celito.clients/hooks';
import { ModalContext } from '@celito.clients/provider';
import { fetchObjectTaskInfo } from '@celito.clients/services';
import {
  APIError,
  getApiErrorMessageString,
  TrainingAssignmentData,
} from '@celito.clients/types';
import {
  getTrainingMaterialCompleteUrl,
  getULHomePageUrl,
  raiseErrorToast,
} from '@celito.clients/utils';
import { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router';

import {
  apiChoicesList,
  checkedSelectedValue,
  choiceSelectedValue,
  feedbackObj,
  QuestionTypes,
  radioSelectedValue,
} from './enums';
import {
  getCourseData,
  getLastQuizAttemptScore,
  getQuizQuestions,
  getQuizStart,
  getTrainingAssignmentData,
  submitQuiz,
  validateSelectedData,
  verifyIfQuizIsActive,
} from './services';
import { TakingQuizProps } from './taking-quiz.model';
import { TakingQuizView } from './taking-quiz.view';
import { Choice, FeedbackType, Question, QuizInfo, SubmitQuiz } from './types';

interface TakingQuizControllerProps extends TakingQuizProps {}

export const TakingQuizController = (
  props: TakingQuizControllerProps
): JSX.Element => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const checkedValue = checkedSelectedValue;
  const { getSearchParams } = useQueryParams();
  const { configureLayout } = useLayout();
  const singleValue = radioSelectedValue;
  const multiValue = choiceSelectedValue;
  const apiChoiceValue = apiChoicesList;
  const ansObj = feedbackObj;

  const questionType = useRef<string>();

  const [isLoading, setIsLoading] = useState(true);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isQuizStarted, setStartQuiz] = useState(false);
  const [showResultPage, setShowResultPage] = useState(false);
  let [defaultIndex, setDefaultIndex] = useState(0);
  const [showFeedback, setShowFeedback] = useState(false);
  const [totalPercentage, setTotalPercentage] = useState(0);
  const [showFailurePage, setShowFailurePage] = useState(false);
  const [retakeQuiz, setRetakeQuiz] = useState(false);
  const [currChoiceId, setCurrChoiceId] = useState<string>();
  const [currBooleanId, setCurrBooleanId] = useState<string>();
  const [isSuccess, setIsSuccess] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string[]>([]);
  const [items, setItems] = useState<Choice[]>([]);
  const [quizInfo, setQuizInfo] = useState<QuizInfo | undefined>();
  const [currQueId, setCurrQueId] = useState<string>();
  const [queAns, setQueAns] = useState<number>(0);
  const [selectedChoicesList, setSelectedChoicesList] = useState<string[]>();
  const [validQuiz, setValidQuiz] = useState(true);
  const [selectedCheckboxValue, setSelectedCheckboxValue] = useState<string[]>(
    []
  );
  const [trainingAssignmentData, setTrainingAssignmentData] = useState<
    TrainingAssignmentData | undefined
  >(undefined);
  const [isViewMode, setIsViewMode] = useState<boolean>(false);
  const [reRender, setReRender] = useState(true);
  const [nullIndex, setNullIndex] = useState<number>();
  const [taskName, setTaskName] = useState<string>();
  const [isQuestionAttempted, setIsQuestionAttempted] =
    useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = useState(false);
  const [isPreviousDisabled, setIsPreviousDisabled] = useState(false);

  const { openModal } = useContext(ModalContext);

  useEffect(() => {
    if (params.name) {
      configureLayout({
        pageTitle: '',
        showHeadingLoader: true,
      });

      const queryParams = getSearchParams();
      setIsLoading(true);

      getTrainingAssignmentData(queryParams.assignment_name)
        .then((trainingAssignmentResponse) => {
          setTrainingAssignmentData(trainingAssignmentResponse);
          if (trainingAssignmentResponse.hasPassedQuiz) {
            getLastQuizAttemptScore(queryParams.assignment_name)
              .then((lastQuizAttemptScoreResponse) => {
                setTotalPercentage(
                  lastQuizAttemptScoreResponse.percentageObtained
                );
              })
              .catch((_e) => {
                const e = _e as APIError;
                setIsError(true);
                setErrorMessage(getApiErrorMessageString(e));
              });
          }
          getCourseData(trainingAssignmentResponse)
            .then((courseDataResponse) => {
              setQuizInfo(
                courseDataResponse.quiz ? courseDataResponse.quiz : undefined
              );
              getTaskInformation(
                'training_assignment__a',
                queryParams.assignment_name
              );
              configureLayout({
                pageTitle: '',
                headerTitle: courseDataResponse?.quiz?.label,
              });
            })
            .catch((_e) => {
              const e = _e as APIError;
              setIsError(true);
              setErrorMessage(getApiErrorMessageString(e));
            })
            .finally(() => {
              setIsLoading(false);
            });
        })
        .catch((_e) => {
          const e = _e as APIError;
          setIsError(true);
          setErrorMessage(getApiErrorMessageString(e));
        });
    }
  }, [location.pathname]);

  useEffect(() => {
    questionType.current = questions[defaultIndex]?.questionType;
    if (questionType.current === QuestionTypes.SEQUENCE) {
      setIsQuestionAttempted(true);
    }
  }, [defaultIndex]);

  const startQuiz = () => {
    setStartQuiz(true);
    setQueAns(0);
    const queryParams = getSearchParams();

    getQuizStart(queryParams.assignment_name)
      .then((res: QuizInfo) => {
        setStartQuiz(true);
        if (params?.name && quizInfo?.version) {
          getQuizQuestions(params?.name, quizInfo?.version)
            .then((queRes: Question[]) => {
              setQuestions(queRes);
              setQueAns(0);
              checkedValue.multipleChoice = [];
              singleValue.multipleChoice = [];
              multiValue.multipleChoice = [];
              ansObj.obj = {};
              setReRender(true);
              questionType.current = queRes[0]?.questionType;
              if (questionType.current === QuestionTypes.SEQUENCE) {
                setIsQuestionAttempted(true);
              }
            })
            .catch((_e) => {
              const e = _e as APIError;
              setIsError(true);
              setErrorMessage(getApiErrorMessageString(e));
              setQueAns(0);
            });
        }
      })
      .catch((_error) => {
        raiseErrorToast(_error);
        setStartQuiz(false);
        setIsError(true);
        setQueAns(0);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    questions.map((que, index) => {
      if (que?.questionType === 'Sequence' && defaultIndex === index) {
        setItems(que?.choices);
      }
    });
  }, [defaultIndex, questions]);

  const validateIfQuizIsActive = async (): Promise<boolean> => {
    try {
      const response = await verifyIfQuizIsActive(
        quizInfo?.name ?? '',
        quizInfo?.version ?? ''
      );

      setValidQuiz(response.version === quizInfo?.version);

      return response.version === quizInfo?.version;
    } catch (e) {
      setIsError(true);
    }

    return false;
  };

  const onUpdatedQuiz = () => {
    navigate(`../${ROUTES.LIST}/my_assignment_view__a`);
  };

  const getTaskInformation = async (objectName = '', recordName = '') => {
    try {
      const response = await fetchObjectTaskInfo(objectName, recordName);
      setTaskName(response?.task?.name as string);
    } catch (err) {
      //error handler
    }
  };

  const onSubmit = () => {
    validateIfQuizIsActive().then((res) => {
      if (!res) return;
      else {
        const queryParams = getSearchParams();
        submitQuiz(queryParams.assignment_name)
          .then((res: SubmitQuiz) => {
            setShowResultPage(true);
            if (!res?.quizResultDto?.isPassed) {
              setShowFailurePage(true);
              checkedValue.multipleChoice = [];
              singleValue.multipleChoice = [];
              multiValue.multipleChoice = [];
            }
            if (res?.quizResultDto?.isRetryAllowed) {
              setRetakeQuiz(true);
            }
            setTotalPercentage(
              Number(res?.quizResultDto?.scoreObtained.split(':')[0])
            );
            setQueAns(0);
            ansObj.obj = {};
            setCurrBooleanId(undefined);
            setCurrChoiceId(undefined);
          })
          .catch((_e) => {
            const e = _e as APIError;
            setIsError(true);
            setErrorMessage(getApiErrorMessageString(e));
            setQueAns(0);
          })
          .finally(() => {
            setIsLoading(false);
            setQueAns(0);
          });
      }
    });
  };

  const onClickRetakeQuiz = () => {
    setStartQuiz(false);
    setShowResultPage(false);
    setShowFailurePage(false);
    setRetakeQuiz(false);
    defaultIndex = 0;
    setDefaultIndex(0);
    setShowFeedback(false);
    setQuestions([]);
    setQueAns(0);
    setTimeout(() => {
      checkedValue.multipleChoice = [];
      singleValue.multipleChoice = [];
      multiValue.multipleChoice = [];
    }, 0);
    ansObj.obj = {};
    setIsViewMode(false);
    setReRender(true);
    setSelectedValue([]);
    setCurrBooleanId(undefined);
    setCurrChoiceId(undefined);
    setSelectedCheckboxValue([]);
    setIsQuestionAttempted(false);
  };

  const onClickReviewMaterial = () => {
    const queryParams = getSearchParams();
    navigate(getTrainingMaterialCompleteUrl(queryParams?.assignment_name));
  };
  // as per the type inc or dec set index
  const incDecQues = (type: string) => {
    if (type === 'inc') {
      setDefaultIndex(defaultIndex + 1);
      setReRender(true);
      setShowFeedback(false);
      setIsSuccess(false);
      if (queAns > defaultIndex) {
        setShowFeedback(true);
        setReRender(true);
        if (Object.keys(ansObj.obj).length > 0) {
          const feedback = ansObj.obj[defaultIndex];
          setIsSuccess(feedback);
        }
      }
    } else {
      setDefaultIndex(defaultIndex - 1);
      setShowFeedback(false);
      setIsSuccess(false);
      if (queAns >= defaultIndex) {
        setShowFeedback(true);
        setReRender(true);
        if (Object.keys(ansObj.obj).length > 0) {
          const feedback = ansObj.obj[defaultIndex];
          setIsSuccess(feedback);
        }
      }
    }
  };

  const onNextButtonClick = async () => {
    setIsNextDisabled(true);
    setIsPreviousDisabled(true);
    await validateIfQuizIsActive().then((res) => {
      if (!res) {
        setIsNextDisabled(false);
        setIsPreviousDisabled(false);
      } else {
        if (!showFeedback) {
          if (
            questionType.current &&
            questionType.current === QuestionTypes.SEQUENCE
          ) {
            setIsViewMode(true);
            setReRender(true);
            const data: string[] = [];
            items.map((orderedSeq: Choice) => {
              data.push(orderedSeq?.name);
            });
            const queryParams = getSearchParams();
            validateSelectedData(
              data,
              questions[defaultIndex]?.name,
              queryParams.assignment_name
            )
              .then((res: FeedbackType) => {
                setIsSuccess(res?.isAnswerCorrect);
                setShowFeedback(true);
                setQueAns(queAns + 1);
                mapCurrentFeedback(res?.isAnswerCorrect, currQueId);
                setCurrChoiceId(undefined);
                setCurrBooleanId(undefined);
              })
              .catch((_error) => {
                raiseErrorToast(_error);
                setIsError(true);
              })
              .finally(() => {
                setIsLoading(false);
                setIsNextDisabled(false);
                setIsQuestionAttempted(false);
                setIsPreviousDisabled(false);
              });
          }
          if (
            questionType.current &&
            questionType.current === QuestionTypes.BOOLEAN
          ) {
            const queryParams = getSearchParams();
            validateSelectedData(
              selectedValue,
              currQueId,
              queryParams.assignment_name
            )
              .then((res: FeedbackType) => {
                setIsSuccess(res?.isAnswerCorrect);
                setShowFeedback(true);
                setQueAns(queAns + 1);
                mapCurrentFeedback(res?.isAnswerCorrect, currQueId);
                setCurrChoiceId(undefined);
                setCurrBooleanId(undefined);
              })
              .catch((_error) => {
                raiseErrorToast(_error);
                setIsError(true);
              })
              .finally(() => {
                setIsLoading(false);
                setIsNextDisabled(false);
                setIsPreviousDisabled(false);
                setIsQuestionAttempted(false);
              });
          }
          if (
            questionType.current &&
            questionType.current === QuestionTypes.MULTIPLE_CHOICE
          ) {
            const queryParams = getSearchParams();
            validateSelectedData(
              selectedCheckboxValue,
              currQueId,
              queryParams.assignment_name
            )
              .then((res: FeedbackType) => {
                setIsSuccess(res?.isAnswerCorrect);
                setShowFeedback(true);
                setQueAns(queAns + 1);
                mapCurrentFeedback(res?.isAnswerCorrect, currQueId);
                setSelectedChoicesList([]);
                setCurrChoiceId(undefined);
                setCurrBooleanId(undefined);
              })
              .catch((_error) => {
                raiseErrorToast(_error);
                setIsError(true);
              })
              .finally(() => {
                setIsLoading(false);
                setIsNextDisabled(false);
                setIsPreviousDisabled(false);
                setIsQuestionAttempted(false);
              });
          }
          if (
            questionType.current &&
            questionType.current === QuestionTypes.MULTIPLE_RESPONSE
          ) {
            const queryParams = getSearchParams();
            validateSelectedData(
              apiChoiceValue.multipleChoice,
              currQueId,
              queryParams.assignment_name
            )
              .then((res: FeedbackType) => {
                setIsSuccess(res?.isAnswerCorrect);
                setShowFeedback(true);
                mapCurrentFeedback(res?.isAnswerCorrect, currQueId);
                setQueAns(queAns + 1);
                setCurrChoiceId(undefined);
                setCurrBooleanId(undefined);
                apiChoiceValue.multipleChoice = [];
              })
              .catch((_error) => {
                raiseErrorToast(_error);
                apiChoiceValue.multipleChoice = [];
              })
              .finally(() => {
                setIsLoading(false);
                apiChoiceValue.multipleChoice = [];
                setIsNextDisabled(false);
                setIsPreviousDisabled(false);
                setIsQuestionAttempted(false);
              });
          }
        } else {
          defaultIndex = defaultIndex + 1;
          setIsViewMode(false);
          if (defaultIndex <= questions.length - 1)
            setDefaultIndex(defaultIndex);
          setShowFeedback(false);
          if (queAns > defaultIndex) {
            setShowFeedback(true);
            setReRender(true);
            if (Object.keys(ansObj.obj).length > 0) {
              const feedback = ansObj.obj[defaultIndex];
              setIsSuccess(feedback);
              setIsViewMode(true);
            }
          }
          setIsNextDisabled(false);
          setIsPreviousDisabled(false);
        }
      }
    });
  };

  const mapCurrentFeedback = (
    isAnswerCorrect: boolean,
    queId: string | undefined
  ) => {
    const index = questions.findIndex((que, index) => {
      return que?.name === queId;
    });
    if (index !== -1) {
      ansObj.obj[index] = isAnswerCorrect;
    }
  };

  const onPrevClick = () => {
    defaultIndex = defaultIndex - 1;
    setDefaultIndex(defaultIndex);
    setShowFeedback(false);
    setIsSuccess(false);
    if (queAns >= defaultIndex) {
      setShowFeedback(true);
      setIsViewMode(true);
      setReRender(true);
      if (Object.keys(ansObj.obj).length > 0) {
        const feedback = ansObj.obj[defaultIndex];
        setIsSuccess(feedback);
      }
    }
  };

  const onDragStart = (
    ev: React.DragEvent<HTMLDivElement>,
    item: Choice,
    name: string
  ) => {
    setCurrQueId(name);
    if (!isViewMode) {
      ev.dataTransfer.setData('text/plain', JSON.stringify(item));
    }
  };

  const onDragOver = (ev: React.DragEvent<HTMLDivElement>) => {
    if (!isViewMode) {
      ev.preventDefault();
    }
  };

  const onDrop = (
    ev: React.DragEvent<HTMLDivElement>,
    index: number,
    name: string
  ) => {
    setCurrQueId(name);
    if (!isViewMode) {
      ev.preventDefault();
      const droppedItem: Choice = JSON.parse(
        ev.dataTransfer.getData('text/plain')
      );
      // Remove the dropped item from its previous position
      const newItems = items.filter(
        (item: Choice) => item.name !== droppedItem.name
      );
      // Insert the dropped item at the new position
      newItems.splice(index, 0, droppedItem);
      setItems(newItems);
    }
  };

  const handleRadioSelection = (name: string, queId: string) => {
    setSelectedValue([name]);
    setSelectedCheckboxValue([name]);

    singleValue.multipleChoice.push(name);
    setCurrBooleanId(name);
    setIsQuestionAttempted(true);

    setCurrQueId(queId);
    setReRender(false);
    setReRender(true);
  };

  const removeChoiceFromArray = (choice: Choice, array: string[]) => {
    const index = array.findIndex((val: string) => {
      return val === choice.name;
    });

    array.splice(index, 1);
  };

  const handleMultipleSelection = (
    checked: boolean | undefined,
    name: string,
    options: Choice,
    queId: string
  ) => {
    if (checked) {
      checkedValue.multipleChoice.push(name);
      apiChoiceValue.multipleChoice.push(name);
      setCurrChoiceId(name);
    } else {
      removeChoiceFromArray(options, checkedValue.multipleChoice);
      removeChoiceFromArray(options, apiChoiceValue.multipleChoice);
      setCurrChoiceId(undefined);
    }
    setCurrQueId(queId);
    if (apiChoiceValue.multipleChoice.length > 1) {
      setIsQuestionAttempted(true);
    } else {
      setIsQuestionAttempted(false);
    }
  };

  const getRadioValue = (choices: Choice[]): string | undefined => {
    const reversedOptionsList = [...singleValue.multipleChoice].reverse();
    const radioValue = reversedOptionsList.find((option) =>
      choices.some((selected) => selected.name === option)
    );
    return radioValue;
  };

  const checkedCheckbox = (type: QuestionTypes, options: Choice) => {
    let compareArray;
    if (type === QuestionTypes.MULTIPLE_CHOICE) {
      compareArray = multiValue.multipleChoice;
    } else if (type === QuestionTypes.MULTIPLE_RESPONSE) {
      compareArray = checkedValue.multipleChoice;
    } else {
      compareArray = singleValue.multipleChoice;
    }
    if (compareArray.includes(options?.name)) {
      return true;
    }
    return false;
  };

  const updateDefaultIndex = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setNullIndex(-1);
      return;
    }

    setNullIndex(0);
    setShowFeedback(false);
    setIsSuccess(false);

    if (Number(e.target.value) <= questions?.length) {
      if (
        Object.keys(ansObj.obj).includes(String(Number(e.target.value) - 1))
      ) {
        handleFeedback(Number(e.target.value) - 1);
      } else if (queAns > defaultIndex) {
        defaultIndex = Number(e.target.value) - 1;
      }
    } else {
      defaultIndex = 0;
      handleFeedback(defaultIndex);
    }

    setDefaultIndex(defaultIndex);
  };

  const handleFeedback = (index: number) => {
    defaultIndex = index;
    setShowFeedback(true);
    setReRender(true);

    if (Object.keys(ansObj.obj).length > 0) {
      setIsSuccess(ansObj.obj[defaultIndex]);
    }
  };

  const onTrainingSignOffBtnClick = () => {
    validateIfQuizIsActive().then((res) => {
      if (!res) return;
      else
        openModal(
          taskName ?? '',
          undefined,
          getULHomePageUrl(),
          trainingAssignmentData
        );
    });
  };

  return (
    <TakingQuizView
      {...{
        ...props,
        isLoading,
        questions,
        isError,
        errorMessage,
        isQuizStarted,
        startQuiz,
        showResultPage,
        defaultIndex,
        showFeedback,
        totalPercentage,
        showFailurePage,
        retakeQuiz,
        onSubmit,
        isSuccess,
        onNextButtonClick,
        onDragStart,
        onDrop,
        onDragOver,
        items,
        handleRadioSelection,
        quizInfo,
        currChoiceId,
        onPrevClick,
        queAns,
        onClickRetakeQuiz,
        onClickReviewMaterial,
        incDecQues,
        handleMultipleSelection,
        checkedCheckbox,
        reRender,
        isViewMode,
        currBooleanId,
        updateDefaultIndex,
        nullIndex,
        taskName: taskName,
        validQuiz,
        onUpdatedQuiz,
        onTrainingSignOffBtnClick,
        isQuestionAttempted,
        trainingAssignmentData,
        selectedValue,
        getRadioValue,
        isNextDisabled,
        isPreviousDisabled,
      }}
    />
  );
};
