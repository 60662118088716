import { makeStyles } from '@fluentui/react-components';

import { Icon, IconButton } from '../../shared';
import { ReaderAPI } from '../types';

const useStyles = makeStyles({
  toolbar: {
    backgroundColor: 'white',
    padding: '0.5em',
    alignItems: 'center',
    display: 'flex',
    boxShadow: '0px 13px 73px -29px rgba(0,0,0,0.77)',
    borderBottom: '1px solid #eaeaea',
    zIndex: 100,
  },
  input: {
    width: '40px',
    textAlign: 'center',
    '&::-webkit-inner-spin-button, &::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '-moz-appearance': 'textfield',
  },
  pageCounter: {
    width: '42px',
    textAlign: 'center',
  },
  group: {
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
  },
});

interface ToolbarProps extends ReaderAPI {
  isFullscreen: boolean;
  toggleFullscreen: () => Promise<void>;
  currentPage: number;
  numPages: number;
}

export default function Toolbar({
  decreaseZoom,
  increaseZoom,
  scale,
  jumpToPage,
  isFullscreen,
  toggleFullscreen,
  currentPage,
  numPages,
}: Readonly<Partial<ToolbarProps>>) {
  const styles = useStyles();
  currentPage = currentPage ?? 0;
  numPages = numPages ?? 0;
  return (
    <div data-testid="pdf-viewer-toolbar" className={styles.toolbar}>
      <div className={styles.group}>
        <IconButton
          onClick={() => jumpToPage?.(currentPage - 2)}
          icon={<Icon iconName="ChevronUp20Regular" />}
        />
        <span className={styles.pageCounter}>
          {currentPage}/{numPages}
        </span>
        <IconButton
          onClick={() => jumpToPage?.(currentPage)}
          icon={<Icon iconName="ChevronDown20Regular" />}
        />
      </div>
      {scale && (
        <div style={{ marginLeft: 'auto' }} className={styles.group}>
          <IconButton
            title="Zoom Out (-10%)"
            onClick={() => decreaseZoom?.()}
            icon={<Icon iconName="ZoomOut20Regular" />}
          />
          <IconButton
            onClick={() => increaseZoom?.()}
            title="Zoom In (+10%)"
            icon={<Icon iconName="ZoomIn20Regular" />}
          />
        </div>
      )}

      <IconButton
        onClick={() => toggleFullscreen?.()}
        title="Fullscreen"
        icon={
          <Icon
            iconName={
              isFullscreen
                ? 'FullScreenMinimize20Regular'
                : 'FullScreenMaximize20Regular'
            }
          />
        }
      />
    </div>
  );
}
