import { LocalizationString } from '@celito.clients/assets';
import { GridView } from '@celito.clients/shared';
import { Label } from 'libs/shared/src/lib/label/src';

import { FilesListDisplayProps } from '../taking-assingment.model';
import { getFilesViewColumns } from '../utils';

export const FilesListDisplay = (props: FilesListDisplayProps) => {
  return (
    <div>
      <Label weight="semibold">{LocalizationString.COURSE}</Label>
      <GridView
        columns={getFilesViewColumns(props)}
        items={props.filesList}
        disableSelection
      />
    </div>
  );
};
