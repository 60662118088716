import { LocalizationString } from '@celito.clients/assets';
import { makeStyles } from '@fluentui/react-components';

import { Icon } from '../../shared';

const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
    display: 'grid',
    placeContent: 'center',
    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '3rem',
    },
    '& p': {
      fontSize: '1.5rem',
      fontWeight: '',
    },
  },
  icon: {
    scale: 4,
  },
});

export default function ErrorFallback() {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div>
        <Icon className={styles.icon} iconName="DocumentError24Filled" />
        <p>{LocalizationString.SOMETHING_WENT_WRONG}</p>
      </div>
    </div>
  );
}
