import { LocalizationString } from '@celito.clients/assets';
import { DateFormat, ROUTES } from '@celito.clients/enums';
import { UserContext } from '@celito.clients/provider';
import { getAllDepartment } from '@celito.clients/services';
import { Field, InHouseInputSelect } from '@celito.clients/shared';
import { UserTypes } from '@celito.clients/types';
import {
  errorToast,
  formatDate,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { useUser } from 'libs/core/src/hooks/useUser';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { AdUserTypes } from './user.model';
import UserView from './user.view';

const UserController: React.FC = () => {
  const { user: loggedInUser = null } = useContext(UserContext);
  const [isUserDataLoading, setIsUserDataLoading] = useState<boolean>(false);
  const [isDepartmentLoading, setIsDepartmentLoading] =
    useState<boolean>(false);
  const [isManagerLoading, setIsManagerLoading] = useState<boolean>(false);

  const [userData, setUserData] = useState<UserTypes | undefined>();
  const [isActive, setIsActive] = useState<boolean>(false);
  const [departmentKey, setDepartmentKey] = useState<IOption | undefined>();

  const [isDataUpdating, setIsDataUpdating] = useState<boolean>(false);

  const { fetchAllAdUsers } = useUser();
  const [selectedId, setSelectedId] = useState<IOption | undefined>();
  const [departments, setDepartments] = useState<IOption[]>([]);

  const [users, setUsers] = useState<AdUserTypes[] | []>([]);

  const navigate = useNavigate();
  const { name = '' } = useParams();
  const { fetchUserInfo, updateUser } = useUser();
  const canUserEditData = name !== loggedInUser?.name;

  const navigateBackRoute = `../${ROUTES.LIST}/user_list_view__s`;

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    setIsDepartmentLoading(true);
    getAllDepartment()
      .then((resp) => {
        const departmentOptions = resp.map((option) => {
          return { value: option.label, text: option.label };
        });

        setDepartments(departmentOptions);
      })

      .catch()
      .finally(() => {
        setIsDepartmentLoading(false);
      });
  }, []);

  useEffect(() => {
    if (userData?.department && departments.length > 0) {
      setDepartmentKey(
        departments.find((item) => item.text === userData.department) ?? {
          value: userData.department,
          text: userData.department,
        }
      );
    }

    if (userData?.manager) {
      setSelectedId({
        value:
          users.find((item) => item.displayName === userData.manager)?.id ?? '',
        text:
          users.find((item) => item.displayName === userData.manager)
            ?.displayName ?? '',
      });
    }
  }, [userData, departments, users]);

  const onBtnClick = () => {
    if (userData) {
      editUser(userData);
    }
  };

  const getAllUsers = () => {
    setIsManagerLoading(true);

    fetchAllAdUsers()
      .then((users: AdUserTypes[] | []) => {
        setUsers(users);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsManagerLoading(false);
      });
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const transformFormData = (userData?: UserTypes) => {
    const formData = [
      {
        label: 'Name',
        value: userData?.label,
      },
      {
        label: 'Email',
        value: userData?.email,
      },
      {
        label: 'Department',
        customElem: (
          <InHouseInputSelect
            options={departments as IOption[]}
            onOptionSelect={(_e, option) => {
              setDepartmentKey(option.selectedOptions as IOption);
            }}
            selectedOptions={departmentKey}
            label=""
          />
        ),
      },
      {
        label: 'Title',
        value: userData?.jobTitle,
      },
      {
        label: 'Phone',
        value: userData?.mobileNumber,
      },
      {
        label: 'Manager',
        customElem: (
          <Field size="large">
            <InHouseInputSelect
              options={
                users
                  ?.filter((option) => option?.mail !== userData?.email)
                  ?.map((options) => {
                    return {
                      value: options.id,
                      text: options.displayName,
                      secondaryText: options.userPrincipalName,
                    };
                  }) ?? []
              }
              isPeoplePicker={true}
              selectedOptions={selectedId}
              placeholder={LocalStrings.addUserPlaceholder}
              onOptionSelect={(_e, option) => {
                setSelectedId(option.selectedOptions as IOption);
              }}
              label=""
            ></InHouseInputSelect>
          </Field>
        ),
      },
      {
        label: 'Country',
        value: userData?.country,
      },
      {
        label: 'Timezone',
        value: userData?.timezone,
      },

      {
        label: 'Status',
        customElem: (
          <InHouseInputSelect
            label=""
            options={[
              { text: 'Active', value: 'active' },
              { text: 'Inactive', value: 'inactive' },
            ]}
            selectedOptions={
              isActive
                ? { text: 'Active', value: 'active' }
                : { text: 'Inactive', value: 'inactive' }
            }
            onOptionSelect={(_e, option) => {
              option?.optionValue === 'active'
                ? setIsActive(true)
                : setIsActive(false);
            }}
          />
        ),
      },
      {
        label: 'Activation Date',
        value: userData?.createdAtUtc
          ? formatDate(userData.createdAtUtc, DateFormat.DateTime)
          : '',
      },
      {
        label: 'Activation By',
        value: userData?.createdBy.label,
      },
      {
        label: 'Modified By',
        value: userData?.modifiedBy.label,
      },
      {
        label: 'Last Modified Date',
        value: userData?.modifiedAtUtc
          ? formatDate(userData.modifiedAtUtc, DateFormat.DateTime)
          : '',
      },
    ];
    return formData;
  };

  const getUserData = useCallback(() => {
    setIsUserDataLoading(true);
    fetchUserInfo(name)
      .then((resp: UserTypes) => {
        setUserData(resp);
        setIsActive(resp.isActive || false);
      })
      .catch((_error) => {
        raiseErrorToast(_error);
      })
      .finally(() => {
        setIsUserDataLoading(false);
      });
  }, [name]);

  const isDepartmentInactive = (departmentSelected: IOption) => {
    return !departments.find((item) => item.value === departmentSelected.value);
  };

  const editUser = (userData: UserTypes) => {
    setIsDataUpdating(true);
    if (departmentKey?.value && isDepartmentInactive(departmentKey)) {
      errorToast({ message: LocalizationString.DEPARTMENT_INACTIVE });
      setIsDataUpdating(false);
    } else if (userData.name) {
      updateUser(userData.name, {
        user: {
          isActive: isActive,
          department: departmentKey?.value ? departmentKey.value : null,
          managerOid: selectedId?.value ? selectedId?.value : null,
        },
      })
        .then(() => {
          successToast({
            message: 'User data edited successfully',
          });
          navigate(`../${ROUTES.LIST}/user_list_view__s`);
        })
        .catch((_error) => {
          raiseErrorToast(_error);
        })
        .finally(() => setIsDataUpdating(false));
    }
  };

  useEffect(() => {
    getUserData();
  }, [getUserData]);

  return (
    <UserView
      {...{
        isActive,
        userData,
        navigateBackRoute,
        transformFormData,
        isUserDataLoading,
        isDepartmentLoading,
        isManagerLoading,
        isDataUpdating,
        onBtnClick,
        navigateBack,
        canUserEditData,
      }}
    />
  );
};

export default UserController;
