import { useIsAuthenticated } from '@azure/msal-react';
import { IdManager } from '@celito.clients/singleton';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';

import { HTTPStatusCode } from '../../enums/http-constants';
import { LayoutURLParamsEnum } from '../../enums/layout-url-enum';
import { useActiveModule } from '../../hooks/useActiveModule';
import { useUser } from '../../hooks/useUser';
import { fetchHeaderConfig } from '../../services/header-services';
import { logger, refactorUser } from '../../services/logger';
import { getRolesInfo } from '../../services/roles-service';
import RolesContextManager from '../../singleton/roles-context-manager';
import UserContextManager from '../../singleton/user-context-manager';
import { HeaderConfig } from '../../types/header-config';
import { ModuleNamesEnum } from '../../types/module-types';
import { UserTypes } from '../../types/user-types';
import { getValueFromParams } from '../../utils/params.util';
import { raiseErrorToast } from '../../utils/toast-util';
import UserContext from './context';

type props = {
  children: React.ReactNode;
};

const idManager = IdManager.getInstance();
const userContextManager = UserContextManager.getInstance();
const rolesContextManager = RolesContextManager.getInstance();

const UserProvider: React.FC<props> = ({ children }) => {
  const { fetchMyInfo, fetchUserPermission } = useUser();

  const [user, setUser] = React.useState<UserTypes | null>(null);
  const [headerConfig, setHeaderConfig] = React.useState<HeaderConfig | null>(
    null
  );

  const [isAdmin, setIsAdmin] = useState(false);
  const [isBizAdmin, setIsBizAdmin] = useState(false);
  const [tabs, setTabs] = useState(['']);
  const [domainObjects, setDomainObjects] = useState([{}]);
  const [roles, setRoles] = useState(['']);

  const navigate = useNavigate();
  const params = useParams();
  const activeModuleName =
    useActiveModule()?.systemName ??
    getValueFromParams(LayoutURLParamsEnum.moduleName, params);
  const isAuthenticated = useIsAuthenticated();

  const getRoles = useCallback(async () => {
    try {
      const roles = await getRolesInfo();

      rolesContextManager.setRoles(roles.data);
    } catch (error) {
      // handle error
    }
  }, []);

  const getUserDetails = useCallback(() => {
    fetchMyInfo()
      .then((user) => {
        logger.identity(user);
        logger.setContext(refactorUser(user));
        setUser(user);
        userContextManager.setUserContext(user);
        if (user?.name) {
          idManager.setId(user.name);
        }
      })
      .catch((err) => {
        if (err.response.body === 'User not invited') navigate('/not-invited');
        else if (err.response.body === 'User is deactivated')
          navigate('/not-active-user');
      });
  }, []);

  const getHeaderConfig = useCallback(() => {
    fetchHeaderConfig()
      .then((config) => {
        setHeaderConfig(config);
      })
      .catch((error) => {
        if (error?.response?.status >= HTTPStatusCode.NOT_FOUND)
          raiseErrorToast(error);
      });
  }, []);

  const getUserPermission = useCallback((moduleName: ModuleNamesEnum) => {
    fetchUserPermission('me', moduleName)
      .then(
        ({
          domainObjects,
          isAdmin,
          tabs,
          roles,
          isBizAdmin,
          permissionGroups,
        }) => {
          setIsAdmin(isAdmin);
          setTabs(tabs);
          setDomainObjects(domainObjects);
          setRoles(roles);
          setIsBizAdmin(isBizAdmin);

          userContextManager.setUserPermissionContext({
            isAdmin,
            tabs,
            domainObjects,
            roles,
            isBizAdmin,
            permissionGroups,
          });

          getHeaderConfig();
        }
      )
      .catch((error) => {
        if (error?.response?.status >= HTTPStatusCode.NOT_FOUND)
          raiseErrorToast(error);
      });
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      getUserDetails();
      getRoles();
    }
  }, [isAuthenticated, getUserDetails, getHeaderConfig, getRoles]);

  useEffect(() => {
    if (user?.name && activeModuleName) {
      getUserPermission(activeModuleName as ModuleNamesEnum);
    }
  }, [activeModuleName, user?.name]);

  const value = {
    user,
    headerConfig,
    isAdmin,
    isBizAdmin,
    roles,
    tabs,
    domainObjects,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export default UserProvider;
