import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const wizardStateStyles = makeStyles({
  active_outer_circle: {
    ...shorthands.borderRadius('100%'),
    minWidth: '1.73rem',
    height: '1.73rem',
    ...shorthands.padding('4px', '8px'),
    marginTop: '2.2px',
    marginLeft: '-2.35rem',
    backgroundColor: 'var(--blue-700)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: Color.white,
  },
  overflowContainer: {
    display: 'flex',
    width: '100%',
    ...shorthands.overflow('hidden !important'),
    ...shorthands.gap('1rem'),
    flexDirection: 'row',
  },
  skeleton: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    '& > div': {
      alignItems: 'center',
    },
  },
  inactive_outer_circle: {
    ...shorthands.borderRadius('50%'),
    width: '2rem',
    height: '2rem',
    ...shorthands.padding('4px', '10px'),
    ...shorthands.border('1px', 'solid', 'var(--border-color)'),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: 'var(--border-color)',
  },

  success_outer_circle: {
    ...shorthands.borderRadius('50%'),
    width: '2rem',
    height: '2rem',
    ...shorthands.padding('4px', '10px'),
    ...shorthands.border('1px', 'solid', Color.green),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
    color: Color.green,
    backgroundColor: Color.wizard_success_state_color,
    ...shorthands.borderColor(Color.green),
  },

  activeImage: {
    maxWidth: '2rem',
    maxHeight: '2rem',
  },

  container: {
    ...shorthands.paddingInline('0px', '1rem'),
    display: 'flex',
    rowGap: '1rem',
    columnGap: '8px',
    width: '100%',
  },

  state_style: {
    columnGap: '8px',
    color: Color.wizard_states_color,
    display: 'flex',
    alignItems: 'center',
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
    position: 'relative',
    width: '100%',
    paddingBottom: '1px',
    ...shorthands.borderBottom('1px', 'solid', Color.gray_500),
  },
  mt3: {
    marginTop: '3px',
  },
  infoIcon: {
    cursor: 'pointer',
    position: 'relative',
    top: '2px',
  },
  workflowHistoryContainer: {
    display: 'flex',
    flexBasis: '80%',
  },
  workflowHistroyButton: {
    flexBasis: '20%',
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap('10px'),
  },
  popupSurface: {
    width: '97%',
    ...shorthands.overflow('auto'),
    ...shorthands.margin('unset !important'),
    position: 'absolute',
    top: '5px !important',
    boxShadow: 'unset !important',
    ...shorthands.borderTop('1px', 'solid', Color.gray_200),
    left: '0',
    zIndex: '9999',
  },
  triggerButton: {
    cursor: 'pointer',
    position: 'relative',
    whiteSpace: 'nowrap',
    flexBasis: '50%',
  },
  fullWidth: {
    width: '100%',
  },
  hideButton: {
    flexBasis: '100%',
    display: 'flex',
  },
  flex90: {
    display: 'flex !important',
    flexBasis: '95% !important',
  },
  flex10: {
    flexBasis: '5%',
    display: 'flex',
    flexDirection: 'row',
    gap: '10px',
  },
  flex100: {
    flexBasis: '100%',
    cursor: 'pointer',
    position: 'relative',
    whiteSpace: 'nowrap',
  },
});
