import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const layoutStyles = makeStyles({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    backgroundColor: Color.background_body_bg,
    alignItems: 'center',
    position: 'relative',
  },

  layout: {
    zIndex: '1000',
    width: '97%',
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 11.5rem)',
    backgroundColor: Color.white,
    borderRadius: '4px',
    overflow: 'auto',
    top: '130px',
  },

  differentHeight: {
    height: 'calc(100vh - 14.5rem) !important',
  },

  space: {
    ...shorthands.padding('1.625rem', '1.625rem', '2.6875rem ', '1.625rem'),
  },
});
