import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  Label,
  Radio,
  RadioGroup,
} from '@fluentui/react-components';

import CheckboxFacet from '../checkbox-facet/checkbox-facet.component';
import { labelStyles } from '../search-result.style';
import { isFacetOfSelectedModule } from '../utils/search-result.util';
import { SearchFilterViewProps } from './search-filter.model';
import classes from './search-filter.module.css';

const SearchFilterView: React.FC<SearchFilterViewProps> = (props) => {
  const {
    openItems,
    onToggleHandler,
    facetsList,
    setSelectedCheckboxesHandler,
    selectedCheckboxes,
    isRefineSearchDisabled,
    searchWithinFilterValue,
    setSelectedRadioButtonHandler,
    selectedQuickFilter,
  } = props;

  return (
    <div className={classes.search_panel}>
      <div className={classes.filter_section}>
        <div className={classes.label}>
          <Label style={labelStyles}>Search within</Label>
        </div>
        <div className={classes.radio_button_group}>
          <RadioGroup
            value={searchWithinFilterValue}
            onChange={(_, data) => setSelectedRadioButtonHandler(data.value)}
          >
            <Radio
              value="Metadata and File Content"
              label="Metadata and File Content"
            />
            <Radio value="Metadata" label="Metadata" />
          </RadioGroup>
        </div>
      </div>
      <div className={classes.filter_section}>
        <div className={classes.label}>
          <Label style={labelStyles}>Refine Search</Label>
        </div>
        <Accordion
          collapsible
          multiple
          openItems={openItems}
          onToggle={onToggleHandler}
        >
          {facetsList.length ? (
            facetsList
              .filter((facet) => {
                return (
                  isFacetOfSelectedModule(facet, selectedQuickFilter) &&
                  facet.items.length > 0
                );
              })
              .map((facets) => (
                <AccordionItem
                  key={`${facets.key}-${facets.label}-${facets.module}`}
                  value={`${facets.key}-${facets.label}-${facets.module}`}
                  disabled={isRefineSearchDisabled}
                >
                  <AccordionHeader as="div" expandIconPosition="end">
                    <b>{facets.label}</b>
                  </AccordionHeader>
                  <AccordionPanel>
                    <CheckboxFacet
                      id={facets.key}
                      items={facets.items.map((checkboxOption) => ({
                        option: checkboxOption.key,
                        docCount: checkboxOption.doc_count,
                        checked: false,
                        dataType: facets.dataType,
                      }))}
                      onCheckboxStateChangeHandler={
                        setSelectedCheckboxesHandler
                      }
                      selectedCheckboxes={selectedCheckboxes}
                      disabled={isRefineSearchDisabled}
                      module={facets.module}
                    />
                  </AccordionPanel>
                </AccordionItem>
              ))
          ) : (
            <div className={classes.radio_button_group}></div>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default SearchFilterView;
