import React from 'react';

import { ContextProps } from './types';

const UserContext = React.createContext<ContextProps>({
  user: null,
  headerConfig: null,
  isAdmin: false,
  isBizAdmin: false,
  roles: [''],
  tabs: [''],
  domainObjects: [],
});

export default UserContext;
