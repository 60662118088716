import { LocalizationString } from '@celito.clients/assets';
import { TaskStatusEnum } from '@celito.clients/enums';
import { Loader } from '@celito.clients/shared';
import { Task } from '@celito.clients/types';
import {
  createTestAttribute,
  objectKeys,
  raiseErrorToast,
} from '@celito.clients/utils';
import { memo, useEffect, useState } from 'react';

import TaskListComponent from './task/component';
import { getStyles } from './task/styles';
import { GroupNames, OwnerOrModifiedBy } from './task/types';
import {
  GroupTableObject,
  GroupTableProps,
  TaskListProps,
  WorkflowHistoryViewProps,
} from './types';
import { getActionsData } from './workflow-actions/services';
import { TaskActions } from './workflow-actions/workflow-actions.model';

const WorkflowHistoryView = memo((componentProps: WorkflowHistoryViewProps) => {
  const styles = getStyles();
  let previousGroupName: GroupNames | null = null;

  const checkGroupNameIsSame = (group: GroupTableObject) => {
    const currentGropuName: GroupNames = Object.keys(group)?.[0] as GroupNames;
    if (currentGropuName === previousGroupName) {
      return null;
    }
    previousGroupName = currentGropuName;
    return currentGropuName;
  };

  return Object.keys(componentProps?.tasks).length ? (
    Object.keys(componentProps?.tasks)
      ?.reverse()
      ?.map((stepId, _index) => (
        <div key={stepId}>
          <GroupTable
            stepId={stepId}
            groupName={checkGroupNameIsSame(componentProps?.tasks[stepId])}
            groups={componentProps?.tasks[stepId]}
            isLastStageindex={
              _index === Object.keys(componentProps?.tasks).length - 1
            }
            ownerName={
              (componentProps?.recordData?.owner as OwnerOrModifiedBy)?.name ??
              ''
            }
            refetchRecordData={componentProps.refetchRecordData!}
            {...componentProps}
            popupRef={componentProps?.popupRef}
            delegatedTask={componentProps?.delegatedTask}
          />
        </div>
      ))
  ) : (
    <div className={styles.notFound}>
      {componentProps?.isLoading ? (
        <Loader className={styles.centerLoading} />
      ) : (
        <span
          className={styles.notask}
          data-testid={`${createTestAttribute(
            LocalizationString.NO_TASK_FOUND
          )}`}
        >
          {LocalizationString.NO_TASK_FOUND}
        </span>
      )}
    </div>
  );
});

const TaskList = ({
  tasksList,
  groupName,
  labelName,
  isLastTableIndex,
  isFirstGroupIndex,
  ownerName,
  delegatedTask,
  ...props
}: TaskListProps) => {
  const [loading, setLoading] = useState(false);
  const [taskActionsCache, setTaskActionsCache] = useState<{
    [key: string]: TaskActions[];
  }>({});

  const checkAllTaskAreCompleted = (tasks: Task[] | undefined) => {
    const isCompleted = tasks?.every?.((task) => {
      return (
        task.status === TaskStatusEnum.Done ||
        task.status === TaskStatusEnum.Cancel
      );
    });
    return isCompleted ?? false;
  };
  let previousLabel: string | null = null;
  const checkLabelIsSame = (task: Task) => {
    if (task?.label === previousLabel) {
      return '';
    }
    previousLabel = task?.label;
    return task?.label;
  };
  const currentOwners: string[] = [];
  const currentTaskOwners = (
    ownerName: string,
    task: Task,
    docOwner: string
  ) => {
    // if the task is of collaborator then we have to exclude owner from the reassign and add participants list
    if (task?.tag === 'cd_create_collaboration') {
      currentOwners.push(docOwner);
    }
    // if the task is of complete draft, then exclude all the assigned collaborators from reassign and add participants list
    if (
      task?.tag === 'cd_create_complete_draft' &&
      (props?.recordData?.collaborators as Record<string, any>[])?.length
    ) {
      const collaboratorUsers: string[] = (
        props?.recordData?.collaborators as Record<string, any>[]
      )?.map((users) => users.name);
      if (collaboratorUsers.length) {
        currentOwners.push(...collaboratorUsers);
      }
    }
    currentOwners.push(ownerName);
    return currentOwners;
  };

  const getTaskActions = (task: Task): TaskActions[] | undefined => {
    return taskActionsCache[task?.label];
  };

  const fetchAndCacheTaskActions = async (task: Task): Promise<void> => {
    setLoading(true);
    try {
      const actionData = await getActionsData(task?.name);
      if (actionData?.length) {
        setTaskActionsCache((prev) => ({
          ...prev,
          [task?.label]: actionData,
        }));
      }
    } catch (error) {
      raiseErrorToast(error);
    } finally {
      setLoading(false);
    }
  };
  const checkIfDelegatedTask = () => {
    const record = tasksList?.every(
      (task) =>
        task.workflowStepInstanceId === delegatedTask?.workflowStepInstanceId
    );
    return record;
  };
  useEffect(() => {
    const tasksToFetch = tasksList.filter(
      (task) => !taskActionsCache[task?.label]
    );
    if (
      !checkLabelIsSame(tasksToFetch[0]) &&
      !checkAllTaskAreCompleted(tasksList)
    ) {
      fetchAndCacheTaskActions(tasksList[0]);
    }
  }, []);

  return (
    <tbody>
      {tasksList?.map((task, i) => (
        <TaskListComponent
          key={task?.workflowInstanceId}
          tasks={task}
          groupName={groupName}
          labelName={checkLabelIsSame(task)}
          showLine={i === tasksList?.length - 1 && !isLastTableIndex}
          isActive={checkAllTaskAreCompleted(tasksList)}
          isFirstTaskIndex={i === 0 && isFirstGroupIndex}
          ownerName={ownerName}
          objectActionDefinitions={props.objectActionDefinitions!}
          objectDefinitions={props?.objectDefinitions}
          recordData={props?.recordData}
          currentTaskOwners={currentTaskOwners(
            task?.ownerName,
            task,
            ownerName
          )}
          callWorkflowAPIOnEvent={props?.callWorkflowAPIOnEvent}
          refetchRecordData={props?.refetchRecordData}
          taskActions={
            !checkAllTaskAreCompleted(tasksList)
              ? getTaskActions(task) || []
              : []
          }
          loading={loading}
          popupRef={props?.popupRef}
          isDelegatedTask={checkIfDelegatedTask()}
        />
      ))}
    </tbody>
  );
};

const GroupTable = ({
  stepId,
  groups,
  isLastStageindex,
  ownerName,
  delegatedTask,
  ...restProps
}: GroupTableProps) => {
  const styles = getStyles();
  return objectKeys(groups)?.map((groupName) =>
    objectKeys(groups[groupName]!)?.map((label, groupIndex) => {
      return (
        <table className={styles.fullWidth} key={`${stepId}-${groupName}`}>
          <thead />
          <TaskList
            tasksList={groups[groupName]![label] || []}
            groupName={restProps?.groupName}
            labelName={label as string}
            isLastTableIndex={
              groupIndex === objectKeys(groups[groupName]!).length - 1 &&
              isLastStageindex
            }
            isFirstGroupIndex={groupIndex === 0}
            ownerName={ownerName}
            objectActionDefinitions={restProps?.objectActionDefinitions}
            objectDefinitions={restProps?.objectDefinitions}
            recordData={restProps?.recordData}
            callWorkflowAPIOnEvent={restProps?.callWorkflowAPIOnEvent}
            refetchRecordData={restProps?.refetchRecordData}
            popupRef={restProps?.popupRef}
            delegatedTask={delegatedTask}
          />
          <tfoot />
        </table>
      );
    })
  );
};

export default WorkflowHistoryView;
