import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { InAppNotification } from '../types/common-types';

type UnreadNotificationsResponse = {
  unreadInAppNotificationDtoList: InAppNotification[];
};

export const getUnreadInAppNotifications = async (params?: {
  module_name: string | undefined;
}) => {
  const response = await performRequest<UnreadNotificationsResponse>(
    `${Endpoints.IN_APP_NOTIFICATIONS_URL}/unread`,
    ApiMethodType.GET,
    null,
    params
  );
  return response?.data;
};

export const getHasUnreadNotifications = async (
  moduleName: string | undefined
) => {
  const response = await performRequest(
    `${Endpoints.IN_APP_NOTIFICATIONS_URL}/are_there_unread`,
    ApiMethodType.GET,
    null,
    { module_name: moduleName }
  );
  return response.data as { hasUnreadNotification: boolean };
};
