import { ApiMethodType } from '@celito.clients/enums';
import {
  SortConfig,
  TemporaryUnifyDefaultAndUserFilters,
} from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import { TrainingAssignmentData } from '@celito.clients/types';
import Endpoints from 'libs/core/src/config/endpoint';
import { PaginatedData } from 'libs/shared/src/lib/assignments-list/src/types';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

import { assignmentViewDefaultFilter, defaultSortConfig } from '../config';

export const getAssignmentViewData = async (
  paginationData: {
    pageLimit: number;
    page: number;
  },
  configData: {
    filters: RulesComponentListViewFilter;
    sortConfig?: SortConfig;
  },
  signal?: AbortSignal
) => {
  const finalFilters = TemporaryUnifyDefaultAndUserFilters(
    assignmentViewDefaultFilter,
    configData.filters
  );

  const response = await performRequest<
    PaginatedData<TrainingAssignmentData[]>
  >(
    `${Endpoints.ASSIGNMENT_VIEW_METADAT_URL}`,
    ApiMethodType.POST,
    {
      filters: finalFilters,
      sort: configData.sortConfig ?? defaultSortConfig,
    },
    {
      limit: paginationData.pageLimit,
      page: paginationData.page,
    },
    undefined,
    signal
  );
  return response.data;
};
