import {
  ApiMethodType,
  CurriculumAttributeNameEnum,
  ObjectEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { FilterAsConditions } from '@celito.clients/types';
import { isMajorVersion } from '@celito.clients/utils';
import Endpoints from 'libs/core/src/config/endpoint';

import { AssociatedCurriculaResponse } from '../course-associated-curricula.model';

export const ITEM_LIMIT_PER_PAGE = 10;

export const getAssociatedCurricula = async (
  courseName: string,
  currentPage: number,
  courseVersion: string
): Promise<AssociatedCurriculaResponse> => {
  const filters: FilterAsConditions[] = [
    {
      conditions: {
        all: [
          {
            any: [
              {
                attribute: CurriculumAttributeNameEnum.COURSES,
                operator: OperatorsEnum.IN,
                value: isMajorVersion(courseVersion)
                  ? [{ name: courseName, version: courseVersion }]
                  : [courseName],
              },
            ],
          },
          {
            any: [
              {
                attribute: CurriculumAttributeNameEnum.STATUS,
                operator: OperatorsEnum.EQUALS,
                value: 'Approved',
              },
            ],
          },
        ],
      },
    },
  ];

  const response = await performRequest<AssociatedCurriculaResponse>(
    `${Endpoints.OBJECT_DATA_URL}/${ObjectEnum.CURRICULUM}`,
    ApiMethodType.POST,
    {
      filters: filters,
    },
    {
      limit: ITEM_LIMIT_PER_PAGE,
      page: currentPage,
      getMaxMajorVersionOnly: true,
    }
  );
  return response.data;
};
