import { useEffect } from 'react';

import { LayoutConfiguration } from '../../types/layout-context-type';
import useLayout from '../useLayout';

type UseConfigureLayoutProps = LayoutConfiguration | undefined;

const useConfigureLayout = (props?: UseConfigureLayoutProps) => {
  const { configureLayout } = useLayout();

  useEffect(() => {
    if (props) {
      configureLayout(props);
    }
  }, [configureLayout, props]);

  return {
    configureLayout,
  };
};

export default useConfigureLayout;
