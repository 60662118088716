import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton, Loader } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Text,
} from '@fluentui/react-components';
import FormGroup from 'libs/form-engine/src/lib/components/form-group';
import { mergeFieldsWithAttributeConfig } from 'libs/form-engine/src/lib/utils/transform-field-data';
import { useRef } from 'react';
import {
  Control,
  FieldValues,
  UseFormReturn,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { Maybe } from 'yup';
import * as yup from 'yup';

import { UserActionPopupProps } from './user-action-popup.model';
import { userActionStyles } from './user-action-popup.styles';

export interface UserActionPopupViewProps extends UserActionPopupProps {
  control: Control<any>;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<Record<string, string | number | undefined>>;
  trigger: UseFormTrigger<FieldValues>;
  // onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onSave: () => Promise<void>;
  loading: boolean;
  methods: UseFormReturn;
  setValidationSchema: React.Dispatch<
    React.SetStateAction<
      yup.ObjectSchema<yup.AnyObject, yup.AnyObject, any, ''> | undefined
    >
  >;
}

const UserActionPopupView = ({
  open,
  title,
  primaryButtonText,
  onCancelClicked,
  userActionData,
  attributeConfig,
  secondaryButtonText,
  control,
  watch,
  setValue,
  trigger,
  isTextRequired,
  onSave,
  loading,
  recordDetails,
  methods,
  setValidationSchema,
}: UserActionPopupViewProps): JSX.Element => {
  const { cssVariables } = useTheme();
  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<Maybe<UseFormReturn>>(null);
  const styles = userActionStyles();

  const checkInFields = mergeFieldsWithAttributeConfig(
    userActionData?.properties?.fields || [],
    attributeConfig!
  );
  const formGroups = [{ isLoading: false, fields: checkInFields }];

  return (
    <Dialog open={open}>
      <DialogSurface style={{ padding: '0px' }} className={styles.container}>
        {loading ? (
          <div className={styles.loaderContainer}>
            <Loader />
          </div>
        ) : (
          <div>
            <DialogBody
              style={{ ...cssVariables }}
              onClick={(e) => e.stopPropagation()}
            >
              <DialogTitle className={styles.modalHeader}>
                <b>{title}</b>
              </DialogTitle>

              <DialogContent
                className={`${
                  secondaryButtonText
                    ? styles.modalBodyWithChildren
                    : styles.modalBody
                }`}
              >
                {isTextRequired && (
                  <Text size={400}>
                    Do you want to proceed with the cancelation of this request?
                    This action is irreversible, and the request will be
                    permanently canceled
                  </Text>
                )}
                <FormGroup
                  groups={formGroups}
                  attributeConfig={attributeConfig}
                  recordData={recordDetails}
                  onSave={onSave}
                  formRef={formRef}
                  submitRef={submitRef}
                  className={styles.group}
                  methods={methods}
                  setValidationSchema={setValidationSchema}
                />
              </DialogContent>

              <DialogActions fluid className={styles.modalFooter}>
                <CustomButton
                  buttonTitle={secondaryButtonText}
                  buttonType={ButtonTypes.Ghost}
                  onButtonClicked={onCancelClicked}
                />
                <CustomButton
                  buttonTitle={primaryButtonText}
                  buttonType={ButtonTypes.Primary}
                  onClick={() => {
                    if (submitRef.current) {
                      submitRef.current.click();
                      return;
                    }

                    onSave();
                  }}
                />
              </DialogActions>
            </DialogBody>
          </div>
        )}
      </DialogSurface>
    </Dialog>
  );
};

export default UserActionPopupView;
