import { FilterDto, Relationship } from '@celito.clients/types';

import { OwnerTypeEnum } from './base-entity';
import { RuleSchema } from './rule.schema';

export enum AttributeDataTypeEnum {
  Number = 'Number',
  Picklist = 'Picklist',
  PlainText = 'PlainText',
  DateTime = 'DateTime',
  Date = 'Date',
  Email = 'Email',
  YesNo = 'YesNo',
  RadioYesNo = 'RadioYesNo',
  ActiveInactive = 'ActiveInactive',
  LongText = 'LongText',
  Reference = 'Reference',
  Document = 'Document',
  MultipleDocument = 'MultipleDocument',
  Record = 'Record',
  ObjectDefinition = 'ObjectDefinition',
  RichText = 'RichText',
  FixedList = 'FixedList',
  USER = 'USER',
  JSON = 'JSON',
  ARRAY = 'ARRAY',
}

export enum TimeOfDayPreferenceEnum {
  CURRENT = 'CURRENT',
  START = 'START',
  END = 'END',
}

export declare class ObjectAttributeDefinitionSchema {
  ownerType: OwnerTypeEnum;
  name: string;
  label: string;
  isInternal: boolean;
  isAuditable: boolean;
  dataType: AttributeDataTypeEnum;
  picklistName?: string;
  displayInList?: boolean;
  rules?: RuleSchema[];
  isEditable?: boolean;
  isMandatory?: boolean;
  isHidden?: boolean;
  isMultiSelect?: boolean;
  isSortable?: boolean;
  isFilterable?: boolean;
  isUnique?: boolean;
  allowAllExtensions?: boolean;
  notAllowedExtensions?: string[];
  isForTrainingAssingment?: boolean;
  isCoAuthoringEnabled?: boolean;
  isVersioningEnabled?: boolean;
  maxFileSizeInBytes?: number;
  allowedExtensions?:
    | string[]
    | {
        dependentAttributeName: string;
        extensions: string[];
        value?: string;
      }[];
  isEncrypted?: boolean;
  displayOrder?: number;
  defaultValue?: any;
  helpText?: string;
  key: string;
  lookupColumnNames?: string[];
  lookupFieldRelationshipName?: string;
  relationshipName?: string;
  relationship?: Relationship;
  excludeFromPayload?: boolean;
  timeOfDayPreference?: TimeOfDayPreferenceEnum;
  columnName: string;
  allowDelete?: boolean;
  fixedList?: string[];
  rulesObjectName?: string;
  defaultValueType?: AttributeDataTypeEnum;
  propertyLookupKey?: string;
  includeRelation?: boolean;
  dataTypeKeyForFE?: string;
  roles?: string[];
  childs?: ObjectAttributeChildSchema[];
  defaultReferenceFilter?: FilterDto[];
  isIdAppendedInColumnNameInDb?: boolean;
  removeFromResponsePayload?: boolean;
  peoplePickerDropdown?: boolean;
  excludeValuesByAttributeName?: string;
  maximumFilesAllowed?: number;
  isSearchable?: boolean;
  isReportable?: boolean;
  isFacet?: boolean;
  isDisplayedInSearchResults?: boolean;
  recordRole?: string;
  canContainMinorVersion?: boolean;
  isSystemField?: boolean;
  doNotCopyToMinorVersion?: boolean;
  shouldNotDisplayAsMergeField?: boolean;
}
export class ObjectAttributeChildSchema {
  lookupAttributeName?: string;
  value?: any;
  rules?: RuleSchema[];
}
export {};
