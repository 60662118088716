import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const documentTemplateStyles = makeStyles({
  stackContainer: {
    ...shorthands.gap('50px'),
    width: '100%',
  },

  textField: {
    ...shorthands.flex(1),
    backgroundColor: Color.white,
    ...shorthands.borderRadius('5px'),
    height: '40px',
    width: '100%',
  },

  textFieldError: {
    ...shorthands.border('1px', 'solid', Color.error_color),

    '&:after': {
      ...shorthands.border('1px', 'solid', Color.error_color),
    },
  },

  field: {
    fontSize: '1rem',
    width: '100%',
  },

  displayView: {
    display: 'flex',
    flexDirection: 'column',
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('24px'),
  },

  copyButton: {
    marginBottom: '2px',
  },

  alignEnd: {
    alignItems: 'flex-end !important',
  },

  row: {
    display: 'flex',
    ...shorthands.gap('8px'),
  },
});
