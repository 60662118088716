import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { useConfigureLayout } from '@celito.clients/hooks';
import { CustomButton } from '@celito.clients/shared';
import { getZonedBasedTime } from '@celito.clients/utils';

import classes from './email-export-redirect.module.css';

const isLinkExpired = (url: string) => {
  const searchParams = new URLSearchParams(url.split('?')[1]);
  const expiryDateUTC = searchParams.get('se');

  if (!expiryDateUTC) {
    return true; // If no expiry date is provided, consider it expired
  }

  const expiryDate = getZonedBasedTime(decodeURIComponent(expiryDateUTC));
  const currentDate = getZonedBasedTime(new Date());

  return currentDate > expiryDate;
};

const EmailExportRedirect = () => {
  // NOTE: do not use new URL constructor because it will encode the signature
  const rawUrl = window.location.href.split('?url=')[1];
  const rawSigParam = rawUrl.split('&sig=')[1];

  if (!rawSigParam) throw new Error('Invalid URL: Missing signature');

  const downloadUrl = rawUrl.replace(
    rawSigParam,
    encodeURIComponent(rawSigParam)
  );

  useConfigureLayout({
    headerTitle: ' ',
    pageTitle: '',
  });

  if (!downloadUrl) {
    return null;
  }

  const isExpired = isLinkExpired(downloadUrl);

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        {isExpired
          ? LocalizationString.EMAIL_EXPORT_LINK_EXPIRED_TITLE_MSG
          : LocalizationString.EMAIL_EXPORT_REDIRECT_MSG}
      </div>

      <CustomButton
        onButtonClicked={() => window.open(downloadUrl, '_blank')}
        buttonType={ButtonTypes.Primary}
        buttonTitle={LocalizationString.DOWNLOAD}
        disabled={isExpired}
      />
    </div>
  );
};

export default EmailExportRedirect;
