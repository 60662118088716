import { useState } from 'react';

import { NavLinkProps } from './nav-link.model';
import NavLinkView from './nav-link.view';

interface NavLinkControllerProps extends NavLinkProps {}

const NavLinkController = (props: NavLinkControllerProps): JSX.Element => {
  const [showContextualMenu, setShowContextualMenu] = useState(false);

  const onShowContextualMenu = () => {
    setShowContextualMenu(!showContextualMenu);
  };

  return (
    <NavLinkView
      onShowContextualMenu={onShowContextualMenu}
      showContextualMenu={showContextualMenu}
      {...props}
    />
  );
};

export default NavLinkController;
