import React from 'react';

interface ModalContextProps {
  isModalOpen: boolean;
  openModal: (
    taskName: string,
    payload?: Record<string, unknown>,
    navigateUrl?: string,
    recordData?: Record<string, any>,
    contextMenuAction?: Record<string, any>,
    onCompleteTask?: () => void
  ) => void;
  closeModal: (successful?: boolean) => void;
  taskName: string;
  extraPayload: Record<string, unknown> & {
    file?: File;
  };
  navigateUrl?: string;
  recordData?: Record<string, any>;
  contextMenuAction?: Record<string, any>;
}

const ModalContext = React.createContext<ModalContextProps>({
  isModalOpen: false,
  openModal: () => {},
  closeModal: () => {},
  taskName: '',
  extraPayload: {},
  navigateUrl: undefined,
  recordData: {},
  contextMenuAction: {},
});

export default ModalContext;
