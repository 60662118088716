import { LocalizationString } from '@celito.clients/assets';
import { ConfirmDialog, CustomButton } from '@celito.clients/shared';
import { ConfirmDialogProps } from 'libs/shared/src/lib/confirm-dialog/src/confirm-dialog.model';
import { CustomButtonProps } from 'libs/shared/src/lib/custom-button/src/custom-button.model';
import * as React from 'react';
type Props = CustomButtonProps & {
  showSubmitConfirmationPopup?: boolean;
  dialogProps: Pick<
    ConfirmDialogProps,
    | 'primaryButtonText'
    | 'title'
    | 'description'
    | 'secondaryButtonText'
    | 'iconSrc'
  >;
};

/**
 * Little wrapper component around `CustomButton` that shows a confirmation
 * popup before triggering the passed down `onButtonClicked` or `onSubmit`
 */
export const ConfirmButton: React.FC<Props> = ({
  onSubmit,
  onButtonClicked,

  dialogProps,

  showSubmitConfirmationPopup = true,
  ...props
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const handleOnClick = (
    e:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLElement>
  ) => {
    if (typeof onSubmit === 'function') onSubmit?.();
    else onButtonClicked?.(e);
  };

  return (
    <>
      {showSubmitConfirmationPopup ? (
        <ConfirmDialog
          open={isOpen}
          onCancelClicked={(e) => {
            e.stopPropagation();
            setIsOpen(false);
          }}
          onConfirmClicked={(e) => {
            e.stopPropagation();
            handleOnClick(e);
            setIsOpen(false);
          }}
          {...dialogProps}
        />
      ) : null}
      <CustomButton
        {...props}
        onButtonClicked={
          showSubmitConfirmationPopup ? () => setIsOpen(true) : handleOnClick
        }
      />
    </>
  );
};
