import { LocalizationString } from '@celito.clients/assets';
import { dismissToast } from '@celito.clients/provider';
import { errorToast, infoToast } from '@celito.clients/utils';
import { isArray, isArrayBuffer } from 'lodash';

type DownloadableData = ArrayBuffer | BlobPart;

class FilesService {
  /**
   * Downloads a file buffer.
   *
   * @param data - The file data as an ArrayBuffer or BlobPart.
   * @param label - The label or name of the file.
   * @param mimeType - The optional MIME type of the file.
   */
  public downloadFileBuffer({
    data,
    label,
    mimeType,
  }: {
    data: DownloadableData;
    label: string;
    mimeType?: string;
  }): void {
    const blob = this.getBlobFromData(data, mimeType);
    const downloadLink = document?.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = label;
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);
  }

  /**
   * Raises a toast indicating that a file is being downloaded and returns the result of the download promise.
   *
   * The toast is dismissed when the promise is resolved.
   *
   * Also handles errors and displays an error toast if the promise is rejected.
   *
   * Usage:
   * Pass a promise that resolves to the file data and an optional callback to handle the result.
   *
   * @param downloadPromise - The promise that resolves to the file data.
   * @param onSuccesfulDownload - Optional callback function to handle the result of the download.
   * @param onError - Optional callback function to handle errors.
   * @returns A promise that resolves to the result of the download.
   */
  public async downloadFile<T>(
    downloadPromise: Promise<T>,
    onSuccesfulDownload?: (result: T) => void,
    onError?: (error: unknown) => void,
    toastOptions?: { message: string; title?: string }
  ): Promise<T | undefined> {
    const toastId = infoToast(
      toastOptions ?? {
        message: LocalizationString.THIS_MAY_TAKE_A_FEW_MOMENTS,
        title: LocalizationString.DOWNLOADING_FILE,
      },
      { timeout: -1 }
    );
    try {
      const result = await downloadPromise;
      onSuccesfulDownload?.(result);
      return result;
    } catch (error) {
      errorToast({
        message: LocalizationString.THERE_WAS_SOME_PROBLEM_DOWNLOADING_THE_FILE,
      });
      onError?.(error);
    } finally {
      dismissToast(toastId);
    }
  }

  private getBlobFromData(data: DownloadableData, mimeType?: string): Blob {
    return new Blob(
      [isArrayBuffer(data) || isArray(data) ? new Uint8Array(data) : data],
      { type: mimeType }
    );
  }
}

/**
 * Service for handling file buffers and downloads.
 */
export const files = new FilesService();
