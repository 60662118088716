import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, OperatorsEnum } from '@celito.clients/enums';
import { useTheme } from '@celito.clients/theme';
import { Role, UserTypes } from '@celito.clients/types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Label,
  mergeClasses,
} from '@fluentui/react-components';
import {
  CustomButton,
  Icon,
  PeoplePicker,
  TextFieldArea,
} from 'libs/shared/src/lib/shared';
import { Control, Controller, FieldValues } from 'react-hook-form';

import { DelegationModalProps } from './delegation-modal.model';
import classes from './delegation-modal.module.css';
import { delagationModalStyles } from './delegation-modal-styles';

interface DelegationModalViewProps extends DelegationModalProps {
  control: Control<FieldValues, any>;
  onFormSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onSubmitHandler: (onSubmit?: boolean) => Promise<void>;
  roles: Role[];
  currentUser: UserTypes | null;
  fromUser: Record<string, any> | null;
  isLoading: boolean;
  onPopupClose: () => void;
  taskOwner: string;
}

export const DelegationModalView = ({
  onClose,
  isOpen,
  onFormSubmit,
  rowItem,
  control,
  onSubmitHandler,
  fromUser,
  isLoading,
  roles,
  currentUser,
  onPopupClose,
  taskOwner,
}: DelegationModalViewProps): JSX.Element => {
  const { cssVariables } = useTheme();

  const getActiveFilter = () => {
    const filter = {
      conditions: {
        all: [
          {
            all: [
              {
                attribute: 'isActive',
                operator: OperatorsEnum.EQUALS,
                value: true,
              },
            ],
          },
        ],
      },
    };
    return filter;
  };

  const styles = delagationModalStyles();
  return (
    <Dialog
      open={isOpen}
      onOpenChange={(e) => {
        e.stopPropagation();
        onPopupClose();
      }}
    >
      <DialogSurface
        className={styles.container}
        style={{ padding: 0, ...cssVariables }}
      >
        <DialogBody onClick={(e) => e.stopPropagation()}>
          <DialogTitle className={styles.modalHeader}>
            {LocalizationString.DELEGATION}
            <Button
              appearance="subtle"
              icon={<Icon iconName="Dismiss24Regular" />}
              onClick={onPopupClose}
            />
          </DialogTitle>

          <form
            onSubmit={onFormSubmit}
            noValidate
            className={styles.modalWidth}
          >
            <DialogContent className={styles.modalBody}>
              <Label
                className={classes.label}
                size="large"
                data-testid="label-delegation-task"
              >
                {'Delegation Task: '}
              </Label>
              <Label
                className={mergeClasses(
                  classes.value,
                  classes.delegationFields
                )}
                data-testid="label-delegation-task-value"
              >
                {(rowItem?.label as string) || ''}
              </Label>
              <div
                className={mergeClasses(
                  styles.fieldsWidth,
                  classes.delegationFields
                )}
              >
                <Controller
                  data-testid="label-delegate-to"
                  name={'delegateName'}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <PeoplePicker
                        label={'Delegate To'}
                        dataTestId="people-picker-delegate-to"
                        required={true}
                        onOptionSelect={(_ev, data) => {
                          if (data?.optionText && data?.optionValue) {
                            onChange({
                              name: data.optionValue,
                              label: data.optionText,
                            });
                          } else {
                            onChange(undefined);
                          }
                        }}
                        roles={roles}
                        errorMessage={error?.message}
                        excludeUsers={taskOwner ? [taskOwner] : []}
                        selectedOptions={
                          value?.name
                            ? { value: value.name, text: value.label }
                            : []
                        }
                        filterByExactRoles={false}
                        referencePickerProps={{
                          filters: getActiveFilter(),
                        }}
                      />
                    );
                  }}
                />
              </div>

              <div
                className={mergeClasses(
                  styles.fieldsWidth,
                  classes.delegationFields
                )}
              >
                <Controller
                  name={'justification'}
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => {
                    return (
                      <TextFieldArea
                        label="Justification"
                        errorMessage={error?.message}
                        onChange={onChange}
                      ></TextFieldArea>
                    );
                  }}
                />
              </div>
            </DialogContent>

            <DialogActions className={styles.modalFooter}>
              <CustomButton
                buttonType={ButtonTypes.Ghost}
                buttonTitle={LocalizationString.CANCEL}
                onClick={onPopupClose}
              />
              <CustomButton
                buttonType={ButtonTypes.Primary}
                buttonTitle={LocalizationString.OK}
                onSubmit={() => onSubmitHandler()}
                isLoading={isLoading}
                iconPosition="before"
              />
            </DialogActions>
          </form>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
