import axiosInstance from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

export const notificationService = () => {
  // Get paginated in-app notifications
  const getInAppNotifications = async (
    page: number,
    limit: number,
    moduleName?: string
  ) => {
    const response = await axiosInstance.get(
      `${Endpoints.IN_APP_NOTIFICATIONS_URL}?page=${page}&limit=${limit}${
        moduleName ? `&module_name=${moduleName}` : ''
      }`
    );
    return response.data;
  };

  // Mark all as read or remove all
  const updateAllInAppNotifications = async (
    action: 'read' | 'remove',
    moduleName?: string
  ) => {
    const response = await axiosInstance.put(
      `${Endpoints.IN_APP_NOTIFICATIONS_URL}?action=${action}${
        moduleName ? `&module_name=${moduleName}` : ''
      }`
    );
    return response.data;
  };

  // Mark as read by id or remove by id
  const updateInAppNotification = async (
    id: number,
    action: 'read' | 'remove'
  ) => {
    const response = await axiosInstance.put(
      `${Endpoints.IN_APP_NOTIFICATIONS_URL}/${id}?action=${action}`
    );
    return response.data;
  };

  return {
    getInAppNotifications,
    updateAllInAppNotifications,
    updateInAppNotification,
  };
};
