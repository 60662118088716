import {
  ApiMethodType,
  AttributeTypeEnum,
  ObjectEnum,
} from '@celito.clients/enums';
import { useConfigureLayout, useObjectData } from '@celito.clients/hooks';
import { Loader } from '@celito.clients/shared';
import { ObjectAttributeDefinition, OwnerType } from '@celito.clients/types';
import DisplayView from 'libs/form-engine/src/lib/components/view-components/display-view/display-view.component';
import { get } from 'lodash';
import { useParams } from 'react-router';

import { documentTemplateStyles } from '../../styles';
import CustomFields from './components/custom-fields';

const fields: ObjectAttributeDefinition[] = [
  {
    key: 'label',
    label: 'Template ID',
    dataTypeKeyForFE: AttributeTypeEnum.PlainText,
    name: 'label',
    columnName: 'label',
    isEditable: false,
    ownerType: OwnerType.Custom,
    dataType: AttributeTypeEnum.PlainText,
  },
  {
    key: 'title',
    label: 'Template Name',
    dataTypeKeyForFE: AttributeTypeEnum.PlainText,
    name: 'title',
    columnName: 'title',
    isEditable: false,
    ownerType: OwnerType.Custom,
    dataType: AttributeTypeEnum.PlainText,
  },
  {
    key: 'description',
    label: 'Description',
    dataTypeKeyForFE: AttributeTypeEnum.PlainText,
    name: 'description',
    columnName: 'description',
    isEditable: false,
    ownerType: OwnerType.Custom,
    dataType: AttributeTypeEnum.LongText,
  },
  {
    custom: true,
    key: 'mergeFeildValues',
    label: 'Merge Field Values',
    dataTypeKeyForFE: AttributeTypeEnum.PlainText,
    name: 'mergeFeildValues',
    columnName: 'mergeFeildValues',
    isEditable: false,
    ownerType: OwnerType.Custom,
    dataType: AttributeTypeEnum.LongText,
  },
  {
    custom: true,
    key: 'document',
    label: 'Document',
    dataTypeKeyForFE: AttributeTypeEnum.Document,
    name: 'document',
    columnName: 'document',
    isEditable: false,
    ownerType: OwnerType.Custom,
    dataType: AttributeTypeEnum.Document,
  },
];

export default function DocumentTemplatesView() {
  const { name: recordName } = useParams();
  const { data, isLoading } = useObjectData({
    objectName: ObjectEnum.DOCUMENT_TEMPLATE,
    method: ApiMethodType.GET,
    recordName,
  });

  useConfigureLayout({
    pageTitle: '',
    headerTitle: 'Document Template',
    enablePadding: false,
  });

  const styles = documentTemplateStyles();

  if (isLoading) {
    return (
      <div className={styles.loaderContainer} style={{ placeItems: 'center' }}>
        <Loader fullPage />
      </div>
    );
  }

  return (
    <div className={styles.formContainer}>
      {fields.map((attribute) => {
        if (!attribute.custom) {
          return (
            <DisplayView
              {...({
                label: attribute.label,
                dataType: attribute.dataTypeKeyForFE,
                attributeConfig: attribute,
                recordDetails: null,
                value: get(data, attribute.name) ?? '',
              } as any)}
            />
          );
        }
        return (
          <CustomFields
            key={attribute.key}
            name={attribute.name}
            value={get(data, attribute.name) ?? ''}
          />
        );
      })}
    </div>
  );
}
