import { doesElementHaveXoverflow } from '@celito.clients/utils';
import { Tooltip, TooltipSlots } from '@fluentui/react-components';
import cn from 'classnames';
import { ConditionalWrapper } from 'libs/shared/src/lib/shared';
import { debounce } from 'lodash';
import {
  MutableRefObject,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

import classes from './conditional-tooltip.styles.module.css';

interface ConditionalTooltipProps extends PropsWithChildren {
  className?: string;
  /**
   * three levels above children, exists outside this component.
   */
  containerRef: MutableRefObject<HTMLDivElement | null>;
  /**
   * two levels above children
   */
  dataWrapperRef?: MutableRefObject<HTMLDivElement | null>;
  /**
   * one level above children
   */
  textWrapperRef?: MutableRefObject<HTMLDivElement | null>;
  textWrapperTestId?: string;
  tooltipContent: TooltipSlots['content'];
  extraConditions?: boolean;
}

export const ConditionalTooltip = ({
  className,
  containerRef,
  textWrapperTestId,
  tooltipContent,
  extraConditions = true,
  children,
  ...extraProps
}: ConditionalTooltipProps): JSX.Element => {
  const internalDataWrapperRef = useRef<HTMLDivElement | null>(null);
  const internalTextWrapperRef = useRef<HTMLDivElement | null>(null);

  const [hasOverflow, setHasOverflow] = useState(false);

  const dataWrapperRef = extraProps.dataWrapperRef ?? internalDataWrapperRef;
  const textWrapperRef = extraProps.textWrapperRef ?? internalTextWrapperRef;

  useEffect(() => {
    if (!extraConditions) return;

    const updateOverflow = () => {
      // To check if the div containing the text content has overflow
      // we first make css's overflow to be visible, so that overflow
      // can happen and we can detect it, then the overflow property
      // is removed
      if (dataWrapperRef.current && textWrapperRef.current) {
        textWrapperRef.current.style.overflow = 'visible';
        setHasOverflow(doesElementHaveXoverflow(dataWrapperRef.current));
        textWrapperRef.current.style.removeProperty('overflow');
      }
    };
    // When the column is resized, we will need to update overflow as well
    const resizeObserver = new ResizeObserver(debounce(updateOverflow, 400));

    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [containerRef, dataWrapperRef, extraConditions, textWrapperRef]);

  const isTooltipVisble = Boolean(
    extraConditions &&
      hasOverflow &&
      ((tooltipContent && typeof tooltipContent === 'string') ||
        typeof tooltipContent === 'number')
  );

  return (
    <ConditionalWrapper
      Wrapper={({ children }) => (
        <Tooltip content={tooltipContent} relationship="label" withArrow>
          {children as JSX.Element}
        </Tooltip>
      )}
      condition={isTooltipVisble}
    >
      <div ref={dataWrapperRef} className={cn(className, classes.dataWrapper)}>
        <div
          ref={textWrapperRef}
          data-testid={textWrapperTestId}
          className={cn(classes.textWrapper, {
            // If extraConditions is false then always show ellipsis
            [classes.hasOverflow]: hasOverflow || !extraConditions,
          })}
        >
          {children}
        </div>
      </div>
    </ConditionalWrapper>
  );
};
