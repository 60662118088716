import { useGetObjectData } from 'libs/shared/src/hooks/get-object-data';
import { useEffect } from 'react';

import { NestedSelectorProps } from './nested-selector.model';
import { NestedSelectorView } from './nested-selector.view';

export const NestedSelectorController = (props: NestedSelectorProps) => {
  const {
    objectData: { data: object, isLoading },
    setObjectName,
    objectName,
  } = useGetObjectData();

  useEffect(() => {
    if (props.relationship.objectName !== objectName)
      setObjectName(props.relationship.objectName);
  }, [props.relationship.objectName]);

  return <NestedSelectorView {...{ ...props, fields: object, isLoading }} />;
};
