import { Environment } from '@celito.clients/singleton';

import { environment } from '../../../libs/core/src/config/environment';

Environment.getInstance().setEnvironment(environment);
/** * Create an Axios Client with defaults */

export const msalConfig = {
  auth: {
    clientId: Environment.getInstance().getParam('NX_CLIENT_ID') as string,
    secret: Environment.getInstance().getParam('NX_CLIENT_SECRETS') as string,
    authority: Environment.getInstance().getParam('NX_AUTHORITY') as string,
    redirectUri: Environment.getInstance().getParam(
      'NX_REDIRECT_BASE_URL'
    ) as string,
    postLogoutRedirectUri: Environment.getInstance().getParam(
      'NX_REDIRECT_BASE_URL'
    ) as string,
    clientCapabilities: ['CP1'],
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
};

export const msalScopes = Environment.getInstance().getParam(
  'NX_MSAL_SCOPES'
) as string;

export const graphApiScope = Environment.getInstance().getParam(
  'NX_GRAPH_API_SCOPE'
) as string;

export const presenceScope = Environment.getInstance().getParam(
  'NX_PRESENCE_SCOPE'
) as string;
