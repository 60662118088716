import { createTestAttribute } from '@celito.clients/utils';
import { Field, InfoLabel, mergeClasses } from '@fluentui/react-components';

import { Icon } from '../shared';
import { FieldViewProps } from './field.model';
import { FieldStyles } from './field.styles';

export const FieldView = ({
  children,
  className,
  validationMessage,
  helperTextLabel,
  required,
  label,
  ...props
}: FieldViewProps) => {
  const styles = FieldStyles();
  return (
    <div className={styles.root}>
      <Field
        label={
          <InfoLabel
            label={label as string}
            size={props.size ?? 'large'}
            weight="semibold"
            info={helperTextLabel}
            required={required}
            className={styles.infoLabel}
          />
        }
        className={mergeClasses(className)}
        {...props}
      >
        {children}
      </Field>
      {validationMessage && (
        <span
          className={styles.error}
          data-testid={`validation-message-${createTestAttribute(
            label as string
          )}`}
        >
          <Icon iconName="Info12Filled" className={styles.mt2} />
          {validationMessage as string}
        </span>
      )}
    </div>
  );
};
