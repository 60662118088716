import { endpoint } from '@celito.clients/endpoints';
import { ApiMethodType, ExportFileType } from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import { getUserTimezone } from '@celito.clients/utils';

import {
  GetObjectDataOptions,
  TemporaryUnifyDefaultAndUserFilters,
} from './get-object-data';

interface GetExportDataOptions extends GetObjectDataOptions {}

export const getExportData = (
  objectName: string,
  fileType: ExportFileType,
  {
    filters,
    sortConfig,
    defaultFilters,
    recordDataFilter,
    defaultSortConfig,
  }: GetExportDataOptions = {},
  listViewName: string,
  shouldNotGetLatestVersion?: boolean,
  moduleName?: string,
  pageTitle?: string
) => {
  const requestBody = {
    filters: TemporaryUnifyDefaultAndUserFilters(
      defaultFilters,
      filters,
      recordDataFilter
    ),
    sort: sortConfig || defaultSortConfig,
  };
  return performRequest<{ message: string }>(
    `${endpoint.OBJECT_DATA_URL}/${objectName}/export`,
    ApiMethodType.POST,
    requestBody,
    {
      timeZone: getUserTimezone(),
      format: fileType,
      listViewName,
      moduleName,
      pageTitle,
      ...(shouldNotGetLatestVersion && { getAllVersions: true }),
    }
  );
};
