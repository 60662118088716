import { NavigationConfig } from '@celito.clients/shared';

import defaultUrlConfig from '../config/defaultUrlConfig';
import { ROUTES } from '../enums/routes';
import { HeaderConfig } from '../types/header-config';
import { initalHeaderConfig } from '../utils/header.config.util';

class HeaderConfigManager {
  private headerConfig: HeaderConfig;
  private transformedHeaderConfig: NavigationConfig | undefined;
  private defaultApplicationLink: string;
  private isDefaultApplicationLinkSet = false;

  constructor() {
    this.headerConfig = initalHeaderConfig;

    let layoutURL: string = ROUTES.LAYOUT;
    layoutURL = layoutURL.replace(
      ':moduleName',
      defaultUrlConfig.defaultModuleName
    );
    layoutURL = layoutURL.replace(':tabName', defaultUrlConfig.defaultTabName);
    layoutURL = layoutURL.replace(
      ':subTabName',
      defaultUrlConfig.defaultSubTabName
    );

    this.defaultApplicationLink = `${layoutURL}/home`;
  }

  getHeaderConfig(): HeaderConfig {
    return this.headerConfig;
  }

  setHeaderConfig(headerConfig: HeaderConfig): void {
    this.headerConfig = headerConfig;
  }

  getTransformedHeaderConfig(): NavigationConfig | undefined {
    return this.transformedHeaderConfig;
  }

  setTransformedHeaderConfig(transformedHeaderConfig: NavigationConfig): void {
    this.transformedHeaderConfig = transformedHeaderConfig;
  }

  getDefaultApplicationlink(): string {
    return this.defaultApplicationLink;
  }

  setDefaultApplicationLink(defaultAplicationLink: string | undefined): void {
    if (defaultAplicationLink) {
      this.defaultApplicationLink = defaultAplicationLink;
      this.isDefaultApplicationLinkSet = true;
    }
  }

  getIsDefaultApplicationLinkSet(): boolean {
    return this.isDefaultApplicationLinkSet;
  }
}

export default new HeaderConfigManager();
