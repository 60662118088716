import cn from 'classnames';

import { IStack } from './stack.model';
import styles from './stack.module.css';

const StackView = ({
  wrap = false,
  children,
  className,
  ...rest
}: IStack): JSX.Element => {
  return !wrap ? (
    <div className={cn(styles.stack, className)} {...rest}>
      {children}
    </div>
  ) : (
    <div className={cn(styles.stackWrap, className)}>
      <div className={styles.stackInner} {...rest}>
        {children}
      </div>
    </div>
  );
};

export default StackView;
