import { AccordionToggleEvent } from '@fluentui/react-components';
import { useState } from 'react';

import { SearchFilterProps } from './search-filter.model';
import SearchFilterView from './search-filter.view';

const SearchFilterController: React.FC<SearchFilterProps> = (props) => {
  const {
    facetsList,
    selectedFacets,
    onFacetUpdationHandler,
    isRefineSearchDisabled,
    searchWithinFilterValue,
    onSortWithinUpdationHandler,
    selectedQuickFilter,
  } = props;

  const [openFilterPanels, setOpenFilterPanels] = useState<string[]>([]);

  const onToggleHandler = (
    event: AccordionToggleEvent<HTMLElement>,
    data: any
  ) => {
    setOpenFilterPanels((prevState) => {
      if (prevState.includes(data.value)) {
        return prevState.filter((item) => item !== data.value);
      } else {
        return [...prevState, data.value];
      }
    });
  };

  return (
    <SearchFilterView
      openItems={openFilterPanels}
      onToggleHandler={onToggleHandler}
      facetsList={facetsList}
      setSelectedCheckboxesHandler={onFacetUpdationHandler}
      selectedCheckboxes={selectedFacets}
      isRefineSearchDisabled={isRefineSearchDisabled}
      searchWithinFilterValue={searchWithinFilterValue}
      setSelectedRadioButtonHandler={onSortWithinUpdationHandler}
      selectedQuickFilter={selectedQuickFilter}
    />
  );
};

export default SearchFilterController;
