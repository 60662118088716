import { makeStyles } from '@fluentui/react-components';

export const actionStyles = makeStyles({
  taskActionIcon: {
    position: 'relative',
    // right: '50px',
  },
  stageActionIcon: {
    position: 'relative',
  },
  spinner: {
    marginTop: '0.375rem',
    marginBottom: '0.375rem',
    marginLeft: '0.4rem',
  },
  flex50: {
    flexBasis: '50%',
  },
  m5: {
    margin: '5px',
  },
});
