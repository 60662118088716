import { createTestAttribute } from '@celito.clients/utils';
import { Radio, RadioGroup, useId } from '@fluentui/react-components';
import React from 'react';

import { Field } from '../../shared';
import { RadioButtonProps } from './radio-button.model';
import { radioButtonStyles } from './radio-button.styles';

export const RadioButtonView: React.FC<RadioButtonProps> = ({
  options,
  onChange,
  dataTestId,
  label,
  errorMessage,
  required,
  layout = 'horizontal',
  helperLabelText,
  ...restProps
}) => {
  const labelId = useId('label');

  const styles = radioButtonStyles();

  return (
    <Field
      label={label}
      helperTextLabel={helperLabelText}
      required={required}
      validationMessage={errorMessage}
      validationState={errorMessage ? 'error' : 'none'}
      size="large"
    >
      <RadioGroup
        layout={layout}
        className={styles.customRadioGroup}
        data-testid={dataTestId}
        aria-labelledby={labelId}
        value={restProps.value}
        onChange={(_: any, data: { value: string }) => onChange(data.value)}
        {...restProps}
      >
        {options &&
          options.map((option) => {
            return (
              <Radio
                key={option.value}
                value={option.value}
                label={option.label}
                data-testid={`radio-button-${createTestAttribute(
                  option.label as string
                )}`}
              />
            );
          })}
      </RadioGroup>
    </Field>
  );
};
