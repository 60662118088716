import {
  ApiMethodType,
  CourseStatusEnum,
  ObjectEnum,
  OperatorsEnum,
} from '@celito.clients/enums';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';

import { RelatedCourseObject } from '../related-course.model';

const courseObjectName = ObjectEnum.COURSE;

export const ITEM_LIMIT_PER_PAGE = 10;

function getAttributeName(filterAttributeName: string): string {
  if (filterAttributeName === 'quiz_a') {
    return 'quiz__a';
  } else if (filterAttributeName === 'training_material__a') {
    return 'training_materials__a'; // Extra (s) in training material
  } else {
    return filterAttributeName;
  }
}

export const getCourseData = async (
  trainingMaterialName: string,
  attributeName: string,
  currentPage: number
) => {
  const filters = [
    {
      conditions: {
        all: [
          {
            any: [
              {
                attribute: getAttributeName(attributeName),
                operator: OperatorsEnum.IN,
                value: [trainingMaterialName],
              },
            ],
          },
          {
            any: [
              {
                attribute: 'status__a',
                operator: OperatorsEnum.EQUALS,
                value: CourseStatusEnum.APPROVED,
              },
            ],
          },
        ],
      },
    },
  ];

  const response = await performRequest<RelatedCourseObject>(
    `${Endpoints.OBJECT_DATA_URL}/${courseObjectName}`,
    ApiMethodType.POST,
    {
      filters: filters,
    },
    {
      page: currentPage,
      limit: ITEM_LIMIT_PER_PAGE,
      getMaxMajorVersionOnly: true,
    }
  );
  return response.data;
};
