import { FormEngineModeTypeEnum } from '@celito.clients/enums';

import { FileDataProps } from '../multi-file-upload.model';
import FilesView from '../multi-file-view/multi-file-view.component';

interface IShowFiles {
  fileNames: (File | FileDataProps)[];
  removeFile: (fileName: string, key: number) => () => void;
  mode?: FormEngineModeTypeEnum;
  objectName: string;
  documentIds: string[];
  uploadedBy?: string[];
}

export const ShowFilesPreview = ({
  fileNames,
  removeFile,
  mode,
  objectName,
  documentIds,
  uploadedBy,
}: IShowFiles): JSX.Element => {
  return (
    <FilesView
      removeFile={removeFile}
      fileNames={fileNames}
      mode={mode}
      objectName={objectName}
      documentIds={documentIds}
      uploadedBy={uploadedBy}
    />
  );
};
