import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const createSuggestionMenuStyle = makeStyles({
  searchBar: {
    width: '100%',
    height: '38px',
  },

  searchBannerOption: {
    display: 'flex',
  },

  searchBanner: {
    display: 'block',
    width: '100%',
    backgroundColor: Color.drawer_header_color,
    fontSize: '1em',
    color: '#222222',
    ...shorthands.padding('8px'),
  },

  container: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('5px'),
    ...shorthands.margin('10px'),
  },

  labelSection: {
    display: 'flex',
    flexDirection: 'row',
    ...shorthands.gap('30px'),
  },

  label: {
    color: Color.gray_500,
  },

  viewAllOption: {
    cursor: 'pointer',
    color: Color.blue_700,
    marginLeft: 'auto',
  },

  list: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('5px'),
  },

  card: {
    ...shorthands.border('1px', 'solid', Color.gray_300),
    backgroundColor: Color.white,
    ...shorthands.borderRadius('5px'),
  },

  cardHeader: {
    fontSize: '16px',
    fontWeight: 600,
    color: Color.blue_700,
    cursor: 'pointer',
  },
});
