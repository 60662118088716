import {
  Menu,
  MenuGroup,
  MenuGroupHeader,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from '@fluentui/react-components';
import { ObjectDefinitionDto } from 'apps/web-client/src/types/object-definition-dto';

import { creatingMergeFieldName } from '../../utils';
import { NestedSelector } from './nested-selector.component';
import { NestedSelectorProps } from './nested-selector.model';
import { NestedSelectorStyles } from './nested-selector.styles';

interface NestedSelectorViewProps extends NestedSelectorProps {
  fields?: ObjectDefinitionDto;
  isLoading: boolean;
}

export const NestedSelectorView = ({
  relationship,
  fields,
  field: fieldProps,
  appendInitalObject = false,
  parentObjectName,
  lastLevel,
  firstLevel,
  onFieldClick,
}: NestedSelectorViewProps) => {
  const styles = NestedSelectorStyles();

  const noReferenceFields = fields?.objectAttributeDefinitions?.filter(
    (field) => !field.relationship
  );

  const attributes = !lastLevel
    ? fields?.objectAttributeDefinitions
    : noReferenceFields;

  return (
    <Menu positioning={'after'}>
      <MenuTrigger disableButtonEnhancement>
        <MenuItem>{fieldProps.label}</MenuItem>
      </MenuTrigger>

      <MenuPopover className={styles.menuPopover}>
        <MenuGroup>
          <MenuGroupHeader>{fields?.label}</MenuGroupHeader>
          <MenuList>
            {attributes?.map((field) =>
              field.relationship && !lastLevel ? (
                <NestedSelector
                  key={`selector-b-${field.name}`}
                  relationship={field.relationship}
                  field={field}
                  appendInitalObject={appendInitalObject}
                  parentObjectName={parentObjectName}
                  lastLevel
                  onFieldClick={onFieldClick}
                  firstLevel={creatingMergeFieldName(
                    undefined,
                    relationship,
                    appendInitalObject,
                    parentObjectName
                  )}
                />
              ) : (
                <MenuItem
                  onClick={() =>
                    onFieldClick(
                      field.name,
                      relationship,
                      appendInitalObject,
                      parentObjectName,
                      firstLevel
                    )
                  }
                  key={field.name}
                >
                  {field.label}
                </MenuItem>
              )
            )}
          </MenuList>
        </MenuGroup>
      </MenuPopover>
    </Menu>
  );
};
