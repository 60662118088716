export const getTopResultCardDataFromApiResponse = (apiResponse: any) => {
  return apiResponse?.flatMap(
    (result: any) =>
      // eslint-disable-next-line no-underscore-dangle
      result._source || []
  );
};

export const getTopResultCardHeading = (topResult: {
  label: string;
  title: string;
}) => {
  if (!topResult.title) return topResult.label;

  return `${topResult.title} | ${topResult.label}`;
};
