import { AvatarColors } from '@celito.clients/enums';
import { Persona } from '@fluentui/react-components';
import { useUserPresence } from 'libs/core/src/hooks/useUser';

import { IUserPersona } from './user-persona.model';

const UserPersonaView = ({
  dataTestId,
  imageInitials,
  id,
  onClick,
  hidePersonaDetails,
  userName,
  secondaryText,
  color = AvatarColors.anchor,
  size,
  idpOid,
}: IUserPersona): JSX.Element => {
  const { data: userPresenceData } = useUserPresence(idpOid);

  const presence = userPresenceData?.availability
    ? userPresenceData?.availability.toLowerCase()
    : 'none';

  return (
    <Persona
      name={hidePersonaDetails ? '' : userName}
      secondaryText={secondaryText}
      data-testid={dataTestId}
      size={size}
      avatar={{
        initials: imageInitials,
        color: color,
      }}
      id={id}
      onClick={onClick}
      presence={{ status: presence }}
    />
  );
};

export default UserPersonaView;
