import { ApiMethodType } from '@celito.clients/enums';
import { SortConfig } from '@celito.clients/list-view-engine';
import { performRequest } from '@celito.clients/network';
import Endpoints from 'libs/core/src/config/endpoint';
import { RulesComponentListViewFilter } from 'libs/shared/src/lib/rules-component/types/rules-component.types';

interface IBodyConfig {
  viewName: string;
  pageNumber: number;
  limit?: number;
  filters?: RulesComponentListViewFilter[];
  sortConfig?: SortConfig;
}

interface IApiResponse {
  data: any;
}

export const getAllObjects = async ({
  pageNumber,
  filters,
  limit,
  sortConfig,
  viewName,
}: IBodyConfig) => {
  const filterBody = {
    filters,
    ...(sortConfig?.attribute && {
      sort: { attribute: sortConfig?.attribute, order: sortConfig?.order },
    }),
  };

  const respone = await performRequest<IApiResponse>(
    `${Endpoints.OBJECT_DATA_URL}/${viewName}?page=${pageNumber}&limit=${
      limit ?? 10
    }`,
    ApiMethodType.POST,
    filterBody
  );
  return respone.data;
};
