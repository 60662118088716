import { performRequest } from '@celito.clients/network';

import Endpoints from '../config/endpoint';
import { ApiMethodType } from '../enums/api-constants';
import { Task, TaskApiPayloadsProps, TaskCompleteResponse } from '../types';
import { TaskInfo } from './../../../../apps/web-client/src/screens/e-signature/e-signature.model';

export const fetchTaskInfo = async (
  taskName: string,
  payloads: TaskApiPayloadsProps
) => {
  const response = await performRequest<{ task: TaskInfo }>(
    `${Endpoints.TASK_URL}/${taskName}`,
    ApiMethodType.GET,
    payloads
  );
  return response.data.task;
};

export const delegateTask = async (
  taskName: string,
  payloads: TaskApiPayloadsProps
) => {
  const response = await performRequest<TaskInfo>(
    `${Endpoints.TASK_URL}/${taskName}`,
    ApiMethodType.PUT,
    payloads
  );
  return response.data;
};

export const claimTask = async (taskName: string) => {
  const response = await performRequest<{ task: Task }>(
    `${Endpoints.TASK_URL}/${taskName}/claim`,
    ApiMethodType.PUT,
    {}
  );
  return response.data;
};

export const fetchObjectTaskInfo = async (
  objectName: string,
  recordName: string,
  params?: object
) => {
  const response = await performRequest<TaskCompleteResponse>(
    `${Endpoints.TASK_OBJECT_URL}/${objectName}/record/${recordName}`,
    ApiMethodType.GET,
    null,
    params
  );
  return response.data;
};

export const getAllTaskData = async (paylod: any = {}) => {
  const response = await performRequest<{ data: Task[] }>(
    `${Endpoints.OBJECT_DATA_URL}/task__s?limit=10&page=1`,
    ApiMethodType.POST,
    paylod
  );
  return response.data;
};
