import { Loader } from '@celito.clients/shared';
import cn from 'classnames';

import EmptySearchResult from './components/EmptySearchResult';
import FilterChips from './components/FilterChips';
import NoResult from './components/NoResult';
import ResultSection from './components/ResultSection';
import { SearchInput } from './components/SearchInput';
import SearchFilter from './search-filter/search-filter.component';
import { SearchResultViewProps } from './search-result.model';
import classes from './search-result.module.css';
import { isAnyFacetSelected } from './utils/search-result.util';

const SearchResultView: React.FC<SearchResultViewProps> = (props) => {
  const {
    searchValue,
    resultCount,
    onSearchInputChange,
    onSearch,
    onClearSearch,
    facetsList,
    selectedFacets,
    onFacetUpdationHandler,
    quickFilters,
    selectedQuickFilter,
    setSelectedQuickFilter,
    selectedViewType,
    setSelectedViewTypeHandler,
    pageLimit,
    currentPage,
    setCurrentPage,
    searchResult,
    onFilterChipDismissHandler,
    onClearFilter,
    isRefineSearchDisabled,
    resultCountInTab,
    enteredSearchValue,
    selectedSortOptions,
    onSortUpdationHandler,
    onSearchCardClick,
    isPageLoading,
    isCardLoading,
    searchWithinFilterValue,
    onSortWithinUpdationHandler,
  } = props;

  return (
    <div className={classes.container}>
      <div className={classes.left_panel}>
        <SearchFilter
          facetsList={facetsList}
          selectedFacets={selectedFacets}
          onFacetUpdationHandler={onFacetUpdationHandler}
          isRefineSearchDisabled={isRefineSearchDisabled}
          searchWithinFilterValue={searchWithinFilterValue}
          onSortWithinUpdationHandler={onSortWithinUpdationHandler}
          selectedQuickFilter={selectedQuickFilter}
        />
      </div>
      <div className={classes.main_page}>
        <div className={classes.main_page_top}>
          <SearchInput
            value={searchValue}
            resultCount={resultCount}
            onSearchInputChange={onSearchInputChange}
            onClearSearch={onClearSearch}
            onSearch={onSearch}
            enteredSearchValue={enteredSearchValue}
            onTopResultCardClickHandler={onSearchCardClick}
          />
        </div>
        <div className={classes.main_page_bottom}>
          {isAnyFacetSelected(selectedFacets) && (
            <div className={classes.applied_filters}>
              <div className={classes.filter_message_label}>
                Applied Filters
              </div>
              <FilterChips
                selectedFacets={selectedFacets}
                onFilterDismissHandler={onFilterChipDismissHandler}
              />
              <div
                className={classes.clear_filter_button}
                role="button"
                onClick={onClearFilter}
              >
                Clear Filter
              </div>
            </div>
          )}
          <div
            className={cn({
              [classes.center_flex]: resultCount <= 0 || isPageLoading,
              [classes.flex_section]: isCardLoading,
            })}
          >
            {isPageLoading ? (
              <Loader />
            ) : resultCount === -1 ? (
              <EmptySearchResult />
            ) : resultCount === 0 ? (
              <NoResult />
            ) : (
              <ResultSection
                quickFilters={quickFilters}
                selectedQuickFilter={selectedQuickFilter}
                setSelectedQuickFilter={setSelectedQuickFilter}
                selectedViewType={selectedViewType}
                setSelectedViewTypeHandler={setSelectedViewTypeHandler}
                searchValue={searchValue}
                pageLimit={pageLimit}
                resultCount={resultCountInTab}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                searchResult={searchResult}
                onSearchCardClickHandler={onSearchCardClick}
                selectedSortList={selectedSortOptions}
                onSortUpdationHandler={onSortUpdationHandler}
                isCardLoading={isCardLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResultView;
