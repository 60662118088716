import { LocalizationString } from '@celito.clients/assets';
import { ButtonTypes, DateFormat } from '@celito.clients/enums';
import {
  Container,
  CustomButton,
  Stack,
  StackItem,
  SwappableSelect,
} from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';
import { formatDate } from '@celito.clients/utils';
import { Text } from '@fluentui/react-components';
import React from 'react';

import { ViewGroupModel } from './view-group.model';
import classes from './view-group.module.css';
import { viewGroupStyles } from './view-group.styles';

const Row: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <Stack className={classes.row}>{children}</Stack>;
};

const Column: React.FC<{ children: JSX.Element | JSX.Element[] }> = ({
  children,
}) => {
  return <StackItem className={classes.column}>{children}</StackItem>;
};

const DisplayItem: React.FC<{ label: string; value?: number | string }> = ({
  label,
  value,
}) => {
  return (
    <Stack>
      <Text className={classes.label}>{label}</Text>
      <Text className={classes.value}>{value || 'NA'}</Text>
    </Stack>
  );
};

const ViewGroupView: React.FC<ViewGroupModel> = ({
  isLoading,
  list,
  setList,
  setRightItemList,
  selectedList,
  setSelectedList,
  navigateBackRoute,
  permissionGrpData,
  rightItemList,
  onEditBtnClick,
  navigateBack,
}) => {
  const { cssVariables } = useTheme();

  const styles = viewGroupStyles();

  return (
    <Container
      style={{ ...cssVariables }}
      showBackBtn
      navigateBackRoute={navigateBackRoute}
      name={permissionGrpData?.label}
      className={styles.container}
    >
      <div>
        <Row>
          <Column>
            <DisplayItem label="Group Name" value={permissionGrpData?.label} />
          </Column>
          <Column>
            <DisplayItem
              label="Description"
              value={permissionGrpData?.description}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem
              label="Roles"
              value={permissionGrpData?.roles
                .filter((role) => role.label)
                .map((role) => role.label)
                .join(',')}
            />
          </Column>
          <Column>
            <DisplayItem
              label={LocalizationString.ACTIVE}
              value={permissionGrpData?.isActive ? 'Yes' : 'No'}
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem
              label="Created By"
              value={permissionGrpData?.createdBy.label}
            />
          </Column>
          <Column>
            <DisplayItem
              label="Created Date"
              value={
                permissionGrpData?.createdAtUtc
                  ? formatDate(
                      permissionGrpData.createdAtUtc,
                      DateFormat.DateTime
                    )
                  : ''
              }
            />
          </Column>
        </Row>
        <Row>
          <Column>
            <DisplayItem
              label="Modified By"
              value={permissionGrpData?.modifiedBy.label}
            />
          </Column>
          <Column>
            <DisplayItem
              label="Modified Date"
              value={
                permissionGrpData?.modifiedAtUtc
                  ? formatDate(
                      permissionGrpData.modifiedAtUtc,
                      DateFormat.DateTime
                    )
                  : ''
              }
            />
          </Column>
        </Row>
        <Row>
          {!isLoading ? (
            <SwappableSelect
              list={list}
              setList={setList}
              setRightItemList={setRightItemList}
              selectedList={selectedList}
              setSelectedList={setSelectedList}
              rightItemList={rightItemList}
              leftLabel="Available Members"
              rightLabel="Selected Members"
              disabled
            />
          ) : (
            <div />
          )}
        </Row>
      </div>

      <Stack className={classes.footer}>
        <CustomButton
          data-testid="button-cancel-view-group"
          customStyles={classes.btn_container}
          buttonType={ButtonTypes.Ghost}
          buttonTitle="Cancel"
          onButtonClicked={navigateBack}
        />
        <CustomButton
          data-testid="button-edit-view-group"
          customStyles={classes.btn_container}
          leftIcon="Edit"
          buttonType={ButtonTypes.Primary}
          buttonTitle={'Edit'}
          onButtonClicked={onEditBtnClick}
        />
      </Stack>
    </Container>
  );
};

export default ViewGroupView;
