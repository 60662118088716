import { LocalizationString } from '@celito.clients/assets';
import { BREAKPOINTS, ButtonTypes } from '@celito.clients/enums';
import { CustomButton, Panel } from '@celito.clients/shared';
import { useTheme } from '@celito.clients/theme';

import { UserView } from './user-view/user-view.component';
import classes from './user-view/user-view.module.css';

type UserViewPanelWrapperProps = {
  isOpen: boolean;
  label: string;
  name: string;
  onDismiss: () => void;
};

export const UserViewPanelWrapper = ({
  isOpen,
  label,
  name,
  onDismiss,
}: UserViewPanelWrapperProps) => {
  const { cssVariables } = useTheme();
  return (
    <Panel
      open={isOpen}
      onClose={onDismiss}
      position="end"
      headerText={label}
      style={{
        width:
          window.innerWidth <= BREAKPOINTS.TABLET.SAFARI.MIN_WIDTH
            ? '100vw'
            : '75vw',
        ...cssVariables,
      }}
    >
      <UserView name={name} />
      <div className={classes.btnContainer}>
        <CustomButton
          buttonTitle={LocalizationString.CANCEL}
          buttonType={ButtonTypes.Ghost}
          onClick={onDismiss}
        />
      </div>
    </Panel>
  );
};
