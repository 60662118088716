import { mergeClasses } from '@fluentui/react-components';

import { FormActionButtonsContainerProps } from './form-action-buttons-container.model';
import classes from './form-action-buttons-container.styles.module.css';

interface FormActionButtonsContainerViewProps
  extends FormActionButtonsContainerProps {}

const FormActionButtonsContainerView = ({
  dataTestId,
  children,
  className,
}: FormActionButtonsContainerViewProps): JSX.Element => {
  return (
    <div
      data-testid={dataTestId}
      className={mergeClasses(classes.container, className)}
    >
      {children}
    </div>
  );
};

export default FormActionButtonsContainerView;
