import { useState } from 'react';

import { UserAvatarProps } from './user-avatar.model';
import UserAvatarView from './user-avatar.view';

interface UserAvatarControllerProps extends UserAvatarProps {}

const UserAvatarController = (
  props: UserAvatarControllerProps
): JSX.Element => {
  const [showAlert, setShowAlert] = useState(false);

  const openAlert = () => {
    setShowAlert(true);
  };
  const closeAlert = () => {
    setShowAlert(false);
  };

  const handleSignout = () => {
    props.onSignOutClick?.();
  };

  return (
    <UserAvatarView
      dataTestId="button-avatar-view"
      showAlert={showAlert}
      openAlert={openAlert}
      closeAlert={closeAlert}
      handleSignout={handleSignout}
      {...props}
    />
  );
};

export default UserAvatarController;
