import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { differenceInMinutes } from 'date-fns';
import Cookies from 'js-cookie'; // Install js-cookie
import { useCallback, useEffect, useRef, useState } from 'react';

const maxTime = 60; // minutes
const cookieName = 'lastTimeStamp';

const SessionTimeout = () => {
  const [events] = useState([
    'click',
    'load',
    'scroll',
    'mousemove',
    'mousedown',
    'keypress',
  ]);

  const isAuthenticated = useIsAuthenticated();
  const { instance } = useMsal();

  const warningInactiveInterval = useRef<number | null>(null);
  const startTimerInterval = useRef<number | null>(null);

  const clearExistingIntervals = () => {
    if (warningInactiveInterval.current) {
      clearInterval(warningInactiveInterval.current);
      warningInactiveInterval.current = null;
    }
    if (startTimerInterval.current) {
      clearTimeout(startTimerInterval.current);
      startTimerInterval.current = null;
    }
  };

  const isCookieEnabled = () => {
    try {
      document.cookie = 'cookietest=1';
      const isEnabled = document.cookie.indexOf('cookietest=') !== -1;
      document.cookie = 'cookietest=; expires=Thu, 01 Jan 1970 00:00:00 GMT';
      return isEnabled;
    } catch {
      return false;
    }
  };

  const setTimeStamp = (timeStamp: string) => {
    if (isCookieEnabled()) {
      Cookies.set(cookieName, timeStamp, { expires: maxTime / 1440 }); // Expires after maxTime (in days)
    } else {
      localStorage.setItem(cookieName, timeStamp);
    }
  };

  const getTimeStamp = () => {
    if (isCookieEnabled()) {
      return Cookies.get(cookieName);
    }
    return localStorage.getItem(cookieName);
  };

  const removeTimeStamp = () => {
    if (isCookieEnabled()) {
      Cookies.remove(cookieName);
    } else {
      localStorage.removeItem(cookieName);
    }
  };

  const warningInactive = (timeString: string | null | undefined) => {
    clearExistingIntervals();

    if (!timeString) return;

    warningInactiveInterval.current = window.setInterval(() => {
      const pastDate = new Date(timeString);
      const now = new Date();
      const minPast = differenceInMinutes(now, pastDate);

      if (minPast >= maxTime) {
        clearExistingIntervals();
        removeTimeStamp();
        instance.logoutRedirect();
      }
    }, 1000);
  };

  const timeChecker = useCallback(() => {
    clearExistingIntervals();
    startTimerInterval.current = window.setTimeout(() => {
      const storedTimeStamp = getTimeStamp();
      warningInactive(storedTimeStamp);
    }, 1000);
  }, []);

  const resetTimer = useCallback(() => {
    clearExistingIntervals();

    if (isAuthenticated) {
      const timeStamp = new Date().toISOString();
      setTimeStamp(timeStamp);
      timeChecker();
    } else {
      removeTimeStamp();
    }
  }, [isAuthenticated, timeChecker]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, resetTimer);
    });

    if (isAuthenticated) {
      timeChecker();
    }

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, resetTimer);
      });
      clearExistingIntervals();
    };
  }, [resetTimer, events, isAuthenticated, timeChecker]);

  return null;
};

export default SessionTimeout;
