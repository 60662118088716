import { ObjectEnum } from '@celito.clients/enums';
import { ObjectAttributeType } from '@celito.clients/types';
import {
  getFormValues,
  refactorFormData,
} from 'libs/form-engine/src/lib/utils/helper';

import { ObjectInitiationViewNameEnum } from '../object-initiation-screen.model';

export const getObjectNameByView = (
  viewName: ObjectInitiationViewNameEnum
): string => {
  switch (viewName) {
    case ObjectInitiationViewNameEnum.ControlledDocumentInitiation:
      return ObjectEnum.CONTROLLED_DOCUMENT;
    case ObjectInitiationViewNameEnum.CourseInitiation:
      return ObjectEnum.COURSE;
    default:
      return ObjectEnum.CURRICULUM;
  }
};

export const transformToPayload = (
  record: Record<string, any>,
  objectDefinition: ObjectAttributeType
) => {
  const formData = new FormData();
  record = refactorFormData(
    objectDefinition!,
    getFormValues(objectDefinition!, record)
  );

  delete record?.['is_trainable__a'];
  delete record?.['lifecycle_stage__s'];
  delete record?.['document_status__a'];
  delete record?.['modified_by_user__s'];
  delete record?.['created_by_user__s'];
  delete record?.['created_at_utc__s'];
  delete record?.['modified_at_utc__s'];
  delete record?.['requestor__a'];
  delete record?.['version__s'];

  if (objectDefinition?.labelFormat) {
    delete record?.['name__s'];
    delete record?.['label__s'];
  }

  Object.keys(record).forEach((key) => {
    if (
      record[key] !== undefined &&
      !(record[key] instanceof Date) &&
      record[key] !== '' &&
      !Number.isNaN(record[key])
    ) {
      formData.append(
        key,
        Array.isArray(record[key])
          ? record[key].length > 0
            ? JSON.stringify(record[key])
            : null
          : record[key]
      );
    }
    if (record[key] instanceof Date) {
      formData.append(key, record[key].toISOString());
    }
  });

  return formData;
};
