import { Loader } from '@celito.clients/shared';
import { FormWithRowSupport } from 'libs/form-engine/src/lib/components/form';
import { getFormValues } from 'libs/form-engine/src/lib/utils/helper';

import { FormGroupProps } from './form-group.model';

const FormGroup = ({
  attributeConfig,
  recordData,
  onSave,
  formRef,
  submitRef,
  methods,
  groups,
  className,
  setValidationSchema,
  excludeUsers,
}: FormGroupProps) => {
  return (
    <>
      {groups.map(({ isLoading, fields }, index) =>
        isLoading ? (
          <Loader key={index} />
        ) : (
          fields.length > 0 && (
            <FormWithRowSupport
              key={index}
              attributes={fields}
              data={{
                ...getFormValues(attributeConfig!, recordData),
              }}
              onSubmit={onSave}
              form={formRef}
              submitButtonRef={submitRef}
              objectValidationRules={
                attributeConfig?.objectValidationRules || []
              }
              objectAttributeDef={
                attributeConfig?.objectAttributeDefinitions || []
              }
              className={className}
              formInstance={methods}
              setValidationSchema={setValidationSchema}
              excludeUsers={excludeUsers}
            />
          )
        )
      )}
    </>
  );
};

export default FormGroup;
