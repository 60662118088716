import { Icon } from '@celito.clients/shared';
import {
  Button,
  Menu,
  MenuPopover,
  MenuTrigger,
  useOverflowMenu,
} from '@fluentui/react-components';

import { OverflowMenuProps } from '../search-result.model';
import classes from '../search-result.module.css';
import OverflowMenuItem from './OverflowMenuItem';

const OverflowMenu: React.FC<OverflowMenuProps> = (props) => {
  const { filters, onTabSelect } = props;

  const { ref, isOverflowing, overflowCount } =
    useOverflowMenu<HTMLButtonElement>();

  if (!isOverflowing) {
    return null;
  }

  return (
    <Menu>
      <MenuTrigger disableButtonEnhancement>
        <Button
          appearance="transparent"
          className={classes.more_button}
          ref={ref}
          icon={<Icon iconName="ChevronDoubleDown16Filled" />}
          aria-label={`${overflowCount} more tabs`}
          role="tab"
        />
      </MenuTrigger>
      <MenuPopover>
        {filters.map((tab) => (
          <OverflowMenuItem key={tab} tab={tab} onTabSelect={onTabSelect} />
        ))}
      </MenuPopover>
    </Menu>
  );
};

export default OverflowMenu;
