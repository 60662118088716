import './theme.module.css';

import React, { Dispatch, SetStateAction } from 'react';

import { cssGenerator } from './style-generator';
import { ThemeColors, ThemeType } from './theme-colors';
import { ThemeFontFamily } from './theme-fontfamily';

interface ThemeContextProps {
  theme: ThemeProps;
  setCurrentTheme: Dispatch<SetStateAction<ThemeType>>;
  cssVariables: object;
}

export interface ThemeProps {
  colors: (typeof ThemeColors)['light'];
  fontFamily: typeof ThemeFontFamily;
}

const ThemeContext = React.createContext<ThemeContextProps>({
  theme: {
    colors: ThemeColors['light'],
    fontFamily: ThemeFontFamily,
  },
  setCurrentTheme: () => {},
  cssVariables: cssGenerator(ThemeColors['light']),
});

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [currentTheme, setCurrentTheme] = React.useState<ThemeType>('light');
  const theme = {
    colors: ThemeColors[currentTheme],
    fontFamily: ThemeFontFamily,
  };
  const cssVariables = cssGenerator({
    ...ThemeColors[currentTheme],
    ...ThemeFontFamily,
  });

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setCurrentTheme,
        cssVariables,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = () => React.useContext(ThemeContext);
