import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const signOffPanelStyles = makeStyles({
  document_sign_off: {
    ...shorthands.padding('1.5rem'),
    ...shorthands.gap('1.5rem'),
    marginLeft: '-1.5rem',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'flex-end',
    boxShadow: 'inset 0px 1px 0px var(--gray-200)',
    zIndex: '10',
    backgroundColor: Color.drawer_header_color,
  },
});
