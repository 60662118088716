import { Task } from '@celito.clients/types';

export class VerdictSchema {
  name!: string;
  label!: string;
  comment?: string;
  isESignatureRequired!: boolean;
  eSignature!: object;
  fields!: any[];
  recipients!: string[];
  notificationTemplateName!: string;
  isSignatureMeaningRequired!: boolean;
  signatureMeaning!: string;
  signatureInstructions!: string;
}
export interface OwnerOrModifiedBy {
  name: string;
  label: string;
  email: string;
  isActive: boolean;
  deletedAtUtc?: string | null;
}
export interface IObject {
  name: string;
  label: string;
}
export interface CreatedBy {
  name: string;
  label: string;
  email: string;
}

export enum ActionButtonType {
  ICON = 'icon',
  BUTTON = 'button',
}

export type GroupNames =
  | 'In Draft'
  | 'In Review'
  | 'In Approval'
  | 'Approved'
  | 'Effective';
export interface TaskGroupedObject {
  [id: string]: {
    [key in GroupNames]?: {
      [label: string]: Task[];
    };
  };
}

export enum TaskOutCome {
  CANCELED = 'Canceled',
  COMPLETE = 'Completed',
  ASSIGNED = 'Assigned',
}
