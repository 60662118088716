import { ZIndex } from '@celito.clients/theme';
import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const userAvatarStyles = makeStyles({
  popoverSurface: {
    ...shorthands.padding('0.5rem'),
    width: '320px',
    zIndex: ZIndex.Dropdown,
  },

  companyInfo: {
    display: 'flex',
    paddingBottom: '0.5rem',
    marginBottom: '1rem',
    ...shorthands.borderBottom('1px', 'solid', Color.gray_300),
  },
  timezoneInfo: {
    display: 'flex',
    paddingBottom: '0.5rem',
    marginTop: '0.5rem',
    ...shorthands.borderTop('1px', 'solid', Color.gray_300),
  },
  timezoneHeading: {
    marginTop: '0.5rem',
    flexBasis: '50%',
  },
  timezoneValue: {
    ...shorthands.overflow('hidden'),
    flexBasis: '95%',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginTop: '0.5rem',
  },
});
