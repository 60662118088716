import {
  AttributeTypeEnum,
  TrainingAssignmentTypeEnum,
} from '@celito.clients/enums';
import { useLayout, useQueryParams } from '@celito.clients/hooks';
import {
  ExportItem,
  getFiltersFromQueryParams,
  getNumberOfFilters,
  setFiltersInQueryParams,
} from '@celito.clients/list-view-engine';
import { UserContext } from '@celito.clients/provider';
import {
  isObjectEmpty,
  raiseErrorToast,
  successToast,
} from '@celito.clients/utils';
import {
  getIsSortedAscending,
  getSortOrder,
  isReferenceSortSupported,
} from 'libs/list-view-engine/src/lib/utils/sort.util';
import { useCallback, useContext, useEffect, useState } from 'react';

import { GridViewProps } from '../../grid-view-new/src';
import { ColumnData } from '../../grid-view-new/src/types';
import { RulesComponentListViewFilter } from '../../rules-component/types/rules-component.types';
import { AssignmentsListProps } from './assignments-list.model';
import { AssignmentsListView } from './assignments-list.view';
import { getLoadingRowData } from './config';
import {
  getCompanyAssignmentExportData,
  getCompanyAssignmentsData,
  getILCAssignmentsData,
  getMyAssignmentsData,
  getMyTeamsAssignmentsData,
} from './services';
import { SortConfig } from './types';
import {
  getAllColumns,
  getAssignmentListItemsPerPage,
  getAsssignmentName,
  getCompanyAssignmentsGroupNames,
  getCompanyAssignmentsListColumnConfig,
  getCompanyAssignmentsRowData,
  getCustomMetadata,
  getILCAssignmentsListColumnConfig,
  getILCAssignmetsRowData,
  getMyAssignmentsListColumnConfig,
  getMyAssignmetsRowData,
} from './utils';

const initialFilters: RulesComponentListViewFilter[] = [];

interface AssignmentsListControllerProps extends AssignmentsListProps {}

let abortController: AbortController | undefined = undefined;

export const AssignmentsListController = ({
  isTrainingMatrix = false,
  ...props
}: AssignmentsListControllerProps): JSX.Element => {
  const { configureLayout } = useLayout();
  const { user } = useContext(UserContext);
  const assignmentListItemsPerPage = getAssignmentListItemsPerPage(
    props.trainingAssignmentType
  );
  const { getSearchParams, setUrlParams, clearUrlParams } = useQueryParams();
  const queryParamFilters = getFiltersFromQueryParams(getSearchParams);

  const [columnData, setColumnData] = useState<GridViewProps['columns']>([]);
  const [rowData, setRowData] = useState<GridViewProps['items']>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [isFilterPanelOpen, setIsFilterPanelOpen] = useState(false);

  const [customObjectMetadata, setCustomObjectMetadata] = useState(
    getCustomMetadata(props.trainingAssignmentType)
  );
  const [allColumns, setAllColumns] = useState(
    getAllColumns(customObjectMetadata)
  );
  const [filters, setFilters] = useState<RulesComponentListViewFilter[]>(
    queryParamFilters ? [queryParamFilters] : []
  );
  const [numberOfFilters, setNumberOfFilters] = useState(
    getNumberOfFilters(queryParamFilters)
  );
  const [currentPageNumber, setCurrentPageNumber] = useState(1);
  const [totalItems, setTotalItems] = useState(assignmentListItemsPerPage);
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>();
  const [groupNames, setGroupNames] = useState<string[]>([]);
  const [resetColumnSizes, setResetColumnSizes] = useState(false);

  const showShimmeringGrid = useCallback(() => {
    // show shimmering rows
    setRowData(getLoadingRowData());
  }, []);

  const fetchRowData = useCallback(
    async (
      pageNumber = 1,
      filterConfig?: RulesComponentListViewFilter[],
      sortConfig?: SortConfig
    ) => {
      setIsLoading(true);
      showShimmeringGrid();

      if (abortController) {
        abortController.abort();
      }
      abortController = new AbortController();
      const signal = abortController.signal;

      try {
        if (
          props.trainingAssignmentType ===
          TrainingAssignmentTypeEnum.MyAssignments
        ) {
          const courseData = await getMyAssignmentsData(
            {
              limit: assignmentListItemsPerPage,
              page: pageNumber,
            },
            {
              filters: filterConfig?.[0] || filters[0],
              sortConfig,
            },
            user,
            signal
          );
          const r = getMyAssignmetsRowData(
            courseData.data,
            props.trainingAssignmentType
          );
          setTotalItems(courseData.total);
          setRowData(r);
        } else if (
          props.trainingAssignmentType ===
          TrainingAssignmentTypeEnum.ILCAssignments
        ) {
          const courseData = await getILCAssignmentsData();
          const r = getILCAssignmetsRowData(
            courseData,
            props.trainingAssignmentType
          );
          setRowData(r);
        } else if (
          props.trainingAssignmentType ===
          TrainingAssignmentTypeEnum.MyTeamsAssignments
        ) {
          const assignmentData = await getMyTeamsAssignmentsData(
            {
              limit: assignmentListItemsPerPage,
              page: pageNumber,
            },
            {
              filters: filterConfig?.[0] || filters[0],
              sortConfig,
            },
            signal
          );

          setTotalItems(assignmentData.total);
          const groups = getCompanyAssignmentsGroupNames(assignmentData.data);
          setGroupNames(groups);
          const r = getCompanyAssignmentsRowData(
            assignmentData.data,
            props.trainingAssignmentType
          );
          setRowData(r);
        } else {
          const assignmentData = await getCompanyAssignmentsData(
            {
              limit: assignmentListItemsPerPage,
              page: pageNumber,
            },
            {
              filters: filterConfig?.[0] || filters[0],
              sortConfig,
            },
            signal
          );
          setTotalItems(assignmentData.total);
          const groups = getCompanyAssignmentsGroupNames(assignmentData.data);
          setGroupNames(groups);
          const rowData = getCompanyAssignmentsRowData(
            assignmentData.data,
            props.trainingAssignmentType
          );
          setRowData(rowData);
        }
      } catch (_error) {
        if ((_error as Error).name === 'CanceledError') {
          return;
        }
        raiseErrorToast(_error);
      } finally {
        setIsLoading(false);
      }
    },

    [props.trainingAssignmentType]
  );

  const onColumnClick: ColumnData['onColumnClick'] = useCallback(
    (_ev: React.MouseEvent<HTMLElement, MouseEvent>, column: ColumnData) => {
      setColumnData((prev) =>
        prev.map((c) => {
          if (c.key === column.key) {
            return {
              ...c,
              isSortedAscending: getIsSortedAscending(
                c.isSorted,
                c.isSortedAscending
              ),
              isSorted: true,
            };
          }
          return {
            ...c,
            isSorted: false,
          };
        })
      );

      const sortConf: SortConfig = {
        attribute: column.data?.name,
        order: getSortOrder(column.isSorted, column.isSortedAscending),
        ...(isReferenceSortSupported(
          column?.data?.dataType as AttributeTypeEnum
        ) && {
          referencedTableColumnName: column?.data?.columnNameToBePicked,
        }),
      };
      setSortConfig(sortConf);
      fetchRowData(currentPageNumber, filters, sortConf);
    },
    [currentPageNumber, fetchRowData, filters]
  );

  const exportFile = async (exportItemData: ExportItem) => {
    try {
      const response = await getCompanyAssignmentExportData(
        props.trainingAssignmentType,
        exportItemData.data.fileType,
        filters[0],
        sortConfig
      );
      successToast({ message: response.data.message });
    } catch (error) {
      raiseErrorToast(error);
    }
  };

  const handlePageChange = (selectedPage: number) => {
    setCurrentPageNumber(selectedPage + 1);
    fetchRowData(selectedPage + 1, filters, sortConfig);
  };

  const applyFilters = useCallback(
    (newFilters: RulesComponentListViewFilter, doApiCall = false) => {
      if (!newFilters && filters?.length === 0 && !doApiCall) {
        return;
      }
      setFilters([newFilters]);
      //set filters in query params
      if (isObjectEmpty(newFilters)) {
        clearUrlParams('filters');
      } else {
        setFiltersInQueryParams(newFilters, setUrlParams);
      }
      setNumberOfFilters(getNumberOfFilters(newFilters));
      setCurrentPageNumber(1);
      fetchRowData(1, [newFilters], sortConfig);
    },
    [filters, clearUrlParams, setUrlParams]
  );

  const onResetFilters = () => {
    setFilters(initialFilters);
    setNumberOfFilters(0);
    setSortConfig(undefined);
    fetchRowData(currentPageNumber, []);
    closeFilterPanel();
  };

  const toggleFilterPanel = () => {
    setIsFilterPanelOpen((prev) => !prev);
  };
  const closeFilterPanel = () => {
    setIsFilterPanelOpen(false);
  };

  useEffect(() => {
    if (
      props.trainingAssignmentType === TrainingAssignmentTypeEnum.MyAssignments
    ) {
      setColumnData(
        getMyAssignmentsListColumnConfig(
          props.trainingAssignmentType,
          onColumnClick
        )
      );
    } else if (
      props.trainingAssignmentType ===
      TrainingAssignmentTypeEnum.MyTeamsAssignments
    ) {
      setColumnData(
        getCompanyAssignmentsListColumnConfig(
          props.trainingAssignmentType,
          onColumnClick
        )
      );
    } else if (
      props.trainingAssignmentType ===
      TrainingAssignmentTypeEnum.CompanyAssignments
    ) {
      setColumnData(
        getCompanyAssignmentsListColumnConfig(
          props.trainingAssignmentType,
          onColumnClick
        )
      );
    } else {
      setColumnData(
        getILCAssignmentsListColumnConfig(props.trainingAssignmentType)
      );
    }

    setResetColumnSizes(true);

    fetchRowData();

    const customMetadata = getCustomMetadata(props.trainingAssignmentType);
    setCustomObjectMetadata(customMetadata);
    setAllColumns(getAllColumns(customMetadata));
    if (!isTrainingMatrix) {
      configureLayout({
        pageTitle: '',
        headerTitle: getAsssignmentName(props.trainingAssignmentType),
      });
    }
  }, [fetchRowData]);

  return (
    <AssignmentsListView
      {...{
        ...props,
        columnData,
        rowData,
        isLoading,
        customObjectMetadata,
        allColumns,
        filters,
        applyFilters,
        onResetFilters,
        isFilterPanelOpen,
        toggleFilterPanel,
        closeFilterPanel,
        numberOfFilters,
        currentPageNumber,
        handlePageChange,
        totalItems,
        itemsPerRow: assignmentListItemsPerPage,
        exportFile,
        isTrainingMatrix,
        groupNames,
        setResetColumnSizes,
        resetColumnSizes,
      }}
    />
  );
};
