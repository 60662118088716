export const mimeTypes = [
  'application/pdf',
  'text/plain',
  'image/jpeg',
  'image/png',
  'video/mp4',
  'video/webm',
];

export const allowedFileTypes = [
  'application/pdf',
  'text/plain',
  'application/rtf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.ms-word.document.macroEnabled.12',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
];

export interface DocVersionHistoryViewProps {
  docsSelected: number[];
  onDocSelected?: (id: number) => void;
  docUrl: string;
  docData: DocData | undefined;
  docVersions: DocVersions[] | undefined;
  compareVersions: () => void;
  onCancelClicked: () => void;
  handleDownload: () => void;
  handleChange: (
    ev?:
      | React.ChangeEvent<HTMLElement | HTMLInputElement>
      | React.FormEvent<HTMLElement | HTMLInputElement>,
    isChecked?: boolean | 'mixed' | undefined,
    item?: DocVersions
  ) => void;
  loading: boolean;
  disableButton: () => boolean;
  handleContextMenuClick: (item: DocVersions, actionType: string) => void;
  showErrorPreview: boolean;
  isLocalPDF: boolean;
}

export interface FileBuffer {
  type: Buffer;
  data: Uint8Array;
}

export interface DocData {
  nameWithExtension: string;
  version: string;
  fileBuffer: FileBuffer;
  fileUrl: string;
  mimeType: string;
  label: string;
  certificateBuffer: FileBuffer;
}

export interface DocVersions {
  nameWithExtension: string;
  documentId: string;
  mimeType: string;
  sizeInBytes: number;
  uploadedByUserName: string;
  uploadedByEmailAddress: string;
  uploadedAtUtc: string;
  version: number;
  label: string;
  canRestorPastVersion?: boolean;
}

export interface CompareData {
  documentId: string;
  versionLeft: number;
  versionRight: number;
  objectName: string;
}

export interface DocVersionRestorePayload {
  objectName: string;
  recordName: string;
  attributeName: string;
}
