import { EditScreenProps } from '../components/wizard-container/edit-screen/edit-screen.model';
import { WizardContainerComponentProps } from '../components/wizard-container/wizard-container.model';

export type CustomPageProps = WizardContainerComponentProps &
  Partial<EditScreenProps>;

export enum RecorLevelUserContextFields {
  RECORD_USER_ACTIONS = 'recordUserActions',
  RECORD_USER_PERMISSIONS = 'recordUserPermissions',
  RECORD_WORKFLOW_ACTIONS = 'recordWorkflowActions',
  RECORD_AND_SYSTEM_ROLES = 'userRecordAndSystemRoles',
}
