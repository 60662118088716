import { DeleteIcon, LocalizationString } from '@celito.clients/assets';
import { FormEngineModeTypeEnum } from '@celito.clients/enums';
import { ConfirmDialog, GridView } from '@celito.clients/shared';
import { useDisplayViewContext } from 'libs/form-engine/src/lib/components/view-components/display-view/display-view.context';

import { ReferenceDocumentsPreviewViewProps } from './reference-documents-preview.model';
import classes from './reference-documents-preview.module.css';
import { getReferenceDocumentsPreviewGridColumns } from './utils/reference-documents-preview-grid-columns';

const ReferenceDocumentsPreviewView = (
  props: ReferenceDocumentsPreviewViewProps
): JSX.Element => {
  const { isPreviewTab } = useDisplayViewContext();
  return (
    <div className={classes.member_selector_container}>
      <ConfirmDialog
        open={props.isConfirmDialogOpen}
        title={LocalizationString.REMOVE}
        description={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_REMOVE}
        iconSrc={DeleteIcon}
        onCancelClicked={props.handleCancelClick}
        onConfirmClicked={props.handleConfirmClick}
        primaryButtonText={LocalizationString.REMOVE}
        secondaryButtonText={LocalizationString.CANCEL}
      />
      <GridView
        columns={getReferenceDocumentsPreviewGridColumns(
          props.handleActionClick,
          props.mode as FormEngineModeTypeEnum,
          isPreviewTab
        )}
        items={props.items}
        disableSelection
        className={classes.grid_view_container}
      />
    </div>
  );
};

export default ReferenceDocumentsPreviewView;
