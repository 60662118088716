import React from 'react';

import type { IPanelComponentProps } from './panel.model';
import { PanelView } from './panel.view';

const PanelController: React.FC<IPanelComponentProps> = (props) => {
  return <PanelView {...props} />;
};

export default PanelController;
