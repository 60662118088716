import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton } from '@celito.clients/shared';

import SuggestionMenu from '../../suggestion-menu/suggestion-menu.component';
import { SearchInputProps } from '../search-result.model';
import classes from '../search-result.module.css';
import { labelStyles } from '../search-result.style';

export const SearchInput: React.FC<SearchInputProps> = (props) => {
  const {
    value,
    resultCount,
    onSearchInputChange,
    onSearch,
    onClearSearch,
    enteredSearchValue,
    onTopResultCardClickHandler,
  } = props;

  return (
    <div className={classes.search_section}>
      <div className={classes.search_bar}>
        <div className={classes.search_input}>
          <label style={labelStyles}>Search by Keyword</label>
          <SuggestionMenu
            placeholder="Enter Keyword"
            searchInput={value}
            onChangeHandler={onSearchInputChange}
            onKeydownHandler={onSearch}
            isIcon={false}
            onTopResultClickHandler={onTopResultCardClickHandler}
          />
        </div>
        <div>
          <CustomButton
            buttonTitle="Search"
            onButtonClicked={() => onSearch()}
            buttonType={ButtonTypes.Primary}
            leftIcon="Search"
          />
        </div>
      </div>
      {resultCount !== -1 ? (
        <div className={classes.search_message}>
          <div className={classes.search_message_label}>
            {resultCount > 0
              ? `Showing ${resultCount} results for "${enteredSearchValue}"`
              : `No results found.`}
          </div>
          <div
            className={classes.clear_search_button}
            role="button"
            onClick={onClearSearch}
          >
            Clear Search
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};
