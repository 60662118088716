import React from 'react';

import SuggestionMenuController from './suggestion-menu.controller';
import { SuggestionMenuProps } from './suggestion-menu.model';

const SuggestionMenu: React.FC<SuggestionMenuProps> = (props) => {
  return <SuggestionMenuController {...props} />;
};

export default SuggestionMenu;
