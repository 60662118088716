import { ObjectEnum, OperatorsEnum } from '@celito.clients/enums';

export const getTaskPayload = (recordName: string) => {
  return {
    filters: [
      {
        attribute: 'object_id',
        operator: OperatorsEnum.EQUALS,
        value: ObjectEnum.TRAINING_ASSIGNMENT,
      },
      {
        attribute: 'objectRecordId',
        operator: OperatorsEnum.EQUALS,
        value: {
          objectName: ObjectEnum.TRAINING_ASSIGNMENT,
          recordName,
        },
      },
    ],
    sort: { attribute: 'created_at_utc__s', order: 'DESC' },
  };
};
