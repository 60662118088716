import { createTestAttribute } from '@celito.clients/utils';
import { mergeClasses, Textarea } from '@fluentui/react-components';
import React from 'react';

import { Field } from '../../shared';
import { TextFieldAreaModel } from './text-field-area.model';
import { textFieldAreaStyles } from './text-field-area.styles';
// The view component for the TextField, responsible for rendering the UI
export const TextFieldAreaView: React.FC<TextFieldAreaModel> = (props) => {
  const {
    labelText,
    required,
    className,
    size = 'large',
    errorMessage,
    disabled,
    value,
    inputRef,
    helperLabelText,
    ...rest
  } = props;

  const styles = textFieldAreaStyles();

  return (
    <div
      className={className}
      data-testid={`label-${createTestAttribute(props.label)}`}
    >
      <Field
        label={props.label}
        helperTextLabel={helperLabelText}
        required={required}
        className={styles.field}
        validationMessage={errorMessage}
        validationState={errorMessage ? 'error' : 'none'}
        size={size}
      >
        <Textarea
          data-testid={`input-field-${createTestAttribute(props.label)}`}
          resize={props.resizable ? 'vertical' : 'none'}
          className={mergeClasses(
            styles.textFieldArea,
            errorMessage && styles.textFieldAreaError
          )}
          disabled={disabled}
          ref={inputRef}
          value={value}
          {...rest}
        />
      </Field>
    </div>
  );
};
