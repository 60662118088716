import React from 'react';

import { TextFieldController } from './text-field.controller';
import { TextFieldModel } from './text-field.model';

const TextField: React.FC<TextFieldModel> = (props) => {
  return <TextFieldController {...props} />;
};

export * from './text-field.styles';

export default TextField;
