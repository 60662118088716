import { FieldValues } from 'react-hook-form/dist/types';

import { ControlledTextFieldController } from './controlled-textfield.controller';
import { ControlledTextFieldProps } from './controlled-textfield.model';

const ControlledTextField = <T extends FieldValues>(
  props: ControlledTextFieldProps<T>
) => {
  return <ControlledTextFieldController {...props} />;
};

export default ControlledTextField;
