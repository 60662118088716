import { DateType } from '@celito.clients/types';
import {
  addDays,
  differenceInCalendarDays,
  differenceInHours,
  differenceInMinutes,
  isValid,
  Locale,
  parseISO,
  setDefaultOptions,
  startOfHour,
} from 'date-fns';
import {
  format as formatWithTimezone,
  formatInTimeZone,
  getTimezoneOffset,
  toZonedTime,
} from 'date-fns-tz';

import {
  DateFormatEnumType,
  SystemTimezone,
  TimeOfDayPrefenceEnum,
} from '../enums/date-formats';
const dateFnsLocaleModules = import.meta.glob(
  '../../../../node_modules/date-fns/locale/*.d.ts'
);

const userLocaleCode =
  (navigator.languages &&
    navigator.languages.length &&
    navigator.languages[0]) ||
  navigator.language;

let dateFnsLocale: Locale | undefined;

(() => {
  const activeLocaleModule: (() => Promise<unknown>) | undefined =
    dateFnsLocaleModules[
      `../../../../node_modules/date-fns/locale/${userLocaleCode}.d.ts`
    ];

  if (typeof activeLocaleModule !== 'function') return;

  activeLocaleModule().then((mod) => {
    // Check if mod is module with default property available
    if (!(mod && typeof mod === 'object' && 'default' in mod)) return;

    dateFnsLocale = mod.default as Locale;

    setDefaultOptions({
      locale: dateFnsLocale,
    });
  });
})();

export const getUserTimezone = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

export const formatDate = (
  date: DateType,
  format: DateFormatEnumType,
  timeZone?: string
) => {
  const modifiedDate = toZonedTime(date, SystemTimezone);

  return formatWithTimezone(modifiedDate, format, {
    timeZone: timeZone ?? SystemTimezone,
    locale: dateFnsLocale,
  });
};

export const formatDateInTimezone = (
  date?: Omit<DateType, number>,
  formatType?: string
) => {
  if (!date || (!(date instanceof Date) && typeof date !== 'string')) return '';
  if (formatType) {
    return formatInTimeZone(date, SystemTimezone, formatType ?? '');
  }
  if (date instanceof Date) {
    return date?.toISOString();
  }
  return date;
};

export const getZonedBasedTime = (date: DateType) => {
  const zonedTime = toZonedTime(date, SystemTimezone);
  return zonedTime;
};

export const getDateTime = (date: DateType) => {
  const modifiedDate = getZonedBasedTime(date);
  const dateTime =
    modifiedDate.getHours() +
    ':' +
    modifiedDate.getMinutes() +
    ':' +
    modifiedDate.getSeconds();
  return dateTime;
};
export const getConvertedDate = (
  date: Date,
  dayPref?: TimeOfDayPrefenceEnum,
  givenTime?: number[]
) => {
  return getStartOfDateInTimezone(
    date.getDate(),
    date.getMonth(),
    date.getFullYear(),
    SystemTimezone,
    dayPref,
    givenTime
  );
};
export const getStartOfDateInTimezone = (
  date: number,
  month: number,
  year: number,
  timezone: string,
  dayPref?: TimeOfDayPrefenceEnum,
  givenTime?: number[]
) => {
  let time;
  if (givenTime?.length) {
    time = givenTime;
  } else if (dayPref === TimeOfDayPrefenceEnum.CURRENT) {
    const currentTime = getZonedBasedTime(new Date());
    time = [
      currentTime?.getHours(),
      currentTime?.getMinutes(),
      currentTime?.getSeconds(),
      currentTime?.getMilliseconds(),
    ];
  } else {
    time =
      dayPref === TimeOfDayPrefenceEnum.END ? [23, 59, 59, 999] : [0, 0, 0, 0];
  }

  const utcDate = new Date(Date.UTC(year, month, date, ...time));
  // Get the timezone offset from UTC in minutes
  // passing date in the timezoneoffset, so that as per the date we will get the timezone offset
  const offsetMinutes = getTimezoneOffset(
    timezone,
    new Date(year, month, date)
  );

  // Create a new Date object by applying the timezone offset
  const localDate = new Date(utcDate.getTime() - offsetMinutes);
  return localDate;
};
export const isDateValid = (date: string | Date) => {
  if (typeof date === 'boolean') {
    return false;
  }

  const invalidDateRegexes = [
    /^\w+-\d+$/,
    /^\w+(-\w+){1,10}$/,
    /^\w+( .+)*$/,
    /^\w+([-\s]\w+){1,10}$/,
  ];

  if (
    typeof date === 'string' &&
    invalidDateRegexes.some((regex) => regex.test(date))
  ) {
    return false;
  }

  if (typeof date === 'object' || date === null || date === undefined) {
    return isValid(date);
  }

  if (typeof date === 'string') {
    const jsDate = new Date(date);

    if (jsDate instanceof Date && !isNaN(+jsDate)) {
      return true;
    }

    const parsedIso = parseISO(date);

    return isValid(parsedIso);
  }

  const jsDate = new Date(date);

  if (jsDate instanceof Date && !isNaN(+jsDate)) {
    return true;
  }

  return isValid(date);
};

export const addDaysToDate = (date: Date, days: number) => addDays(date, days);

export const getDateDifferenceInDays = (date1: Date, date2: Date) =>
  differenceInCalendarDays(date1, date2);

export const parseISODate = (date: string) => parseISO(date);

export const getStartOfHour = (date: Date) => startOfHour(date);

export const getDateDifferenceInHours = (date1: Date, date2: Date) =>
  differenceInHours(date1, date2);

export const getTimezoneAbbreviation = (date: Date, timezone: string) => {
  const abbreviation = formatInTimeZone(date, timezone, 'zzz', {
    locale: dateFnsLocale,
  });
  const mapping = timeZoneMapping.find((entry) =>
    // eslint-disable-next-line no-prototype-builtins
    entry.hasOwnProperty(timezone)
  );
  return mapping ? mapping[timezone] : abbreviation;
};

export const getAbbreviationForSysTimeZone = () => {
  return getTimezoneAbbreviation(new Date(), SystemTimezone);
};

export const getTimeDifference = () => {
  const userTimeZone = getUserTimezone();
  const now = new Date();

  const systemTime = toZonedTime(now, SystemTimezone);
  const userTime = toZonedTime(now, userTimeZone);

  const timeDifferenceMinutes = differenceInMinutes(userTime, systemTime);
  const timeDifferenceHours = (timeDifferenceMinutes / 60).toFixed(1);
  if (timeDifferenceMinutes === 0) {
    return '(same timezone as you)';
  }

  const aheadOrBehind = timeDifferenceMinutes >= 0 ? 'ahead' : 'behind';
  const absDifferenceHours = Math.abs(Number(timeDifferenceHours));

  return `(You are ${absDifferenceHours} hours ${aheadOrBehind})`;
};

export const timeZoneMapping: { [key: string]: string }[] = [
  { UTC: 'UTC' },
  { 'America/New_York': 'EDT' },
  { 'America/Chicago': 'CDT' },
  { 'America/Denver': 'MDT' },
  { 'America/Los_Angeles': 'PDT' },
  { 'America/Phoenix': 'MST' },
  { 'America/Anchorage': 'AKDT' },
  { 'Pacific/Honolulu': 'HST' },
  { 'America/Sao_Paulo': 'BRT' },
  { 'America/Buenos_Aires': 'ART' },
  { 'America/Mexico_City': 'CDT' },
  { 'America/Toronto': 'EDT' },
  { 'America/Vancouver': 'PDT' },
  { 'Europe/London': 'BST' },
  { 'Europe/Paris': 'CEST' },
  { 'Europe/Berlin': 'CEST' },
  { 'Europe/Rome': 'CEST' },
  { 'Europe/Madrid': 'CEST' },
  { 'Europe/Istanbul': 'EEST' },
  { 'Europe/Moscow': 'MSK' },
  { 'Asia/Kolkata': 'IST' },
  { 'Asia/Tokyo': 'JST' },
  { 'Asia/Shanghai': 'CST' },
  { 'Asia/Hong_Kong': 'HKT' },
  { 'Australia/Sydney': 'AEST' },
  { 'Australia/Melbourne': 'AEST' },
  { 'Pacific/Auckland': 'NZST' },
];

export const getDaysDifference = (dateString: string): number => {
  const dueDate = parseISO(dateString);
  const now = new Date();

  const zonedDueDate = toZonedTime(dueDate, SystemTimezone);
  const zonedNow = toZonedTime(now, SystemTimezone);

  const diffInDays = differenceInCalendarDays(zonedDueDate, zonedNow);
  const diffInHours = differenceInHours(zonedDueDate, zonedNow);

  if (diffInDays === 0 && diffInHours > 0) {
    return 0;
  } else if (diffInDays === 0 && diffInHours < 0) {
    return -1;
  } else {
    return diffInDays;
  }
};

export const getCurrentSystemTimeInISOString = () => {
  return formatDateInTimezone(getZonedBasedTime(Date.now()));
};
