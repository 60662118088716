import { LocalizationString, WarningIcon } from '@celito.clients/assets';
import { ButtonTypes, FormEngineModeTypeEnum } from '@celito.clients/enums';
import { useURLParams } from '@celito.clients/hooks';
import {
  CustomButton,
  FormActionButtonsContainer,
  PreviewPanel,
  Stack,
} from '@celito.clients/shared';
import { Field, LayoutRulesDataSchema } from '@celito.clients/types';
import { createTestAttribute } from '@celito.clients/utils';
import { FieldValues, useFormContext } from 'react-hook-form';

import { getFieldSection } from '../../components/helper-components';
import FormContainer from '../../components/helper-components/form-container/form-container';
import { ConfirmButton } from '../../components/wizard-container/confirm-button/confirm-button.component';
import { groupFieldsByRow } from '../../utils/helper';
import { getLayoutRules } from '../../utils/layout-rules';
import { ControlledDocumentPreviewPageProps } from './controlled-document-preview-page.model';
import classes from './controlled-document-preview-page.module.css';
import { getSectionData } from './utils';

interface ControlledDocumentPreviewPageViewProps
  extends ControlledDocumentPreviewPageProps {}

export const ControlledDocumentPreviewPageView = ({
  dataTestId,
  onCancel,
  step,
  totalSteps,
  goToPreviousStep,
  goToNextStep,
  onSave,
  isSubmitting,
  viewDto,
  isLoading,
  methods,
  wizardStepsState,
  watch,
  isWizardStateVisible,
  ...props
}: ControlledDocumentPreviewPageViewProps): JSX.Element => {
  const { urlParams } = useURLParams();
  const {
    formState: { isDirty },
  } = useFormContext();

  const previewableSections = props.sections!.filter(
    (section) => section.name !== 'preview_controlled_document_section__a'
  );

  const isSubmitDisabled =
    isLoading ||
    isSubmitting ||
    wizardStepsState.some((step) => step.isValid === false);
  let formData = watch?.();
  if (props.mode === FormEngineModeTypeEnum.View) {
    formData = props.recordDetails;
  }

  const showSubmitConfirmationPopup = Boolean(
    viewDto?.view?.[0]?.showSubmitConfirmationPopup
  );

  const submitConfirmationPopupMessage =
    viewDto?.view?.[0]?.submitConfirmationPopupMessage;

  const Row = ({
    fields,
    fieldsState,
  }: {
    fields: Field[];
    fieldsState: Record<string, LayoutRulesDataSchema>;
  }) => {
    if (fields.every((field) => !fieldsState[field.columnName]?.isVisible)) {
      return null;
    }

    return (
      <Stack className={classes.row}>
        {fields.map((field, index) => {
          if (!fieldsState[field.columnName]?.isVisible) {
            return null;
          }
          return getFieldSection(
            field.columnName,
            field.width,
            props.recordDetails!,
            props.attributeConfig,
            props.objectName!,
            index,
            true
          );
        })}
      </Stack>
    );
  };

  return (
    <div
      data-testid="div-training-plan-preview-page_container"
      className={classes.container}
    >
      <FormContainer>
        {previewableSections.map((section, stepNumber) => {
          const sectionData = getSectionData(
            props.sections,
            stepNumber,
            props.attributeConfig,
            props.recordDetails
          );

          const fieldsState = getLayoutRules(
            formData as FieldValues,
            sectionData
          );

          const groupedFieldsByRow = groupFieldsByRow(sectionData.fields || []);

          return (
            <div className={classes.previewContainer} key={section.label}>
              <PreviewPanel
                title={section.label}
                onEditPress={() => {
                  props.onStepClick(stepNumber);
                }}
              >
                <Stack
                  data-testid={`preview-${createTestAttribute(
                    section.label
                  )}-section`}
                  className={classes.rowContainer}
                >
                  {Object.entries(groupedFieldsByRow).map(([row, fields]) => (
                    <Row key={row} fields={fields} fieldsState={fieldsState} />
                  ))}
                </Stack>
              </PreviewPanel>
            </div>
          );
        })}
      </FormContainer>

      <FormActionButtonsContainer className={classes.formActionBtnsContainer}>
        <CustomButton
          buttonTitle="Cancel"
          buttonType={ButtonTypes.Ghost}
          onClick={onCancel}
          disabled={isLoading || isSubmitting}
        />

        <div className={classes.formActionBtns}>
          {step > 0 && (
            <CustomButton
              buttonTitle="Previous"
              leftIcon="Previous"
              buttonType={ButtonTypes.Ghost}
              onClick={goToPreviousStep}
              disabled={isLoading || isSubmitting}
            />
          )}
          {
            <CustomButton
              buttonTitle={'Save'}
              leftIcon="Save"
              buttonType={ButtonTypes.Secondary}
              isLoading={isLoading}
              disabled={isLoading || isSubmitting || !isDirty}
              onSubmit={
                onSave
                  ? () =>
                      onSave(
                        false,
                        {
                          skipVersionUpgrade: false,
                        },
                        {
                          // status__s: ControlledDocumentStatusEnum.Draft,
                          ...(urlParams.get('requestType')
                            ? {
                                initiation_type__a:
                                  urlParams.get('requestType'),
                              }
                            : {}),
                        }
                      )
                  : undefined
              }
            />
          }
          <ConfirmButton
            buttonTitle={LocalizationString.SUBMIT}
            buttonType={ButtonTypes.Primary}
            showSubmitConfirmationPopup={showSubmitConfirmationPopup}
            dialogProps={{
              primaryButtonText: LocalizationString.SUBMIT,
              title: LocalizationString.SUBMIT,
              description: submitConfirmationPopupMessage,
              secondaryButtonText: LocalizationString.CANCEL,
              iconSrc: WarningIcon,
            }}
            leftIcon={'Submit'}
            disabled={isSubmitDisabled}
            isLoading={isSubmitting}
            onSubmit={
              onSave
                ? () =>
                    onSave(
                      true,
                      {
                        skipVersionUpgrade: false,
                      },
                      {
                        // status__s: ControlledDocumentStatusEnum.Draft,
                        ...(urlParams.get('requestType')
                          ? {
                              initiation_type__a: urlParams.get('requestType'),
                              is_active__s: true,
                            }
                          : {}),
                      }
                    )
                : undefined
            }
          />
        </div>
      </FormActionButtonsContainer>
    </div>
  );
};
