import { makeStyles } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const radioButtonStyles = makeStyles({
  customLabel: {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '22px',
    letterSpacing: '0px',
    color: Color.black,
    textAlign: 'left',
    opacity: 1,
  },

  customRadioGroup: {
    opacity: 1,
  },
});
