import { createTestAttribute } from '@celito.clients/utils';
import { Checkbox as CheckboxComp } from '@fluentui/react-components';

import { CheckboxComponentProps } from './checkbox.model';

const CheckboxView: React.FC<CheckboxComponentProps> = ({
  id,
  label,
  onChanged,
  name,
  checked,
  disabled = false,
  className,
  componentStyles,
  required = false,
  dataTestId,
  defaultChecked = false,
  ...rest
}) => {
  return (
    <CheckboxComp
      {...{
        id: id,
        label,
        onChange: onChanged,
        name,
        checked,
        disabled,
        className,
        required,
        defaultChecked,
      }}
      {...rest}
      data-testid={`checkbox-${label ? createTestAttribute(label) : 'correct'}`}
    />
  );
};
export default CheckboxView;
