import { Button } from '@fluentui/react-components';
import { Cell, flexRender } from '@tanstack/react-table';
import cn from 'classnames';
import { ConditionalTooltip, Icon } from 'libs/shared/src/lib/shared';
import { MouseEventHandler, useRef } from 'react';
import { useNavigate } from 'react-router';

import { ColumnMeta } from '../../grid-view.model';
import { RowItem } from '../../types';
import getRenderValue from './helpers/getRenderValue';
import isFieldEmpty from './helpers/isFieldEmpty';
import classes from './table-cell.styles.module.css';

interface TableCellProps {
  dataTestId?: string;
  cell: Cell<RowItem, unknown>;
  showPlaceholderIfEmpty: boolean;
}

const TableCell = ({ cell, showPlaceholderIfEmpty }: TableCellProps) => {
  const navigate = useNavigate();

  const tdRef = useRef<HTMLTableCellElement | null>(null);
  const dataContainerRef = useRef<HTMLDivElement | null>(null);
  const textContainerRef = useRef<HTMLDivElement | null>(null);

  const { isFixed = false, showTooltip = false } = (cell.column.columnDef
    .meta || {}) as ColumnMeta;

  const hasSubRow =
    Array.isArray(cell.row.original.subRow) &&
    cell.row.original.subRow.length > 0 &&
    cell.column.id === cell.row.original.subRowToggleFieldName;

  const renderVal = getRenderValue(cell);
  const isAccessorColumn = 'accessorKey' in cell.column.columnDef;
  const showPlaceholder =
    showPlaceholderIfEmpty && isAccessorColumn && isFieldEmpty(renderVal);

  const onClickHandler: MouseEventHandler<HTMLTableCellElement> = (e) => {
    const { onClickNavigateRoute } = cell.row.original;

    // Only allow onclick handler to work on top level elements
    // not inner elements as they might contain modals, popup,etc.
    // which may have buttons of their own, which will trigger this
    // onclick handler also (if stopPropagation is not done)
    const isTopLevelElement =
      e.target === tdRef.current ||
      e.target === dataContainerRef.current ||
      e.target === textContainerRef.current;

    if (isTopLevelElement && onClickNavigateRoute) {
      navigate(onClickNavigateRoute);
    }
  };

  return (
    <td
      ref={tdRef}
      data-testid="grid-body-cell"
      className={cn(classes.tableCell, {
        [classes.isFixed]: isFixed,
      })}
      style={{
        width: cell.column.getSize(),
      }}
      onClick={onClickHandler}
    >
      <ConditionalTooltip
        containerRef={tdRef}
        dataWrapperRef={dataContainerRef}
        textWrapperRef={textContainerRef}
        textWrapperTestId="grid-body-cell-value"
        tooltipContent={renderVal}
        extraConditions={showTooltip}
      >
        {hasSubRow && (
          <Button
            appearance="transparent"
            onClick={cell.row.getToggleExpandedHandler()}
            icon={
              <Icon
                iconName="CaretRight24Filled"
                className={cn(classes.subRowIcon, {
                  [classes.rotate]: cell.row.getIsExpanded(),
                })}
              />
            }
          />
        )}
        {flexRender(cell.column.columnDef.cell, cell.getContext())}
        {showPlaceholder && '-'}
      </ConditionalTooltip>
    </td>
  );
};

export default TableCell;
