import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';

export const noInvitedStyles = makeStyles({
  NotFoundImageComponenet: {
    objectFit: 'contain',
    padding: '1rem',
    width: '100%',
  },

  logoImage: {
    ...shorthands.margin('2.5rem', 0),
    zIndex: 1000,
    width: '180px',
  },

  headerContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Color.background_body_bg,
    boxSizing: 'border-box',
    ...shorthands.overflow('hidden'),
  },

  extraOverFlow: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '16rem',
    backgroundImage: `linear-gradient(92deg,${Color.dark_blue_gradiant} -2.93%,${Color.light_bluen_gradiant} 103.99%)`,
    content: '',
    zIndex: 999,
  },

  contentContainer: {
    position: 'relative',
    zIndex: 1000,
    ...shorthands.borderRadius('4px'),
    height: 'calc(100vh - 10.2rem)',
    ...shorthands.padding('1rem'),
    boxSizing: 'border-box',
    width: '97%',
    backgroundColor: Color.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'auto',
  },

  title: {
    marginTop: '3rem',
    color: 'var(--background-primary_blue_dark)',
    fontWeight: 600,
    fontSize: '2rem',
    fontFamily: 'Metropolis',
    textAlign: 'center',
    lineHeight: 'normal',
  },

  message: {
    ...shorthands.margin('-20rem', 0, '1.5rem', 0),
    fontSize: '1.1rem',
    width: '33rem',
    textAlign: 'center',
    fontWeight: 400,
    fontFamily: 'Metropolis',
    letterSpacing: '0.2px',
    lineHeight: '1.5rem',
    color: 'var(--background-primary_blue_dark)',
    marginTop: '2rem',
  },
  mt2: {
    marginTop: '2rem',
  },

  imageContainer: {
    height: 'auto',
    display: 'flex',
    justifyContent: 'center',
    width: '70vw',
  },
});
