import { LocalizationString } from '@celito.clients/assets';
import { createTestAttribute } from '@celito.clients/utils';

import { DatePicker } from '../../shared';
import { DateRangePickerProps } from './date-range-picker.model';
import classes from './date-range-picker.styles.module.css';

interface DateRangePickerViewProps extends DateRangePickerProps {
  handleRangeValChange: (value: DateRangePickerProps['value']) => void;
}

const DateRangePickerView = ({
  dataTestId,
  value,
  maxDate1,
  maxDate2,
  minDate1,
  minDate2,
  handleRangeValChange,
  required,
  error,
}: DateRangePickerViewProps): JSX.Element => {
  return (
    <div className={classes.dateRangeContainer} data-testid={dataTestId}>
      <DatePicker
        label={LocalizationString.FROM}
        data-testid={`date-picker-${createTestAttribute(
          LocalizationString.FROM
        )}`}
        value={value.start}
        onDateChange={(date) => handleRangeValChange({ ...value, start: date })}
        minDate={new Date(1900, 0, 1) || minDate1}
        maxDate={value.end || maxDate1}
        required={required}
        error={error}
      />
      <DatePicker
        data-testid={`date-picker-${createTestAttribute(
          LocalizationString.TO
        )}`}
        label={LocalizationString.TO}
        value={value.end}
        onDateChange={(date) => handleRangeValChange({ ...value, end: date })}
        minDate={value.start || minDate2}
        maxDate={maxDate2}
        required={required}
        error={error}
      />
    </div>
  );
};

export default DateRangePickerView;
