import {
  LocalizationString,
  SaveIcon,
  WarningIcon,
} from '@celito.clients/assets';
import { ButtonTypes } from '@celito.clients/enums';
import { useURLParams } from '@celito.clients/hooks';
import {
  ConfirmDialog,
  CustomButton,
  FormActionButtonsContainer,
  Loader,
  Stack,
} from '@celito.clients/shared';
import { Field, Section } from '@celito.clients/types';
import { useCallback, useEffect, useState } from 'react';
import { FormProvider, useFormContext } from 'react-hook-form';

import { useFormEngineContext } from '../../../hooks';
import { getFieldContainer } from '../../../utils/attribute-config';
import {
  getFormActionsVisibilityRules,
  groupFieldsByRow,
} from '../../../utils/helper';
import FormContainer from '../../helper-components/form-container/form-container';
import { ConfirmButton } from '../confirm-button/confirm-button.component';
import { getPageComponent } from '../page-component/page-component';
import { SectionView } from '../section/section';
import { EditScreenProps } from './edit-screen.model';
import classes from './edit-screen.module.css';
import { quizHasRelatedCourse } from './utils';

interface EditScreenViewProps extends EditScreenProps {
  isDataSaved?: boolean;
  showSubmitBtn?: boolean;
  isRecordDataLoading: boolean;
  showPrompt: boolean;
  confirmNavigation: () => void;
  cancelNavigation: () => void;
}

const EditScreenView: React.FC<EditScreenViewProps> = (props) => {
  const {
    sections = [],
    attributeConfig,
    step,
    onCancel,
    onSave,
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    onFormSubmit,
    isSaveModalOpen,
    isWarningModalOpen,
    isSubmitModalOpen,
    toggleSubmitModalBtnClick,
    toggleWarningModalBtnClick,
    toggleSaveModalBtnClick,
    dataTestIdForSaveModal,
    dataTestIdForWarningModal,
    mode,
    totalSteps,
    goToNextStep,
    goToPreviousStep,
    goToEditScreen,
    objectName = '',
    methods,
    isCancelModalOpen,
    toggleCancelModalBtnClick,
    recordDetails,
    viewDto,
    version,
    isLoading,
    isSubmitting,
    isRecordDataLoading,
    isDataSaved,
    showPrompt,
    confirmNavigation,
    cancelNavigation,
    isWizardStateVisible,
    ...rest
  } = props;
  const { urlParams } = useURLParams();
  const requestType = urlParams.get('requestType');
  const isUpdateRequest = requestType?.includes('Update');

  const submitConfirmationPopupMessage =
    viewDto?.view?.[0]?.submitConfirmationPopupMessage;

  const [submitDialogProps, setSubmitDialogProps] = useState({
    primaryButtonText: LocalizationString.SUBMIT,
    title: LocalizationString.SUBMIT,
    description: submitConfirmationPopupMessage,
    secondaryButtonText: LocalizationString.CANCEL,
  });

  const isSubmitDisabled =
    isLoading ||
    isSubmitting ||
    props.wizardStepsState.some((step) => step.isValid === false);

  const {
    unregister,
    formState: { isDirty },
  } = useFormContext();

  const { fieldsState } = useFormEngineContext();

  const fieldsStateStringified = JSON.stringify(fieldsState);

  // Unregister fields when they become hidden
  useEffect(() => {
    const fieldsStateParsed = JSON.parse(fieldsStateStringified);
    (sections[step]?.fields || []).forEach((field: Field) => {
      if (
        !fieldsStateParsed[field.columnName]?.isVisible &&
        field.columnName !== 'space'
      ) {
        unregister(field.columnName);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue(field.columnName, null);
      }
    });
  }, [fieldsStateStringified, unregister, props.watch]);

  const showSubmitConfirmationPopup = Boolean(
    viewDto?.view?.[0]?.showSubmitConfirmationPopup
  );

  const groupedFieldsByRow = groupFieldsByRow(sections[step]?.fields || []);

  const Row = useCallback(
    ({ fields }: { fields: Field[] }) => {
      if (fields.every((field) => !fieldsState[field.columnName]?.isVisible)) {
        return null;
      }

      return (
        <Stack className={classes.row}>
          {fields.map((field) => {
            if (!fieldsState[field.columnName]?.isVisible) {
              return null;
            }

            return (
              <SectionView width={field.width} key={field.columnName}>
                {getFieldContainer(
                  objectName,
                  attributeConfig,
                  field.columnName,
                  control,
                  watch,
                  setValue,
                  trigger,
                  mode,
                  recordDetails,
                  undefined,
                  { ...field, ...fieldsState[field.columnName] }
                )}
              </SectionView>
            );
          })}
        </Stack>
      );
    },
    [fieldsState, recordDetails]
  );

  useEffect(() => {
    quizHasRelatedCourse(props?.recordData?.name as string, objectName).then(
      (hasRelatedCourse) => {
        if (hasRelatedCourse)
          setSubmitDialogProps({
            description:
              'Are you sure that you want to submit, it will affect the active courses using this quiz?',
            primaryButtonText: 'Ok',
            secondaryButtonText: LocalizationString.CANCEL,
            title: 'Has Related Course',
          });
      }
    );
  }, [objectName, props?.recordData?.name]);

  const sectionTypeLayout = (
    sections: Section[],
    onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ) => {
    if (sections[step]?.pageComponent) {
      return getPageComponent(sections[step].pageComponent, onCancel, props);
    }

    if (sections[step]?.fields.length > 0) {
      const isEditAction = !isUpdateRequest;

      const { showSaveButton, showSubmitButton } =
        getFormActionsVisibilityRules(
          attributeConfig?.lifecycleStageGroups,
          recordDetails?.lifecycleStage as string,
          isEditAction ? 'edit' : 'update'
        );
      return (
        <>
          <FormContainer className={classes.rowContainer}>
            {Object.entries(groupedFieldsByRow).map(([row, fields]) => (
              <Row key={row} fields={fields} />
            ))}
          </FormContainer>

          <FormActionButtonsContainer>
            <CustomButton
              buttonTitle="Cancel"
              buttonType={ButtonTypes.Ghost}
              typeof="button"
              onClick={onCancel}
              disabled={isLoading || isSubmitting}
            />
            <div className={classes.formActionBtns}>
              {step > 0 && (
                <CustomButton
                  buttonTitle="Previous"
                  leftIcon="Previous"
                  buttonType={ButtonTypes.Ghost}
                  onClick={goToPreviousStep}
                  disabled={isLoading || isSubmitting}
                />
              )}
              {step < totalSteps - 1 && (
                <CustomButton
                  buttonTitle="Next"
                  leftIcon="Next"
                  buttonType={ButtonTypes.Ghost}
                  onClick={goToNextStep}
                  disabled={isLoading || isSubmitting}
                />
              )}
              {showSaveButton && (
                <CustomButton
                  buttonTitle={'Save'}
                  leftIcon="Save"
                  buttonType={ButtonTypes.Secondary}
                  isLoading={isLoading}
                  disabled={isLoading || isSubmitting || !isDirty}
                  onSubmit={!isLoading ? () => onSave(false) : undefined}
                />
              )}
              {showSubmitButton && step === totalSteps - 1 && (
                <ConfirmButton
                  buttonTitle={'Submit'}
                  leftIcon="Submit"
                  buttonType={ButtonTypes.Primary}
                  isLoading={isSubmitting}
                  disabled={isSubmitDisabled}
                  onSubmit={!isSubmitting ? () => onSave(true) : undefined}
                  showSubmitConfirmationPopup={showSubmitConfirmationPopup}
                  dialogProps={{
                    primaryButtonText: submitDialogProps.primaryButtonText,
                    title: submitDialogProps.title,
                    description: submitDialogProps.description,
                    secondaryButtonText: submitDialogProps.secondaryButtonText,
                    iconSrc: WarningIcon,
                  }}
                />
              )}
            </div>
          </FormActionButtonsContainer>
        </>
      );
    }
  };

  return (
    <form
      onSubmit={onFormSubmit}
      noValidate
      encType="multipart/form-data"
      className={classes.form}
    >
      {isRecordDataLoading ? (
        <Stack className={classes.ht}>
          <Loader fullPage />
        </Stack>
      ) : (
        <Stack className={classes.formContainer} {...rest}>
          <FormProvider {...methods}>
            {sectionTypeLayout(sections, onCancel)}
          </FormProvider>
        </Stack>
      )}

      <ConfirmDialog
        dataTestId={dataTestIdForWarningModal}
        open={isCancelModalOpen}
        onCancelClicked={() => toggleCancelModalBtnClick()}
        onConfirmClicked={() => toggleCancelModalBtnClick(true)}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.ARE_YOU_SURE_YOU_WANT_TO_CANCEL}
        iconSrc={WarningIcon}
        description={LocalizationString.CANCEL_POPUP_MSG}
      />
      <ConfirmDialog
        dataTestId={dataTestIdForWarningModal}
        open={isWarningModalOpen}
        onCancelClicked={toggleWarningModalBtnClick}
        onConfirmClicked={toggleWarningModalBtnClick}
        primaryButtonText={LocalizationString.GOT_IT}
        title={LocalizationString.ERROR}
        iconSrc={WarningIcon}
        description={LocalizationString.VALIDATION_DESCRIPTION}
      />
      <ConfirmDialog
        dataTestId={dataTestIdForSaveModal}
        open={isSaveModalOpen}
        onCancelClicked={toggleSaveModalBtnClick}
        onConfirmClicked={toggleSaveModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.SAVE_REQUEST}
        iconSrc={SaveIcon}
        description={LocalizationString.SAVED_DESCRIPTION}
        dontShowCloseIcon
      />
      <ConfirmDialog
        dataTestId={dataTestIdForSaveModal}
        open={isSubmitModalOpen}
        onCancelClicked={toggleSubmitModalBtnClick}
        onConfirmClicked={toggleSubmitModalBtnClick}
        primaryButtonText={LocalizationString.GREAT}
        title={LocalizationString.SUBMIT_REQUEST}
        iconSrc={SaveIcon}
        description={LocalizationString.SUBMIT_DESCRIPTION}
        dontShowCloseIcon
      />
      <ConfirmDialog
        dataTestId={'dirty-form-modal'}
        open={!!showPrompt}
        onConfirmClicked={confirmNavigation}
        onCancelClicked={cancelNavigation}
        primaryButtonText={LocalizationString.YES}
        secondaryButtonText={LocalizationString.NO}
        title={LocalizationString.UNSAVED_TITLE}
        iconSrc={WarningIcon}
        description={LocalizationString.UNSAVED_MESSAGE}
      />
    </form>
  );
};

export default EditScreenView;
