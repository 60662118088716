import { makeStyles, shorthands } from '@fluentui/react-components';
import { Color } from 'libs/theme/src/lib/colors';
import { ThemeFontFamily } from 'libs/theme/src/lib/theme-fontfamily';

export const QuizQuestionPageStyles = makeStyles({
  root: {
    ...shorthands.padding('1.5rem'),
    display: 'flex',
    flexDirection: 'column',
    height: 'inherit',
    justifyContent: 'space-between',
  },

  questionContainer: {
    ...shorthands.border('1px', 'solid', '#eee'),
    ...shorthands.borderRadius('8px'),
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
    height: 'calc(100vh - 24rem)  ',
    overflowY: 'auto',
  },

  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
    ...shorthands.padding('1rem'),
    height: '100%',
  },

  questionActions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center',
  },

  questionNumberField: {
    width: '3.5rem',
    minHeight: '20px',
    height: '32px',
    ...shorthands.margin(0),
    boxShadow: 'var(--shadow2)',
    borderBottomColor: 'var(--colorTransparentStroke)',
    borderLeftColor: 'var(--colorTransparentStroke)',
    borderRightColor: 'var(--colorTransparentStroke)',
    borderTopColor: 'var(--colorTransparentStroke)',

    '> input': {
      textAlign: 'center',
    },
  },

  questionNumberText: {
    display: 'flex',
    flexWrap: 'wrap',
    ...shorthands.gap('8px'),
    alignItems: 'center',

    '> div > label': {
      fontFamily: ThemeFontFamily.bold,
      fontSize: '1rem',
    },
  },

  questionPageControl: {
    display: 'flex',
    ...shorthands.gap('8px'),
    alignItems: 'center',
  },

  totalQuestions: {
    minWidth: '1.8rem',
    fontFamily: ThemeFontFamily.sans,
    fontSize: '1rem',
  },

  settings: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    height: 'auto',
    justifyContent: 'space-between',

    ...shorthands.gap('1rem'),

    '> div:nth-child(1)': {
      width: '30rem',
      minWidth: '20rem',
    },
  },

  endSettings: {
    '> div > label': {
      fontSize: '1rem',
    },
  },

  questionContent: {
    display: 'flex',
    flexDirection: 'column',
    ...shorthands.gap('1rem'),
  },

  row: {
    display: 'flex',
    width: '100%',
    alignItems: 'self-start',

    ...shorthands.gap('1rem'),

    '> *': {
      ...shorthands.flex(1),
    },
  },

  btnActionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },

  btnInnerContainer: {
    minHeight: '40px',
  },

  choices: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },

  deleteIcon: {
    color: Color.DELETE_ICON_COLOR,
  },

  footerBtnContainer: { display: 'flex', ...shorthands.gap('8px') },

  questionRequiredError: {
    fontSize: '12px',
    color: 'var(--colorPaletteRedForeground1)',
  },

  titleContainer: {
    display: 'flex',
    flexDirection: 'column',
  },

  helper: {
    '> div': { width: '18rem' },
  },
});
