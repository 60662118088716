import { ButtonTypes } from '@celito.clients/enums';
import { CustomButton, InHouseInputSelect } from '@celito.clients/shared';
import LocalStrings from 'apps/web-client/src/assets/localisation';
import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import React from 'react';

import { AddUserProps } from './add-user.model';
import { addUserStyles } from './add-user.styles';
import { PreUserAdd } from './components/pre-user-add.component';

const AddUserView: React.FC<AddUserProps> = ({
  isLoading,
  error,
  onChange,
  onAddBtnHandler,
  options,
  selectedId,
  isPreAddUserScreenOpen,
  togglePreAddUserScreen,
  departmentIsSynced,
  isAdminSettingLoading,
  managerIsSynced,
  isUserAddLoading,
}) => {
  const styles = addUserStyles();

  return (
    <div className={styles.addUserContainer}>
      <div className={styles.peoplePickerContainer}>
        <InHouseInputSelect
          selectedOptions={selectedId}
          label="User (from azure active directory)"
          options={options}
          isPeoplePicker={true}
          placeholder={LocalStrings.addUserPlaceholder}
          isLoadingOptions={isLoading}
          onOptionSelect={(_e, option) => {
            onChange(option.selectedOptions as unknown as IOption);
          }}
          errorMessage={error?.msg}
          virtualized
        />
      </div>

      <CustomButton
        buttonType={ButtonTypes.Primary}
        buttonTitle={isUserAddLoading ? 'Loading...' : 'Add User'}
        onButtonClicked={onAddBtnHandler}
        customStyles={styles.button}
      />

      {selectedId?.value && !isAdminSettingLoading && (
        <PreUserAdd
          isOpen={isPreAddUserScreenOpen}
          onClose={togglePreAddUserScreen}
          idpOid={selectedId.value}
          departmentIsSynced={departmentIsSynced}
          managerIsSynced={managerIsSynced}
          userOptions={options}
        />
      )}
    </div>
  );
};

export default AddUserView;
