import { DateRangePicker } from '@celito.clients/shared';
import { DateRangePickerProps } from 'libs/shared/src/lib/date-range-picker/src/date-range-picker.model';
import { Controller, FieldError, FieldValues, Path } from 'react-hook-form';

import { ControlledDaterangePickerProps } from './controlled-daterange-picker.model';

interface ControlledDaterangePickerViewProps<T extends FieldValues>
  extends ControlledDaterangePickerProps<T> {}

const ControlledDaterangePickerView = <T extends FieldValues>(
  props: ControlledDaterangePickerViewProps<T>
): JSX.Element => {
  return (
    <Controller
      name={props.attribute?.name as Path<T>}
      control={props.control}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <DateRangePicker
            value={
              props.attribute?.valueParser
                ? (props.attribute.valueParser(
                    value
                  ) as DateRangePickerProps['value'])
                : value
            }
            onChange={onChange}
            required={
              'isRequired' in props.attribute
                ? props.attribute.isRequired
                : props.attribute.isMandatory
            }
            error={
              props.attribute?.errorParser
                ? (props.attribute.errorParser(error) as FieldError)
                : error
            }
          />
        );
      }}
    />
  );
};

export default ControlledDaterangePickerView;
