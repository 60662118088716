import { SortOrderEnum } from '@celito.clients/enums';
import { useQueryParams } from '@celito.clients/hooks';
import {
  setFiltersInQueryParams,
  SortConfig,
} from '@celito.clients/list-view-engine';
import { isObjectEmpty } from '@celito.clients/utils';
import { TableColumnId } from '@fluentui/react-components';
import { useCallback, useState } from 'react';

import { RulesComponentListViewFilter } from '../../lib/rules-component/types/rules-component.types';

export const useEndpointStatesAndFunctions = (defaultSortProp?: SortConfig) => {
  const [sortConfig, setSortConfig] = useState<SortConfig | undefined>(
    defaultSortProp
  );
  const { setUrlParams, clearUrlParams } = useQueryParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationConfig, setPaginationConfig] = useState({
    totalItems: 0,
    itemsPerRow: 0,
  });
  const [filters, setFilters] = useState<RulesComponentListViewFilter[]>([]);

  const handlePageChange = (selectedPage: number) => {
    setCurrentPage(selectedPage + 1);
  };

  const applyFilters = useCallback(
    (newFilters: RulesComponentListViewFilter, doApiCall = false) => {
      if (!newFilters && filters?.length === 0 && !doApiCall) {
        return;
      }
      setFilters([newFilters]);
      //set filters in query params
      if (isObjectEmpty(newFilters)) {
        clearUrlParams('filters');
      } else {
        setFiltersInQueryParams(newFilters, setUrlParams);
      }
    },
    [filters, clearUrlParams, setUrlParams]
  );

  const headerSortProps = (columnId: TableColumnId, order?: SortOrderEnum) => ({
    onClick: (e: React.MouseEvent) => {
      const sortProps: SortConfig = {
        attribute: columnId.toString(),
        order: order ?? SortOrderEnum.ASC,
      };

      setSortConfig(sortProps);
    },
  });

  return {
    applyFilters,
    headerSortProps,
    currentPage,
    paginationConfig,
    filters,
    setFilters,
    sortConfig,
    handlePageChange,
    setCurrentPage,
    setPaginationConfig,
  };
};
