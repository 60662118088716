import { CustomButton } from '@celito.clients/shared';
import { makeStyles } from '@fluentui/react-components';

const getStyles = makeStyles({
  section: {
    backgroundColor: 'var(--black)',
    color: 'var(--white)',
    width: '100%',
    height: '100%',
    display: 'grid',
    '&>div': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  text: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginBlockEnd: '8px',
    '& p:nth-child(1)': {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: '2.5rem',
    },
  },
});

export type TNoPreviewProps = {
  downloadRawDocument?: () => void;
};

/**
 * NO PREVIEW COMPONENT
 * This component is used to display a message when no preview is available for the document
 * scales to the size of the parent container
 */
export default function NoPreview({ downloadRawDocument }: TNoPreviewProps) {
  const styles = getStyles();
  return (
    <div className={styles.section} style={{ placeItems: 'center' }}>
      <div>
        <div className={styles.text}>
          <p>No Preview Available</p>
        </div>
        <CustomButton
          leftIcon="Download"
          buttonTitle="Download"
          onClick={() => downloadRawDocument?.()}
          size="small"
        />
      </div>
    </div>
  );
}
