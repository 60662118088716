import { RowItem } from 'libs/shared/src/lib/grid-view-new/src/types';
import { Link } from 'react-router-dom';

import classes from '../course-associated-curricula.module.css';

export const associatedCurriculaColumns = [
  {
    fieldName: 'label',
    isResizable: true,
    key: 'column1',
    maxWidth: 250,
    minWidth: 150,
    name: 'Curriculum ID',
    onRender: (item: RowItem) => (
      <Link
        to={`../view/curriculum_detail_view__a/${item.key}?readonly=1`}
        className={classes.hyperlink}
        target="_blank"
        title={item.label}
      >
        {item.label as string}
      </Link>
    ),
  },
  {
    name: 'Curriculum Title',
    isResizable: true,
    key: 'column2',
    maxWidth: 250,
    minWidth: 150,
    fieldName: 'title',
  },
];
