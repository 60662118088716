import { Stack, TextFieldArea } from '@celito.clients/shared';
import { createTestAttribute } from '@celito.clients/utils';
import { useEffect, useRef } from 'react';
import { Controller, FieldValues, Path } from 'react-hook-form';

import { AttributeTypeEnum } from '../../../enums/attributes-enum';
import { RuleTypeEnum } from '../../../enums/rules-type-enum';
import { ruleValue } from '../../../utils/form-validations';
import { ControlledTextFieldAreaProps } from './controlled-textarea.model';
import classes from './controlled-textarea.module.css';

export const ControlledTextFieldAreaView = <T extends FieldValues>(
  props: ControlledTextFieldAreaProps<T>
) => {
  const { name } = props.attribute;

  const minLength = ruleValue(RuleTypeEnum.MIN, props?.attribute?.rules) as
    | number
    | undefined;
  const maxLength = ruleValue(RuleTypeEnum.MAX, props?.attribute?.rules) as
    | number
    | undefined;

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const clickHandler = () => textareaRef.current?.focus();
    const textAreaElement = textareaRef.current;
    textAreaElement?.addEventListener('click', clickHandler);
    const labelElement = textAreaElement?.parentElement?.previousElementSibling;
    if (labelElement?.tagName === 'LABEL') {
      labelElement?.addEventListener('click', clickHandler);
    }
    return () => {
      textAreaElement?.removeEventListener('click', clickHandler);
      labelElement?.removeEventListener('click', clickHandler);
    };
  }, [textareaRef]);

  return (
    <Controller
      name={name as Path<T>}
      control={props.control}
      render={({
        field: { onChange, onBlur, name: fieldName, value },
        fieldState: { error },
      }) => (
        <Stack
          data-testid={`textfieldarea-container-${createTestAttribute(
            props.attribute?.label
          )}`}
          className={classes.section}
        >
          <TextFieldArea
            data-testid={`input-field-${createTestAttribute(
              props.attribute?.label
            )}`}
            {...props}
            {...(props.attribute as unknown as Record<string, unknown>)}
            required={
              'isRequired' in props.attribute
                ? props.attribute.isRequired
                : props.attribute.isMandatory
            }
            disabled={!props.attribute?.isEditable}
            placeholder={props.attribute?.placeholder ?? ''}
            onChange={onChange}
            value={
              props.attribute?.valueParser
                ? (props.attribute.valueParser(value) as string)
                : value ?? ''
            }
            onBlur={(e) => {
              if (
                !props.attribute.fromFilters &&
                props.attribute.dataType === AttributeTypeEnum.Number
              ) {
                // if datatype is number, remove leading zeros
                onChange(parseInt(value));
              }
              onBlur();
            }}
            name={fieldName}
            errorMessage={error?.message}
            inputRef={textareaRef}
            helperLabelText={props.attribute?.helpText}
            {...{ minLength, maxLength }}
          />
        </Stack>
      )}
    />
  );
};
