import { IOption } from 'libs/shared/src/lib/in-house-input-select/in-house-input-select.model';
import { useState } from 'react';

import { SortFacetProps } from './sort-facet.model';
import SortFacetView from './sort-facet.view';

const SortFacetController: React.FC<SortFacetProps> = (props) => {
  const { selectedSortList, onSortUpdationHandler } = props;

  const sortOptions: IOption[] = [
    {
      text: 'Relevancy',
      value: 'Relevancy',
    },
    {
      text: 'Created date',
      value: 'createdAtUtc',
    },
    {
      text: 'Modified date',
      value: 'modifiedAtUtc',
    },
  ];

  const [selectedSortOptions, setSelectedSortOptions] = useState<IOption>({
    value: 'Relevancy',
    text: 'Relevancy',
  });

  const onSortChange = (optionValue: string, optionText: string) => {
    setSelectedSortOptions({ value: optionValue, text: optionText });
  };

  return (
    <SortFacetView
      sortOptions={sortOptions}
      onSortChange={onSortChange}
      selectedSortOptions={selectedSortOptions}
      selectedSortList={selectedSortList}
      onSortUpdationHandler={onSortUpdationHandler}
    />
  );
};

export default SortFacetController;
