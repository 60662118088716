import { makeStyles } from '@fluentui/react-components';

export const controlledRichTextStyles = makeStyles({
  large: {
    '> div:nth-child(2)': {
      fontSize: '16px',
      wordBreak: 'break-word',
    },
  },

  medium: {
    '> div:nth-child(2)': {
      fontSize: '14px',
      wordBreak: 'break-word',
    },
  },

  small: {
    '> div:nth-child(2)': {
      fontSize: '12px',
      wordBreak: 'break-word',
    },
  },

  richtextContainer: {
    marginTop: '4px',
  },
});
