import { useNavigate } from 'react-router';

import { SecondaryDropdownConfigItem } from '../../types';
import { SecondaryDropdownProps } from './secondary-dropdown.component';
import SecondaryDropdownView from './secondary-dropdown.view';

interface SecondaryDropdownControllerProps extends SecondaryDropdownProps {}

const SecondaryDropdownController = (
  props: SecondaryDropdownControllerProps
): JSX.Element => {
  const navigate = useNavigate();

  const handleDropdownItemClick = (item: SecondaryDropdownConfigItem) => {
    if (item.onClick) {
      item.onClick();
    } else if (item.route) {
      navigate(item.route);
    }
    props.toggleSecondaryDropdown();
  };
  return (
    <SecondaryDropdownView
      handleDropdownItemClick={handleDropdownItemClick}
      {...props}
    />
  );
};

export default SecondaryDropdownController;
