import { ColumnDef, SortDirection } from '@tanstack/react-table';
import { MouseEvent as ReactMouseEvent } from 'react';

import { ColumnData, RowItem } from '../../../types';
import columnHelper from '../column-helper';

const defaultColumn = (col: ColumnData): ColumnDef<RowItem, unknown> => {
  let serverSortDirection: SortDirection | false = false;
  const isServerSorted = col.isSorted === true;
  if (col.isSorted) {
    serverSortDirection = col.isSortedAscending ? 'asc' : 'desc';
  }

  return columnHelper.accessor(col.fieldName!, {
    header: col.name,
    minSize: col.minWidth,
    maxSize: col.maxWidth,
    enableResizing: col.isResizable,
    meta: {
      columnType: 'default',
      showTooltip: !!col.showTooltip,
      isServerSorted,
      serverSortDirection,
      showSortIconWhenUnsorted: col.showSortIconWhenUnsorted,
      onClick: (e: ReactMouseEvent<HTMLElement, MouseEvent>) =>
        col.onColumnClick ? col.onColumnClick(e, col) : undefined,
    },
    ...(!col.disableAlphanumericSorting && {
      sortingFn: 'alphanumeric',
    }),
  });
};

export default defaultColumn;
